const columns = [
  {
    name: 'Avatar',
    selector: 'avatar',
    sortable: false,
    grow: 0,
  },
  {
    name: 'Seller Id',
    selector: 'selleridentifier',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Company Name',
    selector: 'company_name',
    sortable: true,
    grow: 1,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contact Person',
    selector: 'contact_person',
    sortable: true,
    grow: 1,
  },
  {
    name: "Buyer's Number",
    selector: 'buyersNumber',
    sortable: false,
    grow: 0
  },
  // {
  //   name: 'Status',
  //   selector: 'credit_status',
  //   sortable: false,
  //   cell: row =>
  //     (row.credit_status === 'Credit Line Created') ? "Credit Line Created" :
  //       (row.credit_status === 'User Approval Pending') ? "User Approval Pending" :
  //         (row.credit_status === 'Admin Revision') ? "Admin Revision" :
  //           (row.credit_status === 'User Rejected') ? "User Rejected" :
  //             (row.credit_status === 'User Approved') ? "User Approved" :
  //               (row.credit_status === 'Admin Approved') ? "Admin Approved" : "NA",
  //   conditionalCellStyles: [
  //     {
  //       when: row => (row.credit_status === 'Credit Line Created'),
  //       style: {
  //         backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.credit_status === 'User Approval Pending'),
  //       style: {
  //         backgroundColor: 'rgba(255, 150, 0, 0.9)',  //orange
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.credit_status === 'Admin Revision'),
  //       style: {
  //         backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.credit_status === 'User Rejected'),
  //       style: {
  //         backgroundColor: 'rgba(248, 0, 0, 0.9)',  //red
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.credit_status === 'Admin Approved'),
  //       style: {
  //         backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
  //         color: 'white',
  //       },
  //     },
  //     {
  //       when: row => (row.credit_status === 'User Approved'),
  //       style: {
  //         backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
  //         color: 'white',
  //       },
  //     }
  //   ]
  // },
  // {
  //   name: 'Created At',
  //   selector: 'created_at',
  //   sortable: false,
  // },
  {
    name: 'Address',
    selector: 'user_address',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Country',
    selector: 'country_code',
    sortable: true,
    grow: 0,
  },
  {
    name: 'Actions',
    selector: 'action',
    sortable: false,
    grow: 0,
  }
];

export default columns
