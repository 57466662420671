import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setDisbursed } from "../../../store/actions/action";
import call from "../../../service";
import toastDisplay from "../../../utils/toastNotification";
import { todayDate_DatePicker } from '../../../utils/dateFormaters';
import validate from '../../../utils/validation_files/disbursedFormValidationRules'


const Disbursed = ({ userTokenDetails, setDisbursed, disbursed, dispatch }) => {
  const [data, setData] = useState({});
  const [value, setValue] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const astrix = <span className="required-field text-danger">*</span>

  // UseEffects
  useEffect(() => {
    call('POST', 'getDiscounting', { id: disbursed.info.id, type: disbursed.info.type, tradeType: (disbursed.info.type / 1 === 4) ? "offline" : "" }).then((result) => {
      console.log('Get Discounting API in disbursed=====>', result);
      setValue({ ...value, id: disbursed.info.id, type: disbursed.info.type });

      if (disbursed.info.type / 1 < 4) {
        setData(result);
      } else {
        setData({
          sellerName: result.finData.sellerName,
          lenderName: result.finData.lenderName,
          contractAmountCurrencyName: result.finData.offline_data.dirCurrency.split(":")[1],
          contract_amount: result.finData.contract_amount,
          amountCurrencyName: result.finData.offline_data.dirCurrency.split(":")[1],
          amount: result.finData.amount,
          interest_rate: result.finData.interest_rate,
          credit_days: result.finData.credit_days,
          status: result.finData.status
        });
      }

    }).catch((e) => {
      console.log('error in getDiscountingnn', e);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      disburseFinance()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  // Handlers
  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value })
    setError({ ...error, [event.target.name]: "" })
  }

  const handleFiles = (event) => {
    event.persist();
    setValue({ ...value, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" })
  };

  function disburseFinance() {
    setshowLoader(true)
    let formData = new FormData();
    Object.keys(value).forEach((key) => {
      formData.append(key, value[key]);
    })

    call('POST', 'disburseFinance', formData).then((result) => {
      console.log('post disburseFinance ===== API>', result);
      setDisbursed(false, {})
      setshowLoader(false)
      toastDisplay("Disbursed successfuly", "success");
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in disburseFinance', e);
      toastDisplay(e, "error");
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(value));
    setIsSubmitting(true);
  };


  console.log("data in disbursed form==>", disbursed)

  // JSX body
  return (
    <div>

      <div className="">
        {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="col-md-12">
          <h1 className="text-center"><u>Disbursal Form</u></h1><br />
          <div className="row bg-shadow p-4">

            <div className="col-md-12">
              <br /><h3><u>Finance Aplication Details</u></h3><br />
            </div>
            <div className="col-md-6 mt-2">
              <label >Exporter</label>
              <input type="text" className="form-control" defaultValue={data.sellerName} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Financing Bank</label>
              <input type="text" className="form-control" defaultValue={data.lenderName} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Currency</label>
              <input type="text" className="form-control" defaultValue={data.contractAmountCurrencyName ? data.contractAmountCurrencyName : data.amountCurrencyName} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Contract Amount</label>
              <input type="text" className="form-control" defaultValue={data.contract_amount} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Currency</label>
              <input type="text" className="form-control" defaultValue={data.amountCurrencyName ? data.amountCurrencyName : data.currency} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Approved Amount</label>
              <input type="text" className="form-control" defaultValue={data.amount} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Rate of Interest</label>
              <input type="text" className="form-control" defaultValue={data.interest_rate} readOnly />
            </div>
            <div className="col-md-6 mt-2">
              <label >Credit Days</label>
              <input type="text" className="form-control" defaultValue={data.credit_days} readOnly />
            </div>

          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="row form-group">

            <div className="col-md-12">
              <h3> Finance Details</h3>
              <hr />
              <div className="row">

                <div className="col-md-4 mt-2">
                  <label >Finance Id {astrix} </label>
                  <input type="text" name="loanId" className={"form-control" + (error.currency ? " border-danger" : "")} onChange={handleChange} />
                  {error.loanId ? <div class="text-danger error-contract">{error.loanId}</div> : ''}
                </div>
                <div className="col-md-4 mt-2">
                  <label >Financier Name{astrix} </label>
                  <input type="text" name="financier" className={"form-control" + (error.financier ? " border-danger" : "")} onChange={handleChange} />
                  {error.financier ? <div class="text-danger error-contract">{error.financier}</div> : ''}
                </div>
                <div className="col-md-4 mt-2">
                  <label >Finance Manager {astrix} </label>
                  <input type="text" name="manager" className={"form-control" + (error.manager ? " border-danger" : "")} onChange={handleChange} />
                  {error.manager ? <div class="text-danger error-contract">{error.manager}</div> : ''}
                </div>
                <div className="col-md-4 mt-2">
                  <label >Dated {astrix} </label>
                  <input type="datetime-local" value={todayDate_DatePicker()} className="form-control" disabled />
                </div>

                <div className="col-md-12 mt-5 pb-3">
                  <ul className="other-documents">
                    <li>
                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                    </li>
                    <li>
                      <h3 className={"custum-control" + (error.lcDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Related Document {astrix} </h3>
                    </li>
                    <li>
                      <div className="file-browse col-md-12">
                        <button className="btn btn-primary btn-sm col-md-6">Upload Document</button>
                        <input className="col-md-12" type="file" accept=".png,.jpg,.pdf" name="disbursedDoc" onChange={handleFiles} />
                      </div>
                    </li>
                    {value.disbursedDoc &&
                      <li>
                        <div className="form-group mb-0"><div className="file-name">{`${value.disbursedDoc.name}`}</div></div>
                      </li>}
                    {error.disbursedDoc ? <div class="text-danger error-contract">{error.disbursedDoc}</div> : ''}
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 p-4">
        <div className="row form-group">
          <div className="col-md-12">
            {data.status === 3 && <button type="button" className="btn btn-success float-right btn-sm" onClick={() => prehandleSubmit()}>
              Disburse
                  <i class="fa fa-check-circle-o p-1" aria-hidden="true"></i>
            </button>}
            {data.status === 4 && <button type="button" className="btn btn-success float-right btn-sm" disabled>
              Disbursed
                  <i class="fa fa-check-circle-o p-1" aria-hidden="true"></i>
            </button>}

          </div>
        </div>
      </div>
    </div>
  );
};

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDisbursed: (flag, data) => { dispatch(setDisbursed({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Disbursed)
