import React, { useEffect, useState } from 'react';
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import avatarUrl from '../../utils/makeAvatarUrl';


const ManageNetworkRequest = ({ userTokenDetails, }) => {

  const [showLoader, setshowLoader] = useState(false);
  const [requestType, setrequestType] = useState('received');
  const [requestsResult, setrequestsResult] = useState([]);

  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true);
    call('POST', 'getnetworkrequests', { "reqType": requestType, "userId": userId }).then((result) => {
      //console.log('running getnetworkrequests api-->', result);
      setrequestsResult(result)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })
    //------------------------------------------------------------------

  }, [requestType]);

  function resolveRequest(data, action, index, type) {
    //console.log("data==>", data)
    // console.log("action==>", action)

    let postObject = {
      id: data.id,
      rFrom: data.request_from,
      rTo: data.request_to,
      newStatus: action,
      loggedUserId: userId,
      reqType: type
    }

    setshowLoader(true);
    call('POST', 'resolvenetworkrequest', postObject).then((result) => {
      // console.log('running getnetworkrequests api-->', result);
      let temp = [...requestsResult]
      temp.splice(index, 1);
      setrequestsResult(temp)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })

  }

  function genReqBody(type) {
    let ReqBody = (requestsResult && requestsResult.length) ? requestsResult.map((request, index) => {
      return (
        <div className='col-md-12 row bg-light mt-3 mb-3 border-bottom border-success rounded'>
          <div className='col-md-1'>
            <img className="m-1" src={avatarUrl(request.user_avatar)} alt="Avatar" width="50" height="50" />
          </div>
          <div className='col-md-2'>
            <label className="text-info">{request.company_name ? request.company_name : "NA"} </label>
          </div>
          <div className='col-md-2'>
            <label className="">{request.usertype_pretty_name ? request.usertype_pretty_name : "NA"} </label>
          </div>
          <div className='col-md-4'>
            <p className="date-format p-0 m-0">{request.user_address ? request.user_address : "NA"} </p>
          </div>
          <div className='col-md-2 text-center'>
            {(type === 'received') && <button type="button" className="btn btn-sm btn-success ml-1" onClick={() => resolveRequest(request, 2, index, type)}>
              <i class="fa fa-check" aria-hidden="true"></i>
            </button>}
            <button type="button" className="btn btn-sm btn-warning ml-1" onClick={() => resolveRequest(request, 3, index, type)}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            <button type="button" className="btn btn-sm btn-danger ml-1" onClick={() => resolveRequest(request, 4, index, type)}>
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )
    }) : ''

    return ReqBody
  }


  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className="col-md-12 row">
        <div className="filter-bg">
          <ul>
            <li className={"complete-cont cursor-pointer " + ((requestType === "received") ? "shadow" : '')} id="progessButton" onClick={() => { setrequestType("received") }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Received Requests
            			</li>
            <li className={"progress-cont cursor-pointer " + ((requestType === "sent") ? "shadow" : '')} id="statusFilter" onClick={() => { setrequestType("sent") }}>
              <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Sent Requests
                  </li>
          </ul>
        </div>
      </div>
      <hr />

      <div className="">
        {genReqBody(requestType)}
      </div>

    </div>
  )
};



export default ManageNetworkRequest