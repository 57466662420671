import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/raisedInvoiceColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { setChannelPartnerRaisedInvoice, setFinanceDetails } from '../../store/actions/action';
import { formatDate_Application } from '../../utils/dateFormaters';
import InvoiceFinance from '../finance/financeApply/invoiceFinance';
import LCFinance from '../finance/financeApply/lcFinance';

const RaisedInvoice = ({ userTokenDetails, navToggleState, dispatch, channelPartnerRaisedInvoice, setChannelPartnerRaisedInvoice, setFinanceDetails, financeDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [channelPartnerUsers, setChannelPartnerUsers] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    call('POST', 'getChannelPartnerUsers', { "userId": userId }).then((result) => {
      console.log('running getChannelPartnerUsers api-->', result);
      setChannelPartnerUsers(result.userData);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
    })

  }, []);

  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerInvoice', objectAPI).then((result) => {
      console.log('running getChannelPartnerInvoice api-->', result);
      settableData(formatDataFortable(result.invoiceData));
      setTotalRows(result.invoiceData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerInvoice', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerUsers', objectAPI).then((result) => {
      settableData(formatDataFortable(result.userData));
      setTotalRows(result.userData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.reference_no = dataArray[i].reference_no;
      tempObj.company_name = (dataArray[i].company_name ? dataArray[i].company_name : 'NA');
      tempObj.type = (dataArray[i].type == 2 ? "LC" : dataArray[i].type == 3 ? "Invoice" : "");
      tempObj.contract_amount = dataArray[i].contract_amount;
      tempObj.amount = (dataArray[i].amount);
      tempObj.created_at = formatDate_Application(dataArray[i].created_at);
      tempObj.action = (dataArray[i].status !== 4 &&
        <button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => {
          {
            (dataArray[i].seller_status / 1 === 2 && dataArray[i].lender_status / 1 === 2) ?
              setFinanceDetails(true, dataArray[i].type / 1, 2, { action: "viewFinance", FinanceID: dataArray[i].id, sellerStatus: dataArray[i].seller_status / 1, lenderStatus: dataArray[i].lender_status / 1 }) :
              setFinanceDetails(true, dataArray[i].type / 1, 1, { action: "viewFinance", FinanceID: dataArray[i].id, sellerStatus: dataArray[i].seller_status / 1, lenderStatus: dataArray[i].lender_status / 1 })
          };
          setChannelPartnerRaisedInvoice(true, { "userId": dataArray[i].tbl_user_id, "userName": dataArray[i].company_name, "userEmail": dataArray[i].email_id, "financeType": dataArray[i].type })
        }}>
          {(dataArray[i].seller_status / 1 === 2 && dataArray[i].lender_status / 1 === 2) ?
            <i class="fa fa-eye" aria-hidden="true"></i> :
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>}
        </button>
      )
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const reset = () => {
    setFilterData({ supplierType: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'raisedinvoice'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={channelPartnerRaisedInvoice.modal ? `Finance Against Invoice (${channelPartnerRaisedInvoice.info.userName})` : "Transaction in process"}
              userTokenDetails={userTokenDetails} />

            {channelPartnerRaisedInvoice.modal ?
              <>
                {channelPartnerRaisedInvoice.info.financeType == 2 &&
                  <LCFinance
                    userTokenDetails={userTokenDetails} />}
                {channelPartnerRaisedInvoice.info.financeType == 3 &&
                  <InvoiceFinance
                    userTokenDetails={userTokenDetails} />}
              </>
              :
              <>
                <div class="d-md-flex mt-3 mb-3">
                  <div class="ml-md-auto">
                    <ul class="range-slider">
                      <li>
                        <select className="form-control" name="supplierType" value={filterData.supplierType} onChange={(event) => { setFilterData({ supplierType: event.target.value }) }}>
                          <option value="" selected>--Select Exporter--</option>
                          {channelPartnerUsers && channelPartnerUsers.length > 0 && channelPartnerUsers.map((supplier) => {
                            return (<option value={supplier.tbl_user_id + ':' + supplier.company_name}>{supplier.company_name}</option>)
                          })}
                        </select>
                      </li>
                      <li></li>
                      <li></li>
                      <li>
                        <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
              </>
            }
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice,
    financeDetails: state.financeDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerRaisedInvoice: (modal, info) => { dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info })) },
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RaisedInvoice)