import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setShowCreateProfileAdminModal, setRegistrationData } from '../../store/actions/action';

import Registration from '../registration/registration';
import AddressBookComp from './addressBookComp'
import SendInviteToUser from './sendInviteComp'
import ChannelPartnerRegistration from './channelPartnerRegistration';

const CreateProfileAdminModal = ({ userTokenDetails, channelPartnerUser, setShowCreateProfileAdminModal, showCreateProfileAdminModal, registrationData, setRegistrationData, dispatch }) => {

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {};
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  const [data, setdata] = useState({
    type: 0
    // "type": (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist) ? 0 : 2
  });
  const [addrBkPage, setaddrBkPage] = useState(0);
  //const [countStatus, setcountStatus] = useState({});


  function handlePreButtonReg() {
    if (registrationData.step === 0) {
      // if ((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist)) {
      setdata({ "type": 0 })
      // } else {
      //   setShowCreateProfileAdminModal(false, {})
      // }

    } else {
      registrationData.payload.modalNav = 1;
      setRegistrationData(registrationData.type, registrationData.step, registrationData.payload);
    }
  }
  function handleNextButtonReg() {
    registrationData.payload.modalNav = 3;
    registrationData.payload['employee'] = { "loggedUserId": userId };
    setRegistrationData(registrationData.type, registrationData.step, registrationData.payload);
  }

  function handlePreButtonAddrBK() {
    if (addrBkPage === 1) {
      setdata({ "type": 0 })
    } else {
      setaddrBkPage(addrBkPage - 1)
    }
  }
  function handleNextButtonAddrBK() {
    if (addrBkPage < 2)
      setaddrBkPage(addrBkPage + 1)
  }

  function handleOnHide() {
    setdata({ "type": 0 })
    setRegistrationData(0, 0, {});
    setShowCreateProfileAdminModal(false, { refresh: true });
  }

  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  function getUserDetails(email) {
    call('POST', 'getuserprofiledata', { 'email': email ? email : '', 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      if (result != undefined && result.company_name) {
        setaddrBkPage(1)
        setdata(data => ({ ...data, "type": 1, "searchUserEmail": result.user_name, "displayError": "" }));
      } else {
        setdata(data => ({ ...data, "displayError": "User Not on Platform, Please register" }));
      }
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })
  }

  function returnJSXforSelection() {
    let width = ((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist) &&
      (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist)) ? 4 :
      ((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist) ||
        (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist)) ? 6 : 12

    return (
      <>
        <div className="modal-body calc-inner-modal row">
          {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist) ?
            <div className="col-md-12 mb-2 text-center">
              <div className="col-md-12 row">
                <label className="col-md-12 m-2 text-info">Find User by Email or referral Code</label>
                <div className="col-md-12">
                  <input type="text" name="searchUserEmail" placeholder="Enter user email here" value={data.searchUserEmail} className="form-control" onChange={handleChange} />
                </div>
                <div className="col-md-12 m-2">
                  <input type="button" value="Apply" className="btn btn-primary btn-sm" onClick={() => getUserDetails(data.searchUserEmail)} />
                </div>
                <div className="col-md-12 m-2">
                  <h3 className="text-danger error-contract">{data.displayError}</h3>
                </div>
              </div>
              <hr />
            </div> :
            <div className="col-md-12 mt-6 mb-3"></div>}
          <div className="col-md-12 our-webcoderskull text-center">
            <ul className="row col-md-12 m-auto" id="">
              {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist) &&
                <li className={`col-md-${width} ${data.type / 1 === 1 ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { setdata(data => ({ ...data, "type": 1 })); setaddrBkPage(1) }}>
                      <i class="fa fa-address-book fa-5x text-info pt-3" aria-hidden="true"></i>
                    </figure>
                    <h3>Select from Global Users</h3>
                  </div>
                </li>}
              {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) &&
                <li className={`col-md-${width} ${data.type / 1 === 2 ? 'active' : ''} `} true >
                  <div className="cnt-block equal-hight">
                    <figure onClick={() => { setdata(data => ({ ...data, "type": 2 })); }}>
                      <i class="fas fa-id-card fa-5x text-info pt-3" aria-hidden="true"></i>
                    </figure>
                    <h3>Register a new User</h3>
                  </div>
                </li>}

              <li className={`col-md-${width} ${data.type / 1 === 3 ? 'active' : ''} `} true >
                <div className="cnt-block equal-hight">
                  <figure onClick={() => { setdata(data => ({ ...data, "type": 3 })); }}>
                    <i class="fas fa-paper-plane fa-5x text-info pt-3" aria-hidden="true"></i>
                  </figure>
                  <h3>Send Invite</h3>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </>
    )
  }
  console.log("showCreateProfileAdminModal ==>", showCreateProfileAdminModal)


  return (
    <>
      {showCreateProfileAdminModal.modal && <div className={"modal" + (showCreateProfileAdminModal.modal ? " show" : "")} id="registration">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">
                {(data.type === 1) ? "Add user from Global List" :
                  (data.type === 2) ? "Register a New User" :
                    (data.type === 3) ? "Send Invite to a User" :
                      (data.type === 4) ? "Register a Channel / Franchise Partner" : "Add/Invite new User"}
              </h4>
              <button type="button" className="close" onClick={handleOnHide}>×</button>
            </div>

            {/* Modal body Start*/}
            {(aclMap.mng_subusers_profile) ?
              <>
                {(channelPartnerUser === true && data.type === 0) ?
                  <div className="modal-body calc-inner-modal row">
                    <div className="col-md-12 our-webcoderskull text-center">
                      <ul className="row col-md-12 m-auto" id="">
                        <li className={`col-md-6 ${data.type / 1 === 1 ? 'active' : ''} `} true >
                          <div className="cnt-block equal-hight">
                            <figure onClick={() => { setdata(data => ({ ...data, "type": 4 })); }}>
                              <i class="fas fa-id-card fa-5x text-info pt-3" aria-hidden="true"></i>
                            </figure>
                            <h3>Register a Channel / Franchise Partner</h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> :
                  (data.type === 0) ?
                    returnJSXforSelection() :
                    (data.type === 1 && (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_addInNetwork && aclMap.mng_subusers_profile.mng_subusers_addInNetwork.isExist)) ?
                      <AddressBookComp
                        userTokenDetails={userTokenDetails}
                        addrBkPage={addrBkPage}
                        userEmailSearch={data.searchUserEmail ? data.searchUserEmail : ''} /> :
                      (data.type === 2) ?
                        <Registration
                          userTokenDetails={userTokenDetails} /> :
                        (data.type === 3) ? <>
                          <SendInviteToUser
                            userTokenDetails={userTokenDetails} />
                        </> :
                          (data.type === 4) ? <>
                            <ChannelPartnerRegistration userTokenDetails={userTokenDetails} adminProfile={true} status={2} />
                          </> :
                            <h3 className="text-danger text-center">
                              Some Error Occured
                            </h3>
                }
              </> :
              <h3 className="text-primary text-center">
                You dont have permission to Add users in your Network, Contact Admin if you think this is an error.
              </h3>
            }
            {/*Modal body Ends*/}

            <div className="modal-footer primary">
              {(data.type === 1) ?
                <>
                  <button type="button" className="btn btn-primary float-left btn-sm" onClick={() => { handlePreButtonAddrBK() }}>Back</button>
                  {(addrBkPage < 2) && <button type="button" className="btn btn-success btn-sm" onClick={() => { handleNextButtonAddrBK() }}>Next</button>}
                </> :
                (data.type === 2) ?
                  <>
                    {(registrationData.step !== 5) && <button type="button" className="btn btn-primary float-left btn-sm" onClick={() => { handlePreButtonReg() }}>Back</button>}
                    {(registrationData.step > 0) && (registrationData.step !== 5) && <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleNextButtonReg() }}>Next</button>}
                  </> :
                  (data.type === 3) ?
                    <>
                      <button type="button" className="btn btn-primary float-left btn-sm" onClick={() => { setdata(data => ({ ...data, "type": 0 })); }}>Back</button>
                    </>
                    : (data.type === 4) ?
                      <><button type="button" className="btn btn-primary float-left btn-sm" onClick={() => { setdata(data => ({ ...data, "type": 0 })); }}>Back</button> </>
                      : <button type="button" className="btn btn-primary float-left btn-sm" onClick={() => { handleOnHide() }}>Close</button>
              }
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}


const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProfileAdminModal)
