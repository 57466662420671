import React, { useEffect, useState } from 'react';
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import { connect } from 'react-redux';
import toastDisplay from '../../utils/toastNotification';
import { setShowCreateProfileAdminModal } from '../../store/actions/action';
import avatarUrl from '../../utils/makeAvatarUrl';


const AddressBookComp = ({ userTokenDetails, addrBkPage, userEmailSearch, setShowCreateProfileAdminModal, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  //states and variables
  const [dbUserData, setdbUserData] = useState([]);
  const [allUserInNetwork, setallUserInNetwork] = useState([]);
  const [dbTypeData, setdbTypeData] = useState([]);
  const [filterData, setfilterData] = useState({ "userAvatars": true });
  const [selectedUsers, setselectedUsers] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [tempQuota, setTempQuota] = useState(0);

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Useeffect
  useEffect(() => {

    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    call('GET', 'getalluserinnetwork').then((result) => {
      let newArray = []
      for (let i = 0; i < result.length; i++) {
        newArray.push(result[i].connection_id)
      }
      setallUserInNetwork(newArray)
    }).catch((e) => {
      console.log('error in getalluserinnetwork', e);
    })

    if (userEmailSearch) {
      call('POST', 'getUsersListing', { "searchUserType": '', "searchKey": userEmailSearch, "network": "fullnetwork", "loggedUserId": userId, "userAvatars": true }).then((result) => {
        // console.log("result of api in fetchUsers-->", result)
        setdbUserData(result)
      }).catch((e) => {
        console.log('error in fetchUsers', e);
      })
    }

    call('get', 'v1/plan/quota').then((result) => {
      setUserPlanQuota(result)
      setTempQuota(result.partners.limitLeft / 1)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota AddressBookComp:-->", e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Function and hadlers
  function fetchUsers(event) {
    setshowLoader(true)

    if (event) {
      event.persist();
      filterData.searchUserType = event.target.value
    }
    filterData["loggedUserId"] = userId
    filterData["network"] = "fullnetwork"

    call('POST', 'getUsersListing', filterData).then((result) => {
      // console.log("result of api in fetchUsers-->", result)
      let tempArray = [], tempArrayResult = [...result]
      for (let i = 0; i < selectedUsers.length; i++) {
        tempArray.push(selectedUsers[i].id)
      }
      for (let i = 0; i < result.length; i++) {
        let index = tempArray.indexOf(result[i].id);
        if (index > -1) {
          tempArrayResult.splice(i, 1);
        }
      }
      setdbUserData(tempArrayResult)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in fetchUsers', e);
    })
  }

  const handleChange = (event) => {
    event.persist();
    setfilterData(filterData => ({ ...filterData, [event.target.name]: event.target.value }));
  };

  function handleSelectedUserGlobal(value) {
    if (tempQuota === -1 || tempQuota > 0) {
      let tempArray = [...selectedUsers]
      tempArray.push(value)
      setselectedUsers(tempArray)

      tempArray = [...dbUserData]
      let index = tempArray.indexOf(value);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
      setdbUserData(tempArray)
      if (tempQuota > 0) {
        setTempQuota(tempQuota - 1)
      }
    } else {
      toastDisplay("Can not Add more users", "warn")
    }
  }

  function handleSelectedUserAdded(value) {
    let tempArray = [...dbUserData]
    tempArray.push(value)
    setdbUserData(tempArray)

    tempArray = [...selectedUsers]
    let index = tempArray.indexOf(value);
    if (index > -1) {
      tempArray.splice(index, 1);
    }
    setselectedUsers(tempArray)
    if (tempQuota > 0) {
      setTempQuota(tempQuota + 1)
    }
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Submit handler

  function preHandleSubmitCheck() {
    if (userPlanQuota.partners && userPlanQuota.partners.enable && ((userPlanQuota.partners.limitLeft / 1) > 0 || (userPlanQuota.partners.limitLeft / 1) === -1)) {
      if ((userPlanQuota.partners.limitLeft / 1) === -1) {
        networkAddRequestSubmit()
      } else if ((userPlanQuota.partners.limitLeft / 1) > selectedUsers.length) {
        networkAddRequestSubmit()
      } else {
        toastDisplay("You have selected " + selectedUsers.length + " Users which is more then your remaining " + userPlanQuota.partners.limitLeft + " " + userPlanQuota.partners.desc + " Quota", "warn")
      }
    } else {
      if (userPlanQuota.trades)
        toastDisplay("You have already used " + userPlanQuota.partners.limitUsed + " out of " + userPlanQuota.partners.totalLimit + " " + userPlanQuota.partners.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  function networkAddRequestSubmit() {

    setshowLoader(true);
    // console.log("in networkAddRequestSubmit=>", selectedUsers)
    let finalUserArray = []
    for (let i = 0; i < selectedUsers.length; i++) {
      finalUserArray.push({
        "id": selectedUsers[i].id,
        "company_name": selectedUsers[i].company_name
      })
    }
    // console.log("in finalUserArray=>", finalUserArray)
    call('POST', 'addusersinnetwork', { "loggedUserId": userId, "networkUserArray": finalUserArray }).then((result) => {
      //console.log('running getcontract api-->', result);
      toastDisplay("All Request sent successfuly.", "success");
      setshowLoader(false);
      setShowCreateProfileAdminModal(false, { refresh: true });
    }).catch((e) => {
      console.log('error in addusersinnetwork', e);
      toastDisplay(e, "error");
      setshowLoader(false);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Jsx Creators
  function createUsersListing() {
    let usersList = (dbUserData && dbUserData.length) ? dbUserData.map((value, index) => {
      return (
        <>
          <div className={"mt-3 mb-3 ml-1 mr-1 row col-md-12 bg-light rounded border-bottom border-primary " + (!allUserInNetwork.includes(value.id / 1) ? "cursor-pointer" : "")}
            onClick={!allUserInNetwork.includes(value.id / 1) ? () => { handleSelectedUserGlobal(value) } : ""} >
            <div className="col-md-1 text-center">
              <div className="" style={{ "vertical-align": "middle" }}>
                <img src={avatarUrl(value.user_avatar)} alt="description" height="60" />
                <p className="date-format p-0 m-0">{value.usertype_pretty_name ? value.usertype_pretty_name : '--'}</p>
              </div>
            </div>
            <div className="col-md-10">
              <h3 className="col-md-12">{value ? value.company_name : '--'}</h3>
              <div className="col-md-12 row">
                <div className="col-md-1 align-middle">
                  {(value && value.country_code) ? <span className="shadow" >
                    <ReactCountryFlag
                      countryCode={value.country_code ? value.country_code : ''}
                      style={{ width: '30px', height: '30px' }} svg />
                  </span> : '--'}
                </div>
                <div className="col-md-11">
                  <p className="pt-0 pb-0 mt-0 mb-0 "> {value.user_name ? value.user_name : ''}  </p>
                  <p className="pt-0 pb-0 mt-0 mb-0 date-format"> {value.user_address ? value.user_address : ''} </p>
                </div>
              </div>
            </div>
            <div className="col-md-1 text-center">
              <div className="" style={{ "vertical-align": "middle" }}>
                <i class={"fas fa-2x " + (allUserInNetwork.includes(value.id / 1) ? "fa-user-check text-primary" : "fas fa-user-plus text-success")}></i>
                <p className={"date-format p-0 m-0 " + (allUserInNetwork.includes(value.id / 1) ? "text-primary" : "text-success")}>{allUserInNetwork.includes(value.id / 1) ? "In Network" : 'Add User'}</p>
              </div>
            </div>
          </div>
        </>)
    }) : ""
    return usersList
  }

  function createSelectedUsersListing() {
    let usersList = selectedUsers && selectedUsers.length ? selectedUsers.map((value, index) => {
      return (
        <>
          <div className={"mt-3 mb-3 ml-1 mr-1 row col-md-12 bg-light rounded border-bottom border-primary " + (!allUserInNetwork.includes(value.id / 1) ? "cursor-pointer" : "")} onClick={() => { handleSelectedUserAdded(value) }} >
            <div className="col-md-1 text-center">
              <div className="align-middle" style={{ "vertical-align": "middle" }}>
                <img src={avatarUrl(value.user_avatar)} alt="description" height="60" />
                <p className="date-format p-0 m-0">{value.usertype_pretty_name ? value.usertype_pretty_name : '--'}</p>
              </div>
            </div>
            <div className={(addrBkPage === 2) ? "col-md-10" : "col-md-11"}>
              <h3 className="col-md-12">{value ? value.company_name : '--'}</h3>
              <div className="col-md-12 row">
                <div className="col-md-1 align-middle">
                  {(value && value.country_code) ? <span className="shadow" >
                    <ReactCountryFlag
                      countryCode={value.country_code ? value.country_code : ''}
                      style={{ width: '30px', height: '30px' }} svg />
                  </span> : '--'}
                </div>
                <div className="col-md-11">
                  <p className="pt-0 pb-0 mt-0 mb-0 "> {value.user_name ? value.user_name : ''}  </p>
                  <p className="pt-0 pb-0 mt-0 mb-0 date-format"> {value.user_address ? value.user_address : ''} </p>
                </div>
              </div>
            </div>
            {(addrBkPage === 2) && <div className="col-md-1 text-center">
              <div className="" style={{ "vertical-align": "middle" }}>
                <i class="text-success fas fa-2x fa-user-lock"></i>
                {/* <p className={"date-format p-0 m-0 " + (allUserInNetwork.includes(value.id / 1) ? "text-primary" : "text-success")}>{allUserInNetwork.includes(value.id / 1) ? "In-Network" : 'Add'}</p> */}
              </div>
            </div>}
          </div>
        </>)
    }) : <div className=""><h3 className="text-warning">No user selected yet</h3></div>
    return usersList
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <div className="modal-body calc-inner-modal">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        {addrBkPage === 1 ? <>
          <div className="col-md-12 row">
            <div className="form-group col-md-5">
              <select className="form-control" name="searchUserType" value={filterData.searchUserType} onChange={(event) => { handleChange(event); fetchUsers(event) }}>
                <option value="0" selected>--Select User Type--</option>
                {(dbTypeData.length) ? dbTypeData.map((item) => {
                  if (item.id > 2) {
                    return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                  }
                }) : ''
                }
              </select>
            </div>
            <div className="form-group col-md-5">
              <input type="text" name="searchKey" placeholder="Search User" value={filterData.searchKey} className="form-control" onChange={handleChange} />
            </div>
            <div className="form-group col-md-2">
              <button type="button" className="btn btn-primary btn-sm float-left" onClick={() => fetchUsers()}>Search</button>
            </div>
          </div>
          <hr />

          <h3 className="text-primary"> Selected Users ({tempQuota === -1 ? "INF" : tempQuota} Left)</h3>
          {createSelectedUsersListing()}
          <hr className="text-primary" />

          <h3 className="text-primary">Users On Platform </h3>
          {createUsersListing()}
        </> :
          (addrBkPage === 2) ?
            <>
              <h3 className="text-primary"> Selected Users </h3>
              {createSelectedUsersListing()}
              <hr className="text-primary" />
              {(selectedUsers.length > 0) && <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => preHandleSubmitCheck()}>Submit</button>}
            </> : ""}
      </div>
    </>
  )
}


const mapStateToProps = state => {
  return {
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressBookComp)
