import React, { useEffect, useState } from 'react';
import { setcreditLineDetails, setCreditDocuments, setCreditlineDataBank } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import validate from '../../utils/validation_files/eligibilityCriteria';
import toastDisplay from '../../utils/toastNotification';
import toolTipText from '../../utils/toolTips/eligibilityCriteria.json';


const MinCriteria = ({ userTokenDetails, setcreditLineDetails, creditLineDetails, navToggleState, dispatch, creditlineDataBank, setCreditlineDataBank, CreditDocuments, setCreditDocuments }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect

  useEffect(() => {

    call('POST', 'getEligibilityCriteria', data).then((result) => {
      console.log('running getEligibilityCriteria api-->', result);
      setData({
        supplierProfile: result.supplierProfile,
        auditedFin: result.auditedFin,
        receivableBuyer: result.receivableBuyer,
        buyersClient: result.buyersClient,
        email: result.email,
        dunsNo: result.dunsNo,
        website: result.website,
        minBusiness: result.minBusiness,
        maxBusiness: result.maxBusiness,
        newBuyer: result.newBuyer,
        soleProprietorship: result.soleProprietorship,
        partnership: result.partnership,
        pvtPubLtd: result.pvtPubLtd,
        LLP: result.LLP,
        apparelTextile: result.apparelTextile,
        industrialMech: result.industrialMech,
        foodBeverage: result.foodBeverage,
        gamingMedia: result.gamingMedia,
        electronicConsumer: result.electronicConsumer,
        eCommerce: result.eCommerce,
        fmcg: result.fmcg,
        medicalPharma: result.medicalPharma,
        auto: result.auto,
        frozenFood: result.frozenFood,
        ITStaffing: result.ITStaffing,
        argo: result.argo,
        govtEntity: result.govtEntity,
        oilGas: result.oilGas,
        diamond: result.diamond,
        preciousStone: result.preciousStone,
        gold: result.gold
      })
    }).catch((e) => {
      console.log("conn:", e);
    })

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = userId
    data.userTypeId = userTypeId
    data.userEmail = userEmail

    call('POST', 'eligibilityCriteria', data).then((result) => {
      console.log('running eligibilityCriteria api-->', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
    }).catch((e) => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", e)
      toastDisplay(e, "error");
    })

  }

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'setting'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title='Eligibility Criteria for Credit Line'
              userTokenDetails={userTokenDetails} />
            <>
              <div className="col-md-12 pt-2 pb-5 mt-5">

                <div className="mb-2 ">
                  <h3 className=" border-bottom pb-2">Select Eligibility Criteria</h3>
                </div>
                <br />

                {/* 1st section */}
                {/* Onboarding */}

                <div className="accordionWrapper  p-0">
                  <div className="container-fluid accordionItem open">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="green mt-3">
                              Onboarding
                            </h3>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row ">

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="supplierProfile" id="supplierProfile" onChange={hanleDocChecks} checked={data.supplierProfile} />
                                  <label className="form-check-label" for="supplierProfile">
                                    Supplier Profile
                                  </label>
                                </div>
                              </div>

                              {/* <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="auditedFin" id="auditedFin" onChange={hanleDocChecks} checked={data.auditedFin} />
                                  <label className="form-check-label" for="auditedFin">
                                    Audited Financials
                              </label>
                                </div>
                              </div> */}

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="receivableBuyer" id="receivableBuyer" onChange={hanleDocChecks} checked={data.receivableBuyer} />
                                  <label className="form-check-label" for="receivableBuyer">
                                    Accounts receivables ageing as on date,showing receivables from all buyers
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="buyersClient" id="buyersClient" onChange={hanleDocChecks} checked={data.buyersClient} />
                                  <label className="form-check-label" for="buyersClient">
                                    List of buyers that client wants to work with us in the attached format
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="email" id="email" onChange={hanleDocChecks} checked={data.email} />
                                  <label className="form-check-label" for="email">
                                    Email (gmail & yahoo, hotmail wont be accepted for legal framework)
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="dunsNo" id="dunsNo" onChange={hanleDocChecks} checked={data.dunsNo} />
                                  <label className="form-check-label" for="dunsNo">
                                    DUNS NO (optional)
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="website" id="website" onChange={hanleDocChecks} checked={data.website} />
                                  <label className="form-check-label" for="website">
                                    Webiste
                                  </label>
                                </div>
                              </div>

                              {/* <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="minInvoice" id="minInvoice" onChange={hanleDocChecks} checked={data.minInvoice} />
                                  <label className="form-check-label" for="minInvoice">
                                    Minimum Invoice Value
                              </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="maxCredit" id="maxCredit" onChange={hanleDocChecks} checked={data.maxCredit} />
                                  <label className="form-check-label" for="maxCredit">
                                    Maximum Credit Term
                              </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="minCredit" id="minCredit" onChange={hanleDocChecks} checked={data.minCredit} />
                                  <label className="form-check-label" for="minCredit">
                                    Minimum Credit Term
                              </label>
                                </div>
                              </div> */}

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="newBuyer" id="newBuyer" onChange={hanleDocChecks} checked={data.newBuyer} />
                                  <label className="form-check-label" for="newBuyer">
                                    New Buyer are Accepted
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <label>Audited Financials (in years) </label>
                                <input type="number" className={" form-control" + (!error.auditedFin ? '' : ' border-danger')} placeholder="" name="auditedFin" value={data.auditedFin} onChange={handleChange} />
                                {error.auditedFin && <p className="text-danger error-contract">{error.auditedFin}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Minimum Exisiting business with buyer (in USD) </label>
                                <input type="number" className={" form-control" + (!error.minBusiness ? '' : ' border-danger')} placeholder="" name="minBusiness" value={data.minBusiness} onChange={handleChange} />
                                {error.minBusiness && <p className="text-danger error-contract">{error.minBusiness}</p>}
                              </div>

                              <div className="form-group col-md-6">
                                <label>Minimum Expected Business with buyer (in USD) </label>
                                <input type="number" className={" form-control" + (!error.maxBusiness ? '' : ' border-danger')} placeholder="" name="maxBusiness" value={data.maxBusiness} onChange={handleChange} />
                                {error.maxBusiness && <p className="text-danger error-contract">{error.maxBusiness}</p>}
                              </div>

                              {/* <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="minBusiness" id="minBusiness" onChange={hanleDocChecks} checked={data.minBusiness} />
                                  <label className="form-check-label" for="minBusiness">
                                    Minimum Exisiting business with buyer
                              </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="maxBusiness" id="maxBusiness" onChange={hanleDocChecks} checked={data.maxBusiness} />
                                  <label className="form-check-label" for="maxBusiness">
                                    Minimum Expected Business with buyer
                              </label>
                                </div>
                              </div> */}

                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2nd section */}
                {/* Finance Available For */}

                <div className="accordionWrapper  p-0">
                  <div className="container-fluid accordionItem open">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="green mt-3">
                              Finance Available For
                            </h3>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row ">

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="soleProprietorship" id="soleProprietorship" onChange={hanleDocChecks} checked={data.soleProprietorship} />
                                  <label className="form-check-label" for="soleProprietorship">
                                    Sole Proprietorship {toolTip('soleProprietorship')}
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="partnership" id="partnership" onChange={hanleDocChecks} checked={data.partnership} />
                                  <label className="form-check-label" for="partnership">
                                    Partnership {toolTip('partnership')}
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="pvtPubLtd" id="pvtPubLtd" onChange={hanleDocChecks} checked={data.pvtPubLtd} />
                                  <label className="form-check-label" for="pvtPubLtd">
                                    PVT/PUB.LTD {toolTip('pvtPubLtd')}
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="LLP" id="LLP" onChange={hanleDocChecks} checked={data.LLP} />
                                  <label className="form-check-label" for="LLP">
                                    LLP {toolTip('LLP')}
                                  </label>
                                </div>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 3rd section */}
                {/* Eligible Industry */}

                <div className="accordionWrapper  p-0">
                  <div className="container-fluid accordionItem open">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="green mt-3">
                              Eligible Industry
                            </h3>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row ">

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="apparelTextile" id="apparelTextile" onChange={hanleDocChecks} checked={data.apparelTextile} />
                                  <label className="form-check-label" for="apparelTextile">
                                    Apparels & Textile
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="industrialMech" id="industrialMech" onChange={hanleDocChecks} checked={data.industrialMech} />
                                  <label className="form-check-label" for="industrialMech">
                                    Industrial & Mechanical
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="foodBeverage" id="foodBeverage" onChange={hanleDocChecks} checked={data.foodBeverage} />
                                  <label className="form-check-label" for="foodBeverage">
                                    Food & Beverage
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="gamingMedia" id="gamingMedia" onChange={hanleDocChecks} checked={data.gamingMedia} />
                                  <label className="form-check-label" for="gamingMedia">
                                    Gaming & Media
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="electronicConsumer" id="electronicConsumer" onChange={hanleDocChecks} checked={data.electronicConsumer} />
                                  <label className="form-check-label" for="electronicConsumer">
                                    Electronics & Consumer Goods
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="eCommerce" id="eCommerce" onChange={hanleDocChecks} checked={data.eCommerce} />
                                  <label className="form-check-label" for="eCommerce">
                                    E-Commerce
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="fmcg" id="fmcg" onChange={hanleDocChecks} checked={data.fmcg} />
                                  <label className="form-check-label" for="fmcg">
                                    FMCG
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="medicalPharma" id="medicalPharma" onChange={hanleDocChecks} checked={data.medicalPharma} />
                                  <label className="form-check-label" for="medicalPharma">
                                    Medical & Pharmaceutical
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="auto" id="auto" onChange={hanleDocChecks} checked={data.auto} />
                                  <label className="form-check-label" for="auto">
                                    Auto
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="frozenFood" id="frozenFood" onChange={hanleDocChecks} checked={data.frozenFood} />
                                  <label className="form-check-label" for="frozenFood">
                                    Frozen Foods
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="ITStaffing" id="ITStaffing" onChange={hanleDocChecks} checked={data.ITStaffing} />
                                  <label className="form-check-label" for="ITStaffing">
                                    IT Staffing
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="argo" id="argo" onChange={hanleDocChecks} checked={data.argo} />
                                  <label className="form-check-label" for="argo">
                                    AGRO
                                  </label>
                                </div>
                              </div>

                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* 4th section */}
                {/* Restricted Industry */}

                <div className="accordionWrapper  p-0">
                  <div className="container-fluid accordionItem open">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="green mt-3">
                              Restricted Industry
                            </h3>
                            <hr />
                          </div>
                          <div className="col-md-12">
                            <div className="row ">

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="govtEntity" id="govtEntity" onChange={hanleDocChecks} checked={data.govtEntity} />
                                  <label className="form-check-label" for="govtEntity">
                                    Buyer/Supplier Government Entity
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="oilGas" id="oilGas" onChange={hanleDocChecks} checked={data.oilGas} />
                                  <label className="form-check-label" for="oilGas">
                                    Oil/Gas
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="diamond" id="diamond" onChange={hanleDocChecks} checked={data.diamond} />
                                  <label className="form-check-label" for="diamond">
                                    Diamond
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="preciousStone" id="preciousStone" onChange={hanleDocChecks} checked={data.preciousStone} />
                                  <label className="form-check-label" for="preciousStone">
                                    Precious Stone
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="form-check pl-0 mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="gold" id="gold" onChange={hanleDocChecks} checked={data.gold} />
                                  <label className="form-check-label" for="gold">
                                    Gold
                                  </label>
                                </div>
                              </div>

                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" className="btn btn-success btn-sm" onClick={() => preHandleSubmit()} >Submit</button>

              </div>
            </>
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditlineDataBank: state.creditlineDataBank,
    navToggleState: state.navToggleState,
    creditLineDetails: state.creditLineDetails,
    CreditDocuments: state.CreditDocuments,
    creditlineDataBank: state.creditlineDataBank
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setcreditLineDetails: (modal, info) => { dispatch(setcreditLineDetails({ modal: modal, info: info })) },
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
    setCreditDocuments: (modal, info) => { dispatch(setCreditDocuments({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinCriteria)