import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';
import { formatDate_Application } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile'
import { setproductDetails } from '../../store/actions/action';
import ReactCountryFlag from "react-country-flag";
import CreateProduct from '../manageProductComp/createProduct';


const CreateCreditLine = ({ userTokenDetails, productDetails, setproductDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Get offer by Auto-match");
  const [secondtab, setSecondTab] = useState(0);
  const [secondtabName, setSecondTabName] = useState("Details");
  const [portLoading, setPortLoading] = useState([]);
  const [eligibilityCriteria, setEligibilityCriteria] = useState([]);
  const [product, setProduct] = useState([]);
  const [productList, setProductList] = useState([]);
  const [getDetail, setGetDetail] = useState({ modal: false, data: {} });
  const [countryData, setcountryData] = useState([]);
  const [productDetail, setProductDetail] = useState([]);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    });

    let objectAPI = {
      "userId": userId,
      "userTypeId": userTypeId,
      "userName": userName,
      "userEmail": userEmail,
    }

    call('POST', 'getProductList', objectAPI).then((result) => {
      setProduct(result);
      setProductList(result);
    }).catch((e) => {
      console.log('error in getProductList', e);
    })

    call('POST', 'getEligibilityCriteria').then((result) => {
      console.log('running getEligibilityCriteria api-->', result);
      setEligibilityCriteria(result);
    }).catch((e) => {
      console.log("getEligibilityCriteria:", e);
    })

    call('POST', 'getProductDetail', objectAPI).then((result) => {
      setProductDetail(result)
    }).catch((e) => {
      console.log('error in getProductDetail', e);
    })

  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  // JSX body
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="creditline_listing">
          <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
            <li>
              <a className={"nav-link  cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
                setTabName("Get offer for you");
              }}>Get offer for you</a>
            </li>
            <li>
              <a className={"nav-link  cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
                setTabName("Advance Filter");
              }}>Advance Filter</a>
            </li>
          </ul>

          <>
            {tab === 0 &&
              <>
                <div className="d-flex mt-3 ">
                  {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                      <thead>
                        <tr style={{ fontSize: "14px" }}>
                          <th width="20">#</th>
                          <th width="300">Product Name</th>
                          <th width="300">Amount</th>
                          <th width="300">Interest Rate</th>
                          <th width="300">Funding Tenor</th>
                          <th width="300">Minimum Invoice Value</th>
                          <th width="300">Maximum Credit Term</th>
                          <th width="300">Minimum Credit Term</th>
                          <th width="150">Valid upto</th>
                          <th width="300">Actions</th>
                        </tr>
                      </thead>
                      {product.length > 0 && productList.map((values, key) => {
                        return (
                          <tbody>
                            <tr style={{ fontSize: "14px" }}>
                              <td >{key + 1}</td>
                              <td>{values.productName}</td>
                              <td >Upto {values.approvedFinanceAmount + ' ' + values.currency.split(':')[1]}</td>
                              <td >{values.interestRate}%</td>
                              <td >{values.fundingTenor} Years</td>
                              <td >{values.minInvoice + ' ' + values.currency.split(':')[1]}</td>
                              <td >{values.maxCredit} Days</td>
                              <td >{values.minCredit} Days</td>
                              <td >{formatDate_Application(values.offerValidity)}</td>
                              <td>
                                <button type="button" className="btn btn-success btn-sm" onClick={() => setGetDetail({ modal: true, data: { id: values.id } })}>Get Detail</button>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                    </table>
                  </div>
                </div>
              </>
            }

            {tab === 1 &&
              <>
                <CreateProduct userTokenDetails={userTokenDetails} type="user" />
              </>
            }
          </>
          {/* <>
            <button type="button" className="btn btn-default btn-sm" onClick={tab > 0 ? () => setTab(tab - 1) : ''} id="previd">Back</button>
            <button type="button" className="btn btn-info btn-sm" onClick={tab < 1 ? () => setTab(tab + 1) : ''} id="nextid">Next</button>
          </> */}
        </div>
      </div>

      {getDetail.modal &&
        productDetail.map((values) => {
          if (values.id == getDetail.data.id) {
            return (
              <div className={"modal" + (getDetail.modal ? " show" : "")} id="ApplyCredit">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h3 className="modal-title text-white">Product Details</h3>
                      <button type="button" className="close" onClick={() => { setGetDetail({ modal: false, data: {} }) }}>×</button>
                    </div>
                    <div className="modal-body calc-modal">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                      <div className="calc-inner-modal">

                        <div className="creditline_listing">
                          <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                            <li>
                              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (secondtab === 0 ? " active show" : "")} onClick={() => {
                                setSecondTab(0);
                                setSecondTabName("Details");
                              }}>Details</a>
                            </li>
                            <li>
                              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (secondtab === 1 ? " active show" : "")} onClick={() => {
                                setSecondTab(1);
                                setSecondTabName("Eligibility Criteria");
                              }}>Eligibility Criteria</a>
                            </li>
                          </ul>

                          <>
                            {secondtab === 0 &&
                              <>
                                {/* a Section */}
                                {/* Funding Details */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Funding Details</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                <ul className="price-ul">
                                                  <li>
                                                    <div className="price-left">{values.productName}</div>
                                                    <div className="price-right">Product Name</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.approvedFinanceAmount}</div>
                                                    <div className="price-right">Approved Finance Amount</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.fundingPercentage}%</div>
                                                    <div className="price-right">Funding Percentage(%)</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.fundingTenor} Years</div>
                                                    <div className="price-right">Funding Tenor</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.minInvoice} Years</div>
                                                    <div className="price-right">Minimum Invoice Value</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.maxCredit} Years</div>
                                                    <div className="price-right">Maximum Credit Term</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.minCredit} Years</div>
                                                    <div className="price-right">Minimum Credit Term</div>
                                                  </li>
                                                </ul>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* b section */}
                                {/* Interest Details */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Interest Details</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                <ul className="price-ul">
                                                  <li>
                                                    <div className="price-left">{values.interestRate}</div>
                                                    <div className="price-right">Interest Rate</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.interestCollection}</div>
                                                    <div className="price-right">Interest Collection</div>
                                                  </li>
                                                </ul>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* c section */}
                                {/* Finance Request Step Fees */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Finance Request Step Fees</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                <ul className="price-ul">
                                                  <li>
                                                    <div className="price-left">{values.finRequestPercentage}%</div>
                                                    <div className="price-right">Percentage</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.finRequestCollection}</div>
                                                    <div className="price-right">Collection</div>
                                                  </li>
                                                </ul>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* d section */}
                                {/* Other T&C */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Other T&C</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                <ul className="price-ul">
                                                  <li>
                                                    <div className="price-left">{values.sanctionCountry ? <span className="shadow">
                                                      <ReactCountryFlag
                                                        countryCode={values.sanctionCountry.split(':')[0]}
                                                        style={{ width: '25px', height: '25px' }} svg />
                                                    </span> : ''}</div>
                                                    <div className="price-right">Sanction Country</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.sanctionPort}</div>
                                                    <div className="price-right">Sanction Port</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.commodities}</div>
                                                    <div className="price-right">Commodities</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{values.currency.split(':')[1]}</div>
                                                    <div className="price-right">Currency</div>
                                                  </li>
                                                  <li>
                                                    <div className="price-left">{formatDate_Application(values.offerValidity)}</div>
                                                    <div className="price-right">Offer Valid Till</div>
                                                  </li>
                                                </ul>

                                              </div>

                                              <br />
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* e section */}
                                {/* Required Documents */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Required Documents</h3>
                                            <hr />
                                          </div>

                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                <ul className="price-ul">
                                                  <li>
                                                    {values.requiredDocuments.split(':').map((docs) => {
                                                      return (
                                                        <div className="price-left">{docs}</div>
                                                      )
                                                    })}
                                                  </li>
                                                </ul>

                                              </div>
                                              <br />
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </>
                            }

                            {secondtab === 1 &&
                              <>
                                {/* Onboarding */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Onboarding</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                {eligibilityCriteria &&
                                                  <ul className="price-ul">
                                                    {eligibilityCriteria.supplierProfile != null &&
                                                      <li>
                                                        <div className="price-left">Supplier Profile</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.auditedFin != null &&
                                                      <li>
                                                        <div className="price-left">Audited Financials</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.receivableBuyer != null &&
                                                      <li>
                                                        <div className="price-left">Accounts receivables ageing as on date,showing receivables from all buyers</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.buyersClient != null &&
                                                      <li>
                                                        <div className="price-left">List of buyers that client wants to work with us in the attached format</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.email != null &&
                                                      <li>
                                                        <div className="price-left">Email (gmail & yahoo, hotmail wont be accepted for legal framework)</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.dunsNo != null &&
                                                      <li>
                                                        <div className="price-left">DUNS NO (optional)</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.website != null &&
                                                      <li>
                                                        <div className="price-left">Webiste</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.minBusiness != null &&
                                                      <li>
                                                        <div className="price-left">Minimum Exisiting business with buyer (in USD)</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.maxBusiness != null &&
                                                      <li>
                                                        <div className="price-left">Minimum Expected Business with buyer (in USD)</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.newBuyer != null &&
                                                      <li>
                                                        <div className="price-left">New Buyer are Accepted</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.soleProprietorship != null &&
                                                      <li>
                                                        <div className="price-left">Sole Proprietorship</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.partnership != null &&
                                                      <li>
                                                        <div className="price-left">Partnership</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.pvtPubLtd != null &&
                                                      <li>
                                                        <div className="price-left">PVT/PUB.LTD</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.LLP != null &&
                                                      <li>
                                                        <div className="price-left">LLP</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.apparelTextile != null &&
                                                      <li>
                                                        <div className="price-left">Apparels & Textile</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.industrialMech != null &&
                                                      <li>
                                                        <div className="price-left">Industrial & Mechanical</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.foodBeverage != null &&
                                                      <li>
                                                        <div className="price-left">Food & Beverage</div>
                                                      </li>
                                                    }
                                                  </ul>
                                                }

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Finance Available For */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Finance Available For</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                {eligibilityCriteria &&
                                                  <ul className="price-ul">
                                                    {eligibilityCriteria.soleProprietorship != null &&
                                                      <li>
                                                        <div className="price-left">Sole Proprietorship</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.partnership != null &&
                                                      <li>
                                                        <div className="price-left">Partnership</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.pvtPubLtd != null &&
                                                      <li>
                                                        <div className="price-left">PVT/PUB.LTD</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.LLP != null &&
                                                      <li>
                                                        <div className="price-left">LLP</div>
                                                      </li>
                                                    }
                                                  </ul>
                                                }

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Eligible Industry */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Eligible Industry</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                {eligibilityCriteria &&
                                                  <ul className="price-ul">
                                                    {eligibilityCriteria.apparelTextile != null &&
                                                      <li>
                                                        <div className="price-left">Apparels & Textile</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.industrialMech != null &&
                                                      <li>
                                                        <div className="price-left">Industrial & Mechanical</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.foodBeverage != null &&
                                                      <li>
                                                        <div className="price-left">Food & Beverage</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.gamingMedia != null &&
                                                      <li>
                                                        <div className="price-left">Gaming & Media</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.electronicConsumer != null &&
                                                      <li>
                                                        <div className="price-left">Electronics & Consumer Goods</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.eCommerce != null &&
                                                      <li>
                                                        <div className="price-left">E-Commerce</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.fmcg != null &&
                                                      <li>
                                                        <div className="price-left">FMCG</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.medicalPharma != null &&
                                                      <li>
                                                        <div className="price-left">Medical & Pharmaceutical</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.auto != null &&
                                                      <li>
                                                        <div className="price-left">Auto</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.frozenFood != null &&
                                                      <li>
                                                        <div className="price-left">Frozen Foods</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.argo != null &&
                                                      <li>
                                                        <div className="price-left">AGRO</div>
                                                      </li>
                                                    }
                                                  </ul>
                                                }

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Restricted Industry */}

                                <div className="accordionWrapper ">
                                  <div className="container-fluid accordionItem open">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h3 className="green mt-3">Restricted Industry</h3>
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="form-group col-md-12">

                                                {eligibilityCriteria &&
                                                  <ul className="price-ul">
                                                    {eligibilityCriteria.govtEntity != null &&
                                                      <li>
                                                        <div className="price-left">Buyer/Supplier Government Entity</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.oilGas != null &&
                                                      <li>
                                                        <div className="price-left">Oil/Gas</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.diamond != null &&
                                                      <li>
                                                        <div className="price-left">Diamond</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.preciousStone != null &&
                                                      <li>
                                                        <div className="price-left">Precious Stone</div>
                                                      </li>
                                                    }
                                                    {eligibilityCriteria.gold != null &&
                                                      <li>
                                                        <div className="price-left">Gold</div>
                                                      </li>
                                                    }
                                                  </ul>
                                                }

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          </>

                        </div>

                      </div>
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-default btn-sm" onClick={secondtab > 0 ? () => setSecondTab(secondtab - 1) : ''} id="previd">Back</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={secondtab < 1 ? () => setSecondTab(secondtab + 1) : ''} id="nextid">Next</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => setGetDetail({ modal: false, data: {} })} >Close</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })
      }
    </>
  );
};

// Redux
// Getting the global state from redux store.
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    productDetails: state.productDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setproductDetails: (status, info) => { dispatch(setproductDetails({ status: status, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(mapStateToProps, mapDispatchToProps)(CreateCreditLine);