import React, { useEffect, useState } from 'react';
import call from "../../../service";
import { connect } from "react-redux";
import { setFinanceDetails } from '../../../store/actions/action';
import toastDisplay from "../../../utils/toastNotification";
import toolTipText from '../../../utils/toolTips/financeForm.json'
import { formatDate_Application, dateFormatter_DatePicker } from '../../../utils/dateFormaters';
import FilePreview from '../../utilComponents/previewFile';
import DigitalSignDoc from '../../contractDocument/digitalSignDoc2';
import OtherDocs from "./otherDocs";
import validate from '../../../utils/validation_files/POFinanceValidationRules';
import config from '../../../config.json';
import { ClearCache, convertImageToPdf, GetCache, SetCache } from '../../../utils/myFunctions';


const POFinance = ({ userTokenDetails, setFinanceDetails, financeDetails, dispatch }) => {
  let cache_data = financeDetails.info.action === "applyFinance" ? GetCache("po_form_data") : {}

  //---------------------------------------------------------------------------------------------------------------------
  // Const State and their handlers declaration and initiation.
  const [bankMaster, setbankMasters] = useState([]);
  const [buyerArr, setBuyerArr] = useState([]);
  const [buyerType, setBuyerType] = useState(cache_data["buyerType"] || 1);
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState(cache_data["data"] || {});
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [fileData, setfileData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [creditData, setCreditData] = useState([]);
  const [counterPart, setCounterPart] = useState([]);
  const [accord, setAccord] = useState({
    0: false
  });
  const [tab, setTab] = useState(0)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    setAclAuthModal(false)
    if (financeDetails.info.tab) {
      setTab(financeDetails.info.tab)
    }

    if (financeDetails.info.action === "viewFinance") {
      setshowLoader(true)
      call('POST', 'getDiscounting', { id: financeDetails.info.FinanceID, type: financeDetails.type }).then((result) => {
        console.log('Po Get Discounting API =====>', result);

        let poDbData = {
          id: result.id,
          referenceNo: result.reference_no,
          buyerId: result.buyer_id / 1 === 0 ? "ME" : result.buyer_id,
          sellerId: result.seller_id,
          sellerName: result.sellerName,
          lenderName: result.lenderName,
          applicantEmail: result.sellerName,
          buyerName: result.buyer_id / 1 === 0 ? "manual" : result.buyerName,
          amount: result.amount,
          contractAmount: result.contract_amount,
          contractAmountCurrency: result.contract_amount_currency,
          contractAmountCurrencyName: result.contractAmountCurrencyName,
          amountCurrencyName: result.amountCurrencyName,
          amountCurrency: result.currency,
          issueDate: result.issue_date ? dateFormatter_DatePicker(result.issue_date) : "",
          creditDays: result.credit_days,
          interestRate: result.interest_rate,
          lenderId: result.lender_id,
          status: result.status,
          sellerStatus: result.seller_status,
          lenderStatus: result.lender_status,
          type: result.type,
          doc: result.docData.docList,
          exhibitDocList: result.docData.exhibitDocList,
          exhibitStatus: result.exhibit_sign_status,
          NOADocList: result.docData.NOADocList,
          frameworkDocList: result.docData.frameworkDocList,
          dnc: "on",
          fintnc: result.fin_tnc,
          buyerNameManual: result.offline_data.buyer_name,
          buyerAddrManual: result.offline_data.buyer_addr,

        }

        setBuyerArr([{
          id: result.buyer_id,
          company_name: result.buyerName
        }])
        setBuyerType(result.offline_data.buyer_type ? result.offline_data.buyer_type : 1)
        setbankMasters([{
          id: result.lender_id,
          company_name: result.lenderName
        }]);

        setAccord({
          0: true
        })

        setAmount(result.amount)
        setData({ ...poDbData, userId: userId })
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log('error in getDiscounting', e);
      })

    } else {
      setData({ ...data, userId: userId, applicantEmail: userEmail });
    }

    // Get currency list from Master table.
    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    if (financeDetails.info.action === "applyFinance") {
      // Get bank master list that are involved in trade finance.
      call("GET", "getBanksListMaster")
        .then((result) => {
          setbankMasters(result);
        })
        .catch((e) => {
          console.log("Error while querying getBanksListMaster:", e);
        });
    }

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI }


    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setCreditData(result.finData);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })

    let buyerObjectAPI = {
      "userId": userId,
      "userTypeId": userTypeId
    }

    buyerObjectAPI = { ...buyerObjectAPI }

    call('POST', 'getBuyersDetail', buyerObjectAPI).then((result) => {
      console.log('running getBuyersDetail api -->', result.buyerData);
      setCounterPart(result.buyerData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (financeDetails.info.action === "applyFinance") {
      SetCache('po_form_data', { data, buyerType })
    }
  }, [data, buyerType])

  useEffect(() => {
    if (financeDetails.info.action === "applyFinance") {

      if (buyerType === 1) {
        call('POST', 'getUsersListing', { "searchUserType": 3, "searchKey": '', "network": "innetwork", "userAvatars": false })
          .then((result) => {
            console.log("result in getUsersListing", result);
            setBuyerArr(result);
            setshowLoader(false)
          })
          .catch((e) => {
            console.log("error in getUsersListing", e);
          });
      } else if (buyerType === 2) {
        call('POST', 'getuserdirectorybytype', { "searchUserType": 3, "searchKey": '' })
          .then((result) => {
            console.log("result in getuserdirectorybytype", result);
            setBuyerArr(result);
            setshowLoader(false)

          })
          .catch((e) => {
            console.log("error in getUsersListing", e);
          });
      }
    }
  }, [buyerType]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      if (tab === 0) {
        setTab(1)
      }
      else {
        setTab(0)
        forward()
      }
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // console.log("data====>", data)
  // console.log("file====>", file)
  // console.log("error====>", error)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  const handleChange = (event) => {
    event.persist();

    if (event.target.name === "buyerId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: (val[0] === "ME" ? val[0] : +val[0]), buyerName: val[1], buyerEmail: val[2] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "contractAmountCurrency") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: val[0], contractAmountCurrencyName: val[1], amountCurrency: val[0], amountCurrencyName: val[1] });
      setError({ ...error, [event.target.name]: "", "amountCurrency": "" });
    } else if (event.target.name === "lenderId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], lenderName: val[1], lenderUniqueId: val[2] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "dnc") {
      if (data.dnc === "on") {
        setData({ ...data, "dnc": "" });
      } else {
        setData({ ...data, [event.target.name]: event.target.value });
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
      setError({ ...error, [event.target.name]: "" });
    }
  };

  const handleFiles = (event) => {
    event.persist();
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
      toastDisplay('Files with pdf, png & jpeg extension are allowed', 'info')
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (!file_type.includes("pdf")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
        // console.log("response==>", response);
      }
      setFile({ ...file, [event.target.name]: fileObj });
      setError({ ...error, [event.target.name]: "" });
    }
  };

  const prehandleForward = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, file, financeDetails, userTypeId, tab));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  // Handler for next button.
  function forward() {
    setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) + 1, financeDetails.info)
  }

  // Handler for back button.
  function backward() {
    if (financeDetails.page === 1) {
      if (financeDetails.info.action === "viewFinance") {
        setFinanceDetails(false, 0, 0, {})
      } else {
        setFinanceDetails(financeDetails.status, 0, 0, financeDetails.info)
      }
    } else if (financeDetails.page === 2 && financeDetails.info.sellerStatus === 2 && financeDetails.info.lenderStatus === 2) {
      setFinanceDetails(false, 0, 0, {})
    } else {
      setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) - 1, financeDetails.info)
      setIsSubmitting(false)

    }
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render=>", values)

      values.tbl_doc_id = values.id
      values.document_name = values.doc_name
      values.uploaded_by = values.createdBy
      values.uploaded_on = values.created_at
      values.signType = 'withoutBlockChain'

      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
        </td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          <button
            disabled={values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1}
            title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={() => callAclAuthComponent(values)}>
            <i class="fas fa-file-signature"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function docUploadPreview(type) {
    return (<tr>
      <td>1</td>
      <td>NA</td>
      <td>NA</td>
      <td>NA</td>
      <td>NA</td>
      <td className="row justify-content-center  mt-0 pt-0 m-0 ">
        <div className="file-browse ">
          <button title="Update File" className="text-center btn btn-success btn-sm m-1">
            <i className="fa fa-upload" aria-hidden="true"></i>
          </button>
          <input type="file" accept=".png,.jpg,.pdf" name={type == 'Exhibit' ? 'ExhibitDocument' : 'NOADocument'} onChange={(event) => updateDocument(event, type)} />
        </div>
      </td>
    </tr>)
  }

  function docAgreementPreview(type) {

    if (type == 'Exhibit') {
      let exhibitDocElements = data.exhibitDocList.length ? data.exhibitDocList.map((values, index) => {
        // console.log("value in doc render =>", values)

        values.reqId = data.id
        values.tbl_doc_id = values.id
        values.document_name = values.doc_name
        values.uploaded_by = values.createdBy
        values.uploaded_on = values.created_at
        values.signType = 'withoutBlockChain'

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {userTypeId == 4 || userTypeId == 14 &&
              <button
                disabled={values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1}
                title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(values)}>
                <i class="fas fa-file-signature"></i>
              </button>}

          </td>
        </tr>)
      }) : ''
      return (exhibitDocElements)
    }

    if (type == 'NOA') {
      let NOADocElements = data.NOADocList.length ? data.NOADocList.map((values, index) => {
        // console.log("value in doc render =>", values)

        values.reqId = data.id
        values.tbl_doc_id = values.id
        values.document_name = values.doc_name
        values.uploaded_by = values.createdBy
        values.uploaded_on = values.created_at
        values.signType = 'withoutBlockChain'
        values.type = 'OTPVerification'

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {userTypeId == 4 || userTypeId == 14 &&
              <button
                disabled={values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1}
                title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(values)}>
                <i class="fas fa-file-signature"></i>
              </button>}

          </td>
        </tr>)
      }) : ''
      return (NOADocElements)
    }

    if (type == 'Framework') {
      let FrameworkDocElements = data.frameworkDocList.length ? data.frameworkDocList.map((values, index) => {

        if (values.lendername == data.lenderName) {

          console.log("value in doc render hhhhhhhhhhhhhhhhhhhh =>", values);
          let valueData = JSON.parse(values.frameworkDocList);

          valueData.reqId = data.id
          valueData.tbl_doc_id = valueData.id
          valueData.document_name = valueData.doc_name
          valueData.uploaded_by = values.lenderName
          valueData.uploaded_on = valueData.created_at
          valueData.signType = 'withoutBlockChain'

          return (<tr>
            <td>{1}</td>
            <td>{(valueData && valueData.doc_name) ? valueData.doc_name : 'NA'}</td>
            <td>{(data.lenderName) ? data.lenderName : 'NA'}</td>
            <td>{(valueData && valueData.created_at) ? formatDate_Application(valueData.created_at) : 'NA'}</td>
            <td>
              {(valueData && valueData.sign) ? <pre><span className="text-success mr-1">{valueData.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
            </td>
            <td className="row justify-content-center  mt-0 pt-0 m-0 ">

              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                () => callPreviewFileComponent(valueData, "view")}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                () => callPreviewFileComponent(valueData, "download")}>
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              {userTypeId == 4 || userTypeId == 14 &&
                <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(valueData)}>
                  <i class="fas fa-file-signature"></i>
                </button>}

            </td>
          </tr>)
        }
      })
        : ''
      return (FrameworkDocElements)
    }

  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  function updateDocument(event, type) {

    let formData = new FormData();
    formData.append('id', data.id);
    formData.append('finType', 1);
    formData.append('uploadType', type);
    formData.append(type == 'Exhibit' ? 'ExhibitDocument' : 'NOADocument', event.target.files[0]);
    call('POST', 'uploadFinAgreement', formData).then((result) => {
      if (result) {
        toastDisplay("File Uploaded", "success");
        setrefresh(refresh + 1);
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Submit handlers

  const submitPOFinanceForm = (event) => {

    setshowLoader(true)
    const currentRoute = "poDiscounting"
    data.sellerId = userId

    data["poDocumentMetadata"] = JSON.stringify({
      "docNo": data.referenceNo,
      "docName": 'PO Document',
      "genDocLabel": "poDocument",
      "docType": 21,
      "category": 3,
      "validUpto": ""
    })

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null
    data.baseUrl = config.baseUrl;
    data.applicantName = userName;

    let formData = new FormData();

    let tempOffline = { buyer_type: buyerType }
    if (data.buyerId === "ME") {
      tempOffline = { ...tempOffline, buyer_name: data.buyerNameManual, buyer_addr: data.buyerAddrManual }
    }
    formData.append("offline_data", JSON.stringify(tempOffline));

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    Object.keys(file).forEach((key) => {
      formData.append(key, file[key]);
    })

    // formData.append("lenderId", "23")

    call("post", currentRoute, formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay("Loan Applied successfuly", "success");
        ClearCache('po_form_data')
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in create finance modal", e);
      });
  };

  // Accept Po Discounting Call
  function acceptPoDiscounting(party) {
    call("POST", "acceptPO", { party: userTypeId, id: data.id, manualUserEmail: userEmail })
      .then((result) => {
        toastDisplay("PO Request has been Accepted successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        toastDisplay(e, "error");
        console.log("error in acceptPO", e);
      });
  }

  // Update Finance form
  function updateDiscounting() {

    setshowLoader(true)
    delete data.doc
    // console.log('data', data);
    // console.log('file', file);

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("amount", data.amount);
    formData.append("creditDays", data.creditDays);
    formData.append("interestRate", data.interestRate);
    formData.append("fintnc", data.fintnc);
    formData.append("otherDocMetaData", data.otherDocMetaData);
    formData.append("sellerId", data.sellerId);
    formData.append("lenderId", data.lenderId);
    formData.append("buyerId", data.buyerId);

    Object.keys(file).forEach((key) => {
      formData.append(key, file[key]);
    })

    call("POST", "updatePoDiscounting", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay("Discounting Updated successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in updatePoDiscounting", e);
      });
  }

  const NavigationButtons = ({ showNextButton }) => {
    return (
      <div className="col-md-12">
        {(financeDetails.type === 1 && ((tab === 0 && showNextButton) || financeDetails.page < 2)) ?
          <button type="button" className="btn btn-success btn-sm float-right" onClick={() => prehandleForward()}>
            Next<i className="fa fa-chevron-right pl-2" aria-hidden="true"></i>
          </button> :

          data.sellerStatus === 2 && data.lenderStatus === 2 ?
            <button type="button" className="btn btn-success btn-sm border border-success float-right" disabled>
              <b> Approved </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            </button> :

            ((data.sellerStatus === 2 && data.sellerId === userId && data.lenderStatus === 1) || (data.sellerStatus === 1 && data.lenderId === userId && data.lenderStatus === 2)) ?
              <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" disabled>
                <b> Approved By You</b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </button> :

              ((data.sellerStatus === 1 && data.sellerId === userId && data.status === 3) || (data.lenderStatus === 1 && data.lenderId === userId && data.status === 3 && data.sellerStatus === 2)) ?
                <>
                  {(amount / 1 === data.amount / 1) &&
                    <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={() => acceptPoDiscounting()}>
                      <b> Approve </b><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}
                  {(data.sellerStatus === 1 && data.lenderStatus === 1) &&
                    <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
                      <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}
                </> :

                ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.sellerId === userId) ?
                  <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                    <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  </button> :

                  ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.lenderId === userId) ?
                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                      <b> Send Offer </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button> :

                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={submitPOFinanceForm} disabled={data.dnc === "on" ? false : true} > <b> Apply </b>
                      <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}

        {(financeDetails.type === 1 && financeDetails.page > 0) &&
          <button type="button" className="btn btn-danger btn-sm float-left" onClick={() => { tab === 1 ? setTab(0) : backward() }}>
            <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
          </button>}
      </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {(financeDetails.status && financeDetails.type === 1 && financeDetails.page === 1) ?
        <div className="col-md-12 shadow bg-light pt-2 pb-5">
          {/* <h1 className="text-center"><u>Purchase Order Form</u></h1><br /> */}
          <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
              }}>Application Details</a>
            </li>
            <li>
              <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
              }}>Documents</a>
            </li>
          </ul>

          {tab === 0 ? (
            <div className="row">

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Applicant  {astrix} {toolTip('buyerId')}</label>
                  <div className="col-md-12">
                    <input type="text" className="form-control" name="applicant" value={data.applicantEmail} readOnly />
                    {error.applicantEmail ? <div class="text-danger error-contract">{error.applicantEmail}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Data Line {toolTip('dirDataLine')}</label>
                  <div className="col-md-12">
                    <select className="form-control" name="buyerType" value={buyerType} onChange={(event) => {
                      setBuyerType(event.target.value / 1)
                    }} disabled={financeDetails.info.action === "applyFinance" ? false : true}>
                      <option value="1" selected>Network</option>
                      <option value="2">Directory</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Select Buyer {astrix}  {toolTip('buyerId')}</label>
                  <div className="col-md-12">
                    <select className={"form-control" + (error.buyerId ? " border-danger" : "")} name="buyerId" value={data.buyerId + ":" + data.buyerName + ":" + data.buyerEmail} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                      <option value="" selected>--Select Buyer--</option>
                      {/* <option value="ME:manual" selected>(N/A) Enter Manually</option> */}
                      <optgroup label="My Buyers">
                        {counterPart.length > 0 ? counterPart.map((counter) => {
                          return (
                            <option value={counter.id + ':' + counter.buyerName + ':' + counter.buyerEmail} >{counter.buyerName}</option>
                          )
                        })
                          :
                          <option disabled>Not Available</option>}
                      </optgroup>
                      <optgroup label="Public Buyer">
                        {buyerArr.length > 0 ? buyerArr.map((buyer) => {
                          return (
                            <option value={buyer.id + ':' + buyer.company_name + ':' + buyer.user_name} >{buyer.company_name}</option>
                          )
                        })
                          :
                          <option disabled>Not Available</option>}
                      </optgroup>
                    </select>
                    {error.buyerId ? <div class="text-danger error-contract">{error.buyerId}</div> : ''}
                  </div>
                </div>
              </div>

              {(data.buyerId === "ME") && <>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Buyer Name (Manual Entry) {astrix} {toolTip('buyerName')}</label>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="buyerNameManual" value={data.buyerNameManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                      {error.buyerNameManual ? <div class="text-danger error-contract">{error.buyerNameManual}</div> : ''}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Buyer Address (Manual Entry) {astrix} {toolTip('buyerAddr')}</label>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="buyerAddrManual" value={data.buyerAddrManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                      {error.buyerAddrManual ? <div class="text-danger error-contract">{error.buyerAddrManual}</div> : ''}
                    </div>
                  </div>
                </div>
              </>}

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">PO No {astrix}  {toolTip('poRefrenceNo')}</label>
                  <div className="col-md-12">
                    <input type="text" className={"form-control" + (error.referenceNo ? " border-danger" : "")} name="referenceNo" value={data.referenceNo} placeholder="Enter PO Refrence No" onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                    {error.referenceNo ? <div class="text-danger error-contract">{error.referenceNo}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">PO Issue date {astrix}  {toolTip('poIssueDate')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.issueDate ? " border-danger" : "")} type="date" name="issueDate" value={data.issueDate} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                    {error.issueDate ? <div class="text-danger error-contract">{error.issueDate}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Currency {astrix}  {toolTip('loanCurrency')}</label>
                  <div className="col-md-12 ">
                    <select className={"form-control" + (error.contractAmountCurrency ? " border-danger" : "")} name="contractAmountCurrency" value={data.contractAmountCurrency + ":" + data.contractAmountCurrencyName} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                      <option value="">--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.id + ':' + currency.name} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {error.contractAmountCurrency ? <div class="text-danger error-contract">{error.contractAmountCurrency}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Contract Amount {astrix}  {toolTip('contractAmount')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.contractAmount ? " border-danger" : "")} type="number" step="0.01" name="contractAmount" value={data.contractAmount} placeholder="Enter Contract Amount" onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                    {error.contractAmount ? <div class="text-danger error-contract">{error.contractAmount}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row form-group">
                  <label className="col-md-12">Currency  {astrix} {toolTip('loanCurrency')}</label>
                  <div className="col-md-12 ">
                    <select className={"form-control" + (error.amountCurrency ? " border-danger" : "")} name="amountCurrency" value={data.amountCurrency + ":" + data.amountCurrencyName} onChange={handleChange} disabled>
                      <option value="">--Select Currency--</option>
                      {currencyData.map((currency) => {
                        return (
                          <option value={currency.id + ':' + currency.name} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                        )
                      })}
                    </select>
                    {error.amountCurrency ? <div class="text-danger error-contract">{error.amountCurrency}</div> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row form-group">
                  <label className="col-md-12">Requested Amount {astrix}  {toolTip('loanAmount')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.amount ? " border-danger" : "")} type="number" step="0.01" name="amount" value={data.amount} placeholder="Enter Finance Amount" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                    {error.amount ? <div class="text-danger error-contract">{error.amount}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Select Lender  {astrix} {toolTip('lenderId')}</label>
                  <div className="col-md-12">
                    {financeDetails.info.action !== "viewFinance" ?
                      <select className={"form-control" + (error.lenderId ? " border-danger" : "")} name="lenderId" id="select_lenderId" value={data.lenderId + ':' + data.lenderName + ':' + data.lenderUniqueId} onChange={handleChange} >
                        <option value="">--Select Lender--</option>
                        {/* {creditData.map((bank) => { */}
                        {bankMaster.map((bank) => {
                          return (
                            // <option value={bank.bank_id + ':' + bank.lendername + ':' + bank.id}>{bank.lendername}</option>
                            <option value={bank.id + ':' + bank.company_name + ':' + bank.email_id}>{bank.company_name}</option>
                          )
                        })}
                      </select>
                      :
                      <input type="text" name="lenderId" value={data.lenderName} className={"form-control" + (error.lenderId ? " border-danger" : "")} onChange={handleChange} disabled />}
                    <br />
                    {creditData.map((bank) => {
                      if (data.lenderUniqueId == bank.id) {
                        return (
                          <p>   Approved Finance Amount: {bank.approvedFinanceAmount}</p>
                        )
                      }
                    })
                    }
                    {error.lenderId ? <div class="text-danger error-contract">{error.lenderId}</div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="row form-group">
                  <label className="col-md-12">Interest Rate (<small>To be filled by Banker</small>){astrix}  {toolTip('interestRate')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.interestRate ? " border-danger" : "")} type="number" name="interestRate" value={data.interestRate} placeholder="Enter Interest rate" onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2} />
                    {error.interestRate ? <div class="text-danger error-contract"> {error.interestRate} </div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="row form-group">
                  <label className="col-md-12">Finance Credit Days {astrix}  {toolTip('loanCreditDays')}</label>
                  <div className="col-md-12">
                    <input className={"form-control" + (error.creditDays ? " border-danger" : "")} type="number" name="creditDays" value={data.creditDays} placeholder="Enter Finance Credit Days" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                    {error.creditDays ? <div class="text-danger error-contract"> {error.creditDays} </div> : ''}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row form-group">
                  <label className="col-md-12">Finance TNC (<small>To be filled by Banker</small>) {astrix}  {toolTip('fintnc')}</label>
                  <div className="col-md-12">
                    <textarea rows="4" className={"form-control" + (error.fintnc ? " border-danger" : "")} name="fintnc" value={data.fintnc} onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2}></textarea>
                    {error.fintnc ? <div class="text-danger error-contract"> {error.fintnc} </div> : ''}
                  </div>
                </div>
              </div>

              {creditData.map((bank) => {
                if (data.lenderUniqueId == bank.id) {
                  return (
                    <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                      <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Credit Line Details</h2>
                      <div className="accordionItemContent">
                        <div className="col-md-12 row">

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Requested Amount</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.credit_amount} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Approved Finance Amount</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.approvedFinanceAmount} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Funding Percentage</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingPercentage + '%'} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Funding Tenor</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingTenor} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Due Date Calculation From</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.duedateCalcFrom} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Interest Rate</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestRate + '%'} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Interest Collection</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestCollection} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Finance Request Percentage</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestPercentage + '%'} readOnly={true} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row form-group">
                              <label className="col-md-12">Finance Request Collection</label>
                              <div className="col-md-12">
                                <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestCollection} readOnly={true} />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                }
              })
              }
            </div>
          ) : null}

          <div className='row'>
            {tab === 1 ?
              (financeDetails.info.action === "applyFinance") ?
                <>
                  <div className="col-md-12">
                    <h3 className="pt-2 pb-2"><u>Supporting Documents</u></h3>
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className={"custum-control" + (error.poDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>PO Document {astrix}  {toolTip('poDocument')}</h3>
                        </li>
                        <li>
                          <div className="document-name-top">Valid Upto </div>
                          <div className="document-name-bottom">(If applicable )</div>
                        </li>
                        <li>
                          <input type="text" className="custum-control year" name={"poDocumentMM"} value={data.poDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                          <input type="text" className="custum-control year" name={"poDocumentYY"} value={data.poDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                        </li>
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="poDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.poDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.poDocument.name}`}</div></div> : ""}
                      {error.poDocument ? <div class="text-danger error-contract">{error.poDocument}</div> : ''}
                    </div>
                  </div>
                </> :
                <>
                  <div class="col-md-12">
                    <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                      <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Initial Documents</h2>
                      <div className="accordionItemContent">
                        <div className="col-md-12">
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th>Type of Document</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="200">Uploaded On</th>
                                  <th>Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.doc && data.doc.length) && docPreviewGenerator(data.doc)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div className={"accordionItem mt-3" + (accord['1'] ? " closed" : " open")}>
                      <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Framework Agreement</h2>
                      <div className="accordionItemContent">
                        <div className="col-md-12">
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="200">Uploaded On</th>
                                  <th width="200">Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data.frameworkDocList && data.frameworkDocList.length) && docAgreementPreview('Framework')}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div className={"accordionItem mt-3" + (accord['2'] ? " closed" : " open")}>
                      <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Exhibit Agreement</h2>
                      <div className="accordionItemContent">
                        <div className="col-md-12">
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="200">Uploaded On</th>
                                  <th width="200">Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : (data.status == 1 && userTypeId == 8) ? docUploadPreview('Exhibit') : ''}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div className={"accordionItem mt-3" + (accord['3'] ? " closed" : " open")}>
                      <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '3': !accord['3'] })}>Notice of Assignment</h2>
                      <div className="accordionItemContent">
                        <div className="col-md-12">
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="200">Uploaded On</th>
                                  <th width="200">Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : (data.exhibitStatus == 1 && (data.status == 2 || data.status == 3) && userTypeId == 8) ? docUploadPreview('NOA') : ''}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              : null}
          </div>

          {tab === 1 ? (
            <>
              <hr />
              <OtherDocs
                data={data}
                setData={setData}
                file={file}
                handleFiles={handleFiles}
                docLimit={2}
              />
            </>
          ) : null}
          <hr />
          <NavigationButtons showNextButton={true} />
        </div> :
        (financeDetails.status && financeDetails.type === 1 && financeDetails.page === 2) ?
          <>
            <div class="col-md-12 p-2">
              <h1 className="text-center"><u>Finance Aginst PO Form- Review</u></h1><br />
              <div className="row m-2">
                <div class="col-md-2 p-2">
                  <label>Applicant</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.applicantEmail} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Buyer</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.buyerNameManual ? data.buyerNameManual : data.buyerName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>PO No</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.referenceNo} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>PO Issue date</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.issueDate} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.contractAmountCurrencyName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Contract Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.contractAmount} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.amountCurrencyName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.amount} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Lender</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.lenderName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Interest Rate</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.interestRate} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Credit Days</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.creditDays} disabled />
                </div>

                <div className="col-md-12 text-left">
                  <div className="row form-group">

                    {financeDetails.info.action === "viewFinance" &&
                      <>
                        {/* <div className=''> */}
                        <div className="col-md-12 mt-3">
                          <h3 className="pt-2 pb-2"><u>Initial Documents</u></h3>
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th>Type of Document</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="150">Uploaded On</th>
                                  <th>Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.doc && data.doc.length > 0) && docPreviewGenerator(data.doc)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div class="col-md-6"> */}
                        <div className="col-md-12 mt-3">
                          <h3 className="pt-2 pb-2"><u>Framework Agreement</u></h3>
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="150">Uploaded On</th>
                                  <th>Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data.frameworkDocList && data.frameworkDocList.length) && docAgreementPreview('Framework')}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div class="col-md-6"> */}
                        <div className="col-md-12 mt-3">
                          <h3 className="pt-2 pb-2"><u>Exhibit Agreement</u></h3>
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="150">Uploaded On</th>
                                  <th>Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : (data.status == 1 && userTypeId == 8) ? docUploadPreview('Exhibit') : ''}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div class="col-md-6"> */}
                        <div className="col-md-12 mt-3">
                          <h3 className="pt-2 pb-2"><u>Notice of Assignment</u></h3>
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="150">Uploaded On</th>
                                  <th>Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : (data.exhibitStatus == 1 && (data.status == 2 || data.status == 3) && userTypeId == 8) ? docUploadPreview('NOA') : ''}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* </div> */}
                      </>}

                    {(file && Object.keys(file).length > 0) &&
                      <div className="col-md-12 row mt-3">

                        <h3 className="pt-2 pb-2 col-md-12 "><u>Added Documents</u></h3>
                        <div className="col-md-6">
                          <h3 className="col-md-12 pl-0 ml-0"><u>Document Name</u></h3>
                        </div>
                        <div className="col-md-6">
                          <h3 className="col-md-12  pl-0 ml-0"><u>File Name</u></h3>
                        </div>

                        {file && Object.keys(file).length ? Object.keys(file).map((doc) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">{doc.toUpperCase() || '--'}</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12 text-primary">{file[doc].name}</label>
                                </div>
                              </div>
                            </>
                          )
                        }) : ''}
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={handleChange} required checked={data.dnc === "on" ? true : false} />
              <label className="checkbox-smallLabel width-auto" for="checkDNC">
                <span>
                  I hereby declare that the given information by me is best in my
                  knowledge and I did not apply any loan against this contract.
                </span>
              </label>
            </div>
          </> : "oops something is wrong , your were not supposed to get here."
      }

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}

      {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refresh}
          setrefreshDoc={setrefresh} />}

      {financeDetails.page === 2 ? (
        <>
          <hr className="pt-2 pb-2 text-primary" />
          <NavigationButtons showNextButton={false} />
        </>
      ) : null}
    </>
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(POFinance)
