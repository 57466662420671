import React from 'react';
export const TabComponent = ({ title, tabs, selectedTab, onTabChange, showAstrix }) => {
  const astrix = <span className="required-field text-danger">*</span>
  return (
    <div>
      <h3>{title}{showAstrix ? astrix : null}</h3>
      <div className="d-inline-block"
        style={{
          "border-radius": "0.5rem",
          "border": "1px solid #c4c4c4"
        }}
      >
        {tabs.map((item, index) =>
          <label style={{ "border-radius": "0.5rem", "border": "0px" }}
            className={"py-2 px-4 mb-0 " + (index === selectedTab ? "text-white bg-primary" : "")}
            onClick={() => index != selectedTab ? onTabChange(index) : null}
          >{item}</label>)
        }
      </div>
    </div>
  )
}

export const ShippingTabComponent = ({ title, tabs, selectedTab, onTabChange }) => {
  return (
    <div className="text-center">
      <h5 className="fw-bold">{title}</h5>
      <div className="d-inline-block"
        style={{
          "border-radius": "0.5rem",
          "border": "1px solid #c4c4c4"
        }}
      >
        {tabs.map((item, index) =>
          <label style={{ "border-radius": "0.5rem", "border": "0px", backgroundColor: index === selectedTab ? "#30878E" : 'transparent' }}
            className={"py-2 px-4 " + (index === selectedTab ? "text-white " : "")}
            onClick={() => index != selectedTab ? onTabChange(index) : null}
          >{item}</label>)
        }
      </div>
    </div>
  )
}
