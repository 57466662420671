import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { setShowViewProfileModal } from '../../store/actions/action';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import Footer from '../partial/footer';
import ManageChannelProfile from './manageChannelProfile';
import ManageLeads from '../admin/manageLeads';
import ChannelPartnerProfileDetails from './channelPartnerProfileDetails';
import customStyles from '../dataTablesColumsMap/customTableCss';
import toastDisplay from '../../utils/toastNotification';

const columns = [
  {
    name: 'CP Company Name',
    selector: 'cp_company_name',
    sortable: false,
  },
  {
    name: 'CP Contact Person',
    selector: 'cp_contact_person',
    sortable: false,
  },
  {
    name: 'Company Name',
    selector: 'company_name',
    sortable: false,
  },
  {
    name: 'Contact Person',
    selector: 'contact_person',
    sortable: false,
  },
  {
    name: 'Email Id',
    selector: 'email_id',
    sortable: false,
  },
  {
    name: 'Contact Number',
    selector: 'contact_number',
    sortable: false,
  },
  {
    name: 'Address',
    selector: 'user_address',
    sortable: false,
  },
  {
    name: 'Country',
    selector: 'country_name',
    sortable: false,
  },
  {
    name: 'Total Buyers',
    selector: "total_buyers",
    sortable: false,
  }
];


const ChannelPartnerReferral = ({ userTokenDetails, setShowViewProfileModal, showViewProfileModal }) => {

  const [tab, setTab] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");
  const [completeButton, setcompleteButton] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [page, setPage] = useState(1)

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  useEffect(() => {
    setLoading(true)
    call('POST', 'getCPList', {})
      .then((result) => {
        console.log("result in getCPList===>", result)
        setChannelPartnerList(result)
        setLoading(false)
      })
      .catch((err) => {
        console.log("error:", err)
        setLoading(false)
      })

    // if (filterData.cpId) {
    setLoading(true)
    call('POST', 'getCPReferrals', { cpId: filterData.cpId, perPage, page })
      .then((result) => {
        console.log("result in getCPReferrals===>", result)
        setLoading(false)
        setTotalRows(result.totalCount)
        settableData(formatDataFortable(result.data))
      })
      .catch((err) => {
        console.log("error:", err)
        setLoading(false)
      })
    // }
  }, [filterData, page, perPage])

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj["cp_company_name"] = dataArray[i]["cp_company_name"]
      tempObj["cp_contact_person"] = dataArray[i]["cp_contact_person"]
      tempObj["company_name"] = dataArray[i]["company_name"]
      tempObj["contact_person"] = dataArray[i]["contact_person"]
      tempObj["email_id"] = dataArray[i]["email_id"]
      tempObj["contact_number"] = dataArray[i]["phone_code"] + " " + dataArray[i]["contact_number"]
      tempObj["user_address"] = dataArray[i]["user_address"]
      tempObj["country_name"] = dataArray[i]["country_name"]
      tempObj["total_buyers"] = dataArray[i]["buyersId"] ? dataArray[i]["buyersId"].split(",")?.length : 0
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    // console.log("handlePerRowsChange", newPerPage, page);
    setPerPage(newPerPage);
    setPage(page)
  };

  const handlePageChange = page => {
    setPage(page)
  };

  console.log('showViewProfileModal => ', showViewProfileModal);

  function ExportTableCSV() {

    if (totalRows / 1 == 0) {
      return toastDisplay("No data to download", "info")
    }

    let objectAPI = {
      cpId: filterData.cpId,
      perPage: totalRows,
      page: 1
    }

    call('POST', 'getCPReferrals', objectAPI).then((result) => {
      console.log('running getCPReferrals api-->', result);

      const element = result.data
      let csvString = "CP Company Name,CP Contact Person,Company Name,Contact Person,Email Id,Contact Number,Country,Total Buyers\r\n"

      for (let i = 0; i < element.length; i++) {
        let rowString = `${element[i].cp_company_name || 'NA'},
      ${element[i].cp_contact_person || 'NA'},
      ${element[i].company_name || 'NA'},
      ${element[i].contact_person || 'NA'},
      ${element[i].email_id || 'NA'},
      ${element[i].contact_number || 'NA'},
      ${element[i].country_name || 'NA'},
      ${element[i].buyersId ? element[i]["buyersId"].split(",")?.length : 0}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `channel_partner_referrals.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getCPReferrals', e);
    })
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------
  //JSX
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          <Sidebar state={'channelPartnerReferral'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 "} id="app-main-div">
            <Header
              title={'Channel Partner Referrals'}
              userTokenDetails={userTokenDetails}
            />

            <div class="d-md-flex mt-3 mb-3">
              <div class="ml-md-auto">
                <ul class="range-slider">
                  {userTypeId == 1 && <li>
                    <select className="form-control" name="supplierType" value={filterData.cpId} onChange={(event) => { setFilterData({ cpId: event.target.value }) }}>
                      <option value="" selected>--Select Channel Partner--</option>
                      {channelPartnerList && channelPartnerList.length > 0 && channelPartnerList.map((cp) => {
                        return (<option value={cp.tbl_user_id}>{`${cp.contact_person} (${cp.company_name})`}</option>)
                      })}
                    </select>
                  </li>}
                </ul>
              </div>
            </div>



            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                highlightOnHover
                // onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />
            <div className="float-left col-md-7 ">
              <div className="col-md-12 row">
                <div className="col-md-3">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div>



          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerReferral)