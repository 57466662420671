export default function validate(values, docs, selectedBuyer, financial) {

  let errors = {};

  // if (!values.lender) {
  //   errors.lender = 'Field Missing';
  // }
  if (!values.companyProfile) {
    errors.companyProfile = 'Field Missing';
  }
  if (!values.creditAmount) {
    errors.creditAmount = 'Field Missing';
  }
  if (!values.daysRequired) {
    errors.daysRequired = 'Field Missing';
  }

  // for (let i = 0; i < docs.data.length; i++) {
  //   if (!(docs.data[i].document) || docs.data[i].document === null) {
  //     errors[docs.data[i].document] = "Field Missing";
  //   }
  //   if (!([docs.data[i].document + ":49"])) {
  //     errors[docs.data[i].document + ":49"] = "Doc Missing";
  //   }
  // }

  // if (selectedBuyer.length == 0) {
  //   errors.selectedBuyer = 'Please select atleast any one buyer';
  // }

  if (financial.type === 'Recourse' && financial.data == null) {
    errors.financial = 'Please select atleast any one financial';
  }

  console.log(errors);
  return errors;
}
