import React, { useEffect, useState } from 'react';
import { setCreditlineDataBank, setCreditDocuments } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/creditLineUsersColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import UserFinDetailBank from './userFinDetailForBank';
import avatarUrl from '../../utils/makeAvatarUrl';
import ReactCountryFlag from "react-country-flag";
import moment from 'moment'
import { decryptData } from '../../utils/myFunctions';


const CreditLineViewer = ({ userTokenDetails, creditlineDataBank, setCreditlineDataBank, navToggleState, dispatch, CreditDocuments, setCreditDocuments }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [count, setCount] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [dbTypeData, setdbTypeData] = useState([]);
  const [searchKey, setsearchKey] = useState('');
  const [newBuyers, setnewBuyers] = useState([])
  const [onlyVisibleDetails, setOnlyVisibleDetails] = useState(false)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    let urlParams = window.location.href.split("?params=")
    if (urlParams[1]) {
      let userData = decryptData(urlParams[1]).split("::")
      setOnlyVisibleDetails(true)
      bankerAction(userData[0], userData[1], userData[2])
    }

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, filterData]);

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    if (userTypeId === 1) {
      call('GET', 'getNewlyAddedBuyerDetails').then((result) => {
        setnewBuyers(result.message)
      }).catch((e) => {
        console.log('error in getuserstypelist', e);
      })
    }
    //---------------------------------------------------------------------------------------------------------------------

  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);


  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": page,
      "resultPerPage": perPage,
      "searchUserType": filterData.searchUserType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getuserwithfindetail', objectAPI).then((result) => {
      console.log('running getuserwithfindetail api-->', result);
      settableData(formatDataFortable(result.userData))
      setTotalRows(result.countData)
      setCount(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserwithfindetail', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": page,
      "resultPerPage": newPerPage,
      "searchUserType": filterData.searchUserType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getuserwithfindetail', objectAPI).then((result) => {
      settableData(formatDataFortable(result.userData))
      setTotalRows(result.countData)
      setCount(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserwithfindetail', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.avatar = <img src={avatarUrl(dataArray[i].user_avatar ? dataArray[i].user_avatar : '--')} alt="Avatar" width="32" height="32" />
      tempObj.company_name = dataArray[i].company_name;
      tempObj.contact_person = dataArray[i].contact_person;
      tempObj.user_address = dataArray[i].user_address;
      tempObj.buyersNumber = dataArray[i].buyersNumber;

      // if (dataArray[i].credit_show_to !== null) {
      //   let show_to = dataArray[i].credit_show_to.split(',');
      //   for (let j = 0; j < show_to.length; j++) {
      //     if (userId == show_to[j]) {
      //       tempObj.credit_status = dataArray[i].credit_status;
      //       var inpDate = new Date(dataArray[i].credit_modified_at);
      //       let date = inpDate.getDate();
      //       let month = inpDate.getMonth() + 1;
      //       let year = inpDate.getFullYear();
      //       tempObj.created_at = date + '/' + month + '/' + year;
      //     }
      //   }
      // }
      tempObj.country_code = (<span className="shadow">
        <ReactCountryFlag
          countryCode={dataArray[i].country_code ? dataArray[i].country_code : ''}
          style={{ width: '25px', height: '25px' }} svg />
      </span>)
      tempObj.action = <button className="btn btn-success btn-sm" onClick={() => { bankerAction(dataArray[i].tbl_user_id, dataArray[i].company_name, dataArray[i].email_id) }} >
        <i class="fas fa-folder-open"></i>
      </button>
      tempObj.selleridentifier = dataArray[i]["identifier"]

      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters

  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchUserType").value = ''
    // document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };


  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {

    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "currentPage": 1,
      "resultPerPage": totalRows,
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getuserwithfindetail', objectAPI).then((result) => {
      console.log('running getuserwithfindetail api in csv-->', result);

      const dbResult = result.userData
      let csvString = "Company Name,Contact Person,Buyers Number,Address,Country,Industry Type\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].company_name},
      ${dbResult[i].contact_person},
      ${dbResult[i].buyersNumber},
      ${dbResult[i].user_address},
      ${dbResult[i].country_code},
      ${dbResult[i].industry_type}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Finance_User_Detail.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getLoans', e);
    })

  }

  function bankerAction(userId, userName, userEmail) {
    setCreditlineDataBank(true, { userId, userName, userEmail })
  }
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {onlyVisibleDetails ? null : (
            <Sidebar state={'creditlineview'} userTokenDetails={userTokenDetails} />
          )}
          {(creditlineDataBank.modal && onlyVisibleDetails) ?
            <UserFinDetailBank
              userTokenDetails={userTokenDetails} />
            : null}

          {!onlyVisibleDetails &&
            <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

              <Header
                title={
                  (CreditDocuments.info.hasOwnProperty('action') && CreditDocuments.info.action.hasOwnProperty('userid')) ? `Credit Documents` :
                    (creditlineDataBank.info.hasOwnProperty('allData')) ? `Credit ID (${creditlineDataBank.info.allData.credit_id})` :
                      (creditlineDataBank.modal) ? `Financial, Credit Line & KYC Details (${creditlineDataBank.info.userName})` :
                        'Users with Financial Details'}
                total={(creditlineDataBank.modal) ? "" : ("Total - " + count + " Users")}
                userTokenDetails={userTokenDetails} />


              {(creditlineDataBank.modal) ?
                <UserFinDetailBank
                  userTokenDetails={userTokenDetails} />
                :
                <>
                  <div className="d-md-flex mt-3 mb-3">

                    <div className="ml-auto">
                      <ul className="range-slider">
                        <li>
                          <div className="d-md-flex">

                            {/* <div className="mr-1 ">
                            <select className="form-control" name="searchUserType" id="searchUserType" value={filterData.searchUserType} onChange={(event) => { setFilterData({ searchUserType: event.target.value }) }}>
                              <option value="" selected>Select User Type</option>
                              {(dbTypeData.length) ? dbTypeData.map((item) => {
                                if (item.id > 2) {
                                  return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                                }
                              }) : ''}
                            </select>
                          </div> */}

                            <div className=" ml-1 position-relative input-margin">
                              <input name="search" placeholder="Search" className="form-control" value={searchKey} onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
                              <button type="button" onClick={() => {
                                setFilterData({
                                  ...filterData,
                                  "search": searchKey
                                })
                              }}><i class="fas fa-search fa-lg"></i></button>
                            </div>
                          </div>

                        </li>
                        <li>
                        </li>
                        <li>
                        </li>
                        <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

                      </ul>
                    </div>
                  </div>


                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={tableData}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                      persistTableHead
                      // expandableRows
                      // expandableRowsComponent={<TableExpandedComponent />}
                      // expandOnRowClicked
                      highlightOnHover
                      onSort={handleSort}
                      sortServer
                      striped
                      noHeader
                      customStyles={customStyles}
                    />
                  </div>
                  {newBuyers.length ? (
                    <>
                      <h2 className='my-4'>New Buyers Added in Last 48 Hrs</h2>
                      <table class="table text-center">
                        <thead>
                          <tr>
                            <th scope="col">Buyer Name</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Created on</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newBuyers.map((item) => (
                            <tr>
                              <td>{item.buyerName ? item.buyerName : "-"}</td>
                              <td>{item.company_name ? item.company_name : "-"}</td>
                              <td>{item.created_at ? moment(item.created_at).format('DD/MM/YYYY, hh:mm a') : "-"}</td>
                              <td>
                                <button className="btn btn-success btn-sm"
                                  onClick={() => { bankerAction(item.tbl_user_id, item.company_name, item.email_id) }} >
                                  <i class="fas fa-folder-open"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  {/* <hr />
                <div className="float-left col-md-7 ">
                  <div className="col-md-12 row">
                    <div className="col-md-3">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                        <i class="fas fa-file-csv"></i> Export CSV
                      </button>
                    </div>
                  </div>
                </div> */}

                </>
              }
            </main>}

        </div>
      </div>
      {/* footer start */}
      {onlyVisibleDetails ? null : (
        <Footer />
      )}
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditlineDataBank: state.creditlineDataBank,
    navToggleState: state.navToggleState,
    CreditDocuments: state.CreditDocuments,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
    setCreditDocuments: (modal, info) => { dispatch(setCreditDocuments({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditLineViewer)
