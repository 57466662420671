import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../../service';
import customStyles from '../../dataTablesColumsMap/customTableCss';
import Header from '../../partial/header';
import Sidebar from '../../partial/sidebar';
import columnNames from './rateListColumns';
import RatePopup from './ratePopup';
import moment from 'moment';
import Footer from '../../partial/footer';
import EditableGrid from '../../CommonComponent/EditableGrid';
import { TabComponent } from '../../CommonComponent/TabComponent';
import toastDisplay from '../../../utils/toastNotification';

const cargoTypeDD = [
  { "name": "Break Bulk" },
  { "name": "Bulk Cargo" },
  { "name": "Oversize Load" },
  { "name": "Liquid Cargo" },
  { "name": "Gas" },
  { "name": "Wheeled Carg" }
]

const containerTypeDD = [
  { "name": "20 Standard" },
  { "name": "40 Standard" },
  { "name": "40 High Cube" },
  { "name": "45 High Cube" },
  { "name": "20 Open Top" },
  { "name": "40 Open Top" },
  { "name": "20 Flatrack" },
  { "name": "40 Flatrack" },
  { "name": "20 Refrigerate" }
]

const vesselTypeDD = [
  { "name": "General Cargo" },
  { "name": "Bulk Carriers" },
  { "name": "Containership" },
  { "name": "Tanker Market" },
  { "name": "Specialized" }
]

const RateDashboard = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  const [showRateListPopup, toggleRateListPopup] = useState(false)
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [completeButton, setcompleteButton] = useState(0)
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });
  const [data, setData] = useState({
    "modeTab": 0,
    "typeTab": 0
  })
  const [error, setError] = useState({})
  const [dbData, setdbData] = useState({})
  const [searchkey, setSearchKey] = useState("");
  const [country, setCountry] = useState([])
  const [ports, setPorts] = useState({ origin: [], destination: [] })
  const [commCategorys, setcommCategorys] = useState([])

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const containerType = ["FCL", "LCL"]
  const vesselType = ['Voyage Charter', 'Time Charter', 'Bareboat Charter']

  useEffect(() => {
    getShippingRatesApi()
  }, [filterData, data.modeTab, data.typeTab])

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getCountryMaster', {}).then((result) => {
      setCountry(result)
      setshowLoader(false);
    }).catch((e) => {
      // console.log('error in getCountryMaster', e);
      setshowLoader(false);
    })
    call('GET', 'getcommoditycategory').then((result) => {
      setcommCategorys(result);
      setshowLoader(false)
    }).catch((e) => {
      // console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })
  }, [])

  const getCountryPorts = (type, country_name) => {
    if (!country_name) {
      setPorts({ ...ports, [type]: [] })
      return null
    }
    setshowLoader(true)
    call('POST', 'getCountryPorts', {
      country_id: country.filter(item => { if (item.name === country_name) { return item } })[0]["id"]
    }).then((result) => {
      setPorts({ ...ports, [type]: result })
      setshowLoader(false)
    }).catch((e) => {
      // console.log("Error while querying getCountryPorts:", e);
      setshowLoader(false)
    })
  }

  const getShippingRatesApi = () => {
    setshowLoader(true);
    call('POST', 'getShippingRates', {
      "created_by": userId,
      "getAllData": true,
      "mode": data.modeTab === 0 ? "Container" : "Vessel",
      "mode_type": data.modeTab === 0 ? containerType[data.typeTab] : vesselType[data.typeTab]
      // "sortColum": filterData.sortColum,
      // "sortDirection": filterData.sortDirection
      // "dataInSlot": (filterData.search || (filterData.from && filterData.to)) ? false : true,
      // "from": filterData.from,
      // "to": filterData.to,
      // "search": filterData.search
    }).then((result) => {
      // console.log('running getShippingRates api-->', result);
      // setdbData(result)
      // settableData(formatDataFortable(result.message))
      // setTotalRows(result.count)
      // setCount(result.count)
      setdbData(result.data)
      setshowLoader(false);
    }).catch((e) => {
      // console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })
  }

  const addUpdateRateList = rate => {
    setshowLoader(true);
    let req = {
      mode: data.modeTab === 0 ? "Container" : "Vessel",
      mode_type: data.modeTab === 0 ? containerType[data.typeTab] : vesselType[data.typeTab],
      data: [],
      created_by: userId
    }
    let RatesKeys = Object.keys(rate)
    for (let i = 0; i < RatesKeys.length; i++) {
      rate[RatesKeys[i]] = typeof (rate[RatesKeys[i]]) === "string" ? rate[RatesKeys[i]].trim() : rate[RatesKeys[i]]
    }
    req.data.push(rate)
    if (!rate.id) {
      call('POST', 'insertShippingRates', req).then((result) => {
        // console.log('inside insertShippingRates api-->', result);
        toastDisplay(result, "success")
        setshowLoader(false);
        getShippingRatesApi()
        // togglePopup(false)
      }).catch((e) => {
        // toastDisplay("Something went wrong", "error")
        setshowLoader(false);
      })
    }
    else {
      call('POST', 'updateShippingRates', req).then((result) => {
        // console.log('inside updateShippingRates api-->', result);
        toastDisplay(result, "success")
        setshowLoader(false);
        getShippingRatesApi()
        // togglePopup(false)
      }).catch((e) => {
        // toastDisplay("Something went wrong", "error")
        setshowLoader(false);
      })
    }
  }

  const handlePageChange = page => {
    // fetchTableData(page, {});
  };


  const handlePerRowsChange = async (newPerPage, page) => {
    // setLoading(true);
    // setPerPage(newPerPage);
    // let objectAPI = {
    //   "currentPage": page,
    //   "resultPerPage": newPerPage,
    //   "userId": userId,
    //   "type_id": userTypeId,
    //   "gridOnly": true
    // }

    // objectAPI = { ...objectAPI, ...filterData }

    // call('POST', 'getLoans', objectAPI).then((result) => {
    //   // console.log('running getLoans api-->', result);
    //   settableData(formatDataFortable(result.data))
    //   setTotalRows(result.count)
    //   setCount(result.count)
    //   setLoading(false);
    // }).catch((e) => {
    //   console.log('error in getLoans', e);
    //   setLoading(false)
    // })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
    // console.log("filter data", filterData);
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      // console.log("dataArray[i]===>", dataArray[i])
      let tempObj = {}

      tempObj.origin_port = dataArray[i].origin_port ? dataArray[i].origin_port : "NA"
      tempObj.destination_port = dataArray[i].destination_port ? dataArray[i].destination_port : "NA"
      tempObj.container_type = dataArray[i].container_type ? dataArray[i].container_type : "NA"
      tempObj.container_no = dataArray[i].container_no ? dataArray[i].container_no : "NA"
      tempObj.cargo_type = dataArray[i].cargo_type ? dataArray[i].cargo_type : "NA"
      tempObj.start_date = dataArray[i].start_date ? moment(dataArray[i].start_date).format("YYYY-MM-DD") : "NA"
      tempObj.end_date = dataArray[i].end_date ? moment(dataArray[i].end_date).format("YYYY-MM-DD") : "NA"
      tempObj.load_volume = dataArray[i].load_volume ? dataArray[i].load_volume : "NA"
      tempObj.load_weight = dataArray[i].load_weight ? dataArray[i].load_weight : "NA"
      tempObj.vessel_type = dataArray[i].vessel_type ? dataArray[i].vessel_type : "NA"
      tempObj.commodity_type = dataArray[i].commodity_type ? dataArray[i].commodity_type : "NA"
      tempObj.flexibility_days = dataArray[i].flexibility_days ? dataArray[i].flexibility_days : "NA"
      tempObj.price = dataArray[i].price ? dataArray[i].price : "NA"
      tempObj.created_at = dataArray[i].created_at ? moment(dataArray[i].created_at).format("YYYY-MM-DD") : "NA"
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function createRateListBody(data) {
    // console.log('data receved', data);
    return (
      data && data.map(item => {
        return (
          <li className={"d-flex mb-2 " + (item.mode === "Container" ? "green-bg" : "blue-bg")}>
            <div className="ntf_time">
              <label>{moment(item.created_at).format("YYYY-MM-DD hh:mm:ss")}</label>
            </div>
            <div className="ntf_content">
              <ul className="p-0 d-flex">
                <li className="col-md-4">
                  <label>{item.mode_type || "NA"}</label>
                  <span>{"Mode Type"}</span>
                </li>
                <li className="col-md-4">
                  <label>{item.origin_port || "NA"}</label>
                  <span>Origin Port</span>
                </li>
                <li className="col-md-4">
                  <label>{item.destination_port || "NA"}</label>
                  <span>Destination Port</span>
                </li>
                <li className="col-md-4">
                  <label>{item.container_type || "NA"}</label>
                  <span>Container Type</span>
                </li>
                <li className="col-md-4">
                  <label>{item.container_no || "NA"}</label>
                  <span>Container No</span>
                </li>
                <li className="col-md-4">
                  <label>{item.cargo_type || "NA"}</label>
                  <span>Cargo Type</span>
                </li>
                <li className="col-md-4">
                  <label>{item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : "NA"}</label>
                  <span>Start Date (yyyy-mm-dd)</span>
                </li>
                <li className="col-md-4">
                  <label>{item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : "NA"}</label>
                  <span>End Date (yyyy-mm-dd)</span>
                </li>
                <li className="col-md-4">
                  <label>{item.load_volume || "NA"}</label>
                  <span>Load Volume (cubic meter)</span>
                </li>
                <li className="col-md-4">
                  <label>{item.load_weight || "NA"}</label>
                  <span>Load Weight (Kg)</span>
                </li>
                <li className="col-md-4">
                  <label>{item.vessel_type || "NA"}</label>
                  <span>Vessel Type</span>
                </li>
                <li className="col-md-4">
                  <label>{item.commodity_type || "NA"}</label>
                  <span>Commodity Type</span>
                </li>
                <li className="col-md-4">
                  <label>{item.flexibility_days || "NA"}</label>
                  <span>Flexibility Days</span>
                </li>
              </ul>
            </div>
            <div className="ntf_mode">
              <label>{"Price - " + item.price + " $"}</label>
            </div>
          </li>
        )
      })
    )
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      applyFilter()
    }
  };

  function applyFilter() {
    setFilterData(filterData => ({ ...filterData, "search": searchkey }));
  }

  const handleChange = (event) => {
    event.persist();
    setFilterData(filterData => ({ ...filterData, [event.target.name]: event.target.value }));
  };

  function resetFilter() {
    setFilterData({ ...filterData, search: "", from: "", to: "" })
    setSearchKey("")
  }

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        {/* {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)} */}
        <div className='row'>
          <Sidebar state={'rate-list'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={'Rate List'}
              // total={'Total Finance Transactions'}
              userTokenDetails={userTokenDetails}
              onButtonClick={() => toggleRateListPopup(true)}
            />
            {showRateListPopup ? (
              <RatePopup togglePopup={toggleRateListPopup} userTokenDetails={userTokenDetails} />
            ) : null}

            <div className="row my-3">
              <div className="col-md-3">
                <TabComponent title={"Select Mode"} tabs={['Container', 'Vessel']}
                  showAstrix={true}
                  selectedTab={data.modeTab} onTabChange={(index) => {
                    setData({ ...data, modeTab: index, typeTab: 0 }); setError({});

                  }} />
              </div>
              <div className="col-md-5">
                <TabComponent title={"Select Type "} showAstrix={true}
                  tabs={data.modeTab === 1 ? vesselType : containerType}
                  selectedTab={data.typeTab} onTabChange={(index) => {
                    setData({ ...data, typeTab: index }); setError({});

                  }} />
              </div>
            </div>
            <EditableGrid headings={columnNames} conditionForColumn={"" + data.modeTab + data.typeTab + ""}
              data={dbData}
              onOriginCountryChange={(val) => getCountryPorts("origin", val)}
              onDestinationCountryChange={(val) => getCountryPorts("destination", val)}
              onDataSubmit={(row) => addUpdateRateList(row)}
              ddDataCountrys={country} ddDataOriginPorts={ports.origin} ddDataDestinationPorts={ports.destination}
              ddDataCommTypes={commCategorys}
              ddDataVesselTypes={vesselTypeDD}
              ddDataCargoTypes={cargoTypeDD} ddDataContainerTypes={containerTypeDD}
            />

            {/* <div class="table-responsive">
              <DataTable
                columns={columnNames}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                // expandableRows
                // expandableRowsComponent={<TableExpandedComponent />}
                expandOnRowClicked
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div> */}

            {/* <div className="d-md-flex mt-3 mb-3 notify_list">
              <div className="mr-md-auto">
                <ul className="view-slider">
                  <li className="green-bg">
                    <label>Container</label>
                  </li>
                  <li className="blue-bg">
                    <label>Vessel</label>
                  </li>
                </ul>
              </div>
              <div className="ml-md-auto relative">
                <ul className="range-slider">
                  <li>
                    <input placeholder="Search" name="search" className="form-control" value={searchkey} onChange={(event) => { setSearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                    <button onClick={() => applyFilter()}><i class="fas fa-search fa-lg"></i></button>
                  </li>
                  <li>
                    <label>From</label>
                    <input type="date" name="from" className="form-control" value={filterData.from} onChange={handleChange} />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" name="to" className="form-control" value={filterData.to} onChange={handleChange} />
                  </li>
                  <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => resetFilter()} /></li>
                </ul>
              </div>
            </div> */}

            {/* <div className="notification_listing">
              {(filterData.search || (filterData.from && filterData.to)) ?
                <>
                  <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}> {"Filtered Rates Between "}
                      {filterData.from ? filterData.from : "--"} to {filterData.to ? filterData.to : "--"}
                      {" with search "} {filterData.search ? filterData.search : "--"}</h2>
                    <div className="accordionItemContent">
                      <div className="nt_data">
                        <ul className="p-0  Ul-list">
                          {createRateListBody(dbData.data)}
                          {dbData.data && dbData.data.length ? "" :
                            <h1 className="accordionItemHeading">No Rates Found in current search :(, Try again with different keyword</h1>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </> :
                <div className="">
                  <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}> Today</h2>
                    <div className="accordionItemContent">
                      <div className="nt_data">
                        <ul className="p-0  Ul-list">
                          {createRateListBody(dbData.todayAddedRates)}
                          {dbData.todayAddedRates && dbData.todayAddedRates.length ? "" :
                            <h1 className="accordionItemHeading">No Rates Added For Today</h1>}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={"accordionItem" + (accorddata.accord_1 ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_1')}> Last 7 Days</h2>
                    <div className="accordionItemContent">
                      <div className="nt_data">
                        <ul className="p-0  Ul-list">
                          {createRateListBody(dbData.sevendayAddedRates)}
                          {dbData.sevendayAddedRates && dbData.sevendayAddedRates.length ? "" :
                            <h1 className="accordionItemHeading">No Rates Added in Last 7 Days</h1>}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={"accordionItem" + (accorddata.accord_2 ? " open" : " closed")}>
                    <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_2')}> Older</h2>
                    <div className="accordionItemContent">
                      <div className="nt_data">
                        <ul className="p-0  Ul-list">
                          {createRateListBody(dbData.olderAddedRates)}
                          {dbData.olderAddedRates && dbData.olderAddedRates.length ? "" :
                            <h1 className="accordionItemHeading">No Rates Found Here</h1>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div> */}
          </main>
        </div>
      </div>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(RateDashboard)