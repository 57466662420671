export default function validate(values) {

  let errors = {};

  if (values.companyContactNo) {
    if (isNaN(values.companyContactNo)) {
      errors.companyContactNo = 'Contact No Should be a number';
    } else if (values.companyContactNo.length < 10) {
      errors.companyContactNo = 'Contact No should be of atleast 10 digits';
    }
  }

  console.log(errors)
  return errors;
}
