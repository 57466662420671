import React, { useEffect, useState } from 'react';
import call from '../../service';
import { ToastContainer } from 'react-toastify';
import avatarUrl from '../../utils/makeAvatarUrl';


const ManageBlockedUsers = ({ userTokenDetails, }) => {

  const [showLoader, setshowLoader] = useState(false);
  const [blockedUsers, setblockedUsers] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true);
    call('POST', 'getblockedusers', { "userId": userId }).then((result) => {
      // console.log('running getnetworkrequests api-->', result);
      setblockedUsers(result)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in getnetworkrequests', e);
      setshowLoader(false);
    })
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function unBlockUser(data, index) {
    console.log("data==>", data)

    let postObject = {
      id: data.id,
      blocked_by: data.blocked_by,
      blocked_user: data.blocked_user,
      loggedUserId: userId
    }

    setshowLoader(true);
    call('POST', 'unblockuser', postObject).then((result) => {
      // console.log('running unblockuser api-->', result);
      let temp = [...blockedUsers]
      temp.splice(index, 1);
      setblockedUsers(temp)
      setshowLoader(false);
    }).catch((e) => {
      console.log('error in unblockuser', e);
      setshowLoader(false);
    })

  }

  function genBlockedBody() {
    let blockedUserBody = (blockedUsers && blockedUsers.length) ? blockedUsers.map((userData, index) => {
      return (
        <div className='col-md-12 row bg-light mt-3 mb-3 border-bottom border-success rounded'>
          <div className='col-md-1'>
            <img className="m-1" src={avatarUrl(userData.user_avatar)} alt="Avatar" width="50" height="50" />
          </div>
          <div className='col-md-2'>
            <label className="text-info">{userData.company_name ? userData.company_name : "NA"} </label>
          </div>
          <div className='col-md-2'>
            <label className="">{userData.usertype_pretty_name ? userData.usertype_pretty_name : "NA"} </label>
          </div>
          <div className='col-md-4'>
            <p className="date-format p-0 m-0">{userData.user_address ? userData.user_address : "NA"} </p>
          </div>
          <div className='col-md-2 text-center'>
            <button type="button" className="btn btn-sm btn-danger ml-1" onClick={() => unBlockUser(userData, index)}>
              Un-Block
            </button>
          </div>
        </div>
      )
    }) : ''

    return blockedUserBody
  }


  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      <div className="col-md-12 row">
        <h3 className="">You have Blocked the following users</h3>
      </div>
      <hr />

      <div className="">
        {genBlockedBody()}
      </div>

    </div>
  )
};



export default ManageBlockedUsers