import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import call from '../../service';
import toastDisplay from "../../utils/toastNotification";
import validate from '../../utils/validation_files/dashboardFinanceMode';

const ChangeFinanceMode = ({ userTokenDetails, setchangeFinanceMode }) => {

  const [data, setData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [error, setError] = useState({});
  // const [financeMode, setfinanceMode] = useState({ modal: true, data: {} });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null;

  useEffect(() => {
    if (userTypeId === 19) {
      call('POST', 'getFinanceType', { 'userId': userId }).then((result) => {
        console.log('result in getFinanceType', result);
        setData({ 'finance_type': result });

      }).catch((e) => {
        console.log('error in getFinanceType', e);
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitFinanceMode()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  }

  function submitFinanceMode() {
    setshowLoader(true)
    data.userId = userId;
    data.userTypeId = userTypeId;
    call('POST', 'setFinanceType', data).then((result) => {
      if (result) {
        setrefresh(refresh + 1);
        toastDisplay("Finance Updated Successfully", "success");
        setshowLoader(false)
        window.location.reload()
      }
      setchangeFinanceMode({ modal: false, data: {} })
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
      setchangeFinanceMode({ modal: false, data: {} })
    })
  }

  console.log('kkkkkkkkkkkk ', refresh);
  // JSX body
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className={"modal show"} id="Confirmation">
        <div className="modal-dialog modal-lg border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Finance Mode</h4>
              {data.finance_type &&
                <button type="button" className="close" onClick={() => { setchangeFinanceMode({ modal: false, data: {} }) }} >×</button>}
            </div>
            <div className="modal-body">

              <div className="col-md-12 text-center">
                <h3> Select Finance Mode </h3>
              </div>
              <hr className="pt-1 pb-1" />
              <div className="col-md-12">
                <div className="row">

                  <div className="col-md-12 text-center">
                    <select className={"form-control" + (error.finance_type ? " border-danger" : "")} name="finance_type" value={data.finance_type} onChange={handleChange}>
                      <option value="" selected>--Select Finance Type--</option>
                      <option value="Invoice Discounting">Invoice Discounting</option>
                      <option value="Letter of Credit (LC) Discounting">Letter of Credit (LC) Discounting</option>
                      {/* <option value="Freight Finance">Freight Finance</option> */}
                    </select>
                    {error.finance_type ? <div class="text-danger error-contract">{error.finance_type}</div> : ''}
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-info btn-sm" onClick={() => { preHandleSubmit() }} >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Redux
// Getting the global state from redux store.
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(mapStateToProps, mapDispatchToProps)(ChangeFinanceMode);