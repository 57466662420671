import React, { useEffect, useState } from 'react';
// import { setContractDetails } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import ContractDetail from '../contractDetails/contractDetails';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/paymentsListColumns';
import customStyles from '../dataTablesColumsMap/customTableCss';
import Footer from '../partial/footer';


const Payment = ({ userTokenDetails, clientType, cDetailsState, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [count, setCount] = useState(0)
  const [completeButton, setcompleteButton] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  useEffect(() => {

    //------------------------------------------------------------------

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, { userId: userId, type_id: userTypeId, activeType: clientType.type })
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, clientType, filterData, clientType.type]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState('');




  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "activeType": clientType.type,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getPayments', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.contractdata))
      setTotalRows(result.countdata.totalcount)
      setCount(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "activeType": clientType.type,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getPayments', objectAPI).then((result) => {
      settableData(formatDataFortable(result.contractdata))
      setTotalRows(result.countdata.totalcount)
      setCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.contract_name = dataArray[i].contract_name;
      tempObj.contract_number = dataArray[i].contract_number;
      tempObj.buyerbankname = dataArray[i].buyerbankname;
      tempObj.supplierbankname = dataArray[i].supplierbankname;
      tempObj.commodity = (dataArray[i].commodity_name) ? dataArray[i].commodity_name : "--";
      tempObj.quantity = (dataArray[i].quantity) ? `${dataArray[i].quantity} mt` : "--";
      tempObj.price = (dataArray[i].currency && dataArray[i].price) ? `${dataArray[i].currency.split(':')[1]}  ${(dataArray[i].price / 1)}` : "--"
      tempObj.totalPrice = (dataArray[i].currency && dataArray[i].price) ? `${dataArray[i].currency.split(':')[1]}  ${((dataArray[i].quantity / 1) * (dataArray[i].price / 1))}` : "--"
      tempObj.payment_type_pretty = dataArray[i].payment_type_pretty;
      tempObj.status = (dataArray[i].payment_status === 0) ? "Pending" : (dataArray[i].payment_status === 1) ? "Recieved" : "--";
      tempObj.payment_status = dataArray[i].payment_status
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 2) {
      setFilterData({
        ...filterData,
        onlyCompleted: true,
        onlyIncompleted: false
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyIncompleted: true
      })
    }
  };


  const reset = () => {
    setcompleteButton(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "activeType": clientType.type,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getPayments', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);

      const payments = result.contractdata
      let csvString = "Contract No.,Importer's Bank,Exporter's Bank,Commodity,Total Price,Payment Mode,Payment Status\r\n"

      for (let i = 0; i < payments.length; i++) {
        let rowString = `${payments[i].contract_number},
      ${payments[i].buyerbankname ? payments[i].buyerbankname : '--'},
      ${payments[i].supplierbankname ? payments[i].supplierbankname : '--'},
      ${payments[i].commodity_name ? payments[i].commodity_name + " | " + payments[i].quantity + "mt" : '--'},
      ${(payments[i].currency) ? payments[i].currency.split(':')[1] + " " + payments[i].total_amount : '--'},
      ${payments[i].payment_type_pretty ? payments[i].payment_type_pretty : '--'},
      ${payments[i].payment_status === 0 ? "Pending" : payments[i].payment_status === 1 ? "Received" : '--'}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Payments_Record_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getPaymets', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'payment'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            {cDetailsState.modal ?
              <ContractDetail /> :
              <>
                <Header
                  title={'Payments'}
                  total={'Total ' + count + ' Payments Transactions'}
                  userTokenDetails={userTokenDetails} />
                <div className="d-md-flex mt-3 mb-3">
                  <div className="filter-bg">
                    <ul>
                      <li className={"pilot-cont cursor-pointer " + (completeButton === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
                        <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Pending
            			</li>
                      <li className={"complete-cont cursor-pointer " + (completeButton === 2 ? "shadow" : '')} id="completeButton" onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
                        <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Received
                    </li>
                    </ul>
                  </div>

                  <div className="ml-md-auto relative">
                    <ul className="range-slider">
                      <li>
                        <input name="search" placeholder="Search" value={searchKey} onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
                        <button type="button" onClick={() => {
                          setFilterData({
                            ...filterData,
                            "search": searchKey
                          })
                        }}><i class="fas fa-search fa-lg"></i></button>
                      </li>
                      <li>
                        <label>From</label>
                        <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
                      </li>
                      <li>
                        <label>To</label>
                        <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
                      </li>
                      <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

                    </ul>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    // expandableRows
                    // expandableRowsComponent={<TableExpandedComponent />}
                    // expandOnRowClicked
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
                <div className="float-left col-md-7 ">
                  <div className="col-md-12 row">
                    <div className="col-md-3">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                        <i class="fas fa-file-csv"></i> Export CSV
                    </button>
                    </div>
                  </div>
                </div>
              </>}
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)
