import React, { useState } from 'react';
import call from '../../service';
import config from '../../config.json';
import toastDisplay from '../../utils/toastNotification';
import Countdown from 'react-countdown';

const LogInWithOtp = (props) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [otpSent, toggeleOtpSent] = useState(false);
  const [timerStatus, toggletimerStatus] = useState(false);

  var handleChange = (event) => {
    event.persist();
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  function showMessage() {
    if (errors.email) {
      return (<p className="text-danger error-contract">{errors.email}</p>);
    }
  }

  const sendOtp = (e) => {
    e.preventDefault()
    if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
      setErrors({ ...errors, email: 'Please enter a valid email id.' })
      return;
    }
    errors.email = '';
    call('post', 'sendOtp', { email: values.email, baseUrl: config.baseUrl }).then((res) => {
      toggeleOtpSent(true)
      toggletimerStatus(true)
      toastDisplay("OTP has been sent to your registered email id", "success");
      setValues({ ...values, "otp": "" })
    }).catch((e) => {
      toastDisplay(e, "error");
    });
  }

  const validateOtp = (e) => {
    e.preventDefault()
    props.login(
      {
        username: values.email,
        otp: values.otp
      }
    )
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      toggletimerStatus(false)
      return null
    } else {
      return <span>{"in "}{minutes}:{seconds}{" mins"}</span>;
    }
  };

  function showLoginWithOtpScreen() {
    return (
      <>
        <div className="form-group">
          <input type="email" id="inputEmail" className={" form-control" + (!errors.email ? '' : ' border-danger')} name="email" placeholder="Email ID"
            autoFocus={true} disabled={otpSent}
            onChange={handleChange} />
          {showMessage()}
          {otpSent ? (
            <input id="otp" type='number'
              className={" form-control mt-4"} name="otp" placeholder="OTP"
              autoFocus={true} maxLength={6} value={values.otp}
              onChange={handleChange} />
          ) : null}
        </div>
        {otpSent ? (
          <label className='text-dark'>Not received OTP?
            <button disabled={timerStatus}
              className="ml-auto btn btn-sm btn-link"
              onClick={(e) => { sendOtp(e) }}>Resend again</button>
            {timerStatus ? (
              <Countdown
                date={Date.now() + 300000}
                renderer={renderer}
              />
            ) : null}</label>
        ) : null}
        <button type="button" className="btn btn-lg btn-primary btn-block btn-sm"
          onClick={(e) => { otpSent ? validateOtp(e) : sendOtp(e) }}
          disabled={otpSent ? !(values.otp && values.otp.length == 6) : false}
          noValidate>
          <>{!otpSent ? "Send OTP" : "Verify OTP"}</>
        </button>
      </>
    )
  }

  return (
    <>
      <div className="authfy-heading">
        <h3 className="auth-title">Log in with OTP</h3>
      </div>
      {showLoginWithOtpScreen()}
    </>
  )

}

export default LogInWithOtp;