import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import toolTipText from '../../utils/toolTips/masterContract.json'
import call from '../../service';
import avatarUrl from '../../utils/makeAvatarUrl';
import ReactCountryFlag from 'react-country-flag';
import { dateFormatter_DatePicker, todayDate_DatePicker } from '../../utils/dateFormaters';
import AddPortComponent from '../utilComponents/addPortComponent';
import SpecificationsForm from '../specifications/specificationForm'
import price_in_words from '../../utils/wordtoNumber';
import toastDisplay from '../../utils/toastNotification';
import TncForm from '../tncComponents/tncForms';
import DocumentACL from '../tradeContract/documentACL';

const POGrid = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  // States and variables
  const [showPopup, togglePopup] = useState(false)
  const [data, setData] = useState({
    'contractType': 'FOB',
    "paymentMode": 1,
    'quantity': 0
  });
  const [supplierInfo, setSupplierInfo] = useState({})
  const [dbSupplier, setDbSupplier] = useState([])
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [contract, setContract] = useState({
    state: 0,
    col: 'FOB'
  });
  const [viewAccordion, setViewAccordion] = useState(0);
  const [commodity, setCommodity] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [changelogs, setchangelogs] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState({ state: false, name: '', location: '' });
  const [spec, setSpecification] = useState({});
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agency, setAgency] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [buyerBank, setBuyerBank] = useState([]);
  const [sellerBank, setSellerBank] = useState([]);
  const [refresh, setrefresh] = useState(0)
  const [dataUpdate, setDataUpdate] = useState({});
  const [dataTnc, setdataTnc] = useState({});
  const [tabing, settabing] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [currencyData, setcurrencyData] = useState([]);
  const [paymentModes, setpaymentModes] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierAvatar, setsupplierAvatar] = useState('');
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [countryData, setcountryData] = useState([]);
  const [contractEditMode, setcontractEditMode] = useState(false);
  const [refreshSupplier, setrefreshSupplier] = useState(0)
  const [supplierSearch, setsupplierSearch] = useState('')
  const [editCount, setEditCount] = useState(0);
  // const [extraTnc, setextraTnc] = useState([]);
  const [docCategoryACL, setdocCategoryACL] = useState({});
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [specChange, setspecChange] = useState(0)
  const [tncChange, settncChange] = useState(0)
  const [specOrder, setspecOrder] = useState([])
  const [tncOrder, settncOrder] = useState([])

  const astrix = <span className="required-field text-danger">*</span>
  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    seterrorData({ ...errorData, [event.target.name]: "" })
  }

  const handleContract = (con, val) => {
    setData(data => ({ ...data, 'contractType': val }))
    setContract({ state: con, col: val });

    let contractTypecheck = val;
    if (contractTypecheck === "FOB") {
      getTNConChange("fobTNCcoal")
    } else if (contractTypecheck === "CIF") {
      getTNConChange("cifTNCcoal")
    }
  }

  function getTNConChange(type) {
    call('POST', 'getjsonfromdb', { "jsonFor": type }).then((result) => {
      // console.log("getjsonfromdb:=>", result);
      setdataTnc(result.json_data)
      settncOrder(result.order_array.split(":"))
    }).catch((e) => {
      console.log("Error while querying getjsonfromdb:", e);
    })
  }

  function handleSupplierShow() {
    // WIP
  }

  function getSupplierCommodities(event, supplierId) {
    event.persist();
    setData(data => ({ ...data, "commodity": "" }));
    if (!event.target.value) {
      setsupplierCommodityList([])
      return null
    }
    call('post', 'getusercommoditylist', { "userId": supplierId, "commCatId": event.target.value === "All" ? "" : event.target.value }).then((result) => {
      // console.log("api result in getusercommoditylist->", result)
      setsupplierCommodityList(result)
    }).catch((e) => {
      console.log('error in getusercommoditylist', e);
    })
  }

  const verifyDate = (event) => {
    event.persist();
    if (event.target.name === "laycanStart") {

      if (new Date(event.target.value) < new Date()) {
        setData(data => ({ ...data, [event.target.name]: todayDate_DatePicker(true) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")
      }
      //  else if (new Date(event.target.value) > new Date(data.laycanEnd)) {
      //   let tempDate = new Date(data.laycanEnd)
      //   tempDate.setDate(tempDate.getDate() - 10)
      //   setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
      //   seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
      //   toastDisplay("Incorrect Date , Min date can be of today's date", "warn")
      // }

    } else if (event.target.name === "laycanEnd") {
      if (new Date(event.target.value) < new Date()) {
        let tempDate = new Date()
        tempDate.setDate(tempDate.getDate() + 10)
        setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")

      } else if (new Date(event.target.value) < new Date(data.laycanStart)) {
        let tempDate = new Date(data.laycanStart)
        tempDate.setDate(tempDate.getDate() + 10)
        setData(data => ({ ...data, [event.target.name]: dateFormatter_DatePicker(tempDate) }));
        seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
        // toastDisplay("Incorrect Date , Min date can be of today's date", "warn")

      }
    }
  };

  //Ports List
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }

  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
    seterrorData(errorData => ({ ...errorData, "Specification": "" }))
    setEditCount(editCount + 1)
    setspecChange(specChange + 1)
  }

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value / 1 === value / 1) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }

  }

  function getUserArray() {
    let usersArray = [
      {
        "userId": data.exporter_id,
        "userName": data.supplierName ? data.supplierName : data.supplier
      },
      {
        "userId": data.importer_id,
        "userName": data.buyerName ? data.buyerName : data.buyerEmail
      },
      {
        "userId": data.investigationAgencyExporter,
        "userName": getSelectName('investigationAgencyExporter', data.investigationAgencyExporter)
      },
      {
        "userId": data.investigationAgencyImporter,
        "userName": getSelectName('investigationAgencyImporter', data.investigationAgencyImporter)
      },
      {
        "userId": data.investigationAgencythird,
        "userName": getSelectName('investigationAgencythird', data.investigationAgencythird)
      },
      {
        "userId": data.commodityInsurer,
        "userName": getSelectName('commodityInsurer', data.commodityInsurer)
      },
      {
        "userId": data.tradeInsurer,
        "userName": getSelectName('tradeInsurer', data.tradeInsurer)
      },
      {
        "userId": data.supplierBank,
        "userName": getSelectName('supplierBank_select', data.supplierBank)
      },
      {
        "userId": data.buyerBank,
        "userName": getSelectName('buyerBank_select', data.buyerBank)
      }
    ]

    let mapArray = [], finalArray = []
    for (let i = 0; i < usersArray.length; i++) {
      // console.log("here is id usersArray[i]===>", usersArray[i])
      if (usersArray[i].userId / 1 !== 0) {
        if (!mapArray.includes(usersArray[i].userId)) {
          finalArray.push(usersArray[i])
          mapArray.push(usersArray[i].userId)
        }
      }
    }
    // console.log("here is id finalArray===>", finalArray)
    return finalArray

  }


  function loadIndexPrice(event) {
    event.persist();
    if ((event.target.value / 1) === 2) {
      call('POST', 'getcommodityindexprice', { commodity: data.commodity ? data.commodity.split(':')[1] : data.commodity ? data.commodity.split(':')[1] : 0 }).then((result) => {
        // console.log("result in getcommodityindexprice api->", result)
        setData(data => ({
          ...data,
          price: result.lastPrice,
          currency: result.priceUnit
        }));
      }).catch((e) => {
        console.log("Error while querying getcommodityindexprice:", e);
        toastDisplay(e, "info")
      })
    }
  }


  function handleInc() {
    document.getElementById('quantity').stepUp(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) + 1
    })
    // if (contractState.info.modalMode === "edit") {
    setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) + 1 }));
    // }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }

  function handleDec() {
    document.getElementById('quantity').stepDown(1)
    setData({
      ...data, quantity: parseInt(data.quantity, 10) - 1
    })
    // if (contractState.info.modalMode === "edit") {
    setDataUpdate(dataUpdate => ({ ...dataUpdate, quantity: parseInt(data.quantity, 10) - 1 }));
    // }
    seterrorData(errorData => ({ ...errorData, quantity: "" }))
    setEditCount(editCount + 1)
  }

  //Payment options list
  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <>
          <div className="switch-field ml-1 mr-1">
            <input type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id} checked={(data.paymentMode / 1 === value.id) ? true : false} onChange={handleChange} />
            <label for={"paymentMode" + value.id}>{value.payment_type_pretty}</label>
          </div>
        </>
      )
    }) : ''
    return PaymentOptionslist
  }

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let tncdataElement = { ...dataTnc }
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    seterrorData(errorData => ({ ...errorData, "tnc": "" }))
    setEditCount(editCount + 1)
    settncChange(tncChange + 1)
  }



  return (
    <>
      <div className="container-fluid">
        <div className='row'>
          <Sidebar state={'po'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={'Create Contract'}
              // total={'Total Finance Transactions'}
              userTokenDetails={userTokenDetails}
              onButtonClick={() => togglePopup(true)}
            // createFinance={{ flag: true }}
            />

            <div className={"modal" + (showPopup ? " show" : "")} id="Confirmation">
              <div className="modal-dialog modal-xl border-inner" id="parent_class">
                <div className="modal-content">
                  <div className="modal-header primary">
                    <h4 className="modal-title text-white">Create Contract</h4>
                    <button type="button" className="close" onClick={() => { togglePopup(false) }}>×</button>
                  </div>
                  <div className="modal-body calc-modal">
                    <div className="calc-inner-modal">

                      <div className="d-flex">
                        <div className="col-md-12 row m-0 p-0">
                          <div className="col-md-6 row">
                            <div className="col-md-5">
                              <h3>Contract Name {astrix} {toolTip('contractAlias')}</h3>
                            </div>
                            <div className="col-md-7 pr-0">
                              <div className="form-group">
                                <input type="text" name="contractAlias" placeholder="Enter Contract Name" value={data.contractAlias} className={"form-control" + (!errorData.contractAlias ? '' : ' border-danger')} onChange={handleChange} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 row">
                            <div className="col-md-6">
                              <h3>Contract Type {astrix} {toolTip('contractType')}</h3>
                            </div>
                            <div className="ml-auto">
                              <div className="switch-field">
                                <input type="radio" id="fob" name="contractType" value="fob" defaultChecked={contract.state === 0 ? 'checked' : ''} onClick={() => handleContract(0, 'FOB')} />
                                <label for="fob">FOB</label>
                                <input type="radio" id="cif" name="contractType" value="cif" defaultChecked={contract.state === 1 ? 'checked' : ''} onClick={() => handleContract(1, 'CIF')} />
                                <label for="cif" className="mr-0">CIF</label>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-12 border-top border-bottom pt-3">
                            <div className="form-group row d-flex align-items-center">
                              <label className="col-md-3 m-0">
                                {"Seller/Exporter"} {astrix} {toolTip('supplier')}
                              </label>
                              <div className="ml-auto pr-3 mb-0" >
                                <div id="SelectValue"> {!selectedSupplier.state && <div className="btn btn-primary" onClick={() => handleSupplierShow()}>
                                  {"Select Seller/Exporter"}</div>}
                                  {selectedSupplier.state && <div className="chat-message">
                                    <img src={avatarUrl(supplierAvatar)} alt="Avatar" width="32" height="32" />
                                    <div className="chat-message-content clearfix" >
                                      <h5>{selectedSupplier.name}</h5>
                                      <div className="col-md-12 row">
                                        <p className="date-format p-0 m-0 col-md-11" style={{ "max-width": "500px", "font-size": "10px" }}>{selectedSupplier.address}</p>
                                        <p className="date-format p-0 m-0 col-md-1">{selectedSupplier.location ?
                                          <span className="shadow">
                                            <ReactCountryFlag
                                              countryCode={selectedSupplier.location ? selectedSupplier.location : ''}
                                              style={{ width: '25px', height: '25px' }} svg />
                                          </span> : '--'}
                                        </p>
                                      </div>

                                    </div>
                                    <button className="edit-icon" onClick={() => handleSupplierShow()}><i class="fas fa-edit text-primary"></i> </button>
                                  </div>}
                                  {errorData.supplier && <p className="text-danger error-contract">{errorData.supplier}</p>}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="modal-padding">
                            {!selectedSupplier.state &&
                              <>
                                <h3 className="mt-3"> Commodity {astrix}  {toolTip('commodity')}</h3>
                                <div className='row mx-2'>
                                  <div className="col-md-4 pl-0 mt-2 mb-2">
                                    <select className={" form-control" + (!errorData.commCatId ? '' : ' border-danger')} name="commCatId" value={data.commCatId} onChange={
                                      (event) => {
                                        handleChange(event);
                                        getSupplierCommodities(event, selectedSupplier.supplierId);
                                      }}>
                                      <option value="" selected>--Select Commodity Type/Category--</option>
                                      <option value="All">Show All Commodity</option>
                                      {
                                        commCategory.length ? commCategory.map((item, index) => {
                                          return (<option value={item.id}>{item.category}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.commCatId && <p className="text-danger error-contract ml-2">{errorData.commCatId}</p>}
                                  </div>
                                  <div className="col-md-4 pl-0 mt-2 mb-2">
                                    <select className={" form-control" + (!errorData.commodity ? '' : ' border-danger')} name="commodity"
                                      value={data.commodity}
                                      onChange={
                                        (event) => {
                                          handleChange(event);
                                        }}>
                                      <option value="">--Select Commodity Name</option>
                                      {
                                        supplierCommodityList.length ? supplierCommodityList.map((item, index) => {
                                          return (<option value={item.commodity_pretty_name + ':' + item.id}>{item.commodity_pretty_name}</option>)
                                        }) : ''
                                      }
                                    </select>
                                    {errorData.commodity && <p className="text-danger error-contract ml-2">{errorData.commodity}</p>}
                                  </div>
                                </div>
                              </>}
                          </div>

                          <div className="modal-padding">
                            {!commodity && <>
                              <hr className="mb-3" />
                              <div id="Check-box">
                                <div className=" mt-2">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="">Laycan Start Date {astrix} {toolTip('laycanStart')}</label>
                                      <input type="date" min={todayDate_DatePicker(true)} name="laycanStart" value={data.laycanStart}
                                        className={" form-control" + (!errorData.laycanStart ? '' : ' border-danger')} onChange={handleChange} onBlur={verifyDate} required />
                                      {errorData.laycanStart && <p className="text-danger error-contract">{errorData.laycanStart}</p>}
                                    </div>
                                    <div className="col-md-6">
                                      <label className="">Laycan End Date {astrix} {toolTip('laycanEnd')}</label>
                                      <input type="date" min={todayDate_DatePicker(true)} name="laycanEnd" value={data.laycanEnd}
                                        className={" form-control" + (!errorData.laycanEnd ? '' : ' border-danger')} onChange={handleChange} onBlur={verifyDate} required disabled={data.laycanStart ? false : true} />
                                      {errorData.laycanEnd && <p className="text-danger error-contract">{errorData.laycanEnd}</p>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="mt-3 mb-3" />

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-md-12">Loading Country {astrix} {toolTip('loadingCountry')}</label>
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!errorData.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={data.loadingCountry}
                                        onChange={(event) => { handleChange(event); loadPort(event); }}>
                                        <option value="" selected>--Select country--</option>
                                        {/* <CountryList /> */}
                                        {
                                          countryData.length ? countryData.map((item, index) => {
                                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                          }) : ''
                                        }
                                      </select>
                                      {errorData.loadingCountry && <p className="text-danger error-contract">{errorData.loadingCountry}</p>}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-md-12">Loading Port {astrix} {toolTip('loadingPort')}</label>
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!errorData.loadingPort ? '' : ' border-danger')} name="loadingPort" value={data.loadingPort} onChange={handleChange}>
                                        <option value="" selected>--Select loading port--</option>
                                        {portLoading.map((portLoading) => {
                                          return (
                                            <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                          )
                                        })}
                                      </select>
                                      {errorData.loadingPort && <p className="text-danger error-contract">{errorData.loadingPort}</p>}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-md-12">Unloading Country {astrix} {toolTip('unloadingCountry')}</label>
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!errorData.unloadingCountry ? '' : ' border-danger')} name="unloadingCountry" value={data.unloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                                        <option value="" selected>--Select country--</option>
                                        {/* <CountryList /> */}
                                        {
                                          countryData.length ? countryData.map((item, index) => {
                                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                          }) : ''
                                        }
                                      </select>
                                      {errorData.unloadingCountry && <p className="text-danger error-contract">{errorData.unloadingCountry}</p>}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-md-12">Unloading Port {astrix} {toolTip('unloadingPort')}</label>
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!errorData.unloadingPort ? '' : ' border-danger')} name="unloadingPort" value={data.unloadingPort} onChange={handleChange}>
                                        <option value="" selected>--Select Unloading port--</option>
                                        {
                                          portUnloading.map((portUnloading) => {
                                            return (
                                              <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                                            )
                                          })
                                        }
                                      </select>
                                      {errorData.unloadingPort && <p className="text-danger error-contract">{errorData.unloadingPort}</p>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <AddPortComponent countryList={countryData} />
                            </>}
                          </div>

                          <div className="">
                            {/* {miniPreview()} */}
                            <hr />
                            {/* Commodity Form Component Start */}
                            {errorData.Specification && <p className="text-danger text-center">{errorData.Specification}</p>}
                            {!((data.commodity || data.commodity_id) && activeClass === 2) && <SpecificationsForm
                              commodity={data.commodity ? data.commodity.split(':')[1] : data.commodity_id}
                              spec={spec}
                              handleSpecification={handleSpecification}
                              setSpecification={setSpecification}
                              specOrder={specOrder}
                              setspecOrder={setspecOrder}
                              // contMode={contractState.info.modalMode}
                              editCount={editCount}
                              setEditCount={setEditCount}
                              editLevel={true}
                              masterid={false} />}
                            {/* Commodity Form Component End */}
                          </div>


                          <div className="">
                            {/* {miniPreview()} */}
                            <hr />
                            <div className="modal-padding">
                              <div className="row">
                                <label className="col-md-3">Commodity Price/mt ( metric tonne* ) {astrix} {toolTip('priceType')}</label>
                                <div className="col-md-3">
                                  <div className="form-group">

                                    <select className={" form-control" + (!errorData.priceType ? '' : ' border-danger')} name="priceType" value={data.priceType}
                                      onChange={(event) => { handleChange(event); loadIndexPrice(event) }}>
                                      <option value="" selected>--Select Price Type--</option>
                                      <option value="1"> Fixed Price</option>
                                      <option value="2">Index Price</option>
                                    </select>
                                    {errorData.priceType && <p className="text-danger error-contract">{errorData.priceType}</p>}
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="row">
                                    {(data.priceType / 1) === 2 ? <>
                                      <div className="col-md-5">
                                        <div className="form-group">
                                          <input type="number" step="0.01" name="price" value={data.price} placeholder="Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false} />
                                          {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                        </div>
                                      </div>
                                      <span className="col-md-2">+-</span>
                                      <div className="col-md-5">
                                        <div className="form-group">
                                          <input type="number" name="priceTolerance" value={data.priceTolerance} placeholder="" className={" form-control" + (!errorData.priceTolerance ? '' : ' border-danger')} onChange={handleChange} />
                                          {errorData.priceTolerance && <p className="text-danger error-contract">{errorData.priceTolerance}</p>}
                                        </div>
                                      </div>
                                    </> : <>
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <input type="number" step="0.01" name="price" value={data.price} placeholder="Enter Price" className={" form-control" + (!errorData.price ? '' : ' border-danger')} onChange={handleChange} />
                                          {errorData.price && <p className="text-danger error-contract">{errorData.price}</p>}
                                        </div>
                                      </div>
                                    </>}
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <select className={" form-control" + (!errorData.currency ? '' : ' border-danger')} name="currency" value={data.currency} onChange={handleChange} disabled={(data.priceType / 1) === 2 ? true : false}>
                                      <option value="" selected>--Select Currency--</option>
                                      {currencyData.map((currency) => {
                                        return (
                                          <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                                        )
                                      })}
                                    </select>
                                    {errorData.currency && <p className="text-danger error-contract">{errorData.currency}</p>}
                                  </div>
                                </div>

                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div className="form-group d-flex align-items-center mb-0 pb-2">
                                    <label for="message-text" className="col-form-label">Add Quantity {astrix} {toolTip('quantity')}</label>
                                    <span className="ml-3">(mt)</span>
                                    <div className="ml-auto">
                                      <button type="button" onClick={() => { handleDec() }} value="-" className="edit-icon"><i class="fas fa-minus text-primary"></i></button>
                                      <input type="number" name="quantity" placeholder="0" min="0" size="1" id="quantity" value={data.quantity} className={" text-center increment" + (!errorData.quantity ? '' : ' border-danger')} onChange={handleChange} />
                                      <button type="button" onClick={() => { handleInc() }} value="+" className="edit-icon"><i class="fas fa-plus text-primary"></i></button>
                                    </div>
                                  </div>
                                  <p className="text-primary">(Tolerance = +/- 10%)</p>
                                  {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                                </div>
                              </div>
                              <hr />
                              <div className="row d-flex mt-5">
                                <label className="col-md-6">Total Amount = Commodity Price/mt x Quantity</label>
                                <div className="col-md-6 ">
                                  <h4 className="text-primary text-right" ><span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[1]) : ''} </span>{data.price && data.quantity ? Intl.NumberFormat('en-IN').format(data.price * data.quantity) : 0}</h4>
                                  <p className="text-primary text-right" >{data.price && data.quantity ? price_in_words(data.price * data.quantity) : ''}<span >{data.price && data.quantity && data.currency ? (data.currency.split(':')[0]) : ''} </span></p>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="">
                            {/* {miniPreview()} */}
                            {/* <hr /> */}
                            <div className="modal-padding">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row form-group">
                                    <label className="col-md-12">Payment Mode {astrix} {toolTip('paymentMode')}</label>
                                    <div className="modal-padding row">
                                      {
                                        getPaymentOptionslist()
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* //---------------------------------------------- */}
                              {/* add bank Component */}
                              {/* {addBankObj.modal && <AddBankComponent />} */}
                              {/* //---------------------------------------------- */}

                              <div className="row" id="Bank1">
                                <div className="col-md-12">
                                  <div className="row form-group">
                                    <label className="col-md-8"> Importer's Bank  {astrix} {toolTip("buyerBank")}</label>
                                    <div className="col-md-8">
                                      <select className="form-control" name="buyerBank" value={data.buyerBank} onChange={handleChange} id="buyerBank_select">
                                        <option selected value="">--Select Bank--</option>
                                        <option value="0" >Not Available</option>
                                        {
                                          // getbuyerBankList()
                                        }
                                      </select>
                                      {errorData.buyerBank && <p className="text-danger error-contract">{errorData.buyerBank}</p>}
                                    </div>
                                    <div className="col-md-4">
                                      <div className="modal-padding">
                                        {/* <button className="btn btn-primary float-right btn-sm" onClick={() => setaddBankObj(true, { "email": data.buyerEmail ? data.buyerEmail : '' })} >Add Bank</button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="Bank1">
                                <div className="col-md-12">
                                  <div className="row form-group">
                                    <label className="col-md-8">Exporter's bank {astrix} {toolTip("supplierBank")}</label>
                                    <div className="col-md-8">
                                      <select className="form-control" name="supplierBank" value={data.supplierBank} onChange={handleChange} id="supplierBank_select">
                                        <option selected value="">--Select Bank--</option>
                                        <option value="0" >Not Available</option>
                                        {
                                          // getsupplierBankList()
                                        }
                                      </select>
                                      {errorData.supplierBank && <p className="text-danger error-contract">{errorData.supplierBank}</p>}
                                    </div>
                                    <div className="col-md-4">
                                      <div className="modal-padding">
                                        {/* <button className="btn btn-primary float-right btn-sm" onClick={() => setaddBankObj(true, { "email": data.supplierEmail ? data.supplierEmail : '' })} >Add Bank</button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="w-100">
                            {/* {miniPreview()} */}
                            {/* <hr /> */}
                            {/* <h2>Contract TNC</h2> */}
                            {errorData.tnc && <p className="text-danger">{errorData.tnc}</p>}
                            {true ? <TncForm
                              dataTnc={dataTnc}
                              setdataTnc={setdataTnc}
                              tncOrder={tncOrder}
                              settncOrder={settncOrder}
                              // contMode={contractState.info.modalMode}
                              contMode={"edit"}
                              editCount={editCount}
                              setEditCount={setEditCount}
                              handleTNC={handleTNC} /> : ''}
                            {errorData.tnc && <p className="text-danger text-center">{errorData.tnc}</p>}
                          </div>


                          <div className="w-100">
                            {/* {miniPreview()} */}
                            <hr />
                            {true ? <DocumentACL
                              userArray={getUserArray()}
                              docCategoryACL={docCategoryACL}
                              setdocCategoryACL={setdocCategoryACL}
                              contractId={data.contract_id ? data.contract_id : null}
                              contractNO={null}
                              dataObj={{ "exporter_id": data.exporter_id / 1, "importer_id": data.importer_id / 1, "importer_bank": data.buyerBank / 1, "exporter_bank": data.supplierBank / 1 }}
                            // modalType={contractState.info.modalMode} 
                            />
                              : ""}
                          </div>




                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="modal-footer primary">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => null()}>Cancel</button>
                    <button type="button" className="btn btn-info btn-sm" onClick={() => null()}>Save</button>
                  </div>
                </div>
              </div>
            </div>

          </main>

        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//     setDisbursed: (flag, data) => { dispatch(setDisbursed({ modal: flag, info: data })) },
//     setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
//   }
// }
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  null
)(POGrid)