import React, { useEffect, useState } from 'react';
import moment from 'moment';

// const rowsCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

const EditableGrid = props => {
  const [extraRowsCount, setExtraRowsCount] = useState(1);
  const [values, setValues] = useState([])
  const [infocusRowId, setInfocusRowId] = useState(null)
  const [errors, setErrors] = useState({})
  const [headings, setHeadings] = useState(props.headings)

  let newRowAdded = false

  // Dynamically change columns based on conditions 
  useEffect(() => {
    if (props.headings.length && props.conditionForColumn) {
      let temp = []
      for (let i = 0; i < props.headings.length; i++) {
        if (props.headings[i].condition.includes(props.conditionForColumn)) {
          temp.push(props.headings[i])
        }
      }
      setHeadings(temp)
    }
    // console.log("values", values);
  }, [props.conditionForColumn])

  useEffect(() => {
    setValues(props.data)
    setErrors({})
  }, [props.data])

  // const handleChange = event => {
  //   event.persist()
  //   setExtraRowsCount(event.target.value)
  // }

  const appendExtraRows = () => {
    let temp = []
    for (let i = 0; i < extraRowsCount; i++) {
      temp.push({ is_edited: true, is_new_row: true })
    }
    setValues(values.concat(temp))
  }

  const handleRowsChange = (event, selector) => {
    event.persist();
    let temp = event.target.name.split(":")
    let valIndex = temp[0]
    let headingIndex = temp[1]
    values[valIndex][selector] = event.target.value
    values[valIndex]["is_edited"] = true
    if (headings[headingIndex]["onddSelectionChangeClear"]) {
      values[valIndex][headings[headingIndex]["onddSelectionChangeClear"]] = ""
    }
  }

  const validateCompleteRow = valIndex => {
    let rowToValidate = values[valIndex]
    let tempErrors = {}
    let isValidRow = true
    for (let i = 0; i < headings.length; i++) {
      let valToValidate = rowToValidate[headings[i]["selector"]] ? rowToValidate[headings[i]["selector"]].toString() : ""
      if (headings[i]["checkForMandatory"]) {
        if (valToValidate.trim()) {
          valToValidate = valToValidate.trim()
          if (headings[i]["validation"]) {
            if (headings[i]["validation"] === "positive_no") {
              if (isNaN(valToValidate / 1)) {
                isValidRow = false
                tempErrors[valIndex + ":" + i] = "Invalid No"
              }
              if (valToValidate <= 0) {
                isValidRow = false
                tempErrors[valIndex + ":" + i] = "Invalid No"
              }
            }
            if (headings[i]["validation"] === "date_format(yyyy-mm-dd)") {
              if (moment(valToValidate).format("YYYY-MM-DD") != valToValidate) {
                isValidRow = false
                tempErrors[valIndex + ":" + i] = "Invalid Date Format"
              }
              else if (rowToValidate["start_date"] > rowToValidate["end_date"]) {
                isValidRow = false
                tempErrors[valIndex + ":" + i] = "Start date is greater than end date"
              }
            }
          }
        }
        else {
          isValidRow = false
          tempErrors[valIndex + ":" + i] = "Field Missing"
        }
      }
    }
    // console.log("tempErrors", tempErrors);
    setErrors({ ...errors, ...tempErrors })
    if (isValidRow) {
      props.onDataSubmit(rowToValidate)
    }
  }

  return (
    <>
      <table className="table table-bordered text-center" >
        <thead className='' style={{ "position": "sticky", 'top': "5rem" }}>
          <tr>
            <th className="align-middle">Sr No.</th>
            {headings.map(item => {
              return (
                <th className="align-middle">{item.name}</th>
              )
            }
            )}
          </tr>
        </thead>
        <tbody>
          {headings.length && values.length ? values.map((val, valIndex) => {
            if (val.is_new_row) {
              newRowAdded = true
            }
            return (<tr>
              <td className={"p-1 align-middle"}>{valIndex + 1}</td>
              {headings.map((heading, headingIndex) => {
                let rowId = valIndex + ":" + headingIndex
                if (heading["selector"] === "action") {
                  return (
                    <td className='align-middle'>
                      <button type="button" className="btn btn-primary btn-sm"
                        onClick={() => validateCompleteRow(valIndex)} disabled={!val.is_edited}
                      >
                        <i disabled class="fa fa-save display-6"
                        ></i>
                      </button>
                      {heading["showDeleteAction"] ? (
                        <button type="button" className="btn btn-primary btn-sm ml-4"
                          onClick={() => props.onDeleteAction(valIndex)} disabled={val.is_edited}
                        >
                          <i disabled class="fa fa-trash display-6"
                          ></i>
                        </button>
                      ) : null}
                    </td>
                  )
                }
                else if (errors[rowId]) {
                  return (
                    <td className={"p-1 align-middle text-danger"} onClick={() => {
                      (val.is_new_row || heading["editExistData"]) && setInfocusRowId(rowId); setErrors({ ...errors, [rowId]: "" })
                    }}>
                      {errors[rowId]}
                    </td>
                  )
                }
                else if (infocusRowId != rowId) {
                  return (
                    <td className={"p-1 align-middle text-break"} onClick={() => {
                      (val.is_new_row || heading["editExistData"]) && setInfocusRowId(rowId)
                    }}>
                      {val[heading["selector"]]}
                    </td>
                  )
                }
                else if (infocusRowId === rowId) {
                  if (heading["ddField"]) {
                    return (
                      <td className={"align-middle"}>
                        <select className="form-control" name={rowId}
                          onBlur={() => setInfocusRowId(null)}
                          onChange={(event) => { handleRowsChange(event, heading["selector"]); (heading["onddChange"] && props[heading["onddChange"]](event.target.value)) }}
                          defaultValue={val[heading["selector"]]}
                        >
                          <option value="" selected>--Select--</option>
                          {props[heading["ddData"]].map((item) => {
                            return (<option value={item[heading["ddKey"]]}>{item[heading["ddKey"]]}</option>)
                          })}
                        </select>
                      </td>
                    )
                  }
                  else if (heading["calendarField"]) {
                    return (
                      <td className={"align-middle"}>
                        <input type="date" autoFocus={true}
                          name={rowId} className=" border-0"
                          onFocus={() => { setInfocusRowId(rowId) }}
                          onChange={(event) => handleRowsChange(event, heading["selector"])}
                          style={{ outline: "none", background: 'transparent', resize: "none" }}
                          onBlur={() => setInfocusRowId(null)}
                          defaultValue={val[heading["selector"]]}
                        />
                      </td>
                    )
                  }
                  else {
                    return (
                      <td className={"align-middle"}>
                        <input type="text" autoFocus={true}
                          name={rowId} className=" border-0"
                          onFocus={() => { setInfocusRowId(rowId) }}
                          onChange={(event) => handleRowsChange(event, heading["selector"])}
                          style={{ outline: "none", background: 'transparent', resize: "none" }}
                          onBlur={() => setInfocusRowId(null)}
                          defaultValue={val[heading["selector"]]}
                        />
                      </td>
                    )
                  }
                }
              })}
            </tr>)
          }
          ) : null}
        </tbody>
      </table>
      {!values.length ? (
        <h6 className='text-center align-middle'>No Records Found</h6>
      ) : null}
      <button type="button" className="btn btn-primary btn-sm" disabled={newRowAdded}
        onClick={appendExtraRows}
      >
        {"Add Row"}
        {/* <select className="mx-2" name="extraRowsCount" value={extraRowsCount}
          onChange={handleChange}>
          {rowsCount.map(item =>
            <option value={item} selected>{item}</option>
          )}
        </select> */}
      </button>
    </>
  )
}

export default EditableGrid;