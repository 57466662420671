import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setInvoiceDetail } from '../../store/actions/action';
import { formatDate_Application } from '../../utils/dateFormaters';
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import toastDisplay from '../../utils/toastNotification';
import price_in_words from '../../utils/wordtoNumber'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ShipmentDetailAccord from '../contractDetails/shipmentDetailAccord'
import AclLogin from '../utilComponents/aclLogin';
import avatarUrl from '../../utils/makeAvatarUrl';


const InvoiceGenShipment = ({ userTokenDetails, setInvoiceDetail, InvoiceDetail, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const contractNo = InvoiceDetail.info.contractNo
  const invoiceType = InvoiceDetail.info.invoiceType
  const operationType = InvoiceDetail.info.operation
  const [dbData, setDbData] = useState({})
  const [values, setvalues] = useState({ contract_number: contractNo, userId: InvoiceDetail.info.userId, "invoiceType": "shipment" })
  const [showLoader, setshowLoader] = useState(false);
  const [agreeInvoiceGen, setagreeInvoiceGen] = useState(false);
  const [previewInvoice, setpreviewInvoice] = useState(false);
  const [fileData, setfileData] = useState({});
  const [login, setLogin] = useState({
    state: false,
    validated: false
  });

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getShipContracts', { contractNo: contractNo, contractOnly: true, invoice: true }).then(async (result) => {
      console.log('getShipContracts  ContractDetailShipment-->', result);
      setDbData(result)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("error in fetch shipment cont details==>", e)
    })

    if (operationType === "ReGenerate") {
      call('POST', 'getinvoiceforcontract', { "contract_number": contractNo, "invoiceType": invoiceType }).then(async (result) => {
        console.log("get invoice details in gen invoice ->", result)
        setfileData(result.fileData)
      }).catch((error) => {
        console.log("error occur in getcontractdetails ->", error)
      })
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  const handleFile = (event) => {
    event.persist();
    setvalues(values => ({ ...values, [event.target.name]: event.target.files[0] }));

  }

  function printDiv(divName) {

    let printContents = document.getElementById(divName)
    window.scrollTo(0, 0);
    let w = printContents.clientWidth;
    let h = printContents.clientHeight;


    html2canvas(printContents, {
      width: w,
      height: h,
      scrollX: 0,
      scrollY: -window.scrollY,
      allowTaint: true,
      useCORS: true
    }).then((canvas) => {
      //      window.open().document.write('<img src="' + canvas.toDataURL() + '" />');
      setshowLoader(true)
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        compress: true
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      let invoiceFile = new File([pdf.output('blob')], "FreightInvoiceDoc.pdf", {
        type: pdf.output('blob').type,
        lastModified: pdf.output('blob').lastModified,
      });

      if (operationType === "Generate") {
        handleInvoiceUpload(invoiceFile)
      }
      else if (operationType === "ReGenerate") {
        handleInvoiceUpdate(invoiceFile)
      }

    });
  }


  const handleInvoiceUpload = (generatedInvoiceBlob) => {
    setshowLoader(true)
    let formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    })
    if (generatedInvoiceBlob) { formData.append("invoiceDoc", generatedInvoiceBlob); }

    call('POST', 'uploadinvoice', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Operation Success", "success")
        setInvoiceDetail(false, { "refresh": true })
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Error Occured!", "error")
    })

  }

  const handleInvoiceUpdate = (generatedInvoiceBlob) => {
    setshowLoader(true)

    console.log("inside regen function old file info-->", fileData)
    console.log("inside regen function new file-->", generatedInvoiceBlob)

    let payloadUpload = {
      uploadingUser: userId,
      userTypeName: 'seller',
      id: fileData.relId,
      tbl_doc_id: fileData.id,
      contract_id: fileData.trade_contract_number,
      linked_to: 0,
      isUpdateQuery: true,
      prevFileHash: fileData.file_hash,
      categoryId: fileData.category,
      linked_id: contractNo,
      filePayload: {
        docNo: fileData.doc_no,
        docName: fileData.doc_name,
        genDocLabel: fileData.gen_doc_label,
        docType: fileData.tbl_mst_doc_id,
        validUpto: ""
      }
    }

    payloadUpload = JSON.stringify(payloadUpload);
    let formData = new FormData();
    formData.append('payloadUpload', payloadUpload);
    formData.append('doc', generatedInvoiceBlob);

    call('POST', 'updateDoc', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setInvoiceDetail(false, { "refresh": true })
        toastDisplay("Invoice Re-generation Done", "success");
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err.message, "error");
    })

  }

  return (
    <>
      {showLoader && <div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>}
      {login.state && <AclLogin setLogin={setLogin} />}
      {!previewInvoice ?
        <div className="col-md-12 row mt-2">
          <div className="col-md-12">
            <h2 className="text-primary">Contract Info ({contractNo})</h2>
            <hr className="p-1" />
            <div className="d-flex align-content-around flex-wrap mb-3 contract-details">
              <div className="col-md-12 row mt-2">
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Charterer</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.charterer_avatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="date-format">  {dbData.charterer ? dbData.charterer : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.chartererEmail ? dbData.chartererEmail : '--'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Ship Owner</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.shipOwner_avatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="date-format">  {dbData.shipOwner ? dbData.shipOwner : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.shipOwnerEmail ? dbData.shipOwnerEmail : '--'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Vessel Master</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.vesselMasterAvatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="date-format">  {dbData.vesselMaster ? dbData.vesselMaster : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.vesselMaster ? dbData.vesselMaster : '--'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Port Agent</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.portAgentAvatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="date-format">  {dbData && dbData.portAgent ? dbData.portAgent : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.portAgent ? dbData.portAgent : '--'}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="card-panel">
                <ul>
                  <li>
                    {(dbData.contract_type / 1 === 0) && <h3>{dbData.commodity_name ? dbData.commodity_name : "--"}</h3>}
                    {(dbData.contract_type / 1 === 1) && <h3>{dbData.off_data ? dbData.off_data.commodity : "--"}</h3>}
                    <p>Commodity</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {dbData.ship_price} </h3>
                    <p>Shipment Price</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {dbData.ship_demmurage_price} </h3>
                    <p>Demmurage Rate</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.charterer} </h3>
                    <p>Charterer</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.shipOwner} </h3>
                    <p>Ship Owner</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3><span className="shadow">
                      <ReactCountryFlag
                        countryCode={dbData.ship_load_country ? dbData.ship_load_country.split(':')[0] : ''}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> {dbData.ship_load_port}</h3>
                    <p>Loading Port</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3><span className="shadow">
                      <ReactCountryFlag
                        countryCode={dbData.ship_unload_country ? dbData.ship_unload_country.split(':')[0] : ''}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> {dbData.ship_unload_port}</h3>
                    <p>Unloading Port</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3> {dbData.ship_name}</h3>
                    <p>Vessel</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{formatDate_Application(dbData.ship_date)} </h3>
                    <p>Shipment Date</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{formatDate_Application(dbData.laycan_start)}</h3>
                    <p>Laycan Start</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{formatDate_Application(dbData.laycan_end)} </h3>
                    <p>Laycan End</p>
                  </li>
                </ul>
              </div>

            </div>
          </div>


          <div className="col-md-12">
            <h2 className="text-primary"> Shipment details</h2>
            <hr />
            <ShipmentDetailAccord
              userTokenDetails={userTokenDetails}
              contractNo={null}
              shipContractNo={contractNo} />
          </div>

          {((operationType === "Generate") || (operationType === "ReGenerate")) ?
            <div className="col-md-12 mt-4">
              <h2 className="text-primary">{(operationType === "Generate") ? "" : "Re-"}Generate Invoice</h2>
              <hr className="p-1" />
              <div className="form-check mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="agreeInvoiceGen" id="agreeInvoiceGen" onChange={(event) => { event.persist(); setagreeInvoiceGen(event.target.checked) }} checked={agreeInvoiceGen} />
                <label className="form-check-label" for="agreeInvoiceGen"> I hereby declare that the above information 100% correct and verified by me and
                  I give my consensus to use it and generate the invoice for given contract.</label>
              </div>
              <div className="col-md-12 mt-3 row">
                {(operationType === "Generate") &&
                  <div className="col-md-6">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() =>
                      setInvoiceDetail(true, {
                        "contractNo": InvoiceDetail.info.contractNo,
                        "userType": InvoiceDetail.info.userType,
                        "userId": InvoiceDetail.info.userId,
                        "operation": "Upload",
                        "invoiceType": "shipment"
                      })} > Cancel, I will Upload the Invoice</button>
                  </div>}
                {agreeInvoiceGen && <div className="col-md-6">
                  <button type="button" className="btn btn-success btn-sm" onClick={() => setpreviewInvoice(true)}>Preview, The Invoice</button>
                </div>}
              </div>
            </div>
            :
            <div className="col-md-12 mt-4">
              <h2 className="text-primary">Upload Invoice</h2>
              <hr className="p-1" />
              <div className="col-md-12 mt-3 row">
                <div className="col-md-4">
                  <button type="button" className="btn btn-danger btn-sm" onClick={() =>
                    setInvoiceDetail(true, {
                      "contractNo": InvoiceDetail.info.contractNo,
                      "userType": InvoiceDetail.info.userType,
                      "userId": InvoiceDetail.info.userId,
                      "operation": "Generate",
                      "invoiceType": "shipment"
                    })} > Cancel, I will Generate the Invoice</button>
                </div>
                <div className="col-md-4">
                  <div className="file-browse">
                    <button className="btn btn-primary btn-sm">Choose Document</button>
                    <input type="file" accept=".png,.jpg,.pdf" name="invoiceDoc" onChange={handleFile} />
                  </div>
                </div>
                <div className="col-md-4">
                  {(values.invoiceDoc) ? <div className="form-group mb-0"><div className="file-name">{values.invoiceDoc.name}</div></div> : ""}
                </div>
                {values.invoiceDoc && <div className="col-md-6">
                  <button type="button" className="btn btn-success btn-sm" onClick={() => handleInvoiceUpload(null)}>Submit</button>
                </div>}
              </div>
            </div>}

        </div> :

        <div className="col-md-12 row mt-2">

          <div className="col-md-12 row m-2" id="divOfInvoiceContent">

            <div className="col-md-12 mt-5 text-center">
              <img src={avatarUrl(dbData.shipOwner_avatar)} alt="Avatar" width="100" height="100" />
              <h2 className="">{dbData.shipOwner ? dbData.shipOwner : '--'}</h2>
              <p className="">Email: {dbData.shipOwnerEmail ? dbData.shipOwnerEmail : '--'}</p>
            </div>

            <div className="col-md-12 mt-2 d-flex justify-content-center">
              <div className="col-md-10 row mt-2">
                <div className="col-md-6 text-left">
                  <p className=""><b>To:</b> {dbData.charterer ? dbData.charterer : '--'}</p>
                </div>
                <div className="col-md-6 text-right">
                  <p className=""><b>INVOICE NO:</b> {InvoiceDetail.info.contractNo.replace("SH", "INV")}</p>
                  <p className=""><b>Dated:</b> {formatDate_Application(new Date())}</p>
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex justify-content-center">
              <div className="col-md-10 mt-2 border">
                <div className="col-md-12 text-center border-bottom mt-1">
                  <h1>FREIGHT INVOICE</h1>
                </div>
                <div className="col-md-12 text-left border-bottom mt-2">
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>C/P Date: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.created_at ? dbData.created_at : '--'} </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Vessel: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_name ? dbData.ship_name : '--'} </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Charterer: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.charterer ? dbData.charterer : '--'} </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Commodity: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.commodity_name ? dbData.commodity_name : '--'} </p></div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Loading Port: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_load_port ? dbData.ship_load_port : '--'}, {dbData.ship_load_country ? dbData.ship_load_country.split(':')[0] : '--'}</p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Discharge Port: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_unload_port ? dbData.ship_unload_port : '--'}, {dbData.ship_unload_country ? dbData.ship_unload_country.split(':')[0] : '--'} </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Freight Rate: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {dbData.ship_price ? dbData.ship_price : '--'} PMT </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Corgo Quantity: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_quantity ? dbData.ship_quantity : '--'}mt </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Ocean Freight: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {(dbData.ship_quantity / 1) * (dbData.ship_price / 1)} </p></div>
                  </div>
                  <div className="col-md-12 row" >
                    <div className="col-md-3 pl-0" ><b>Brokerage: </b></div>
                    <div className="col-md-9 pl-0" ><p>{dbData.brokerage ? dbData.brokerage : '--'} </p></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="col-md-12 row">
                    <div className="col-md-6 text-left">
                      <p>Total Due to Owner</p>
                    </div>
                    <div className="col-md-6 text-right">
                      <p><b>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {(dbData.ship_quantity / 1) * (dbData.ship_price / 1)}</b></p>
                    </div>
                  </div>
                  <h3 className="text-center">{price_in_words((dbData.ship_quantity / 1) * (dbData.ship_price / 1))}{dbData.ship_currency ? " " + dbData.ship_currency.split(':')[0] : ''}</h3>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-2 d-flex justify-content-center">
              <div className="col-md-10 mt-2">
                <div className="col-md-12 row" >
                  <div className="col-md-3 pl-0" ><b>Beneficiary Bank: </b></div>
                  <div className="col-md-9 pl-0" ><p>{dbData.soBankerName ? dbData.soBankerName : '--'} </p></div>
                </div>
                <div className="col-md-12 row" >
                  <div className="col-md-3 pl-0" ><b>Swift Code: </b></div>
                  <div className="col-md-9 pl-0" ><p>{dbData.swiftCode ? dbData.swiftCode : '--'} </p></div>
                </div>
                <div className="col-md-12 row" >
                  <div className="col-md-3 pl-0" ><b>Account No: </b></div>
                  <div className="col-md-9 pl-0" ><p>{dbData.soAccountNo ? dbData.soAccountNo : '--'} </p></div>
                </div>
              </div>
            </div>

          </div>

          <hr className="col-md-12 pt-1 pb-1" />
          <div className="col-md-12 mt-3 row">
            <div className="col-md-6">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => setpreviewInvoice(false)}>Cancel</button>
            </div>
            {!login.validated && <div className="col-md-6">
              <button type="button" className="btn btn-warning btn-sm" onClick={() => setLogin({ ...login, state: true })}>Authorise, To Enable {(operationType === "Generate") ? "" : "Re-"}Generate Option</button>
            </div>}
            {(agreeInvoiceGen && login.validated) && <div className="col-md-6">
              <button type="button" className="btn btn-success btn-sm" onClick={() => printDiv('divOfInvoiceContent')}>{(operationType === "Generate") ? "" : "Re-"}Generate The Invoice</button>
            </div>}
          </div>
        </div>

      }
    </>
  )
};

const mapStateToProps = state => {
  return {
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceGenShipment)
