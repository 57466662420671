import React from 'react';
import { Link } from "react-router-dom";
import { setRegistrationData } from '../../store/actions/action';
import { connect } from 'react-redux';



const ThankYouPage = ({ setRegistrationData, dispatch }) => {
  var isAdminModal = !(window.location.href.includes('registration'));

  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal thaks-bg">
        <h1 className="green mb-4 display-4 font-italic">Profile Registered Successfully!</h1>
        <div className="col-md-12 our-webcoderskull">
          <div className="form-group text-center mt-3 ">
            <button type="button" className="btn btn-primary btn-sm" onClick={() => setRegistrationData(0, 0, {})}>Back</button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        <ul className="thank-you-bg">
          <li>
            <div className="thaks-bg">
              <h1 className="green mb-4 display-4 font-italic">Thank you !</h1>
              <h3 className="pb-2">Thank You for registering with us. If you are facing any issue then please contact us on 8450969138, 8452035022 or 8451978022</h3>
              <div className="col-md-12 our-webcoderskull">
                <div className="form-group text-center mt-3 ">
                  <button type="button" className="btn btn-primary btn-sm" onClick={handleCloseThankYou}>Home</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    )
  }
}

function handleCloseThankYou() {
  window.location.href = "/login"
  setRegistrationData(0, 0, {})
}

const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouPage);
