import React, { useEffect, useState } from 'react';
import Footer from '../partial/footer';
import Sidebar from '../partial/sidebar';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../partial/header';
import ManageUsers from './manageUsers.js'
import ManageNetworkRequest from './manageNetworkRequests.js'
import ManageBlockedUsers from './manageBlockedUsers'
import ManageProfile from "./manageProfile"

const AdminMain = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [tab, setTab] = useState(0);
  const [pageTitle, setPageTitle] = useState("Manage Users");
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    if (aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.get_users_profiles && aclMap.mng_subusers_profile.get_users_profiles.isExist) {
      setTab(0)
      setPageTitle(userTypeId / 1 === 1 ? "All Users" : "My Network")
    } else if (aclMap.mng_subusers && aclMap.mng_subusers.manage_users && aclMap.mng_subusers.manage_users.isExist) {
      setTab(1)
      setPageTitle("Manage Users")
    } else if (aclMap.mng_subusers && aclMap.mng_subusers.manage_network && aclMap.mng_subusers.manage_network.isExist) {
      setTab(2)
      setPageTitle("Manage Network")
    } else if (aclMap.mng_subusers && aclMap.mng_subusers.manage_networkRequest && aclMap.mng_subusers.manage_networkRequest.isExist) {
      setTab(3)
      setPageTitle("Manage Requests")
    } else if (aclMap.mng_subusers && aclMap.mng_subusers.manage_blockedUser && aclMap.mng_subusers.manage_blockedUser.isExist) {
      setTab(4)
      setPageTitle("Blocked Users")
    }
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state={'admin'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={pageTitle}
              total={""}
              userTokenDetails={userTokenDetails} />

            <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
              {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.get_users_profiles && aclMap.mng_subusers_profile.get_users_profiles.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>{userTypeId / 1 === 1 ? "All Users" : "My Network"}</a>
                </li>}
              {(aclMap.mng_subusers && aclMap.mng_subusers.manage_users && aclMap.mng_subusers.manage_users.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>Manage Users</a>
                </li>}
              {(aclMap.mng_subusers && aclMap.mng_subusers.manage_network && aclMap.mng_subusers.manage_network.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Manage Network</a>
                </li>}
              {(aclMap.mng_subusers && aclMap.mng_subusers.manage_networkRequest && aclMap.mng_subusers.manage_networkRequest.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 3 ? " active show" : "")} onClick={() => setTab(3)}>Network Requests</a>
                </li>}
              {(aclMap.mng_subusers && aclMap.mng_subusers.manage_blockedUser && aclMap.mng_subusers.manage_blockedUser.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 4 ? " active show" : "")} onClick={() => setTab(4)}>Blocked Users</a>
                </li>}
            </ul>

            <div className="d-flex">
              {tab === 0 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.get_users_profiles && aclMap.mng_subusers_profile.get_users_profiles.isExist) &&
                    <ManageProfile userTokenDetails={userTokenDetails} channelPartnerUser={false} />}
                </div>
              }
              {tab === 1 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.mng_subusers && aclMap.mng_subusers.manage_users && aclMap.mng_subusers.manage_users.isExist) &&
                    <ManageUsers userTokenDetails={userTokenDetails} compType={'admin'} />}
                </div>
              }
              {tab === 2 &&
                <div className={"tab-pane active show  col-md-12"}>
                  {(aclMap.mng_subusers && aclMap.mng_subusers.manage_network && aclMap.mng_subusers.manage_network.isExist) &&
                    <ManageUsers userTokenDetails={userTokenDetails} compType={'user'} />}
                </div>
              }
              {tab === 3 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.mng_subusers && aclMap.mng_subusers.manage_networkRequest && aclMap.mng_subusers.manage_networkRequest.isExist) &&
                    < ManageNetworkRequest userTokenDetails={userTokenDetails} />}
                </div>
              }
              {tab === 4 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.mng_subusers && aclMap.mng_subusers.manage_blockedUser && aclMap.mng_subusers.manage_blockedUser.isExist) &&
                    <ManageBlockedUsers userTokenDetails={userTokenDetails} />}
                </div>
              }
            </div>
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
};

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMain)
