

export const rateManagementColumns = [
  {
    name: 'Rate Id',
    selector: 'info_dump_id',
    sortable: false,
    // grow: 1
  },
  {
    name: 'Origin Port',
    selector: 'origin_port',
    sortable: false,
    // grow: 2,
  },
  {
    name: 'Destination Port',
    selector: 'destination_port',
    sortable: false,
    // grow: 2,
  },
  {
    name: 'Mode',
    selector: 'mode',
    sortable: false,
    // grow: 1,
  },
  {
    name: 'Carrier Scac',
    selector: 'carrier_scac',
    sortable: false,
    // grow: 1,
  },
  {
    name: 'Carrier Name',
    selector: 'carrier_name',
    sortable: false,
    // grow: 1,
  },
  {
    name: 'Departure Date',
    selector: 'departure_date',
    sortable: false,
    // grow: 1,
  },
  {
    name: 'Commission Percentage',
    selector: 'commission_percentage_input',
    sortable: false,
    // grow: 1,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    grow: 2,
    conditionalCellStyles: [
      {
        when: row => (row.status === "On Hold"),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status === "Rate Published"),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  },
  {
    name: 'Last Updated At',
    selector: 'last_updated_at',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
    grow: 2
  }
]