const columns = [
  {
    name: 'S.No.',
    selector: 's_no',
  },
  {
    name: 'User',
    selector: 'avatar',
  },
  {
    name: 'Entity Name',
    selector: 'name',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Country',
    selector: 'country',
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
  },
  {
    name: 'Contact Person',
    selector: 'contactPerson',
    grow: 2,
    sortable: true,
  },
  {
    name: 'Email Id',
    selector: 'email',
    grow: 2,
  },
  {
    name: 'No.of Contracts',
    selector: 'noOfContracts',
  },
  {
    name: 'Created on',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    grow: 2
  }
];

export default columns
