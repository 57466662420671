import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setCreditlineDataBank, setCreditDocuments } from '../../store/actions/action';
import FinanceDetailsGrid from '../userProfile/financeDetailGrid'
import LenderOffer from './lenderOffer';
import CreditDocument from './creditDocument';


const UserFinDetailBank = ({ userTokenDetails, creditlineDataBank, setCreditlineDataBank, dispatch, CreditDocuments, setCreditDocuments }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [currencyData, setcurrencyData] = useState([]);
  const [creditDetailsDB, setcreditDetailsDB] = useState({});

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  //------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //API Calls
    // Get currency list from Master table.
    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    if (userTypeId / 1 === 8) {
      call("post", "getusercreditdetailbybbank", { userId: creditlineDataBank.info.userId, bankId: userId }).then((result) => {
        setcreditDetailsDB(result);
      }).catch((e) => {
        console.log("error in getcurrencylist", e);
      });
    }

  }, [refresh]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  //---------------------------------------------------------------------------------------------------------------------


  console.log("creditDetailsDB==>", creditDetailsDB)

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {creditlineDataBank.info.hasOwnProperty('allData') ?
        <LenderOffer userTokenDetails={userTokenDetails} manualUserId={creditlineDataBank.info.userId}
          manualUserName={creditlineDataBank.info.userName}
          manualUserEmail={creditlineDataBank.info.userEmail} creditlineDataBank={creditlineDataBank} />
        :
        (CreditDocuments.info.hasOwnProperty('action') && CreditDocuments.info.action.hasOwnProperty('userid')) ?
          <CreditDocument userTokenDetails={userTokenDetails} manualUserId={CreditDocuments.info.id}
            manualUserName={CreditDocuments.info.name}
            manualUserEmail={CreditDocuments.info.email} userDetail='' userPendingDetail='' CreditDocuments={CreditDocuments} />
          :
          <>
            <div className="col-md-12 bg-light shadow">
              <FinanceDetailsGrid
                userTokenDetails={userTokenDetails}
                manualUserId={creditlineDataBank.info.userId}
                manualUserName={creditlineDataBank.info.userName}
                manualUserEmail={creditlineDataBank.info.userEmail}
                creditlineDataBank={creditlineDataBank}
                setCreditlineDataBank={setCreditlineDataBank} />
            </div>
          </>
      }
    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    CreditDocuments: state.CreditDocuments,
    creditlineDataBank: state.creditlineDataBank
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
    setCreditDocuments: (modal, info) => { dispatch(setCreditDocuments({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFinDetailBank)
