import React, { useEffect, useState } from 'react';
import * as CSV from 'csv-string';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

let columnNames = [
  { name: "Country Code", selector: "country_code" },
  { name: "Payment Terms(in days)", selector: "payment_terms_in_days" },
  { name: "Interest Rate (in %)", selector: "interest_rate" },
  { name: "Factoring Fees Type", selector: "factoring_fees_type", },
  { name: "Factoring Fees (in %/$)", selector: "factoring_fees_in_usd" },
  { name: "One Time Setup Fees (in $)", selector: "one_time_setup_fees_in_usd" }
]


const InterestRatePopup = ({ userTokenDetails, togglePopup }) => {
  // States and variables
  const [data, setData] = useState({
    "modeTab": 0,
    "typeTab": 0
  })
  const [rateFile, setRateFile] = useState({})
  const [error, setError] = useState({})
  const [showLoader, setshowLoader] = useState(false);


  let isFileDataValid = true
  const astrix = <span className="required-field text-danger">*</span>
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const handleCSV = (event) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setError({ ...error, "bulk_upld_error": "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setError({ ...error, "bulk_upld_error": "Please select csv file" });
      setRateFile({ ...rateFile, name: event.target.files[0]['name'], rows: [] })
      return
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      let rows = CSV.parse(e.target.result)
      // console.log("processed csv rows", rows);
      if (rows && rows.length > 1) {
        rows.shift()
        setRateFile({ ...rateFile, rows, name: event.target.files[0]['name'], columns: columnNames })
      }
      else {
        setError({ ...error, "bulk_upld_error": "Selected file is empty" });
        setRateFile({ ...rateFile, name: event.target.files[0]['name'], rows: [] })
      }
    }
    reader.readAsText(event.target.files[0]);
  }

  const validateField = (col_name, value, index) => {
    let validateAsNo = [columnNames[1], columnNames[2], columnNames[4], columnNames[5]]
    if (!value) {
      return { "msg": "Required", "success": false }
    }
    value = value.trim();
    if (validateAsNo.filter(item => { if (item.name === col_name) { return item } }).length) {
      if (isNaN(value / 1)) {
        return { "msg": "Invalid No", "success": false }
      }
      if (value <= 0) {
        return { "msg": "Invalid No", "success": false }
      }
    }
    if (col_name === columnNames[0]["name"] && value.length > 2) {
      return { "msg": "Invalid Country Code", "success": false }
    }
    if (col_name === columnNames[3]["name"] && value != "Fixed" && value != "Variable") {
      return { "msg": "Invalid Fees Type", "success": false }
    }
    return { "msg": "", "success": true }
  }

  const submitRateList = async () => {
    setshowLoader(true);
    for (let i = 0; i < rateFile.rows.length; i++) {
      let req = {}
      req["type"] = "ID"
      req["userId"] = userId
      for (let j = 0; j < rateFile.columns.length; j++) {
        req[rateFile.columns[j]["selector"]] = rateFile.rows[i][j].trim()
      }
      await call('POST', `financeCalculator/addInterestRate`, req)
    }
    toastDisplay("Data uploaded successfully", "success")
    togglePopup(false)
    setshowLoader(false)
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  }

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className={"modal show"} id="Confirmation">
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Upload Interest Rates</h4>
                <button type="button" className="close" onClick={() => togglePopup(false)}>×</button>
              </div>

              <div className="modal-body calc-modal mx-4" style={{ "overflow-x": "hidden" }}>
                <div className='row my-2'>
                  <div className='col-md-6 '>
                    <h6 className=''>
                      <a className="text-dark cursor-pointer" href={"./assets/docs/interestRates.csv"}
                        target="_blank" download>
                        <img className='mr-2' src="./assets/images/logos/csv.jpg" alt="" width="45" height="45"></img>
                        {"Download Template"}</a>
                    </h6>
                  </div>


                  <div className='row'>
                    <div className='col-auto'>
                      <div>
                        <label className="">Select CSV file for bulk upload</label>
                        <div className="file-browse">
                          <button className="btn btn-primary btn-sm">Upload  File</button>
                          <input type="file" onClick={(event) => {
                            event.target.value = null
                          }} accept=".csv" name="ratelist" onChange={handleCSV} />
                        </div>
                        {(rateFile.rows || error.bulk_upld_error) ?
                          <label className={"error-contract " + (!error.bulk_upld_error ? "text-success" : "text-danger")}>{error.bulk_upld_error ? error.bulk_upld_error : (rateFile.rows.length + " Entries Found")}</label> : null}
                      </div>
                    </div>
                  </div>


                  {rateFile.rows && rateFile.rows.length ? (
                    <div className='ml-4'>
                      <h6>{rateFile.name}</h6>
                      <h6 className='text-danger'>Note* - Before submitting file kindly review below entries</h6>
                      <table className="table">
                        <thead>
                          <tr>
                            {rateFile.columns.map(item => (
                              <th scope="col">{item.name}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rateFile.rows.map((row, rIndex) =>
                            <tr>
                              {rateFile.columns.map((col, index) => {
                                let res = validateField(col["name"], row[index], "" + rIndex + index + "");
                                if (!res.success) {
                                  isFileDataValid = res.success
                                  return (
                                    <td className=''>
                                      <span class="border border-danger p-2">
                                        {res.msg}
                                      </span>
                                    </td>
                                  )
                                }
                                return (
                                  <td>{row[index]}</td>
                                )
                              }
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                </div>





              </div>

              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => togglePopup(false)}>Cancel</button>
                <button type="button"
                  disabled={(rateFile.rows && rateFile.rows.length && isFileDataValid) ? false : true}
                  className="btn btn-info btn-sm" onClick={() => submitRateList()}>Submit</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  )

}

export default InterestRatePopup;