import React, { useEffect, useState } from 'react';
import { setcreditLineDetails, setCreditDocuments, setCreditlineDataBank } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/requestCreditLine';
import pendingcolumns from '../dataTablesColumsMap/pendingCreditLine';
import rejectcolumns from '../dataTablesColumsMap/rejectCreditLine';
import agreementcolumns from '../dataTablesColumsMap/agreementCreditLine';
import customStyles from '../dataTablesColumsMap/customTableCss';
import FilePreview from '../utilComponents/previewFile';
import validate from '../../utils/validation_files/applyCreditLineByUser';
import toastDisplay from '../../utils/toastNotification';
import CreateCreditLine from './createCreditLine';
import LenderOffer from './lenderOffer';
import CreditDocument from './creditDocument';
import DigitalSignDoc from '../contractDocument/digitalSignDoc';
import { formatDate_Application } from '../../utils/dateFormaters';


const AllCreditLine = ({ userTokenDetails, setcreditLineDetails, creditLineDetails, navToggleState, dispatch, creditlineDataBank, setCreditlineDataBank, CreditDocuments, setCreditDocuments }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0);
  const [dbTypeData, setdbTypeData] = useState([]);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Request");
  const [creditUserDetail, setCreditUserDetail] = useState([]);
  const [creditOfferDetail, setCreditOfferDetail] = useState([]);
  const [allCreditDocuments, setAllCreditDocuments] = useState([]);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [dashData, setdashData] = useState({});
  const [pendingAction, setPendingAction] = useState({ modal: false, data: {} });
  const [offerValidityAction, setOfferValidityAction] = useState({ modal: false, data: {} });
  const [revisionAction, setRevisionAction] = useState({ modal: false, data: {} });
  const [viewAction, setViewAction] = useState({ modal: false, data: {} });
  const [userData, setUserData] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentRow, setDocumentRow] = useState({ data: [{ name: null, document: null }] });
  const [createCredit, setCreateCredit] = useState({ modal: false, data: {} });
  const [buyersList, setBuyersList] = useState([]);
  const [financialList, setFinancialList] = useState([]);
  const [kycAction, setkycAction] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [userPendingDetail, setUserPendingDetail] = useState([]);


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {});
    fetchPendingTableData(1, {});
    fetchApprovedTableData(1, {});
    fetchRejectedTableData(1, {});
    fetchCompletedTableData(1, {});
    fetchAgreementTableData(1, {});
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, filterData]);

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls

    call('get', 'getDashboardData')
      .then((result) => {
        console.log("fetched result in dashboard===>", result)
        setdashData(result);
        setkycAction(true);
      })
      .catch((err) => {
        console.log("error:", err)
        // setShow(false);
      })


    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "userName": userName,
      "userEmail": userEmail,
      "gridType": "request"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call("POST", "getCreditlineDocDetail", objectAPI).then(async (result) => {
      setCreditUserDetail(result);
    }).catch((e) => {
      console.log("error in getCreditlineDocDetail", e);
    });

    call("POST", "getCreditOfferDocDetail", objectAPI).then(async (result) => {
      setCreditOfferDetail(result);
    }).catch((e) => {
      console.log("error in getRequestCredits", e);
    });

    call("POST", "getAllCreditDocuments", objectAPI).then(async (result) => {
      setAllCreditDocuments(result);
    }).catch((e) => {
      console.log("error in getRequestCredits", e);
    })

    call("POST", "getUserDetail", { "userId": userId }).then((result) => {
      setUserData(result[0]);
    }).catch((e) => {
      console.log("error in getUserDetail", e);
    });

    setCreateCredit({ modal: true, data: { id: "1" } });

    let object = {
      "userId": userId,
      "userTypeId": userTypeId
    }

    object = { ...object }

    call('POST', 'getBuyersDetail', object).then((result) => {
      console.log('running getBuyersDetail api-->', result.buyerData);
      setBuyersList(result.buyerData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    });

    let finObject = {
      "userId": userId,
      "userTypeId": userTypeId
    }

    finObject = { ...finObject }

    call('POST', 'getuserfinancegrid', finObject).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setFinancialList(result.finData);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
    })
    //---------------------------------------------------------------------------------------------------------------------

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pendingtableData, setpendingtableData] = useState([]);
  const [pendingtotalRows, setPendingTotalRows] = useState(0);
  const [approvedtableData, setapprovedtableData] = useState([]);
  const [approvedtotalRows, setApprovedTotalRows] = useState(0);
  const [rejectedtableData, setrejectedtableData] = useState([]);
  const [rejectedtotalRows, setRejectedTotalRows] = useState(0);
  const [completedtableData, setcompletedtableData] = useState([]);
  const [completedtotalRows, setCompletedTotalRows] = useState(0);
  const [agreementtableData, setagreementtableData] = useState([]);
  const [agreementtotalRows, setagreementTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  //-------------------------------------------------------------------------------------------------------
  //Request

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createMiniBody(data) {
    for (let i = 0; i < creditUserDetail.length; i++) {
      let usersCredit = JSON.parse(creditUserDetail[i].userDetail);
      if (creditUserDetail[i].userid == data.id) {
        return (<>
          {
            usersCredit.map((arrayElement) => {
              return (
                <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
                  <div className="ml-5 mr-5">
                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                    <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                      {arrayElement.doc_name}
                    </button>
                  </div>

                </div>
              )
            })
          }
          {/* <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
            <div className="ml-5 mr-5">
              <div className="border-left">
                <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => documentAction({ userid: data.id })}>
                  <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>Document Details</button>
              </div>
            </div>
          </div> */}
        </>
        )
      }
    }
  }

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "request"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      settableData(formatDataFortable(result.finData))
      setUserDetail(result.finData);
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "request"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      settableData(formatDataFortable(result.finData))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.id = dataArray[i].id;
      tempObj.bank_id = dataArray[i].bank_id;
      tempObj.lendername = dataArray[i].company_name;
      tempObj.credit_amount = dataArray[i].credit_amount;
      tempObj.credit_days = dataArray[i].daysRequired
      var d = new Date(dataArray[i].created_at);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.created_at = date + '/' + month + '/' + year;
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.status = dataArray[i].status;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      if (userTypeId === 1) {
        tempObj.action = <button className="btn btn-primary btn-sm ml-1" >
          <i className={"fas fa-eye"} aria-hidden="true"></i>
        </button>
      } else if (dataArray[i].status === 'Credit Line Created') {
        tempObj.action = <button className="btn btn-primary btn-sm ml-1"
          onClick={() => setViewAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })}><i className={"fas fa-eye"} aria-hidden="true"
          ></i>
        </button >
      } else if (dataArray[i].status === 'User Approval Pending' || dataArray[i].status === 'Admin Revision') {
        tempObj.action = 'N/A'
      }
      else {
        tempObj.action =
          <button className="btn btn-primary btn-sm ml-1"
            onClick={() => setRevisionAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })}
          ><i className={"fa fa-pencil-square-o"}></i></button>
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }

  function ReqExportTableCSV() {

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "request"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);

      const dbResult = result.finData
      let csvString = "Lender Name,Requested Amount,Requested Credit Days,Created At,Status)\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].company_name ? dbResult[i].company_name : "NA"},
      ${dbResult[i].credit_amount ? dbResult[i].credit_amount : "NA"},
      ${dbResult[i].daysRequired ? dbResult[i].daysRequired : "NA"},
      ${dbResult[i].created_at ? formatDate_Application(dbResult[i].created_at) : "NA"},
      ${dbResult[i].status ? dbResult[i].status : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Credit_Line_Requested_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })
  }

  //-------------------------------------------------------------------------------------------------------------------
  //Pending

  const TablePendingExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createPendingMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createPendingMiniBody(data) {
    for (let i = 0; i < creditOfferDetail.length; i++) {
      let usersCredit = JSON.parse(creditOfferDetail[i].userDetail);
      if (creditOfferDetail[i].userid == data.id) {
        return (<>
          {
            usersCredit.map((arrayElement) => {
              if (arrayElement.doc_name !== 'Framework') {
                return (
                  <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
                    <div className="ml-5 mr-5">
                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                      <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                        {arrayElement.doc_name}
                      </button>
                    </div>
                  </div>
                )
              }
            })
          }
          {/* <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
            <div className="ml-5 mr-5">
              <div className="border-left">
                <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => documentAction({ userid: data.id, tabType: 'pendingTabType' })}>
                  <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>Document Details</button>
              </div>
            </div>
          </div> */}
        </>
        )
      }
    }
  }

  function fetchPendingTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "pending"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setpendingtableData(formatPendingDataFortable(result.finData));
      setUserPendingDetail(result.finData);
      setPendingTotalRows(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handlePendingPageChange = page => {
    fetchPendingTableData(page, {});
  };

  const handlePendingSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePendingPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "pending"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      setpendingtableData(formatPendingDataFortable(result.finData))
      setPendingTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatPendingDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      var inpDate = new Date(dataArray[i].credit_validity);
      let date = inpDate.getDate();
      let month = inpDate.getMonth() + 1;
      let year = inpDate.getFullYear();
      tempObj.credit_validity = date + '/' + month + '/' + year;

      tempObj.id = dataArray[i].id;
      tempObj.lendername = dataArray[i].lendername;
      tempObj.requestedAmount = dataArray[i].credit_amount;
      tempObj.approvedFinanceAmount = dataArray[i].approvedFinanceAmount + dataArray[i].currency.split(':')[1];
      tempObj.fundingPercentage = dataArray[i].fundingPercentage + '%';
      tempObj.fundingTenor = dataArray[i].fundingTenor;
      tempObj.interestRate = dataArray[i].interestRate;
      tempObj.interestCollection = dataArray[i].interestCollection;
      tempObj.finRequest = dataArray[i].finRequestPercentage;
      tempObj.additionalFee = dataArray[i].additionalFee;
      tempObj.description = dataArray[i].additionalDescription;
      tempObj.lastfeeCharges = dataArray[i].lastfeeCharges;
      tempObj.platformFee = dataArray[i].platformFee;
      tempObj.status = dataArray[i].status;

      if (dataArray[i].status === 'User Approval Pending') {

        var currDate = new Date();

        if (inpDate.setHours(0, 0, 0, 0) < currDate.setHours(0, 0, 0, 0)) {

          tempObj.action = <button className="btn btn-success btn-sm" onClick={() => setOfferValidityAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })} >
            <i class="fas fa-folder-open"></i>
          </button>
        }
        else {

          tempObj.action = <button className="btn btn-success btn-sm"
            onClick={() => bankerAction('pendingTab', dataArray[i])} >
            <i class="fas fa-folder-open"></i>
          </button>
        }
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }

  function PendingExportTableCSV() {

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "pending"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api -->', result);

      const dbResult = result.finData
      let csvString = "Lender Name,Requested Amount,Finance Amount,Funding Percentage,Funding Tenor,Interest Rate,Interest Collection,Finance Request(%),Additional Fees,Description,Last Fee Charges,Offer Valid Till,Status)\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].lendername ? dbResult[i].lendername : "NA"},
      ${dbResult[i].credit_amount ? dbResult[i].credit_amount : "NA"},
      ${dbResult[i].approvedFinanceAmount ? dbResult[i].approvedFinanceAmount + dbResult[i].currency.split(':')[1] : "NA"},
      ${dbResult[i].fundingPercentage ? dbResult[i].fundingPercentage + "%" : "NA"},
      ${dbResult[i].fundingTenor ? dbResult[i].fundingTenor : "NA"},
      ${dbResult[i].interestRate ? dbResult[i].interestRate : "NA"},
      ${dbResult[i].interestCollection ? dbResult[i].interestCollection : "NA"},
      ${dbResult[i].finRequestPercentage ? dbResult[i].finRequestPercentage : "NA"},
      ${dbResult[i].additionalFee ? dbResult[i].additionalFee : "NA"},
      ${dbResult[i].additionalDescription ? dbResult[i].additionalDescription : "NA"},
      ${dbResult[i].lastfeeCharges ? dbResult[i].lastfeeCharges : "NA"},
      ${dbResult[i].credit_validity ? formatDate_Application(dbResult[i].credit_validity) : "NA"},
      ${dbResult[i].status ? dbResult[i].status : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Credit_Line_Pending_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })
  }

  //-------------------------------------------------------------------------------------------------------------------
  //Approved

  function fetchApprovedTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setapprovedtableData(formatApprovedDataFortable(result.finData));
      setApprovedTotalRows(result.countdata);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handleApprovedPageChange = page => {
    fetchApprovedTableData(page, {});
  };

  const handleApprovedSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handleApprovedPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      setapprovedtableData(formatApprovedDataFortable(result.finData));
      setApprovedTotalRows(result.countdata.totalcount);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatApprovedDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      var d = new Date(dataArray[i].credit_validity);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.credit_validity = date + '/' + month + '/' + year;

      tempObj.id = dataArray[i].id;
      tempObj.lendername = dataArray[i].lendername;
      tempObj.approvedFinanceAmount = dataArray[i].approvedFinanceAmount + dataArray[i].currency.split(':')[1];
      tempObj.fundingPercentage = dataArray[i].fundingPercentage + '%';
      tempObj.fundingTenor = dataArray[i].fundingTenor;
      tempObj.interestRate = dataArray[i].interestRate;
      tempObj.interestCollection = dataArray[i].interestCollection;
      tempObj.finRequest = dataArray[i].finRequestPercentage;
      tempObj.additionalFee = dataArray[i].additionalFee;
      tempObj.description = dataArray[i].additionalDescription;
      tempObj.lastfeeCharges = dataArray[i].lastfeeCharges;
      tempObj.platformFee = dataArray[i].platformFee;
      tempObj.status = dataArray[i].status;

      resultArray.push(tempObj)
    }
    return resultArray
  }

  function ApprovedExportTableCSV() {

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api -->', result);

      const dbResult = result.finData
      let csvString = "Lender Name,Finance Amount,Funding Percentage,Funding Tenor,Interest Rate,Interest Collection,Finance Request(%),Additional Fees,Description,Last Fee Charges,Offer Valid Till,Status)\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].lendername ? dbResult[i].lendername : "NA"},
      ${dbResult[i].approvedFinanceAmount ? dbResult[i].approvedFinanceAmount : "NA"},
      ${dbResult[i].fundingPercentage ? dbResult[i].fundingPercentage + "%" : "NA"},
      ${dbResult[i].fundingTenor ? dbResult[i].fundingTenor : "NA"},
      ${dbResult[i].interestRate ? dbResult[i].interestRate : "NA"},
      ${dbResult[i].interestCollection ? dbResult[i].interestCollection : "NA"},
      ${dbResult[i].finRequestPercentage ? dbResult[i].finRequestPercentage : "NA"},
      ${dbResult[i].additionalFee ? dbResult[i].additionalFee : "NA"},
      ${dbResult[i].additionalDescription ? dbResult[i].additionalDescription : "NA"},
      ${dbResult[i].lastfeeCharges ? dbResult[i].lastfeeCharges : "NA"},
      ${dbResult[i].credit_validity ? formatDate_Application(dbResult[i].credit_validity) : "NA"},
      ${dbResult[i].status ? dbResult[i].status : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Credit_Line_Approved_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })
  }

  //--------------------------------------------------------------------------------------------------------------------
  //Rejected

  function fetchRejectedTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "rejected"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setrejectedtableData(formatRejectedDataFortable(result.finData));
      setRejectedTotalRows(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handleRejectedPageChange = page => {
    fetchRejectedTableData(page, {});
  };

  const handleRejectedSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handleRejectedPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "rejected"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      setrejectedtableData(formatRejectedDataFortable(result.finData));
      setRejectedTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatRejectedDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      var d = new Date(dataArray[i].credit_validity);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.credit_validity = date + '/' + month + '/' + year;

      tempObj.id = dataArray[i].id;
      tempObj.lendername = dataArray[i].lendername;
      tempObj.approvedFinanceAmount = dataArray[i].approvedFinanceAmount + dataArray[i].currency.split(':')[1];
      tempObj.fundingPercentage = dataArray[i].fundingPercentage + '%';
      tempObj.fundingTenor = dataArray[i].fundingTenor;
      tempObj.interestRate = dataArray[i].interestRate;
      tempObj.interestCollection = dataArray[i].interestCollection;
      tempObj.finRequest = dataArray[i].finRequestPercentage;
      tempObj.additionalFee = dataArray[i].additionalFee;
      tempObj.description = dataArray[i].additionalDescription;
      tempObj.lastfeeCharges = dataArray[i].lastfeeCharges;
      tempObj.platformFee = dataArray[i].platformFee;
      tempObj.status = dataArray[i].status;

      if (dataArray[i].status === 'User Rejected') {
        tempObj.action = <button className="btn btn-primary btn-sm ml-1" onClick={() => setPendingAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })} >
          <i className={"fas fa-eye"} aria-hidden="true"></i>
        </button>
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }

  function RejectedExportTableCSV() {

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "rejected"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api -->', result);

      const dbResult = result.finData
      let csvString = "Lender Name,Finance Amount,Funding Percentage,Funding Tenor,Interest Rate,Interest Collection,Finance Request(%),Additional Fees,Description,Last Fee Charges,Offer Valid Till,Status)\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let rowString = `${dbResult[i].lendername ? dbResult[i].lendername : "NA"},
      ${dbResult[i].approvedFinanceAmount ? dbResult[i].approvedFinanceAmount : "NA"},
      ${dbResult[i].fundingPercentage ? dbResult[i].fundingPercentage + "%" : "NA"},
      ${dbResult[i].fundingTenor ? dbResult[i].fundingTenor : "NA"},
      ${dbResult[i].interestRate ? dbResult[i].interestRate : "NA"},
      ${dbResult[i].interestCollection ? dbResult[i].interestCollection : "NA"},
      ${dbResult[i].finRequestPercentage ? dbResult[i].finRequestPercentage : "NA"},
      ${dbResult[i].additionalFee ? dbResult[i].additionalFee : "NA"},
      ${dbResult[i].additionalDescription ? dbResult[i].additionalDescription : "NA"},
      ${dbResult[i].lastfeeCharges ? dbResult[i].lastfeeCharges : "NA"},
      ${dbResult[i].credit_validity ? formatDate_Application(dbResult[i].credit_validity) : "NA"},
      ${dbResult[i].status ? dbResult[i].status : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Credit_Line_Rejected_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    })
  }

  //-----------------------------------------------------------------------------------------------------------------
  //Completed

  function fetchCompletedTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "completed"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setcompletedtableData(formatCompletedDataFortable(result.finData));
      setCompletedTotalRows(result.countdata.totalcount);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handleCompletedPageChange = page => {
    fetchCompletedTableData(page, {});
  };

  const handleCompletedSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handleCompletedPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "completed"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      setcompletedtableData(formatCompletedDataFortable(result.finData));
      setCompletedTotalRows(result.countdata.totalcount);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatCompletedDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      var d = new Date(dataArray[i].credit_validity);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.credit_validity = date + '/' + month + '/' + year;

      tempObj.id = dataArray[i].id;
      tempObj.lendername = dataArray[i].lendername;
      tempObj.approvedFinanceAmount = dataArray[i].approvedFinanceAmount;
      tempObj.fundingPercentage = dataArray[i].fundingPercentage + '%';
      tempObj.fundingTenor = dataArray[i].fundingTenor;
      tempObj.interestRate = dataArray[i].interestRate;
      tempObj.interestCollection = dataArray[i].interestCollection;
      tempObj.finRequest = dataArray[i].finRequestPercentage;
      tempObj.additionalFee = dataArray[i].additionalFee;
      tempObj.description = dataArray[i].additionalDescription;
      tempObj.lastfeeCharges = dataArray[i].lastfeeCharges;
      tempObj.platformFee = dataArray[i].platformFee;
      tempObj.status = dataArray[i].status;

      if (dataArray[i].status === 'Completed') {
        tempObj.action = 'N/A'
        // <button className="btn btn-primary btn-sm ml-1" onClick={() => setPendingAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })} >
        //   <i className={"fas fa-eye"} aria-hidden="true"></i>
        // </button>
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }


  //-------------------------------------------------------------------------------------------------------------------
  //Agreement

  function fetchAgreementTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "userName": userName,
      "userEmail": userEmail,
      "gridType": "agreement"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditOfferDocDetail', objectAPI).then((result) => {
      console.log('running getCreditOfferDocDetail api-->', result);
      setagreementtableData(formatAgreementDataFortable(result));
      // setagreementTotalRows(result.countdata);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditOfferDocDetail', e);
      setLoading(false)
    })
  }

  const handleAgreementPageChange = page => {
    fetchAgreementTableData(page, {});
  };

  const handleAgreementSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handleAgreementPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "userTypeId": userTypeId,
      "userId": userId,
      "userName": userName,
      "userEmail": userEmail,
      "gridType": "agreement"
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditOfferDocDetail', objectAPI).then((result) => {
      setagreementtableData(formatAgreementDataFortable(result));
      // setagreementTotalRows(result.countdata.totalcount);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditOfferDocDetail', e);
      setLoading(false)
    })
  };

  function formatAgreementDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let userDetail = JSON.parse(dataArray[i].userDetail);
      for (let j = 0; j < userDetail.length; j++) {
        if (userDetail[j].doc_name == 'Framework') {
          let tempObj = {}
          tempObj.id = dataArray[i].id;
          tempObj.agreementname = 'Framework Agreement';
          tempObj.uploadedBy = dataArray[i].lendername;
          tempObj.uploadedOn = formatDate_Application(dataArray[i].created_at);
          tempObj.signedBy = (userDetail[j] && userDetail[j].sign) ? <pre><span className="text-success mr-1">{userDetail[j].sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''
          tempObj.actions = <div>
            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(userDetail[j].doc_id, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(userDetail[j].doc_id, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {/* <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => callAclAuthComponent(userDetail[j].doc_id)}>
              <i class="fas fa-file-signature"></i>
            </button> */}
          </div>

          resultArray.push(tempObj)
        }
      }
    }
    return resultArray
  }

  //-----------------------------------------------------------------------------------------------------------------
  // Add Documents

  function addDocumentRow() {
    if (documentRow.data.length < 5) {
      documentRow.data.push({ name: null });
      setDocumentRow({ ...documentRow });
    }
  }

  function deleteDocumentRow(ind) {
    documentRow.data.splice(ind, 1)
    setDocumentRow({ ...documentRow })
  }

  function getDocumentRow() {
    let rowsArr = [];
    for (let i = 0; i < documentRow.data.length; i++) {
      rowsArr.push(
        <>
          <div className="modal-padding" id={"CreditLine:49"}>
            <ul className="other-documents pb-0 mb-0">
              <li >
                <label className="mb-0" >
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                </label>
              </li>
              <li>
                <input type="text" className={" form-control" + (!error.documentName ? '' : ' border-danger')} placeholder="Document Name" name="documentName" value={data.multipledocumentName} onChange={(event) => handledocumentChange(event, i)} required />
                {error.documentName && <p className="text-danger error-contract">{error.documentName}</p>}
              </li>
              <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                <div className="file-browse">
                  <button type="button" className="btn btn-primary btn-sm">Upload Document</button>
                  <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={documentRow.data[i].documentName + ":49"} onChange={(event) => handledocumentFiles(event, i)} />
                  {error[documentRow.data[i].dirContractDocument] && <p lassName="text-danger error-contract">{error[documentRow.data[i].dirContractDocument]}</p>}
                </div>
              </li>
              <li style={{ "min-width": "150px" }}>
                {(documentRow.data[i][documentRow.data[i].documentName + ":49"]) ? <div className="filePath text-left"><div className="file-name">{`${documentRow.data[i][documentRow.data[i].documentName + ":49"].name}`}</div></div> : ""}
              </li>
              <li>
                <div className="col-md-1">
                  <span className="field">
                    <i className="fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteDocumentRow(i)}></i>
                  </span>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </div>
        </>
      )
    }
    return rowsArr;
  }

  const handledocumentChange = (event, ind) => {
    if (event.target.name === 'name') {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    } else {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    }
    setDocumentRow({ ...documentRow })
  };

  const handledocumentFiles = (event, ind) => {
    event.persist();
    documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.files[0] }
    setDocumentRow({ ...documentRow });
  };


  //---------------------------------------------------------------------------------------------------------------------

  const handleFiles = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  const preHandleSubmit = (event) => {
    data.lender = revisionAction.data.allData.company_name;
    data.bank_id = revisionAction.data.allData.bank_id;
    data.id = revisionAction.data.id;
    if (event) event.preventDefault();
    setError(validate(data, documentRow));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = userId
    data.userEmail = userEmail

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    let docName = [];

    Object.keys(documentRow.data).forEach((key) => {
      if (documentRow.data[key].documentName) {
        docName.push(documentRow.data[key].documentName);
      }
      formData.append('documentName', docName);

      if (documentRow.data[key][documentRow.data[key].documentName + ":49"]) {
        formData.append((documentRow.data[key].documentName + ":49"), documentRow.data[key][documentRow.data[key].documentName + ":49"])
      }
    });

    call('POST', "insertCreditLineByUser", formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function bankerAction(tab, allData) {
    setCreditlineDataBank(true, { tab, allData });
    setcreditLineDetails(true, { action: tab })
  }

  function documentAction(userid, tabType) {
    setCreditDocuments(true, { action: userid, tabType: tabType })
  }

  function lenderDocuments() {
    for (let i = 0; i < creditUserDetail.length; i++) {
      if (creditUserDetail[i].userid === revisionAction.data.allData.id) {
        let docJson = creditUserDetail[i].userDetail;
        let keysArray = Object.keys(docJson)
        let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
          return (<>
            {docJson[keyValue].map((arrayElement) => {
              return (
                <div className="row pt-2">
                  <div className="form-group col-md-6">

                    <div className="modal-padding" id={keyValue + ":49"}>
                      <ul className="other-documents pb-0 mb-0">
                        <li style={{ "min-width": "200px" }}>
                          <label className="mb-0" >
                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                            {keyValue} {astrix}
                          </label>
                        </li>
                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                          <div className="file-browse">
                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={keyValue + ":49"} onChange={handleFiles} />
                            {error[keyValue + ":49"] && <p lassName="text-danger error-contract">{error[keyValue + ":49"]}</p>}
                          </div>
                        </li>
                        <li style={{ "min-width": "200px" }}>
                          {(data[keyValue + ":49"]) ? <div className="filePath text-left"><div className="file-name">{`${data[keyValue + ":49"].name}`}</div></div> : ""}
                        </li>
                      </ul>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              )
            })}
          </>)
        }) : ""
        return (returnJSX)
      }
    }
  }
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'creditlineview'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={(creditLineDetails.info.action === "applycreditLine") ? `Apply for Credit Line` :
                (creditLineDetails.info.action === 'pendingTab') ? "Offer's Section" :
                  (CreditDocuments.info.hasOwnProperty('action')) ? "Credit Document Details" :
                    'Credit Line Details'
              }
              userTokenDetails={userTokenDetails} />
            {creditLineDetails.info.action === "applycreditLine" ?
              <CreateCreditLine
                userTokenDetails={userTokenDetails} creditLineDetails={creditLineDetails} setcreditLineDetails={setcreditLineDetails} />
              :
              creditLineDetails.info.action === 'pendingTab' ?
                <LenderOffer userTokenDetails={userTokenDetails} manualUserId={creditlineDataBank.info.userId}
                  manualUserName={creditlineDataBank.info.userName}
                  manualUserEmail={creditlineDataBank.info.userEmail} creditlineDataBank={creditlineDataBank} />
                :
                CreditDocuments.info.hasOwnProperty('action') ?
                  <CreditDocument userTokenDetails={userTokenDetails} manualUserId={userId}
                    manualUserName={userName}
                    manualUserEmail={userEmail} userDetail={userDetail} userPendingDetail={allCreditDocuments} CreditDocuments={CreditDocuments} />
                  :
                  <>
                    <div className="d-md-flex mt-3 mb-3 notify_list">
                      <div className="ml-md-auto relative">
                      </div>
                    </div>
                    <div className="creditline_listing">
                      <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {

                            setTab(0);
                            setTabName("Request");
                          }}>Request</a>
                        </li>
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                            setTab(1);
                            setTabName("Pending");
                          }}>Pending</a>
                        </li>
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                            setTab(2);
                            setTabName("Approved");
                          }}>Approved</a>
                        </li>
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 3 ? " active show" : "")} onClick={() => {
                            setTab(3);
                            setTabName("Completed");
                          }}>Completed</a>
                        </li>
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 4 ? " active show" : "")} onClick={() => {
                            setTab(4);
                            setTabName("Rejected");
                          }}>Rejected</a>
                        </li>
                        <li>
                          <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 5 ? " active show" : "")} onClick={() => {
                            setTab(5);
                            setTabName("One-Time Agreement");
                          }}>One-Time Agreement</a>
                        </li>
                      </ul>

                      <>
                        {tab === 0 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={columns}
                                      data={tableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={totalRows}
                                      onChangeRowsPerPage={handlePerRowsChange}
                                      onChangePage={handlePageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      // expandableRows
                                      // expandableRowsComponent={<TableExpandedComponent />}
                                      // expandOnRowClicked
                                      highlightOnHover
                                      onSort={handleSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                  <hr />
                                  <div className="float-left col-md-6 ">
                                    <div className="col-md-12 row">
                                      <div className="col-md-6">
                                        <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ReqExportTableCSV()}>
                                          <i class="fas fa-file-csv"></i> Export csv
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {tab === 1 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={pendingcolumns}
                                      data={pendingtableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={pendingtotalRows}
                                      onChangeRowsPerPage={handlePendingPerRowsChange}
                                      onChangePage={handlePendingPageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      expandableRows
                                      expandableRowsComponent={<TablePendingExpandedComponent />}
                                      expandOnRowClicked
                                      highlightOnHover
                                      onSort={handlePendingSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                  <hr />
                                  <div className="float-left col-md-6 ">
                                    <div className="col-md-12 row">
                                      <div className="col-md-6">
                                        <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => PendingExportTableCSV()}>
                                          <i class="fas fa-file-csv"></i> Export csv
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {tab === 2 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={rejectcolumns}
                                      data={approvedtableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={approvedtotalRows}
                                      onChangeRowsPerPage={handleApprovedPerRowsChange}
                                      onChangePage={handleApprovedPageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      expandableRows
                                      expandableRowsComponent={<TablePendingExpandedComponent />}
                                      expandOnRowClicked
                                      highlightOnHover
                                      onSort={handleApprovedSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                  <hr />
                                  <div className="float-left col-md-6 ">
                                    <div className="col-md-12 row">
                                      <div className="col-md-6">
                                        <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ApprovedExportTableCSV()}>
                                          <i class="fas fa-file-csv"></i> Export csv
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {tab === 3 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={rejectcolumns}
                                      data={completedtableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={completedtotalRows}
                                      onChangeRowsPerPage={handleCompletedPerRowsChange}
                                      onChangePage={handleCompletedPageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      expandableRows
                                      expandableRowsComponent={<TablePendingExpandedComponent />}
                                      expandOnRowClicked
                                      highlightOnHover
                                      onSort={handleCompletedSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {tab === 4 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={rejectcolumns}
                                      data={rejectedtableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={rejectedtotalRows}
                                      onChangeRowsPerPage={handleRejectedPerRowsChange}
                                      onChangePage={handleRejectedPageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      expandableRows
                                      expandableRowsComponent={<TablePendingExpandedComponent />}
                                      expandOnRowClicked
                                      highlightOnHover
                                      onSort={handleRejectedSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                  <hr />
                                  <div className="float-left col-md-6 ">
                                    <div className="col-md-12 row">
                                      <div className="col-md-6">
                                        <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => RejectedExportTableCSV()}>
                                          <i class="fas fa-file-csv"></i> Export csv
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {tab === 5 &&
                          <>
                            <div className="calc-inner-modal">
                              <div className="modal-padding">
                                <div className="row d-flex mt-5">
                                  <div className="table-responsive">
                                    <DataTable
                                      columns={agreementcolumns}
                                      data={agreementtableData}
                                      progressPending={loading}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={agreementtotalRows}
                                      onChangeRowsPerPage={handleAgreementPerRowsChange}
                                      onChangePage={handleAgreementPageChange}
                                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                                      persistTableHead
                                      // expandableRows
                                      // expandableRowsComponent={<TablePendingExpandedComponent />}
                                      // expandOnRowClicked
                                      highlightOnHover
                                      onSort={handleAgreementSort}
                                      sortServer
                                      striped
                                      noHeader
                                      customStyles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </>

                    </div>
                  </>}
            {showPreviewModal && <FilePreview
              userTokenDetails={userTokenDetails}
              fileData={fileData}
              showPreviewModal={showPreviewModal}
              setshowPreviewModal={setshowPreviewModal}
              viewTrail={false}
            />}

            {showAclAuthModal &&
              <DigitalSignDoc
                userTokenDetails={userTokenDetails}
                fileData={fileData}
                showAclAuthModal={showAclAuthModal}
                setAclAuthModal={setAclAuthModal}
                refreshDoc={refresh}
                setrefreshDoc={setrefresh} />}

            {revisionAction.modal &&
              <div className={"modal" + (revisionAction.modal ? " show" : "")} id="ApplyCredit">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Credit Line Form</h4>
                      <button type="button" className="close" onClick={() => { setRevisionAction({ modal: false, data: {} }) }}>×</button>
                    </div>
                    <div className="modal-body calc-modal">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                      <div className="calc-inner-modal">

                        {/* 1st Section */}

                        <div className="accordionWrapper row pr-0 pt-0">
                          <div className="container-fluid accordionItem open">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h3 className="green mt-3">
                                      Select Lender
                                    </h3>
                                    <hr />
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row pt-2">

                                      <div className="form-group col-md-6">
                                        <label>Lender Name {astrix} </label>
                                        <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={revisionAction.data.allData.company_name} />
                                        {error.lendername && <p className="text-danger error-contract">{error.lendername}</p>}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* 2nd Section */}

                        <div className="accordionWrapper row pr-0 pt-0">
                          <div className="container-fluid accordionItem open">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h3 className="green mt-3">
                                      {revisionAction.data.allData.company_name} Documents
                                    </h3>
                                    <hr />
                                  </div>
                                  <div className="col-md-12">
                                    {
                                      lenderDocuments()
                                    }

                                    <div className="row pt-2">
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <div className="form-group col-md-6">
                                            <div className="col-md-12">
                                              <button className="btn btn-primary btn-sm" onClick={() => addDocumentRow()} >Add More Documents</button>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="form-group col-md-6">
                                            <div className="col-md-12">
                                              <label>(Note: You can add total 5 documents. Total Count left : {5 - (documentRow.data.length)})</label>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    {
                                      getDocumentRow()
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* 3rd Section */}

                        <div className="accordionWrapper row pr-0 pt-0">
                          <div className="container-fluid accordionItem open">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h3 className="green mt-3">
                                      Credit Line Details
                                    </h3>
                                    <hr />
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row pt-2">

                                      <div className="form-group col-md-4">
                                        <label>Credit Amount{astrix} </label>
                                        <input type="number" className={" form-control" + (!error.creditAmount ? '' : ' border-danger')} placeholder="" name="creditAmount" value={data.creditAmount} onChange={handleChange} />
                                        {error.creditAmount && <p className="text-danger error-contract">{error.creditAmount}</p>}
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-check pl-0 mt-1 mb-1">
                                          <input className="form-check-input" type="checkbox" name="companyProfile" id="companyProfile" onChange={hanleDocChecks} checked={data.companyProfile} />
                                          <label className="form-check-label" for="companyProfile">
                                            I accept/Agree to share my Company Profile, KYC documents and Financial Details with lender.
                                          </label>
                                        </div>
                                      </div>

                                      {/* <div className="col-md-12">
                                        <div className="form-check pl-0 mt-1 mb-1">
                                          <input className="form-check-input" type="checkbox" name="kycDocument" id="kycDocument" onChange={hanleDocChecks} checked={data.kycDocument} />
                                          <label className="form-check-label" for="kycDocument">
                                            I accept/Agree to share my KYC documents with lender.
                              </label>
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-check pl-0 mt-1 mb-1">
                                          <input className="form-check-input" type="checkbox" name="finDetails" id="finDetails" onChange={hanleDocChecks} checked={data.finDetails} />
                                          <label className="form-check-label" for="finDetails">
                                            I accept/Agree to share my Financial details with lender.
                              </label>
                                        </div>
                                      </div> */}
                                      <br />
                                      <br />
                                      <hr className="pt-1 pb-1" />

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer primary">
                      {(data.companyProfile == true) &&
                        <button type="button" className="btn btn-primary btn-sm ml-1" onClick={() => preHandleSubmit()} >Submit</button>
                      }
                      <button type="button" className="btn btn-info btn-sm" onClick={() => setRevisionAction({ modal: false, data: {} })} >Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            }

            {offerValidityAction.modal &&
              <div className={"modal" + (offerValidityAction.modal ? " show" : "")} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Action Form</h4>
                      <button type="button" className="close" onClick={() => { setOfferValidityAction({ modal: false, data: {} }) }}>×</button>
                    </div>
                    <div className="modal-body">

                      <div className="col-md-12 text-center">
                        <h3> {`${userName} - Credit Details`} </h3>
                      </div>
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-12 text-center">
                            <label className="form-check-label text-danger">Sorry but your offer validity was till {offerValidityAction.data.allData.credit_validity}</label>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>}


            {kycAction &&
              (dashData && dashData.userDetails) &&
              (dashData.userDetails.company_gst_verification !== "true" || dashData.userDetails.company_iec_verification !== "true") &&
              <div className={"modal show"} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Verification</h4>
                      <a href={'/dashboard'}>
                        <button type="button" className="close" >×</button>
                      </a>
                    </div>
                    <div className="modal-body">

                      <div className="col-md-12 text-center">
                        <h3> KYC Verification Alert </h3>
                      </div>
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12">
                        <div className="row">

                          <div className="col-md-12 text-center">
                            <label className="form-check-label text-danger">{`Hey "${dashData.userDetails.company_name}" Your KYC is not verified. Please complete your KYC process.`}</label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="modal-footer primary">
                      <a href={'/editprofile'}>
                        <button className="btn btn-info btn-sm">Go to KYC Verification</button>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            }
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    creditlineDataBank: state.creditlineDataBank,
    navToggleState: state.navToggleState,
    creditLineDetails: state.creditLineDetails,
    CreditDocuments: state.CreditDocuments,
    creditlineDataBank: state.creditlineDataBank
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setcreditLineDetails: (modal, info) => { dispatch(setcreditLineDetails({ modal: modal, info: info })) },
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
    setCreditDocuments: (modal, info) => { dispatch(setCreditDocuments({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCreditLine)
