import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import { dateFormatter_DatePicker } from '../../utils/dateFormaters';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/bussinessDetailsComp';
import FilePreview from '../utilComponents/previewFile'
import { formatDate_Application, } from '../../utils/dateFormaters';

const docsFormArray = [
  { "key": "MOM_Document", "name": "MOA Document", "dbId": ":9:44" },
  { "key": "AOA_Document", "name": "AOA Document", "dbId": ":9:45" },
  { "key": "Shareholding_Document", "name": "Shareholding Partners Details", "dbId": ":9:46" },
  { "key": "Partnership_Deed_Document", "name": "Partnership Deed", "dbId": ":9:47" },
  { "key": "Company_Profile_Document", "name": "Company Profile Document", "dbId": ":9:48" },
]

const BussinessDetailsComp = ({ userTokenDetails, userProfileDataG, compCall, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [showLoader, setshowLoader] = useState(false);
  const [update, setupdate] = useState(false);
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [detailModalStatus, setdetailModalStatus] = useState(true);
  const [docModalStatus, setdocModalStatus] = useState(true);
  const [docData, setdocData] = useState([])
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [fileData, setfileData] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [countryIncorporation, setCountryIncorporation] = useState([]);


  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null

  // const astrix = <span className="required-field text-danger">*</span>
  //------------------------------------------------------------------
  //use Form func/comp imports

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    setshowLoader(true)
    //API Calls
    call('GET', 'getuserdetailsextra').then((result) => {
      console.log("result in getuserdetailsextra api->", result)
      if (result.id) {
        setupdate(true)
      }
      // console.log("userProfileDataG in getuserdetailsextra api->", userProfileDataG)

      setData({
        companyName: userProfileDataG.company_name,
        website: result.userDetails.website,
        entityType: result.userDetails.entity_type,
        businessType: result.userDetails.business_type,
        industryType: result.userDetails.industry_type,
        dunsNo: result.userDetails.dunsNo,
        minBusiness: result.userDetails.minExisting,
        maxBusiness: result.userDetails.minExpected,
        cinNo: userProfileDataG.cin_no,
        gstNo: userProfileDataG.gst_vat_no,
        panNo: userProfileDataG.pan_no,
        iecNo: userProfileDataG.iec_no,
        licenseNo: userProfileDataG.license_no,
        ifscCode: userProfileDataG.ifsc_no,
        dateIncorporation: dateFormatter_DatePicker(result.userDetails.date_of_incorporation),
        countryIncorporation: result.userDetails.country_of_incorporation,
        countryOperation: result.userDetails.country_of_operation,
        companyContactNo: result.userDetails.company_contact_no,
        companyEmail: result.userDetails.company_email,
        companyAddress: result.userDetails.business_address
      })

      setCountryIncorporation(result.userDetails.country_of_incorporation);

      setdocData(result.docDetails)

      setshowLoader(false)

    }).catch((e) => {
      setshowLoader(false)

      console.log("Error while querying getBanksListMaster:", e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

  }, [refresh]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit("details");
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const handleFile = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
    setErrors(errors => ({ ...errors, [event.target.name]: "" }))
  }


  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };


  function handleSubmit(type) {
    setshowLoader(true)

    let formData = new FormData();
    let docError = {}
    if (type === "docs") {
      for (let i = 0; i < docsFormArray.length; i++) {
        if (!values[docsFormArray[i].key + docsFormArray[i].dbId]) {
          docError[docsFormArray[i].key + docsFormArray[i].dbId] = "File Missing"
        }
      }

      if (Object.keys(docError).length) {
        setErrors(docError)
        setshowLoader(false)
        return null
      } else {
        values.userId = userId
        values.userEmail = userEmail
        values.updatingfiles = 1

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
      }

    } else {
      data.userId = userId
      data.userEmail = userEmail
    }


    call('POST', update ? 'updateuserdetailsextra' : 'insertuserdetailsextra', (type === "docs") ? formData : data).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 1)
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }



  function docGridCreator(docArray) {
    let docElements = docArray ? docArray.map((values, index) => {
      // console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.category_name) ? values.category_name : 'NA'}</td>
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="row justify-content-end  m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>

          {/* <div className="file-browse ">
            <button title="Update File" className="text-center btn btn-success btn-sm m-1">
              <i className="fa fa-upload" aria-hidden="true"></i>
            </button>
            <input type="file" accept=".png,.jpg,.pdf" name={"doctype" + values.doc_type} onChange={(event) => updateDocument(event, values)} />
          </div> */}

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {detailModalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Business Details
                      <button type="button" onClick={() => setdetailModalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-times fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">

                    <div className="row pt-2">

                      <div className="form-group col-md-6">
                        <label>Company Name</label>
                        <input type="text" className={" form-control" + (!error.companyName ? '' : ' border-danger')} placeholder="" name="companyName" value={data.companyName} onChange={handleChange} required disabled={true} />
                        {error.companyName && <p className="text-danger error-contract">{error.companyName}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Website </label>
                        <input type="text" className={" form-control" + (!error.website ? '' : ' border-danger')} placeholder="" name="website" value={data.website} onChange={handleChange} required />
                        {error.website && <p className="text-danger error-contract">{error.website}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Entity Type</label>
                        <select className={" form-control" + (!errors.entityType ? '' : ' border-danger')} name="entityType" value={data.entityType} onChange={handleChange} >
                          <option value="" selected>Select Type</option>
                          <option value="privateLtd">Private Limited</option>
                          <option value="publicLtd">Public Limited</option>
                          <option value="jointLtd">Joint Limited</option>
                        </select>
                        {error.entityType && <p className="text-danger error-contract">{error.entityType}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Business Type (metal, agriculture etc)  </label>
                        <input type="text" className={" form-control" + (!error.businessType ? '' : ' border-danger')} placeholder="" name="businessType" value={data.businessType} onChange={handleChange} required />
                        {error.businessType && <p className="text-danger error-contract">{error.businessType}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Industry Type (metal, agriculture etc)  </label>
                        <input type="text" className={" form-control" + (!error.industryType ? '' : ' border-danger')} placeholder="" name="industryType" value={data.industryType} onChange={handleChange} required />
                        {error.industryType && <p className="text-danger error-contract">{error.industryType}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>CIN No. </label>
                        <input type="text" className={" form-control" + (!error.cinNo ? '' : ' border-danger')} placeholder="" name="cinNo" value={data.cinNo} onChange={handleChange} required disabled={true} />
                        {error.cinNo && <p className="text-danger error-contract">{error.cinNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>GST No. </label>
                        <input type="text" className={" form-control" + (!error.gstNo ? '' : ' border-danger')} placeholder="" name="gstNo" value={data.gstNo} onChange={handleChange} required disabled={true} />
                        {error.gstNo && <p className="text-danger error-contract">{error.gstNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>PAN No. </label>
                        <input type="text" className={" form-control" + (!error.panNo ? '' : ' border-danger')} placeholder="" name="panNo" value={data.panNo} onChange={handleChange} required disabled={true} />
                        {error.panNo && <p className="text-danger error-contract">{error.panNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>IEC No. </label>
                        <input type="text" className={" form-control" + (!error.iecNo ? '' : ' border-danger')} placeholder="" name="iecNo" value={data.iecNo} onChange={handleChange} required disabled={true} />
                        {error.iecNo && <p className="text-danger error-contract">{error.iecNo}</p>}
                      </div>

                      {[6, 12, 11, 5, 13].includes(userTypeId / 1) &&
                        <div className="form-group col-md-6">
                          <label>License No. </label>
                          <input type="text" className={" form-control" + (!error.licenseNo ? '' : ' border-danger')} placeholder="" name="licenseNo" value={data.licenseNo} onChange={handleChange} required disabled={true} />
                          {error.licenseNo && <p className="text-danger error-contract">{error.licenseNo}</p>}
                        </div>}

                      {(userTypeId / 1 === 8) &&
                        <>
                          <div className="form-group col-md-6">
                            <label>IFSC Code. </label>
                            <input type="text" className={" form-control" + (!error.ifscCode ? '' : ' border-danger')} placeholder="" name="iecNo" value={data.ifscCode} onChange={handleChange} required disabled={true} />
                            {error.ifscCode && <p className="text-danger error-contract">{error.ifscCode}</p>}
                          </div>
                        </>}

                      <div className="form-group col-md-6 flag-relative" id="POScountry">
                        <div className="flag-icon col-md-3">
                          {data.countryIncorporation ? <span className="shadow">
                            <ReactCountryFlag
                              countryCode={data.countryIncorporation}
                              style={{ width: '25px', height: '25px' }} svg />
                          </span> :
                            <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image" />}
                        </div>
                        <label>Country of Incorporation </label>
                        <select className={" form-control" + (!errors.countryIncorporation ? '' : ' border-danger')} name="countryIncorporation" value={data.countryIncorporation} onChange={handleChange} >
                          <option value="" selected>Select Country</option>
                          {countryData.map((country) => {
                            return (
                              <option value={country.sortname} >{country.name}</option>
                            )
                          })}
                        </select>
                        {errors.countryIncorporation && <p className="text-danger error-contract">{errors.countryIncorporation}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Country of Operation </label>
                        <input type="text" className={" form-control" + (!error.countryOperation ? '' : ' border-danger')} placeholder="" name="countryOperation" value={data.countryOperation} onChange={handleChange} required disabled={true} />
                        {error.countryOperation && <p className="text-danger error-contract">{error.countryOperation}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Company Contact No</label>
                        <input type="number" min="1000000001" min="9999999999" className={" form-control" + (!error.companyContactNo ? '' : ' border-danger')} placeholder="" name="companyContactNo" value={data.companyContactNo} onChange={handleChange} required />
                        {error.companyContactNo && <p className="text-danger error-contract">{error.companyContactNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Company Email</label>
                        <input type="text" className={" form-control" + (!error.companyEmail ? '' : ' border-danger')} placeholder="" name="companyEmail" value={data.companyEmail} onChange={handleChange} required />
                        {error.companyEmail && <p className="text-danger error-contract">{error.companyEmail}</p>}
                      </div>

                      {(userTypeId !== 8) &&
                        <div className="form-group col-md-6">
                          <label>DUNS No (optional) </label>
                          <input type="number" className={" form-control" + (!error.dunsNo ? '' : ' border-danger')} placeholder="" name="dunsNo" value={data.dunsNo} onChange={handleChange} />
                          {error.dunsNo && <p className="text-danger error-contract">{error.dunsNo}</p>}
                        </div>}

                      {(userTypeId !== 8) &&
                        <div className="form-group col-md-6">
                          <label>Minimum Exisiting business with buyer (in USD) </label>
                          <input type="number" className={" form-control" + (!error.minBusiness ? '' : ' border-danger')} placeholder="" name="minBusiness" value={data.minBusiness} onChange={handleChange} />
                          {error.minBusiness && <p className="text-danger error-contract">{error.minBusiness}</p>}
                        </div>}

                      {(userTypeId !== 8) &&
                        <div className="form-group col-md-6">
                          <label>Minimum Expected Business with buyer (in USD) </label>
                          <input type="number" className={" form-control" + (!error.maxBusiness ? '' : ' border-danger')} placeholder="" name="maxBusiness" value={data.maxBusiness} onChange={handleChange} />
                          {error.maxBusiness && <p className="text-danger error-contract">{error.maxBusiness}</p>}
                        </div>}

                      <div className="form-group col-md-12">
                        <label>Address </label>
                        <textarea className={" form-control" + (!error.companyAddress ? '' : ' border-danger')} placeholder="Enter Flat / House / Office No" name="address" onChange={handleChange} value={data.companyAddress} required disabled={true} />
                        {error.companyAddress && <p className="text-danger error-contract">{error.companyAddress}</p>}
                      </div>

                      <hr className="col-md-11" />
                      <div className="form-group col-md-12">
                        <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => preHandleSubmit()}>{update ? "Update" : "Submit"}</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              Business Details
              <button type="button" onClick={() => setdetailModalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>}

      {docModalStatus ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Business Documents
                      <button type="button" onClick={() => setdocModalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-times fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">

                    {docData.length > 0 ? <>
                      <div className="col-md-12">
                        <div className="table-responsive" style={{ "min-height": "150px" }}>
                          <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                            <thead>
                              <tr>
                                <th width="20">#</th>
                                <th>Type of Document</th>
                                <th width="300">Document Name</th>
                                <th width="200">Uploaded By</th>
                                <th width="200">Uploaded On</th>
                                <th className="text-center" width="150">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {docGridCreator(docData)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </> :
                      <>
                        {docsFormArray.map((item) => {
                          return (<>
                            <div className="form-group pb-0 mb-0" id={item.key}>
                              <ul className="other-documents pb-0 mb-0">
                                <li style={{ "min-width": "300px" }}>
                                  <label className="mb-0" >
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                    {item.name}
                                    <span className="required-field text-danger">*</span>
                                  </label>
                                </li>
                                <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                  <div className="file-browse">
                                    <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" id="file_1" name={item.key + item.dbId} onChange={handleFile} />
                                    {errors[item.key + item.dbId] && <p className="error-contract p-0 m-0">{errors[item.key + item.dbId]}</p>}
                                  </div>
                                </li>
                                <li style={{ "min-width": "500px" }}>
                                  {(values[item.key + item.dbId]) ? <div className="filePath text-left"><div className="file-name">{`${values[item.key + item.dbId].name}`}</div></div> : ""}
                                </li>
                              </ul>
                              <hr className="" />
                            </div>
                          </>)
                        })}

                        <div className="form-group col-md-12">
                          <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => handleSubmit("docs")}>Submit</button>
                        </div>

                      </>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              Business Documents
              <button type="button" onClick={() => setdocModalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>}

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}

    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BussinessDetailsComp)
