import React, { useEffect, useState } from 'react';
import { setAddDirectory } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import ReactCountryFlag from "react-country-flag";
import validate from '../../utils/validation_files/addUserInDirectory';


const AddUserInDir = ({ userTokenDetails, addDirectory, setAddDirectory, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [addEnable, setaddEnable] = useState(false);
  const [userExistData, setuserExistData] = useState({});
  const [dbTypeData, setdbTypeData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [countryData, setcountryData] = useState([]);

  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function checkEmailId() {

    let tempError = {}

    if (!data.dirEmail) {
      tempError.dirEmail = 'Email ID is required';
    } else if (!/\S+@\S+\.\S+/.test(data.dirEmail)) {
      tempError.dirEmail = 'Email ID is invalid';
    }

    setError(tempError)

    if (!tempError.dirEmail) {
      call('post', 'getdirectoryuser', { dirEmail: data.dirEmail }).then((result) => {
        if (result.id) {
          setuserExistData(result)
        } else {
          setaddEnable(true);
        }
      }).catch((e) => {
        console.log('error in getuserstypelist', e);
      })
    }

  }
  //---------------------------------------------------------------------------------------------------------------------

  console.log('userExistData---->', userExistData);
  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler

  const handleSubmit = () => {

    setshowLoader(true)
    let postObj = {
      existingUser: !addEnable,
      userData: addEnable ? { ...data } : { ...userExistData }
    }

    call('POST', 'adduserindirectory', postObj).then((result) => {
      if (result) {
        setshowLoader(false)
        setAddDirectory(false, { refresh: true })
        toastDisplay(result, "success")
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, userExistData));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {addDirectory.modal &&
        <div className={"modal" + (addDirectory.modal ? " show" : "")} id="shipmodal">
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Add New User in Directory</h4>
                <button type="button" className="close" onClick={() => { setAddDirectory(false, {}); }}>×</button>
              </div>
              <div className="modal-body calc-inner-modal">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div className="col-md-12 m-2 row">

                  <div className="col-md-12">
                    <div className="row text-center form-group">
                      <label className="col-md-12">Email Id {astrix} </label>
                      <div className="col-md-12">
                        <input type="text" name="dirEmail" value={data.dirEmail} placeholder="Enter Email Id" className={" form-control" + (!error.dirEmail ? '' : ' border-danger')} onChange={handleChange} />
                        {error.dirEmail ? <div class="text-danger error-contract">{error.dirEmail}</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button className="btn btn-sm btn-primary btn-sm" onClick={() => checkEmailId()} >Go</button>
                  </div>
                  <hr className="col-md-11" />

                  {(data.dirEmail && addEnable) ? <>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Company Name {astrix} </label>
                        <div className="col-md-12">
                          <input type="text" name="dirCompanyName" value={data.dirCompanyName} placeholder="Enter Company Name" className={" form-control" + (!error.dirCompanyName ? '' : ' border-danger')} onChange={handleChange} />
                          {error.dirCompanyName ? <div class="text-danger error-contract">{error.dirCompanyName}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Contact Person {astrix} </label>
                        <div className="col-md-12">
                          <input type="text" name="dirContactPerson" value={data.dirContactPerson} placeholder="Enter Contact Person" className={" form-control" + (!error.dirContactPerson ? '' : ' border-danger')} onChange={handleChange} />
                          {error.dirContactPerson ? <div class="text-danger error-contract">{error.dirContactPerson}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Contact Number {astrix} </label>
                        <div className="col-md-12">
                          <input type="number" name="dirContactNo" value={data.dirContactNo} placeholder="Enter Contact Number " className={" form-control" + (!error.dirContactNo ? '' : ' border-danger')} onChange={handleChange} />
                          {error.dirContactNo ? <div class="text-danger error-contract">{error.dirContactNo}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">User type {astrix} </label>
                        <div className="col-md-12">
                          <select className="form-control" name="dirUserType" value={data.dirUserType} onChange={handleChange}>
                            <option value="" selected>Select user type</option>
                            {(dbTypeData.length) ? dbTypeData.map((item) => {
                              if (item.id > 2) {
                                return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                              }
                            }) : ''
                            }
                          </select>
                          {error.dirUserType ? <div class="text-danger error-contract">{error.dirUserType}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row form-group">
                        <label className="col-md-12">Address {astrix} </label>
                        <div className="col-md-12">
                          <textarea name="dirAddress" value={data.dirAddress} placeholder="Enter Address" className={" form-control" + (!error.dirAddress ? '' : ' border-danger')} onChange={handleChange}  ></textarea>
                          {error.dirAddress ? <div class="text-danger error-contract">{error.dirAddress}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 form-group flag-relative" id="POScountry">
                      <div className="flag-icon">
                        {data.dirCountry ? <span className="shadow ml-2">
                          <ReactCountryFlag
                            countryCode={data.dirCountry}
                            style={{ width: '25px', height: '25px' }} svg />
                        </span> :
                          <img height="16" src="../assets/images/onbording/flag.png" alt="flag" className="image ml-3" />}
                      </div>
                      <label className="">Country {astrix}  </label>
                      <select className={" form-control" + (!error.dirCountry ? '' : ' border-danger')} name="dirCountry" value={data.dirCountry} onChange={handleChange} >
                        <option value="" selected>Select Country</option>
                        {countryData.map((country) => {
                          return (
                            <option value={country.sortname} >{country.name}</option>
                          )
                        })}
                      </select>
                      {error.dirCountry && <p className="text-danger error-contract">{error.dirCountry}</p>}
                    </div>


                  </> :
                    (userExistData && userExistData.id) ? <>
                      <div className="col-md-12 row mt-5">

                        <ul className="price-ul col-md-12">
                          <li>
                            <div className="price-left">{userExistData.dir_company_name}</div>
                            <div className="price-right">Company Name </div>
                          </li>
                          <li>
                            <div className="price-left"> {userExistData.dir_contactperson} </div>
                            <div className="price-right">Contact Person</div>
                          </li>
                          <li>
                            <div className="price-left"> {userExistData.dir_email}</div>
                            <div className="price-right">Email</div>
                          </li>
                        </ul>

                        {(userExistData.inDirAlso) ?
                          <div className="col-md-12 text-center mt-5">
                            <h3>User already exists in your directory</h3>
                          </div>
                          : <div className="col-md-12 text-center mt-5">
                            <h3>User already exists in platform, Click submit to add in your directory</h3>
                          </div>}
                      </div>
                    </> : ""}

                </div>

              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => { setAddDirectory(false, {}); }}>Cancel</button>
                {(!userExistData.inDirAlso) &&
                  <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>}
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {

  return {
    addDirectory: state.addDirectory
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAddDirectory: (modal, info) => { dispatch(setAddDirectory({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserInDir)
