

import React, { useEffect, useState } from 'react';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import FilePreview from '../../utilComponents/previewFile'
import { formatDate_Application, formatDateTime_Application } from '../../../utils/dateFormaters';
import { Accordion, Card, Button } from 'react-bootstrap'
import BankDocumentsComp from './bankDocumentsComp.js';
import BankReimburseForm from '../ttPaymentProcess/bankReimburseForm.js'
import validate from '../../../utils/validation_files/lcformValidationRules.js'
import avatarUrl from '../../../utils/makeAvatarUrl';
import AddUserInContract from '../../utilComponents/addUserInContract.js'

const LCPaymentProcess = ({ userTokenDetails, contractNo, contractData }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [contractLCData, setcontractLCData] = useState({});
  const [bankProfileData, setbankProfileData] = useState({});
  const [data, setdata] = useState({
    'uploadLC': false,
  });
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [formPreviewModal, setformPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [formJson, setformJson] = useState({})
  const [formJsonOrder, setformJsonOrder] = useState([])
  const [docList, setDocList] = useState({})

  const [toggle, settoggle] = useState(false)
  const [toggle1, settoggle1] = useState(false)
  const [toggle2, settoggle2] = useState(false)

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getlcdetails', { "contract_number": contractNo }).then(async (result) => {
      console.log("data of api in getlcdetails-->", result)
      let linedDocArray = (result.contractData && result.contractData.linked_docs) ? result.contractData.linked_docs.split`,`.map(x => +x) : []
      delete result.contractData.linked_docs
      if (result.contractData.lc_request === 0) {
        call('POST', 'getjsonfromdb', { "jsonFor": "lcDraftForm" }).then((res) => {
          console.log("getjsonfromdb:=>", res);
          fillJsonForm(res.json_data, result.banksProfileData)
          let tempArray = res.order_array.split(":")
          setformJsonOrder(tempArray)
        }).catch((e) => {
          console.log("Error while querying getjsonfromdb:", e);
        })

      } else {

        call('POST', 'getjsonfromdb', { "jsonFor": "lcDraftForm" }).then((res) => {
          let tempArray = res.order_array.split(":")
          setformJsonOrder(tempArray)
        }).catch((e) => {
          console.log("Error while querying getjsonfromdb:", e);
        })

        setformJson(result.contractData.lc_form_json ? result.contractData.lc_form_json : {})
        delete result.contractData.lc_form_json
      }

      if (!result.contractData.lc_status_buyer) {
        call('POST', 'getDocList', { "cid": contractNo, "userId": userId, "userTypeId": userTypeId }).then((result) => {
          console.log("doc data in bank page-->", result)
          setDocList(result)
          setshowLoader(false)
        }).catch((e) => {
          console.log("Error while querying Doc:", e);
          setshowLoader(false)
        })
      }

      setshowLoader(false)
      setcontractLCData({ ...result.contractData, linkedDocs: linedDocArray })
      setbankProfileData(result.banksProfileData)
      setdata(data => ({
        ...data,
        linkedDocs: linedDocArray,
        "lcDocStatus": result.contractData.doc_status ? result.contractData.doc_status : 3,
        "lcStatusBank": result.contractData.lc_status_bank ? result.contractData.lc_status_bank : 0,
        "lcStatusSeller": result.contractData.lc_status_seller ? result.contractData.lc_status_seller : 0,
        "lcStatusBuyer": result.contractData.lc_status_buyer ? result.contractData.lc_status_buyer : 0
      }));

    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in getlcdetails ->", error)
    })
    //------------------------------------------------------------------
  }, [refresh]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitLCprocessData()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleFile = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.files[0] }));
  }

  const handleFormChange = (event) => {
    event.persist();
    let tempformJson = { ...formJson }
    tempformJson[event.target.name].value = event.target.value
    setformJson(tempformJson)
    setError({ ...error, [event.target.name]: "" });
  }

  const handleFormChangeBankCode = (event) => {
    event.persist();
    let tempformJson = { ...formJson }
    tempformJson[event.target.name].code = event.target.value
    setformJson(tempformJson)
    setError({ ...error, [event.target.name + "code"]: "" });
  }
  //---------------------------------------------------------------------------------------------------------------------
  console.log("contractLCData in bank accord=>", contractLCData)
  // console.log("contractData in bank accord=>", contractData)
  console.log("data in bank accord=>", data)
  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function submitLCrequest() {
    setshowLoader(true)
    call('POST', 'raiselcrequest', { "contract_number": contractNo, "formJson": JSON.stringify(formJson), linkedDoc: data.linkedDocs }).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in raiselcrequest ->", error)
      toastDisplay(error, "error")
    })
  }

  function notifyUserManual(sendToUsers) {
    setshowLoader(true)

    let postObj = {
      contractNumber: contractNo,
      sendToUsers: sendToUsers,
      message: "From " + userName + " ," + data.comment
    }

    call('POST', 'manualnotifyuser', postObj).then(async (result) => {
      setshowLoader(false)
      setdata(data => ({ ...data, "comment": "" }));
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in manualnotifyuser ->", error)
      toastDisplay(error, "error")
    })
  }

  function submitLCprocessData() {

    setshowLoader(true)

    let processStatus = (contractLCData.process_status / 1 < 2) ? (data.agreeBuyerLCform ? 2 : contractLCData.process_status / 1) :
      (contractLCData.process_status / 1 < 6) ? (data.agreeBankLCform ? 6 : data.lcDocStatus / 1) :
        (contractLCData.process_status / 1 <= 7 && (data.swiftDocument && data.swiftDocument.name)) ? 7 :
          contractLCData.process_status / 1

    console.log("processStatus==>", processStatus)
    let postObj = {
      ...data,
      "contract_id": contractLCData.id,
      "contract_no": contractNo,
      "party_a": processStatus < 3 ? contractData.importer_id :
        processStatus <= 7 ? contractData.buyer_bank :
          contractLCData.party_a,
      "party_b": processStatus < 3 ? contractData.buyer_bank :
        processStatus <= 7 ? contractData.importer_id :
          contractLCData.party_b,
      "process_status": processStatus,
      "sendToUsers": processStatus < 6 ? contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id :
        contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank
    }

    if (contractLCData.process_status / 1 < 2 && userTypeId !== 8) {
      postObj["formJson"] = JSON.stringify(formJson)
      if (data.agreeSellerLCform) {
        postObj.lcStatusSeller = 1
      }
      if (data.agreeBuyerLCform) {
        postObj.lcStatusBuyer = 1
        postObj.lcStatusSeller = 1
      }
    }

    if (contractLCData.process_status / 1 < 6 && userTypeId === 8) {
      postObj["formJson"] = JSON.stringify(formJson)
      if (data.agreeBankLCform) {
        postObj.lcStatusBank = 1
      }
    }

    if (postObj.process_status === 6 && userTypeId === 8) {
      postObj["formJsonOrder"] = formJsonOrder
    } else {
      console.log("in else here buyer, seller agree ==>", data.agreeLCDraftBuyer, data.agreeLCDraftSeller)
      if (data.agreeLCDraftBuyer) {
        postObj.lcStatusBuyer = 2
        delete data.agreeLCDraftBuyer
        delete postObj.agreeLCDraftBuyer
      } else if (data.agreeLCDraftSeller) {
        postObj.lcStatusSeller = 2
        delete data.agreeLCDraftSeller
        delete postObj.agreeLCDraftSeller
      }
    }

    console.log("postObj===>", postObj)

    if (data.swiftDocument && data.swiftDocument.name) {
      setdata(data => ({ ...data, ["swiftDocument"]: '' }));
    }

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    call('POST', 'updatelcprocess', formData).then((result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      //toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in updatelcprocess ->", error)
      toastDisplay(error, "error")
    })

  }

  const prehandleSubmitbuyer = (event) => {
    if (event) event.preventDefault();
    setError(validate(formJson, "buyer"));
    setIsSubmitting(true);
  };

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(formJson, "bank"));
    setIsSubmitting(true);
  };

  const hanleDocChecks = (event) => {
    event.persist();
    let tempArray = data[event.target.name] ? [...data[event.target.name]] : []
    let selectedDoc = event.target.value / 1
    let index = tempArray.indexOf(selectedDoc)
    if (index !== -1) {
      tempArray = tempArray.slice(0, index)
    } else {
      tempArray.push(selectedDoc / 1)
    }
    setdata(data => ({ ...data, [event.target.name]: tempArray }));
  }

  function fillJsonForm(json, bank) {

    let currency = contractData.currency.split(":")
    // console.log("bankProfileData==>", bankProfileData)
    json.amount.value = ((contractData.price / 1) * (contractData.quantity / 1))
    json.nameAndAddressApplicant.value = contractData.buyername + " , " + contractData.buyerAddress
    json.nameAndAddressBenificiary.value = contractData.suppliername + " , " + contractData.supplierAddress
    json.advisingAndConfirmingThough.value = bank.buyer_bank_name + " , " + bank.buyer_bank_addr
    json.currencyCode.value = currency[1] + "( " + currency[2] + " " + currency[0] + " )"
    json.portOfLoading.value = contractData.loading_port.toUpperCase()
    json.portOfDischarge.value = contractData.unloading_port.toUpperCase()
    json.dateofIssue.value = formatDate_Application(new Date)
    json.descriptionOfGoodsAndServices.value = json.descriptionOfGoodsAndServices.value.replace("{commodity}", contractData.commodity_name)
    json.descriptionOfGoodsAndServices.value = json.descriptionOfGoodsAndServices.value.replace("{portOfLoading}", contractData.loading_port.toUpperCase())
    json.descriptionOfGoodsAndServices.value = json.descriptionOfGoodsAndServices.value.replace("{quantity}", contractData.quantity)

    console.log("final json==>", json)
    setformJson(json)
  }

  function getDocListingChecks() {
    let keyArrary = Object.keys(docList)
    let renderDoc = []

    let returnJSX = keyArrary.length ? keyArrary.map((key) => {

      if (docList[key].categoryData.length) {
        return (
          <>
            {docList[key].categoryData.map((values, index) => {
              if (!renderDoc.includes(values.tbl_doc_id)) {
                renderDoc.push(values.tbl_doc_id)
                return (<>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="linkedDocs" value={values.tbl_doc_id} id={"docCheckbox" + key + index} onChange={hanleDocChecks}
                        checked={data.linkedDocs.includes(values.tbl_doc_id)} />
                      <label className="form-check-label" htmlFor={"docCheckbox" + key + index}>
                        {values.doc_name + " (" + docList[key].categoryPrettyname + ")"}
                      </label>
                    </div>

                  </div>
                </>)
              }
            })}
          </>
        )
      }
    }) : ""
    return returnJSX
  }


  function openDOCPreview(file) {

    let fileOBJ = {}
    if (file === "LC") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractLCData.lc_doc_id
      }
    } else if (file === "SWIFT") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractLCData.swift_doc_id
      }
    } else if (file === "txnDoc") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractLCData.reimburse_data.txnDoc
      }
    }


    setfileData(fileOBJ)
    setshowPreviewModal(true)
  }

  function getProcessStatus(status) {
    let text = ''
    switch (status) {
      case 0:
        text = (userId === contractData.exporter_id) ? "Currently No Status is Available , Operations Pending" : "Bank Process Current Status, LC Not Available use below form to request LC"
        break;
      case 1:
        text = "Bank Process Current Status, LC Application Form Available"
        break;
      case 2:
        text = "Bank Process Current Status, LC Application Form Submitted to Bank for Draft"
        break;
      case 3:
        text = "Bank Process Current Status, Document Verification Pending by Banker"
        break;
      case 4:
        text = "Bank Process Current Status, Error in Documents found by banker"
        break;
      case 5:
        text = "Bank Process Current Status, All LC related Documents are verified by Banker"
        break;
      case 6:
        text = "Bank Process Current Status, Draft LC is Available"
        break;
      case 7:
        text = "Bank Process Current Status, SWIFT Document Available"
        break;
      case 8:
        text = (userTypeId === 8) ? "Bank Process Current Status, Exporter has availed all the documents for Reimbursement" : "Bank Process Current Status, Documents are sent to bank for LC Reimbursement"
        break;
      case 9:
        text = "Bank Process Current Status, LC Reimbursement Initated Process Done"
        break;
      case 22:
        text = "Bank Process Current Status, LC Requested Wait for the bank's response"
        break;
      case 23:
        text = "Bank Process Current Status, Buyer requested for LC"
        break;
      case 24:
        text = "Bank Process Current Status, Buyer requested for LC to Buyer Bank"
        break;
      default:
        text = "Currently No Status is Available , Operations Pending"
    }
    return text
  }


  // Function to genertae LC form 
  function generateLCForm() {
    // console.log("formJson==>", formJson)
    // console.log("formJsonOrder==>", formJsonOrder)

    let keysArray = formJsonOrder.length ? formJsonOrder : Object.keys(formJson)
    let formJSX = []

    if (keysArray.length) {
      keysArray.map((key, index) => {
        if (formJson[key].size === "sm") {
          formJSX.push(
            <div className="col-md-4 mb-1">
              <label>{formJson[key].name}</label>

              <textarea rows="1" className={" form-control" + (!error[key] ? '' : ' border-danger')} name={key} value={formJson[key].value} onChange={(key === "formOfDocumentaryCredit") ? "" : handleFormChange}
                required ></textarea>
              {/* disabled={(userId === contractData.importer_id || (key === "swiftCode" && userId === contractData.buyer_bank)) ? false : true} */}
              {error[key] ? <div class="text-danger error-contract" id={error[key]}>{error[key]}</div> : ''}

              <input type="text" className={" form-control mt-1" + (!error[key + "code"] ? '' : ' border-danger')} name={key} placeholder="Enter Bank Code (To be Filled by Bank Only)" value={formJson[key].code} onChange={handleFormChangeBankCode}
                disabled={(userId === contractData.buyer_bank) ? false : true} required />
              {error[key + "code"] ? <div class="text-danger error-contract" id={error[key + "code"]}>{error[key + "code"]}</div> : ''}
            </div>
          )
        } else {
          formJSX.push(
            <div className="col-md-12 mb-1">
              <label>{formJson[key].name}</label>

              <textarea rows="5" className={" form-control" + (!error[key] ? '' : ' border-danger')} name={key} value={formJson[key].value} onChange={handleFormChange}
                required></textarea>
              {/* disabled={(userId === contractData.importer_id) ? false : true} */}
              {error[key] ? <div class="text-danger error-contract" id={error[key]}>{error[key]}</div> : ''}

              <input type="text" className={" form-control mt-1" + (!error[key + "code"] ? '' : ' border-danger')} name={key} placeholder="Enter Bank Code (To be Filled by Bank Only)" value={formJson[key].code} onChange={handleFormChangeBankCode}
                disabled={(userId === contractData.buyer_bank) ? false : true} required />
              {error[key + "code"] ? <div class="text-danger error-contract" id={error[key + "code"]}>{error[key + "code"]}</div> : ''}
            </div>
          )
        }
        return null
      })
    }

    return formJSX
  }

  function generateLCFormPreview() {
    // console.log("formJson==>", formJson)
    let keysArray = formJsonOrder.length ? formJsonOrder : Object.keys(formJson)
    let formJSX = []
    if (keysArray.length) {

      keysArray.map((key, index) => {
        formJSX.push(
          <tr >
            <td ><b>{(formJson[key].code ? formJson[key].code + ": " : "") + formJson[key].name}</b></td>
            <td >:</td>
            <td ><pre>{formJson[key].value ? formJson[key].value : '---'}</pre></td>
          </tr>
        )
        return null
      })

      return (
        <>
          <h3 className="text-center">LC request Form</h3>
          <table class="table table-borderless" >
            <tbody>
              {formJSX.map((element, index) => {
                return (element)
              })}
            </tbody>
          </table>
        </>
      )
    } else {
      return ""
    }
  }

  //---------------------------------------------------------------------------------------------------------------------


  return (<>
    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

    {(contractData.supplier_bank / 1 === 0 && contractData.buyer_bank / 1 === 0) ?
      <>
        <AddUserInContract
          userTokenDetails={userTokenDetails}
          addUserData={{ usertype: 8, name: "Bank" }}
          contractData={contractData}
          refresh={refresh}
          setrefresh={setrefresh}
        />
      </> :
      <div className="mt-5">


        {/* banker's profile display start */}
        <div className="col-md-12">
          <div className="row bg-shadow bg-white" style={{ "min-height": "200px" }} >
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="chat-message pl-2">
                  <img src={avatarUrl(bankProfileData.buyer_bank_avatar)} alt="Avatar" width="32" height="32" />
                  <div className="chat-message-content clearfix pt-2">
                    <span className="date-format">  {bankProfileData.buyer_bank_name ? bankProfileData.buyer_bank_name : '--'}</span><br />
                  </div>
                  <p className="date-format p-0 m-0">{bankProfileData.buyer_bank_addr ? bankProfileData.buyer_bank_addr : '--'}</p>
                </div>
              </div>
              <div className="col-md-12">
                <label>{bankProfileData.buyer_ac_no ? bankProfileData.buyer_ac_no : '--'}</label>
                <div className="date-format">Importer Bank Account No</div>
              </div>
            </div>
            <div className="col-md-6 border-left">
              <div className="col-md-12">
                <div className="chat-message pl-2">
                  <img src={avatarUrl(bankProfileData.seller_bank_avatar)} alt="Avatar" width="32" height="32" />
                  <div className="chat-message-content clearfix pt-2">
                    <span className="date-format">  {bankProfileData.seller_bank_name ? bankProfileData.seller_bank_name : '--'}</span><br />
                  </div>
                  <p className="date-format p-0 m-0">{bankProfileData.seller_bank_addr ? bankProfileData.seller_bank_addr : '--'}</p>
                </div>
              </div>
              <div className="col-md-12">
                <label>{bankProfileData.seller_ac_no ? bankProfileData.seller_ac_no : '--'}</label>
                <div className="date-format">Exporter Bank Account No</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* banker's profile display end */}

        {(contractLCData.process_status) &&
          <>
            <div className="col-md-12">
              <ul className="price-ul">
                <li>
                  <div className="price-left">{contractLCData.lcprocess_created_at ? formatDateTime_Application(contractLCData.lcprocess_created_at) : '--'}</div>
                  <div className="price-right">Process Initiated at</div>
                </li>
                <li>
                  <div className="price-left"> {contractLCData.lcprocess_modified_at ? formatDateTime_Application(contractLCData.lcprocess_modified_at) : '--'}</div>
                  <div className="price-right">Process Updated at</div>
                </li>
              </ul>
            </div>
            <hr />
          </>}


        {/* buyer seller procss status start */}

        <>
          <div className="col-md-12 row ml-2">

            {(userId === contractData.buyer_bank) ? <div className="">
              <h3 className={(contractLCData.process_status < 9) ? "text-warning" : "text-success"}>
                {(contractLCData.process_status === 0 && contractLCData.lc_request === 1) ? getProcessStatus(23) :
                  getProcessStatus(contractLCData.process_status / 1)}
              </h3>
            </div> :
              (userId === contractData.supplier_bank) ? <div className="">
                <h3 className={(contractLCData.process_status < 9) ? "text-warning" : "text-success"}>
                  {(contractLCData.process_status === 0 && contractLCData.lc_request === 1) ? getProcessStatus(24) :
                    getProcessStatus(contractLCData.process_status / 1)}
                </h3>
              </div> :
                <div className="mt-1">
                  <h3 className={(contractLCData.process_status < 9) ? "text-warning" : "text-success"}>
                    {getProcessStatus(contractLCData.process_status / 1)}
                  </h3>
                </div>}

            {(contractLCData.lc_doc_id) ?
              <div className="ml-5">
                <button type="button" className="btn btn-sm btn-primary" onClick={() => openDOCPreview("LC")}>View LC Draft</button>
              </div> : ""}
            {(contractLCData.swift_doc_id) ?
              <div className="ml-5">
                <button type="button" className="btn btn-sm btn-primary" onClick={() => openDOCPreview("SWIFT")}>View SWIFT LC</button>
              </div> : ""}
          </div>
          {userTypeId === 8 && <hr />}
        </>
        {/* buyer seller procss status end */}

        {(contractLCData.reimburse_data && contractLCData.reimburse_data.txnId) &&
          <>
            <div className="col-md-12">
              <h3 className="text-success">Payment Details</h3>
              <ul className="price-ul">
                <li>
                  <div className="price-left">{contractLCData.reimburse_data.txnId ? contractLCData.reimburse_data.txnId : '--'}</div>
                  <div className="price-right">Transaction ID </div>
                </li>
                <li>
                  <div className="price-left"> {contractLCData.reimburse_data.txnAmount ? contractLCData.reimburse_data.txnAmount : '--'} </div>
                  <div className="price-right"> Transaction Amount</div>
                </li>
                <li>
                  <div className="price-left"> {contractLCData.reimburse_data.txnDate ? contractLCData.reimburse_data.txnDate : '--'}</div>
                  <div className="price-right">Transaction Date/Time</div>
                </li>
                <li>
                  <div className="price-left"> <button type="button" className="btn btn-sm btn-link" onClick={() => openDOCPreview("txnDoc")}>Open Document</button></div>
                  <div className="price-right">View Payment TXN Doc</div>
                </li>
              </ul>
            </div>
          </>}


        {/* buyer lc process section start */}
        {((aclMap.contract_access && aclMap.contract_access.cont_lcReq && aclMap.contract_access.cont_lcReq.isExist) && (userId === contractData.importer_id)) &&
          <>
            {(contractLCData.process_status < 6) &&
              <div className="col-md-12 mt-4">
                <hr />
                <h3 className="text-primary">Request for LC to Issuing/Applicant Bank</h3>
                <hr className="p-1" />

                <Accordion>
                  <Card>
                    <Card.Header>
                      <div className="col-md-12 row">
                        <div className="col-md-11 row">

                          <h3 className="text-primary mt-2">LC Application Form</h3>

                          <select className="form-control col-md-3 ml-5 mr-5" name="formOfDocumentaryCredit" value={formJson.formOfDocumentaryCredit ? formJson.formOfDocumentaryCredit.value : ''} onChange={handleFormChange}>
                            <option value="" selected>--Select LC Type--</option>
                            <option value="IRREVOCABLE" >IRREVOCABLE</option>
                            <option value="REVOCABLE" >REVOCABLE</option>
                            <option value="STAND-BY" >STAND-BY</option>
                            <option value="CONFIRMED" >CONFIRMED</option>
                            <option value="UNCONFIRMED" >UNCONFIRMED</option>
                            <option value="TRANSFERABLE" >TRANSFERABLE</option>
                            <option value="BACK-TO-BACK" >BACK-TO-BACK</option>
                            <option value="PAYMENT AT SIGHT" >PAYMENT AT SIGHT</option>
                            <option value="DEFERRED PAYMENT" >DEFERRED PAYMENT</option>
                            <option value="RED CLAUSE" >RED CLAUSE</option>
                          </select>

                        </div>
                        <div className="col-md-1">
                          <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                              {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                            </span>
                          </Accordion.Toggle>
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="col-md-12 mt-3 row">
                          <h3 className="col-md-12 text-center mb-5">{"APPLICATION FOR " + (formJson.formOfDocumentaryCredit ? formJson.formOfDocumentaryCredit.value : '---') + " DOCUMENTARY CREDIT"}</h3>
                          {generateLCForm()}
                          {((userId === contractData.importer_id) && contractLCData.lc_status_buyer / 1 < 1) &&
                            <>
                              <div className="col-md-12 row mt-5 mb-2">
                                <div className="col-md-12 ">
                                  <h3>Select Mandatory Doc</h3>
                                </div>
                                <div className="col-md-12 row">
                                  {getDocListingChecks()}
                                </div>
                              </div>
                              <hr className="col-md-12 " />
                            </>}

                          {((contractLCData.lc_request === 1) && (contractLCData.process_status / 1 === 1)) && <>
                            {(contractLCData.lc_status_buyer / 1 === 0) ? <>
                              <div className="col-md-12 mt-2 ml-2 row">
                                <div className="form-check mb-1 pl-0" >
                                  <input className="form-check-input" type="checkbox" name="agreeBuyerLCform" id="agreeBuyerLCform" onChange={
                                    (event) => {
                                      event.persist();
                                      setdata(data => ({
                                        ...data, [event.target.name]: event.target.checked
                                      }));
                                    }} checked={data.agreeBuyerLCform} />

                                  <label className="form-check-label" for="agreeBuyerLCform"> I hereby declare that I agree with the information in Application form and submit it to bank
                          for final LC generation process </label>
                                </div>
                              </div>

                            </> : <>
                                <label className="ml-3 text-success"> You have Submitted your Agreement for the above form</label>
                              </>}
                          </>}

                          <div className="col-md-12 mt-3 row">
                            <div className="col-md-6">
                              <button type="button" className="btn btn-sm btn-primary" onClick={() => setformPreviewModal(true)}>Preview Form</button>
                            </div>
                            {((contractLCData.lc_request === 1) && contractLCData.process_status / 1 < 2) && <>
                              {(!data.agreeBuyerLCform) ?
                                <div className="col-md-6">
                                  <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCprocessData()}>Save Process</button>
                                </div> :
                                <div className="col-md-6">
                                  <button type="button" className="btn btn-danger btn-sm" onClick={() => prehandleSubmitbuyer()}>Agree & Submit Form </button>
                                </div>
                              }
                            </>}
                          </div>

                          {(contractLCData.lc_request === 0) && <>
                            <div className="form-check mt-5 mb-1 pl-0" >
                              <input className="form-check-input" type="checkbox" name="agreeRaiseLCrequest" id="agreeRaiseLCrequest" onChange={
                                (event) => {
                                  event.persist();
                                  setdata(data => ({
                                    ...data, [event.target.name]: event.target.checked
                                  }));
                                }} checked={data.agreeRaiseLCrequest} />

                              <label className="form-check-label" for="agreeRaiseLCrequest"> I hereby declare that the above information is 100% correct, all related documents are already submitted and
                   verified by me. I request the respective bank to generate LC for the Contract</label>
                            </div>
                            <div className="col-md-12 mt-3 row">
                              {data.agreeRaiseLCrequest &&
                                <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCrequest()}>Submit Request</button>
                              }
                            </div>
                          </>}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

              </div>}

            {contractLCData.process_status === 8 && <>
              <hr className="p-1" />
              <Accordion>
                <Card>
                  <Card.Header>
                    <div className="col-md-12 row">
                      <div className="col-md-11 row">
                        <h3 className="text-primary mt-2">Documents Status</h3>
                      </div>
                      <div className="col-md-1">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                            {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                          </span>
                        </Accordion.Toggle>
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <BankDocumentsComp
                        userTokenDetails={userTokenDetails}
                        contractData={{
                          id: contractLCData.id,
                          contractNo: contractNo,
                          linkedDocs: contractLCData.linkedDocs,
                          exporter_id: contractData.importer_id,
                          importer_id: contractData.supplier_bank,
                          buyer_bank: contractData.buyer_bank,
                          supplier_bank: contractData.exporter_id
                        }}
                        setfileData={setfileData}
                        setshowPreviewModal={setshowPreviewModal}
                        setshowLoader={setshowLoader}
                        refresh={refresh}
                        setrefresh={setrefresh}
                        processStatus={contractLCData.process_status / 1}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

            </>}

          </>}
        {/* buyer lc process section end */}


        <>
          {(contractLCData.process_status === 6 && contractLCData.doc_status === 5 && contractLCData.lc_doc_id / 1 && userTypeId !== 8) && <>
            <hr />
            {((userId === contractData.importer_id && contractLCData.lc_status_buyer === 1) || (userId === contractData.exporter_id && contractLCData.lc_status_seller === 1)) ?
              <div className="mt-1 mb-1 ml-2">
                <h3>Provide Aggrement on LC Draft</h3>
                <div className="form-check" >
                  <input className="form-check-input" type="checkbox" name={userId === contractData.importer_id ? "agreeLCDraftBuyer" : userId === contractData.exporter_id ? "agreeLCDraftSeller" : ""}
                    id={userId === contractData.importer_id ? "agreeLCDraftBuyer" : userId === contractData.exporter_id ? "agreeLCDraftSeller" : ""} onChange={
                      (event) => {
                        event.persist();
                        setdata(data => ({
                          ...data, [event.target.name]: event.target.checked
                        }));
                      }} checked={userId === contractData.importer_id ? data.agreeLCDraftBuyer : userId === contractData.exporter_id ? data.agreeLCDraftSeller : ""} />

                  <label className="form-check-label" for={userId === contractData.importer_id ? "agreeLCDraftBuyer" : userId === contractData.exporter_id ? "agreeLCDraftSeller" : ""}>
                    I hereby declare that , I give my agrrement on this LC Draft and bank can use this information to generate SWIFT LC
                   </label>
                </div>
                <div className="col-md-12 mt-3 row">
                  {(userId === contractData.importer_id ? data.agreeLCDraftBuyer : userId === contractData.exporter_id ? data.agreeLCDraftSeller : "") &&
                    <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCprocessData()}>Submit Request</button>
                  }
                </div>
              </div> : (contractLCData.lc_status_buyer === 2 && contractLCData.lc_status_seller === 2) ?
                <h3>Both parties gave agreement on LC Draft waiting for bank to procide SWIFT LC Dcoument</h3> :
                ((userId === contractData.importer_id && contractLCData.lc_status_buyer === 2) || (userId === contractData.exporter_id && contractLCData.lc_status_seller === 2)) ?
                  <h3>You gave your agreement on the LC Draft waiting for other party to give agreement</h3> : "Something went wrong"}
          </>}
        </>




        {/* seller lc process section start */}
        {(userId === contractData.exporter_id) && <>

          {/* {(contractLCData.lc_request === 1 && contractLCData.process_status < 6) &&
            <div className="col-md-12">
              <Accordion>
                <Card>
                  <Card.Header>

                    <div className="col-md-12 row">
                      <div className="col-md-11 row">

                        <h3 className="text-primary mt-2"> LC Application Form</h3>

                      </div>
                      <div className="col-md-1">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                            {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                          </span>
                        </Accordion.Toggle>
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="col-md-12 mt-3 row">
                        <h3 className="col-md-12 text-center mb-5">{"APPLICATION FOR " + (formJson.formOfDocumentaryCredit ? formJson.formOfDocumentaryCredit.value : '---') + " DOCUMENTARY CREDIT"}</h3>
                        {generateLCForm()}

                        {((contractLCData.lc_request === 1) && (contractLCData.process_status / 1 < 6)) && <>
                          {(contractLCData.lc_status_seller / 1 === 0) ? <>
                            <div className="col-md-12 mt-2 ml-2 row">
                              <div className="form-check mb-1 pl-0" >
                                <input className="form-check-input" type="checkbox" name="agreeSellerLCform" id="agreeSellerLCform" onChange={
                                  (event) => {
                                    event.persist();
                                    setdata(data => ({
                                      ...data, [event.target.name]: event.target.checked
                                    }));
                                  }} checked={data.agreeSellerLCform} />

                                <label className="form-check-label" for="agreeSellerLCform"> I hereby declare that I give my agreement with the Application form</label>
                              </div>
                            </div>

                          </> : <>
                              <label className="ml-3 text-success"> You have Submitted your Agreement for the above form</label>
                            </>}
                        </>}

                        <div className="col-md-12 mt-3 row">
                          <div className="col-md-6">
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => setformPreviewModal(true)}>Preview Form Document</button>
                          </div>
                          {((contractLCData.lc_request === 1) && (contractLCData.lc_status_seller === 0) && contractLCData.process_status / 1 < 2 && data.agreeSellerLCform) && <div className="col-md-6">
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => submitLCprocessData()}>Submit Agreement</button>
                          </div>}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

            </div>
          } */}

          {(contractLCData.process_status >= 7 && contractLCData.process_status < 9) && <>
            <hr className="p-1" />
            <div className="col-md-12">
              <h3 className="text-primary">Documents Submition for LC Reimbursement</h3>
              <hr className="p-1" />

              <Accordion>
                <Card>
                  <Card.Header>
                    <div className="col-md-12 row">
                      <div className="col-md-11 row">
                        <h3 className="text-primary mt-2">Documents Status</h3>
                      </div>
                      <div className="col-md-1">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                            {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                          </span>
                        </Accordion.Toggle>
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <BankDocumentsComp
                        userTokenDetails={userTokenDetails}
                        contractData={{
                          id: contractLCData.id,
                          contractNo: contractNo,
                          linkedDocs: contractLCData.linkedDocs,
                          exporter_id: contractData.importer_id,
                          importer_id: contractData.supplier_bank,
                          buyer_bank: contractData.buyer_bank,
                          supplier_bank: contractData.exporter_id
                        }}
                        setfileData={setfileData}
                        setshowPreviewModal={setshowPreviewModal}
                        setshowLoader={setshowLoader}
                        refresh={refresh}
                        setrefresh={setrefresh}
                        processStatus={contractLCData.process_status / 1}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

            </div>
          </>}

        </>}
        {/* seller lc process section end */}


        {/* lc process banker section start */}
        {(aclMap.contract_access && aclMap.contract_access.cont_lcManage && aclMap.contract_access.cont_lcManage.isExist) &&
          <>
            {/* lc process buyer bank section start */}
            {(userId === contractData.buyer_bank) ?
              <>

                {(contractLCData.process_status / 1 < 9) &&
                  <div className="col-md-12">
                    <h3 className="text-primary">Banker Process</h3>
                    <hr className="p-1" />

                    {(contractLCData.process_status < 8) && <>
                      {(contractLCData.process_status < 6) ?
                        <Accordion>
                          <Card>
                            <Card.Header>
                              <div className="col-md-12 row">
                                <div className="col-md-11 row">

                                  <h3 className="text-primary mt-2">LC Application Form</h3>

                                  <select className="form-control col-md-3 ml-5 mr-5" name="formOfDocumentaryCredit" value={formJson.formOfDocumentaryCredit ? formJson.formOfDocumentaryCredit.value : ''} onChange={handleFormChange}>
                                    <option value="" selected>--Select LC Type--</option>
                                    <option value="IRREVOCABLE" >IRREVOCABLE</option>
                                    <option value="REVOCABLE" >REVOCABLE</option>
                                    <option value="STAND-BY" >STAND-BY</option>
                                    <option value="CONFIRMED" >CONFIRMED</option>
                                    <option value="UNCONFIRMED" >UNCONFIRMED</option>
                                    <option value="TRANSFERABLE" >TRANSFERABLE</option>
                                    <option value="BACK-TO-BACK" >BACK-TO-BACK</option>
                                    <option value="PAYMENT AT SIGHT" >PAYMENT AT SIGHT</option>
                                    <option value="DEFERRED PAYMENT" >DEFERRED PAYMENT</option>
                                    <option value="RED CLAUSE" >RED CLAUSE</option>
                                  </select>

                                  {(contractLCData.process_status >= 2) &&
                                    <select className="form-control col-md-4 ml-5 mr-5" name="lcDocStatus" value={data.lcDocStatus} onChange={handleChange}>
                                      <option value="3" selected>Documnet Verification Pending</option>
                                      <option value="4" >Conflicts in Documents</option>
                                      <option value="5" >Documents Verified</option>
                                    </select>}

                                </div>
                                <div className="col-md-1">
                                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                      {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                    </span>
                                  </Accordion.Toggle>
                                </div>
                              </div>

                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div className="col-md-12 mt-3 row">
                                  <h3 className="col-md-12 text-center mb-5">{"APPLICATION FOR " + (formJson.formOfDocumentaryCredit ? formJson.formOfDocumentaryCredit.value : '---') + " DOCUMENTARY CREDIT"}</h3>
                                  {generateLCForm()}

                                  {(contractLCData.lc_status_buyer === 1 && contractLCData.lc_status_seller === 1) &&
                                    <label className="ml-3 text-success"> Expoter and Importer gave their Agreement on the above form</label>}

                                  {((contractLCData.lc_request === 1) && (contractLCData.process_status / 1 >= 2) && (contractLCData.lc_status_bank / 1 === 0) && (data.lcDocStatus / 1 === 5)) &&
                                    <div className="col-md-12 mt-2 ml-2 row">
                                      <div className="form-check mb-1 pl-0" >
                                        <input className="form-check-input" type="checkbox" name="agreeBankLCform" id="agreeBankLCform" onChange={
                                          (event) => {
                                            event.persist();
                                            setdata(data => ({
                                              ...data, [event.target.name]: event.target.checked
                                            }));
                                          }} checked={data.agreeBankLCform} />

                                        <label className="form-check-label" for="agreeBankLCform"> I hereby declare that I have checked all the documents related to LC draft and verified all the
                              informantion in the form , and approve the this LC application for Lc Drafting</label>
                                      </div>
                                    </div>
                                  }

                                  <div className="col-md-12 mt-3 row">
                                    <div className="col-md-6">
                                      <button type="button" className="btn btn-sm btn-primary" onClick={() => setformPreviewModal(true)}>Preview Form Document</button>
                                    </div>
                                    {(contractLCData.process_status < 6) &&
                                      <>
                                        {(!data.agreeBankLCform) ? <div className="col-md-6">
                                          <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCprocessData()}>Save Process</button>
                                        </div> :
                                          <div className="col-md-6">
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => prehandleSubmit()}>Agree & Draft LC </button>
                                          </div>}
                                      </>}
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                        : (contractLCData.lc_status_buyer === 2 && contractLCData.lc_status_seller === 2) ?
                          <Accordion>
                            <Card>
                              <Card.Header>
                                <div className="col-md-12 row">
                                  <div className="col-md-11 row">
                                    <h3 className="text-primary mt-2">{contractLCData.swift_file_hash ? "Update SWIFT LC" : "Upload SWIFT LC"}</h3>
                                  </div>
                                  <div className="col-md-1">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                      <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                        {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                      </span>
                                    </Accordion.Toggle>
                                  </div>
                                </div>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <div className="col-md-12 mt-3 row">
                                    {(contractLCData.process_status >= 6) && <>

                                      <div className="col-md-2 pull-left">
                                        <div className="file-browse col-md-12 mt-2">
                                          <button className="btn btn-primary btn-sm">Select SWIFT LC File</button>
                                          <input type="file" accept=".png,.jpg,.pdf" name="swiftDocument" onChange={(event) => {
                                            handleFile(event);
                                            setdata(data => ({ ...data, "uploadSwift": !data.uploadSwift }));
                                          }} />
                                        </div>
                                      </div>

                                      {data.swiftDocument &&
                                        <>
                                          <div className="">
                                            <div className="form-group col-md-12"><div className="file-name">{data.swiftDocument.name}</div></div>
                                          </div>
                                          <div className="ml-5 mt-2">
                                            <button className="btn btn-primary btn-sm" onClick={() => submitLCprocessData()}>Submit</button>
                                          </div>
                                        </>}

                                    </>}
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion> :
                          (contractLCData.lc_status_buyer === 2 && contractLCData.lc_status_seller === 1) ?
                            <h3>Buyer gave his agreement, waiting for Seller</h3> :
                            (contractLCData.lc_status_buyer === 1 && contractLCData.lc_status_seller === 2) ?
                              <h3>Seller gave his agreement, waiting for Buyer</h3> :
                              (contractLCData.lc_status_buyer === 1 && contractLCData.lc_status_seller === 1) ?
                                <h3>Waiting for both parties agreement, once both party agree you can generate and uplaod LC SWIFT Document</h3> :
                                "something went wrong"
                      }
                    </>}

                    {contractLCData.process_status === 8 && <>

                      <Accordion>
                        <Card>
                          <Card.Header>
                            <div className="col-md-12 row">
                              <div className="col-md-11 row">
                                <h3 className="text-primary mt-2">Documents Status</h3>
                              </div>
                              <div className="col-md-1">
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                  <span className="cursor-pointer ml-5" onClick={() => settoggle1(!toggle1)}>
                                    {!toggle1 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                  </span>
                                </Accordion.Toggle>
                              </div>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <BankDocumentsComp
                                userTokenDetails={userTokenDetails}
                                contractData={{
                                  id: contractLCData.id,
                                  contractNo: contractNo,
                                  linkedDocs: contractLCData.linkedDocs,
                                  exporter_id: contractData.importer_id,
                                  importer_id: contractData.supplier_bank,
                                  buyer_bank: contractData.buyer_bank,
                                  supplier_bank: contractData.exporter_id
                                }}
                                setfileData={setfileData}
                                setshowPreviewModal={setshowPreviewModal}
                                setshowLoader={setshowLoader}
                                refresh={refresh}
                                setrefresh={setrefresh}
                                processStatus={contractLCData.process_status / 1}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <Accordion>
                        <Card>
                          <Card.Header>
                            <div className="col-md-12 row">
                              <div className="col-md-11 row">
                                <h3 className="text-primary mt-2">Provide LC Reimbursement Details</h3>
                              </div>
                              <div className="col-md-1">
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                  <span className="cursor-pointer ml-5" onClick={() => settoggle2(!toggle2)}>
                                    {!toggle2 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                  </span>
                                </Accordion.Toggle>
                              </div>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              <BankReimburseForm
                                userTokenDetails={userTokenDetails}
                                contractData={{
                                  id: contractLCData.id,
                                  contractNo: contractNo,
                                  exporter_id: contractData.importer_id,
                                  importer_id: contractData.supplier_bank,
                                  buyer_bank: contractData.buyer_bank,
                                  supplier_bank: contractData.exporter_id,
                                  paymentType: "LC"
                                }}
                                setshowLoader={setshowLoader}
                                refresh={refresh}
                                setrefresh={setrefresh}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>


                    </>}
                  </div>}
              </> :
              // lc process seller bank section start
              (userId === contractData.supplier_bank) ?
                <div>

                  {contractLCData.process_status === 8 && <>

                    <Accordion>
                      <Card>
                        <Card.Header>
                          <div className="col-md-12 row">
                            <div className="col-md-11 row">
                              <h3 className="text-primary mt-2">Documents Status</h3>
                            </div>
                            <div className="col-md-1">
                              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                  {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                </span>
                              </Accordion.Toggle>
                            </div>
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <BankDocumentsComp
                              userTokenDetails={userTokenDetails}
                              contractData={{
                                id: contractLCData.id,
                                contractNo: contractNo,
                                linkedDocs: contractLCData.linkedDocs,
                                exporter_id: contractData.importer_id,
                                importer_id: contractData.supplier_bank,
                                buyer_bank: contractData.buyer_bank,
                                supplier_bank: contractData.exporter_id
                              }}
                              setfileData={setfileData}
                              setshowPreviewModal={setshowPreviewModal}
                              setshowLoader={setshowLoader}
                              refresh={refresh}
                              setrefresh={setrefresh}
                              processStatus={contractLCData.process_status / 1}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                  </>}

                </div> :
                ""}
          </>}
        {/* lc process banker section ends */}


        {/* notify module start */}
        {(contractLCData.lc_request === 1) && <>
          <hr />
          <div className="col-md-12 mt-4 row">
            <div className="col-md-8">
              <h3 className="text-primary">Notify other party about update</h3>
            </div>
            <div className="col-md-4 row">
              <lable className="col-md-6"> Select party</lable>
              <select className="form-control col-md-6 mb-2" name="notifyParty" value={data.notifyParty} onChange={handleChange}>
                <option value="0" selected>-Select Party-</option>
                {(userId / 1 !== contractData.exporter_id / 1) && <option value={contractData.exporter_id} >Exporter</option>}
                {(userId / 1 !== contractData.importer_id / 1) && <option value={contractData.importer_id} >Importer</option>}
                {(userId / 1 !== contractData.supplier_bank / 1) && <option value={contractData.supplier_bank} >Exporter Bank</option>}
                {(userId / 1 !== contractData.buyer_bank / 1) && <option value={contractData.buyer_bank} >Importer Bank</option>}
              </select>
            </div>
            <div className="col-md-12">
              <textarea rows="2" maxlength="200" className="form-control col-md-12" placeholder="Enter Message" name="comment" onChange={handleChange} required></textarea>
              {(data.notifyParty) && <button type="button" className="btn btn-success btn-sm mt-2 mb-2" onClick={() => notifyUserManual(data.notifyParty)}>Send</button>}
            </div>
          </div>
        </>}
        {/* notify module ends */}


        {/* form preview modal start */}
        {formPreviewModal &&
          <div className={"modal" + (formPreviewModal ? " show" : "")} id="changelogsModal">
            <div className="modal-dialog modal-xl border-inner" id="parent_class">
              <div className="modal-content">
                <div className="modal-header primary">
                  <h4 className="modal-title text-white">LC request Form Preview</h4>
                  <button type="button" className="close" onClick={() => setformPreviewModal(false)}>×</button>
                </div>
                <div className="modal-body calc-inner-modal">
                  {generateLCFormPreview()}
                </div>
              </div>
            </div>
          </div>}
        {/* form preview modal ends */}

      </div>}

    {
      showPreviewModal &&
      <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal} />
    }

  </>)
}


export default LCPaymentProcess
