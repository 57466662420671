
import React, { useState, useEffect } from 'react';
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';

function Product({ product }) {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  console.log("product detail in paypal button--->", product)

  useEffect(() => {

    if (product.paymentType === 1) {
      document.getElementById('paypal_button' + product.plan_code).innerHTML = "";
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    currency_code: product.currency,
                    value: product.price,
                  },
                },
              ],
            });
          },
          onCancel: (data) => {
            toastDisplay("You cancelled the payment!", "warn")
            console.log("** On Cancel Event single payment **");
            console.log("Data->>", data);
          },
          onApprove: async (data, actions) => {
            toastDisplay("Payment Successfull", "success")
            console.log("** On Approve Event single payment **");
            console.log("Data->>", data);
            const order = await actions.order.capture();
            console.log("order->>", order);
            sendPaymentDetails(order)
            setPaidFor(true);
          },
          onError: async (error) => {
            toastDisplay("Some Error Occured during payment!", "error")
            console.log("** On Error Event single payment **");
            console.log("error->>", error);
            setError(error);
          },
          "style": {
            "color": "blue",
            "shape": "rect",
            "size": "responsive",
            "layout": "vertical"
          }
        })
        .render('#paypal_button' + product.plan_code);
    } else if (product.paymentType === 2) {
      document.getElementById('paypal_button' + product.plan_code).innerHTML = "";
      window.paypal
        .Buttons({
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              'plan_id': product.planId  //'P-2W511414RN028831EL3CNHZY'
            });
          },
          onCancel: async (data) => {
            toastDisplay("You cancelled the payment!", "warn")
            console.log("** On Cancel Event subscription **");
            console.log("Data->>", data);
          },
          onApprove: async (data, actions) => {
            toastDisplay("Payment Successfull", "success")
            console.log("** On Approve Event subscription **");
            console.log("Data->>", data);
            const order = await actions.subscription.get();
            console.log("order->>", order);
            sendPaymentDetails(order)
            setPaidFor(true);
          },
          onError: async (error) => {
            toastDisplay("Some Error Occured during payment!", "error")
            console.log("** On Error Event subscription **");
            console.log("error->>", error);
            setError(error);
          },
          "style": {
            "color": "blue",
            "shape": "rect",
            "size": "responsive",
            "layout": "vertical"
          }
        })
        .render('#paypal_button' + product.plan_code);
    }
  }, [product.paymentType]);


  function sendPaymentDetails(paymentData) {
    let obj = {
      "jsonResp": paymentData,
      "userId": product.userId,
      "planId": product.id
    }
    call('POST', 'savePaymentDetails', obj).then((result) => {
      console.log("API result savePaymentDetails:-->", result);
      toastDisplay("Payment Details saved in database", "success")

      setTimeout(function () { window.location.reload(); }, 5000);

    }).catch((e) => {
      console.log("Error while querying gettraderplans:", e);
      toastDisplay("Unable to save payment details in database", "error")
    })

  }




  return (
    <div>
      {error && <div>Uh oh, an error occurred!</div>}
      {paidFor ? <h3 className="text-success">Congrats, you just bought {product.name}!</h3> : <div id={"paypal_button" + product.plan_code}></div>}
    </div>
  );
}

export default Product;
