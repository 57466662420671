import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setCreditDocuments } from '../../store/actions/action';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/CreditLinebyAdmin';
import customStyles from '../dataTablesColumsMap/customTableCss';
import FilePreview from '../utilComponents/previewFile';
import CreditDocument from '../creditLineComp/creditDocument';

const CreditLineGrid = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, gridType, creditlineDataBank, setCreditlineDataBank, CreditDocuments, setCreditDocuments }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [creditUserDetail, setCreditUserDetail] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [fileData, setfileData] = useState({});
  const [data, setData] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false);

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------
  }, [refresh, filterData]);

  useEffect(() => {

    data.userId = manualUserId
    call("POST", "getCreditlineDocDetail", data).then(async (result) => {
      setCreditUserDetail(result);
    }).catch((e) => {
      console.log("error in getCreditlineDocDetail", e);
    });
    //------------------------------------------------------------------
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createMiniBody(data) {
    for (let i = 0; i < creditUserDetail.length; i++) {
      let usersCredit = JSON.parse(creditUserDetail[i].userDetail);
      if (creditUserDetail[i].userid == data.id) {
        return (<>
          {
            usersCredit.map((arrayElement) => {
              return (
                <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
                  <div className="ml-5 mr-5">
                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                    <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(arrayElement.doc_id, "view")}>
                      {arrayElement.doc_name}
                    </button>
                  </div>
                </div>
              )
            })
          }
          <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
            <div className="ml-5 mr-5">
              <div className="border-left">
                <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => documentAction(data)}>
                  <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>Document Details</button>
              </div>
            </div>
          </div>
        </>
        )
      }
    }
  }

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": gridType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      settableData(formatDataFortable(result.finData));
      setTotalRows(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": gridType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      settableData(formatDataFortable(result.finData))
      setTotalRows(result.countdata);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.id = dataArray[i].id;
      tempObj.requestId = dataArray[i].credit_id;
      tempObj.credit_amount = dataArray[i].credit_amount;
      var d = new Date(dataArray[i].created_at);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.created_at = date + '/' + month + '/' + year;
      tempObj.loanType = dataArray[i].loanType;
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.status = dataArray[i].status;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      if (dataArray[i].status == 'User Approval Pending' || dataArray[i].status === 'User Approved' || dataArray[i].status === 'User Rejected') {
        tempObj.action = 'N/A';
      }
      else {
        tempObj.action = <button className="btn btn-success btn-sm" onClick={() => bankerAction(manualUserId, manualUserName, manualUserEmail, dataArray[i])} >
          <i class="fas fa-folder-open"></i>
        </button>
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function bankerAction(userId, userName, userEmail, allData) {
    setCreditlineDataBank(true, { userId, userName, userEmail, allData })
  }

  function documentAction(data) {
    setCreditDocuments(true, { action: { userid: data.id }, name: manualUserName, email: manualUserEmail, id: manualUserId, gridType: 'bankType' })
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Filters


  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview




  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return

  return (
    <>
      {CreditDocuments.info.hasOwnProperty('action') ?
        <CreditDocument userTokenDetails={userTokenDetails} manualUserId={userId}
          manualUserName={userName}
          manualUserEmail={userEmail} userDetail='' userPendingDetail='' CreditDocuments={CreditDocuments} />
        :
        <div className="table-responsive">
          <DataTable
            columns={columns}
            data={tableData}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
            persistTableHead
            // expandableRows
            // expandableRowsComponent={<TableExpandedComponent />}
            // expandOnRowClicked
            highlightOnHover
            onSort={handleSort}
            sortServer
            striped
            noHeader
            customStyles={customStyles}
          />
        </div>
      }
      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
      />}
    </>
  )
}

//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    CreditDocuments: state.CreditDocuments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCreditDocuments: (modal, info) => { dispatch(setCreditDocuments({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditLineGrid)