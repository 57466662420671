const columns = [

  {
    name: 'Contract Name',
    selector: 'dir_contract_name',
    sortable: false,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contract No',
    selector: 'dir_contract_no',
  },
  {
    name: 'Seller',
    selector: 'seller',
    sortable: false,
  },
  {
    name: 'Buyer',
    selector: 'buyer',
    sortable: false,
  },
  {
    name: 'Commodity',
    selector: 'commodity',
    sortable: false,
  },
  {
    name: 'Quantity',
    selector: 'quantity',
    sortable: false,
  },
  {
    name: 'Dated',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
  }
];

export default columns
