const columns = [
  {
    name: 'User Name',
    selector: 'user_name',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'User Email',
    selector: 'user_email',
    sortable: false,
  },
  {
    name: 'Number of buyers',
    selector: 'buyers_added',
    sortable: false,
  }
];

export default columns