const columns = [
  {
    name: 'Contract Name',
    selector: 'contract_name',
    sortable: true,
    grow: 1,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contract Number',
    selector: 'contract_number',
    sortable: true,
    grow: 1,
  },
  {
    name: 'Importer\'s Bank',
    selector: 'buyerbankname',
    sortable: true,
  },
  {
    name: 'Exporter\'s Bank',
    selector: 'supplierbankname',
    sortable: true,
  },
  {
    name: 'Commodity',
    selector: 'commodity',
    sortable: false,
  },
  {
    name: 'Quantity',
    selector: 'quantity',
    sortable: false,
  },
  {
    name: 'Price/mt',
    selector: 'price',
    sortable: false,
  },
  {
    name: 'Total Price',
    selector: 'totalPrice',
    sortable: false,
  },
  {
    name: 'Payment Mode',
    selector: 'payment_type_pretty',
    sortable: true,
  },
  {
    name: 'Payment Status',
    selector: 'status',
    sortable: false,
    conditionalCellStyles: [
      {
        when: row => (row.payment_status === 0),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.payment_status === 1),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.payment_status !== 0 && row.payment_status !== 1),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
          color: 'white',
        },
      }
    ]
  },
  // {
  //   name: 'Actions',
  //   selector: 'action',
  //   sortable: true,
  // }
];

export default columns
