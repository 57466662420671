import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { setChannelPartnerRaisedInvoice, setFinanceDetails } from "../../store/actions/action";
import call from '../../service';
import AccountListTable from "./AccountListTable";
import { formatDate_Application } from '../../utils/dateFormaters';
import InvoiceFinance from '../finance/financeApply/invoiceFinance';
import LCFinance from '../finance/financeApply/lcFinance';
export const TableHeadData = [
  "Invoice ID",
  "Exporter",
  "Invoice Amount",
  "Disbursed Amount",
  "Created Date",
  "Action"
];

const RaisedInvoices = ({ userTokenDetails, navToggleState, dispatch, channelPartnerRaisedInvoice, setChannelPartnerRaisedInvoice, setFinanceDetails, financeDetails }) => {

  const [channelPartnerInvoice, setChannelPartnerInvoice] = useState([]);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    call('POST', 'getChannelPartnerInvoice', { "userId": userId }).then((result) => {
      console.log('running getChannelPartnerInvoice api-->', result);
      setChannelPartnerInvoice(result.invoiceData);
    }).catch((e) => {
      console.log('error in getChannelPartnerInvoice', e);
    })

  }, []);

  return (
    <>
      {
        channelPartnerRaisedInvoice.modal ?
          <>
            {channelPartnerRaisedInvoice.info.financeType == 2 &&
              <LCFinance
                userTokenDetails={userTokenDetails} />}
            {channelPartnerRaisedInvoice.info.financeType == 3 &&
              <InvoiceFinance
                userTokenDetails={userTokenDetails} />}
          </>
          :
          <div className="mx-4 bgCustom px-5 py-4 shadow-sm ">
            <div className="row align-items-center">
              <div className="col-12">
                <a href={'/raisedinvoice'}>
                  <button type="button" className="text-center btn btn-primary btn-sm m-1 float-right">View All</button>
                </a>
                <h5>Transaction in process</h5>
              </div>
            </div>

            <AccountListTable TableHeadData={TableHeadData}>
              {channelPartnerInvoice && channelPartnerInvoice.length && channelPartnerInvoice.map((key, index) => {
                if (index < 5) {
                  return (
                    <>
                      <tr key={index}>
                        <td>{key.reference_no}</td>
                        <td>{key.company_name}</td>
                        <td>{key.contract_amount}</td>
                        <td>{key.amount}</td>
                        <td>{formatDate_Application(key.created_at)}</td>
                        <td>
                          {key.status !== 4 &&
                            <button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => {
                              {
                                (key.seller_status / 1 === 2 && key.lender_status / 1 === 2) ?
                                  setFinanceDetails(true, key.type / 1, 2, { action: "viewFinance", FinanceID: key.id, sellerStatus: key.seller_status / 1, lenderStatus: key.lender_status / 1 }) :
                                  setFinanceDetails(true, key.type / 1, 1, { action: "viewFinance", FinanceID: key.id, sellerStatus: key.seller_status / 1, lenderStatus: key.lender_status / 1 })
                              };
                              setChannelPartnerRaisedInvoice(true, { "userId": key.tbl_user_id, "userName": key.company_name, "userEmail": key.email_id, "financeType": key.type })
                            }}>
                              {(key.seller_status / 1 === 2 && key.lender_status / 1 === 2) ?
                                <i class="fa fa-eye" aria-hidden="true"></i> :
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>}
                            </button>
                          }
                        </td>

                      </tr>
                    </>
                  );
                }
              })}
            </AccountListTable>
          </div>
      }</>
  );
};
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice,
    financeDetails: state.financeDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerRaisedInvoice: (modal, info) => { dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info })) },
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RaisedInvoices)