import React, { useEffect, useState } from 'react';
import { setproductDetails } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import toastDisplay from '../../utils/toastNotification';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import ProductDetails from './productDetails';

const FinanceCalculator = ({ userTokenDetails, navToggleState }) => {

  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({})
  const [financiers, setFinanciers] = useState([])
  const [countries, setCountries] = useState([])
  const [paymentTerms, setPaymentTerms] = useState([])
  const [calculations, setCalculations] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  const astrix = <span className="required-field text-danger">*</span>

  useEffect(() => {
    // call('POST', 'financeCalculator/getBanks', { type: "ID" }).then((result) => {
    //   setFinanciers(result)
    // }).catch((e) => {
    //   console.log('error in getBanks', e);
    // })
    getCountries()
    if (data.country) {
      getPaymentTerms()
    }
    else {
      setPaymentTerms([])
    }
  }, [data.country])

  async function getCountries() {
    setShowLoader(true)
    let result = await call('POST', 'financeCalculator/getCountries', { type: "ID" })
    setShowLoader(false)
    setCountries(result)
  }

  async function getPaymentTerms() {
    setShowLoader(true)
    let result = await call('POST', 'financeCalculator/getPaymentTerms', { type: "ID", country: data.country })
    setShowLoader(false)
    setPaymentTerms(result)
  }

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  const handleChange = event => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
    setCalculations([])
  }

  const validateFields = async () => {
    let validateFields = ["country", "invoiceAmount", "paymentTerm"]
    let err = {}

    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      if (!data[element]) {
        err[element] = "Mandatory field"
      }
    }

    if (!Object.keys(err).length) {
      if (data.invoiceAmount / 1 <= 0) {
        err["invoiceAmount"] = "Invoice amount should be greater than zero"
        return setErrors(err)
      }
      setShowLoader(true)
      let result = await call('POST', 'financeCalculator/getCalculations', {
        type: "ID", country: data.country, paymentTerm: data.paymentTerm,
        invoiceAmount: data.invoiceAmount
      })
      setShowLoader(false)
      setCalculations(result)
    }
    else {
      toastDisplay("Form validation error", "error")
    }

    setErrors(err)

  }

  return (
    <>
      <div className="container-fluid">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row">
          <Sidebar state={'financeCalculator'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 expanded-right"} id="app-main-div">

            <Header
              title={'Finance Calculator'}
              userTokenDetails={userTokenDetails} />

            <div className="mt-3 pt-3">
              <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}>Invoice Discounting</h2>
                <div className="accordionItemContent text-center">
                  <h3 className="my-2 pb-3">Check Cost Efficiency</h3>

                  {/* <div className="form-group row mb-4">
                    <div className='col-md-6'>
                      <h3>Select Financier {astrix}</h3>
                    </div>
                    <div className='col-md-3'>
                      <select className={" form-control" + (!errors.financier ? '' : ' border-danger')} name="financier" value={data.financier} onChange={handleChange} >
                        <option value="" selected>Select</option>
                        {financiers.length ? financiers.map((item, index) => (
                          <option value={item.created_by} >{item.name}</option>
                        )) : ""}
                      </select>
                      {errors.financier && (<p className="text-danger error-contract">{errors.financier}</p>
                      )}
                    </div>
                  </div> */}

                  <div className="form-group row text-center mb-4">
                    <div className='col-md-6'>
                      <h3>Select Country {astrix}</h3>
                    </div>
                    <div className='col-md-3'>
                      <select className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                        <option value="" selected>Select</option>
                        {countries.length ? countries.map((item, index) => (
                          <option value={item.country_code} >{item.name}</option>
                        )) : ""}
                      </select>
                      {errors.country && (<p className="text-danger error-contract text-left">{errors.country}</p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row text-center mb-4">
                    <div className='col-md-6'>
                      <h3>Invoice Amount (in $) {astrix}</h3>
                    </div>
                    <div className='col-md-3'>
                      <input type="number" className={" form-control" + (!errors.invoiceAmount ? '' : ' border-danger')} placeholder="" name="invoiceAmount" value={data.invoiceAmount} onChange={handleChange} />
                      {errors.invoiceAmount && (<p className="text-danger error-contract text-left">{errors.invoiceAmount}</p>
                      )}
                    </div>
                  </div>

                  <div className="form-group row text-center mb-4">
                    <div className='col-md-6'>
                      <h3>Payment Terms {astrix}</h3>
                    </div>
                    <div className='col-md-3'>
                      <select className={" form-control" + (!errors.paymentTerm ? '' : ' border-danger')} name="paymentTerm" value={data.paymentTerm} onChange={handleChange} >
                        <option value="" selected>Select</option>
                        {paymentTerms.length ? paymentTerms.map((item, index) => (
                          <option value={item.payment_terms_in_days} >{item.payment_terms_in_days + " Days"}</option>
                        )) : ""}
                      </select>
                      {errors.paymentTerm && (<p className="text-danger error-contract text-left">{errors.paymentTerm}</p>
                      )}
                    </div>
                  </div>

                  <button type="button" className="btn-primary btn my-3 btn-sm align-self-center" onClick={validateFields}>CALCULATE</button>

                  {calculations.length ? (
                    <div className="col-md-12 mt-3">
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th >#</th>
                              <th >Financier Name</th>
                              <th >Invoice Amount (in $)</th>
                              <th >Payment Terms</th>
                              <th >Interest Rate (in %)</th>
                              <th >Factoring Fees (in $)</th>
                              <th >One Time Setup Fees (in $)</th>
                              <th >Total Charges (in $)</th>
                              <th >You'll Get Approx (in $)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {calculations.map((item, index) => {
                              return (
                                <tr>
                                  <th >{index + 1}</th>
                                  <th >{item.company_name}</th>
                                  <th >{data.invoiceAmount + " USD"}</th>
                                  <th >{data.paymentTerm + " Days"}</th>
                                  <th >{item.interest_rate + " % ~ " + item.interestCharges + " USD"}</th>
                                  <th >{item.factoring_fees_in_usd + " USD"}</th>
                                  <th >{item.one_time_setup_fees_in_usd + " USD"}</th>
                                  <th >{item.totalCharges + " USD"}</th>
                                  <th >{item.amountAfterCharges + " USD"}</th>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <label className='text-left text-danger mt-4 font-weight-bold'>* Above figures are calculated for the reference based on data received from financiers.</label>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

            </div>


          </main>

        </div>

      </div>

    </>
  )
}



export default FinanceCalculator;