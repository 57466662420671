import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import { dateFormatter_DatePicker } from '../../utils/dateFormaters';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/bussinessDetailsComp';
import FilePreview from '../utilComponents/previewFile'
import { formatDate_Application, } from '../../utils/dateFormaters';

const KYCDocumentsComp = ({ userTokenDetails, userEmail_id, clientType, compCall, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  //States and Variables

  const [showLoader, setshowLoader] = useState(false);
  const [kycUpdate, setkycUpdate] = useState(true);
  const [refresh, setrefresh] = useState();
  const [values, setValues] = useState([]);
  const [kycData, setkycData] = useState([]);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [detailModalStatus, setdetailModalStatus] = useState(true);
  const [error, setError] = useState({});
  const [docModalStatus, setdocModalStatus] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  const aclMap = userTokenDetails && userTokenDetails.aclMap ? userTokenDetails.aclMap : {}


  //------------------------------------------------------------------------------------------------------------------------------------------------
  //UseEffect

  useEffect(() => {
    getProfileData()
  }, [refresh]);

  function getProfileData() {
    call('POST', 'getuserprofiledata', { 'email': userEmail_id ? userEmail_id : userEmail, "kyc": true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      let userProfileData = result.userProfileData
      setkycData(result.userKYCData)
      setshowLoader(false)
      if (result.userProfileData) {
        setValues({
          userTypeId: userProfileData.type_id,
          userId: userProfileData.tbl_user_id,
          email: userProfileData.login_id,
          contactPerson: userProfileData.contact_person,
          designation: userProfileData.designation,
          contactNo: userProfileData.contact_number,
          address: userProfileData.user_address,
          country: userProfileData.country_code,
          userAvatar: userProfileData.user_avatar ? userProfileData.user_avatar : null,
          kyc_done: (userProfileData.kyc_done / 1),
          panNo: userProfileData.pan_no,
          gstNo: userProfileData.gst_vat_no,
          iecNo: userProfileData.iec_no,
          cinNo: userProfileData.cin_no,
          company_pan_verification: userProfileData.company_pan_verification,
          company_cin_verification: userProfileData.company_cin_verification,
          company_gst_verification: userProfileData.company_gst_verification,
          company_iec_verification: userProfileData.company_iec_verification,
          refercode: userProfileData.refercode
        })
      }
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in getuserprofiledata', e);
    })
  }

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit("details");
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);


  //-------------------------------------------------------------------------------------------------------------------------------------------------
  //Functions

  function callPreviewFileComponent(values, action) {
    setfileData({ ...values, "action": action })
    setshowPreviewModal(true)
  };

  function kycGridCreator(kycData) {
    let docElements = kycData ? kycData.map((values, index) => {
      console.log("value in doc render=>", values)
      return (<tr key={kycData.doc_name}>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : 'NA'}</td>
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
        <td>{(values && values.uploaded_on) ? formatDate_Application(values.uploaded_on) : 'NA'}</td>
        <td className="text-center">
          {(values && values.doc_status === 1) ? <i className="text-success fa fa-check-circle" /> :
            (values && values.doc_status === 0) ? <i className="text-warning fa fa-hourglass-start" /> :
              (values && values.doc_status === 2) ? <i className="text-warning fa fa-hourglass-start" /> : ''}
        </td>
        <td className="row justify-content-end  m-0 ">

          {values.uploaded_by ?
            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button> : ''}

          {values.uploaded_by ? <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button> : ''}

          <div className="file-browse ">
            <button title="Update File" className="text-center btn btn-success btn-sm m-1">
              <i className="fa fa-upload" aria-hidden="true"></i>
            </button>
            {
              values.uploaded_by ? <input type="file" accept=".png,.jpg,.pdf" name={"doctype" + values.doc_type} onChange={(event) => updateDocument(event, values)} />
                :
                <input type="file" accept=".png,.jpg,.pdf" name={"doctype" + values.doc_type} onChange={(event) => updateDocument(event, values, 'manualEntry')} />
            }
          </div>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  };

  const updateDocument = (event, data, type) => {
    event.persist();
    setshowLoader(true)

    let user
    if (userTypeId / 1 === 3 || (userTypeId / 1 === 14 && clientType.type === 'buyer')) {
      user = 'buyer'
    } else if (userTypeId / 1 === 4 || (userTypeId / 1 === 14 && clientType.type === 'seller')) {
      user = 'seller'
    }

    let payloadUpload = {
      uploadingUser: userId,
      kycUserId: values.userId,
      userTypeName: user,
      id: data.id ? data.id : '',
      tbl_doc_id: data.tbl_doc_id ? data.tbl_doc_id : '',
      contract_id: "",
      linked_to: data.linked_to,
      isUpdateQuery: data.doc_status === 1 ? true : false,
      prevFileHash: data.file_hash,
      categoryId: data.category_id,
      type: type ? type : '',
      filePayload: {
        docNo: data.doc_no,
        docName: data.doc_name,
        genDocLabel: (data.doc_status === 1 && data.doc_type === 1) ? data.gen_doc_label : event.target.name,
        docType: data.doc_type,
        validUpto: data.valid_upto
      }
    }

    payloadUpload = JSON.stringify(payloadUpload);
    let formData = new FormData();
    formData.append('payloadUpload', payloadUpload);
    formData.append('doc', event.target.files[0]);

    call('POST', 'updateDoc', formData).then((result) => {
      if (result) {
        toastDisplay("File Uploaded", "success");
        // setrefresh(refresh + 1)
        getProfileData()
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      // setrefresh(refresh + 1)
      getProfileData()
      setshowLoader(false)
    })
  };

  const handleChange = (event) => {
    event.persist();
    setValues({ ...values, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(values));
    setIsSubmitting(true);
  };

  function handleSubmit() {
    setshowLoader(true)

    values.userId = userId
    values.userEmail = userEmail

    call('POST', 'kycVerification', values).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false);
      setrefresh(refresh + 1);
    }).catch(err => {
      setshowLoader(false);
      console.log("conn:", err);
      toastDisplay(err, "error");
    })
  }


  //------------------------------------------------------------------------------------------------------------------------
  //JSX Return

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {(detailModalStatus && (kycUpdate && values.kyc_done)) ?
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      KYC Verification
                      <button type="button" onClick={() => setdetailModalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-minus fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">

                    <div className="row pt-2">

                      <div className="form-group col-md-6">
                        <label>PAN Number</label>
                        <input type="text" className={" form-control" + (!error.panNo ? '' : ' border-danger')} placeholder="" name="panNo" value={values.panNo} onChange={handleChange} required />
                        {error.panNo && <p className="text-danger error-contract">{error.panNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>GST Number </label>
                        <input type="text" className={" form-control" + (!error.gstNo ? '' : ' border-danger')} placeholder="" name="gstNo" value={values.gstNo} onChange={handleChange} required />
                        {error.gstNo && <p className="text-danger error-contract">{error.gstNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>IEC Number </label>
                        <input type="text" className={" form-control" + (!error.iecNo ? '' : ' border-danger')} placeholder="" name="iecNo" value={values.iecNo} onChange={handleChange} required />
                        {error.iecNo && <p className="text-danger error-contract">{error.iecNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>CIN Number </label>
                        <input type="text" className={" form-control" + (!error.cinNo ? '' : ' border-danger')} placeholder="" name="cinNo" value={values.cinNo} onChange={handleChange} required />
                        {error.cinNo && <p className="text-danger error-contract">{error.cinNo}</p>}
                      </div>
                    </div>

                    <hr className="col-md-11" />
                    <div className="form-group col-md-12">
                      <button type="button" className="btn-primary btn mt-3 text-center btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              KYC Verification
              <button type="button" onClick={() => setdetailModalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-plus  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>
      }

      {
        (docModalStatus && (kycUpdate && values.kyc_done)) ?
          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        KYC Documents
                        <button type="button" onClick={() => setdocModalStatus(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                          <i class="fas fa-minus fa-sm text-danger "></i>
                        </button>
                      </h3>
                      <hr />
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive" style={{ "min-height": "150px" }}>
                        <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                          <thead>
                            <tr>
                              <th width="20">#</th>
                              <th>Type of Document</th>
                              <th width="300">Document Name</th>
                              <th width="200">Uploaded By</th>
                              <th width="200">Uploaded On</th>
                              <th width="100">Status</th>
                              <th className="text-center" width="150">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {kycGridCreator(kycData)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <h3 className="green mt-3">
                KYC Documents
                <button type="button" onClick={() => setdocModalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                  <i class="fas fa-plus  fa-sm text-primary"></i>
                </button>
              </h3>
            </div>
          </div>
      }

      {/* {(kycUpdate && values.kyc_done) ? <>
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      KYC Verification
                      <button type="button" onClick={() => setkycUpdate(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-minus fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>
        :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              KYC Verification
              <button type="button" onClick={() => setkycUpdate(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>}

      {(kycUpdate && values.kyc_done) ? <>
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      KYC Documents
                                    <button type="button" onClick={() => setkycUpdate(false)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-minus fa-sm text-danger "></i>
                      </button>
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive" style={{ "min-height": "150px" }}>
                      <table className="table table-striped table-sm m-0 mt-2 mb-2" cellSpacing={0} cellPadding={0}>
                        <thead>
                          <tr>
                            <th width="20">#</th>
                            <th>Type of Document</th>
                            <th width="300">Document Name</th>
                            <th width="200">Uploaded By</th>
                            <th width="200">Uploaded On</th>
                            <th width="100">Status</th>
                            <th className="text-center" width="150">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {kycGridCreator()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>
        :
        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <h3 className="green mt-3">
              KYC Documents
                            <button type="button" onClick={() => setkycUpdate(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                <i class="fas fa-folder-open  fa-sm text-primary"></i>
              </button>
            </h3>
          </div>
        </div>} */}

      {
        showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />
      }
    </>
  )
};


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCDocumentsComp)