import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { get } from 'store';
import call from '../../service';
import { formatDate_Application } from '../../utils/dateFormaters';
import customStyles from '../dataTablesColumsMap/customTableCss';
import Header from '../partial/header';
import Sidebar from '../partial/sidebar';
import { lcfast_grid_columns, lcfast_grid_columns_v2 } from './lcFastGridData';
import { LCQuoteForm } from './lcQuoteForm';
import moment from 'moment'
import Footer from '../partial/footer';
import FilePreview from '../utilComponents/previewFile';
import toastDisplay from '../../utils/toastNotification';
import { LCQuoteFormV2 } from './lcQuoteFormV2';
import { getUserDataFromCookie } from '../../utils/cookieHelper';

const LCFastDashboardV2 = ({ navToggleState }) => {

  let userTokenDetails = {}

  const queryParams = new URLSearchParams(window.location.search)
  const lcId = queryParams.get("id")
  const finId = queryParams.get("finId")

  if (finId) {
    userTokenDetails = {
      "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InN0YXR1cyI6MSwidHlwZUlEIjoxOSwidXNlcm5hbWUiOiJ0cmFkZWZpbmFuY2VAbWFpbGluYXRvci5jb20iLCJzdGVwIjoxLCJ1c2VySUQiOjEzNiwicGFyZW50SUQiOjB9LCJzZXNzaW9uSUQiOiI2ZjQ2ODEzOTg5MTg3NjY5Nzc5ZWJlYTU0NWUxYmNmNTAzMGVlNjQzIiwiaWF0IjoxNjY1MzkxNjM1LCJleHAiOjE2NzU3NTk2MzUsImF1ZCI6IkZPQiBERVYiLCJzdWIiOiJhY2Nlc3NNYWluIn0.yYeRBZlZ-Sur4Tfa0rRHTdhyv4hO9DZwSz6FfdEuLCUg9oJhh0GeURg7Z0HwAlTLH45MI5DsO7X_C4lOr1DtZw",
    }
  }
  else {
    userTokenDetails = getUserDataFromCookie()
  }

  console.log("tokennnnn", userTokenDetails);

  const [showLCQuoteForm, toggleLCQuoteForm] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [dbData, setDbData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [selectedLCData, setSelectedLCData] = useState({})
  const [filterCount, setFilterCount] = useState({})
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);


  const userTypeId = finId ? 8 : userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      getLCFastData()
    }
  };

  useEffect(() => {
    getLCFastData()
  }, [filterData, currentPage, perPage, showLCQuoteForm])

  useEffect(() => {
    if (lcId) {
      setshowLoader(true)
      call("POST", "getLCFastByIdV2", {
        docCreditNo: lcId
      }, userTokenDetails.token)
        .then((result) => {
          if (result.data) {
            toggleLCQuoteForm(true);
            setSelectedLCData(result.data)
          }
          else {
            window.location = '/lc-fast'
          }
          setshowLoader(false)
        })
        .catch((e) => {
          // console.log("Error while querying getLCFast:", e);
          setshowLoader(false)
        });
    }
  }, [lcId])


  function getStatus(item) {
    if (true) {
      if (item.offer_id) {
        return item.offer_status === 0 ? "Approved by Bank" : item.offer_status === 1 ? "Rejected by Bank" :
          item.offer_status === 2 ? "Deal Locked" : ""
      }
      else {
        return "Pending"
      }
    }
  }

  function callPreviewFileComponent(values, action) {
    if (values.base64 && values.file_data) {
      setfileData({ "action": action, "file_data": values.file_data, "base64": values.base64 })
    }
    else {
      setfileData({ "action": action, "file_hash": values.file_hash, "file_name": values.file_name, "file_type": values.doc_type })
    }
    setshowPreviewModal(true)
  }

  function formatResult(result) {
    result.forEach((item, index) => {
      result[index]["docCreditNo"] = item.docCreditNo
      result[index]["59"] = item.userResp["59"] || "NA"
      result[index]["32B"] = item.userResp["32B"] || "NA"
      result[index]["44E"] = item.userResp["44E"] || "NA"
      result[index]["44F"] = item.userResp["44F"] || "NA"
      result[index]["44C"] = item.userResp["44C"] || "NA"
      result[index]["51a"] = item.userResp["51a"] || "NA"
      result[index]["53a"] = item.userResp["53a"] || "NA"
      result[index]["status"] = item.status === 0 ? "Pending" : ""
      // result[index]["exp_date_of_shipment"] = moment(item.exp_date_of_shipment).format('YYYY-MM-DD')
      // result[index]["status"] = getStatus(item)
      // result[index]["roi"] = item.offer_id ? item.offer_details.roi : "NA"
      // result[index]["amount"] = item.offer_id ? item.offer_details.amount + (" " + item.offer_details.currency.split("::")[1]) : "NA"
      result[index]["lc_doc_action"] = (<>
        {<button className="btn btn-info btn-sm mr-2 ml-2"
          onClick={() => { callPreviewFileComponent({ "doc_type": "LC Document", ...item["lcDoc"] }, "view") }} >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>}
      </>)
      result[index]["action"] = (<>
        {<button className="btn btn-info btn-sm mr-2 ml-2" onClick={() => { toggleLCQuoteForm(true); setSelectedLCData(item) }} >
          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        </button>}
      </>)
    })
    return result
  }


  function getLCFastData() {
    setshowLoader(true)
    call("POST", "getLCFastV2", {
      userId, userTypeId, ...filterData, currentPage, perPage
    }, userTokenDetails.token)
      .then((result) => {
        setDbData(formatResult(result.data))
        setTotalRows(result.totalCount)
        // setFilterCount(result.filterCount)
        setshowLoader(false)
      })
      .catch((e) => {
        // console.log("Error while querying getLCFast:", e);
        setshowLoader(false)
      });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
    setCurrentPage(page)
  };

  const handlePageChange = page => {
    setCurrentPage(page)
  };

  function getTableColumns() {
    return lcfast_grid_columns_v2
  }


  return (
    <>
      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
        mktPlaceShipment={true}
      />}
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className='row'>
          {finId ? null : (
            <Sidebar state={'lc-fast'} userTokenDetails={userTokenDetails} />
          )}
          <main role="main" className={`ml-sm-auto ${finId ? 'col-lg-12' : 'col-lg-10'} ` + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            {finId ? null : (
              <Header
                title={showLCQuoteForm ? "LC Quote Application" : 'LC Fast'}
                // total={'Total LC Quotes ' + (filterCount.total || 0)}
                userTokenDetails={userTokenDetails}
                onButtonClick={() => toggleLCQuoteForm(true)}
                showNavBack={showLCQuoteForm}
                onBackAction={() => { window.location = '/lc-fast' }}
                showHeaderButton={!showLCQuoteForm}
              />
            )}
            {showLCQuoteForm &&
              <LCQuoteFormV2
                userTokenDetails={userTokenDetails}
                onClose={() => { toggleLCQuoteForm(false); setSelectedLCData({}) }}
                selectedLCData={selectedLCData}
              />}


            {!showLCQuoteForm && (
              <div class="d-md-flex mt-3 mb-3">
                {/* <div class="filter-bg">
                  <ul>
                    <li className={"pilot-cont  cursor-pointer " + (filterData.status === 0 ? "shadow" : '')} id="progessButton" onClick={() => {
                      setFilterData({ ...filterData, status: 0 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Pending (${filterCount.pending || 0})`}
                    </li>
                    <li className={"complete-cont  cursor-pointer " + (filterData.status === 1 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 1 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Approved (${filterCount.approved || 0})`}</li>
                    <li className={"danger-cont text-danger  cursor-pointer " + (filterData.status === 2 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 2 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Rejected (${filterCount.rejected || 0})`}</li>
                    <li className={"primary-cont cursor-pointer " + (filterData.status === 3 ? "shadow" : '')} onClick={() => {
                      setFilterData({ ...filterData, status: 3 })
                    }}>
                      <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Deal Locked (${filterCount.deal_locked || 0})`}</li>
                  </ul>
                </div> */}


                {/* <div class="ml-md-auto">
                  <ul class="range-slider">
                    <li>
                      <input name="search" id="searchInput" placeholder="Search" value={filterData.search} onChange={(event) => {
                        setFilterData({ ...filterData, search: event.target.value })
                      }} onKeyDown={handleKeyPress} />
                      <button type="button" onClick={() => {
                        getLCFastData()
                      }}><i class="fas fa-search fa-lg"></i></button>
                    </li>

                    <li>
                      <label>From</label>
                      <input type="date" id="searchFromdate" name="fromDate" value={filterData.fromDate} onChange={(event) => {
                        setFilterData({ ...filterData, fromDate: event.target.value })
                      }} />
                    </li>
                    <li>
                      <label>To</label>
                      <input type="date" id="searchTodate" name="toDate" value={filterData.toDate} onChange={(event) => {
                        setFilterData({ ...filterData, toDate: event.target.value })
                      }} />
                    </li>
                    <li>
                      <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() =>
                        setFilterData({ search: "", fromDate: "", toDate: "" })} />
                    </li>
                  </ul>
                </div> */}
              </div>
            )}

            {!showLCQuoteForm &&
              <div class="table-responsive">
                <DataTable
                  columns={getTableColumns()}
                  data={dbData}
                  progressPending={showLoader}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                  persistTableHead
                  // expandableRows
                  // expandableRowsComponent={<></>}
                  // expandOnRowClicked
                  highlightOnHover
                  // onSort={handleSort}
                  sortServer
                  striped
                  noHeader
                  customStyles={customStyles}
                />
              </div>
            }

            {/* {showLCQuoteForm ? null : (
              <>
                <hr />
                <div className="float-left col-md-6 ">
                  <div className="col-md-12 row">
                    <div className="col-md-6">
                      <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                        <i class="fas fa-file-csv"></i> Export CSV
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )} */}

          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}


const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(LCFastDashboardV2)
