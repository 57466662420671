import React, { useEffect, useState } from 'react';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import { connect } from 'react-redux';
import { setContractDetails, setContractDetailLogi } from '../../store/actions/action';
import ContractDetail from '../contractDetails/contractDetails';
import ContractDetailShipment from '../logistics/contractDetailShipment';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application, formatDateTime_Application } from '../../utils/dateFormaters';
import Footer from '../partial/footer';

const AllNotification = ({ userTokenDetails, cDetailsState, setContractDetails, setContractDetailLogi, contractDetailLogi, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [searchkey, setSearchKey] = useState("");
  const [dbData, setdbData] = useState({})
  // const [dbDataFilter, setdbDataFilter] = useState([])
  const [filter, setfilter] = useState(false)
  const [refresh, setrefresh] = useState(0)
  const [accorddata, setaccorddata] = useState({
    accord_0: true,
    accord_1: false,
    accord_2: false
  });

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    if (filterData.fromDate || filterData.toDate || filterData.search) {
      setfilter(true)
    } else {
      setfilter(false)
    }

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getNotifications', {
      userTypeId: userTypeId,
      userID: userId,
      email: userEmail,
      sidebar: false,
      from: filterData.fromDate ? filterData.fromDate : "",
      to: filterData.toDate ? filterData.toDate : "",
      search: filterData.search ? filterData.search : "",
      filter: (filterData.fromDate || filterData.toDate || filterData.search) ? true : false
    }).then((result) => {
      console.log("result in get notification api-->>", result);
      setdbData(result)
    }).catch((e) => {
      console.log("Error while querying getNotifications :->", e);
    })
    //------------------------------------------------------------------
  }, [refresh, filterData]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setFilterData(filterData => ({ ...filterData, [event.target.name]: event.target.value }));
  };

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function handleContractredirect(contractNO, type) {
    if (type === 1) {
      setContractDetails(contractNO)
    } else {
      setContractDetailLogi(true, { contract_number: contractNO })
    }
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  // const fetchDataFilter = (event) => {
  //   call('POST', 'getNotifications', {
  //     userTypeId: userTypeId,
  //     userID: userId,
  //     email: userEmail,
  //     sidebar: false,
  //     filter: true,
  //     from: filterData.fromDate !== undefined ? filterData.fromDate : "",
  //     to: filterData.toDate !== undefined ? filterData.toDate : "",
  //     search: filterData.search !== undefined ? filterData.search : ""
  //   }).then((result) => {
  //     console.log("result fetchDataFilter-->>:", result);
  //     setdbDataFilter(result)
  //   }).catch((e) => {
  //     console.log("Error while querying fetchDataFilter :->", e);
  //   })
  // }

  function applyFilter() {
    setFilterData(filterData => ({ ...filterData, "search": searchkey }));
    // setaccorddata({
    //   accord_0: true,
    //   accord_1: false,
    //   accord_2: false
    // })
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      applyFilter()
    }
  };

  function resetFilter() {
    setrefresh(refresh + 1)
    setFilterData({ ...filterData, search: "", fromDate: "", toDate: "" })
    setfilter(false)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Notif Body Component
  function CreateNotificationBody(notifData) {
    console.log("notifData-->>:", notifData);
    let notifBody = notifData ? notifData.map((value, index) => {
      let NotifData = value.notif_data ? JSON.parse(value.notif_data) : {}
      return (
        <li className={"d-flex mb-2 " + (value.notif_type / 1 === 1 ? "red-bg" :
          value.notif_type / 1 === 2 ? "green-bg" :
            value.notif_type / 1 === 3 ? "blue-bg" :
              value.notif_type / 1 === 4 ? "yellow-bg" : "common-bg")}>
          <div className="ntf_time">
            <label>{value.created_at ? formatDateTime_Application(value.created_at) : "--"}</label>
          </div>
          <div className="ntf_content">
            {([1, 2, 3, 5].includes(value.notif_type / 1)) && <>
              <ul className="p-0 d-flex">
                <li className="col-md-4">
                  <label className="text-info">
                    {(value.address) ? <a className="cursor-pointer" onClick={() => handleContractredirect(value.contract_no, value.notif_type / 1)}>
                      #{value.contract_no ? value.contract_no : "--"}
                    </a> : '#' + (value.contract_no ? value.contract_no : "--")}
                  </label>
                  <span>Contract No</span>
                </li>

                {(value.notif_type / 1 === 1 || value.notif_type / 1 === 5) && <>
                  <li className="col-md-4">
                    <label>{value.supplier ? value.supplier : "--"}</label>
                    <span>Exporter</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.buyer ? value.buyer : "--"}</label>
                    <span>Importer</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.commodity_name ? value.commodity_name.toUpperCase() : "--"} | {value.quantity ? value.quantity : "--"}mt</label>
                    <span>Commodity</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.currency ? value.currency.split(':')[1] : "--"} {value.price ? value.price : "--"}/mt</label>
                    <span>Price</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.laycan_start ? formatDate_Application(value.laycan_start) : "--"} - {value.laycan_end ? formatDate_Application(value.laycan_end) : "--"}</label>
                    <span>Laycan Start-End</span>
                  </li>
                </>}

                {(value.notif_type / 1 === 2) && <>
                  <li className="col-md-4">
                    <label>{value.charterername ? value.charterername : "--"}</label>
                    <span>Charterer</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.shipownername ? value.shipownername : '--'}</label>
                    <span>Ship Owner</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.commodity_name ? value.commodity_name.toUpperCase() : "--"} | {value.ship_quantity ? value.ship_quantity : "--"}mt</label>
                    <span>Commodity</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.ship_currency ? value.ship_currency.split(':')[1] : "--"} {value.ship_price ? value.ship_price : "--"}/mt</label>
                    <span>Price</span>
                  </li>
                  <li className="col-md-4">
                    <label>
                      {value.ship_load_country ? <span className="shadow">
                        <ReactCountryFlag
                          countryCode={value.ship_load_country.split(':')[0]}
                          style={{ width: '25px', height: '25px' }} svg />
                      </span> : ''}
                      {"  -  "}
                      {value.ship_unload_country ? <span className="shadow">
                        <ReactCountryFlag
                          countryCode={value.ship_unload_country.split(':')[0]}
                          style={{ width: '25px', height: '25px' }} svg />
                      </span> : ""}
                    </label>
                    <span>Loading-Unloading Country</span>
                  </li>
                </>}

                {(value.notif_type / 1 === 3) && <>
                  <li className="col-md-4">
                    <label>
                      {value.load_country ? <span className="shadow">
                        <ReactCountryFlag
                          countryCode={value.load_country.split(':')[0]}
                          style={{ width: '25px', height: '25px' }} svg />
                      </span> : ''}
                    </label>
                    <span>Loading Country</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.loading_port ? value.loading_port : "--"}</label>
                    <span>Loading Port</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.commodity_name ? value.commodity_name.toUpperCase() : "--"} | {value.quantity ? value.quantity : "--"}mt</label>
                    <span>Commodity</span>
                  </li>
                  <li className="col-md-4">
                    <label>
                      {value.unload_country ? <span className="shadow">
                        <ReactCountryFlag
                          countryCode={value.unload_country.split(':')[0]}
                          style={{ width: '25px', height: '25px' }} svg />
                      </span> : ''}
                    </label>
                    <span>Unloading Country</span>
                  </li>
                  <li className="col-md-4">
                    <label>{value.unloading_port ? value.unloading_port : "--"}</label>
                    <span>Unloading Port</span>
                  </li>
                </>}
              </ul>
            </>}
            {(value.notif_type / 1 === 4) && <>
              <ul className="p-0 d-flex">
                <li className="col-md-4">
                  <label className="text-info">{NotifData ? NotifData.companyName : "--"} </label>
                  <span>Company Name</span>
                </li>
                <li className="col-md-4">
                  <label className="text-info">{NotifData ? NotifData.userName : "--"} </label>
                  <span>User Name</span>
                </li>
                <li className="col-md-4">
                  <label className="text-info">{NotifData ? NotifData.userType : "--"} </label>
                  <span>User Type</span>
                </li>
              </ul>
            </>}

            {([6, 7].includes(value.notif_type / 1)) && <>
              {console.log("values in 67==>", value)}
              <ul className="p-0 d-flex">
                {(value.notif_type / 1 === 6) &&
                  <li className="col-md-4">
                    <label className="text-info">{value.contract_no ? value.contract_no : "--"} </label>
                    <span>{(value.event === "LC_FAST" || value.event === "INVOICE_DISCOUNTING") ?
                      value.event.replace("_", " ") : "Loan Against"}</span>
                  </li>}
              </ul>
            </>}

          </div>
          <div className="ntf_mode">
            <label>
              {(value.notif_type / 1 === 3 && value.event === "QUOTATION_SUBMIT" && [5, 10, 11].indexOf(userTypeId) === -1) ? value.message.replace('Submited', 'Received') : value.message}
              {(value.event === "AGREE_CONTRACT_SELLER" || value.event === "UPDATE_CONTRACT_SELLER") ? value.supplier : (value.event === "AGREE_CONTRACT_BUYER" || value.event === "UPDATE_CONTRACT_BUYER") ? value.buyer : ''}
              {(value.notif_type / 1 === 2) &&
                <>
                  <br />
                  {(value.address) ? <a className="cursor-pointer text-info" onClick={() => handleContractredirect(value.contract_number)}>
                    {(value.event === "CREATE_CONTRACT_SHIPMENT" || value.event === "CREATE_CONTRACT_BROKER") ? value.contract_number ? value.contract_number : '--' : ''}
                  </a> : (value.event === "CREATE_CONTRACT_SHIPMENT" || value.event === "CREATE_CONTRACT_BROKER") ? value.contract_number ? value.contract_number : '--' : ''}
                </>}
              {(value.event === "QUOTATION_SUBMIT" || value.event === "QUOTATION_UPDATE") ? <><br /><a className="cursor-pointer text-info" href={'/quotationlist'}>Quotation list</a></> : ''}
            </label>
          </div>
        </li>
      )
    }) : ''

    return notifBody
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            {cDetailsState.modal ?
              <ContractDetail userTokenDetails={userTokenDetails} /> :
              contractDetailLogi.page ? <ContractDetailShipment userTokenDetails={userTokenDetails} /> :
                <>
                  <Header
                    title={'Notifications'}
                    total={'Total ' + ((dbData.todayNotifications !== undefined && dbData.sevendayNotifications !== undefined && dbData.olderNotifications !== undefined && filter === false)
                      ? (dbData.todayNotifications.length + dbData.sevendayNotifications.length + dbData.olderNotifications.length)
                      : dbData.Notifications ? dbData.Notifications.length : 0) + ' Notifications'}
                    userTokenDetails={userTokenDetails} />
                  <div className="d-md-flex mt-3 mb-3 notify_list">
                    <div className="mr-md-auto">
                      <ul className="view-slider">
                        <li className="red-bg">
                          <label>Contract</label>
                        </li>
                        <li className="green-bg">
                          <label>Shipment</label>
                        </li>
                        <li className="blue-bg">
                          <label>Quotation</label>
                        </li>
                        <li className="yellow-bg">
                          <label>Payments</label>
                        </li>
                        {(userTypeId / 1 === 2) && <li className="">
                          <label>User</label>
                        </li>}
                      </ul>
                    </div>
                    <div className="ml-md-auto relative">
                      {/* {filter === true && <div className="reset" onClick={() => resetFilter()}>reset</div>} */}
                      <ul className="range-slider">
                        <li>
                          <input placeholder="Search" name="search" className="form-control" value={searchkey} onChange={(event) => { setSearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                          <button onClick={() => applyFilter()}><i class="fas fa-search fa-lg"></i></button>
                        </li>
                        <li>
                          <label>From</label>
                          <input type="date" name="fromDate" className="form-control" value={filterData.fromDate} onChange={handleChange} />
                        </li>
                        <li>
                          <label>To</label>
                          <input type="date" name="toDate" className="form-control" value={filterData.toDate} onChange={handleChange} />
                        </li>
                        <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => resetFilter()} /></li>
                      </ul>
                    </div>
                  </div>
                  <div className="notification_listing">
                    {filter === false &&
                      <div className="">
                        <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}> Today</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {CreateNotificationBody(dbData.todayNotifications)}
                                {(dbData.todayNotifications !== undefined && dbData.todayNotifications.length === 0) ? <h1 className="accordionItemHeading">No Notifications for Today :) All done</h1> : ""}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className={"accordionItem" + (accorddata.accord_1 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_1')}> Last 7 Days</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {CreateNotificationBody(dbData.sevendayNotifications)}
                                {(dbData.sevendayNotifications !== undefined && dbData.sevendayNotifications.length === 0) ? <h1 className="accordionItemHeading">No Notifications Here</h1> : ""}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className={"accordionItem" + (accorddata.accord_2 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_2')}> Older</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {CreateNotificationBody(dbData.olderNotifications)}
                                {(dbData.olderNotifications !== undefined && dbData.olderNotifications.length === 0) ? <h1 className="accordionItemHeading">No Notifications Here</h1> : ""}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>}
                    {filter === true &&
                      <>
                        <div className={"accordionItem" + (accorddata.accord_0 ? " open" : " closed")}>
                          <h2 className="accordionItemHeading" onClick={() => handleAccordion('accord_0')}> Filtered Notifications Between {filterData.fromDate ? filterData.fromDate : "--"} to {filterData.toDate ? filterData.toDate : "--"} with search {filterData.search ? filterData.search : "--"}</h2>
                          <div className="accordionItemContent">
                            <div className="nt_data">
                              <ul className="p-0  Ul-list">
                                {CreateNotificationBody(dbData.Notifications)}
                                {(dbData.Notifications && dbData.Notifications.length === 0) ? <h1 className="accordionItemHeading">No Notifications found in current search :(, Try again</h1> : ""}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </>}
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  );
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractDetailLogi: state.contractDetailLogi,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractDetailLogi: (flag, data) => { dispatch(setContractDetailLogi({ page: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllNotification)
