

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap'
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application, formatDateTime_Application } from '../../utils/dateFormaters';


const ContractTrail = ({ userTokenDetails, contractNo, modal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [Data, setData] = useState({});
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getcontractchangelogs', { "contract_number": contractNo }).then(async (result) => {
      console.log("result in getcontractchangelogs-->", result)
      setData(result)
    }).catch((error) => {
      console.log("error occur in getcontractchangelogs ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="mt-2">
      <h2 className="mt-1 mb-3" >Change logs</h2>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Contract No</th>
            <th>Contract Name</th>
            <th>Commodity</th>
            <th>Laycan Start</th>
            <th>Laycan End</th>
            <th>Payment Mode</th>
            <th>Price Type</th>
            <th>Price</th>
            <th>Currency</th>
            <th>Quantity</th>
            <th>Exporter's Inv. Agency</th>
            <th>Importer's Inv. Agency</th>
            <th>Third Party Inv. Agency</th>
            <th>Trade Insurer</th>
            <th>Commodity Insurer</th>
            <th>Ship Owner</th>
            <th>Load Country</th>
            <th>Loading Port</th>
            <th>Unload Country</th>
            <th>Unloading Port</th>
            <th>Commodity Specifications</th>
            <th>Contract TNC</th>
            <th>Dated</th>
            <th>Changes By</th>
          </tr>
        </thead>
        <tbody>
          {(Data && Data.length) ? Data.map((value, index) => {
            return (<tr>
              <td>{index}</td>
              <td>{value.contract_number ? value.contract_number : "-"}</td>
              <td>{value.contract_name ? value.contract_name : "-"}</td>
              <td>{value.commodity_name ? value.commodity_name : "-"}</td>
              <td>{value.laycan_start_clog ? formatDate_Application(value.laycan_start_clog) : "-"}</td>
              <td>{value.laycan_end_clog ? formatDate_Application(value.laycan_end_clog) : "-"}</td>
              <td>{value.payment_mode_clog ? value.payment_mode_clog : "-"}</td>
              <td>{value.price_type_clog ? value.price_type_clog : "-"}</td>
              <td>{value.price_clog ? value.price_clog : "-"}</td>
              <td>{value.currency_clog ? value.currency_clog.split(':')[1] : "-"}</td>
              <td>{value.quantity_clog ? value.quantity_clog : "-"}</td>
              <td>{value.agencynameExporter ? value.agencynameExporter : "-"}</td>
              <td>{value.agencynameImporter ? value.agencynameImporter : "-"}</td>
              <td>{value.agencynameThird ? value.agencynameThird : "-"}</td>
              <td>{value.tradeinsurer ? value.tradeinsurer : "-"}</td>
              <td>{value.commodityInsurer ? value.commodityInsurer : "-"}</td>
              <td>{value.shipOwner ? value.shipOwner : "-"}</td>
              <td>{value.load_country_clog ? <span className="shadow">
                <ReactCountryFlag
                  countryCode={value.load_country_clog.split(':')[0]}
                  style={{ width: '25px', height: '25px' }} svg />
              </span> : "-"}</td>
              <td>{value.loading_port_clog ? value.loading_port_clog : "-"}</td>
              <td>{value.unload_country_clog ? <span className="shadow">
                <ReactCountryFlag
                  countryCode={value.unload_country_clog.split(':')[0]}
                  style={{ width: '25px', height: '25px' }} svg />
              </span> : "-"}</td>
              <td>{value.unloading_port_clog ? value.unloading_port_clog : "-"}</td>
              <td>{value.specification_clog > 0 ? "Updated" : "-"}</td>
              <td>{value.tnc_clog > 0 ? "Updated" : "-"}</td>
              <td>{value.created_at_clog ? formatDateTime_Application(value.created_at_clog) : "-"}</td>
              <td>{value.changingParty ? value.changingParty : "-"}</td>
            </tr>
            )
          }) : ""}
        </tbody>
      </Table>

    </div>
  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------


export default ContractTrail
