import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import validate from '../../utils/validation_files/kycFormValidationRules'
import toastDisplay from '../../utils/toastNotification';

const TrulioKYCForm = ({ userTokenDetails, userData, setdoKYC, clientType, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [formJson, setformJson] = useState({});
  const [concentData, setconcentData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [refresh, setrefresh] = useState()

  const [trulioObject, setTrulioObject] = useState({
    "AcceptTruliooTermsAndConditions": false,
    "CleansedAddress": false,
    "ConfigurationName": "Identity Verification",
    "ConsentForDataSources": [],
    "CountryCode": userData.countryCode,
    "DataFields": {}
  });

  //---------------------------------------------------------------------------------------------------------------------

  console.log("formJson===>", formJson)
  // console.log("values===>", values)
  // console.log("trulioObject===>", trulioObject)
  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    let formRoute = 'v1/kyc/fields/' + userData.countryCode
    let concentRoute = 'v1/kyc/concents/' + userData.countryCode

    call('get', formRoute).then((result) => {
      console.log("result in get filed==>", result.fields)
      setformJson(JSON.parse(result.fields))
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in get filed', e);
    })

    call('get', concentRoute).then((result) => {
      console.log("result in concentRoute==>", result)
      setconcentData(result)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log('error in concentRoute', e);
    })
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    setError(error => ({ ...error, [event.target.name]: "" }))
  };

  async function handleSubmit() {
    setshowLoader(true)

    await updateTruilioObject()

    console.log("final trulioObject===>", trulioObject)

    call('post', "v1/kyc/verify", trulioObject).then((result) => {
      console.log("result in verify verify==>", result)
      setshowLoader(false)
      toastDisplay(result.main, "success");
      setTimeout(function () { setdoKYC(false) }, 3000);
    }).catch((e) => {
      setshowLoader(false)
      toastDisplay(e, "error");
      console.log('error in verify verify', e);
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(values, formJson));
    setIsSubmitting(true);
  };


  async function updateTruilioObject() {

    let TempTrulioObject = {}

    function iterateObjectKeys(object, tempTrulioObject) {
      console.log("object in iterateObjectKeys==>", object)

      for (let key of Object.keys(object)) {
        tempTrulioObject[key] = {}
        if (object[key].properties && object[key].properties[Object.keys(object[key].properties)[0]].type === "object") {
          iterateObjectKeys(object[key].properties, tempTrulioObject[key])
        } else {
          iterateInputKeys(object[key], key, tempTrulioObject[key])
        }

      }

    }


    function iterateInputKeys(object, pkey, tempTrulioObject) {
      console.log("object in iterateInputKeys==>", object)

      for (let key of Object.keys(object.properties)) {
        if (object.properties[key].type === "object") {
          tempTrulioObject[key] = {}
          iterateInputKeys(object.properties[key].properties, key, tempTrulioObject[key])
        } else {
          if (values[pkey + ":" + key])
            tempTrulioObject[key] = ((object.properties[key].type === "int") ? (values[pkey + ":" + key]) / 1 : values[pkey + ":" + key])
        }

      }
    }

    await iterateObjectKeys(formJson.properties, TempTrulioObject)
    console.log("TempTrulioObject==>", TempTrulioObject)

    if (TempTrulioObject.NationalIds && Object.keys(TempTrulioObject.NationalIds).length) {
      TempTrulioObject.NationalIds = [TempTrulioObject.NationalIds]
    } else {
      TempTrulioObject.NationalIds = []
    }

    trulioObject.DataFields = TempTrulioObject

    if (concentData.length) {
      for (let item of concentData) {
        trulioObject.ConsentForDataSources.push(item.name)
      }
    }

  }


  function generatePropertyBody(object) {
    // console.log("object in generatePropertyBody==>", object)
    return (
      <>
        {Object.keys(object).map((key) => {
          return (
            <>
              <div className="col-md-12 row">
                <div className="col-md-12 mb-3" >
                  <h3 className="col-md-12 text-primary">{object[key].title}</h3>
                  <hr className="col-md-12" />
                </div>
                {(object[key].properties && object[key].properties[Object.keys(object[key].properties)[0]].type === "object") ?
                  generatePropertyBody(object[key].properties) :
                  generateInputPropertyBody(object[key], key)}
              </div>
              <hr className="col-md-12" />
            </>
          )
        })}
      </>
    )
  }

  function generateInputPropertyBody(object, pkey) {
    // console.log("object in generateInputPropertyBody==>", object)
    return (
      <>
        {Object.keys(object.properties).map((key) => {
          return (<>
            {object.properties[key].type === "object" ?
              generateInputPropertyBody(object.properties[key].properties, key)
              :
              <div className="col-md-4" >
                <div className="form-group" >
                  <label>
                    {object.properties[key].label ? object.properties[key].label :
                      object.properties[key].title ? object.properties[key].title :
                        key} </label>{(object.required && object.required.includes(key)) ? <span className="required-field text-danger">*</span> : ""}

                  <input type={(object.properties[key].type === "int" ? "number" : "string")}
                    className={" form-control" + (!error[pkey + ":" + key] ? '' : ' border-danger')}
                    placeholder={"Enter " + object.properties[key].description}
                    name={pkey + ":" + key} value={values[pkey + ":" + key]} defaultValue={object.properties[key].value} onChange={handleChange} />
                  {error[pkey + ":" + key] && <p className="text-danger error-contract">{error[pkey + ":" + key]}</p>}
                </div>
              </div>}
          </>)
        })}
      </>
    )

  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (

    <div className="col-md-12 mt-5" >
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 text-center">
        <h2 className="text-primary">KYC Verification</h2>
      </div>
      <div className="row mt-3">
        {(Object.keys(formJson).length > 0) ? generatePropertyBody(formJson.properties) : ""}
      </div>

      {concentData.length > 0 && concentData.map((cdata) => {
        return (
          <>
            <hr className="col-md-12" />
            <div className="col-md-12 mt-1 mb-1 text-left" >
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>{cdata.name}</b></div>
                <div className="col-md-9 pl-0" ><p>{cdata.text} </p></div>
              </div>
            </div>
            <hr className="col-md-12" />
          </>
        )
      })}

      <div className="form-check mt-1 mb-1" >
        <input className="form-check-input" type="checkbox" name="agreeTrulioTNC" id="agreeTrulioTNC" onChange={(event) => {
          event.persist();
          setTrulioObject(trulioObject => ({ ...trulioObject, "AcceptTruliooTermsAndConditions": event.target.checked }));

        }} checked={trulioObject.AcceptTruliooTermsAndConditions} />
        <label className="form-check-label" for="agreeTrulioTNC"> Agree to terms and conditions of trulio</label>
      </div >

      <hr className="col-md-12" />
      <div className="col-md-12 row" >
        <div className="col-md-6" >
          <button type="button" className="btn-danger btn btn-sm mt-3" onClick={() => setdoKYC(false)}>Cancel </button>
        </div>
        <div className="col-md-6" >
          <button type="button" className="btn-success btn btn-sm mt-3" onClick={() => prehandleSubmit()} disabled={!trulioObject.AcceptTruliooTermsAndConditions}>Submit </button>
        </div>

      </div>

    </div>

  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    clientType: state.clientType,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrulioKYCForm)
