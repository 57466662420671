import React, { useEffect, useState } from 'react';
import call from '../../service';
// import toastDisplay from '../../utils/toastNotification';


const ContainerComp = ({ userTokenDetails, contractNo, JSONData, setContainerDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [showLoader, setshowLoader] = useState(false);
  const [jsonData, setjsonData] = useState({});
  const [containerType, setcontainerType] = useState("");

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (JSONData && Object.keys(JSONData).length) {
      setjsonData(JSONData)
    } else {
      setshowLoader(true)
      call('post', 'getcontractcontainers', { contractNo: contractNo }).then((result) => {
        console.log("result in getcontractcontainers=>", result)
        let jsonTemp = result.container_data && Object.keys(result.container_data) ? result.container_data : {}
        setjsonData(jsonTemp)
        setcontainerType(result.container_type_pretty)
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log('error in getcontractcontainers', e);
      })
    }
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------
  console.log("JSONData===>", JSONData)
  console.log("jsonData===>", jsonData)
  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    let keys = event.target.name.split(":")
    let tempJsonObj = { ...jsonData }

    tempJsonObj[keys[1]][keys[0]] = event.target.value
    setjsonData(tempJsonObj)
    setContainerDetails(tempJsonObj)
  };

  const handleAddContainer = () => {
    // event.persist();
    let key = Object.keys(jsonData).length + 1
    let tempObj = {
      "containerNo": "",
      "containerVesselNo": "",
      "quantity": ""
    }
    let tempJsonObj = { ...jsonData }
    tempJsonObj[key] = tempObj
    setjsonData(tempJsonObj)
    setContainerDetails(tempJsonObj)
  };

  const handleDelete = (delKey) => {

    let tempJsonObj = { ...jsonData }
    delete tempJsonObj[delKey]
    setjsonData(tempJsonObj)
    setContainerDetails(tempJsonObj)
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  function createBody() {
    let keyArray = Object.keys(jsonData)

    let returnHtml = keyArray.length ? keyArray.map((key, index) => {
      return (
        <>
          <div className="col-md-12 m-3 row">
            <div className="col-md-11 row">
              <div className="col-md-3">
                <label>Container No</label>
                <input type="text" name={"containerNo:" + key} value={jsonData[key].containerNo} className="form-control" onChange={handleChange} />
              </div>
              <div className="col-md-3">
                <label>Container Vessel No</label>
                <input type="text" name={"containerVesselNo:" + key} value={jsonData[key].containerVesselNo} className="form-control" onChange={handleChange} />
              </div>
              <div className="col-md-3">
                <label>Container Type</label>
                <input type="text" value={containerType} className="form-control" disabled />
              </div>
              <div className="col-md-3">
                <label>Quantity in Container</label>
                <input type="number" name={"quantity:" + key} value={jsonData[key].quantity} className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-md-1">
              <label> </label>
              <button type="button" className="btn btn-danger btn-sm mt-4" onClick={() => handleDelete(key)}><i class="fas fa-trash"></i></button>
            </div>
          </div>
          <hr />
        </>
      )
    }) : ""

    return returnHtml
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 row">
        <h3 className="col-md-12">Provide Container Details</h3>
        <div className="col-md-12 mt-2">
          {createBody()}
        </div>
        <hr />
        <button type="button" className="btn btn-success btn-sm ml-5" onClick={() => handleAddContainer()}>Add Container +</button>
      </div>
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------

export default ContainerComp
