const columns = [
  {
    name: 'Agreement Name',
    selector: 'agreementname',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Uploaded By',
    selector: 'uploadedBy',
  },
  {
    name: 'Uploaded On',
    selector: 'uploadedOn',
  },
  {
    name: 'Signed By',
    selector: 'signedBy'
  },
  {
    name: 'Actions',
    selector: 'actions',
  }
];

export default columns