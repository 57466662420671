import React, { useState, useEffect } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../homeComponents/header';
import { setRegistrationData } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';
import { is_valid } from '../../utils/myFunctions';


const ServiceLocationPicker = ({ registrationData, setRegistrationData, dispatch }) => {

  const [portCountries, setPortCountries] = useState([])
  const [portNames, setPortNames] = useState([])
  const [data, setdata] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  let [formError, setformError] = useState({});
  const [selectedPort, setSelectedPort] = useState([])
  const [addMoreArr, setaddMoreArr] = useState([1])
  const [selectedPortsArray, setselectedPortsArray] = useState([]);


  useEffect(() => {

    if (registrationData.payload && is_valid(registrationData.payload.step9)) {
      // console.log("payload9", registrationData.payload);
      setSelectedPort(registrationData.payload.step9)
      setaddMoreArr(registrationData.payload.step9_other_data.addMoreArr)
      setdata(registrationData.payload.step9_other_data.data)
      setformError(registrationData.payload.step9_other_data.formError)
    }

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getCountryMaster', {}).then((result) => {
      setPortCountries(result);
      setshowLoader(false);
    }).catch((e) => {
      // console.log('error in getCountryMaster', e);
      setshowLoader(false);
    })

    // console.log("ports:", registrationData.payload.step9);
    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal
    let temp = registrationData && registrationData.payload && registrationData.payload.step9 ? registrationData.payload.step9 : [];
    setselectedPortsArray(temp)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let addNewPort = (id) => {
    if (!is_valid(data["portCountry" + id]) || !is_valid(data["portName" + id])) {
      setformError({
        ["portCountry" + id]: !is_valid(data["portCountry" + id]) ? "Port Country is Mandatory" : '',
        ["portName" + id]: !is_valid(data["portName" + id]) ? "Port Name is Mandatory" : ''
      });
      return
    }
    setformError({})
    let portDetails = {
      id,
      "port_country": data["portCountry" + id],
      "port_name": data["portName" + id].toLowerCase().replace(/\s/g, ''),
      "port_pretty_name": data["portName" + id]
    }
    setSelectedPort(selectedPort.concat(portDetails))
    toastDisplay("Port added successfully", "success")
    // console.log("selectedPort", selectedPort);
  }

  let deletePort = (id) => {
    setSelectedPort(selectedPort.filter(data => { if (data.id != id) { return data } }))
    let temp = addMoreArr
    temp[id - 1] = null
    setaddMoreArr(temp)
    toastDisplay("Port removed successfully", "success")
  }

  const postPorts = () => {
    if (selectedPort.length > 0) {
      registrationData.payload.step9 = selectedPort;
      registrationData.payload.step9_other_data = {
        formError, data, addMoreArr
      }
      setRegistrationData(registrationData.type, 3, registrationData.payload);
    }
    else {
      toastDisplay("Form Validation Error", "warn")
    }
  }

  const handleBack = () => {
    let temp = selectedPortsArray
    registrationData.payload.step9 = temp;
    if (registrationData.type === 6) {
      //redirected to initial profile picker if user type is investigation agency
      setRegistrationData(registrationData.type, 2, registrationData.payload)
    }
  }

  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: (event.target.value).replace(/^\s?/, '') }));
    if (event.target.name.includes("portCountry")) {
      if (event.target.value) {
        getCountryPorts(event.target.value)
      }
      else {
        setPortNames([])
      }
    }
  }

  const getCountryPorts = (country_name) => {
    setshowLoader(true)
    call('POST', 'getCountryPorts', {
      country_id: portCountries.filter(item => { if (item.name === country_name) { return item } })[0]["id"]
    }).then((result) => {
      setPortNames(result)
      setshowLoader(false)
    }).catch((e) => {
      // console.log("Error while querying getCountryPorts:", e);
      setshowLoader(false)
    })
  }

  function serviceLocationPicker() {
    const astrix = <span className="required-field text-danger">*</span>
    return (
      <>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row">
          <div className="col-md-12 mb-2">
            <h3 className="green border-bottom pb-2">Select Service Locations :
            </h3>
          </div>
          {addMoreArr.map((id) => {
            if (id) {
              return (
                <div className="container ml-2">
                  <div className='row'>
                    <div className='col-8'>
                      <label >Port Country {astrix} </label>
                      <div >
                        {selectedPort.filter(data => { if (data.id == id) { return data } }).length ? (
                          <input disabled
                            type="text" value={data["portCountry" + id]}
                            className={"form-control"}
                          />
                        ) :
                          (
                            <select
                              className={" form-control" + (!formError["portCountry" + id] ? '' : ' border-danger')} name={"portCountry" + id}
                              value={data["portCountry" + id]} onChange={handleChange}>
                              <option value="" selected>--Select Port Country--</option>
                              {
                                portCountries.length ? portCountries.map((item, index) => {
                                  return (<option value={item.name}>{item.name}</option>)
                                }) : ''
                              }
                            </select>
                          )}
                        {formError["portCountry" + id] ? <div class="text-danger error-contract">{formError["portCountry" + id]}</div> : ''}
                      </div>
                    </div>

                    <div className='col-4 align-self-end'>
                      {selectedPort.filter(data => { if (data.id == id) { return data } }).length ? (
                        <button type="button"
                          disabled={selectedPort.length == 1}
                          className="btn btn-primary btn-sm" onClick={() => deletePort(id)}>DELETE</button>
                      ) : (
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => addNewPort(id)}>SUBMIT</button>
                      )}
                    </div>

                    <div className='col-8 mb-4 mt-3'>
                      <label >Port Name {astrix} </label>
                      <div >
                        {selectedPort.filter(data => { if (data.id == id) { return data } }).length ? (
                          <input disabled
                            type="text" value={data["portName" + id]}
                            className={"form-control"}
                          />
                        ) : (
                          <select
                            className={" form-control" + (!formError["portName" + id] ? '' : ' border-danger')} name={"portName" + id}
                            value={data["portName" + id]} onChange={handleChange}>
                            <option value="" selected>--Select Port Name--</option>
                            {
                              portNames.length ? portNames.map((item, index) => {
                                return (<option value={item.name}>{item.name}</option>)
                              }) : ''
                            }
                          </select>
                        )}
                        {formError["portName" + id] ? <div class="text-danger error-contract">{formError["portName" + id]}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          }
          )}
          {selectedPort.length ? (
            <button type="button" className="btn btn-primary btn-sm mb-4"
              style={{ "margin-left": '45%' }}
              onClick={() => { setaddMoreArr(addMoreArr.concat(addMoreArr.length + 1)); setPortNames([]) }}>ADD MORE</button>
          ) : null}

          {!selectedPort.length ? <p className="text-danger w-100 text-center">{"Please select at least one port"}</p> : ""}
        </div >
      </>
    )
  }

  return (
    <div className="on-bording">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <ul className="next-button">
        <li onClick={handleBack}><button >{"<< Previous"}</button></li>
        <li onClick={postPorts}><button >{"Next >>"}</button></li>
      </ul>

      <div className="container-fluid">
        <div className="row">
          <Header title={'Commodities Information'} />
          <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc overflow-auto">
            {serviceLocationPicker()}
          </div>
        </div>
      </div>
    </div>
  )

}


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceLocationPicker)