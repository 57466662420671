import React, { useState } from "react";
import RateListCard from "./RateListCard";
import moment from 'moment';

const ShipRatesAccordian_Component = ({ userTypeId, accId, headerId, target, weekHeading, tarnsitTime, result, allData, data, isRateCalculated,
  getQuotation, condition }) => {

  const [accord, setaccord] = useState({
    accord_0: false,
    accord_1: false,
    accord_2: false
  });

  console.log('weeksheading => ', weekHeading);
  const List_Data = [
    // {
    //   name: "Origin Port", selector: "origin_port", sortable: true
    // }, //0
    // { name: "Destination Port", selector: "destination_port", sortable: true }, //1
    // { name: "Mode", selector: "mode", sortable: true }, //
    // { name: "Mode Type", selector: "mode_type", sortable: true }, //
    { name: "Company Name", selector: "company_name", sortable: true, condition: "00:01:10:11:12" }, //2
    { name: "Container Type", selector: "container_type", sortable: true, condition: "00" }, //2
    { name: "Container No", selector: "container_no", sortable: true, condition: "00" }, //3
    { name: "Cargo Type", selector: "cargo_type", sortable: true, condition: "11:12" }, //4
    // { name: "Start Date (yyyy-mm-dd)", selector: "start_date", sortable: true }, //5
    // { name: "End Date (yyyy-mm-dd)", selector: "end_date", sortable: true }, //6
    // { name: "Load Volume (cubic meter)", selector: "load_volume", sortable: true, condition: "01" }, //7
    // { name: "Load Weight (Kg)", selector: "load_weight", sortable: true, condition: "01" }, //8
    { name: "Vessel Type", selector: "vessel_type", sortable: true, condition: "10:11:12" }, //9
    { name: "Commodity Type", selector: "commodity_type", sortable: true, condition: "10" }, //10
    { name: "Flexibility Days", selector: "flexibility_days", sortable: true, condition: "11" }, //11
    { name: isRateCalculated ? "Price ($) (tentative)" : "Price ($)", selector: "price", sortable: true, condition: "00:01:10:11:12" }, //12
    { name: "Currency", selector: "currency", sortable: true, condition: "00:01:10:11:12" }, //13
    // { name: "Created At", selector: "created_at", sortable: true } //13
  ];

  const handleAccordion = (accordion) => {
    setaccord(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  return (
    <>
      {/* <div className="accordion accordion-flush" id={accId}> */}
      {/* <div className="accordion-item accordionitem mb-3"> */}
      {/* <button className="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" data-bs-target={`#${target}`}
          aria-expanded="false" aria-controls={target}> */}
      <div className={"accordionItem" + (accord["accord_1"] ? " open" : " closed")}>
        <h2 className="accordionItemHeading pl-3" onClick={() => handleAccordion('accord_1')}>{
          // "Shipping Rates List (" + result + ") Results"
          result + " Ship Rates Found"
        }</h2>
        {accord["accord_1"] && (
          <div className="accordionItemContent">
            {data.map((item, index) => (
              <RateListCard userTypeId={userTypeId}
                accChildid={"accordionFlushExample" + index} AccChildHeader={"flush-headingOne" + index}
                AccChildCollapse={"flush-collapseOne" + index}
                startDate={moment(item.start_date).format("YYYY-MM-DD")} StartLoc={item.origin_port}
                // NoOfDays={allData.tarnsitTime} 
                endDate={item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : "NA"}
                getQuotation={getQuotation}
                EndLoc={item.destination_port} List_Data={List_Data} AdditionalData={item} isRateCalculated={isRateCalculated}
                condition={condition} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ShipRatesAccordian_Component;