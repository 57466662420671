import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { validate_text_field } from '../../utils/myFunctions';
//import { getUserDataFromCookie } from '../../utils/cookieHelper';

// editLevel (true= editable, false=viewonly) ; userLevel (1->admin , 2->User)
const SpecificationsForm = ({ commodity, spec, handleSpecification, setSpecification, contMode, editCount, setEditCount, editLevel, masterid, specOrder, setspecOrder, commName }) => {

  let [dbSpecData, setdbSpecData] = useState({});
  const [newSpec, setnewSpec] = useState({ newSpecificationName: "", newSpecificationValue: "", newSpecificationrejectionValue: '' });
  // const [userEmail, setuserEmail] = useState('');
  // const [userTypeId, setuserTypeId] = useState('');
  // const [userId, setuserId] = useState('');

  console.log("commodity in spec form -->", commodity)

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (!commName) {
      call('POST', 'getcommoditydatabyid', { commId: commodity, masterid: masterid }).then((result) => {
        console.log("API result getcommoditydatabyid:-->", result);
        setdbSpecData(result.message[0]);
      }).catch((e) => {
        console.log("Error while querying getCommodityList:", e);
      })
    }

    //------------------------------------------------------------------

  }, [commodity]);

  console.log("spec in form component-->", spec)
  console.log("specOrder in form component-->", specOrder)


  //---------------------------------------------------------------------------------------------------------------------
  // Functions to add dynamic specification elements
  function addElement() {
    let newSpecObj = { "name": newSpec.newSpecificationName, "value": newSpec.newSpecificationValue, "rejectionValue": newSpec.newSpecificationrejectionValue, "rejectionSymbol": newSpec.newSpecificationrejectionSymbol }
    setSpecification({ ...spec, [newSpec.newSpecificationName.toLowerCase().replace(/\s/g, '')]: newSpecObj });
    setnewSpec(newSpec => ({
      ...newSpec,
      newSpecificationName: "",
      newSpecificationValue: "",
      newSpecificationrejectionSymbol: '',
      newSpecificationrejectionValue: ''
    }));

    if (contMode)
      setEditCount(editCount + 1)

    let tempOrderArray = [...specOrder]
    tempOrderArray.push(newSpec.newSpecificationName.toLowerCase().replace(/\s/g, ''))
    setspecOrder(tempOrderArray)

  }

  function removeElement(key) {
    let specdataElement = { ...spec }
    delete specdataElement[key]
    setSpecification(specdataElement)

    let tempOrderArray = [...specOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    setspecOrder(tempOrderArray)

  }


  const handlenewSpec = (event) => {
    event.persist();
    setnewSpec(newSpec => ({ ...newSpec, [event.target.name]: validate_text_field(event.target.value) }));
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Commodity Spec Components

  function createSpecComponet(specification) {
    let keyArray = specOrder.length ? specOrder : Object.keys(specification).length ? Object.keys(specification) : []
    return (
      <div className="modal-padding ">
        <h3 className="mt-4 mb-4">Specification ({commName ? commName : dbSpecData.commodity_pretty_name}) <span className="required-field text-danger">*</span> <span className="help-icon" flow="right" tooltip="Commodity Specifications">i</span></h3>
        <div className="row">
          {
            keyArray.length ? keyArray.map((key) => {
              return (
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="">{specification[key].name ? specification[key].name + (" - (Typical Value)") : '--'}</label>
                            <input type="text" className={"form-control " + (!masterid && !specification[key].value && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',value' : ''} value={specification[key].value} placeholder="" onChange={handleSpecification} disabled={editLevel ? false : true} />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="">Rejection At</label>
                            <select className={"form-control " + (!masterid && !specification[key].rejectionSymbol && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',rejectionSymbol' : ''} value={specification[key].rejectionSymbol} onChange={handleSpecification} disabled={editLevel ? false : true} >
                              <option value="" selected>-select-</option>
                              <option value="!">N/A</option>
                              <option value="<">Below</option>
                              <option value=">">Above</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="">Rejection Value</label>
                            <input type="text" className={"form-control " + (!masterid && !specification[key].rejectionValue && contMode === "edit" ? ' border-danger' : "")} name={key ? key + ',rejectionValue' : ''} value={specification[key].rejectionValue} placeholder="" onChange={handleSpecification} disabled={editLevel ? false : true} />
                          </div>
                        </div>
                        <div className="col-md-1">
                          <button type="button" className="delete-icon spec-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) : (
              <h3 className="ml-3">No Specification Added</h3>
            )
          }
        </div>

        <hr />

        <div className="row">
          <div className="col-md-12">
            <h3 className="col-md-12 pl-0 mt-2">ADD New Specification</h3>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="">Specification Name<span className="required-field text-danger">*</span></label>
                  <input type="text" value={newSpec.newSpecificationName} name="newSpecificationName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewSpec} />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label className="">Typical Value<span className="required-field text-danger">*</span></label>
                  <input type="text" value={newSpec.newSpecificationValue} name="newSpecificationValue" placeholder="Value" className="form-control" onChange={handlenewSpec} />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label className="">Rejection At{!masterid ? <span className="required-field text-danger"></span> : ""}</label>
                  <select className="form-control" name="newSpecificationrejectionSymbol" value={newSpec.newSpecificationrejectionSymbol} onChange={handlenewSpec} >
                    <option value="" selected>-select-</option>
                    <option value="!">N/A</option>
                    <option value="<">Below</option>
                    <option value=">">Above</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="">Rejection Value{!masterid ? <span className="required-field text-danger"></span> : ""}</label>
                  <input type="text" value={newSpec.newSpecificationrejectionValue} name="newSpecificationrejectionValue" placeholder="Rejection Value" className="form-control" onChange={handlenewSpec} />
                </div>
              </div>
              <div className="col-md-2">
                {(newSpec.newSpecificationName !== "" && newSpec.newSpecificationValue !== "") && <button className="btn-primary btn btn-sm spec-delete" onClick={() => addElement()} >Add +</button>}
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>

      {createSpecComponet(spec)}

    </>)
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecificationsForm)
