import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';


const AssignVesselModal = ({ userTokenDetails, assignVessel, setassignVessel, setrefresh, refresh }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({
    contract_no: assignVessel.data.contract_no,
    vesselMaster: assignVessel.data.vessel_master_id ? assignVessel.data.vessel_master_id : "",
    carrierAgent: assignVessel.data.carrier_agent_id ? assignVessel.data.carrier_agent_id : "",
    vesselIMO: assignVessel.data.ship_imo_no ? assignVessel.data.ship_imo_no : "",
  });
  const [showLoader, setshowLoader] = useState(false);
  const [userShips, setuserShips] = useState([]);
  const [selectedShip, setselectedShip] = useState({});
  const [vesselMasters, setvesselMasters] = useState([]);
  const [carrierAgents, setcarrierAgents] = useState([]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('get', 'getuserships').then((result) => {
      console.log("user Ships=>", result)
      setuserShips(result)

      for (let i = 0; i < result.length; i++) {
        if (result[i].imo_no / 1 === assignVessel.data.ship_imo_no / 1) {
          setselectedShip(result[i])
        }
      }
      setshowLoader(false)
    }).catch((e) => {
      console.log('error in getuserships', e);
    })

    call('POST', 'getUsersListing', { "searchUserType": 7, "searchKey": '', "network": "innetwork", "userAvatars": false }).then((result) => {
      console.log("result of api in getUsersListing vesselmaster-->", result)
      setvesselMasters(result)
    }).catch((e) => {
      console.log('error in getUsersListing vesselmaster', e);
    })

    call('POST', 'getUsersListing', { "searchUserType": 18, "searchKey": '', "network": "innetwork", "userAvatars": false }).then((result) => {
      console.log("result of api in getUsersListing carrier agents-->", result)
      setcarrierAgents(result)
    }).catch((e) => {
      console.log('error in getUsersListing carrier agents', e);
    })
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleShipSelect = (event) => {
    event.persist();
    console.log("event.target.value=>", event.target.value)
    let tempdata = JSON.parse(event.target.value)
    setData(data => ({ ...data, ["vesselIMO"]: tempdata.imo_no }));
    setselectedShip(tempdata)
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  console.log("assignVessel=>", assignVessel)
  console.log("data=>", data)
  console.log("user selectedShip=>", selectedShip)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function saveVesselInfo() {

    setshowLoader(true)

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });


    call('POST', 'updatevesselincontract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setassignVessel({ modal: false, data: {} })
        toastDisplay("Vessel and Users added Succesfully", "success")
        setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {assignVessel.modal && <div className={"modal" + (assignVessel.modal ? " show" : "")} id="shipmodal">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Assign Vessel for Contract {assignVessel.data.contract_no}</h4>
              <button type="button" className="close" onClick={() => setassignVessel({ modal: false, data: {} })}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="modal-padding">
                <ul className="price-ul">
                  <li>
                    <div className="price-left">{assignVessel.data.contract_no}</div>
                    <div className="price-right">Contract</div>
                  </li>
                  <li>
                    <div className="price-left">{assignVessel.data.charterer}</div>
                    <div className="price-right">Charterer </div>
                  </li>
                  <li>
                    <div className="price-left">{assignVessel.data.shipOwner}</div>
                    <div className="price-right">Ship Owner</div>
                  </li>
                  <li>
                    <div className="price-left">{assignVessel.data.loadingPort}  -  {assignVessel.data.unloadingPort}</div>
                    <div className="price-right">Loading Port - Unloading Port</div>
                  </li>
                </ul>
              </div>
              {data.vesselIMO &&
                <div className="modal-padding mt-2">
                  <ul className="price-ul">
                    <li>
                      <div className="price-left">{selectedShip.imo_no}</div>
                      <div className="price-right">Vessel IMO No</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.ship_name}</div>
                      <div className="price-right">Vessel Name</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.type}</div>
                      <div className="price-right">Vessel Type</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.category}</div>
                      <div className="price-right">Vessel Category</div>
                    </li>

                    <li>
                      <div className="price-left">{selectedShip.registry_port}</div>
                      <div className="price-right">Registry Port</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.lengthv}</div>
                      <div className="price-right">Vessel Length</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.breadthv}</div>
                      <div className="price-right">Vessel Breadth</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.depth}</div>
                      <div className="price-right">Vessel Depth</div>
                    </li>

                    <li>
                      <div className="price-left">{selectedShip.dead_weight}</div>
                      <div className="price-right">Vessel Dead Weight</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.gross}</div>
                      <div className="price-right">Vessel Gross</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.net}</div>
                      <div className="price-right">Vessel Net</div>
                    </li>
                    <li>
                      <div className="price-left">{selectedShip.date_manufacture}</div>
                      <div className="price-right">Vessel DOM</div>
                    </li>


                  </ul>
                </div>}

              <div className="modal-padding mt-4">
                <div className="col-md-12 row">

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Select Vessel</label>
                      <div className="col-md-12">
                        <select className="form-control" name="vesselIMO" value={JSON.stringify(selectedShip)} onChange={handleShipSelect}>
                          {(userShips && userShips.length) ? <option value="" selected>--Select Vessel--</option> : ""}
                          {(userShips && userShips.length) ? userShips.map((ship) => {
                            return (
                              <option value={JSON.stringify(ship)} >{ship.ship_name}</option>
                            )
                          }) : <option value="" selected>No Vessel Found</option>}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Select Vessel Master</label>
                      <div className="col-md-12">
                        <select className="form-control" name="vesselMaster" value={data.vesselMaster} onChange={handleChange}>
                          {(vesselMasters && vesselMasters.length) ? <option value="" selected>--Select Vessel Master--</option> : ""}
                          {(vesselMasters && vesselMasters.length) ? vesselMasters.map((vesselMaster) => {
                            return (
                              <option value={vesselMaster.id} >{vesselMaster.company_name}</option>
                            )
                          }) : <option value="" selected>No Vessel Master Found</option>}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Select Carrier Agent</label>
                      <div className="col-md-12">
                        <select className="form-control" name="carrierAgent" value={data.carrierAgent} onChange={handleChange}>
                          {(carrierAgents && carrierAgents.length) ? <option value="" selected>--Select Carrier Agent--</option> : ""}
                          {(carrierAgents && carrierAgents.length) ? carrierAgents.map((carrierAgent) => {
                            return (
                              <option value={carrierAgent.id} >{carrierAgent.company_name}</option>
                            )
                          }) : <option value="" selected>No Carrier Agent Found</option>}
                        </select>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => setassignVessel({ modal: false, data: {} })}>Cancel</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => saveVesselInfo()}>Update</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------

export default AssignVesselModal
