export default function validate(values) {
    let errors = {};
  
  
    if (!values.shippmentType) {
    errors.shippmentType = 'Select Shippment Type';
    }
    if (!values.containerType) {
    errors.containerType = 'Select Container Type';
    }
    if (!values.loadingCountry) {
    errors.loadingCountry = 'Select Loading Country';
    }
    if (!values.loadingPort) {
    errors.loadingPort = 'Select Loading Port';
    }
    if (!values.dischargeCountry) {
    errors.dischargeCountry = 'Select Discharge Country';
    }
    if (!values.dischargePort) {
    errors.dischargePort = 'Select Discharge Port';
    }
    if (!values.commodityType) {
    errors.commodityType = 'Enter Commodity Type';
    }
    if (!values.quantity) {
        errors.quantity = 'Enter Quantity';
    }
    if (!values.weight) {
        errors.weight = 'Enter Weight';
    }
    if (!values.shippmentHazardous) {
        errors.shippmentHazardous = 'Select Shippment Hazardous';
    }

    console.log(errors);
    return errors;
  }
  