import React, { useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
// import { setInvoiceDetail } from '../../store/actions/action';
import LaytimeSheet from './laytimeSheet';
import Footer from '../partial/footer';


const Laytime = ({ userTokenDetails, navToggleState, dispatch }) => {

  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Loading Laytime Sheet");

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  console.log("insdie the roght component")

  return (
    // <>
    <>
      <Sidebar state={'shipment'} userTokenDetails={userTokenDetails} />
      <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

        <Header
          title={tabName}
          userTokenDetails={userTokenDetails} />

        <ul className="nav nav-tabs-custom mt-2" id="myTab" role="tablist">
          {(aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
                setTabName("Loading Laytime Sheet");
              }}>Loading</a>
            </li>}
          {(aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
                setTabName("Unloading Laytime Sheet");
              }}>Unloading</a>
            </li>}
        </ul>

        {tab === 0 &&
          <div className="tab-pane active show col-md-12">
            {(aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
              <LaytimeSheet
                userTokenDetails={userTokenDetails}
                laytimeType={1} />}
          </div>}
        {tab === 1 &&
          <div className="tab-pane active show col-md-12">
            {(aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
              <LaytimeSheet
                userTokenDetails={userTokenDetails}
                laytimeType={2} />}
          </div>}

      </main>
      {/* footer start */}
      {/* <Footer /> */}
      {/* footer end */}
      {/* </> */}
    </>
  )
};


const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Laytime)
