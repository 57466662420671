import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

const AclOTP = ({ setOTP, fileData }) => {

  const [data, setData] = useState({});

  console.log('oootttttttppppppppppppp ', fileData);

  useEffect(() => {

    call("POST", "sendOTPMail", fileData).then((result) => {
      console.log('result in sendOTPMail===> ', result);
    }).catch((e) => {
      console.log("error in sendOTPMail", e);
    });

    setData({ ...data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  // Apply Loan Form
  function validateUser() {
    let object = {
      OTP: data.OTP,
      tbl_doc_id: fileData.tbl_doc_id
    }
    console.log('==== Login data ====', object);
    call('post', 'matchEnteredOTP', object).then((result) => {
      console.log('==== Login result ====', result);
      if (result.validated == true) {
        toastDisplay(result.msg, "success");
        setOTP({ state: false, validated: result.validated });
        // Disabled Auth after a defined time
        setTimeout(function () { setOTP({ state: false, validated: false }) }, 60000);
      } else {
        toastDisplay(result.msg, "error")
      }
    }).catch((e) => {
      console.log('error in matchEnteredOTP', e);
      toastDisplay(e.msg, "error")
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  // JSX return
  return (
    <div className="modal show" id="CreateContract">
      <div className={"modal-dialog modal-xs border-inner "} id="parent_class">
        <form noValidate>
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">One Time Password (OTP)</h4>
              <button type="button" className="close" onClick={() => setOTP({ state: false, validated: false })}>×</button>
            </div>
            <div className="modal-body">

              <div className="col-md-12">
                <div className="row form-group">
                  <h6>One Time Password (OTP) has been generated and sent on your registered email. Please go and check.</h6>
                </div>

                <div className="row form-group">
                  <div className="col-md-4 form-group">
                    <label>Enter OTP</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input className="form-control" type="number" name="OTP" onChange={handleChange} required />
                  </div>

                </div>
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-info btn-sm" onClick={() => validateUser()} >Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AclOTP;
