import React, { useState } from 'react';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Plans from './plans.js';
import Footer from '../partial/footer';
import { connect } from 'react-redux';


const BuyPlans = ({ userTokenDetails, navToggleState, dispatch }) => {

  const [planName, setplanName] = useState("Subscribtion Plans")


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'plans'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={planName}
              total={''}
              userTokenDetails={userTokenDetails} />

            <Plans
              userTokenDetails={userTokenDetails}
              setplanName={setplanName} />

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
};



const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyPlans)
