import React, { useEffect, useState } from 'react';
import call from "../../../service";
import { connect } from "react-redux";
import { setFinanceDetails } from '../../../store/actions/action';
import toastDisplay from '../../../utils/toastNotification';

const ChooseFinance = ({ userTokenDetails, setFinanceDetails, financeDetails, dispatch }) => {


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [userHaveCreditLine, toggleUserHaveCreditLine] = useState(false);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    //------------------------------------------------------------------
    call('get', 'v1/plan/quota').then((result) => {
      setUserPlanQuota(result)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota ChooseFinance:-->", e);
    })

    call('POST', 'getCreditLineByType', {
      "currentPage": 1,
      "resultPerPage": 10,
      "manualUserId": userId,
      "userTypeId": 19,
      "userId": userId,
      "gridType": "userapproved"
    }).then((result) => {
      if (result.finData && result.finData.length) {
        toggleUserHaveCreditLine(true)
      }
    }).catch((e) => {
      console.log("Error in getCreditLineByType", e);
    })

    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Functions
  function enableFinanceForm(status, type, page, inf0) {
    if (userPlanQuota.finance && userPlanQuota.finance.enable && ((userPlanQuota.finance.limitLeft / 1) > 0 || (userPlanQuota.finance.limitLeft / 1) === -1)) {
      setFinanceDetails(status, type, page, inf0)
    } else {
      if (userPlanQuota.trades)
        toastDisplay("You have already used " + userPlanQuota.finance.limitUsed + " out of " + userPlanQuota.finance.totalLimit + userPlanQuota.finance.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // JSX return
  return (
    <>
      <div className="col-md-12 row p-0">
        <div className="col-md-12 mb-2 text-center">
          <h3 className="green border-bottom pb-2">Apply Finance Against</h3>
        </div>
        <div className="col-md-12 our-webcoderskull text-center">
          <div id="SelectName" className="mb-4">
          </div>
          <ul className="row" id="">
            {(userTypeId !== 19 && userTypeId !== 5) &&
              <li className={`col-md-3 ${financeDetails.type / 1 === 1 ? 'active' : ''} `} true >
                <div className="cnt-block equal-hight">
                  <figure onClick={() => enableFinanceForm(financeDetails.status, 1, 1, { action: "applyFinance", type: "PO" })}>
                    <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true"></i>
                  </figure>
                  <h3>Purchase Order (PO)</h3>
                </div>
              </li>
            }
            {(userTypeId !== 5) &&
              <li className={`col-md-3 ${financeDetails.type / 1 === 2 ? 'active' : ''} `} true >
                <div className="cnt-block equal-hight">
                  <figure onClick={() => enableFinanceForm(financeDetails.status, 2, 1, { action: "applyFinance", type: "LC" })}>
                    <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true"></i>
                  </figure>
                  <h3>Letter of Credit (LC)</h3>
                </div>
              </li>}
            {(userTypeId !== 5) &&
              <li className={`col-md-3 ${financeDetails.type / 1 === 3 ? 'active' : ''} `} true >
                <div className="cnt-block equal-hight">
                  <figure onClick={() => enableFinanceForm(financeDetails.status, 3, 1, { action: "applyFinance", type: "INVOICE" })}>
                    <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true">
                      <h6> Trader Finance </h6>
                    </i>
                  </figure>
                  <h3>Invoice Discounting</h3>
                </div>
              </li>}
            {([3, 4, 14, 19].includes(userTypeId)) && <li className={`col-md-3 ${financeDetails.type / 1 === 4 ? 'active' : ''} `} true >
              <div className="cnt-block equal-hight">
                {/* <figure > */}
                <figure onClick={() => setFinanceDetails(financeDetails.status, 4, 1, { action: "applyFinance", type: "MYTRADE" })}>
                  <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true"></i>
                </figure>
                <h3>My Trade (Offline) </h3>
              </div>
            </li>}
            {([3, 4, 14].includes(userTypeId)) && <li className={`col-md-3 ${financeDetails.type / 1 === 5 ? 'active' : ''} `} true >
              <div className="cnt-block equal-hight">
                {/* <figure > */}
                <figure onClick={() => setFinanceDetails(financeDetails.status, 5, 1, { action: "applyFinance", type: "MYTRADE" })}>
                  <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true"></i>
                </figure>
                <h3>My Trade (Online)</h3>
              </div>
            </li>}
            {userHaveCreditLine &&
              <li className={`col-md-3 ${financeDetails.type / 1 === 6 ? 'active' : ''} `} true >
                <div className="cnt-block equal-hight">
                  <figure onClick={() => enableFinanceForm(financeDetails.status, 6, 1, { action: "applyFinance", type: "FREIGHT" })}>
                    <i class="fa fa-university fa-3x text-primary pt-3" aria-hidden="true"></i>
                  </figure>
                  <h3>Freight Finance</h3>
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    </>
  );
};


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseFinance)
