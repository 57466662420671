

import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

const InsuranceRemark = ({ userTokenDetails, setshowInsuranceRemark, showInsuranceRemark, setrefresh, refresh }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [insurers, setInsurers] = useState([]);
  const [data, setData] = useState({ commodityUser: [], tradeUser: [] });
  const [showLoader, setshowLoader] = useState(false);
  const contractData = showInsuranceRemark.contractData;

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true);
    call('POST', 'getinsuranceagencylist', { traderId: (userTypeId === 14 ? userId : null) }).then(async (result) => {
      setshowLoader(false);
      setInsurers(result)
    }).catch((error) => {
      console.log("error occur in getinsuranceagencylist ->", error)
    })
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  }

  const handleCheckbox = (event) => {
    event.persist();
    let tempArray = data[event.target.name] ? [...data[event.target.name]] : []
    let selectedUser = event.target.value / 1
    let index = tempArray.indexOf(selectedUser)
    if (index !== -1) {
      tempArray = tempArray.slice(0, index)
    } else {
      tempArray.push(selectedUser / 1)
    }
    setData(data => ({ ...data, [event.target.name]: tempArray }));
  }

  const handleCheckAllCommInsurance = (event) => {
    event.persist();
    let tempArray = [...data.commodityUser]
    for (let i = 0; i < insurers.length; i++) {
      if (!tempArray.includes(insurers[i])) {
        tempArray.push(insurers[i].tbl_user_id)
      }
    }
    setData(data => ({ ...data, commodityUser: tempArray }));
  }

  const handleCheckAllTradeInsurance = (event) => {
    event.persist();
    let tempArray = [...data.tradeUser]
    for (let i = 0; i < insurers.length; i++) {
      if (!tempArray.includes(insurers[i])) {
        tempArray.push(insurers[i].tbl_user_id)
      }
    }
    setData(data => ({ ...data, tradeUser: tempArray }));
  }

  //---------------------------------------------------------------------------------------------------------------------

  console.log("data in request =>", data)
  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmitQuoat() {

    let formData = new FormData();

    formData.append("sendToCommodityUsers", data.commodityUser);
    formData.append("sendToTradeUsers", data.tradeUser);
    formData.append("contractId", showInsuranceRemark.contract_id);
    formData.append("contractNo", showInsuranceRemark.contract_no);

    setshowLoader(true)
    call('POST', 'raiseInsuranceQuotation', formData).then((result) => {
      setrefresh(refresh + 1)
      setshowLoader(false)
      setshowInsuranceRemark({ modal: false })
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while raising quotation request:", e);
      toastDisplay(e, "error")
    })

  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {showInsuranceRemark.modal && <div className={"modal" + (showInsuranceRemark.modal ? " show" : "")} id="DocumentUpload">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Send Investigation Quotation</h4>
              <button type="button" className="close" onClick={() => { setshowInsuranceRemark(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

              <div className=" col-md-12 row" >

                <div className="col-md-12">
                  <ul className="price-ul">
                    <li>
                      <div className="price-left">{contractData.contract_name}</div>
                      <div className="price-right">Contract </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.buyername}</div>
                      <div className="price-right">Importer </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.suppliername}</div>
                      <div className="price-right">Exporter </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.commodity_name}</div>
                      <div className="price-right">Commodity </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.quantity}</div>
                      <div className="price-right">Quantity </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.laycan_start}</div>
                      <div className="price-right">Laycan Start </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.laycan_end}</div>
                      <div className="price-right">Laycan End </div>
                    </li>
                    <li>
                      <div className="price-left">{contractData.created_at}</div>
                      <div className="price-right">Created at </div>
                    </li>
                  </ul>
                </div>

                <div className=" col-md-12 row mt-4">
                  <div className=" col-md-4">
                    <label >Commodity Insurance by</label>
                    {(insurers.length > 1) &&
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="checkAll" name="commodityCheckAll" id="commodityCheckAll" onChange={handleCheckAllCommInsurance} />
                        <label className="form-check-label" htmlFor="commodityCheckAll">"Check All"</label>
                      </div>
                    }
                    {insurers && insurers.length ? insurers.map((user, index) => {
                      return (
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value={user.tbl_user_id} name="commodityUser" id={"commodityUser" + index} onChange={handleCheckbox} checked={data.commodityUser.includes(user.tbl_user_id)} />
                          <label className="form-check-label" htmlFor={"commodityUser" + index}>{user.company_name}</label>
                        </div>
                      )
                    }) : ""
                    }
                  </div>
                  <div className=" col-md-4">
                    <label >Trade Insurance by</label>
                    {(insurers.length > 1) &&
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="checkAll" name="tradeCheckall" id="tradeCheckall" onChange={handleCheckAllTradeInsurance} />
                        <label className="form-check-label" htmlFor="tradeCheckall">"Check All"</label>
                      </div>
                    }
                    {insurers && insurers.length ? insurers.map((user, index) => {
                      return (
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value={user.tbl_user_id} name="tradeUser" id={"tradeUser" + index} onChange={handleCheckbox} checked={data.tradeUser.includes(user.tbl_user_id)} />
                          <label className="form-check-label" htmlFor={"tradeUser" + index}>{user.company_name}</label>
                        </div>
                      )
                    }) : ""
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => handleSubmitQuoat()} >Submit</button>
            </div>
          </div>
        </div>
      </div >}
    </>)
}


export default InsuranceRemark