import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import ReactCountryFlag from "react-country-flag";
import avatarUrl from '../../utils/makeAvatarUrl';
import columns from '../dataTablesColumsMap/accountListColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { setChannelPartnerAccountList } from '../../store/actions/action';
import FinanceDetailGrid from '../userProfile/financeDetailGrid';

const AccountList = ({ userTokenDetails, navToggleState, dispatch, channelPartnerAccountList, setChannelPartnerAccountList }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [channelPartnerUsers, setChannelPartnerUsers] = useState([]);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    call('POST', 'getChannelPartnerUsers', { "userId": userId }).then((result) => {
      console.log('running getChannelPartnerUsers api-->', result);
      setChannelPartnerUsers(result.userData);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
    })

  }, []);

  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerUsers', objectAPI).then((result) => {
      console.log('running getChannelPartnerUsers api-->', result);
      settableData(formatDataFortable(result.userData));
      setTotalRows(result.userData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getChannelPartnerUsers', objectAPI).then((result) => {
      settableData(formatDataFortable(result.userData));
      setTotalRows(result.userData.length);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getChannelPartnerUsers', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.user_avatar = <img src={avatarUrl(dataArray[i].user_avatar ? dataArray[i].user_avatar : '--')} alt="Avatar" width="32" height="32" />;
      tempObj.company_name = (dataArray[i].company_name ? dataArray[i].company_name : 'NA');
      tempObj.contact_person = dataArray[i].contact_person;
      tempObj.no_of_buyers = (dataArray[i].no_of_buyers);
      tempObj.user_address = dataArray[i].user_address;
      tempObj.country_code = (<span className="shadow">
        <ReactCountryFlag
          countryCode={dataArray[i].country_code ? dataArray[i].country_code : ''}
          style={{ width: '25px', height: '25px' }} svg />
      </span>);
      tempObj.action = <button className="btn btn-success btn-sm" onClick={() => { setChannelPartnerAccountList(true, { "userId": dataArray[i].tbl_user_id, "userName": dataArray[i].company_name, "userEmail": dataArray[i].email_id }) }}>
        <i className={"fas fa-folder-open"}></i>
      </button>

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({ supplierType: "", buyerType: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions


  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      // "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerReport', objectAPI).then((result) => {
      console.log('running getBuyerReport api in csv-->', result);

      const dbResult = result.reportData
      let csvString = "Lead By,Date of Establishment,Supplier Name,Industry Type,Buyer Name,DUNS No,Country,Country Name,Terms of Payment,Product Details,Previous Annual Sale,Expected Sale,Stenn,Remark,Modifi,Remark,Tradewind,Remark,Created At\r\n"


      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `User_Report.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((e) => {
      console.log('error in getLoans', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'accountlist'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={channelPartnerAccountList.modal ? `Financial, Credit Line & KYC Details (${channelPartnerAccountList.info.userName})` : "Account List"}
              userTokenDetails={userTokenDetails} />

            {channelPartnerAccountList.modal ?
              <FinanceDetailGrid userTokenDetails={userTokenDetails} manualUserId={channelPartnerAccountList.info.userId} manualUserName={channelPartnerAccountList.info.userName} manualUserEmail={channelPartnerAccountList.info.userEmail} />
              :
              <>
                <div class="d-md-flex mt-3 mb-3">
                  <div class="ml-md-auto">
                    <ul class="range-slider">
                      <li>
                        <select className="form-control" name="supplierType" value={filterData.supplierType} onChange={(event) => { setFilterData({ supplierType: event.target.value }) }}>
                          <option value="" selected>--Select Exporter--</option>
                          {channelPartnerUsers && channelPartnerUsers.length > 0 && channelPartnerUsers.map((supplier) => {
                            return (<option value={supplier.tbl_user_id + ':' + supplier.company_name}>{supplier.company_name}</option>)
                          })}
                        </select>
                      </li>
                      <li></li>
                      <li></li>
                      <li>
                        <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
                {/* <div className="float-left col-md-6 ">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div> */}
              </>

            }

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
    channelPartnerAccountList: state.channelPartnerAccountList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerAccountList: (modal, info) => { dispatch(setChannelPartnerAccountList({ modal: modal, info: info })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountList)