export default function validate(values, file, financeDetails, userTypeId, tab) {
  let errors = {};

  if (!values.buyerId) {
    errors.buyerId = 'Select a Buyer';
  }

  if (!values.invoiceRefrenceNo) {
    errors.invoiceRefrenceNo = 'Enter Invoice Number';
  }

  if (!values.invoiceIssueDate) {
    errors.invoiceIssueDate = 'Enter Invoice issue date';
  }

  if (!values.currency) {
    errors.currency = 'Select a Currency';
  }

  if (!values.contractAmount) {
    errors.contractAmount = 'Enter Contract Amount';
  }

  if (!values.reqAmount) {
    errors.reqAmount = 'Enter Requested Amount';
  }

  // if (!values.fintnc && userTypeId === 8) {
  //   errors.fintnc = 'Enter Fin T&C';
  // }

  if (!values.poRefrenceNo) {
    errors.poRefrenceNo = 'Enter a PO number';
  }

  if (!values.bankListId) {
    errors.bankListId = 'Select a bank';
  }

  if (!file.invoiceDocument && financeDetails.info.action === "applyFinance" && tab === 1) {
    errors.invoiceDocument = 'Upload the Invoice Copy';
  }

  if (!values.interestRate && userTypeId === 8) {
    errors.interestRate = 'Enter Interest Rate';
  }

  if (!values.creditDays) {
    errors.creditDays = 'Enter credit days';
  }

  if (!values.lenderId) {
    errors.lenderId = 'Select lender';
  }

  if (!values.invoiceDueDate) {
    errors.invoiceDueDate = 'Enter Due Date';
  }

  console.log("error in finance form==>", errors)

  return errors;
}