import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setInvoiceDetail } from '../../store/actions/action';
import { formatDate_Application } from '../../utils/dateFormaters';
import call from '../../service';
import ReactCountryFlag from "react-country-flag";
import SpecificationsPreview from '../specifications/specificationPreview'
import toastDisplay from '../../utils/toastNotification';
import price_in_words from '../../utils/wordtoNumber'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Accordion, Card } from 'react-bootstrap'
import AclLogin from '../utilComponents/aclLogin';
import PriceAdjustmentComp from './priceAdjustmentComp.js';
import avatarUrl from '../../utils/makeAvatarUrl';


const InvoiceGen = ({ userTokenDetails, planData, setInvoiceDetail, InvoiceDetail, clientType, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const contractNo = InvoiceDetail.info.contractNo
  const operationType = InvoiceDetail.info.operation
  const invoiceType = InvoiceDetail.info.invoiceType
  const [dbData, setDbData] = useState({})
  const [specData, setspecData] = useState({})
  const [reportDataDB, setreportDataDB] = useState([])

  const [values, setvalues] = useState({ contract_number: contractNo, userId: InvoiceDetail.info.userId, "invoiceType": "contract" })
  const [showLoader, setshowLoader] = useState(false);
  const [agreeInvoiceGen, setagreeInvoiceGen] = useState(false);
  const [addDem_Disp, setaddDem_Disp] = useState(false);
  const [accorddata, setaccorddata] = useState({ 1: true });
  const [previewInvoice, setpreviewInvoice] = useState(false);
  const [fileData, setfileData] = useState({});
  const [adjustPrice, setadjustPrice] = useState([]);
  const [login, setLogin] = useState({
    state: false,
    validated: false
  });

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractdetails', { "contract_number": contractNo, "spec": true, "invoice": true }).then(async (result) => {
      console.log("getcontractdetails in gen invoice ->", result)
      setspecData(result.constractDetails[0].specification)
      delete result.constractDetails[0].specification
      setDbData({ ...result.constractDetails[0], ...result.extraData })
      setshowLoader(false)
    }).catch((error) => {
      console.log("error occur in getcontractdetails ->", error)
    })

    call('POST', 'getcontractinvreport', { "contract_number": contractNo, "userTypeId": userTypeId, "userId": userId }).then((result) => {
      console.log("result in getcontractinvreport api2->", result)
      setreportDataDB(result)
      // setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying getcontractinvreport:", e);
    })

    if (operationType === "ReGenerate") {
      call('POST', 'getinvoiceforcontract', { "contract_number": contractNo, "invoiceType": invoiceType }).then(async (result) => {
        console.log("get invoice details in gen invoice ->", result)
        setfileData(result.fileData)
      }).catch((error) => {
        console.log("error occur in getcontractdetails ->", error)
      })
    }

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleFile = (event) => {
    event.persist();
    setvalues(values => ({ ...values, [event.target.name]: event.target.files[0] }));

  }

  const handleAccordion = (accordion) => {
    console.log("accordion-->", accordion)
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }


  function reportshowBody(contractSpec, specification) {
    let keyArray = Object.keys(specification).length ? Object.keys(specification) : []

    const Operators = {
      '>': function (a, b) { return a > b },
      '<': function (a, b) { return a < b },
    };

    return (
      <div className="col-md-12">

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Properties</th>
              <th scope="col">Contract Value</th>
              <th scope="col">Rejection Value</th>
              <th scope="col">Report Value</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {keyArray.map((key) => {
              return (
                <tr>
                  <td>
                    {contractSpec[key].name ? contractSpec[key].name : '--'}
                  </td>
                  <td>
                    {contractSpec[key].value ? contractSpec[key].value : '--'}
                  </td>
                  <td>
                    {contractSpec[key].rejectionValue ? contractSpec[key].rejectionValue : '--'}
                  </td>
                  <td>
                    {specification[key].value ? specification[key].value : '--'}
                  </td>
                  <td className="text-center">
                    {(key === "type" ? (contractSpec[key].value === specification[key].value ?
                      <i className="fa fa-check text-success" aria-hidden="true"></i> :
                      <i className="fa fa-times text-danger" aria-hidden="true"></i>) :
                      contractSpec[key].rejectionSymbol && Operators[contractSpec[key].rejectionSymbol](contractSpec[key].rejectionValue / 1, specification[key].value / 1) ?
                        <i className="fa fa-check text-success" aria-hidden="true"></i> :
                        <i className="fa fa-times text-danger" aria-hidden="true"></i>)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>)
  }


  function printDiv(divName) {

    let printContents = document.getElementById(divName)
    window.scrollTo(0, 0);
    let w = printContents.clientWidth;
    let h = printContents.clientHeight;


    html2canvas(printContents, {
      width: w,
      height: h,
      scrollX: 0,
      scrollY: -window.scrollY,
      allowTaint: true,
      useCORS: true
      // foreignObjectRendering: true
    }).then((canvas) => {
      // canvas.crossOrigin = 'Anonymous';
      // window.open().document.write('<img src="' + canvas.toDataURL() + '" />');
      setshowLoader(true)
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        compress: true
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      let invoiceFile = new File([pdf.output('blob')], "InvoiceDoc.pdf", {
        type: pdf.output('blob').type,
        lastModified: pdf.output('blob').lastModified,
      });

      if (operationType === "Generate") {
        handleInvoiceUpload(invoiceFile)
      }
      else if (operationType === "ReGenerate") {
        handleInvoiceUpdate(invoiceFile)
      }

    });
  }


  const handleInvoiceUpload = (generatedInvoiceBlob) => {
    setshowLoader(true)
    let formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    })
    if (generatedInvoiceBlob) { formData.append("invoiceDoc", generatedInvoiceBlob); }

    call('POST', 'uploadinvoice', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Operation Success", "success")
        setInvoiceDetail(false, { "refresh": true })
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Error Occured!", "error")
    })

  }

  const handleInvoiceUpdate = (generatedInvoiceBlob) => {
    setshowLoader(true)

    console.log("inside regen function old file info-->", fileData)
    console.log("inside regen function new file-->", generatedInvoiceBlob)

    let payloadUpload = {
      uploadingUser: userId,
      userTypeName: 'seller',
      id: fileData.relId,
      tbl_doc_id: fileData.id,
      contract_id: contractNo,
      linked_to: 0,
      isUpdateQuery: true,
      prevFileHash: fileData.file_hash,
      categoryId: fileData.category,
      filePayload: {
        docNo: fileData.doc_no,
        docName: fileData.doc_name,
        genDocLabel: fileData.gen_doc_label,
        docType: fileData.tbl_mst_doc_id,
        validUpto: ""
      }
    }

    payloadUpload = JSON.stringify(payloadUpload);
    let formData = new FormData();
    formData.append('payloadUpload', payloadUpload);
    formData.append('doc', generatedInvoiceBlob);

    call('POST', 'updateDoc', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setInvoiceDetail(false, { "refresh": true })
        toastDisplay("Invoice Re-generation Done", "success");
      }
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err.message, "error");
    })

  }

  const operators = {
    '+': function (a, b) { return a + b },
    '-': function (a, b) { return a - b },
  };

  function getInvoiceTotalAmount() {

    let totalAmount = (dbData.price / 1) * (dbData.quantity / 1)
    if (addDem_Disp) {
      if (dbData.final_demurage) {
        totalAmount = operators["+"]((totalAmount / 1), (dbData.final_demurage / 1))
      } else {
        totalAmount = operators["-"]((totalAmount / 1), (dbData.final_dispatch / 1))
      }
    }

    if (adjustPrice.length) {
      for (let i = 0; i < adjustPrice.length; i++) {
        totalAmount = operators[adjustPrice[i].operator]((totalAmount / 1), (adjustPrice[i].value / 1))
      }
    }
    return totalAmount
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // console.log("adjustPrice==>", adjustPrice)
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  return (
    <>
      {showLoader && <div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>}
      {login.state && <AclLogin setLogin={setLogin} />}
      {!previewInvoice ?
        <div className="col-md-12 row mt-2">
          <div className="col-md-12">
            <h2 className="text-primary">Contract Info ({contractNo})</h2>
            <hr className="p-1" />
            <div className="d-flex align-content-around flex-wrap mb-3 contract-details">
              <div className="col-md-12 row mt-2">
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Importer</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.buyeravatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="text-primary">  {dbData.buyername ? dbData.buyername : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.buyerAddress ? dbData.buyerAddress : '--'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Exporter</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.supplieravatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="text-primary">  {dbData.suppliername ? dbData.suppliername : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.supplierAddress ? dbData.supplierAddress : '--'}</p>
                    </div>
                  </div>
                </div>
                {(userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) ?
                  <div className="col-md-3 ">
                    <div className="mr-auto pr-3 form-group">
                      <label className="col-md-12">Investigation Agency</label>
                      <div className="chat-message pl-2">
                        <img src={avatarUrl(dbData.agencyavatarImporter)} alt="Avatar" width="32" height="32" />
                        <div className="chat-message-content clearfix pt-2">
                          <span className="text-primary">  {dbData.agencynameImporter ? dbData.agencynameImporter : '--'}</span><br />
                        </div>
                        <p className="date-format p-0 m-0">{dbData.agencyImporterAddress ? dbData.agencyImporterAddress : '--'}</p>
                      </div>
                    </div>
                  </div> :
                  (userTypeId === 4 || (userTypeId === 14 && clientType.type === 'seller')) ?
                    <div className="col-md-3 ">
                      <div className="mr-auto pr-3 form-group">
                        <label className="col-md-12">Investigation Agency</label>
                        <div className="chat-message pl-2">
                          <img src={avatarUrl(dbData.agencyavatarExporter)} alt="Avatar" width="32" height="32" />
                          <div className="chat-message-content clearfix pt-2">
                            <span className="text-primary">  {dbData.agencynameExporter ? dbData.agencynameExporter : '--'}</span><br />
                          </div>
                          <p className="date-format p-0 m-0">{dbData.agencyExporterAddress ? dbData.agencyExporterAddress : '--'}</p>
                        </div>
                      </div>
                    </div> : ""}
                <div className="col-md-3 ">
                  <div className="mr-auto pr-3 form-group">
                    <label className="col-md-12">Ship Owner</label>
                    <div className="chat-message pl-2">
                      <img src={avatarUrl(dbData.shipowneravatar)} alt="Avatar" width="32" height="32" />
                      <div className="chat-message-content clearfix pt-2">
                        <span className="date-format text-primary">  {dbData && dbData.shipOwner ? dbData.shipOwner : '--'}</span><br />
                      </div>
                      <p className="date-format p-0 m-0">{dbData.shipOwnerAddress ? dbData.shipOwnerAddress : '--'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.commodity_name ? dbData.commodity_name.toUpperCase() : ""}</h3>
                    <p>Commodity</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.quantity ? dbData.quantity + " mt" : ""}</h3>
                    <p>Quantity</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'} </h3>
                    <p>Price</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.load_country ? <span className="shadow">
                      <ReactCountryFlag
                        countryCode={dbData.load_country ? dbData.load_country.split(':')[0] : ''}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> : "NA"} </h3>
                    <p>Loading Country</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.loading_port ? dbData.loading_port : '--'} </h3>
                    <p>loading Port</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.unload_country ? <span className="shadow">
                      <ReactCountryFlag
                        countryCode={dbData.unload_country ? dbData.unload_country.split(':')[0] : ''}
                        style={{ width: '25px', height: '25px' }} svg />
                    </span> : "NA"} </h3>
                    <p>Unloading Country</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3> {dbData.unloading_port ? dbData.unloading_port : "-"} </h3>
                    <p>Unoading Port</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.laycan_start ? formatDate_Application(dbData.laycan_start) : '--'}</h3>
                    <p>Laycan Start</p>
                  </li>
                </ul>
              </div>
              <div className="card-panel">
                <ul>
                  <li>
                    <h3>{dbData.laycan_end ? formatDate_Application(dbData.laycan_end) : '--'} </h3>
                    <p>Laycan End</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div className="col-md-12">
            <h2 className="text-primary"> Commodity details</h2>
            <hr className="p-1" />
            <div className={"accordionItem" + (accorddata[1] ? " open" : " closed")}>
              <h2 className="accordionItemHeading" onClick={() => handleAccordion(1)}> Specifications</h2>
              <div className="accordionItemContent">

                {(reportDataDB && reportDataDB.length) ? reportDataDB.map((value) => {
                  return (
                    <>
                      <h3 className="text-primary  pb-2">
                        {
                          value.reportFrom === 'importerAgencyReport' ? "Report by Importer's Agency" :
                            value.reportFrom === 'exporterAgencyReport' ? "Report by Exporter's Agency" :
                              value.reportFrom === 'thirdAgencyReport' ? "Report by Third Party Agency" : "--"
                        }
                        {/* {statusPrint(value.report_status)} */}
                      </h3>
                      <div className="col-md-12 row">
                        {value.report_details && reportshowBody(value.contractSpec, value.report_details)}
                      </div>
                      <hr />
                    </>
                  )
                }) : specData ? <SpecificationsPreview specification={specData} /> : ''}

              </div>
            </div>
          </div>

          {(dbData.final_demurage || dbData.final_dispatch) &&
            <div className="col-md-12 mt-4">
              <h2 className="text-primary"> Shipment details</h2>
              <hr className="p-1" />
              <div className="form-check mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="addDem_Disp" id="addDem_Disp" onChange={(event) => { event.persist(); setaddDem_Disp(event.target.checked) }} checked={addDem_Disp} />
                <label className="form-check-label" for="addDem_Disp">Add the Total {dbData.final_demurage ? " Demurage " + dbData.final_demurage + " " : " Dispatch " + dbData.final_dispatch + " "} in the Contract Invoice</label>
              </div>
            </div>}

          <div className="col-md-12 mt-4">
            <h2 className="text-primary">Price Adjustment (If any)</h2>
            <hr className="p-1" />
            <PriceAdjustmentComp
              adjustPrice={adjustPrice}
              setadjustPrice={setadjustPrice}
            />
          </div>

          {((operationType === "Generate") || (operationType === "ReGenerate")) ?
            <div className="col-md-12 mt-4">
              <h2 className="text-primary">{(operationType === "Generate") ? "" : "Re-"}Generate Invoice</h2>
              <hr className="p-1" />
              <div className="form-check mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="agreeInvoiceGen" id="agreeInvoiceGen" onChange={(event) => { event.persist(); setagreeInvoiceGen(event.target.checked) }} checked={agreeInvoiceGen} />
                <label className="form-check-label" for="agreeInvoiceGen"> I hereby declare that the above information 100% correct and verified by me and
                  I give my consensus to use it and generate the invoice for given contract.</label>
              </div>
              <div className="col-md-12 mt-3 row">
                {(operationType === "Generate") &&
                  <div className="col-md-6">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() =>
                      setInvoiceDetail(true, {
                        "contractNo": InvoiceDetail.info.contractNo,
                        "userType": InvoiceDetail.info.userType,
                        "userId": InvoiceDetail.info.userId,
                        "operation": "Upload",
                        "invoiceType": "contract"
                      })} > Cancel, I will Upload the Invoice</button>
                  </div>}
                {agreeInvoiceGen && <div className="col-md-6">
                  <button type="button" className="btn btn-success btn-sm" onClick={() => setpreviewInvoice(true)}>Preview, The Invoice</button>
                </div>}
              </div>
            </div>
            :
            <div className="col-md-12 mt-4">
              <h2 className="text-primary">Upload Invoice</h2>
              <hr className="p-1" />
              <div className="col-md-12 mt-3 row">
                {(planData.invoiceGen && planData.invoiceGen.enable) && <div className="col-md-4">
                  <button type="button" className="btn btn-danger btn-sm" onClick={() =>
                    setInvoiceDetail(true, {
                      "contractNo": InvoiceDetail.info.contractNo,
                      "userType": InvoiceDetail.info.userType,
                      "userId": InvoiceDetail.info.userId,
                      "operation": "Generate",
                      "invoiceType": "contract"
                    })} > Cancel, I will Generate the Invoice</button>
                </div>}
                <div className="col-md-8 row">
                  <div className="col-md-4">
                    <div className="file-browse">
                      <button className="btn btn-primary btn-sm">Choose Document</button>
                      <input type="file" accept=".png,.jpg,.pdf" name="invoiceDoc" onChange={handleFile} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    {(values.invoiceDoc) ? <div className="form-group mb-0"><div className="file-name">{values.invoiceDoc.name}</div></div> : ""}
                  </div>
                  {values.invoiceDoc && <div className="col-md-3">
                    <button type="button" className="btn btn-success btn-sm" onClick={() => handleInvoiceUpload(null)}>Submit</button>
                  </div>}
                </div>
              </div>
            </div>}

        </div> :
        <div className="col-md-12 row mt-2">

          <div className="col-md-12 row m-2" id="divOfInvoiceContent">

            <div className="col-md-12 mt-5 row">
              <div className="col-md-3 text-center">
                <img src={avatarUrl(dbData.supplieravatar)} alt="Avatar" width="100" height="100" />
              </div>
              <div className="col-md-8 text-center">
                <h1>  {dbData.suppliername ? dbData.suppliername : '--'}</h1>
                <p className="">{dbData.supplierAddress ? dbData.supplierAddress : '--'}</p>
                <p className="">Email: {dbData.supplier_email ? dbData.supplier_email : '--'}, Mobile: {dbData.suppliermobileno ? dbData.suppliermobileno : '--'}, GST: {dbData.suppliergst ? dbData.suppliergst : '--'}</p>
              </div>
            </div>
            <hr className="col-md-12 pt-2 pb-2" />
            <div className="col-md-12 mt-2 row">
              <div className="col-md-6 text-left">
                <h2>  {dbData.buyername ? dbData.buyername : '--'}</h2>
                <p className="">{dbData.buyerAddress ? dbData.buyerAddress : '--'}</p>
              </div>
              <div className="col-md-6 text-right">
                <p className=""><b>INVOICE NO:</b> {InvoiceDetail.info.contractNo.replace("CT", "INV")}</p>
                <p className=""><b>Dated:</b> {formatDate_Application(new Date())}</p>
              </div>
            </div>
            <hr className="col-md-12 pt-2 pb-2" />
            <div className="col-md-12 mt-2 text-center">
              <h2><u><b>COMMERCIAL INVOICE</b></u></h2>
            </div>
            <div className="col-md-12 mt-2 text-left">
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>LC NO: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.lc_no ? dbData.lc_no : "--"} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>AGGREMENT NO: </b></div>
                <div className="col-md-9 pl-0" ><p>{InvoiceDetail.info.contractNo ? InvoiceDetail.info.contractNo : "--"} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>NAME OF VESSEL: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.ship_name ? dbData.ship_name : "--"} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>PORT OF LOADING: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.loading_port ? dbData.loading_port : '--'} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>PORT OF DISCHARGE: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.unloading_port ? dbData.unloading_port : '--'} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>B/L NO: </b></div>
                <div className="col-md-9 pl-0" ><p>{"--"} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>B/L DATE: </b></div>
                <div className="col-md-9 pl-0" ><p>{"--"} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>NAME OF COMMODITY: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.commodity_name ? dbData.commodity_name : '--'} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>ORIGIN: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.load_country ? dbData.load_country : '--'} </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>PACKING: </b></div>
                <div className="col-md-9 pl-0" ><p>In BULK </p></div>
              </div>
              <div className="col-md-12 row" >
                <div className="col-md-3 pl-0" ><b>QUANTITY: </b></div>
                <div className="col-md-9 pl-0" ><p>{dbData.quantity ? dbData.quantity + " mt" : '--'} </p></div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    PRODUCT SPECIFICATIONS:
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {(reportDataDB && reportDataDB.length) ? reportDataDB.map((value) => {
                        return (
                          <>
                            <h3 className="text-primary  pb-2">
                              {
                                value.reportFrom === 'importerAgencyReport' ? "Report by Importer's Agency" :
                                  value.reportFrom === 'exporterAgencyReport' ? "Report by Exporter's Agency" :
                                    value.reportFrom === 'thirdAgencyReport' ? "Report by Third Party Agency" : "--"
                              }
                            </h3>
                            <div className="col-md-12 row">
                              {value.report_details && reportshowBody(value.contractSpec, value.report_details)}
                            </div>
                            <hr />
                          </>
                        )
                      }) : specData ? <SpecificationsPreview specification={specData} /> : ''}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="col-md-12 mt-2">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>PRICING:</th>
                    <th width="250px"> {dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'} per mt</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="col-md-12 text-center mt-2">
              <h3><u><b>DETAILS OF CALCULATION BASED ON CERTIFICATE OF ANALYSIS ISSED BY AN INDEPENDENT INSPECTION AGENCY AT THE PORT OF LOADING</b></u></h3>
            </div>
            <div className="col-md-12 mt-2">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'} x {dbData.quantity ? dbData.quantity + " mt" : '--'}</th>
                    <th width="250px">{dbData.currency ? dbData.currency.split(':')[1] : ''} {(dbData.price / 1) * (dbData.quantity / 1)}</th>
                  </tr>
                </thead>
              </table>
            </div>

            {adjustPrice.length > 0 &&
              <>
                <div className="col-md-12 text-center mt-2">
                  <h3><u><b>DETAILS OF CALCULATION BASED ON PRICE ADJUSTMENT CLAUSE </b></u></h3>
                </div>
                <div className="col-md-12 mt-2">
                  <table class="table table-bordered">
                    <thead>
                      {adjustPrice.map((value) => {
                        return (
                          <tr>
                            <th>
                              {value.key}
                            </th>
                            <th width="250px">
                              {value.operator + "  "}
                              {dbData.currency ? dbData.currency.split(':')[1] : ''}
                              {value.value}
                            </th>
                          </tr>
                        )
                      })}

                    </thead>
                  </table>
                </div>
              </>}

            {addDem_Disp &&
              <>
                <div className="col-md-12 text-center mt-2">
                  <h3><u><b>DETAILS OF CALCULATION BASED ON DEMURAGE/DISPATCH CAME FROM SHIPMENT SETTLEMENT</b></u></h3>
                </div>
                <div className="col-md-12 mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>
                          {/* {dbData.currency ? dbData.currency.split(':')[1] : ''} {(dbData.price / 1) * (dbData.quantity / 1)} */}
                          {dbData.final_demurage ? "Demurage" : "Dispatch"}
                        </th>
                        <th width="250px">
                          {dbData.final_demurage ? "+  " : "-  "}
                          {dbData.currency ? dbData.currency.split(':')[1] : ''}
                          {dbData.final_demurage ? dbData.final_demurage : dbData.final_dispatch}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </>}
            <div className="col-md-12 text-center mt-2">
              <h3><u><b>Total Amount</b></u></h3>
            </div>
            <div className="col-md-12 mt-2">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th><h3 className="text-center">
                      {price_in_words(getInvoiceTotalAmount())}{dbData.currency ? dbData.currency.split(':')[0] : ''}
                    </h3></th>
                    <th width="250px">
                      {dbData.currency ? dbData.currency.split(':')[1] + " " : ''}
                      {getInvoiceTotalAmount()}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="col-md-12 text-left mt-3">
              <div className="col-md-6">
                <h3>For  {dbData.suppliername ? dbData.suppliername : '--'}</h3>
                <br />
                <br />
                <p>.......................................</p>
                <p>Authorised Signatory</p>
              </div>
            </div>


          </div>

          <hr className="col-md-12 p1-1 pb-1" />
          <div className="col-md-12 mt-3 row">
            <div className="col-md-6">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => setpreviewInvoice(false)}>Cancel</button>
            </div>
            {!login.validated && <div className="col-md-6">
              <button type="button" className="btn btn-warning btn-sm" onClick={() => setLogin({ ...login, state: true })}>Authorise, To Enable {(operationType === "Generate") ? "" : "Re-"}Generate Option</button>
            </div>}
            {(agreeInvoiceGen && login.validated) && <div className="col-md-6">
              <button type="button" className="btn btn-success btn-sm" onClick={() => printDiv('divOfInvoiceContent')}>{(operationType === "Generate") ? "" : "Re-"}Generate The Invoice</button>
            </div>}
          </div>
        </div>

      }
    </>
  )
};

const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceGen)
