
import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}

const columns = [

  {
    name: 'Vessel Name',
    selector: 'ship_name',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
  },
  {
    name: 'Imo No',
    selector: 'imo_no',
    sortable: true,
  },
  {
    name: 'Vessel Status',
    selector: 'ship_status',
    conditionalCellStyles: [
      {
        when: row => (row.ship_status === "Available"),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.ship_status === "Booked"),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',  //blue
          color: 'white',
        },
      }
    ]
  },
  {
    name: 'Commodities',
    selector: 'commodities',
    sortable: true,
  },
  {
    name: 'Registry Port',
    selector: 'registry_port',
    grow: 2,
    sortable: true,
  },
  {
    name: 'Length',
    selector: 'lengthv',
    sortable: true,
  },
  {
    name: 'Breadth',
    selector: 'breadthv',
    sortable: true,
  },
  {
    name: 'Depth',
    selector: 'depth',
    sortable: true,
  },
  {
    name: 'Deadweight',
    selector: 'dead_weight',
    sortable: true,
  },
  {
    name: 'Gross Ton',
    selector: 'gross',
    sortable: true,
  },
  {
    name: 'Net Ton',
    selector: 'net',
    sortable: true,
  }
];

if (aclMap.ships_access && aclMap.ships_access.ships_book && aclMap.ships_access.ships_book.isExist) {
  columns.push({
    name: 'Action',
    selector: 'action',
  })
}

export default columns
