import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setAddDirectory } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import columns from '../dataTablesColumsMap/userDirectoryColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import DataTable from 'react-data-table-component';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import AddUserInDir from './addUserInDir'
import toastDisplay from '../../utils/toastNotification';
import UpdateUserInDir from './updateUserInDir'
import Footer from '../partial/footer';

const UserDirectory = ({ userTokenDetails, addDirectory, navToggleState, dispatch }) => {


  const [refresh, setrefresh] = useState(0)
  const [dbTypeData, setdbTypeData] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [deleteConfirm, setdeleteConfirm] = useState({});
  const [updateModal, setupdateModal] = useState({ modal: false, id: null });

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------
  // console.log("addDirectory====>", addDirectory)

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls

    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    fetchTableData(1)
    //------------------------------------------------------------------

  }, [refresh, filterData, addDirectory.info.refresh]);


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("")

  function fetchTableData(page) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', "getuserdirectory", objectAPI).then((result) => {
      console.log('running getuserdirectory api-->', result);
      settableData(formatDataFortable(result.dirData))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserdirectory', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', "getuserdirectory", objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.dirData))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserdirectory', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };



  function formatDataFortable(dataArray, pageNo) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.country = (<span className="shadow">
        <ReactCountryFlag
          countryCode={dataArray[i].dir_country ? dataArray[i].dir_country : ''}
          style={{ width: '25px', height: '25px' }} svg />
      </span>)
      tempObj.name = dataArray[i].dir_company_name ? dataArray[i].dir_company_name : '--';
      tempObj.category = dataArray[i].usertype_pretty_name ? dataArray[i].usertype_pretty_name : '--'
      tempObj.contactPerson = dataArray[i].dir_contactperson ? dataArray[i].dir_contactperson : '--'
      tempObj.contactNo = dataArray[i].dir_contact_no ? dataArray[i].dir_contact_no : '--'
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : "--";
      tempObj.status = dataArray[i].active
      tempObj.action = (aclMap.mng_directory && aclMap.mng_directory.dir_mnguser && aclMap.mng_directory.dir_mnguser.isExist) ?
        <> {(dataArray[i].active) ?
          <button className="btn btn-success btn-sm ml-1" onClick={() => activeDeactiveUser(dataArray[i].id, "off")} tooltip="Active/Deactive">
            <i class="fas fa-toggle-on"></i>
          </button> :
          <button className="btn btn-danger btn-sm ml-1" onClick={() => activeDeactiveUser(dataArray[i].id, "on")} tooltip="Active/Deactive">
            <i class="fas fa-toggle-off"></i>
          </button>}
          <button className="btn btn-primary btn-sm ml-1" onClick={() => setupdateModal({ modal: true, id: dataArray[i].id })} tooltip="update">
            <i class="fas fa-edit"></i>
          </button>
          <button className="btn btn-danger btn-sm ml-1" onClick={() => setdeleteConfirm({ modal: true, id: dataArray[i].id })} tooltip="Remove">
            <i class="fas fa-trash-alt"></i>
          </button>
        </> : ""

      resultArray.push(tempObj)
    }
    return resultArray
  }

  // Handlers
  // console.log("update modal==>", updateModal)

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };


  const reset = () => {
    setFilterData({ searchUserType: "", search: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  function activeDeactiveUser(id, action) {

    call('post', 'toggleuserdirectory', { userId: id, action: action }).then((result) => {
      toastDisplay(result, "success")
      setrefresh(refresh + 1)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })
  }

  function deleteUser(id) {

    call('post', 'deleteuserdirectory', { userId: id }).then((result) => {
      setdeleteConfirm({ modal: false, id: null })
      toastDisplay(result, "success")
      setrefresh(refresh + 1)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })
  }

  function warnAndStop() {
    console.log("here");
    toastDisplay("You already added Max Users in your directory", "warn");
    setAddDirectory(false, {})
    return ""
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state={'directory'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={"Personal Directory"}
              total={"Total Users - " + totalRows}
              userTokenDetails={userTokenDetails} />

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

            <div className="">

              <div className="d-flex mt-3 mb-3">

                <div className="ml-auto">
                  <ul className="range-slider">
                    <li >
                      <div className="d-md-flex" >
                        <div className="mr-1 " >
                          <select className="form-control" name="searchUserType" value={filterData.searchUserType} onChange={filterState}>
                            <option value="" selected>All users</option>
                            {(dbTypeData.length) ? dbTypeData.map((item) => {
                              if (item.id > 2) {
                                return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                              }
                            }) : ''
                            }
                          </select>
                        </div>
                        <div className="ml-1 position-relative input-margin" >
                          <input placeholder="Search" type="text" className="form-control" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                          <button type="button" onClick={() => {
                            setFilterData({
                              ...filterData,
                              "search": searchKey
                            })
                          }}><i class="fas fa-search fa-lg"></i></button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>From</label>
                      <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} />
                    </li>
                    <li>
                      <label>To</label>
                      <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} />
                    </li>
                    <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
                  </ul>
                </div>
              </div>

              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={tableData}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                  persistTableHead
                  // expandableRows
                  // expandableRowsComponent={<TableExpandedComponent />}
                  expandOnRowClicked
                  highlightOnHover
                  onSort={handleSort}
                  sortServer
                  striped
                  noHeader
                  customStyles={customStyles}
                />
              </div>
              <hr />
              <div className="float-right">
                <br />
              </div>

              {addDirectory.modal && (aclMap.mng_directory && aclMap.mng_directory.dir_adduser && aclMap.mng_directory.dir_adduser.isExist) && (totalRows / 1 < 300) ?
                <AddUserInDir userTokenDetails={userTokenDetails} /> :
                addDirectory.modal ?
                  <>{warnAndStop()}</> : ""}

              {updateModal.modal && (aclMap.mng_directory && aclMap.mng_directory.dir_adduser && aclMap.mng_directory.dir_adduser.isExist) &&
                <UpdateUserInDir
                  userTokenDetails={userTokenDetails}
                  updateModal={updateModal}
                  setupdateModal={setupdateModal} />}

              {(deleteConfirm.modal && (aclMap.mng_directory && aclMap.mng_directory.dir_mnguser && aclMap.mng_directory.dir_mnguser.isExist)) &&
                <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
                  <div className="modal-dialog modal-lg border-inner" id="parent_class">
                    <div className="modal-content">
                      <div className="modal-header primary">
                        <h4 className="modal-title text-white">Confirmation</h4>
                        <button type="button" className="close" onClick={() => { setdeleteConfirm({ modal: false, id: null }) }}>×</button>
                      </div>
                      <div className="modal-body">
                        Please Confirm Delete Operation
                      </div>
                      <div className="modal-footer primary">
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteUser(deleteConfirm.id)}>Delete</button>
                        <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, id: null })}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>}

            </div>
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
};

const mapStateToProps = state => {

  return {
    addDirectory: state.addDirectory,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAddDirectory: (modal, info) => { dispatch(setAddDirectory({ modal: modal, info: info })) },

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDirectory)
