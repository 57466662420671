import React, { useState, useEffect } from 'react';
import call from '../../service';
import jsPDF from 'jspdf';
import toastDisplay from '../../utils/toastNotification';
import html2canvas from 'html2canvas';
import { formatDateTime_Application } from '../../utils/dateFormaters';

const LaytimeSheetTrail = ({ userTokenDetails, laytimeType, contractNo, contractType, setRefresh, refresh }) => {
  const [trail, setTrail] = useState({});

  // UseEffects
  useEffect(() => {
    //API Calls
    call('POST', 'getLaytime', { cNo: contractNo, contractType: contractType, laytimeType: laytimeType }).then(result => {
      if (result) {
        setTrail(result.laytimeData ? result.laytimeData : {})
      }
    }).catch((e) => {
      console.log('error', e);
    })
  }, [refresh]);

  // Download SOF Sheet
  function downloadSof(fileHash, fileName) {
    let formData = new FormData();
    formData.append('fileHash', fileHash);
    formData.append('fileName', fileName);
    let ext = fileName.split('.')[1]

    call('POST', 'downloadSof', formData).then((result) => {
      if (result) {
        let link = document.createElement("a");
        link.download = contractNo + "." + ext;
        link.href = 'data:application/' + ext + ';base64,' + encodeURI(result.filebase64);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
    })
  }


  function downloadLaytime(divName) {

    let printContents = document.getElementById(divName)
    let outerDiv = document.getElementById("outerDivID")

    // window.scrollTo(0, document.body.scrollHeight);
    let w = outerDiv.clientWidth;
    let h = outerDiv.clientHeight + 20;

    html2canvas(printContents, {
      width: w,
      height: h,
      scrollX: 0,
      scrollY: -window.scrollY,
      allowTaint: true,
      useCORS: true
    }).then((canvas) => {
      // window.open().document.write('<img src="' + canvas.toDataURL() + '" />');
      // setshowLoader(true)
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        compress: true
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      // setshowLoader(false)
      pdf.save(`Laytime.pdf`);
    });
  }

  // Tail Row Component
  function trailInnerRow(ind) {
    let innerRows = []
    for (let i = 1; i < trail.sheet.sheetData[ind].sub.length; i++) {
      innerRows.push(
        <tr>
          <td><input type="text" className="form-control" defaultValue={trail.sheet.sheetData[ind].sub[i].laytimeStart} readOnly /></td>
          <td><input type="text" className="form-control" defaultValue={trail.sheet.sheetData[ind].sub[i].laytimeEnd} readOnly /></td>
          <td><input type="text" className="form-control" defaultValue={trail.sheet.sheetData[ind].sub[i].laytimeUsed} readOnly /></td>
          <td><input type="text" className="form-control" defaultValue={trail.sheet.sheetData[ind].sub[i].reason} readOnly /></td>
          <td>{trail.sheet.sheetData[ind].sub[i].laytimeDiff}</td>
        </tr>
      )
    }
    return innerRows;
  }

  // Tail Body Component
  function trailBody() {
    if (trail && !Object.keys(trail).length) {
      return (
        <div className="text-center mt-5" id="laytimeSheetDiv" style={{ "height": "60px" }}>
          <h2 className="p-3">No Laytime Sheet Found</h2>
        </div>
      )
    }
    else {
      return (
        <>

          <div className="p-1" id="laytimeSheetDiv">
            <div className="col-md-12" >
              <h3 className="pt-2 ">{laytimeType === 1 ? "Loading " : "Unloading "}laytime Sheet</h3>
              <div className="col-md-12 row mt-3 text-center">
                <label className="col-md-6"><b>Last Edited By :</b> {trail.modified_by_name ? trail.modified_by_name : "NA"} </label>
                <label className="col-md-6"><b>Last Edited At :</b> {trail.updated_at ? formatDateTime_Application(trail.updated_at) : "NA"}</label>
              </div>
            </div>
            <div className="mt-2">
              <div className="row">
                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Quantity Loaded (In MT)</label>
                    <div className="col-md-12">
                      <input type="number" className="form-control" defaultValue={trail.sheet.qLoaded} readOnly />
                    </div>
                  </div>

                </div>
                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">C/P Loading Rate (PDPR)</label>
                    <div className="col-md-12"><input type="number" className="form-control" defaultValue={trail.sheet.loadRate} readOnly /></div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">NOR Tendered</label>
                    <div className="col-md-12"><input type="datetime-local" className="form-control" defaultValue={trail.sheet.nor} readOnly /></div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Time of Arrival</label>
                    <div className="col-md-12">
                      <input type="datetime-local" className="form-control" defaultValue={trail.sheet.arrivalTime} readOnly />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Laytime Unit</label>
                    <div className="col-md-12">
                      <select className="form-control" className="form-control" defaultValue={trail.sheet.laytimeUnit} readOnly >
                        <option>ATUTC</option>
                        <option>USC</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Laytime commenced </label>
                    <div className="col-md-12">
                      <input type="datetime-local" placeholder="" className="form-control" defaultValue={trail.sheet.laytimeCommenced} readOnly />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Loading commenced</label>
                    <div className="col-md-12"><input type="datetime-local" className="form-control" defaultValue={trail.sheet.loadCommenced} readOnly /></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Loading completed</label>
                    <div className="col-md-12"><input type="datetime-local" className="form-control" defaultValue={trail.sheet.loadCompleted} readOnly /></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Demurage Rate</label>
                    <div className="col-md-12">
                      <input type="number" step="0.01" placeholder="$9,000.00" className="form-control" defaultValue={trail.sheet.demurageRate} readOnly />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Despatch Rate</label>
                    <div className="col-md-12">
                      <input type="number" step="0.01" placeholder="$4,500.00" className="form-control" defaultValue={trail.sheet.dispatchRate} readOnly />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row form-group">
                    <label className="col-md-12">Laytime Allowed</label>
                    <div className="col-md-12"><input type="text" className="form-control" defaultValue={trail.sheet.timeAllowed} readOnly /></div>
                  </div>
                </div>
              </div>
            </div>

            <table className="table table-custom table-sm" cellspacing="0" cellpadding="0">
              <thead>
                <tr>
                  <th>Lay Date</th>
                  <th>Day</th>
                  <th>Laytime (From) </th>
                  <th>Laytime (To)</th>
                  <th>Laytime Diff (To - From)</th>
                  <th>Remarks</th>
                  <th>Laytime used</th>
                </tr>
              </thead>
              <tbody>
                {
                  trail.sheet.sheetData.map((val, ind) => {
                    let temp = trailInnerRow(ind)

                    return (
                      <>
                        <tr>
                          <td rowSpan={trail.sheet.sheetData[ind].sub.length}><input type="text" defaultValue={val.date} className="form-control" readOnly /></td>
                          <td rowSpan={trail.sheet.sheetData[ind].sub.length}><input type="text" defaultValue={val.day} className="form-control" readOnly /> </td>
                          <td><input type="text" defaultValue={val.sub[0].laytimeStart} className="form-control" readOnly /></td>
                          <td><input type="text" defaultValue={val.sub[0].laytimeEnd} className="form-control" readOnly /></td>
                          <td><input type="text" defaultValue={val.sub[0].laytimeUsed} className="form-control" readOnly /></td>
                          <td><input type="text" defaultValue={val.sub[0].reason} className="form-control" readOnly /></td>
                          <td>{val.sub[0].laytimeDiff}</td>
                        </tr>
                        {
                          temp
                        }
                      </>
                    )
                  })
                }
                <tr>
                  <td colSpan="5" rowSpan="6" className="align-middle text-center"><b>Port Total laytime used</b></td>
                  <td><b>Time Allowed</b></td>
                  <td>{trail.sheet.timeAllowed}</td>
                </tr>
                <tr>
                  <td><b>Time used </b></td>
                  <td>{trail.sheet.timeUsed}</td>
                </tr>
                {
                  !trail.sheet.timeDiif.includes('-') &&
                  <>
                    <tr>
                      <td><b>Time Saved</b></td>
                      <td>{trail.sheet.timeDiif}</td>
                    </tr>
                    <tr>
                      <td><b>Days </b></td>
                      <td>{trail.sheet.totalDays}</td>
                    </tr>
                    <tr>
                      <td><b>Despatch Rate PDPR </b></td>
                      <td>{trail.sheet.dispatchRate}</td>
                    </tr>
                    <tr>
                      <td><b> Total Despatch </b></td>
                      <td>{(trail.sheet.dispatchRate * trail.sheet.totalDays).toFixed(2)}</td>
                    </tr>
                  </>
                }

                {
                  trail.sheet.timeDiif.includes('-') &&
                  <>
                    <tr>
                      <td><b>Time exceeded</b></td>
                      <td>{trail.sheet.timeDiif}</td>
                    </tr>
                    <tr>
                      <td><b>Days </b></td>
                      <td>{trail.sheet.totalDays}</td>
                    </tr>
                    <tr>
                      <td><b>Demurage Rate PDPR </b></td>
                      <td>{trail.sheet.demurageRate}</td>
                    </tr>
                    <tr>
                      <td><b> Total Demurage </b></td>
                      <td>{trail.sheet.demurageRate * trail.sheet.totalDays}</td>
                    </tr>
                  </>
                }

              </tbody>
            </table>

          </div>
        </>
      )
    }
  }

  // Return Component JSX
  return (
    <div className="col-md-12 mt-5">
      <div className="col-md-12 row">
        <div className="col-md-10">
          <h2>{laytimeType === 1 ? "Loading " : "Unloading "}Laytime History</h2>
        </div>
        <div className="col-md-2">
          {(trail && Object.keys(trail).length > 0) &&
            <button className="btn btn-success btn-sm" onClick={() => downloadLaytime("laytimeSheetDiv")}>{<i className="fa fa-download" aria-hidden="true"></i>} Sheet</button>}
          {/* <button className="btn btn-primary ml-1" onClick={() => downloadSof(trail.docDetails[0].file_hash, trail.docDetails[0].file_name)}>
                  {<i className="fa fa-download" aria-hidden="true"></i>}
                   SOF</button> */}
        </div>

      </div>

      <div className={"col-md-12 mt-2 " + ((trail && !Object.keys(trail).length) ? "bg-light" : "bg-shadow")} id="outerDivID" style={(trail && !Object.keys(trail).length) ? { "height": "80px" } : {}}>
        {trailBody()}
      </div>

    </div>
  )
}


export default LaytimeSheetTrail;
