export default function validate(values, data) {
  console.log(data);
  let errors = {};
  for (var i = 0; i < data.length; i++) {

    if (!values[`${data[i]}doc:11:15`]) {
      errors[`${data[i]}doc:11:15`] = 'File is required';
    }
  }

  console.log(errors)
  return errors;
}
