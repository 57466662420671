export const profileIndex = {
  "3": "Information",
  "4": "Information",
  "6": "of Investigation Agency",
  "12": "of Custom Inspection",
  "8": "of Bank",
  "11": "of Stevedore",
  "5": "of Shipper",
  "10": "of Broker",
  "13": "of Insurance Agency",
  "14": "Information",
  "7": "of Vessel Master",
  "17": "Captain",
  "19": "Trade Finance"
}

export const kycDocs = [
  { "key": "cinDocument", "length": "21", "name": "CIN", "userType": [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 18, 19], "dbId": ":2:1" },
  { "key": "panDocument", "length": "10", "name": "PAN", "userType": [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 18, 19], "dbId": ":2:1" },
  { "key": "gstDocument", "length": "15", "name": "GST", "userType": [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 18, 19], "dbId": ":2:1" },
  { "key": "iecDocument", "length": "10", "name": "IEC", "userType": [3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 18, 19], "dbId": ":2:1" },
  { "key": "ifscDocument", "length": "11", "name": "IFSC", "userType": [8], "dbId": ":2:1" },
  { "key": "license", "length": "", "name": "License", "userType": [6, 12, 11, 5, 13], "dbId": ":2:1" },
]

export const finTypeKYCDocs = [
  { "key": "cinDocument", "length": "21", "name": "CIN", "organizationType": ["pvtPubLtd"], "dbId": ":2:1" },
  { "key": "gstDocument", "length": "15", "name": "GST", "organizationType": ["pvtPubLtd", "partnership", "soleProprietorship", "llp"], "dbId": ":2:1" },
  { "key": "iecDocument", "length": "10", "name": "IEC", "organizationType": ["pvtPubLtd", "partnership", "soleProprietorship", "llp"], "dbId": ":2:1" },
]