
import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}

const columns = [
  {
    name: 'Vessel Name',
    selector: 'ship_name',
    sortable: true,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
  },
  {
    name: 'Imo No',
    selector: 'imo_no',
    sortable: true,
  },
  // {
  //   name: 'Commodities',
  //   selector: 'commodities',
  //   sortable: true,
  // },
  {
    name: 'Registry Port',
    selector: 'registry_port',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Length',
    selector: 'lengthv',
    sortable: true,
  },
  {
    name: 'Breadth',
    selector: 'breadthv',
    sortable: true,
  },
  {
    name: 'Depth',
    selector: 'depth',
    sortable: true,
  },
  {
    name: 'Deadweight',
    selector: 'dead_weight',
    sortable: true,
  },
  {
    name: 'Gross Ton',
    selector: 'gross',
    sortable: true,
  },
  {
    name: 'Net Ton',
    selector: 'net',
    sortable: true,
  },
  {
    name: 'Vessel Status',
    selector: 'availability',
    grow: 2,
    cell: row =>
      (row.availability === 1) ? "Busy/In-Use" :
        (row.availability === 2) ? "Free/Not In-use" :
          (row.availability === 3) ? "Under Booking" :
            (row.availability === 4) ? "Already Booked" : "",
    conditionalCellStyles: [
      {
        when: row => (row.availability === 4),
        style: {
          backgroundColor: 'rgba(48, 135, 142,0.9)',
          color: 'white',
        },
      },
      {
        when: row => (row.availability === 3),
        style: {
          backgroundColor: 'rgba(248, 148, 6, 0.9)',
          color: 'white',
        },
      },
      {
        when: row => (row.availability === 2),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',
          color: 'white',
        },
      },
      {
        when: row => (row.availability === 1),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',
          color: 'white',
        },
      }
    ]
  },

];

if (aclMap.ships_access && aclMap.ships_access.ships_edit && aclMap.ships_access.ships_edit.isExist) {
  columns.push({
    name: 'Update',
    selector: 'update',
  },
    {
      name: 'Active/De-active',
      selector: 'action',
    })
}


export default columns
