import React from 'react';
import Header from '../partial/header';
import { ToastContainer } from 'react-toastify';
import { setViewTrack } from '../../store/actions/action';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import config from '../../config'

const Tracking = ({ userTokenDetails, setViewTrack, ViewTrack, dispatch }) => {

  //const [values, setValues] = useState({});

  // useEffect(() => {
  //   call('POST', 'getDocList', { "cid": ViewTrack.contractId }).then(async (result) => {
  //     console.log("api result ---> ", result)
  //     setdbDocData(result);
  //     fillvalueData(result);
  //   }).catch((e) => {
  //     console.log("Error while querying Doc:", e);
  //   })
  // }, []);

  console.log("check-------", ViewTrack)


  return (
    <><ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <Header
        title={'Contract Details: Track Shipment'}
        userTokenDetails={userTokenDetails} />
      <div className="d-flex">
        <ul className="contract-left">
          <li>
            <h2>Contract {ViewTrack.contractId}</h2>
            <p>Estimated Delivery on 12th Feb, 2020</p>
          </li>
        </ul>
      </div>
      <div className="d-flex flex-column mt-5">
        <ul className="timeline" id="timeline">
          <li className="complete">
            <div className="timestamp">
              <span className="author">Chandigarh</span>
              <span className="date">30/07/2019<span>
              </span></span></div>
            <div className="status">
              <div className="status-first">
                <span>Loading Port </span>
                <span>Commidity has arrived at loading Port </span>
              </div>
              <div className="status-second">20.5937° N, 78.9629° E</div>
              <div className="status-third">Coordinates</div>
              <div className="status-fourth">35 mis ago</div>
            </div>
          </li>
          <li className>
            <div className="timestamp">
              <span className="author">Kurukshetra</span>
              <span className="date">01/08/2019<span>
              </span></span></div>
            <div className="status">
              <div className="status-first">
                <span>Investigation</span>
                <span>Investigation has been done by ASTM</span>
              </div>
              <div className="status-second">20.5937° N, 78.9629° E</div>
              <div className="status-third">Coordinates</div>
              <div className="status-fourth">35 mis ago</div>
            </div>
          </li>
          <li className>
            <div className="timestamp">
              <span className="author">Panipat</span>
              <span className="date">05/08/2019<span>
              </span></span></div>
            <div className="status">
              <div className="status-first">
                <span>Vessel </span>
                <span>Vessel is now on the way </span>
              </div>
              <div className="status-second">20.5937° N, 78.9629° E</div>
              <div className="status-third">Coordinates</div>
              <div className="status-fourth">35 mis ago</div>
            </div>
          </li>
          <li className>
            <div className="timestamp">
              <span className="author">Delhi</span>
              <span className="date">15/08/2019<span>
              </span></span></div>
            <div className="status">
              <div className="status-first">
                <span>Arrived at Destination </span>
                <span>Container has been arrived at given Location &amp; ready to pick</span>
              </div>
              <div className="status-second">20.5937° N, 78.9629° E</div>
              <div className="status-third">Coordinates</div>
              <div className="status-fourth">35 mis ago</div>
            </div>
          </li>
        </ul>
      </div>

    </>)
}

const mapStateToProps = state => {
  return {
    ViewTrack: state.ViewTrack
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracking)
