import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import Footer from '../partial/footer';
import FilePreview from '../utilComponents/previewFile';
import DataTable from 'react-data-table-component';
import docJson from '../userProfile/financeDoc.json';
import customStyles from '../dataTablesColumsMap/customTableCss';
import fincolumns from '../dataTablesColumsMap/userFinancialDetailsGrid';
import authorizedcolumns from '../dataTablesColumsMap/userShareholderDetailsGrid';
import bankcolumns from '../dataTablesColumsMap/bankDetailsGrid';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/creditlineByBank';

const LenderOffer = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, creditlineDataBank }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [buyersList, setBuyersList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [fileData, setfileData] = useState({});
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [pendingAction, setPendingAction] = useState({ modal: false, data: {} });
  const [documentRow, setDocumentRow] = useState({ data: [{ documentName: null, document: null }] });
  const [pendingActionData, setPendingActionData] = useState({});
  const [assignBuyer, setAssignBuyer] = useState({ data: [] });
  const [supplierInformation, setSupplierInformation] = useState([]);
  const [authorized, setAuthorized] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);

  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    fetchfinTableData(1, {});
    fetchauthorizedTableData(1, {});
    fetchbankTableData(1, {});

    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    let objectAPI = {
      "buyersId": creditlineDataBank.info.allData.buyer_id,
      "docId": creditlineDataBank.info.allData.doc_id,
    }

    call('POST', 'getBuyersDetailWithDoc', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      setBuyersList(result);

    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    });

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    });

    call('POST', 'userdetailsextra', { userId: manualUserId }).then((result) => {
      console.log("result in getuserdetailsextra api->", result);
      setSupplierInformation(result);

    }).catch((e) => {
      console.log("Error while querying getBanksListMaster:", e);
    });
    //---------------------------------------------------------------------------------------------------------------------

  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  // Datatable
  const [fintableData, setfintableData] = useState([]);
  const [authorizedtableData, setauthorizedtableData] = useState([]);
  const [banktableData, setbanktableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);

  //Financial Grid

  function fetchfinTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userTypeId": userTypeId,
      "manualUserId": manualUserId,
      "gridType": "selected",
      "financial_id": creditlineDataBank.info.allData.financial_year_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  }

  const handlefinPageChange = page => {
    fetchfinTableData(page, {});
  };

  const handlefinPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userTypeId": userTypeId,
      "manualUserId": manualUserId,
      "gridType": "selected",
      "financial_id": creditlineDataBank.info.allData.financial_year_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getuserfinancegrid', objectAPI).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setfintableData(formatfinDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      setLoading(false)
    })
  };

  function formatfinDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.year = dataArray[i].year;
      tempObj.currency = dataArray[i].currency.split(":")[1];
      tempObj.turnover = dataArray[i].turnover + " " + dataArray[i].currency.split(":")[1];
      tempObj.netprofit = dataArray[i].netprofit + " " + dataArray[i].currency.split(":")[1];
      tempObj.networth = dataArray[i].networth + " " + dataArray[i].currency.split(":")[1];
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      tempObj.action = 'N/A';

      tempObj.finance_doc_1_year = dataArray[i].finance_doc_1_year;
      tempObj.finance_doc_2_year = dataArray[i].finance_doc_2_year;
      tempObj.finance_doc_3_year = dataArray[i].finance_doc_3_year;
      tempObj.finance_doc_current_year = dataArray[i].finance_doc_current_year;
      tempObj.gst_doc_6_month = dataArray[i].gst_doc_6_month;
      tempObj.itr_doc_1_year = dataArray[i].itr_doc_1_year;
      tempObj.itr_doc_2_year = dataArray[i].itr_doc_2_year;
      tempObj.itr_doc_3_year = dataArray[i].itr_doc_3_year;
      tempObj.debtor_doc = dataArray[i].debtor_doc;
      tempObj.creditor_doc = dataArray[i].creditors_doc;

      resultArray.push(tempObj)
    }
    return resultArray
  }

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function createMiniBody(data) {
    let keysArray = Object.keys(docJson)
    let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
      return (<>
        {docJson[keyValue].map((arrayElement) => {
          return (
            <div className="col-md-4 bg-light border-left mb-1">
              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
              <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(data[arrayElement.name], "view")}>
                {keyValue + " - ( " + arrayElement.lable + " )"}
              </button>
            </div>
          )
        })}
      </>)
    }) : ""

    return (returnJSX)

  }

  //Authorized Signatory Grid

  function fetchauthorizedTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": manualUserId,
      "gridType": "selected",
      "authorized_id": creditlineDataBank.info.allData.authorized_signatory_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getShareHolderGrid api-->', result);
      setauthorizedtableData(formatauthorizedDataFortable(result));
      setAuthorized(result);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getShareHolderGrid', e);
      setLoading(false)
    })
  }

  const handleauthorizedPerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": manualUserId,
      "type_id": userTypeId,
      "gridOnly": true,
      "gridType": "selected",
      "authorized_id": creditlineDataBank.info.allData.authorized_signatory_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getShareHolderGrid', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      setauthorizedtableData(formatauthorizedDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  };

  const handleauthorizedPageChange = page => {
    fetchauthorizedTableData(page, {});
  };

  function formatauthorizedDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.name = dataArray[i].name;
      tempObj.country = dataArray[i].country;
      tempObj.nationality = dataArray[i].nationality;
      tempObj.email_id = dataArray[i].email_id;
      tempObj.share = dataArray[i].share_in_company;
      tempObj.admin_status = dataArray[i].admin_status;
      tempObj.signatory = dataArray[i].signatory;
      tempObj.itr = dataArray[i].itr;
      tempObj.din = dataArray[i].din;

      let doc = dataArray[i].doc_array.split(',');
      tempObj.kyc_doc1 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[0], "view")}>
        View
      </button>
      tempObj.kyc_doc2 = <button className="btn btn-link btn-sm p-0 m-0" onClick={() => callPreviewFileComponent(doc[1], "view")}>
        View
      </button>

      tempObj.action = 'N/A'

      resultArray.push(tempObj)
    }
    return resultArray
  };

  //Bank Details Grid

  function fetchbankTableData(page, inptObject) {
    setLoading(true);

    let objectAPI = {
      email: manualUserEmail,
      inProfile: true,
      "gridType": "selected",
      "bank_detail_id": creditlineDataBank.info.allData.bank_detail_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getUserBanks', objectAPI).then((result) => {
      console.log('running getUserBanks api-->', result);
      setbanktableData(formatbankDataFortable(result));
      setBankList(result);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
      setLoading(false)
    })
  }

  const handlebankPerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    let objectAPI = {
      email: manualUserEmail,
      inProfile: true,
      "gridType": "selected",
      "bank_detail_id": creditlineDataBank.info.allData.bank_detail_id
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getUserBanks', objectAPI).then((result) => {
      console.log('running getUserBanks api-->', result);
      setbanktableData(formatbankDataFortable(result));
      setLoading(false);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
      setLoading(false);
    })
  };

  const handlebankPageChange = page => {
    fetchbankTableData(page, {});
  };

  function formatbankDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.id = dataArray[i].id;
      tempObj.userEmail = dataArray[i].email;
      tempObj.account = dataArray[i].beneficiaryAccountNo;
      tempObj.bankName = dataArray[i].beneficiaryBankName;
      tempObj.accountType = dataArray[i].acount_type;
      tempObj.ifscCode = dataArray[i].beneficiaryIfscCode;
      tempObj.swiftCode = dataArray[i].beneficiarySwiftCode;
      tempObj.beneficiaryName = dataArray[i].beneficiaryAccountName;
      tempObj.beneficiaryBranchAddress = dataArray[i].beneficiaryBranchAddress;

      resultArray.push(tempObj)
    }
    return resultArray
  };


  //---------------------------------------------------------------------------------------------------------------------
  // functions

  const assignCredit = (assignData) => {
    let object = {
      "buyer_id": assignData.id,
      "assignValue": data.buyersCredit,
      "assignRemark": data.buyersRemark,
      "buyerEmail": assignData.buyerEmail,
      "id": creditlineDataBank.info.allData.id,
      "lender_id": userId,
      "lender_name": creditlineDataBank.info.allData.company_name ? creditlineDataBank.info.allData.company_name :
        creditlineDataBank.info.allData.lendername
    }

    assignBuyer.data.push(object);
    setAssignBuyer(assignBuyer);
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  const handleFiles = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = manualUserId;
    data.userName = manualUserName;
    data.userEmail = manualUserEmail;
    data.contract_id = creditlineDataBank.info.allData.credit_id;
    data.buyer_id = creditlineDataBank.info.allData.buyer_id;
    data.bankId = userId;
    data.bankEmail = userEmail;
    data.requestedAmount = creditlineDataBank.info.allData.credit_amount;
    data.credit_id_by_user = creditlineDataBank.info.allData.id;
    data.company_name = creditlineDataBank.info.allData.company_name;
    data.buyerCredit = JSON.stringify(assignBuyer);
    data.supplierInformation = JSON.stringify(supplierInformation);
    data.authorizedData = JSON.stringify(authorized);
    data.bankList = JSON.stringify(bankList);
    data.buyersList = JSON.stringify(buyersList);

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    let docName = [];

    Object.keys(documentRow.data).forEach((key) => {
      if (documentRow.data[key].document) {
        docName.push(documentRow.data[key].document);
      }
      formData.append('document', docName);

      if (documentRow.data[key][documentRow.data[key].document + ":49"]) {
        formData.append((documentRow.data[key].document + ":49"), documentRow.data[key][documentRow.data[key].document + ":49"])
      }
    });

    call('POST', "insertcreditofferbybbank", formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false);
      setrefresh(refresh + 2);
      setIsSubmitting(false);
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }).catch(err => {
      setIsSubmitting(false);
      setshowLoader(false);
      console.log("conn:", err);
      toastDisplay(err, "error");
    })
  }

  const handleChangePending = (event) => {
    if (event.target.name === "pendingStatus" && event.target.value / 1 === 2) {
      setPendingActionData({ ...pendingActionData, [event.target.name]: event.target.value, "pendingComment": "Details OK" })
    } else {
      setPendingActionData({ ...pendingActionData, [event.target.name]: event.target.value })
    }
  };

  function submitPendingStatus() {
    if (!pendingActionData.pendingStatus || !pendingActionData.pendingComment) {
      toastDisplay("Please Fill all details", "warn")
      return
    }

    pendingActionData.id = creditlineDataBank.info.allData.id;
    pendingActionData.allData = creditlineDataBank.info.allData;

    call('POST', 'pendingaddusercreditstatus', { ...pendingActionData, ...pendingAction.data }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
      setPendingAction({ modal: false, data: {} })
      setPendingActionData({})
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }).catch((e) => {
      console.log('error in adminadduserfinacestatus', e);
      toastDisplay(e, "error");
    })

  }

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //More Document

  function addDocumentRow() {
    if (documentRow.data.length < 5) {
      documentRow.data.push({ name: null });
      setDocumentRow({ ...documentRow });
    }
  }

  function deleteDocumentRow(ind) {
    documentRow.data.splice(ind, 1)
    setDocumentRow({ ...documentRow })
  }

  function getDocumentRow() {

    let rowsArr = [];
    for (let i = 0; i < documentRow.data.length; i++) {
      rowsArr.push(
        <>
          <div className="modal-padding" id={"CreditLine:49"}>
            <ul className="other-documents pb-0 mb-0">
              <li >
                <label className="mb-0" >
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                </label>
              </li>


              <li>
                <input type="text" className={" form-control" + (!error.multipledocumentName ? '' : ' border-danger')} placeholder="Document Name" name="document" value={data.document} onChange={(event) => handledocumentChange(event, i)} required />
                {error.multipledocumentName && <p className="text-danger error-contract">{error.multipledocumentName}</p>}
              </li>


              <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                <div className="file-browse">
                  <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                  <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={documentRow.data[i].document + ":49"} onChange={(event) => handledocumentFiles(event, i)} disabled={!documentRow.data[i].document} />
                  {error[documentRow.data[i].dirContractDocument] && <p lassName="text-danger error-contract">{error[documentRow.data[i].dirContractDocument]}</p>}
                </div>
              </li>
              <li style={{ "min-width": "150px" }}>
                {(documentRow.data[i][documentRow.data[i].document + ":49"]) ? <div className="filePath text-left"><div className="file-name">{`${documentRow.data[i][documentRow.data[i].document + ":49"].name}`}</div></div> : ""}
              </li>
              <li>
                <div className="col-md-1">
                  <span className="field">
                    <i className="fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteDocumentRow(i)}></i>
                  </span>
                </div>
              </li>
            </ul>
            <br />
            <br />
          </div>
        </>
      )
    }
    return rowsArr;
  }

  const handledocumentChange = (event, ind) => {
    if (event.target.name === 'name') {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    } else {
      documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.value }
    }
    setDocumentRow({ ...documentRow })
  };

  const handledocumentFiles = (event, ind) => {
    event.persist();
    documentRow.data[ind] = { ...documentRow.data[ind], [event.target.name]: event.target.files[0] }
    setDocumentRow({ ...documentRow });
  };

  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview

  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{manualUserName ? manualUserName : userName}</div>
            <div className="price-right">Username </div>
          </li>
          <li>
            <div className="price-left">{creditlineDataBank.info.allData.company_name ? creditlineDataBank.info.allData.company_name :
              creditlineDataBank.info.allData.lendername}</div>
            <div className="price-right">Lender Name </div>
          </li>
          <li>
            <div className="price-left"> {creditlineDataBank.info.allData.credit_amount} </div>
            <div className="price-right">Requested Amount (USD)</div>
          </li>
          <li>
            <div className="price-left">{creditlineDataBank.info.allData.created_at}</div>
            <div className="price-right">Created At</div>
          </li>
          <li>
            <div className="price-left"> {creditlineDataBank.info.allData.status}</div>
            <div className="price-right">Status</div>
          </li>
        </ul>
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="col-md-12 mb-2 text-center">
          <h3 className="green border-bottom pb-2">Offer Credit Line</h3>
        </div>
        <br />

        {/* 1st section */}

        {/* <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Lender Details
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">

                      <div className="form-group col-md-6">
                        <label>Lender Name </label>
                        <input type="text" className={" form-control" + (!error.lender ? '' : ' border-danger')} placeholder="" name="lender" value={creditlineDataBank.info.allData.company_name ?
                          creditlineDataBank.info.allData.company_name : creditlineDataBank.info.allData.lendername} />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Loan type </label>
                        <input type="text" className={" form-control" + (!error.loanType ? '' : ' border-danger')} placeholder="" name="loanType" value={creditlineDataBank.info.allData.loanType} />
                      </div>

                      <div className="col-md-12">
                        <div className="form-check pl-0 mt-1 mb-1">
                          <input className="form-check-input" type="checkbox" name="loanTerms" id="loanTerms" checked="yes" />
                          <label className="form-check-label" for="loanTerms">
                            I accept/Agree to all the details provided by me.
                          </label>
                        </div>
                      </div>

                      <br />
                      <br />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* 2nd Section */}

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Applicants Info
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">
                        {miniPreview()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 3rd section */}

        {/* <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Buyer's List
                    </h3>
                    <hr />
                  </div>

                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">

                        {buyersList && buyersList.length > 0 && buyersList.map((value) => {
                          return (
                            <>
                              <div className="modal-padding">
                                <ul className="price-ul">
                                  <li>
                                    <div className="price-left">{value.buyerName ? value.buyerName : ''}</div>
                                    <div className="price-right">Buyer Name </div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerEmail ? value.buyerEmail : ''}</div>
                                    <div className="price-right">Buyer Email </div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.buyerPhone ? value.buyerPhone : ''} </div>
                                    <div className="price-right">Contact No</div>
                                  </li>
                                  <li>
                                    <div className="price-left">{value.buyerAddress ? value.buyerAddress : ''}</div>
                                    <div className="price-right">Buyer Address</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.previousAnnualSale ? value.previousAnnualSale : ''}</div>
                                    <div className="price-right">Previous Sale</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.currentAnnualSale ? value.currentAnnualSale : ''}</div>
                                    <div className="price-right">Expected Sale</div>
                                  </li>
                                  <li>
                                    <div className="price-left"> {value.productDetails ? value.productDetails : ''}</div>
                                    <div className="price-right">Product Details</div>
                                  </li>
                                </ul>
                              </div>


                              <div className="col-md-12">
                                <div className="row pt-2">
                                  {creditlineDataBank.info.tab !== 'pendingTab' ?
                                    <div className="form-group col-md-6">
                                      <label>Assign Credit </label>
                                      <input type="number" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} onChange={handleChange} />
                                      {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
                                    </div>
                                    :
                                    <div className="form-group col-md-6">
                                      {JSON.parse(creditlineDataBank.info.allData.buyer_credit).data.map((buyerValue) => {
                                        return (
                                          value.buyerEmail == buyerValue.buyerEmail &&
                                          (<div className="form-group col-md-10">
                                            <label>Buyer's Assigned Credit </label>
                                            <input type="text" className={" form-control" + (!error.assignValue ? '' : ' border-danger')} placeholder="" name="assignValue" value={buyerValue.assignValue} />
                                          </div>)
                                        )
                                      })}
                                    </div>
                                  }

                                  {creditlineDataBank.info.tab !== 'pendingTab' ?
                                    <div className="form-group col-md-6">
                                      <label>Remark </label>
                                      <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} onChange={handleChange} />
                                      {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
                                    </div>
                                    :
                                    <div className="form-group col-md-6">
                                      {JSON.parse(creditlineDataBank.info.allData.buyer_credit).data.map((buyerValue) => {
                                        return (
                                          value.buyerEmail == buyerValue.buyerEmail &&
                                          (<div className="form-group col-md-10">
                                            <label>Buyer's Assigned Remark </label>
                                            <input type="text" className={" form-control" + (!error.assignRemark ? '' : ' border-danger')} placeholder="" name="assignRemark" value={buyerValue.assignRemark} />
                                          </div>)
                                        )
                                      })}
                                    </div>
                                  }
                                  <br />
                                  <br />
                                </div>
                                {creditlineDataBank.info.tab !== 'pendingTab' &&
                                  <div className="form-group col-md-4">
                                    <button className="btn btn-success btn-sm" onClick={() => assignCredit(value)} >
                                      Assign Credit
                                    </button>
                                  </div>
                                }

                                <hr className="pt-1 pb-1" />
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* 4th section */}

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    {creditlineDataBank.info.tab === "pendingTab" ?
                      <h3 className="green mt-3">
                        Financial Details
                      </h3>
                      :
                      <h3 className="green mt-3">
                        Financial Detail & Documents
                      </h3>
                    }
                    <hr />
                  </div>
                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <div className="table-responsive">
                    <DataTable
                      columns={fincolumns}
                      data={fintableData}
                      progressPending={loading}
                      pagination
                      paginationServer
                      onChangeRowsPerPage={handlefinPerRowsChange}
                      onChangePage={handlefinPageChange}
                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                      persistTableHead
                      expandableRows
                      expandableRowsComponent={<TableExpandedComponent />}
                      expandOnRowClicked
                      highlightOnHover
                      sortServer
                      striped
                      noHeader
                      customStyles={customStyles}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <br />
                  {/* {creditlineDataBank.info.tab !== 'pendingTab' &&
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <h3 className="green mt-3">
                          Uploaded Documents by Applicant
                    </h3>
                        <hr />
                      </div>
                      <div className="col-md-12">
                        <div className="row pt-2">
                          <div className="form-group col-md-6">

                            {JSON.parse(creditlineDataBank.info.allData.doc_id).map((value) => {
                              return (
                                <>
                                  <div className="modal-padding">
                                    <ul className="other-documents pb-0 mb-0">
                                      <li style={{ "min-width": "250px" }}>
                                        <label className="mb-0">
                                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                          {value.doc_name}
                                        </label>
                                      </li>
                                      <li style={{ "min-width": "200px" }}>
                                        <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                          () => callPreviewFileComponent(value.doc_id, "view")}>
                                          <i className="fa fa-eye" aria-hidden="true"></i>
                                        </button>
                                        <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                          () => callPreviewFileComponent(value.doc_id, "download")}>
                                          <i className="fa fa-download" aria-hidden="true"></i>
                                        </button>
                                      </li>
                                    </ul>
                                    <br />
                                    <br />
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 5th section */}

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Authorized Signatory
                    </h3>
                    <hr />
                  </div>
                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <div className="table-responsive">
                    <DataTable
                      columns={authorizedcolumns}
                      data={authorizedtableData}
                      progressPending={loading}
                      pagination
                      paginationServer
                      onChangeRowsPerPage={handleauthorizedPerRowsChange}
                      onChangePage={handleauthorizedPageChange}
                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                      persistTableHead
                      highlightOnHover
                      sortServer
                      striped
                      noHeader
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* 6th section */}

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Bank Details
                    </h3>
                    <hr />
                  </div>
                  <br />
                  <br />
                  <hr className="pt-1 pb-1" />

                  <div className="table-responsive">
                    <DataTable
                      columns={bankcolumns}
                      data={banktableData}
                      progressPending={loading}
                      pagination
                      paginationServer
                      onChangeRowsPerPage={handlebankPerRowsChange}
                      onChangePage={handlebankPageChange}
                      progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                      persistTableHead
                      highlightOnHover
                      sortServer
                      striped
                      noHeader
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 7th section */}

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Credit Line Offer
                    </h3>
                    <hr />
                  </div>

                  {/* a Section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Funding Details
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Lender Name {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={creditlineDataBank.info.allData.lendername} />
                                    :
                                    <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={creditlineDataBank.info.allData.company_name} />
                                  }
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Approved Finance Amount {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={creditlineDataBank.info.allData.approvedFinanceAmount} />
                                    :
                                    <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={data.approvedFinanceAmount} onChange={handleChange} />
                                  }
                                  {error.approvedFinanceAmount && <p className="text-danger error-contract">{error.approvedFinanceAmount}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Funding Percentage(%) {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={creditlineDataBank.info.allData.fundingPercentage} />
                                    :
                                    <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={data.fundingPercentage} onChange={handleChange} />
                                  }
                                  {error.fundingPercentage && <p className="text-danger error-contract">{error.fundingPercentage}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Funding Tenor {astrix}</label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={creditlineDataBank.info.allData.fundingTenor} />
                                    :
                                    <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={data.fundingTenor} onChange={handleChange} />
                                  }
                                  {error.fundingTenor && <p className="text-danger error-contract">{error.fundingTenor}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Due date calculation from</label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.duedateCalcFrom ? '' : ' border-danger')} placeholder="" name="duedateCalcFrom" value={creditlineDataBank.info.allData.duedateCalcFrom} />
                                    :
                                    <input type="date" className={" form-control" + (!error.duedateCalcFrom ? '' : ' border-danger')} placeholder="" name="duedateCalcFrom" value={data.duedateCalcFrom} onChange={handleChange} />
                                  }
                                  {error.duedateCalcFrom && <p className="text-danger error-contract">{error.duedateCalcFrom}</p>}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* b section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Interest Details
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Interest Rate {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={creditlineDataBank.info.allData.interestRate} />
                                    :
                                    <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={data.interestRate} onChange={handleChange} />
                                  }
                                  {error.interestRate && <p className="text-danger error-contract">{error.interestRate}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-md-12">Interest Collection {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.interestCollection ? '' : ' border-danger')} placeholder="" name="interestCollection" value={creditlineDataBank.info.allData.interestCollection} />
                                    :
                                    <div className="col-md-12">
                                      <select className="form-control" name="interestCollection" value={data.interestCollection} onChange={handleChange} >
                                        <option value="" selected>Select any one</option>
                                        <option value="deducted_upfront">Deducted upfront</option>
                                        <option value="later">Later</option>
                                      </select>
                                    </div>
                                  }
                                  {error.interestCollection && <p className="text-danger error-contract">{error.interestCollection}</p>}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3rd section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Finance Request Step Fees
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Percentage {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={creditlineDataBank.info.allData.finRequestPercentage} />
                                    :
                                    <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={data.finRequestPercentage} onChange={handleChange} />
                                  }
                                  {error.finRequestPercentage && <p className="text-danger error-contract">{error.finRequestPercentage}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-md-12">Collection {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.finRequestCollection ? '' : ' border-danger')} placeholder="" name="finRequestCollection" value={creditlineDataBank.info.allData.finRequestCollection} />
                                    :
                                    <div className="col-md-12">
                                      <select className="form-control" name="finRequestCollection" value={data.finRequestCollection} onChange={handleChange} >
                                        <option value="" selected>Select any one</option>
                                        <option value="upfront">Upfront</option>
                                        <option value="deductible">Deductible in first transaction</option>
                                      </select>
                                    </div>
                                  }
                                  {error.finRequestCollection && <p className="text-danger error-contract">{error.finRequestCollection}</p>}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* c section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Additional Fees Detail
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Additional Fee 1 {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.additionalFee ? '' : ' border-danger')} placeholder="" name="additionalFee" value={creditlineDataBank.info.allData.additionalFee} />
                                    :
                                    <input type="number" className={" form-control" + (!error.additionalFee ? '' : ' border-danger')} placeholder="" name="additionalFee" value={data.additionalFee} onChange={handleChange} />
                                  }
                                  {error.additionalFee && <p className="text-danger error-contract">{error.additionalFee}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Description 1 {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.additionalDescription ? '' : ' border-danger')} placeholder="" name="additionalDescription" value={creditlineDataBank.info.allData.additionalDescription} />
                                    :
                                    <input type="text" className={" form-control" + (!error.additionalDescription ? '' : ' border-danger')} placeholder="" name="additionalDescription" value={data.additionalDescription} onChange={handleChange} />
                                  }
                                  {error.additionalDescription && <p className="text-danger error-contract">{error.additionalDescription}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-md-12">Collection {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.additionalCollection ? '' : ' border-danger')} placeholder="" name="additionalCollection" value={creditlineDataBank.info.allData.additionalCollection} />
                                    :
                                    <div className="col-md-12">
                                      <select className="form-control" name="additionalCollection" value={data.additionalCollection} onChange={handleChange} >
                                        <option value="" selected>Select any one</option>
                                        <option value="deducted_upfront">Deducted upfront</option>
                                        <option value="later">Later</option>
                                      </select>
                                    </div>
                                  }
                                  {error.additionalCollection && <p className="text-danger error-contract">{error.additionalCollection}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Last Fee Charges(% or USD per day) {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.lastfeeCharges ? '' : ' border-danger')} placeholder="" name="lastfeeCharges" value={creditlineDataBank.info.allData.lastfeeCharges} />
                                    :
                                    <input type="text" className={" form-control" + (!error.lastfeeCharges ? '' : ' border-danger')} placeholder="" name="lastfeeCharges" value={data.lastfeeCharges} onChange={handleChange} />
                                  }
                                  {error.lastfeeCharges && <p className="text-danger error-contract">{error.lastfeeCharges}</p>}
                                </div>
                                <br />
                                <br />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* d section */}

                  {/* <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Platform Fees
                                    </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Platform Fee {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="number" className={" form-control" + (!error.platformFee ? '' : ' border-danger')} placeholder="" name="platformFee" value={creditlineDataBank.info.allData.platformFee} />
                                    :
                                    <input type="number" className={" form-control" + (!error.platformFee ? '' : ' border-danger')} placeholder="" name="platformFee" value={data.platformFee} onChange={handleChange} />
                                  }
                                  {error.platformFee && <p className="text-danger error-contract">{error.platformFee}</p>}
                                </div>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* e section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Other T&C
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                <div className="form-group col-md-6">
                                  <label>Sanction Country {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} placeholder="" name="sanctionCountry" value={creditlineDataBank.info.allData.sanctionCountry} />
                                    :
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} name="sanctionCountry" value={data.sanctionCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                        <option value="" selected>--Select country--</option>
                                        {/* <CountryList /> */}
                                        {
                                          countryData.length ? countryData.map((item, index) => {
                                            return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                          }) : ''
                                        }
                                      </select>
                                      {error.sanctionCountry && <p className="text-danger error-contract">{error.sanctionCountry}</p>}
                                    </div>
                                  }
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Sanction Port {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} placeholder="" name="sanctionPort" value={creditlineDataBank.info.allData.sanctionPort} />
                                    :
                                    <div className="col-md-12">
                                      <select className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} name="sanctionPort" value={data.sanctionPort} onChange={handleChange}>
                                        <option value="" selected>--Select loading port--</option>
                                        {portLoading.map((portLoading) => {
                                          return (
                                            <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                          )
                                        })}
                                      </select>
                                      {error.sanctionPort && <p className="text-danger error-contract">{error.sanctionPort}</p>}
                                    </div>
                                  }
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Commodities {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={creditlineDataBank.info.allData.commodities} />
                                    :
                                    <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={data.commodities} onChange={handleChange} />
                                  }
                                  {error.commodities && <p className="text-danger error-contract">{error.commodities}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Currency {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.currency ? '' : ' border-danger')} placeholder="" name="currency" value={creditlineDataBank.info.allData.currency.split(':')[1]} />
                                    :
                                    <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency} onChange={handleChange} >
                                      <option value="" selected>--Select Currency--</option>
                                      {currencyData.map((currency) => {
                                        return (
                                          <option value={currency.id + ':' + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                                        )
                                      })}
                                    </select>
                                  }
                                  {error.currency && <p className="text-danger error-contract">{error.currency}</p>}
                                </div>

                                <div className="form-group col-md-6">
                                  <label>Offer Valid Till {astrix} </label>
                                  {creditlineDataBank.info.tab === "pendingTab" ?
                                    <input type="text" className={" form-control" + (!error.credit_validity ? '' : ' border-danger')} placeholder="" name="credit_validity" value={creditlineDataBank.info.allData.credit_validity} />
                                    :
                                    <input type="date" className={" form-control" + (!error.offerValidity ? '' : ' border-danger')} placeholder="" name="offerValidity" value={data.offerValidity} onChange={handleChange} />
                                  }
                                  {error.offerValidity && <p className="text-danger error-contract">{error.offerValidity}</p>}
                                </div>

                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* f section */}

                  <div className="accordionWrapper row pr-0 pt-0">
                    <div className="container-fluid accordionItem open">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h3 className="green mt-3">
                                Required Documents
                              </h3>
                              <hr />
                            </div>
                            <div className="col-md-12">
                              <div className="row pt-2">

                                {creditlineDataBank.info.tab === "pendingTab" &&
                                  <div className="form-group col-md-6">
                                    {JSON.parse(creditlineDataBank.info.allData.credit_docs).map((value) => {
                                      if (value.doc_name !== 'Framework') {
                                        return (
                                          <>
                                            <div className="modal-padding">
                                              <ul className="other-documents pb-0 mb-0">
                                                <li style={{ "min-width": "250px" }}>
                                                  <label className="mb-0" >
                                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                                    {value.doc_name}
                                                  </label>
                                                </li>
                                                <li style={{ "min-width": "200px" }}>
                                                  <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                                    () => callPreviewFileComponent(value.doc_id, "view")}>
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                  </button>
                                                  <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                                    () => callPreviewFileComponent(value.doc_id, "download")}>
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                  </button>
                                                </li>
                                              </ul>
                                              <br />
                                              <br />
                                            </div>
                                          </>
                                        )
                                      }
                                    })}
                                  </div>
                                }

                                {creditlineDataBank.info.tab !== "pendingTab" &&
                                  <div>
                                    <div className="modal-padding" id={"Creditline:49"}>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                            Credit Line Document {astrix}
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={"Creditline:49"} onChange={handleFiles} />
                                            {error["Creditline:49"] && <p lassName="text-danger error-contract">{error["Creditline:49"]}</p>}
                                          </div>
                                        </li>
                                        <li style={{ "min-width": "200px" }}>
                                          {(data["Creditline:49"]) ? <div className="filePath text-left"><div className="file-name">{`${data["Creditline:49"].name}`}</div></div> : ""}
                                        </li>
                                      </ul>
                                      <br />
                                      <br />
                                    </div>

                                    <div className="modal-padding" id={"Framework:56"}>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                            Framework Document {astrix}
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={"Framework:56"} onChange={handleFiles} />
                                            {error["Framework:56"] && <p lassName="text-danger error-contract">{error["Framework:56"]}</p>}
                                          </div>
                                        </li>
                                        <li style={{ "min-width": "200px" }}>
                                          {(data["Framework:56"]) ? <div className="filePath text-left"><div className="file-name">{`${data["Framework:56"].name}`}</div></div> : ""}
                                        </li>
                                      </ul>
                                      <br />
                                      <br />
                                    </div>

                                    <div className="col-md-12">
                                      <label className="mb-0" >
                                        <b>You can add extra documents(If Any).</b>
                                      </label>
                                    </div>

                                    <div className="row pt-2">
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <div className="form-group col-md-6">
                                            <div className="col-md-12">
                                              <button className="btn btn-primary btn-sm" onClick={() => addDocumentRow()}>Add More Documents</button>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="form-group col-md-6">
                                            <div className="col-md-12">
                                              <label>(Note: You can add total 5 documents. Total Count left : {5 - (documentRow.data.length)})</label>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    {
                                      getDocumentRow()
                                    }

                                    {/* <div className="modal-padding" id={"Creditline ExtraDoc:49"}>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                          Extra Document(If Any)
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={"Creditline ExtraDoc:49"} onChange={handleFiles} />
                                            {error["Creditline ExtraDoc:49"] && <p lassName="text-danger error-contract">{error["Creditline ExtraDoc:49"]}</p>}
                                          </div>
                                        </li>
                                        <li style={{ "min-width": "200px" }}>
                                          {(data["Creditline ExtraDoc:49"]) ? <div className="filePath text-left"><div className="file-name">{`${data["Creditline ExtraDoc:49"].name}`}</div></div> : ""}
                                        </li>
                                      </ul>
                                      <br />
                                      <br />
                                    </div> */}
                                  </div>
                                }


                                <br />
                                <br />
                              </div>
                            </div>

                            {/* <div className="col-md-12">
                              <div className="row pt-2">
                                {creditlineDataBank.info.tab === "pendingTab" &&
                                  <div className="form-group col-md-6">

                                    {JSON.parse(creditlineDataBank.info.allData.doc_id).map((value) => {
                                      return (
                                        <>
                                          <div className="modal-padding">
                                            <ul className="other-documents pb-0 mb-0">
                                              <li style={{ "min-width": "250px" }}>
                                                <label className="mb-0">
                                                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                                  {value.doc_name}
                                                </label>
                                              </li>
                                              <li style={{ "min-width": "200px" }}>
                                                <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                                  () => callPreviewFileComponent(value.doc_id, "view")}>
                                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                                </button>
                                                <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                                  () => callPreviewFileComponent(value.doc_id, "download")}>
                                                  <i className="fa fa-download" aria-hidden="true"></i>
                                                </button>
                                              </li>
                                            </ul>
                                            <br />
                                            <br />
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                }
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                {creditlineDataBank.info.tab !== "pendingTab" &&
                  <div className="form-group col-md-4">
                    <button type="button" className="btn btn-success btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        {/* 8th Section */}

        {creditlineDataBank.info.tab === 'pendingTab' &&
          <div className="accordionWrapper row pr-0 pt-0">
            <div className="container-fluid accordionItem open">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="green mt-3">
                        Status for Credit Line
                      </h3>
                      <hr />
                    </div>
                    <br />
                    <br />
                    <hr className="pt-1 pb-1" />

                    <div className="col-md-12">
                      <div className="row">

                        <div className="col-md-4 ">
                          <label>Choose your Status for Credit Line</label>
                        </div>

                        <div className="row col-md-4 ">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="pendingStatus" id="pendingStatus1" value="1" onChange={handleChangePending} checked={pendingActionData.pendingStatus / 1 === 1 ? true : false} />
                            <label className="form-check-label text-danger" for="pendingStatus1">Rejected</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="pendingStatus" id="pendingStatus2" value="2" onChange={handleChangePending} checked={pendingActionData.pendingStatus / 1 === 2 ? true : false} />
                            <label className="form-check-label text-success" for="pendingStatus2">Approved</label>
                          </div>
                        </div>
                        <hr className="col-md-11 pt-1 pb-1" />
                        <div className="row col-md-12">
                          <label className="col-md-2">Comment</label>
                          <div className="col-md-10">
                            <textarea name="pendingComment" value={pendingActionData.pendingComment} placeholder="Enter Message" className="form-control" onChange={handleChangePending} />
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <button type="button" className="btn btn-success btn-sm" onClick={submitPendingStatus}  >Submit</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        }


      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}

      {
        showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />
      }

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LenderOffer)