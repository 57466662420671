import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

const AssignedCreditToBuyer = ({ userTokenDetails, bank, manualUserId, manualUserName, manualUserEmail }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [bankList, setBankList] = useState([]);
  const [accord, setaccord] = useState({
    0: false,
    1: false,
  });
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("");
  const [buyersDetail, setBuyersDetail] = useState([]);

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect

  useEffect(() => {

    let objectAPI = {
      "userId": manualUserId,
    }

    call("POST", "getBuyersDetail", objectAPI).then(async (result) => {
      console.log('result in getBuyersDetail  ', result);
      setBuyersDetail(result.buyerData);
    }).catch((e) => {
      console.log("error in getBuyersDetail", e);
    });

  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  function creditButton(value) {

    let userValue = false;

    if (value.buyers_credit == null) {
      return (<div className="col-md-12">
        <div className="row pt-2">
          <div className="form-group col-md-6">
            <label>Assign Credit </label>
            <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} disabled />
            {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
          </div>

          <div className="form-group col-md-6">
            <label>Remark </label>
            <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} disabled />
            {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
          </div>
          <br />
          <br />
        </div>

        <hr className="pt-1 pb-1" />
      </div>)
    }
    else {
      let buyersCreditList = JSON.parse(value.buyers_credit);
      for (let i = 0; i < buyersCreditList.length; i++) {
        if (buyersCreditList[i].lender_id == bank.id) {
          userValue = true;
        }
        if (userValue == true) {
          return (
            <div className="col-md-12">
              <div className="row pt-2">
                <div className="form-group col-md-6">
                  <label>Assigned Credit </label>
                  <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={buyersCreditList[i].assignValue} disabled />
                </div>

                <div className="form-group col-md-6">
                  <label>Remark </label>
                  <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={buyersCreditList[i].assignRemark} disabled />
                </div>
                <br />
                <br />
              </div>

              <hr className="pt-1 pb-1" />
            </div>
          )
        }

      }
      return (<div className="col-md-12">
        <div className="row pt-2">
          <div className="form-group col-md-6">
            <label>Assign Credit </label>
            <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.multiplebuyersCredit} disabled />
            {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
          </div>

          <div className="form-group col-md-6">
            <label>Remark </label>
            <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.multiplebuyersRemark} disabled />
            {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
          </div>
          <br />
          <br />
        </div>

        <hr className="pt-1 pb-1" />
      </div>)
    }
  }

  const handleChange = (event) => {
    event.persist();
    setData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    setError(errorData => ({ ...errorData, [event.target.name]: "" }))
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.checked }));
  };

  const handleSubmit = (event) => {

    let objectAPI = {
      "userId": manualUserId,
      "username": manualUserName,
      "data": data
    }

  };

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="accordionWrapper row pr-0 pt-0">
        <div className="container-fluid accordionItem open">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="green mt-3">
                    Buyer's List
                  </h3>
                  <hr />
                </div>

                <br />
                <br />
                <hr className="pt-1 pb-1" />

                <div className="col-md-12">
                  <div className="row pt-2">
                    <div className="form-group col-md-12">

                      {buyersDetail && buyersDetail.length > 0 && buyersDetail.map((value) => {
                        return (
                          <>
                            <div className="modal-padding">
                              <ul className="price-ul">
                                <li>
                                  <div className="price-left">{value.buyerName ? value.buyerName : ''}</div>
                                  <div className="price-right">Buyer Name </div>
                                </li>
                                <li>
                                  <div className="price-left">{value.buyerEmail ? value.buyerEmail : ''}</div>
                                  <div className="price-right">Buyer Email </div>
                                </li>
                                <li>
                                  <div className="price-left"> {value.buyerPhone ? value.buyerPhone : ''} </div>
                                  <div className="price-right">Contact No</div>
                                </li>
                                <li>
                                  <div className="price-left">{value.buyerAddress ? value.buyerAddress : ''}</div>
                                  <div className="price-right">Buyer Address</div>
                                </li>
                                <li>
                                  <div className="price-left">{value.buyerCountry ? value.buyerCountry : ''}</div>
                                  <div className="price-right">Country</div>
                                </li>
                                <li>
                                  <div className="price-left">{value.buyerDUNSNo ? value.buyerDUNSNo : ''}</div>
                                  <div className="price-right">DUNS Number</div>
                                </li>
                                <li>
                                  <div className="price-left"> {value.previousAnnualSale ? value.previousAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : ''}</div>
                                  <div className="price-right">Previous Sale</div>
                                </li>
                                <li>
                                  <div className="price-left"> {value.currentAnnualSale ? value.currentAnnualSale + ' ' + value.buyerCurrency.split(':')[1] : ''}</div>
                                  <div className="price-right">Expected Sale</div>
                                </li>
                                <li>
                                  <div className="price-left"> {value.productDetails ? value.productDetails : ''}</div>
                                  <div className="price-right">Product Details</div>
                                </li>
                              </ul>
                            </div>

                            {creditButton(value)}

                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedCreditToBuyer)