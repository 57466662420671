import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import Footer from '../partial/footer';
import { TabComponent } from '../CommonComponent/TabComponent';
import toastDisplay from '../../utils/toastNotification';
import QmsWindow from '../contractDetails/qms';
import { formatDateTime_Application } from '../../utils/dateFormaters';
import { decryptData } from '../../utils/myFunctions';
const queryString = require('query-string');

const useCaseLabels = ["Invoice Discounting", "LC Quotation"]
const astrix = <span className="required-field text-danger">*</span>

const CreateChatRoomPopup = ({ onClose, userTokenDetails }) => {

  const [data, setData] = useState({ useCase: 0 })
  const [errorData, seterrorData] = useState({})
  const [applicationIds, setApplicationIds] = useState([])
  const [partys, setPartys] = useState({})
  const [chatroomName, setChatRoomName] = useState("")

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  useEffect(() => {
    call('POST', 'getApplicationsByUseCase', { useCase: useCaseLabels[data.useCase], userId, userTypeId }).then((result) => {
      // console.log("getApplicationsByUseCase:=>", result);
      setApplicationIds(result)
    }).catch((e) => {
      // console.log("Error while querying getApplicationsByUseCase:", e);
    })

    if (data.applicationId) {
      call('POST', 'getPartyDetailsByApplicationId', { useCase: useCaseLabels[data.useCase], applicationId: data.applicationId }).then((result) => {
        // console.log("getPartyDetailsByApplicationId:=>", result);
        setPartys(result)
        setChatRoomName((data.applicationId + "/" + result.lenderName + "/" + result.exporterName).replace(/\s/g, "").toUpperCase())
      }).catch((e) => {
        // console.log("Error while querying getPartyDetailsByApplicationId:", e);
      })
    }
  }, [data.useCase, data.applicationId])


  const handleChange = e => {
    e.persist()
    if (e.target.name === "applicationId" && !e.target.value) {
      clearForm()
    }
    setData({ ...data, [e.target.name]: e.target.value })
    seterrorData({ ...errorData, [e.target.name]: "" })
  }

  function clearForm() {
    setPartys({ lenderName: '', exporterName: '', buyerName: '' });
    setChatRoomName("")
  }

  function onSubmit() {
    call('POST', 'createChatRoom', {
      chatroomName, includedUsers: partys.lender_id + "::" + partys.seller_id + "::" + partys.buyer_id,
      userId
    }).then((result) => {
      // console.log("createChatRoom:=>", result);
      toastDisplay(result, "success")
      onClose()
    }).catch((e) => {
      // console.log("Error while querying createChatRoom:", e);
      toastDisplay(e, "error")
      onClose()
    })
  }

  return (
    <div className={"modal show"} id="CreateContract">
      <div className="modal-dialog d-flex modal-xl border-inner" id="parent_class">
        <div className="modal-content">

          <div className="modal-header primary">
            <h4 className="modal-title text-white">
              Create Chat Room
            </h4>
            <button type="button" className="close" onClick={onClose}>×</button>
          </div>

          <div className="modal-body calc-modal">
            <div className="calc-inner-modal">
              <div className="d-flex">
                <div className="col-md-12 row m-0 p-0">


                  <div className="col-md-6">
                    <div className="col-md-12 pr-0">
                      <TabComponent title={"Select Use Case "} showAstrix tabs={useCaseLabels}
                        selectedTab={data.useCase} onTabChange={(index) => {
                          setData({ ...data, useCase: index, applicationId: "" });
                          clearForm()
                        }} />
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="col-md-5">
                      <h3>Select Application Id {astrix} </h3>
                    </div>
                    <div className="col-md-7 pr-0">
                      <select className={" form-control" + (!errorData.applicationId ? '' : ' border-danger')} name="applicationId" value={data.applicationId} onChange={
                        (event) => {
                          handleChange(event);
                        }}>
                        <option value="" selected>Select Application Id</option>
                        {
                          applicationIds.length ? applicationIds.map((item, index) => {
                            return (<option value={item.applicationId}>{item.applicationId}</option>)
                          }) : ''
                        }
                      </select>
                      {errorData.applicationId && <p className="text-danger error-contract ml-2">{errorData.applicationId}</p>}
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 text-center py-3">
                    <h3>Chat room will be created between below parties</h3>
                  </div>

                  <div className="col-md-4">
                    <div className="col-md-5">
                      <h3>Lender</h3>
                    </div>
                    <div className="col-md-10 pr-0">
                      <div className="form-group">
                        <input type="text" readOnly name="lenderName" placeholder="" value={partys.lenderName} className={"form-control" + (!errorData.lenderName ? '' : ' border-danger')} onChange={handleChange} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="col-md-5">
                      <h3>Exporter</h3>
                    </div>
                    <div className="col-md-10 pr-0">
                      <div className="form-group">
                        <input type="text" readOnly name="exporterName" placeholder="" value={partys.exporterName} className={"form-control" + (!errorData.exporterName ? '' : ' border-danger')} onChange={handleChange} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="col-md-5">
                      <h3>Buyer</h3>
                    </div>
                    <div className="col-md-10 pr-0">
                      <div className="form-group">
                        <input type="text" readOnly name="buyerName" placeholder="" value={partys.buyerName} className={"form-control" + (!errorData.buyerName ? '' : ' border-danger')} onChange={handleChange} />
                      </div>
                    </div>
                  </div>

                  {chatroomName && (
                    <div className="col-md-12 mt-4 text-center pt-4">
                      <h3>Chat room with ({chatroomName}) name will be created</h3>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer primary justify-content-end">
            <button type="button" className="btn btn-dark btn-sm align-self-start" onClick={onClose} id="previd">Cancel</button>
            <button disabled={!chatroomName} type="button" className="btn btn-info btn-sm" onClick={onSubmit} id="nextid">Create</button>
          </div>

        </div>
      </div>
    </div>
  )

}

const ChatRooms = ({ userTokenDetails, setDisbursed, disbursed, setFinanceDetails, financeDetails, navToggleState, dispatch }) => {

  // Read chat id from url & set bydefault
  let linkData = window.location.href.split("chatRoom?")

  const [showChatRoomPopup, toggleChatRoomPopup] = useState(false)
  const [chatRoomList, setChatRoomList] = useState([])
  const [data, setData] = useState({ chatRoomId: linkData[1] ? decryptData(linkData[1]) : "" })
  const [errorData, seterrorData] = useState({})
  const [chatData, setChatData] = useState([])
  const [showLoader, setshowLoader] = useState(false);

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  useEffect(() => {
    setshowLoader(true)
    call('POST', 'getChatRooms', { userId, userTypeId }).then((result) => {
      // console.log("getChatRooms:=>", result);
      setChatRoomList(result)
      setshowLoader(false)
    }).catch((e) => {
      // console.log("Error while querying getChatRooms:", e);
      setshowLoader(false)
    })
    getChat()
  }, [showChatRoomPopup, data.chatRoomId])

  function getChat() {
    if (data.chatRoomId) {
      setshowLoader(true)
      call('POST', 'getMessageByChatRoom', { chatRoomId: data.chatRoomId }).then((result) => {
        // console.log("getMessageByChatRoom:=>", result);
        setChatData(result)
        setshowLoader(false)
      }).catch((e) => {
        // console.log("Error while querying getMessageByChatRoom:", e);
        setshowLoader(false)
      })
    }
  }

  const handleChange = e => {
    e.persist()
    setData({ ...data, [e.target.name]: e.target.value })
    seterrorData({ ...errorData, [e.target.name]: "" })
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      sendMessage()
    }
  };

  function gettrailList() {
    const trailList = chatData ? chatData.map((messageData, index) => {
      return (
        <>
          {(messageData.msg_from !== userId) &&
            <div className="chat-item is-you">
              {messageData.msg_to / 1 == -1 ? (
                <time className="chat-label">{messageData.company_name + "  "}</time>
              ) : null}
              <time className="chat-label">{formatDateTime_Application(messageData.created_at)}</time>
              <p className="chat-message">{messageData.message}</p>
            </div>
          }
          {(messageData.msg_from === userId) &&
            <div className="chat-item is-me">
              <time className="chat-time">{formatDateTime_Application(messageData.created_at)}</time>
              <p className="chat-message">{messageData.message}</p>
            </div>
          }
        </>
      )
    }) : ''
    return trailList
  }

  function sendMessage() {
    setshowLoader(true)
    let selectedChatRoomDetails = chatRoomList.filter(item => {
      if (item.chat_room_id === data.chatRoomId) {
        return item
      }
    })[0]
    call('POST', 'sendMessageByChatRoom', {
      userId, userTypeId, chatRoomId: data.chatRoomId, message: data.message,
      includedUsers: selectedChatRoomDetails["included_users"],
      senderName: userName, chatRoomName: selectedChatRoomDetails["name"]
    }).then((result) => {
      // console.log("sendMessageByChatRoom:=>", result);
      toastDisplay(result, "success")
      setData({ ...data, message: "" })
      setshowLoader(false)
      getChat()
    }).catch((e) => {
      // console.log("Error while querying sendMessageByChatRoom:", e);
      setshowLoader(false)
    })
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {showChatRoomPopup &&
        <CreateChatRoomPopup onClose={() => toggleChatRoomPopup(false)} userTokenDetails={userTokenDetails} />
      }
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'chatRoom'} userTokenDetails={userTokenDetails} />

          <main role="main" className={"ml-sm-auto col-lg-10" + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <Header
              title={'Chat Room'}
              userTokenDetails={userTokenDetails}
              onButtonClick={() => { toggleChatRoomPopup(true) }}
            />

            <div className="col-md-12 mt-4">
              <div className="col-md-5">
                <h3>Select Chat Room {astrix} </h3>
              </div>
              <div className="col-md-12 pr-0">
                <select className={" form-control"} name="chatRoomId" value={data.chatRoomId} onChange={handleChange}>
                  <option value="" selected>Select Chat Room</option>
                  {chatRoomList.length && chatRoomList.map((item, index) => (
                    <option value={item.chat_room_id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>


            {data.chatRoomId ? <>
              <div className="chat-body">
                <div className="chat-contentv2">
                  {!chatData.length &&
                    <h3 className="m-2 text-center">No Chat History Found</h3>
                  }
                  {gettrailList()}
                </div>
              </div>
              <form className="chat-form" onSubmit={e => e.preventDefault()}>
                <input className="chat-form-inputv2" type="text" value={data.message}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress} placeholder="Enter your message" name="message" id="chatBoxInput" required />
                <button className="button" type="button" disabled={!data.message} onClick={sendMessage}>Send</button>
              </form>
            </> : null}

          </main>
        </div>
      </div>
      <Footer />
    </>
  )

}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps, null
)(ChatRooms)