import call from '../../service';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setShowViewProfileModal } from '../../store/actions/action';
import ReactCountryFlag from "react-country-flag";
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile'
import commImageUrl from '../../utils/makeCommImageUrl'


const ViewProfileDemo = ({ userTokenDetails, setShowViewProfileModal, showViewProfileModal, refresh, setrefresh, type, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  var [showLoader, setshowLoader] = useState(true);
  var [dbData, setdbData] = useState([]);
  const [dbTypeData, setdbTypeData] = useState([]);
  const [fileData, setfileData] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //API Calls
    call('POST', 'getSingleUser', { id: showViewProfileModal.info.id }).then(async (result) => {
      console.log("api result in admin modal view page-->", result)
      setdbData(result)
      setshowLoader(false)
    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while querying users:", e);
    })

    call('GET', 'getuserstypelist').then((result) => {
      console.log('result in getuserstypelist', result);
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleResToProfile = (id, status) => {
    // console.log("id->", id)
    call('post', 'updateUserStatus', { "id": id, "status": status }).then(async (result) => {
      setshowLoader(false)
      setShowViewProfileModal(false, { "refeshData": true })
      setrefresh(refresh + 1)
      //console.log("update status res ---> ", result)
      toastDisplay(result, "success")

    }).catch((e) => {
      setshowLoader(false)
      console.log("Error while update status:", e);
      toastDisplay(e, "error")
    })
  }

  function callPreviewFileComponent(id, action) {
    setfileData({
      "action": action,
      "tbl_doc_id": id,
    })
    setshowPreviewModal(true)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function removeUserFromNetwork(rmUserID) {
    call('post', 'removeuserfromnetwork', { "rmUserID": rmUserID, "loggedUserID": userId }).then((result) => {
      setshowLoader(false)
      setShowViewProfileModal(false, { "refeshData": true })
      toastDisplay(result, "success")
    }).catch((e) => {
      setshowLoader(false)
      console.log("Errorremoveuserfromnetwork:", e);
      toastDisplay(e, "error")
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  console.log("userTypeId->", userTypeId)

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Retun
  return (
    <>
      {showViewProfileModal.modal && <div className={"modal" + (showViewProfileModal.modal ? " show" : "")} id="ProfileDetails">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Profile Details</h4>
              <button type="button" className="close" onClick={() => { setShowViewProfileModal(false, {}) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="accordionItem open">
                <h2 className="accordionItemHeading" >Company Profile Of {(dbData && dbData.userType) ? dbData.userType.usertype_pretty_name : '--'} </h2>
                <div className="accordionItemContent">
                  <div className="divider border-0">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{(dbData && dbData.userDetails) ? dbData.userDetails.company_name : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Company Name</span></div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{(dbData && dbData.userDetails) ? dbData.userDetails.contact_person : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Contact Person</span></div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{(dbData && dbData.userDetails) ? dbData.userDetails.email_id : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Email</span></div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{(dbData && dbData.userDetails) ? dbData.userDetails.user_address : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Address</span></div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">
                            {(dbData && dbData.userDetails && dbData.userDetails.country_code) ? <span className="shadow">
                              <ReactCountryFlag
                                countryCode={dbData.userDetails.country_code}
                                style={{ width: '25px', height: '25px' }} svg />
                            </span> : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Country</span></div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row form-group">
                          <label className="col-md-12">{(dbData && dbData.userDetails) ? dbData.userDetails.contact_number : "--"}</label>
                          <div className="col-md-12 "><span className="date-format">Contact No</span></div>
                        </div>
                      </div>

                      {(dbData && dbData.userDetails && dbData.userDetails.cin_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.cin_no}</label>
                            <div className="col-md-12 "><span className="date-format">CIN NO</span></div>
                          </div>
                        </div>}
                      {(dbData && dbData.userDetails && dbData.userDetails.gst_vat_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.gst_vat_no}</label>
                            <div className="col-md-12 "><span className="date-format">GST No/VAT No</span></div>
                          </div>
                        </div>}
                      {(dbData && dbData.userDetails && dbData.userDetails.pan_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.pan_no}</label>
                            <div className="col-md-12 "><span className="date-format">PAN No</span></div>
                          </div>
                        </div>}
                      {(dbData && dbData.userDetails && dbData.userDetails.iec_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.iec_no}</label>
                            <div className="col-md-12 "><span className="date-format">IEC No</span></div>
                          </div>
                        </div>}
                      {(dbData && dbData.userDetails && dbData.userDetails.ifsc_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.ifsc_no}</label>
                            <div className="col-md-12 "><span className="date-format">IFSC Code</span></div>
                          </div>
                        </div>}
                      {(dbData && dbData.userDetails && dbData.userDetails.license_no) &&
                        <div className="col-md-4">
                          <div className="row form-group">
                            <label className="col-md-12">{dbData.userDetails.license_no}</label>
                            <div className="col-md-12 "><span className="date-format">License No</span></div>
                          </div>
                        </div>}


                    </div>
                  </div>
                </div>
              </div>
              <div className="accordionItem open">
                <h2 className="accordionItemHeading" >Domain Facility</h2>
                <div className="accordionItemContent">
                  <div className="divider border-0">
                    <div className="row">
                      {dbTypeData && dbTypeData.length > 0 && dbTypeData.map((item, key) => {
                        let domain_key = dbData.domain_key ? dbData.domain_key.split(',') : '';
                        let domain_name = [];
                        for (let i = 0; i < domain_key.length; i++) {
                          if (parseInt(domain_key[i]) === parseInt(item.id)) {
                            domain_name.push(item.usertype_pretty_name);
                            return (
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{domain_name.join(', ')}</label>
                                  <ul>
                                    <li>
                                      <img alt="description" height="60" src={"../assets/images/onbording/usertype/" + `${parseInt(domain_key[i])}.png`} />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          }
                        }
                      })
                      }
                    </div>
                  </div>
                </div>
              </div>
              {(userTypeId / 1) && <div>
                {/* Commodities Section */}
                {(dbData && (dbData.type_id === 14 || dbData.type_id === 4)) &&
                  <div className="accordionItem open">
                    <h2 className="accordionItemHeading"> Selected Commodities</h2>
                    <div className="accordionItemContent our-webcoderskull">
                      <ul className="row p-0">
                        {(dbData && dbData.commodities !== undefined) && dbData.commodities.map((commData) => {
                          return (
                            <>
                              <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight">
                                  <figure>
                                    <img src={commImageUrl(commData.commodityInfo.filename)} className="img-responsive" alt="" />
                                  </figure>
                                  <h3>
                                    {commData.commodityInfo ? commData.commodityInfo.commodity_pretty_name : '--'}
                                  </h3>
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                  </div>}
                {/* Documents Section */}
                {(dbData && dbData.docRelations && dbData.docRelations.length > 0) &&
                  <div className="accordionItem open">
                    <h2 className="accordionItemHeading">Documents </h2>
                    <div className="accordionItemContent our-webcoderskull">
                      <ul className="row p-0">
                        {(dbData && dbData.docRelations.length) && dbData.docRelations.map((docData) => {
                          return (
                            <>
                              <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight">
                                  <a onClick={
                                    () => callPreviewFileComponent(docData.userDocs.id, "view",)}
                                    target="_blank">
                                    <figure>
                                      <i class={"fas text-primary fa-5x m-3 " + ((docData.userDocs.file_name.split(".")[1] === "pdf") ? "fa-file-pdf" : "fa-file-image")}></i>
                                    </figure>
                                  </a>
                                  <h3>
                                    {docData.userDocs.doc_name ? docData.userDocs.doc_name : '--'}
                                  </h3>
                                  <p className="date-format">
                                    {docData.docMaster.document_name ? docData.docMaster.document_name : '--'}
                                  </p>
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                  </div>}
                {/* Bank Details Section */}
                {(dbData && dbData.bank && dbData.bank.length > 0) &&
                  <div className="accordionItem open">
                    <h2 className="accordionItemHeading">Bank Details </h2>
                    <div className="accordionItemContent">
                      {(dbData && dbData.bank !== undefined) && dbData.bank.map((bankData) => {
                        return (
                          <>
                            <div className="divider border-0">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.ac_no ? bankData.ac_no : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">Account Number</span></div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.bank_name ? bankData.bank_name : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">Bank Name</span></div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.acount_type ? bankData.acount_type : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">Account Type</span></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.account_alias ? bankData.account_alias : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">Account Name </span></div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.ifsc_code ? bankData.ifsc_code : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">IFSC Code</span></div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.swift_code ? bankData.swift_code : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">SWIFT Code</span></div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{bankData.beneficiary_name ? bankData.beneficiary_name : "--"}</label>
                                    <div className="col-md-12"><span className="date-format">Beneficiary Name</span></div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>}
              </div>}
            </div>
            <div className="modal-footer primary">
              {/* {type && type !== 'channel_partner' ?  */}
              <>
                {(dbData.status / 1 === 1 && (aclMap.mng_subusers && aclMap.mng_subusers.reject_user && aclMap.mng_subusers.reject_user.isExist)) ?
                  <button type="button" className="btn btn-danger btn-sm" onClick={
                    (dbData !== null && dbData.userDetails && dbData.id !== null) ?
                      () => { setshowLoader(true); handleResToProfile(dbData.id, 2); } :
                      toastDisplay("User Id not Availabe", "warn")}>
                    Reject
                  </button>

                  : (dbData.status / 1 === 2 && (aclMap.mng_subusers && aclMap.mng_subusers.accept_user && aclMap.mng_subusers.accept_user.isExist)) ?
                    <button type="button" className="btn btn-primary btn-sm" onClick={
                      (dbData !== null && dbData.userDetails && dbData.id !== null) ?
                        () => { setshowLoader(true); handleResToProfile(dbData.id, 1); } :
                        toastDisplay("User Id not Availabe", "warn")}>
                      Accept
                    </button>

                    : (dbData.status / 1 === 0) ? <>
                      {(aclMap.mng_subusers && aclMap.mng_subusers.accept_user && aclMap.mng_subusers.accept_user.isExist) &&
                        <button type="button" className="btn btn-primary btn-sm" onClick={
                          (dbData !== null && dbData.userDetails && dbData.id !== null) ?
                            () => { setshowLoader(true); handleResToProfile(dbData.id, 1); } :
                            toastDisplay("User Id not Availabe", "warn")}>
                          Accept
                        </button>}
                      {(aclMap.mng_subusers && aclMap.mng_subusers.reject_user && aclMap.mng_subusers.reject_user.isExist) &&
                        <button type="button" className="btn btn-danger btn-sm" onClick={
                          (dbData !== null && dbData.userDetails && dbData.id !== null) ?
                            () => { setshowLoader(true); handleResToProfile(dbData.id, 2); } :
                            toastDisplay("User Id not Availabe", "warn")}>
                          Reject
                        </button>}
                    </> : ""}
                <>
                  {(aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.mng_subusers_removeNetwork && aclMap.mng_subusers_profile.mng_subusers_removeNetwork.isExist) &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => removeUserFromNetwork(dbData.id)}>
                      Remove From Network
                    </button>}
                </>
              </>
              {/* // : <button type="button" className="btn btn-primary btn-sm" onClick={() => setShowViewProfileModal(false, {})}>
              //   Cancel
              // </button>} */}
            </div>
          </div>
        </div>
      </div>}
      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal} />}
    </>
  )
}



const mapStateToProps = state => {
  return {
    showViewProfileModal: state.showViewProfileModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProfileDemo)
