import React, { useEffect, useState } from 'react';
import call from "../../../service";
import { connect } from "react-redux";
import { setFinanceDetails, setChannelPartnerRaisedInvoice } from '../../../store/actions/action';
import toastDisplay from "../../../utils/toastNotification";
import toolTipText from '../../../utils/toolTips/financeForm.json'
import { formatDate_Application, dateFormatter_DatePicker } from '../../../utils/dateFormaters';
import FilePreview from '../../utilComponents/previewFile';
import AddSigningAuthority from '../../utilComponents/addSigningAuthority';
import DigitalSignDoc from '../../contractDocument/digitalSignDoc2';
import OtherDocs from "./otherDocs";
import validate from '../../../utils/validation_files/InvoiceFinanceValidationRules';
import config from '../../../config.json';
import DataTable from 'react-data-table-component';
import customStyles from '../../dataTablesColumsMap/customTableCss';
import columns from '../../dataTablesColumsMap/insuranceQuoteGrid';
import { ClearCache, convertImageToPdf, GetCache, modifi_currencies, most_used_currencies, SetCache } from '../../../utils/myFunctions';
import QmsWindow from '../../contractDetails/qms';
import FeesStructure from './feesStructure';
// import BankMaster from '../../../utils/bankMaster.json';


const InvoiceFinance = ({ userTokenDetails, setFinanceDetails, financeDetails, channelPartnerRaisedInvoice, setChannelPartnerRaisedInvoice, dispatch, viewOnly, buyerUserId }) => {
  let cache_data = financeDetails.info.action === "applyFinance" ? GetCache("invoice_form_data") : {}

  //---------------------------------------------------------------------------------------------------------------------
  // Const State and their handlers declaration and initiation.
  const [bankMaster, setbankMasters] = useState([]);
  const [saveDocResult, setSaveDocResult] = useState({});
  const [buyerArr, setBuyerArr] = useState([]);
  const [buyerType, setBuyerType] = useState(1);
  const [logiArr, setLogiArr] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [data, setData] = useState(cache_data["data"] || {});
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Application Form");
  const [fileData, setfileData] = useState({});
  const [fileDetail, setfileDetail] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [addPreviewModal, setaddPreviewModal] = useState(false);
  const [addFileData, setaddFileData] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [creditData, setCreditData] = useState([]);
  const [counterPart, setCounterPart] = useState([]);
  const [buyerCredit, setBuyerCredit] = useState(cache_data["buyerCredit"] || { modal: false, data: {} });
  const [userBank, setUserBank] = useState([]);
  const [accord, setAccord] = useState({
    0: false
  });
  const [insuranceData, setInsuranceData] = useState({ modal: false, data: {} });
  const [showQms, setShowQms] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [showFees, toggleShowFees] = useState(false);
  const [feesData, setFeesData] = useState({});

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userIdentifier = userTokenDetails.identifier ? userTokenDetails.identifier : null
  const astrix = <span className="required-field text-danger">*</span>

  let isLenderModifi = data.lenderName ? data.lenderName.toLowerCase().includes("modifi") : false
  // console.log('bankMaster ', bankMaster)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    setAclAuthModal(false)

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      // console.log('error in getallCountry', e);
    })

    if (financeDetails.info.tab) {
      setTab(financeDetails.info.tab)
    }

    if (financeDetails.info.action === "viewFinance") {
      setshowLoader(true)
      call('POST', 'getDiscounting', { id: financeDetails.info.FinanceID, type: financeDetails.type }).then((result) => {
        console.log('Invoice Get Discounting API  =====>', result);

        let poDbData = {
          id: result.id,
          applicantEmail: result.sellerName,
          buyerId: result.buyer_id / 1 === 0 ? "ME" : result.buyer_id,
          buyerName: result.buyer_id / 1 === 0 ? "manual" : result.buyerName,
          sellerId: result.seller_id,
          sellerName: result.sellerName,
          sellerEmail: result.sellerEmail,
          invoiceRefrenceNo: result.reference_no,
          poRefrenceNo: result.po_no,
          invoiceIssueDate: result.issue_date ? dateFormatter_DatePicker(result.issue_date) : "",
          invoiceDueDate: result.due_date ? dateFormatter_DatePicker(result.due_date) : "",
          currency: result.currency,
          currencyName: result.amountCurrencyName,
          contractAmount: result.contract_amount,
          reqAmount: result.amount,
          creditDays: result.credit_days,
          interestRate: result.interest_rate,
          logisticId: result.logistics === null ? "NULL" : result.logistics,
          logisticName: result.shipperName === null ? "Not Available" : result.shipperName,
          grnDate: result.grn_date ? dateFormatter_DatePicker(result.grn_date) : "",
          lenderId: result.lender_id,
          lenderName: result.lenderName,
          status: result.status,
          sellerStatus: result.seller_status,
          lenderStatus: result.lender_status,
          type: result.type,
          doc: result.docData.docList,
          exhibitDocList: result.docData.exhibitDocList,
          exhibitStatus: result.exhibit_sign_status,
          noaSignedByBuyer: result.noa_signedBy_buyer,
          noaSignedBySupplier: result.noa_signedBy_supplier,
          NOADocList: result.docData.NOADocList,
          frameworkDocList: result.docData.frameworkDocList,
          dnc: "on",
          fintnc: result.fin_tnc,
          buyerNameManual: result.offline_data.buyer_name,
          buyerAddrManual: result.offline_data.buyer_addr,
          logisticNameManual: result.offline_data.logistic_name,
          noa_signedBy_buyer: result.noa_signedBy_buyer,
          noa_signedBy_supplier: result.noa_signedBy_supplier,
          bankListId: result.bankList.split(':')[0],
          bankListName: result.bankList.split(':')[1],
          nameAuthorizedSignatory: result.nameAuthorizedSignatory,
          buyerPosition: result.buyerPosition,
          buyerContact: result.buyerContact,
          buyerEmail: result.buyerEmail,
          shipmentOriginCountry: result.shipment_origin_country,
          shipmentOriginAddress: result.shipment_origin_address,
          shipmentOriginCity: result.shipment_origin_city,
          shipmentOriginPostalCode: result.shipment_origin_postal_code,

          shipmentDestinationCountry: result.shipment_destination_country,
          shipmentDestinationAddress: result.shipment_destination_address,
          shipmentDestinationCity: result.shipment_destination_city,
          shipmentDestinationPostalCode: result.shipment_destination_postal_code,

          financingTerm: result.financing_terms_days
        }

        setBuyerArr([{
          id: result.buyer_id,
          company_name: result.buyerName
        }])
        setBuyerType(result.offline_data.buyer_type ? result.offline_data.buyer_type : 1)
        setbankMasters([{
          id: result.lender_id,
          company_name: result.lenderName
        }]);

        setAccord({
          0: true
        });

        setAmount(result.amount)
        setData({ ...poDbData, userId: userId })
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log('error in getDiscounting', e);
      })

    } else {
      setData({ ...data, userId: userId, applicantEmail: userEmail });
    }

    // Get currency list from Master table.
    call("get", "getcurrencylist").then((result) => {
      setcurrencyData(result);
    }).catch((e) => {
      console.log("error in getcurrencylist", e);
    });

    if (financeDetails.info.action === "applyFinance") {

      // Get bank master list that are involved in trade finance.
      call("GET", "getBanksListMaster")
        .then((result) => {
          setbankMasters(result);
        })
        .catch((e) => {
          console.log("Error while querying getBanksListMaster:", e);
        });

      call('POST', 'getUsersListing', { "searchUserType": 5, "searchKey": '', "network": "innetwork", "userAvatars": false })
        .then((result) => {
          // console.log("result====>", result)
          setLogiArr([...result]);
        })
        .catch((e) => {
          console.log("error in getcurrencylist", e);
        });

      call('POST', 'getUsersListing', { "searchUserType": 10, "searchKey": '', "network": "innetwork", "userAvatars": false })
        .then((result) => {
          // console.log("result====>", result)
          setLogiArr([...result]);
        })
        .catch((e) => {
          console.log("error in getcurrencylist", e);
        });
    }

    let objectAPI = {
      "manualUserId": userId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": "userapproved"
    }

    objectAPI = { ...objectAPI }


    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      setCreditData(result.finData);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
    });

    // setshowLoader(true)
    // call('POST', 'getFinanceDocList', { "userId": userId, "userTypeId": userTypeId }).then((result) => {
    //   console.log("doc data in doc details page-->", result)
    //   setdbDocData(result);
    //   setshowLoader(false);
    // }).catch((e) => {
    //   console.log("Error while querying Doc:", e);
    //   setshowLoader(false)
    // })

    let buyerObjectAPI = {
      "userId": userId,
      "userTypeId": userTypeId
    }

    buyerObjectAPI = { ...buyerObjectAPI }

    call('POST', 'getBuyersDetail', buyerObjectAPI).then((result) => {
      console.log('running getBuyersDetail api -->', result.buyerData);
      setCounterPart(result.buyerData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    })

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      console.log('running getUserBanks api -->', result);
      setUserBank(result);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (financeDetails.info.action === "applyFinance") {
      SetCache('invoice_form_data', { data, buyerCredit })
    }
  }, [data, buyerCredit])

  useEffect(() => {

    //------------------------------------------------------------------
    fetchTableData(1, { userId, type_id: userTypeId });
    //------------------------------------------------------------------
  }, [insuranceData.modal === true]);

  useEffect(() => {
    if (financeDetails.info.action === "applyFinance") {

      if (buyerType === 1) {
        call('POST', 'getUsersListing', { "searchUserType": 3, "searchKey": '', "network": "innetwork", "userAvatars": false })
          .then((result) => {
            setBuyerArr(result);
            setshowLoader(false)
          })
          .catch((e) => {
            console.log("error in getUsersListing", e);
          });
      } else if (buyerType === 2) {
        call('POST', 'getuserdirectorybytype', { "searchUserType": 3, "searchKey": '' })
          .then((result) => {
            console.log("result in getuserdirectorybytype", result);
            setBuyerArr(result);
            setshowLoader(false)

          })
          .catch((e) => {
            console.log("error in getUsersListing", e);
          });
      }
    }
  }, [buyerType]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting && buyerCredit.data.limit != "No values assigned to buyer") {
      if (tab === 0) {
        setTab(1)
      }
      else {
        if (isLenderModifi) {
          submitInvoiceFinanceForm()
        }
        else {
          setTab(0)
          forward()
        }
      }
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error, buyerCredit]);

  useEffect(() => {
    if (saveDocResult.hasOwnProperty('doc_id') && saveDocResult.doc_id) {
      console.log("saveDocResult====>", saveDocResult)
      setData({ ...data, [saveDocResult.doc_name]: saveDocResult.doc_id, [saveDocResult.doc_name + '_id']: saveDocResult.authorityId, [saveDocResult.doc_name + '_email']: saveDocResult.authorityEmail })
    }
  }, [saveDocResult]);

  useEffect(() => {
    if (data.lenderName && data.buyername) {
      setBuyerCredit({ modal: true, data: { limit: 'No values assigned to buyer' } })
      counterPart.map((bank) => {
        if (data.buyerId == bank.id && bank.buyers_credit != null) {
          let buyerDetail = JSON.parse(bank.buyers_credit);
          buyerDetail.map((buyer) => {
            if (data.lenderId == buyer.lender_id) {
              setBuyerCredit({ modal: true, data: { limit: buyer.assignValue + ' ' + (bank.currency ? bank.currency.split(':')[1] : '') } })
            }
          })
        }
      })
    }
  }, [data])
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // console.log("data====>", data)
  // console.log("financeDetails====>", financeDetails)
  // console.log("docId====>", docId)
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getLoans', objectAPI).then((result) => {
      console.log('running getLoans api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      // setCount(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getLoans', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI }

    call('POST', 'getLoans', objectAPI).then((result) => {
      // console.log('running getLoans api-->', result);
      settableData(formatDataFortable(result.data))
      setTotalRows(result.count)
      // setCount(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getLoans', e);
      setLoading(false)
    })
  };

  const handleSort = (column, sortDirection) => {
    // setFilterData({
    //   ...filterData,
    //   "sortColum": column.selector,
    //   "sortDirection": sortDirection
    // })
  };

  //---------------------------------------------------------------------------------------------------------------------
  const handleChange = (event) => {
    event.persist();
    if (event.target.name === "buyerId") {
      let val = event.target.value.split(':');
      let buyerSelected = counterPart.filter(item => {
        if (item.id == val[0]) {
          return item
        }
      })[0]
      let buyerEmail = buyerSelected && buyerSelected["buyerEmail"] ? buyerSelected["buyerEmail"] : ""
      // let shipmentDestinationCountry = buyerSelected && buyerSelected["buyerCountry"] ? buyerSelected["buyerCountry"] : ""
      // let shipmentDestinationAddress = buyerSelected && buyerSelected["buyerAddress"] ? buyerSelected["buyerAddress"] : ""
      setData({
        ...data, [event.target.name]: (val[0] === "ME" ? val[0] : +val[0]), buyername: val[1], buyerEmail
      });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "currency") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: val[0], currencyName: val[1] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "lenderId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], lenderName: val[1], lenderEmail: val[2] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "logisticId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], logisticName: val[1] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === 'bankListId') {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], bankListName: val[1] });
      setError({ ...error, [event.target.name]: "" });
    } else if (event.target.name === "dnc") {
      if (data.dnc === "on") {
        setData({ ...data, "dnc": "" });
      } else {
        setData({ ...data, [event.target.name]: event.target.value });
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
      setError({ ...error, [event.target.name]: "" });
    }
  };

  const handleFiles = (event) => {
    event.persist();
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
      toastDisplay('Files with pdf, png & jpeg extension are allowed', 'info')
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (!file_type.includes("pdf")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
        // console.log("response==>", response);
      }
      setFile({ ...file, [event.target.name]: fileObj, [event.target.name + "dataUrl"]: fileDataUrl });
      setData({ ...data, [event.target.name]: fileObj })
      setError({ ...error, [event.target.name]: "" });
      setfileDetail({
        "file_name": event.target.name,
        "buyerId": data.buyerId,
        "buyerEmail": data.buyerEmail,
        "buyerName": data.buyerName,
        "lenderId": data.lenderId,
        "lenderEmail": data.lenderEmail,
        "lenderName": data.lenderName,
        "file_data": { [event.target.name]: fileObj }
      });
      setaddFileData(fileDataUrl);
      setaddPreviewModal(true);
    }
  };

  const prehandleForward = (event) => {
    if (event) event.preventDefault();
    data['selectedBuyerLimit'] = (typeof (buyerCredit.data) != 'string') ? JSON.stringify(buyerCredit.data) : buyerCredit.data
    setError(validate(data, file, financeDetails, userTypeId, tab, isLenderModifi));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  // Handler for next button.
  function forward() {
    setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) + 1, financeDetails.info)
  }

  // Handler for back button.
  function backward() {
    if (financeDetails.page === 1) {
      if (financeDetails.info.action === "viewFinance") {
        if (userTypeId == 20) {
          setChannelPartnerRaisedInvoice(false, {})
        }
        setFinanceDetails(false, 0, 0, {})
      } else {
        setFinanceDetails(financeDetails.status, 0, 0, financeDetails.info)
      }
    } else if (financeDetails.page === 2 && financeDetails.info.sellerStatus === 2 && financeDetails.info.lenderStatus === 2) {
      setFinanceDetails(false, 0, 0, {})
    } else {
      setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) - 1, financeDetails.info)
      setIsSubmitting(false)
      setFeesData({})
      toggleShowFees(false)
    }
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render =>", values)
      let hasBuyerSigned = false
      if (viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(buyerUserId) != -1) {
        hasBuyerSigned = true
      }

      values.tbl_doc_id = values.id
      values.financeId = values.id
      values.document_name = values.doc_name
      values.uploaded_by = values.createdBy
      values.uploaded_on = values.created_at
      values.signType = 'withoutBlockChain'

      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
        </td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">
          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          {userTypeId != 8 &&
            <button
              disabled={hasBuyerSigned || (!viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1)}
              title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                () => callAclAuthComponent(values)}>
              <i class="fas fa-file-signature"></i>
            </button>
          }

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function docUploadPreview(type) {
    return (<tr>
      <td>1</td>
      <td>NA</td>
      <td>NA</td>
      <td>NA</td>
      <td>NA</td>
      <td className="row justify-content-center  mt-0 pt-0 m-0 ">
        <div className="file-browse ">
          <button title="Update File" className="text-center btn btn-success btn-sm m-1">
            <i className="fa fa-upload" aria-hidden="true"></i>
          </button>
          <input type="file" accept=".png,.jpg,.pdf" name={type == 'Exhibit' ? 'ExhibitDocument' : 'NOADocument'} onChange={(event) => updateDocument(event, type)} />
        </div>
      </td>
    </tr>)
  }

  function docAgreementPreview(type) {

    if (type == 'Exhibit') {
      let exhibitDocElements = data.exhibitDocList.length ? data.exhibitDocList.map((values, index) => {
        // console.log("value in doc render =>", values)

        let hasBuyerSigned = false
        if (viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(buyerUserId) != -1) {
          hasBuyerSigned = true
        }

        values.reqId = data.id
        values.financeId = data.id
        values.tbl_doc_id = values.id
        values.document_name = values.doc_name
        values.uploaded_by = values.createdBy
        values.uploaded_on = values.created_at
        values.signType = 'withoutBlockChain'

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {!viewOnly && userTypeId != 8 &&
              <button
                disabled={hasBuyerSigned || (!viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1)}
                title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(values)}>
                <i class="fas fa-file-signature"></i>
              </button>}

          </td>
        </tr>)
      }) : ''
      return (exhibitDocElements)
    }

    if (type == 'NOA') {
      let NOADocElements = data.NOADocList.length ? data.NOADocList.map((values, index) => {
        // console.log("value in doc render =>", values)

        let hasBuyerSigned = false
        if (viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(buyerUserId) != -1) {
          hasBuyerSigned = true
        }

        values.reqId = data.id
        values.financeId = data.id
        values.tbl_doc_id = values.id
        values.document_name = values.doc_name
        values.uploaded_by = values.createdBy
        values.uploaded_on = values.created_at
        values.signType = 'withoutBlockChain'
        values.type = 'OTPVerification'
        values.finType = 3

        return (<tr>
          <td>{1}</td>
          <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
          <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
          <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
          <td>
            {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
          </td>
          <td className="row justify-content-center  mt-0 pt-0 m-0 ">

            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            {userTypeId != 8 &&
              <button
                disabled={hasBuyerSigned || (!viewOnly && values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1)}
                title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(values)}>
                <i class="fas fa-file-signature"></i>
              </button>}

          </td>
        </tr>)
      }) : ''
      return (NOADocElements)
    }

    if (type == 'Framework') {
      let FrameworkDocElements = data.frameworkDocList.length ? data.frameworkDocList.map((values, index) => {

        if (values.lendername == data.lenderName) {

          console.log("value in doc render =>", values);
          let valueData = JSON.parse(values.frameworkDocList);

          valueData.reqId = data.id
          valueData.tbl_doc_id = valueData.id
          valueData.document_name = valueData.doc_name
          valueData.uploaded_by = values.lenderName
          valueData.uploaded_on = valueData.created_at
          valueData.signType = 'withoutBlockChain'

          return (<tr>
            <td>{1}</td>
            <td>{(valueData && valueData.doc_name) ? valueData.doc_name : 'NA'}</td>
            <td>{(data.lenderName) ? data.lenderName : 'NA'}</td>
            <td>{(valueData && valueData.created_at) ? formatDate_Application(valueData.created_at) : 'NA'}</td>
            <td>
              {(valueData && valueData.sign) ? <pre><span className="text-success mr-1">{valueData.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
            </td>
            <td className="row justify-content-center  mt-0 pt-0 m-0 ">

              <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                () => callPreviewFileComponent(valueData, "view")}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
              <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                () => callPreviewFileComponent(valueData, "download")}>
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              {userTypeId == 4 || userTypeId == 14 || userTypeId == 19 &&
                <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
                  () => callAclAuthComponent(valueData)}>
                  <i class="fas fa-file-signature"></i>
                </button>}

            </td>
          </tr>)
        }
      })
        : ''
      return (FrameworkDocElements)
    }

  }

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function callPreviewFileComponent(values, action) {
    // console.log("offlinefileData", values);
    if (values.base64 && values.file_data) {
      setfileData({ tbl_doc_id: values.id, "action": action, "file_data": values.file_data, "base64": values.base64 })
    }
    else {
      setfileData({ tbl_doc_id: values.id, "action": action })
    }
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  function updateDocument(event, type) {

    let formData = new FormData();
    formData.append('id', data.id);
    formData.append('finType', 3);
    formData.append('uploadType', type);
    formData.append(type == 'Exhibit' ? 'ExhibitDocument' : 'NOADocument', event.target.files[0]);
    call('POST', 'uploadFinAgreement', formData).then((result) => {
      if (result) {
        toastDisplay("File Uploaded", "success");
        setrefresh(refresh + 1);
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Submit handlers

  const submitInvoiceFinanceForm = (event) => {

    setshowLoader(true)
    const currentRoute = "applyInvoiceDiscounting"// = "applyLCFinance"
    data.sellerId = userId

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null
    data.baseUrl = config.baseUrl;
    data.applicantName = userName;

    let formData = new FormData();

    let tempOffline = { buyer_type: buyerType }
    if (data.buyerId === "ME") {
      tempOffline = { ...tempOffline, buyer_name: data.buyerNameManual, buyer_addr: data.buyerAddrManual }
    }
    if (data.logisticName == "Others") {
      tempOffline = { ...tempOffline, logistic_name: data.logisticNameManual }
    }
    formData.append("offline_data", JSON.stringify(tempOffline));

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })


    formData.append('userEmail', userEmail);

    let selectedBuyer = counterPart.filter(i => {
      if (i.id === data.buyerId) {
        return i
      }
    })[0]
    let selectedBank = userBank.filter(i => {
      if (i.id === data.bankListId) {
        return i
      }
    })[0]
    formData.append("userIdentifier", userIdentifier)
    formData.append("buyerIdentifier", selectedBuyer["identifier"])
    formData.append("incoTerms", selectedBuyer["incoTerms"])
    formData.append("bankAccountNo", selectedBank["beneficiaryAccountNo"])

    if (isLenderModifi) {
      formData.append("showFees", showFees)
      formData.append("quoteId", feesData.quoteId)
    }

    call("post", currentRoute, formData)
      .then((result) => {
        // console.log("apiresult================>", result);
        setshowLoader(false)
        if (isLenderModifi && !showFees) {
          toastDisplay("Kindly check fees structure to continue", "info");
          setFeesData(result)
          toggleShowFees(true)
          setTab(0)
          forward()
        }
        else {
          toastDisplay("Loan Applied successfuly", "success");
          ClearCache('invoice_form_data')
          setFinanceDetails(false, 0, 0, {})
        }
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in create finance modal", e);
      });
  };

  // Accept Po Discounting Call
  function acceptInvoiceDiscounting(party) {
    call("POST", "acceptInvoiceDiscounting", { party: userTypeId, id: data.id, manualUserEmail: userEmail })
      .then((result) => {
        toastDisplay("Request Accepted successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        toastDisplay(e, "error");
        console.log("error in acceptPO", e);
      });
  }

  // Update Finance form
  function updateDiscounting() {

    setshowLoader(true)
    delete data.doc
    // console.log('data', data);
    // console.log('file', file);

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("invoiceRefrenceNo", data.invoiceRefrenceNo);
    formData.append("reqAmount", data.reqAmount);
    formData.append("creditDays", data.creditDays);
    formData.append("interestRate", data.interestRate);
    formData.append("fintnc", data.fintnc);
    formData.append("otherDocMetaData", data.otherDocMetaData);
    formData.append("sellerId", data.sellerId);
    formData.append("lenderId", data.lenderId);
    formData.append("buyerId", data.buyerId);

    Object.keys(file).forEach((key) => {
      formData.append(key, file[key]);
    })

    call("POST", "updateInvoiceDiscounting", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay("Discounting Updated successfuly", "success");
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in updateInvoiceDiscounting", e);
      });
  }

  const NavigationButtons = ({ showNextButton }) => {
    return (
      <div className="col-md-12">
        {(financeDetails.type === 3 && ((tab === 0 && showNextButton) || financeDetails.page < 2)) ?
          <button type="button" className="btn btn-success float-right btn-sm"
            onClick={() => { prehandleForward() }}>
            Next<i className="fa fa-chevron-right pl-2" aria-hidden="true"></i>
          </button> :

          <>
            {
              data.status / 1 == 4 ?
                <button type="button" className="btn btn-success border border-success float-right btn-sm" disabled>
                  <b> Disbursed </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                </button> :
                data.status / 1 == 5 ?
                  <button type="button" className="btn btn-danger border border-success float-right btn-sm" disabled>
                    <b> Declined </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  </button>
                  :
                  data.sellerStatus === 1 && data.lenderId === userId && data.lenderStatus === 2 ?
                    <button type="button" className="btn btn-success border border-success float-right btn-sm" disabled>
                      <b> Approved By You </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button> :

                    data.sellerStatus === 1 && data.lenderStatus === 2 ?
                      <button type="button" className="btn btn-success border border-success float-right btn-sm" disabled>
                        <b> Approved </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                      </button> :

                      ((amount / 1 === data.reqAmount / 1) && data.lenderId === userId && data.exhibitStatus == 1 && data.noaSignedByBuyer == 1 && data.noaSignedBySupplier == 1) ?
                        <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={() => acceptInvoiceDiscounting()}>
                          <b> Approve </b><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        </button> :


                        ((data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 20) && financeDetails.info.action === "viewFinance" && data.sellerId === userId) ?
                          <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                            <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                          </button> :

                          ((data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 20) && financeDetails.info.action === "viewFinance" && data.lenderId === userId) ?
                            <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                              <b> Send Offer </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                            </button> :

                            (userTypeId != 20 &&
                              <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={submitInvoiceFinanceForm} disabled={data.dnc === "on" ? false : true} > <b> Apply </b>
                                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                              </button>
                            )
            }
          </>

          // data.sellerStatus === 2 && data.lenderStatus === 2 ?
          //   <button type="button" className="btn btn-success border border-success float-right btn-sm" disabled>
          //     <b> Approved </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //   </button> :

          //   ((data.sellerStatus === 2 && data.sellerId === userId && data.lenderStatus === 1) || (data.sellerStatus === 1 && data.lenderId === userId && data.lenderStatus === 2)) ?
          //     <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" disabled>
          //       <b> Approved By You</b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //     </button> :

          //     ((data.sellerStatus === 1 && data.sellerId === userId && data.status === 3 && userTypeId != 20) || (data.lenderStatus === 1 && data.lenderId === userId && data.status === 3 && data.sellerStatus === 2 && data.noa_signedBy_buyer == 1 && data.noa_signedBy_supplier == 1 && userTypeId != 20)) ?
          //       <>
          //         {((amount / 1 === data.reqAmount / 1) && data.exhibitStatus == 1 && data.noaSignedByBuyer == 1 && data.noaSignedBySupplier == 1) ?
          //           <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={() => acceptInvoiceDiscounting()}>
          //             <b> Approve </b><i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //           </button> :
          //           <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
          //             <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //           </button>
          //         }
          //         {(data.sellerStatus === 1 && data.lenderStatus === 1) &&
          //           <>
          //             {userTypeId == 8 ?
          //               <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
          //                 <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //               </button>
          //               :
          //               <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={acceptInvoiceDiscounting}>
          //                 <b> Approve </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //               </button>}

          //           </>
          //         }
          //       </> :

          //       ((data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 20) && financeDetails.info.action === "viewFinance" && data.sellerId === userId) ?
          //         <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
          //           <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //         </button> :

          //         ((data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 20) && financeDetails.info.action === "viewFinance" && data.lenderId === userId) ?
          //           <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
          //             <b> Send Offer </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //           </button> :

          //           (userTypeId != 20 &&
          //             <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={submitInvoiceFinanceForm} disabled={data.dnc === "on" ? false : true} > <b> Apply </b>
          //               <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          //             </button>
          //           )
        }



        {(financeDetails.type === 3 && financeDetails.page > 0) &&
          <button type="button" className="btn btn-danger float-left btn-sm"
            onClick={() => { tab === 1 ? setTab(0) : backward() }}>
            <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
          </button>}
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------

  // JSX return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {/* {showFees ? <FeesStructure
        fees={feesData.fees}
        onCancel={() => {
          toastDisplay("You have to accept fees structure to apply for finance", "error")
          toggleShowFees(false)
        }} onAccept={submitInvoiceFinanceForm} /> : null} */}
      <div className="creditline_listing">
        {/* <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
          <li>
            <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
              setTab(0);
              setTabName("Application Form");
            }}>Application Form</a>
          </li>
          <li>
            <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
              setTab(1);
              setTabName("Insurance Form");
            }}>Insurance Form</a>
          </li>
        </ul> */}

        <>
          {/* {tab === 0 &&
            <> */}
          {/* {financeDetails.info.action === "viewFinance" && (
            <>
              <div className="chat-button" onClick={() => { setShowQms(true) }}><i className="fa fa-comments-o" aria-hidden="true"></i></div>
              <div className="circle-rippl"></div>
              {showQms &&
                <>
                  <div className="chat-box" id="chatBox" Style="display: block">
                    <div className="chat-header">
                      <h1 className="chat-title">QMS</h1>
                      <div className="chat-btn-bg">
                        <span className="chat-close" onClick={() => { setShowQms(false) }}>×</span>
                      </div>
                    </div>
                    <QmsWindow
                      userTokenDetails={userTokenDetails}
                      contractNo={data.invoiceRefrenceNo}
                      isInvoiceDiscounting={true}
                      isBuyerView={viewOnly}
                      buyerUserId={data.buyerId}
                      sellerData={{ email: data.sellerEmail, user_id: data.sellerId }}
                      partyUserId={viewOnly ? [data.sellerId, data.lenderId] :
                        [data.sellerId, data.lenderId, data.buyerId]}
                    />
                  </div>
                </>
              }
            </>
          )} */}
          {(financeDetails.status && financeDetails.type === 3 && financeDetails.page === 1) ?
            <>
              <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                <li>
                  <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                    setTab(0);
                  }}>Application Details</a>
                </li>
                <li>
                  <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                    setTab(1);
                  }}>Documents</a>
                </li>
              </ul>
              <div className="col-md-12 shadow bg-light pt-2 pb-5">

                {tab === 0 ? (
                  <div className="row">

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Applicant  {astrix} {toolTip('buyerId')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.applicantEmail ? " border-danger" : "")} name="applicant" value={data.applicantEmail} readOnly />
                          {error.applicantEmail ? <div class="text-danger error-contract">{error.applicantEmail}</div> : ''}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                            <div className="row form-group">
                          <label className="col-md-12">Data Line {toolTip('dirDataLine')}</label>
                          <div className="col-md-12">
                            <select className="form-control" name="buyerType" value={buyerType} onChange={(event) => {
                              setshowLoader(true); setBuyerType(event.target.value / 1)
                            }} disabled={financeDetails.info.action === "applyFinance" ? false : true}>
                              <option value="1" selected>Network</option>
                              <option value="2">Directory</option>
                            </select>
                          </div>
                        </div>
                      </div> */}

                    {/* <div className="col-md-2">
                      <div className="row form-group">
                        <label className="col-md-12">Currency {astrix}  {toolTip('loanCurrency')}</label>
                        <div className="col-md-12 ">
                          {
                            financeDetails.info.action !== "viewFinance" ?
                              <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                                <option value="" selected>--Select Currency--</option>
                                <option value="2:USD">Dollars ( USD )</option>
                                <option value="11:EUR">Euro ( EUR )</option>
                                <option value="19:GBP">Pounds ( GBP )</option>
                              </select> :
                              <input type="text" name="currency" value={data.currencyName} className={"form-control" + (error.currency ? " border-danger" : "")} disabled />}
                          {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Amount {astrix}  {toolTip('contractAmount')}</label>
                        <div className='row mx-3 w-100'>
                          <input type="number" step="0.01" className={"form-control w-75" + (error.contractAmount ? " border-danger" : "")} name="contractAmount" value={data.contractAmount} placeholder="Enter Contract Amount" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control w-25" + (error.currency ? " border-danger" : "")}
                              name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange}
                              disabled={financeDetails.info.action === "viewFinance"}>
                              <option value={""}>Select Currency</option>
                              {isLenderModifi && modifi_currencies.length ? modifi_currencies.map((item, index) => (
                                <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                              ))
                                : null}
                              {!isLenderModifi && most_used_currencies.length ? most_used_currencies.map((item, index) => (
                                <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                              ))
                                : null}
                              {isLenderModifi ? null : (
                                <option value={""}>--- All Currencies ---</option>)}
                              {!isLenderModifi && currencyData.length ? currencyData.map((item, index) => (
                                <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                              ))
                                : null}
                            </select> :
                            <input type="text" name="currency" value={data.currencyName} className={"form-control w-25" + (error.currency ? " border-danger" : "")} disabled />
                          }
                        </div>
                        <div className="col-md-12">
                          {error.contractAmount ? <div class="text-danger error-contract">{error.contractAmount}</div> : ''}
                        </div>
                      </div>
                    </div>



                    {(data.buyerId === "ME") && <>
                      <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">Buyer Name (Manual Entry) {astrix} {toolTip('buyerName')}</label>
                          <div className="col-md-12">
                            <input type="text" className="form-control" name="buyerNameManual" value={data.buyerNameManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                            {error.buyerNameManual ? <div class="text-danger error-contract">{error.buyerNameManual}</div> : ''}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">Buyer Address (Manual Entry) {astrix} {toolTip('buyerAddr')}</label>
                          <div className="col-md-12">
                            <input type="text" className="form-control" name="buyerAddrManual" value={data.buyerAddrManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                            {error.buyerAddrManual ? <div class="text-danger error-contract">{error.buyerAddrManual}</div> : ''}
                          </div>
                        </div>
                      </div>
                    </>}

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice No  {astrix} {toolTip('invoiceRefrenceNo')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.invoiceRefrenceNo ? " border-danger" : "")} name="invoiceRefrenceNo" value={data.invoiceRefrenceNo} placeholder="Enter Invoice Refrence No" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceRefrenceNo ? <div class="text-danger error-contract">{error.invoiceRefrenceNo}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">PO No {astrix}  {toolTip('poRefrenceNo')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.poRefrenceNo ? " border-danger" : "")} name="poRefrenceNo" value={data.poRefrenceNo} placeholder="Enter PO Refrence No" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.poRefrenceNo ? <div class="text-danger error-contract">{error.poRefrenceNo}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Issue Date {astrix}  {toolTip('invoiceIssueDate')}</label>
                        <div className="col-md-12">
                          <input type="date" name="invoiceIssueDate" value={data.invoiceIssueDate} className={"form-control" + (error.invoiceIssueDate ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceIssueDate ? <div class="text-danger error-contract">{error.invoiceIssueDate}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Due Date {astrix}  {toolTip('invoiceDueDate')}</label>
                        <div className="col-md-12">
                          <input type="date" name="invoiceDueDate" value={data.invoiceDueDate} className={"form-control" + (error.invoiceDueDate ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceDueDate ? <div class="text-danger error-contract">{error.invoiceDueDate}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Lender  {astrix} {toolTip('lenderId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.lenderId ? " border-danger" : "")} name="lenderId" id="select_lenderId"
                              value={data.lenderId + ':' + data.lenderName + ':' + data.lenderEmail} onChange={handleChange} >
                              <option value="">--Select Lender--</option>
                              {bankMaster.map((values) => {
                                return (<option value={values.id + ':' + values.company_name + ':' + values.email_id} >{values.company_name}</option>)
                              })}
                            </select>
                            :
                            <input type="text" name="lenderId" value={data.lenderName} className={"form-control" + (error.lenderId ? " border-danger" : "")} onChange={handleChange} disabled />}
                          <br />
                          {error.lenderId ? <div class="text-danger error-contract">{error.lenderId}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Buyer {astrix}  {toolTip('buyerId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.buyerId ? " border-danger" : "")} name="buyerId"
                              value={data.buyerId + ':' + data.buyername} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                              <option value="" selected>--Select Buyer--</option>
                              <optgroup label="My Buyers">
                                {counterPart.length > 0 ? counterPart.map((counter) => {
                                  return (
                                    <option value={counter.id + ':' + counter.buyerName} >{counter.buyerName}</option>
                                  )
                                })
                                  :
                                  <option disabled>Not Available</option>}
                              </optgroup>
                              <optgroup label="Public Buyer">
                                {buyerArr.length > 0 ? buyerArr.map((buyer) => {
                                  return (
                                    <option value={buyer.id + ':' + buyer.company_name + ':' + buyer.user_name} >{buyer.company_name}</option>
                                  )
                                })
                                  :
                                  <option disabled>Not Available</option>}
                              </optgroup>
                            </select>
                            :
                            <input type="text" name="buyerId" value={data.buyerName} className={"form-control" + (error.buyerId ? " border-danger" : "")} disabled />}
                          {error.buyerId ? <div class="text-danger error-contract">{error.buyerId}</div> : ''}
                          {financeDetails.info.action === "viewFinance" ? '' : (data.lenderName && data.buyername) &&
                            (buyerCredit.modal && buyerCredit.data.limit != 'No values assigned to buyer') ?
                            <div><br /><p>Approved Finance Amount: {buyerCredit.data.limit}</p></div>
                            :
                            (buyerCredit.modal && buyerCredit.data.limit == 'No values assigned to buyer') ?
                              <div class="text-danger"><br /><p>  Approved Finance Amount: No values assigned to buyer</p></div>
                              : ''
                          }
                          {financeDetails.info.action === "viewFinance" ? '' : error.selectedBuyerLimit ? <div class="text-danger error-contract">{error.selectedBuyerLimit}</div> : ''}

                        </div>
                      </div>
                    </div>
                    {(data.buyername || data.buyerName) &&
                      <>
                        {isLenderModifi ? null : (
                          <div className="col-md-3">
                            <div className="row form-group">
                              <label className="col-md-12">Name of Authorized Signatory (Buyer) {astrix}</label>
                              <div className="col-md-12">
                                <input type="text" name="nameAuthorizedSignatory" value={data.nameAuthorizedSignatory} className={"form-control" + (error.nameAuthorizedSignatory ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                                {error.nameAuthorizedSignatory ? <div class="text-danger error-contract">{error.nameAuthorizedSignatory}</div> : ''}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Buyer Email {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" name="buyerEmail" value={data.buyerEmail} className={"form-control" + (error.buyerEmail ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                              {error.buyerEmail ? <div class="text-danger error-contract">{error.buyerEmail}</div> : ''}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Buyer Contact {astrix}</label>
                            <div className="col-md-12">
                              <input type="number" name="buyerContact" value={data.buyerContact} className={"form-control" + (error.buyerContact ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                              {error.buyerContact ? <div class="text-danger error-contract">{error.buyerContact}</div> : ''}
                            </div>
                          </div>
                        </div>

                        {isLenderModifi ? null : (
                          <div className="col-md-3">
                            <div className="row form-group">
                              <label className="col-md-12">Buyer Position {astrix}</label>
                              <div className="col-md-12">
                                <input type="text" name="buyerPosition" value={data.buyerPosition} className={"form-control" + (error.buyerPosition ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                                {error.buyerPosition ? <div class="text-danger error-contract">{error.buyerPosition}</div> : ''}
                              </div>
                            </div>
                          </div>
                        )}

                      </>
                    }

                    {/* <div className="col-md-2">
                      <div className="row form-group">
                        <label className="col-md-12">Currency {astrix}  {toolTip('loanCurrency')}</label>
                        <div className="col-md-12 ">
                          {
                            financeDetails.info.action !== "viewFinance" ?
                              <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                                <option value="" selected>--Select Currency--</option>
                                <option value="2:USD">Dollars ( USD )</option>
                                <option value="11:EUR">Euro ( EUR )</option>
                                <option value="19:GBP">Pounds ( GBP )</option>
                              </select> :
                              <input type="text" name="currency" value={data.currencyName} className={"form-control" + (error.currency ? " border-danger" : "")} disabled />}
                          {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Requested Amount {astrix}  {toolTip('loanAmount')}</label>
                        <div className='row mx-3 w-100'>
                          <input type="number" step="0.01" className={"form-control w-75" + (error.reqAmount ? " border-danger" : "")} name="reqAmount" value={data.reqAmount} placeholder="Enter Finance Amount" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />

                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control w-25" + (error.currency ? " border-danger" : "")}
                              name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange}
                              // disabled={financeDetails.info.action === "viewFinance"}
                              disabled={true}
                            >
                              <option value={""}>Select Currency</option>
                              {most_used_currencies.length ? most_used_currencies.map((item, index) => (
                                <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                              ))
                                : null}
                              <option value={""}>--- All Currencies ---</option>
                              {currencyData.length ? currencyData.map((item, index) => (
                                <option value={item.id + ':' + item.code}>{item.name + " ( " + item.code + " )"}</option>
                              ))
                                : null}
                            </select> :
                            <input type="text" name="currency" value={data.currencyName} className={"form-control w-25" + (error.currency ? " border-danger" : "")} disabled />
                          }
                          {error.reqAmount ? <div class="text-danger error-contract">{error.reqAmount}</div> : ''}
                        </div>
                      </div>
                    </div>

                    {
                      userTypeId == 8 &&
                      <div className="col-md-3">
                        <div className="row form-group">
                          <label className="col-md-12">Interest Rate (<small>To be filled by Banker</small>){astrix}  {toolTip('interestRate')}</label>
                          <div className="col-md-12">
                            <input className={"form-control" + (error.interestRate ? " border-danger" : "")} type="number" name="interestRate" value={data.interestRate} placeholder="Enter Interest rate" onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2} />
                            {error.interestRate ? <div class="text-danger error-contract"> {error.interestRate} </div> : ''}
                          </div>
                        </div>
                      </div>
                    }

                    {/* <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">GRN Date {astrix}  {toolTip('grnDate')}</label>
                          <div className="col-md-12">
                            <input type="date" name="grnDate" value={data.grnDate} className={"form-control" + (error.grnDate ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                            {error.grnDate ? <div class="text-danger error-contract">{error.grnDate}</div> : ''}
                          </div>
                        </div>
                      </div> */}

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Bank {astrix} {toolTip('bankListId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.bankListId ? " border-danger" : "")} name="bankListId" value={data.bankListId + ":" + data.bankListName} onChange={handleChange}>
                              <option value="" selected>--Select Bank--</option>
                              {userBank.map((bank) => {
                                if (bank.beneficiaryBankName !== null) {
                                  return (
                                    <option value={bank.id + ':' + bank.beneficiaryBankName} >{bank.beneficiaryBankName}</option>
                                  )
                                }
                              })}
                            </select>
                            :
                            <input type="text" name="bankListId" value={data.bankListName} className={"form-control" + (error.bankListId ? " border-danger" : "")} onChange={handleChange} disabled />}
                          {error.bankListId ? <div class="text-danger error-contract">{error.bankListId}</div> : ''}
                        </div>
                      </div>
                    </div>

                    {/* {isLenderModifi ? null : ( */}
                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Finance Credit Days {astrix}  {toolTip('loanCreditDays')}</label>
                        <div className="col-md-12">
                          <input className={"form-control" + (error.creditDays ? " border-danger" : "")} type="number" name="creditDays" value={data.creditDays} placeholder="Enter Finance Credit Days" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                          {error.creditDays ? <div class="text-danger error-contract"> {error.creditDays} </div> : ''}
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    {/* {isLenderModifi ? (
                      <div className="col-md-3">
                        <div className="row form-group">
                          <label className="col-md-12">Finance Terms Days {astrix}</label>
                          <div className="col-md-12">
                            <input className={"form-control" + (error.financingTerm ? " border-danger" : "")} type="number" name="financingTerm" value={data.financingTerm} placeholder="Enter Finance Terms Days" onChange={handleChange}
                              disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                            {error.financingTerm ? <div class="text-danger error-contract"> {error.financingTerm} </div> : ''}
                          </div>
                        </div>
                      </div>
                    ) : null} */}

                    {isLenderModifi ? null : (
                      <div className="col-md-3">
                        <div className="row form-group">
                          <label className="col-md-12">Shipper/Broker {toolTip('logisticId')}</label>
                          <div className="col-md-12">
                            {financeDetails.info.action !== "viewFinance" ?
                              <select className={"form-control" + (error.logisticId ? " border-danger" : "")} name="logisticId" value={data.logisticId + ":" + data.logisticName} onChange={handleChange}>
                                <option value="" selected>--Select Shipper/Broker--</option>
                                {logiArr.map((logist) => {
                                  return (
                                    <option value={logist.id + ':' + logist.company_name} >{logist.company_name}</option>
                                  )
                                })}
                                <option value={"0:Others"}>Others</option>
                              </select>
                              :
                              <input type="text" name="logisticId" value={data.logisticName == 'Not Available' ? data.logisticNameManual : data.logisticName} className={"form-control" + (error.logisticId ? " border-danger" : "")} onChange={handleChange} disabled />}
                            {error.logisticId ? <div class="text-danger error-contract">{error.logisticId}</div> : ''}
                          </div>
                        </div>
                      </div>
                    )}

                    {data.logisticName == "Others" &&
                      <div className="col-md-3">
                        <div className="row form-group">
                          <label className="col-md-12">Shipper/Broker Name (Manual Entry) {astrix} {toolTip('logisticName')}</label>
                          <div className="col-md-12">
                            <input type="text" className="form-control" name="logisticNameManual" value={data.logisticNameManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                            {error.logisticNameManual ? <div class="text-danger error-contract">{error.logisticNameManual}</div> : ''}
                          </div>
                        </div>
                      </div>
                    }

                    {isLenderModifi ? (
                      <>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Origin Country {astrix}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!error.shipmentOriginCountry ? '' : ' border-danger')} name="shipmentOriginCountry" value={data.shipmentOriginCountry} onChange={handleChange}
                                disabled={financeDetails.info.action === "viewFinance"}  >
                                <option value="" selected>Select Country</option>
                                {countryData.map((country) => {
                                  return (
                                    <option value={country.sortname} >{country.name}</option>
                                  )
                                })}
                              </select>
                              {error.shipmentOriginCountry ? <div class="text-danger error-contract">{error.shipmentOriginCountry}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Origin Address {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentOriginAddress ? '' : ' border-danger')} name="shipmentOriginAddress" value={data.shipmentOriginAddress} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentOriginAddress ? <div class="text-danger error-contract">{error.shipmentOriginAddress}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Origin City {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentOriginCity ? '' : ' border-danger')} name="shipmentOriginCity" value={data.shipmentOriginCity} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentOriginCity ? <div class="text-danger error-contract">{error.shipmentOriginCity}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Origin Postal Code {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentOriginPostalCode ? '' : ' border-danger')} name="shipmentOriginPostalCode" value={data.shipmentOriginPostalCode} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentOriginPostalCode ? <div class="text-danger error-contract">{error.shipmentOriginPostalCode}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Destination Country {astrix}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!error.shipmentDestinationCountry ? '' : ' border-danger')} name="shipmentDestinationCountry" value={data.shipmentDestinationCountry} onChange={handleChange}
                                disabled={financeDetails.info.action === "viewFinance"}  >
                                <option value="" selected>Select Country</option>
                                {countryData.map((country) => {
                                  return (
                                    <option value={country.sortname} >{country.name}</option>
                                  )
                                })}
                              </select>
                              {error.shipmentDestinationCountry ? <div class="text-danger error-contract">{error.shipmentDestinationCountry}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Destination Address {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentDestinationAddress ? '' : ' border-danger')} name="shipmentDestinationAddress" value={data.shipmentDestinationAddress} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentDestinationAddress ? <div class="text-danger error-contract">{error.shipmentDestinationAddress}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Destination City {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentDestinationCity ? '' : ' border-danger')} name="shipmentDestinationCity" value={data.shipmentDestinationCity} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentDestinationCity ? <div class="text-danger error-contract">{error.shipmentDestinationCity}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row form-group">
                            <label className="col-md-12">Shipment Destination Postal Code {astrix}</label>
                            <div className="col-md-12">
                              <input type="text" className={" form-control" + (!error.shipmentDestinationPostalCode ? '' : ' border-danger')} name="shipmentDestinationPostalCode" value={data.shipmentDestinationPostalCode} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                              {error.shipmentDestinationPostalCode ? <div class="text-danger error-contract">{error.shipmentDestinationPostalCode}</div> : ''}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {userTypeId == 8 &&
                      <div className="col-md-12">
                        <div className="row form-group">
                          <label className="col-md-12">Finance TNC (<small>To be filled by Banker</small>) {astrix}  {toolTip('fintnc')}</label>
                          <div className="col-md-12">
                            <textarea rows="4" className={"form-control" + (error.fintnc ? " border-danger" : "")} name="fintnc" value={data.fintnc} onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2}></textarea>
                            {error.fintnc ? <div class="text-danger error-contract"> {error.fintnc} </div> : ''}
                          </div>
                        </div>
                      </div>}

                    {/* {creditData.map((bank) => {
                                    if (data.lenderUniqueId == bank.id) {
                                      return (
                                        <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                                          <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Credit Line Details</h2>
                                          <div className="accordionItemContent">
                                            <div className="col-md-12 row">

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Requested Amount</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.credit_amount} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Approved Finance Amount</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.approvedFinanceAmount} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Funding Percentage</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingPercentage + '%'} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Funding Tenor</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.fundingTenor} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Due Date Calculation From</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.duedateCalcFrom} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Interest Rate</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestRate + '%'} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Interest Collection</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.interestCollection} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Finance Request Percentage</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestPercentage + '%'} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-md-6">
                                                <div className="row form-group">
                                                  <label className="col-md-12">Finance Request Collection</label>
                                                  <div className="col-md-12">
                                                    <input type="text" className={"form-control" + (error.dirContractNo ? " border-danger" : "")} name="dirContractNo" value={bank.finRequestCollection} readOnly={true} />
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                  } */}

                    {/* {
                      data.logisticName == "Others" &&
                      <div className="col-md-3">
                        <div className="row form-group">
                          <label className="col-md-12">Shipper/Broker Name (Manual Entry) {astrix} {toolTip('logisticName')}</label>
                          <div className="col-md-12">
                            <input type="text" className="form-control" name="logisticNameManual" value={data.logisticNameManual} onChange={handleChange} readOnly={financeDetails.info.action === "viewFinance"} />
                            {error.logisticNameManual ? <div class="text-danger error-contract">{error.logisticNameManual}</div> : ''}
                          </div>
                        </div>
                      </div>
                    } */}

                  </div >
                ) : null}

                <div className="row">
                  {tab === 1 ? (
                    <>
                      {(financeDetails.info.action === "applyFinance") ?
                        <>
                          <div className="col-md-12">
                            <h3 className="pt-2 pb-2"><u>Supporting Documents</u></h3>
                            <div className="col-md-12 pt-2 pb-3">
                              <ul className="other-documents">
                                <li>
                                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                </li>
                                <li>
                                  <h3 className={"custum-control" + (error.invoiceDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Invoice Document {astrix}  {toolTip('invoiceDocument')}</h3>
                                </li>
                                {/* <li>
                                              <div className="document-name-top">Valid Upto </div>
                                              <div className="document-name-bottom">(If applicable )</div>
                                            </li>
                                            <li>
                                              <input type="text" className="custum-control year" name={"invoiceDocumentMM"} value={data.invoiceDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                                              <input type="text" className="custum-control year" name={"invoiceDocumentYY"} value={data.invoiceDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                                            </li> */}
                                <li>
                                  <div className="file-browse">
                                    <button className="btn btn-primary btn-sm">Upload Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" name="invoiceDocument" onChange={handleFiles} />
                                  </div>
                                </li>
                                <li> </li>
                              </ul>
                              {file.invoiceDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.invoiceDocument.name}`}</div></div> : ""}
                              {error.invoiceDocument ? <div class="text-danger error-contract">{error.invoiceDocument}</div> : ''}
                            </div>
                            <hr />
                            <div className="col-md-12 pt-2 pb-3">
                              <ul className="other-documents">
                                <li>
                                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                </li>
                                <li>
                                  <h3 className={"custum-control" + (error.poDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>PO Document {astrix} {toolTip('poDocument')}</h3>
                                </li>
                                {/* <li>
                                            <div className="document-name-top">Valid Upto </div>
                                            <div className="document-name-bottom">(If applicable )</div>
                                          </li>
                                          <li>
                                            <input type="text" className="custum-control year" name={"poDocumentMM"} value={data.poDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                                            <input type="text" className="custum-control year" name={"poDocumentYY"} value={data.poDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                                          </li> */}
                                <li>
                                  <div className="file-browse">
                                    <button className="btn btn-primary btn-sm">Upload Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" name="poDocument" onChange={handleFiles} />
                                  </div>
                                </li>
                                <li> </li>
                              </ul>
                              {file.poDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.poDocument.name}`}</div></div> : ""}
                              {error.poDocument ? <div class="text-danger error-contract">{error.poDocument}</div> : ''}
                            </div>
                            <hr />
                            {isLenderModifi ? (
                              <div className="col-md-12 pt-2 pb-3">
                                <h3 className="pt-2"><u>Transport Document</u></h3>
                                {error.transportDocument ? <div class="text-danger error-contract pb-2">{error.transportDocument}</div> : ''}
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className="custum-control" style={{ "min-width": "205px" }}>CMR CIM</h3>
                                  </li>
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="cmrCim" onChange={handleFiles} />
                                    </div>
                                  </li>
                                  <li> </li>
                                </ul>
                                {file.cmrCim ? <div className="form-group mb-0"><div className="file-name">{`${file.cmrCim.name}`}</div></div> : ""}
                              </div>
                            ) : (
                              <div className="col-md-12 pt-2 pb-3">
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className="custum-control" style={{ "min-width": "205px" }}>Investigation Report {toolTip('invReportDocument')}</h3>
                                  </li>
                                  {/* <li>
                                          <div className="document-name-top">Valid Upto </div>
                                          <div className="document-name-bottom">(If applicable )</div>
                                        </li>
                                        <li>
                                          <input type="text" className="custum-control year" name={"invReportDocumentMM"} value={data.invReportDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                                          <input type="text" className="custum-control year" name={"invReportDocumentYY"} value={data.invReportDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                                        </li> */}
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="invReportDocument" onChange={handleFiles} />
                                    </div>
                                  </li>
                                  <li> </li>
                                </ul>
                                {file.invReportDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.invReportDocument.name}`}</div></div> : ""}
                              </div>
                            )}
                            <hr />
                            <div className="col-md-12 pt-2 pb-3">
                              <ul className="other-documents">
                                <li>
                                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                </li>
                                <li>
                                  <h3 className="custum-control" style={{ "min-width": "205px" }}>Shipment (Bill of Leading) {toolTip('logisticDocument')}</h3>
                                </li>
                                {/* <li>
                              <div className="document-name-top">Valid Upto </div>
                              <div className="document-name-bottom">(If applicable )</div>
                            </li>
                            <li>
                              <input type="text" className="custum-control year" name={"logisticDocumentMM"} value={data.logisticDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                              <input type="text" className="custum-control year" name={"logisticDocumentYY"} value={data.logisticDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                            </li> */}
                                <li>
                                  <div className="file-browse">
                                    <button className="btn btn-primary btn-sm">Upload Document</button>
                                    <input type="file" accept=".png,.jpg,.pdf" name="logisticDocument" onChange={handleFiles} />
                                  </div>
                                </li>
                                <li> </li>
                              </ul>
                              {file.logisticDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.logisticDocument.name}`}</div></div> : ""}
                            </div>
                            <hr />
                            {isLenderModifi ? (
                              <div className="col-md-12 pt-2 pb-3">
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className="custum-control" style={{ "min-width": "205px" }}>Airway Bill</h3>
                                  </li>
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="airwayBill" onChange={handleFiles} />
                                    </div>
                                  </li>
                                  <li> </li>
                                </ul>
                                {file.airwayBill ? <div className="form-group mb-0"><div className="file-name">{`${file.airwayBill.name}`}</div></div> : ""}
                              </div>
                            ) : (
                              <div className="col-md-12 pt-2 pb-3">
                                <ul className="other-documents">
                                  <li>
                                    <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                  </li>
                                  <li>
                                    <h3 className="custum-control" style={{ "min-width": "205px" }}>Certificate of Origin {toolTip('grnDocument')}</h3>
                                  </li>
                                  {/* <li>
                                    <div className="document-name-top">Valid Upto </div>
                                    <div className="document-name-bottom">(If applicable )</div>
                                  </li>
                                  <li>
                                    <input type="text" className="custum-control year" name={"grnDocumentMM"} value={data.grnDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                                    <input type="text" className="custum-control year" name={"grnDocumentYY"} value={data.grnDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                                  </li> */}
                                  <li>
                                    <div className="file-browse">
                                      <button className="btn btn-primary btn-sm">Upload Document</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="grnDocument" onChange={handleFiles} />
                                    </div>
                                  </li>
                                  <li> </li>
                                </ul>
                                {file.grnDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.grnDocument.name}`}</div></div> : ""}
                              </div>
                            )}
                          </div>
                        </> :
                        <>
                          <div class="col-md-12">
                            <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Initial Documents</h2>
                              <div className="accordionItemContent">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                      <thead>
                                        <tr>
                                          <th width="20">#</th>
                                          <th>Type of Document</th>
                                          <th width="200">Document Name</th>
                                          <th width="200">Uploaded By</th>
                                          <th width="200">Uploaded On</th>
                                          <th>Signed By</th>
                                          <th className="text-center" width="150">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(data && data.doc && data.doc.length) && docPreviewGenerator(data.doc)}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div className={"accordionItem mt-3" + (accord['1'] ? " closed" : " open")}>
                              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '1': !accord['1'] })}>Framework Agreement</h2>
                              <div className="accordionItemContent">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                      <thead>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="200">Document Name</th>
                                          <th width="200">Uploaded By</th>
                                          <th width="200">Uploaded On</th>
                                          <th width="200">Signed By</th>
                                          <th className="text-center" width="150">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(data.frameworkDocList && data.frameworkDocList.length) && docAgreementPreview('Framework')}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div className={"accordionItem mt-3" + (accord['2'] ? " closed" : " open")}>
                              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '2': !accord['2'] })}>Exhibit Agreement</h2>
                              <div className="accordionItemContent">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                      <thead>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="200">Document Name</th>
                                          <th width="200">Uploaded By</th>
                                          <th width="150">Uploaded On</th>
                                          <th width="150">Signed By</th>
                                          <th className="text-center" width="250">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : (data.status == 3 && userTypeId == 8) ? docUploadPreview('Exhibit') : ''}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div className={"accordionItem mt-3" + (accord['3'] ? " closed" : " open")}>
                              <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '3': !accord['3'] })}>Notice of Assignment</h2>
                              <div className="accordionItemContent">
                                <div className="col-md-12">
                                  <div className="table-responsive" style={{ "min-height": "150px" }}>
                                    <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                      <thead>
                                        <tr>
                                          <th width="20">#</th>
                                          <th width="200">Document Name</th>
                                          <th width="200">Uploaded By</th>
                                          <th width="150">Uploaded On</th>
                                          <th width="150">Signed By</th>
                                          <th className="text-center" width="250">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : (data.exhibitStatus == 1 && (data.status == 2 || data.status == 3) && userTypeId == 8) ? docUploadPreview('NOA') : ''}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>}
                      <div class="col-md-12">
                        <hr />
                        {financeDetails.info.action !== "viewFinance" &&
                          <OtherDocs
                            data={data}
                            setData={setData}
                            file={file}
                            handleFiles={handleFiles}
                            docLimit={2}
                          />}
                      </div>

                    </>
                  ) : null}
                </div>
                <hr />
                {
                  !viewOnly ? (
                    <NavigationButtons showNextButton={true} />
                  ) : null
                }
              </div >
            </> :
            (financeDetails.status && financeDetails.type === 3 && financeDetails.page === 2) ?
              <>
                {/* <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                      setTab(0);
                    }}>Application Details</a>
                  </li>
                  <li>
                    <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                      setTab(1);
                    }}>Documents</a>
                  </li>
                </ul> */}
                <div class="col-md-12 p-2">
                  <h1 className="text-center"><u>Finance against Invoice Form- Review</u></h1><br />
                  <div className="row m-2">
                    {/* {tab === 0 ? ( */}
                    <>
                      <div class="col-md-2 p-2">
                        <label>Applicant</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.applicantEmail} disabled />
                      </div>
                      <div class="col-md-2 p-2">
                        <label>Buyer</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.buyerNameManual ? data.buyerNameManual : data.buyername} disabled />
                      </div>

                      {isLenderModifi ? null : (
                        <>
                          <div class="col-md-2 p-2">
                            <label>Authorized Signatory (Buyer)</label>
                          </div>
                          <div class="col-md-4 p-2">
                            <input type="text" class="form-control" value={data.nameAuthorizedSignatory} disabled />
                          </div>
                        </>
                      )}
                      <div class="col-md-2 p-2">
                        <label>Buyer Email</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.buyerEmail} disabled />
                      </div>
                      <div class="col-md-2 p-2">
                        <label>Buyer Contact</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.buyerContact} disabled />
                      </div>
                      {isLenderModifi ? null : (
                        <>
                          <div class="col-md-2 p-2">
                            <label>Buyer Position</label>
                          </div>
                          <div class="col-md-4 p-2">
                            <input type="text" class="form-control" value={data.buyerPosition} disabled />
                          </div>
                        </>
                      )}

                      <div class="col-md-2 p-2">
                        <label>Invoice No</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.invoiceRefrenceNo} disabled />
                      </div>

                      <div class="col-md-2 p-2">
                        <label>Po No</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.poRefrenceNo} disabled />
                      </div>

                      <div class="col-md-2 p-2">
                        <label>Invoice Issue date</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.invoiceIssueDate} disabled />
                      </div>

                      <div class="col-md-2 p-2">
                        <label>Invoice Due date</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.invoiceDueDate} disabled />
                      </div>

                      <div class="col-md-2 p-2">
                        <label>Currency</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.currencyName} disabled />
                      </div>
                      <div class="col-md-2 p-2">
                        <label>Invoice Amount</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.contractAmount} disabled />
                      </div>
                      {/* <div class="col-md-2 p-2">
                        <label>Currency</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.currencyName} disabled />
                      </div> */}
                      <div class="col-md-2 p-2">
                        <label>Requested Amount</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.reqAmount} disabled />
                      </div>
                      <div class="col-md-2 p-2">
                        <label>Lender</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.lenderName} disabled />
                        {/* {bankMaster.map((bank) => {
                          if (bank.company_name == data.lenderName) {
                            return (<input type="text" class="form-control" value={bank.company_name} disabled />)
                          }
                        })} */}
                      </div>
                      {userTypeId == 8 &&
                        <><div class="col-md-2 p-2">
                          <label>Interest Rate</label>
                        </div>
                          <div class="col-md-4 p-2">
                            <input type="text" class="form-control" value={data.interestRate} disabled />
                          </div></>}
                      {/* {!isLenderModifi ? ( */}
                      <>
                        <div class="col-md-2 p-2">
                          <label>Credit Days</label>
                        </div>
                        <div class="col-md-4 p-2">
                          <input type="text" class="form-control" value={data.creditDays} disabled />
                        </div>
                      </>
                      {/* ) : null} */}
                      <div class="col-md-2 p-2">
                        <label>Bank Name</label>
                      </div>
                      <div class="col-md-4 p-2">
                        <input type="text" class="form-control" value={data.bankListName} disabled />
                      </div>
                      {isLenderModifi ? null : (
                        <>
                          <div class="col-md-2 p-2">
                            <label>Shipper/Broker</label>
                          </div>
                          <div class="col-md-4 p-2">
                            <input type="text" class="form-control" value={data.logisticName == "Others" ? data.logisticNameManual : data.logisticName} disabled />
                          </div>
                        </>
                      )}
                    </>
                    {/* ) : null} */}
                    {/* <div class="col-md-2 p-2">
                  <label>GRN Date</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.grnDate} disabled />
                </div> */}
                    <div className="text-left">
                      <div className="row form-group">

                        {/* {financeDetails.info.action === "viewFinance" && tab === 1 && */}
                        {financeDetails.info.action === "viewFinance" &&
                          <>
                            <div className="col-md-12">
                              <div className="col-md-12 mt-3">
                                <h3 className="pt-2 pb-2"><u>Initial Documents</u></h3>
                                <div className="table-responsive" style={{ "min-height": "150px" }}>
                                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                    <thead>
                                      <tr>
                                        <th width="20">#</th>
                                        <th>Type of Document</th>
                                        <th width="200">Document Name</th>
                                        <th width="200">Uploaded By</th>
                                        <th width="150">Uploaded On</th>
                                        <th width="150">Signed By</th>
                                        <th className="text-center" width="150">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(data && data.doc && data.doc.length) && docPreviewGenerator(data.doc)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="col-md-12 mt-3">
                                <h3 className="pt-2 pb-2"><u>Framework Agreement</u></h3>
                                <div className="table-responsive" style={{ "min-height": "150px" }}>
                                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                    <thead>
                                      <tr>
                                        <th width="20">#</th>
                                        <th width="200">Document Name</th>
                                        <th width="200">Uploaded By</th>
                                        <th width="150">Uploaded On</th>
                                        <th width="150">Signed By</th>
                                        <th className="text-center" width="150">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(data.frameworkDocList && data.frameworkDocList.length) && docAgreementPreview('Framework')}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="col-md-12 mt-3">
                                <h3 className="pt-2 pb-2"><u>Exhibit Agreement</u></h3>
                                <div className="table-responsive" style={{ "min-height": "150px" }}>
                                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                    <thead>
                                      <tr>
                                        <th width="20">#</th>
                                        <th width="200">Document Name</th>
                                        <th width="200">Uploaded By</th>
                                        <th width="150">Uploaded On</th>
                                        <th width="150">Signed By</th>
                                        <th className="text-center" width="250">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(data && data.exhibitDocList && data.exhibitDocList.length) ? docAgreementPreview('Exhibit') : ''}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="col-md-12 mt-3">
                                <h3 className="pt-2 pb-2"><u>Notice of Assignment</u></h3>
                                <div className="table-responsive" style={{ "min-height": "150px" }}>
                                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                    <thead>
                                      <tr>
                                        <th width="20">#</th>
                                        <th width="200">Document Name</th>
                                        <th width="200">Uploaded By</th>
                                        <th width="150">Uploaded On</th>
                                        <th width="150">Signed By</th>
                                        <th className="text-center" width="250">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(data && data.NOADocList && data.NOADocList.length) ? docAgreementPreview('NOA') : (data.exhibitStatus == "1" && data.status == 2 && userTypeId == 8) ? docUploadPreview('NOA') : ''}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        }

                        {/* {tab === 1 && (file && Object.keys(file).length > 0) && */}
                        {(file && Object.keys(file).length > 0) &&
                          <div className="col-md-12 row mt-3">

                            {/* <h3 className="pt-2 pb-2 col-md-12 "><u>Added Documents</u></h3> */}
                            <div className="col-md-5">
                              <h3 className="col-md-12 pl-0 ml-0"><u>Document Name</u></h3>
                            </div>
                            <div className="col-md-5">
                              <h3 className="col-md-12  pl-0 ml-0"><u>File Name</u></h3>
                            </div>
                            <div className="col-md-2">
                              <h3 className="col-md-12  pl-0 ml-0"><u>View File</u></h3>
                            </div>

                            {file && Object.keys(file).length ? Object.keys(file).map((doc) => {
                              if (!doc.includes("dataUrl")) {
                                return (
                                  <>
                                    <div className="col-md-5">
                                      <div className="row form-group">
                                        <label className="col-md-12">{doc.toUpperCase() || '--'}</label>
                                      </div>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="row form-group">
                                        <label className="col-md-12 text-primary">{file[doc].name}</label>
                                      </div>
                                    </div>
                                    <div className='col-md-2'>
                                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                                        () => callPreviewFileComponent({ file_data: file[doc], base64: file[doc + "dataUrl"] }, "view")}>
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </>
                                )
                              }
                            }) : ''}
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
                {isLenderModifi ? (
                  <div>
                    <h5 className="modal-title mb-4"><u>Modifi Fees Summary</u></h5>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Fees Type</th>
                          <th>Amount</th>
                          <th>Currency</th>
                        </tr>
                      </thead>
                      {feesData.fees && Object.keys(feesData.fees).map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.toUpperCase().split("FEE")[0]}</td>
                          <td>{feesData.fees[item]["amount"] / 100}</td>
                          <td>{feesData.fees[item]["currency"]}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                ) : null}
                <div className="col-md-12 pt-3">
                  <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={handleChange} required checked={data.dnc === "on" ? true : false} />
                  <label className="checkbox-smallLabel width-auto" for="checkDNC">
                    <span>
                      I hereby declare that the given information by me is best in my
                      knowledge and I did not apply any loan against this contract.
                    </span>
                  </label>
                </div>
              </> : "oops something is wrong , your were not supposed to get here."
          }
          {
            showPreviewModal && <FilePreview
              userTokenDetails={userTokenDetails}
              fileData={fileData}
              showPreviewModal={showPreviewModal}
              setshowPreviewModal={setshowPreviewModal}
            />
          }

          {/* {addPreviewModal && <AddSigningAuthority
        userTokenDetails={userTokenDetails}
        addFileData={addFileData}
        fileDetail={fileDetail}
        addPreviewModal={addPreviewModal}
        setaddPreviewModal={setaddPreviewModal}
        saveDocResult={saveDocResult}
        setSaveDocResult={setSaveDocResult}
      />} */}

          {
            showAclAuthModal &&
            <DigitalSignDoc
              userTokenDetails={userTokenDetails}
              fileData={fileData}
              showAclAuthModal={showAclAuthModal}
              setAclAuthModal={setAclAuthModal}
              refreshDoc={refresh}
              setrefreshDoc={setrefresh}
              viewOnly={viewOnly}
              buyerUserId={buyerUserId}
            />
          }

          {
            financeDetails.page === 2 ? (
              <>
                <hr className="pt-2 pb-2 text-primary" />
                <NavigationButtons showNextButton={false} />
              </>
            ) : null
          }

          {/* </>} */}
          {/* {tab === 1 &&
            <>
              <div className="col-md-12 shadow bg-light pt-2 pb-5">

                <div className="row">

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Deal ID  {astrix}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.dealId ? " border-danger" : "")} name="applicant" value={data.dealId} />
                        {error.dealId ? <div class="text-danger error-contract">{error.dealId}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Party State Name  {astrix}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.partyStateName ? " border-danger" : "")} name="applicant" value={data.partyStateName} />
                        {error.partyStateName ? <div class="text-danger error-contract">{error.partyStateName}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Mode of Transit  {astrix}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.modeOfTransit ? " border-danger" : "")} name="applicant" value={data.modeOfTransit} />
                        {error.modeOfTransit ? <div class="text-danger error-contract">{error.modeOfTransit}</div> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Tax Effective Date  {astrix}</label>
                      <div className="col-md-12">
                        <input type="text" className={"form-control" + (error.taxEffectiveDate ? " border-danger" : "")} name="applicant" value={data.taxEffectiveDate} />
                        {error.taxEffectiveDate ? <div class="text-danger error-contract">{error.taxEffectiveDate}</div> : ''}
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-md-12">
                  <h3 className="pt-2 pb-2"><u>Product Details</u></h3>
                  <div className="row">

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Commodity Name  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.commodityName ? " border-danger" : "")} name="applicant" value={data.commodityName} />
                          {error.commodityName ? <div class="text-danger error-contract">{error.commodityName}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Sum Insured  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.sumInsured ? " border-danger" : "")} name="applicant" value={data.sumInsured} />
                          {error.sumInsured ? <div class="text-danger error-contract">{error.sumInsured}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Limit Per Sending  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.limitPerSending ? " border-danger" : "")} name="applicant" value={data.limitPerSending} />
                          {error.limitPerSending ? <div class="text-danger error-contract">{error.limitPerSending}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Limits Per Location  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.limitsPerLocation ? " border-danger" : "")} name="applicant" value={data.limitsPerLocation} />
                          {error.limitsPerLocation ? <div class="text-danger error-contract">{error.limitsPerLocation}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Type of Cargo  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.typeOfCargo ? " border-danger" : "")} name="applicant" value={data.typeOfCargo} />
                          {error.typeOfCargo ? <div class="text-danger error-contract">{error.typeOfCargo}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Excess Percentage {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.excessPercentage ? " border-danger" : "")} name="applicant" value={data.excessPercentage} />
                          {error.excessPercentage ? <div class="text-danger error-contract">{error.excessPercentage}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Coverage Type  {astrix}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.coverageType ? " border-danger" : "")} name="applicant" value={data.coverageType} />
                          {error.coverageType ? <div class="text-danger error-contract">{error.coverageType}</div> : ''}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <hr className="pt-2 pb-2 text-primary" />
                <div className="col-md-12">
                  <button type="button" className="btn btn-success float-right btn-sm" onClick={() => { setInsuranceData({ modal: true, data: {} }) }}>
                    <i class="fas fa-search"></i> Search
                  </button>
                </div>
                {
                  insuranceData.modal &&
                  <>
                    <hr className="pt-2 pb-2 text-primary" />
                    <div className="col-md-12">
                      <h3 className="green mt-3"><u>Quote Details</u></h3>
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableData}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                          persistTableHead
                          highlightOnHover
                          onSort={handleSort}
                          sortServer
                          striped
                          noHeader
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </>
                }
              </div>


            </>} */}
          {/* </>
      </div> */}
        </>
      </div >
    </>
  );
};


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice,
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setChannelPartnerRaisedInvoice: (modal, info) => { dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info })) },
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceFinance)
