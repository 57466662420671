export default function validate(values) {

  let errors = {};

  // if (values.supplierProfile != true) {
  //   errors.supplierProfile = 'Field Missing';
  // }

  console.log(errors);
  return errors;
}