//----------------------------------------------------------------------
// Components Import
import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Login from './components/homeComponents/login.js';
import Logout from './components/homeComponents/logout.js';
import ChannelPartner from './components/homeComponents/channelPartner.js';
import shipment from './components/logistics/shipmentV2.js';
import ContractGrid from './components/contractGrid/contractList.js';
import Payment from './components/payments/payment.js';
import Tracking from './components/contractGrid/tracking.js';
import Admin from './components/admin/adminMain.js';
import ManageProfile from './components/admin/manageProfile.js';
import CommoditiesList from './components/commodityFormandMaster/commoditiesList.js';
import AllNotification from './components/notifications/allNotifications.js';
import AllCreditLines from './components/creditLineComp/CreditLines.js';
import AllCounterPart from './components/myCounterPartComp/myCounterPart.js';
import AllBuyerLines from './components/buyerLineComp/buyerLine.js';
import ContractDoc from './components/contractDetails/contractDetails.js';
import Land from './components/homeComponents/land.js';
import Ships from './components/ships/ships.js';
import quotationContract from './components/logistics/openQuotation.js';
import quotationslist from './components/logistics/quotationslist.js';
import Registration from './components/registration/registration.js';
import EditProfile from './components/userProfile/editProfile.js';
import Finance from './components/finance/financeGrid.js';
import Dashboard from './components/Dashboard/index.js';
import ShowPlans from './components/subscriptionPlans/showPlans.js';
import BuyPlans from './components/subscriptionPlans/buyPlans.js';
import SubscriptionGrid from './components/admin/subscriptionGrid.js';
import UserDirectory from './components/userDirectory/userDirectory.js';
import ContractDirectoryGrid from './components/contractDirectory/contractDirectoryGrid.js';
import ChangePassword from './components/userProfile/updatePassword.js';
import CreditLineUsers from './components/creditLineComp/creditLineUsers.js';
import Setting from './components/settingComp/eligibleCriteria.js';
import ManageProduct from './components/manageProductComp/manageProduct.js';
import RateCalculator from './components/rateCalculatorComp/rateCalculator.js';
import FinanceRequest from './components/finance/financeRequest.js';
import Reports from './components/reportsComp/report.js';
import UserDetailedList from './components/userProfile/userDetailedList.js';
import DocumentView from './components/utilComponents/documentView.js';
import InsuranceCasesList from './components/insurance/insuranceCases';
import ChannelPartnerAgreement from './components/channelPartner/channelPartnerAgreement.js';
//----------------------------------------------------------------------

//----------------------------------------------------------------------
// Functions Import
import { getUserDataFromCookie, removeCookieandAvatar } from './utils/cookieHelper';
import { initSocket } from './socket'
import inactivityWatcher from './utils/inactivityWatcher';
import POGrid from './components/purchaseOrder/poGrid.js';
import RateDashboard from './components/ships/rateList/rateDashboard.js';
import LCFastDashboard from './components/lcFast/lcFastDashboard.js';
import MarketPlace from './components/marketplace/Marketplace.js';
import MyBookingsDashboard from './components/ships/marketPlaceBookings/myBookingsDashboard.js';
import ChannelPartnerUserList from './components/channelPartner/channelPartnerUserList.js';
import ChannelPartnerList from './components/channelPartner/channelPartnerList.js';
import ShipmentQuotationsDashboard from './components/ships/marketPlaceBookings/shipmentQuotations/shipmentQuotationsDashboard.js';
import quotationslistV2 from './components/logistics/quotationslistV2.js';
import reportForFinancier from './components/reportsComp/reportForFinancier.js';
import AccountList from './components/channelPartner/accountList.js';
import DisbursedInvoice from './components/channelPartner/disbursedInvoices.js';
import RaisedInvoice from './components/channelPartner/raisedInvoices.js';
import markeplaceQuotationDashboard from './components/logistics/markeplaceQuotationDashboard.js';
import FinanceDetailView from './components/finance/financeDetailView.js';
import RefLink from './components/homeComponents/refLink.js';
import chatRooms from './components/chatRooms/chatRooms.js';
import rateManagement from './components/ships/freightForwarders/rateManagement.js';
import uploadDumpRates from './components/ships/rateList/uploadDumpRates.js';
import financeCalculator from './components/rateCalculatorComp/financeCalculator.js';
import InterestRates from './components/rateCalculatorComp/interestRates.js';
import NewRegistration from './components/registration/newRegistration.js';
import NewLogin from './components/registration/newLogin.js';
import lcFastDashboardV2 from './components/lcFast/lcFastDashboardV2.js';
import { HandleRedirect } from './components/handleRedirects.js';
import channelPartnerReferral from './components/channelPartner/channelPartnerReferral.js';
//----------------------------------------------------------------------

//----------------------------------------------------------------------
// get token and user details. Init scoket
let socket
const userDetails = getUserDataFromCookie()
userDetails.aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
const token = userDetails.token ? userDetails.token : "";
const hasPlan = userDetails.has_plan === 1 ? true : userDetails.has_plan === 0 ? false : "NLI";
const userTypeId = userDetails.type_id ? userDetails.type_id : null

console.log("token===================>", token);

if (token && !socket) {
  initSocket(token).then((socketObj) => {
    socket = socketObj
    // console.log("test socket in app.js-->", socketObj)
  })
}
//----------------------------------------------------------------------


//------------------------------------------------------------------------------------------------
// App function
function App() {
  let onlyAllowedUrl = localStorage.getItem("onlyAllowedUrl")
  if (onlyAllowedUrl && !window.location.href.includes(onlyAllowedUrl)) {
    removeCookieandAvatar()
    localStorage.clear()
    window.location.reload()
  }

  console.log("userTokenDetails=======>", userDetails)

  // Defination of private route
  const PrivateRoute = ({ component: Component, ...props }) => {
    return (
      <Route
        {...props}
        render={
          innerProps =>
            (hasPlan === true) ?
              (token ? <Component userTokenDetails={userDetails} {...innerProps} /> : <Redirect to="/login" />) :
              (hasPlan === "NLI") ?
                <Redirect to="/login" /> :
                <Redirect to="/buyplans" />
        }
      />
    );
  };

  if (token) {
    // Initiate inactivity watcher if user is logged in
    inactivityWatcher()
  }

  return (
    <Router >
      <Route path="/" exact component={NewLogin} />
      <Route path="/login" exact component={NewLogin} />
      {/* <Route path="/login" exact component={Login} /> */}
      <Route path="/logout" exact component={Logout} />
      <Route path="/registration" exact component={NewRegistration} />
      <Route path="/channelPartner" exact component={ChannelPartner} />
      <Route path="/showplans" exact component={ShowPlans} />
      <Route path="/channelPartnerAgreement" exact component={ChannelPartnerAgreement} />
      <Route path="/financeDetailView" exact component={!userDetails.token ? Login : FinanceDetailView} />
      <Route path="/refLink" exact component={RefLink} />
      <Route path="/uploadShippingRatesData" exact component={uploadDumpRates} />
      <Route path="/redirect" exact component={HandleRedirect} />

      <PrivateRoute path="/buyplans" exact component={BuyPlans} />
      <PrivateRoute path="/editprofile" exact component={EditProfile} />
      <PrivateRoute path="/contract" exact component={ContractGrid} />
      <PrivateRoute path="/shipment" exact component={shipment} />
      <PrivateRoute path={userTypeId / 1 === 1 ? "/users" : "/network"} exact component={Admin} />
      <PrivateRoute path="/manageprofile" exact component={ManageProfile} />
      <PrivateRoute path="/commoditieslist" exact component={CommoditiesList} />
      <PrivateRoute path="/notifications" exact component={AllNotification} />
      <PrivateRoute path="/creditLine" exact component={AllCreditLines} />
      <PrivateRoute path="/counterPart" exact component={AllCounterPart} />
      <PrivateRoute path="/buyerline" exact component={AllBuyerLines} />
      <PrivateRoute path="/contractdoc" exact component={ContractDoc} />
      <PrivateRoute path="/tracking" exact component={Tracking} />
      <PrivateRoute path="/payment" exact component={Payment} />
      <PrivateRoute path="/ships" exact component={Ships} />
      <PrivateRoute path="/quotation" exact component={quotationContract} />
      <PrivateRoute path="/quotationlist" exact component={quotationslist} />
      <PrivateRoute path="/finance" exact component={Finance} />
      <PrivateRoute path="/po" exact component={POGrid} />
      <PrivateRoute path="/rate-list" exact component={RateDashboard} />
      <PrivateRoute path="/rate-management" exact component={rateManagement} />
      <PrivateRoute path="/marketplace-shipment-quotations" exact component={ShipmentQuotationsDashboard} />
      <PrivateRoute path="/my-quotations" exact component={markeplaceQuotationDashboard} />
      <Route path="/lc-fast" exact component={lcFastDashboardV2} />
      <PrivateRoute path="/marketplace" exact component={MarketPlace} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/planpayments" exact component={SubscriptionGrid} />
      <PrivateRoute path="/userdirectory" exact component={UserDirectory} />
      <PrivateRoute path="/contractdirectory" exact component={ContractDirectoryGrid} />
      <PrivateRoute path="/changepassword" exact component={ChangePassword} />
      <PrivateRoute path="/creditlineview" exact component={CreditLineUsers} />
      <PrivateRoute path="/reports" exact component={userTypeId === 8 ? reportForFinancier : Reports} />
      <PrivateRoute path="/setting" exact component={Setting} />
      <PrivateRoute path="/product" exact component={ManageProduct} />
      <PrivateRoute path="/rateCalculator" exact component={RateCalculator} />
      <PrivateRoute path="/financeCalculator" exact component={financeCalculator} />
      <PrivateRoute path="/interestRates" exact component={InterestRates} />
      <PrivateRoute path="/financerequest" exact component={FinanceRequest} />
      <PrivateRoute path="/userDetailedList" exact component={UserDetailedList} />
      <PrivateRoute path="/documentView" exact component={DocumentView} />
      <PrivateRoute path="/cases" exact component={InsuranceCasesList} />
      <PrivateRoute path="/userdetails" exact component={ChannelPartnerUserList} />
      <PrivateRoute path="/channelUsers" exact component={ChannelPartnerList} />
      <PrivateRoute path="/accountlist" exact component={AccountList} />
      <PrivateRoute path="/disbursedinvoice" exact component={DisbursedInvoice} />
      <PrivateRoute path="/raisedinvoice" exact component={RaisedInvoice} />
      <PrivateRoute path="/channelPartnerReferral" exact component={channelPartnerReferral} />


      <PrivateRoute path="/chatRoom" exact component={chatRooms} />

    </Router>
  );
}

export default App;
