

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { Accordion, Card, Button } from 'react-bootstrap'
import toastDisplay from "../../utils/toastNotification";
import { formatDate_Application } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile'
import avatarUrl from '../../utils/makeAvatarUrl';


const BillOfLading = ({ userTokenDetails, contractNo, shipContractNo, mktPlaceShipment, partyDetails, clientType, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [ownerData, setOwnerData] = useState({});
  const [dbData, setDbData] = useState({});
  const [data, setData] = useState({});
  const [blDocdata, setBlDocData] = useState({});
  const [contractUsers, setContractUsers] = useState([]);
  const [toggle, settoggle] = useState(false)
  const [blAvail, setblAvail] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [deleteConfirm, setdeleteConfirm] = useState({ modal: false, info: "" });
  const [refresh, setrefresh] = useState(0)
  const [agreeBL, setagreeBL] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  console.log("ContractNo ->", contractNo)
  console.log("shipContractNo ->", shipContractNo)
  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getblinfo', {
      "contract_number": contractNo, "ship_contract_number": shipContractNo,
      mktPlaceShipment
    }).then((result) => {
      console.log("result ->", result)
      let tempOwnerData = {
        prev_owner_name: result.blData.prev_owner_name,
        prev_owner_avatar: result.blData.prev_owner_avatar,
        prev_owner_addr: result.blData.prev_owner_addr,
        current_owner_name: result.blData.current_owner_name,
        current_owner_avatar: result.blData.current_owner_avatar,
        current_owner_addr: result.blData.current_owner_addr
      }

      setOwnerData(tempOwnerData)

      delete result.prev_owner_name
      delete result.prev_owner_avatar
      delete result.prev_owner_addr
      delete result.current_owner_name
      delete result.current_owner_avatar
      delete result.current_owner_addr

      setDbData(result.blData ? result.blData : {})
      setBlDocData(result.blDocData ? result.blDocData : {})

      let tempCheck = result.blDocData && result.blDocData.docExists ? true : false
      setblAvail(tempCheck)
      if (mktPlaceShipment && partyDetails.trader.email_id) {
        setContractUsers([partyDetails.trader])
      }
      else {
        setContractUsers(result.contractUsers)
      }
      setshowLoader(false)

    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in getblinfo ->", error)
    })

    //------------------------------------------------------------------

  }, [refresh]);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();

    if (event.target.name === "blDocument") {
      setData({ ...data, [event.target.name]: event.target.files[0] });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler

  function handleSubmit(action) {

    if (action === "insertDoc") {
      data.currentOwner = userId
    } else if (action === "submit") {
      data.ownerHistory = dbData.owner_history
      data.currentOwner = dbData.current_owner
      data.processId = dbData.id
      data.contract_id = dbData.contract_id
    } else if (action === "buyerAcceptBL" || action === "sellerSurrenderBL") {
      data.processId = dbData.id
    }
    data.action = action
    data.contract_number = contractNo
    data.ship_contract_number = shipContractNo

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    if (mktPlaceShipment) {
      formData.append("mktPlaceShipment", true)
    }

    setshowLoader(true)
    call("post", "blprocess", formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay(" successfuly", "success");
        setrefresh(refresh + 1)
        setdeleteConfirm({ modal: false, info: "" })
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in blprocess", e);
      });

  }
  //---------------------------------------------------------------------------------------------------------------------

  return (<>

    {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

    <div className="mt-5">

      {/*BL Status start */}
      <div className="col-md-12">
        <div className="row bg-shadow bg-white" style={{ "min-height": "150px" }} >
          {ownerData.prev_owner_name ? (
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="chat-message pl-2">
                  <img src={avatarUrl(ownerData.prev_owner_avatar)} alt="Avatar" width="32" height="32" />
                  <div className="chat-message-content clearfix pt-2">
                    <span className="date-format">  {ownerData.prev_owner_name ? ownerData.prev_owner_name : 'N/A'}</span><br />
                  </div>
                  <p className="date-format p-0 m-0">{ownerData.prev_owner_addr ? ownerData.prev_owner_addr : 'N/A'}</p>
                </div>
              </div>
              <div className="col-md-12">
                <label>Previous Holder of Bill Of Lading</label>
                {/* <div className="date-format">Importer Bank Account No</div> */}
              </div>
            </div>
          ) : null}
          <div className="col-md-6 border-left">
            <div className="col-md-12">
              <div className="chat-message pl-2">
                <img src={avatarUrl(ownerData.current_owner_avatar)} alt="Avatar" width="32" height="32" />
                <div className="chat-message-content clearfix pt-2">
                  <span className="date-format">  {ownerData.current_owner_name ? ownerData.current_owner_name : 'N/A'}</span><br />
                </div>
                <p className="date-format p-0 m-0">{ownerData.current_owner_addr ? ownerData.current_owner_addr : 'N/A'}</p>
              </div>
            </div>
            <div className="col-md-12">
              <label>Current Holder of Bill Of Lading</label>
              {/* <div className="date-format">Exporter Bank Account No</div> */}
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* BL Status end */}


      <div className="col-md-12 row">

        {(!blAvail && [5, 10].includes(userTypeId / 1)) && !showLoader && (aclMap.shipment_access && aclMap.shipment_access.shipment_blupload && aclMap.shipment_access.shipment_blupload.isExist) &&
          < div className="col-md-12 mt-3 mb-5">
            <h3 className="pt-2 pb-2 text-primary"><u>Upload Bill Of Lading</u></h3>
            <div className=" pb-3">
              <ul className="other-documents">
                <li>
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                </li>
                <li>
                  <h3 className="custum-control">Bill Of Lading</h3>
                </li>
                <li>
                  <input type="text" className="custum-control" name={"blNumber"} value={data.blNumber} placeholder="BL Number" onChange={handleChange} />
                </li>
                <li>
                  <div className="document-name-top">Valid Upto </div>
                  <div className="document-name-bottom">(If applicable )</div>
                </li>
                <li>
                  <input type="text" className="custum-control year" name={"blDocumentMM"} value={data.blDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                  <input type="text" className="custum-control year" name={"blDocumentYY"} value={data.blDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                </li>
                <li>
                  <div className="file-browse col-md-12">
                    <button className="btn btn-primary btn-sm col-md-12">Select Document</button>
                    <input className="col-md-12" type="file" accept=".png,.jpg,.pdf" name="blDocument" onChange={handleChange} />
                  </div>
                </li>
                <li> </li>
              </ul>
              {data.blDocument ? <div className="form-group mb-0"><div className="file-name">{`${data.blDocument.name}`}</div></div> : ""}
            </div>
            {data.blDocument &&
              <>
                <hr />
                <button className="btn btn-success btn-sm" onClick={() => handleSubmit("insertDoc")} >Submit</button>
              </>}
          </div>}

        {blAvail && (dbData.current_owner === userId || dbData.owner_history.split(",").includes(userId + "")) ?
          <div className="col-md-12 mt-3">
            <h3 className="pt-2 pb-2 text-primary"><u>Bill Of Lading</u></h3>
            <div className="table-responsive" style={{ "min-height": "150px" }}>
              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th>Type of Document</th>
                    <th width="200">Document Name</th>
                    <th width="200">Uploaded By</th>
                    <th width="150">Uploaded On</th>
                    <th className="text-center" width="150">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{blDocdata && blDocdata.doc_name ? blDocdata.doc_name : 'NA'}</td>
                    <td>{(blDocdata && blDocdata.doc_no) ? blDocdata.doc_no : 'NA'}</td>
                    <td>{(blDocdata && blDocdata.uploaded_by) ? blDocdata.uploaded_by : 'NA'}</td>
                    <td>{(blDocdata && blDocdata.created_at) ? formatDate_Application(blDocdata.created_at) : 'NA'}</td>
                    <td className="row justify-content-center  mt-0 pt-0 m-0 ">

                      <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(blDocdata, "view")}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                        () => callPreviewFileComponent(blDocdata, "download")}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> :
          blAvail && dbData.current_owner !== userId ?
            <div className="text-center col-md-12 mt-5"><h2 className="text-warning">You Do not have the authority of Bill of Lading</h2></div> :
            !blAvail && [5, 10].includes(userTypeId / 1) ? "" :
              <div className="text-center col-md-12 mt-5"><h2 className="text-warning">{showLoader ? "" : 'BL Not uploded'}</h2></div>}



        {!mktPlaceShipment && contractUsers && contractUsers.length && blAvail && dbData.current_owner === userId && dbData.bl_status === 1 && !(userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) ?
          <div className="col-md-12">
            <Accordion>
              <Card>
                <Card.Header>
                  <div className="col-md-12 row">
                    <div className="col-md-11 row">
                      <h3 className="text-primary mt-2">Submit Bill of Lading</h3>
                    </div>
                    <div className="col-md-1">
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                          {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                        </span>
                      </Accordion.Toggle>
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="col-md-12 row">

                      <div className="col-md-4">
                        <label>Submit Bill Of Lading to</label>
                      </div>
                      <div className="col-md-4">
                        <select className="form-control" name="newOwner" value={data.newOwner} onChange={handleChange} >
                          <option value="" selected>--Select User to Submit--</option>
                          {contractUsers.map((user) => {
                            return (<option value={user.tbl_user_id} >{user.company_name}</option>)
                          })}
                        </select>
                      </div>
                      <div className="col-md-4">
                        {data.newOwner ? <button title="Submit" className="text-center btn btn-danger btn-sm m-1" onClick={() => setdeleteConfirm({ modal: true, info: "submit" })}>
                          Submit
                        </button> : ""}
                      </div>

                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> : null}

        {mktPlaceShipment && userTypeId != 14 && dbData.bl_doc_id && dbData.current_owner === userId ?
          <div className="col-md-12">
            <Accordion>
              <Card>
                <Card.Header>
                  <div className="col-md-12 row">
                    <div className="col-md-11 row">
                      <h3 className="text-primary mt-2">Submit Bill of Lading</h3>
                    </div>
                    <div className="col-md-1">
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                          {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                        </span>
                      </Accordion.Toggle>
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="col-md-12 row">

                      <div className="col-md-4">
                        <label>Submit Bill Of Lading to</label>
                      </div>
                      <div className="col-md-4">
                        <select className="form-control" name="newOwner" value={data.newOwner} onChange={handleChange} >
                          <option value="" selected>--Select User to Submit--</option>
                          {contractUsers.map((user) => {
                            return (<option value={user.tbl_user_id} >{user.company_name}</option>)
                          })}
                        </select>
                      </div>
                      <div className="col-md-4">
                        {data.newOwner ? <button title="Submit" className="text-center btn btn-danger btn-sm m-1" onClick={() => setdeleteConfirm({ modal: true, info: "submit" })}>
                          Submit
                        </button> : ""}
                      </div>

                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> : null}


        {blAvail && dbData.current_owner === userId && dbData.bl_status === 1 && (userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) &&
          <div className="col-md-12 ">

            <div className="col-md-12">
              <h3 className="text-primary mt-2">Accept Bill of Lading</h3>
              <hr />
            </div>
            <div className="col-md-12">
              <div className="form-check pl-0 mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="acceptBLBuyer" id="acceptBLBuyer" onChange={(event) => { event.persist(); setagreeBL(event.target.checked) }} checked={agreeBL} />
                <label className="form-check-label" for="acceptBLBuyer"> I hereby declare that , I accept/Agree on this Bill of Lading</label>
              </div>
            </div>
            {agreeBL &&
              <div className="col-md-6 mt-3">
                <button type="button" className="btn btn-success btn-sm" onClick={() => handleSubmit("buyerAcceptBL")}>Accept BL</button>
              </div>}

          </div>}


        {blAvail && dbData.bl_status === 2 && (userTypeId === 4 || (userTypeId === 14 && clientType.type === 'seller')) &&
          <div className="col-md-12 ">

            <div className="col-md-12">
              <h3 className="text-primary mt-2">Surrender Bill of Lading to Importer/Buyer</h3>
              <hr />
            </div>
            <div className="col-md-12">
              <div className="form-check pl-0 mt-1 mb-1">
                <input className="form-check-input" type="checkbox" name="acceptBLBuyer" id="acceptBLBuyer" onChange={(event) => { event.persist(); setagreeBL(event.target.checked) }} checked={agreeBL} />
                <label className="form-check-label" for="acceptBLBuyer"> I hereby declare that , I Surrender my rights on this Bill of Lading, and transfer the full authority of B/L to the
                  Importer/Buyer</label>
              </div>
            </div>
            {agreeBL &&
              <div className="col-md-6 mt-3">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleSubmit("sellerSurrenderBL")}>Surrender BL</button>
              </div>}

          </div>}

        {blAvail && dbData.bl_status === 2 && <>
          {(userTypeId === 4 || (userTypeId === 14 && clientType.type === 'seller')) ?
            ""
            : <div className="text-center col-md-12 mt-5"><h2 className="text-success">Importer/Buyer has accepted the Bill of Lading</h2></div>}</>}

        {blAvail && dbData.bl_status === 3 && <>
          {(userTypeId === 3 || (userTypeId === 14 && clientType.type === 'buyer')) ?
            <div className="text-center col-md-12 mt-5"><h2 className="text-success">Exporter/Seller has Surrendered his all rights from Bill of Lading , Now You have the full authority on
              Bill of Lading</h2></div>
            : <div className="text-center col-md-12 mt-5"><h2 className="text-success">Exporter/Seller has Surrendered his all rights from Bill of Lading , Now Importer/Buyer has the full authority on
              Bill of Lading</h2></div>}</>}


      </div>

    </div>

    {showPreviewModal && <FilePreview
      userTokenDetails={userTokenDetails}
      fileData={fileData}
      showPreviewModal={showPreviewModal}
      setshowPreviewModal={setshowPreviewModal}
    />}

    {(deleteConfirm.modal) &&
      <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
        <div className="modal-dialog modal-lg border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Confirmation</h4>
              <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
            </div>
            <div className="modal-body">
              Please Confirm {deleteConfirm.info} Operation, Once done you will no longer have authority on Bill Of Lading
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => handleSubmit(deleteConfirm.info)}>{deleteConfirm.info.toUpperCase()}</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, info: "" })}>CANCEL</button>
            </div>
          </div>
        </div>
      </div>}

  </>)

}

//---------------------------------------------------------------------------------------------------------------------
// Redux

const mapStateToProps = state => {
  return {
    clientType: state.clientType,
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillOfLading)
