export default function validate(values) {

  let errors = {};
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

  if (!values.email) {
    errors.email = 'Email ID is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email ID is invalid';
  }
  if (!values.companyName) {
    errors.companyName = "Company Name is required";
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!strongRegex.test(values.password)) {
    errors.password = 'Password Does not meet the Policy. It requires min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol';
  }

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = "Those Passwords didn't match. Try again";
  }
  if (!values.contactNo) {
    errors.contactNo = 'Contact No is required';
  } else if (isNaN(values.contactNo)) {
    errors.contactNo = 'Contact No Should be a number';
  } else if (values.contactNo.length < 10) {
    errors.contactNo = 'Contact No should be of atleast 10 digits';
  }
  if (!values.contactPerson) {
    errors.contactPerson = "Contact Person is required";
  }
  if (!values.companyAddress) {
    errors.companyAddress = "Company Address is required";
  }
  if (!values.role) {
    errors.role = "Role is required";
  }
  if (!values.organizationType) {
    errors.organizationType = "Entity is required";
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }
  if (values.organizationType && values.organizationType === 'individual' && values.country === 'IN') {
    if (!values.aadharDocumentName) {
      errors.aadharDocumentName = 'Aadhar Number is required'
    }
    if (!values.panDocumentName) {
      errors.panDocumentName = 'PAN Number is required'
    }
    else if (values.panDocumentName.length < 10) {
      errors.panDocumentName = 'PAN Number should be of 10 digits'
    }
  }
  if (values.organizationType && values.organizationType !== 'individual' && values.country === 'IN') {
    if (!values.panDocumentName) {
      errors.panDocumentName = 'PAN Number is required'
    }
    else if (values.panDocumentName.length < 10) {
      errors.panDocumentName = 'PAN Number should be of 10 digits'
    }
    // if (!values.gstDocumentName) {
    //   errors.gstDocumentName = 'GST Number is required'
    // }
    // else if (values.gstDocumentName.length < 15) {
    //   errors.gstDocumentName = 'GST Number should be of 15 digits'
    // }
  }
  // if (values.organizationType && values.organizationType === 'pvtPubLtd' && values.country === 'IN') {
  //   if (!values.cinDocumentName) {
  //     errors.cinDocumentName = 'CIN Number is required'
  //   }
  //   else if (values.cinDocumentName.length < 21) {
  //     errors.cinDocumentName = 'CIN Number should be of 21 digits'
  //   }
  // }
  console.log(errors);
  return errors;
}