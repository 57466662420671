import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setContractDetails, setLaytimesheet,
  setContractState, setContractDetailLogi,
  setContractStateLogi, setshowModalAddComm,
  setViewTrack, setInvoiceDetail,
  setContractDocList, setPayment,
  setShowCreateProfileAdminModal, setAddShip,
  setDisbursed, setFinanceDetails, setAddDirectory, setRaiseQuotation,
  setoffContract, setChannelPartnerAccountList, setChannelPartnerDisbursedInvoice, setChannelPartnerRaisedInvoice, setShowViewProfileModal, setcreditLineDetails, setNavToggleState, setCreditlineDataBank, setCreditline, setCreditDocuments
} from '../../store/actions/action';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import logoutSession from '../../utils/logoutSession';
import avatarUrl from '../../utils/makeAvatarUrl';
import creditLineGrid from '../userProfile/creditLineGrid';
import ChangeFinanceMode from '../finance/changeFinanceType';
import { TabComponent } from '../CommonComponent/TabComponent';
import { inspectionTypeIds, shipperTypeIds } from './sidebar';
// import ApplyForCreditLine from '../creditLineComp/applyCreditLine';


const Header = ({ userTokenDetails, cDetailsState, setContractDetails,
  laytimesheet, setLaytimesheet,
  title, total, setContractDetailLogi,
  contractDetailLogi, payment,
  setContractState, setContractStateLogi,
  contractDocList, setContractDocList,
  setshowModalAddComm, setViewTrack, ViewTrack,
  setInvoiceDetail, InvoiceDetail,
  setAddShip, application,
  setApplication, setDisbursed,
  setFinanceDetails, financeDetails, disbursed,
  contractState, showModalAddComm, offContract, showViewProfileModal, setShowViewProfileModal,
  setAddDirectory, setRaiseQuotation, setoffContract, channelPartnerAccountList, setChannelPartnerAccountList, channelPartnerDisbursedInvoice, setChannelPartnerDisbursedInvoice,
  channelPartnerRaisedInvoice, setChannelPartnerRaisedInvoice,
  setcreditLineDetails, creditLineDetails,
  setCreditDocuments, CreditDocuments,
  creditlineDataBank, setCreditlineDataBank, showNavBack, onBackAction, onButtonClick, showHeaderButton,
  selectedView, onViewChange, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  let length = window.location.href.split('/').length
  let url = window.location.href.split('/')[length - 1]
  const [profile, setProfile] = useState(false);
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [subUserDetail, setSubUserDetail] = useState({});
  const [subUserCriteria, setSubUserCriteria] = useState({});
  const [financeType, setFinanceType] = useState(null);
  const [changeFinanceMode, setchangeFinanceMode] = useState({ modal: false, data: {} });
  // const [createCredit, setCreateCredit] = useState({ modal: false, data: {} })

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userAvatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  // console.log('kkkkkkkkkkkkkkkkkk ', aclMap);
  // console.log('nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn ', userPlanQuota);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects

  useEffect(() => {
    //------------------------------------------------------------------
    call('get', 'v1/plan/quota').then((result) => {
      console.log("API result getUserCurrentPlanQuota header:-->", result);
      setUserPlanQuota(result)

      setContractState(false, {})
      setshowModalAddComm(false, {})
      setcreditLineDetails(false, {})
      setCreditDocuments(false, {})

    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota header:-->", e);
    });

    call('POST', 'getSubUserName', { "subUserId": subUserId }).then((result) => {
      console.log('result in getSubUserName => ', result);
      setSubUserDetail(result);
    })

    if (parentData == true) {
      call('POST', 'getSubUserCriteria', { "subUserId": subUserId }).then((result) => {
        console.log('result in getSubUserCriteria => ', result);
        if (result.length > 0) {
          setSubUserCriteria(result[0]);
        }
      })
    }
    if (userTypeId === 19) {
      call('POST', 'getFinanceType', { 'userId': userId }).then((result) => {
        console.log('result in getFinanceType', result);
        setFinanceType(result);

      }).catch((e) => {
        console.log('error in getFinanceType', e);
      });
    }
    //------------------------------------------------------------------
  }, [contractState.info.refresh, showModalAddComm.info.refresh]);

  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // Fucntion

  function createCommodityContract() {
    if (userPlanQuota.trades && userPlanQuota.trades.enable && ((userPlanQuota.trades.limitLeft / 1) > 0 || (userPlanQuota.trades.limitLeft / 1) === -1)) {
      setContractState(true, { "modalMode": 'create' })
    } else {
      if (userPlanQuota.trades && userPlanQuota.trades.enable)
        toastDisplay("You have already used " + userPlanQuota.trades.limitUsed + " out of " + userPlanQuota.trades.totalLimit + " " + userPlanQuota.trades.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  function addNewCommodity() {
    if (aclMap.commodity_access && aclMap.commodity_access.commodity_addAdmin && aclMap.commodity_access.commodity_addAdmin.isExist) {
      setshowModalAddComm(true, { "type": "addNewCommodityAdmin" })
    } else {
      if (userPlanQuota.commodities && userPlanQuota.commodities.enable && ((userPlanQuota.commodities.limitLeft / 1) > 0 || (userPlanQuota.commodities.limitLeft / 1) === -1)) {
        setshowModalAddComm(true, { "type": "addNewCommodityUser" })
      } else {
        if (userPlanQuota.trades && userPlanQuota.commodities.enable)
          toastDisplay("You have already used " + userPlanQuota.commodities.limitUsed + " out of " + userPlanQuota.commodities.totalLimit + " " + userPlanQuota.commodities.desc + " Quota", "warn")
        else
          toastDisplay("Plan Quota details are not available", "warn")
      }
    }
  }

  function applyNewFinance() {
    if (userPlanQuota.finance && userPlanQuota.finance.enable && ((userPlanQuota.finance.limitLeft / 1) > 0 || (userPlanQuota.finance.limitLeft / 1) === -1)) {
      setFinanceDetails(true, 0, 0, { action: "applyFinance" })
    } else {
      if (userPlanQuota.trades && userPlanQuota.finance.enable)
        toastDisplay("You have already used " + userPlanQuota.finance.limitUsed + " out of " + userPlanQuota.finance.totalLimit + " " + userPlanQuota.finance.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  function setFinanceTypeOnHeader() {
    if (financeType === 'Invoice Discounting') {
      setFinanceDetails(true, 3, 1, { action: "applyFinance", type: "INVOICE" })
    }
    if (financeType === 'Letter of Credit (LC) Discounting') {
      setFinanceDetails(true, 2, 1, { action: "applyFinance", type: "LC" })
    }
    if (financeType === 'Freight Finance') {
      setFinanceDetails(true, 6, 1, { action: "applyFinance", type: "FREIGHT" })
    }
  }

  function applyNewCredit() {
    if (userTypeId / 1 === 19) {
      return setcreditLineDetails(true, { action: "applycreditLine" })
    }
    if (userPlanQuota.creditLine && userPlanQuota.creditLine.enable) {
      setcreditLineDetails(true, { action: "applycreditLine" })
    } else {
      if (userPlanQuota.trades && userPlanQuota.creditLine && userPlanQuota.creditLine.enable)
        toastDisplay("You have already used " + userPlanQuota.creditLine.limitUsed + " out of " + userPlanQuota.creditLine.totalLimit + " " + userPlanQuota.creditLine.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  function applyNewProduct() {
    if (userTypeId / 1 === 19 || userTypeId / 1 === 8) {
      return setcreditLineDetails(true, { action: "applyProduct" })
    }
    if (userPlanQuota.creditLine && userPlanQuota.creditLine.enable) {
      setcreditLineDetails(true, { action: "applyProduct" })
    } else {
      if (userPlanQuota.trades && userPlanQuota.creditLine && userPlanQuota.creditLine.enable)
        toastDisplay("You have already used " + userPlanQuota.creditLine.limitUsed + " out of " + userPlanQuota.creditLine.totalLimit + " " + userPlanQuota.creditLine.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  const logout = () => {
    logoutSession()
  }


  //---------------------------------------------------------------------------------------------------------------------

  // console.log("contractDocList header==>", contractDocList)
  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (<>
    <div className="d-flex breadcrumb-bg">
      {/* UI RESP CHANGE */}
      <button className="open-menu">
        <div></div>
        <div></div>
        <div></div>
      </button>
      {/* UI RESP CHANGE */}


      <ul className="breadcrumb-left">
        {(cDetailsState.modal && !contractDocList.modal && !ViewTrack.modal && !InvoiceDetail.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="contractDetailsBack" onClick={() => { setContractDetails(null); }}></i>
        </li>}
        {(cDetailsState.modal && contractDocList.modal && !ViewTrack.modal && !InvoiceDetail.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="contractDocBack" onClick={() => {
            if (contractDocList.info && contractDocList.info.direct) {
              setContractDetails(null);
              setContractDocList(null);
            } else {
              setContractDocList(null);
            }
          }}></i>
        </li>}
        {(cDetailsState.modal && !contractDocList.modal && ViewTrack.modal && !InvoiceDetail.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="contractTrackBack" onClick={() => { setViewTrack(false, {}); }}></i>
        </li>}
        {(cDetailsState.modal && !contractDocList.modal && !ViewTrack.modal && InvoiceDetail.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="contractInvoiceBack" onClick={() => { setInvoiceDetail(false, {}); }}></i>
        </li>}
        {(contractDetailLogi.page && !laytimesheet.state && !ViewTrack.modal && !InvoiceDetail.modal) && < li >
          {/* <i className="fa fa-angle-left" aria-hidden="true" id="shipmentDetailsBack" onClick={() => { setContractDetailLogi(false, {}) }}></i> */}
          <i className="fa fa-angle-left" aria-hidden="true" id="shipmentDetailsBack" onClick={() => { title === 'Contract Details Documents' ? setContractDocList(null) : setContractDetailLogi(false, {}) }}></i>
        </li>}
        {(contractDetailLogi.page && !contractDocList.modal && !ViewTrack.modal && !laytimesheet.state && InvoiceDetail.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="shipmentInvoiceBack" onClick={() => { setInvoiceDetail(false, {}); }}></i>
        </li>}
        {(laytimesheet.state) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="laytimeSheetBack" onClick={() => setLaytimesheet(false, "")}></i>
        </li>}
        {(contractDetailLogi.page && ViewTrack.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setViewTrack(false, {})}></i>
        </li>}
        {(url === 'contractdirectory' && offContract.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setoffContract(false, {})}></i>
        </li>}

        {(url === 'accountlist' && channelPartnerAccountList.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="ChannelAccountListBack" onClick={() => setChannelPartnerAccountList(false, {})}></i>
        </li>}

        {(url === 'disbursedinvoice' && channelPartnerDisbursedInvoice.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="ChannelAccountListBack" onClick={() => setChannelPartnerDisbursedInvoice(false, {})}></i>
        </li>}

        {(url === 'dashboard' && channelPartnerAccountList.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="ChannelAccountListBack" onClick={() => setChannelPartnerAccountList(false, {})}></i>
        </li>}

        {(url === 'channelUsers' && showViewProfileModal && showViewProfileModal.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="profileTrackBack" onClick={() => setShowViewProfileModal(false, {})}></i>
        </li>}

        {showNavBack &&
          <li>
            <i className="fa fa-angle-left" aria-hidden="true" id="contractTrackBack" onClick={() => { onBackAction() }}></i>
          </li>
        }

        {url === 'creditlineview' &&
          (creditlineDataBank.info.hasOwnProperty('allData') ?
            <li>
              <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setCreditlineDataBank(true, {})}></i>
            </li>
            :
            (creditlineDataBank.modal ?
              <li>
                <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setCreditlineDataBank(false, {})}></i>
              </li>
              :
              ""
            )
          )
        }

        {url == 'creditLine' &&
          (creditLineDetails.info.hasOwnProperty('action') &&
            <li>
              <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setcreditLineDetails(false, {})}></i>
            </li>
          )
        }

        {url == 'creditLine' &&
          (CreditDocuments.info.hasOwnProperty('action') &&
            <li>
              <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setCreditDocuments(false, {})}></i>
            </li>
          )
        }

        {url == 'product' &&
          (creditLineDetails.info.hasOwnProperty('action') &&
            <li>
              <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setcreditLineDetails(false, {})}></i>
            </li>
          )
        }

        {/* {(url === 'creditLine' && creditline.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="shipemntTrackBack" onClick={() => setCreditline(false, {})}></i>
        </li>} */}


        {(financeDetails.status && financeDetails.type === 0 && financeDetails.page === 0) ? <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => setFinanceDetails(false, 0, 0, {})}></i>
        </li> :
          (userTypeId === 19 && financeDetails.status && (financeDetails.type > 0) && financeDetails.page === 1 && (financeDetails.info.action === "applyFinance")) ? <li>
            <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => setFinanceDetails(false, 0, 0, {})}></i>
          </li> :
            (financeDetails.status && (financeDetails.type > 0) && financeDetails.page === 1 && (financeDetails.info.action === "applyFinance")) ? <li>
              <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => setFinanceDetails(financeDetails.status, 0, 0, {})}></i>
            </li> :
              (financeDetails.status && (financeDetails.type > 0) && financeDetails.page === 1 && (financeDetails.info.action === "viewFinance")) ? <li>
                <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => { setFinanceDetails(false, 0, 0, {}); setChannelPartnerRaisedInvoice(false, {}) }}></i>
              </li> :
                (financeDetails.status && (financeDetails.type > 0) && financeDetails.page === 2 && (financeDetails.info.action === "viewFinance")) ? <li>
                  <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => { setFinanceDetails(false, 0, 0, {}); setChannelPartnerRaisedInvoice(false, {}) }}></i>
                </li> : ''}

        {creditLineDetails.modal && creditLineDetails.info.action === "applycreditLine" &&
          <i className="fa fa-angle-left" aria-hidden="true" id="loanPageBack" onClick={() => setcreditLineDetails(creditLineDetails.info.action, {})}></i>
        }


        {(disbursed.modal) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="disburedPageBack" onClick={() => setDisbursed(false, {})}></i>
        </li>}
        {(application && application.flag) && <li>
          <i className="fa fa-angle-left" aria-hidden="true" id="applicationPageBack" onClick={() => setApplication(false)}></i>
        </li>}
        <li>
          <h2>{title}</h2>
          <p>{total}</p>
        </li>
      </ul>

      {/* ---------------------------------------------------------------------------------------------------------------------------*/}
      {/* {userTypeId === 19 &&
        <>
          <div className="ml-auto pr-4">
            <ul className="nav-top d-flex flex-md-row">
              <li>
                <h3>Finance Type: {financeType}</h3>
              </li>
              <li>
                <button className="btn btn-success btn-sm" onClick={() => setchangeFinanceMode({ modal: true, data: {} })}>Change Finance Type</button>
              </li>
            </ul>
          </div>
        </>
      } */}

      <div className="ml-auto pr-4">
        <ul className="nav-top d-flex flex-md-row">
          {(!cDetailsState.modal && !payment.state && url === 'contract' && title != 'Workorder Details' &&
            (aclMap.contract_access && aclMap.contract_access.cont_create && aclMap.contract_access.cont_create.isExist)) &&
            <li>
              {/* create commodity contract button */}
              <button className="btn btn-success btn-sm" onClick={() => createCommodityContract()} disabled={parentData == true && subUserCriteria.commodityContracts == '0'} >
                <span>Create Contract</span>
                {userPlanQuota.trades && userPlanQuota.trades.enable &&
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class={(userPlanQuota.trades.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                      {(userPlanQuota.trades.limitLeft / 1) === -1 ? "" : userPlanQuota.trades.limitLeft}
                    </i>
                  </span>}
              </button>
            </li>}

          {/* {(aclMap.credit_line && aclMap.credit_line.mng_financial_details && aclMap.credit_line.mng_financial_details.isExist && url == "creditLine") &&
            <li>
              <button className="btn btn-success btn-sm" onClick={() => setCreateCredit({ modal: true, data: { id: "1" } })} >
                <span>Create Credit Line</span>
                {userPlanQuota.trades && userPlanQuota.trades.enable &&
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class={(userPlanQuota.trades.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                      {(userPlanQuota.trades.limitLeft / 1) === -1 ? "" : userPlanQuota.trades.limitLeft}
                    </i>
                  </span>}
              </button>
            </li>} */}

          {/* {(url === 'po') && <li>
            <button className="btn btn-success btn-sm"
              onClick={onButtonClick}>
              Create Contract
            </button>
          </li>} */}

          {(url === 'rate-list') && <li>
            <button className="btn btn-success btn-sm"
              onClick={onButtonClick}>
              Upload Rate List
            </button>
          </li>}

          {(url === 'interestRates') && <li>
            <button className="btn btn-success btn-sm"
              onClick={onButtonClick}>
              Upload Interest Rates
            </button>
          </li>}

          {(url.includes('chatRoom')) && <li>
            <button className="btn btn-success btn-sm"
              onClick={onButtonClick}>
              Create Chat Room
            </button>
          </li>}

          {(url === 'lc-fast') && showHeaderButton && userTypeId != 8 && userTypeId != 1 && <li>
            <button className="btn btn-success btn-sm"
              onClick={onButtonClick}>
              Apply LC Quote
            </button>
          </li>}

          {(url === 'my-quotations') && !inspectionTypeIds.includes(userTypeId) &&
            !shipperTypeIds.includes(userTypeId) &&
            <div className='mr-4'>
              <TabComponent
                tabs={['Shipping', 'Inspection']}
                selectedTab={selectedView}
                onTabChange={(index) => onViewChange(index)}
              />
            </div>
          }

          {(url === 'commoditieslist' &&
            ((aclMap.commodity_access && aclMap.commodity_access.commodity_addAdmin && aclMap.commodity_access.commodity_addAdmin.isExist) ||
              (aclMap.commodity_access && aclMap.commodity_access.commodity_addUser && aclMap.commodity_access.commodity_addUser.isExist))) && <li>
              {/* new commodity add button */}
              {!showNavBack && (
                <button className="btn btn-info btn-sm" onClick={() => addNewCommodity()} disabled={parentData == true && subUserCriteria.manageCommodities == "0"}>
                  New Commodity
                  {userPlanQuota.commodities && userPlanQuota.commodities.enable && (aclMap.commodity_access && aclMap.commodity_access.commodity_addUser && aclMap.commodity_access.commodity_addUser.isExist) &&
                    <span class="fa-stack">
                      <i class="far fa-circle fa-stack-2x"></i>
                      <i class={(userPlanQuota.commodities.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                        {(userPlanQuota.commodities.limitLeft / 1) === -1 ? "" : userPlanQuota.commodities.limitLeft}
                      </i>
                    </span>}
                </button>
              )}
            </li>}

          {(url === 'ships' && (aclMap.ships_access && aclMap.ships_access.ships_add && aclMap.ships_access.ships_add.isExist)) && <li>
            <button className="btn btn-info btn-sm" onClick={() => setAddShip(true, {})}>Add Vessel</button>
          </li>}

          {(url === 'userdirectory' && (aclMap.mng_directory && aclMap.mng_directory.dir_adduser && aclMap.mng_directory.dir_adduser.isExist)) && <li>
            <button className="btn btn-info btn-sm" onClick={() => setAddDirectory(true, {})} disabled={parentData == true && subUserCriteria.personalDirectory == "0"}>Add New User</button>
          </li>}

          {/* {(url === 'quotationlist') && <li>
            <button className="btn btn-info btn-sm" onClick={() => setRaiseQuotation(true, {})}>Raise Quotation</button>
          </li>} */}

          {(url === 'contractdirectory' && !offContract.modal && (aclMap.cont_directory && aclMap.cont_directory.dir_addcontract && aclMap.cont_directory.dir_addcontract.isExist)) && <li>
            <button className="btn btn-info btn-sm" onClick={() => setoffContract(true, { type: "create" })} disabled={parentData == true && subUserCriteria.contractsDirectory == "0"}>Add New Contract</button>
          </li>}

          {/* finance apply button */}
          {(url === 'finance' && (userTypeId === 4 || userTypeId === 14 || userTypeId === 19 || userTypeId === 5)) && !financeDetails.status &&
            < li >
              <button className="btn btn-success btn-sm" onClick={() => { applyNewFinance(); }} disabled={parentData == true && subUserCriteria.finance == "0"}>
                <span>Apply For Finance</span>
                {userPlanQuota.finance && userPlanQuota.finance.enable &&
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class={(userPlanQuota.finance.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                      {(userPlanQuota.finance.limitLeft / 1) === -1 ? "" : userPlanQuota.finance.limitLeft}
                    </i>
                  </span>}
              </button>
            </li>}

          {/* credit apply button */}
          {(url === 'creditLine' && (userTypeId === 4 || userTypeId === 14 || userTypeId === 19)) && creditLineDetails.info.action !== "applycreditLine" && creditLineDetails.info.action !== "pendingTab" &&
            < li >
              <button className="btn btn-success btn-sm" onClick={() => applyNewCredit()} disabled={parentData == true && subUserCriteria.creditLines == "0"}>
                <span>Create Credit Line</span>
                {userPlanQuota.creditLine && userPlanQuota.creditLine.enable &&
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    {/* <i class={(userPlanQuota.creditLine.limitLeft / 1) === -1 ? "fas fa-infinity fa-stack-1x" : ""}>
                      {(userPlanQuota.creditLine.limitLeft / 1) === -1 ? "" : userPlanQuota.creditLine.limitLeft}
                    </i> */}
                    <i class="fas fa-infinity fa-stack-1x"></i>
                  </span>}
              </button>
            </li>}

          {/* Create Product */}
          {url === 'product' &&
            (creditLineDetails.info.action !== "applyProduct") &&
            < li >
              <button className="btn btn-success btn-sm" onClick={() => applyNewProduct()}>
                <span>Create Product</span>
                {userPlanQuota.creditLine && userPlanQuota.creditLine.enable &&
                  <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class="fas fa-infinity fa-stack-1x"></i>
                  </span>}
              </button>
            </li>}


          {/* <li className="text-truncate" style={{ "max-width": "200px" }}>Hi {subUserDetail.subUserName}</li> */}
          <li style={{ "line-height": "10px" }}>
            <h2>{subUserDetail.contact_person}</h2>
            <p>{subUserDetail.company_name}</p>
          </li>
          <li onClick={() => setProfile(profile ? false : true)}>
            <span className="profile-icon">
              <img src={avatarUrl(subUserDetail.subUserAvatar)} alt="Avatar" />
            </span>
            {profile && <div id="ProfileCard" >
              <ul>
                <a href={'/dashboard'} ><li><i class="fas fa-user fa-lg text-primary"></i> {subUserDetail.subUserName}</li></a>
                <a href={'/editprofile'} ><li><i class="fas fa-user-edit fa-lg text-primary"></i>Edit Profile</li></a>
                <a href={'/changepassword'} ><li><i class="fas fa-key fa-lg text-primary"></i>Change Password</li></a>
                <li onClick={() => logout()}><i class="fas fa-sign-out-alt fa-lg text-primary"></i> Logout</li>
              </ul>
            </div>}
          </li>
          <li><a href={'/notifications'} ><i class="fas fa-bell fa-lg"></i><span className="task-label"></span></a></li>
        </ul>
      </div>

      {/* {createCredit.modal &&
        <ApplyForCreditLine userTokenDetails={userTokenDetails} createCredit={createCredit} setCreateCredit={setCreateCredit} />
        // </div>
      } */}
    </div >

    {/* UI RESP CHANGE */}
    <ul class="breadcrumb-show">
      <li>
        <h2>{title}</h2>
        <p>{total}</p>
      </li>
    </ul>
    {/* UI RESP CHANGE */}

    {changeFinanceMode.modal &&
      <ChangeFinanceMode userTokenDetails={userTokenDetails} setchangeFinanceMode={setchangeFinanceMode} />
    }
  </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    contractLogi: state.contractLogi,
    contractDocList: state.contractDocList,
    payment: state.payment,
    showCreateProfileAdminModal: state.showCreateProfileAdminModal,
    ViewTrack: state.ViewTrack,
    InvoiceDetail: state.InvoiceDetail,
    contractDetailLogi: state.contractDetailLogi,
    laytimesheet: state.laytimesheet,
    showAddShip: state.showAddShip,
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    creditLineDetails: state.creditLineDetails,
    CreditDocuments: state.CreditDocuments,
    showModalAddComm: state.showModalAddComm,
    addDirectory: state.addDirectory,
    offContract: state.offContract,
    channelPartnerAccountList: state.channelPartnerAccountList,
    channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice,
    channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    showViewProfileModal: state.showViewProfileModal,
    creditlineDataBank: state.creditlineDataBank,
    creditline: state.creditline
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: false })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractStateLogi: (flag, data) => { dispatch(setContractStateLogi({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ cId: id, modal: false, info: data })) },
    setPayment: (state) => { dispatch(setPayment({ state: state })) },
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) },
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) },
    setAddShip: (flag, data) => { dispatch(setAddShip({ modal: flag, info: data })) },
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) },
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
    setContractDetailLogi: (flag, data) => { dispatch(setContractDetailLogi({ page: flag, info: data })) },
    setLaytimesheet: (state, cNumber) => { dispatch(setLaytimesheet({ state: state, cNo: cNumber })) },
    setDisbursed: (flag, data) => { dispatch(setDisbursed({ modal: flag, info: data })) },
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
    setAddDirectory: (modal, info) => { dispatch(setAddDirectory({ modal: modal, info: info })) },
    setRaiseQuotation: (modal, info) => { dispatch(setRaiseQuotation({ modal: modal, info: info })) },
    setoffContract: (modal, info) => { dispatch(setoffContract({ modal: modal, info: info })) },
    setChannelPartnerAccountList: (modal, info) => { dispatch(setChannelPartnerAccountList({ modal: modal, info: info })) },
    setChannelPartnerRaisedInvoice: (modal, info) => { dispatch(setChannelPartnerRaisedInvoice({ modal: modal, info: info })) },
    setChannelPartnerDisbursedInvoice: (modal, info) => { dispatch(setChannelPartnerDisbursedInvoice({ modal: modal, info: info })) },
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) },
    setCreditlineDataBank: (modal, info) => { dispatch(setCreditlineDataBank({ modal: modal, info: info })) },
    setcreditLineDetails: (status, info) => { dispatch(setcreditLineDetails({ status: status, info: info })) },
    setCreditDocuments: (status, info) => { dispatch(setCreditDocuments({ status: status, info: info })) },
    setCreditline: (modal, info) => { dispatch(setCreditline({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
