import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import avatarUrl from '../../utils/makeAvatarUrl';
import ReactCountryFlag from "react-country-flag";
import validate from '../../utils/validation_files/channelPartnerProfile';

const ChannelPartnerRegistration = ({ userTokenDetails, adminProfile, status, onClose }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // states and variables
  const [data, setdata] = useState({ finTechType: true });
  const [errors, seterrors] = useState({});
  const [countryData, setcountryData] = useState([]);
  const [userData, setUserData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userEmail = userTokenDetails ? userTokenDetails.email ? userTokenDetails.email : null : null;
  const userTypeId = userTokenDetails ? userTokenDetails.type_id ? userTokenDetails.type_id : null : null;
  //---------------------------------------------------------------------------------------------------------------------

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------
  // Useeffect
  useEffect(() => {

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errors]);
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleAvatar = (event) => {
    event.persist();
    console.log('fileUPloaded:==>', event.target.files[0])
    if (event.target.files && event.target.files[0] && event.target.files[0].size && event.target.files[0].size <= 5242880) {
      seterrors(errors => ({ ...errors, [event.target.name]: "" }))
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setdata(values => ({ ...values, avatarBase64: reader.result, avatarFile: event.target.files[0] }));
      };
    } else {
      seterrors(errors => ({ ...errors, [event.target.name]: "Max size limit is 5MB" }))
      setdata(values => ({ ...values, avatarBase64: "" }));
    }
  }

  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const hanleDocChecks = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.checked }));
  }

  console.log('dataa => ', data);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    seterrors(validate(data));
    setIsSubmitting(true);
  };

  async function submitForm() {
    data.adminProfile = adminProfile;
    data.typeId = 20;
    data.status = status;
    let doKyc = false
    console.log("insidesubmitForm", data);
    if (data.organizationType === "pvtPubLtd" && data.cinDocumentName && data.gstDocumentName) {
      doKyc = true
    }
    else if (data.gstDocumentName) {
      doKyc = true
    }
    if (data.country != "IN") {
      data.setKycTrue = true
    }
    setshowLoader(true)
    if (doKyc) {
      let result = await call('POST', 'getKYCDetail', data)
      if (result.company_name != '') {
        // console.log(result.company_name);
        data["gst_company_detail"] = result.gst_company_detail ? result.gst_company_detail : null
        data["iec_company_detail"] = result.iec_company_detail ? result.iec_company_detail : null
        data["cin_company_detail"] = result.cin_company_detail ? result.cin_company_detail : null
      }
    }
    console.log("beforecallingregistration", data);
    call('POST', 'registration', data).then((result) => {
      setshowLoader(false)
      toastDisplay(result, "success");
      onClose()
      setrefresh(refresh + 1)
    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Subimit handlers

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <>
      <div className="modal-body calc-inner-modal">
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h3 className="green border-bottom pb-2">Create Profile :</h3>
                </div>
                <div className="col-md-3">
                  <div className="profile-sidebar">
                    <div className="profile-userpic">
                      <img src={data.avatarBase64 ? data.avatarBase64 : avatarUrl(data.userAvatar)} id="myImg" className="img-responsive" alt="Avatar" />
                      <span className="icon-edit-profile">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                        <input type="file" accept=".png,.jpg" id="file_3" dir="2" name="avatar" onChange={handleAvatar} />
                      </span>
                    </div>
                    <div className="profile-userbuttons text-center mt-2 ">
                      <label>Avatar {astrix} </label>
                    </div>
                    {errors.avatar && <p className="text-danger error-contract">{errors.avatar}</p>}
                  </div>

                </div>
                <div className="col-md-9">
                  <form className="pr-4">
                    <div className="row pt-3">
                      <div className="form-group col-md-6">
                        <label>Select Role {astrix}  </label>
                        <select className={" form-control" + (!errors.role ? '' : ' border-danger')} name="role" value={data.role} onChange={handleChange} >
                          <option value="" selected>Select Role</option>
                          {/* <option value="introducer">Introducer</option> */}
                          <option value="CP">Channel Partner</option>
                          <option value="FP">Franchise Partner</option>
                          {/* <option value="techAggregator">Tech Aggregator</option> */}
                        </select>
                        {errors.role && <p className="text-danger error-contract">{errors.role}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Company Name {astrix} </label>
                        <input type="text" className={" form-control" + (!errors.companyName ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="companyName" value={data.companyName} onChange={handleChange} required />
                        {errors.companyName && <p className="text-danger error-contract">{errors.companyName}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Contact Person {astrix} </label>
                        <input type="text" className={" form-control" + (!errors.contactPerson ? '' : ' border-danger')} placeholder="Enter Contact Person Name" name="contactPerson" value={data.contactPerson} onChange={handleChange} required />
                        {errors.contactPerson && <p className="text-danger error-contract">{errors.contactPerson}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Email ID {astrix} </label>
                        <input type="text" className={" form-control" + (!errors.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={data.email} onChange={handleChange} required />
                        {errors.email && <p className="text-danger error-contract">{errors.email}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Password {astrix}</label>
                        <input type="password" className={" form-control" + (!errors.password ? '' : ' border-danger')} placeholder="Min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol" name="password" value={data.password} onChange={handleChange} required />
                        {errors.password && (<p className="text-danger error-contract">{errors.password}</p>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Re-enter password {astrix}</label>
                        <input type="password" className={" form-control" + (!errors.passwordConfirm ? '' : ' border-danger')} placeholder="Re-enter password.." name="passwordConfirm" value={data.passwordConfirm} onChange={handleChange} required />
                        {errors.passwordConfirm && (<p className="text-danger error-contract">{errors.passwordConfirm}</p>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Contact No. {astrix} </label>
                        <input type="number" pattern=".{10,13}" className={" form-control" + (!errors.contactNo ? '' : ' border-danger')} placeholder="Enter Contact No" name="contactNo" value={data.contactNo} onChange={handleChange} required />
                        {errors.contactNo && <p className="text-danger error-contract">{errors.contactNo}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Country {astrix}  </label>
                        <select className={" form-control" + (!errors.country ? '' : ' border-danger')} name="country" value={data.country} onChange={handleChange} >
                          <option value="" selected>Select Country</option>
                          {countryData.map((country) => {
                            return (
                              <option value={country.sortname} >{country.name}</option>
                            )
                          })}
                        </select>
                        {errors.country && <p className="text-danger error-contract">{errors.country}</p>}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Select Entity {astrix}  </label>
                        <select className={" form-control" + (!errors.organizationType ? '' : ' border-danger')} name="organizationType" value={data.organizationType} onChange={handleChange} >
                          <option value="" selected>Select entity</option>
                          <option value="individual">Individual</option>
                          <option value="soleProprietorship">Sole proprietorship</option>
                          <option value="partnership">Partnership</option>
                          <option value="llp">LLP</option>
                          <option value="pvtPubLtd">Pvt / Pub Ltd</option>
                        </select>
                        {errors.organizationType && <p className="text-danger error-contract">{errors.organizationType}</p>}
                      </div>

                      <div className="form-group col-md-12">
                        <label>Address {astrix} </label>
                        <textarea className={" form-control" + (!errors.companyAddress ? '' : ' border-danger')} placeholder="Enter Flat / House / Office No" name="companyAddress" onChange={handleChange} value={data.companyAddress} required />
                        {errors.companyAddress && <p className="text-danger error-contract">{errors.companyAddress}</p>}
                      </div>

                      {(data.country === 'IN') && data.organizationType &&
                        // <><h3 className="mb-4" id="KYCDoc" >KYC Documents {astrix}</h3>
                        <><h3 className="mb-4" id="KYCDoc" >KYC Documents</h3>
                          <div id="newDoc">
                            {data.organizationType === 'individual' && <div className="form-group pb-0 mb-0">
                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      PAN Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={10} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors["panDocumentName"] ? '' : ' border-danger')} placeholder={"Enter PAN Number"} name="panDocumentName" onChange={handleChange} required />
                                    {errors["panDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["panDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"PAN Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>
                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      Aadhar Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={20} className={" form-control" + (!errors["aadharDocumentName"] ? '' : ' border-danger')} placeholder={"Enter Aadhar Number"} name="aadharDocumentName" onChange={handleChange} required />
                                    {errors["aadharDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["aadharDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"Aadhar Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>
                            </div>}
                            {(data.organizationType !== 'individual') && <div className="form-group pb-0 mb-0">
                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      PAN Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={10} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors["panDocumentName"] ? '' : ' border-danger')} placeholder={"Enter PAN Number"} name="panDocumentName" onChange={handleChange} required />
                                    {errors["panDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["panDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"PAN Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>
                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      GST Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={15} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors["gstDocumentName"] ? '' : ' border-danger')} placeholder={"Enter GST Number"} name="gstDocumentName" onChange={handleChange} required />
                                    {errors["gstDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["gstDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"GST Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>

                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      IEC Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={30} pattern="" className={" form-control" + (!errors["iecDocumentName"] ? '' : ' border-danger')} placeholder={"Enter IEC Number"} name="iecDocumentName" onChange={handleChange} required />
                                    {errors["iecDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["iecDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"IEC Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>
                            </div>}
                            {data.organizationType === 'pvtPubLtd' && <div className="form-group pb-0 mb-0">
                              <div className="row">
                                <ul className="other-documents">
                                  <li style={{ "min-width": "350px" }}>
                                    <label className="mb-0" >
                                      <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                      CIN Number
                                    </label>
                                  </li>
                                  <li style={{ "min-width": "350px" }}>
                                    <input type="text" maxLength={21} pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}" className={" form-control" + (!errors["cinDocumentName"] ? '' : ' border-danger')} placeholder={"Enter CIN Number"} name="cinDocumentName" onChange={handleChange} required />
                                    {errors["cinDocumentName"] ? <p className="text-danger error-contract p-0 m-0">{errors["cinDocumentName"]}</p> : <p className="text-dark error-contract p-0 m-0">{"CIN Number"}</p>}
                                  </li>
                                </ul>
                                <hr className="" />
                              </div>
                            </div>}
                          </div>
                        </>}

                      {data.role && (
                        <>
                          <div className=" col-md-12 row mt-2">
                            <div className=" col-md-6">
                              <label>{`Select User Type of ${data.role === "CP" ? "Channel Partner" : "Franchise Partner"}`}</label>
                              {/* <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox" name="tradeTechType" id="tradeTechType" onChange={hanleDocChecks} checked={data.tradeTechType} />
                                <label className="form-check-label" for="tradeTechType">Trade-Tech</label>
                              </div> */}
                            </div>
                          </div>

                          <div className=" col-md-12 row mt-2">
                            <div className=" col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="finTechType" id="finTechType" onChange={hanleDocChecks} checked={data.finTechType} />
                                <label className="form-check-label" for="finTechType">Fin-Tech</label>
                              </div>
                            </div>
                          </div>

                          {/* <div className=" col-md-12 row mt-2">
                            <div className=" col-md-6">
                              <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox" name="logiTechType" id="logiTechType" onChange={hanleDocChecks} checked={data.logiTechType} />
                                <label className="form-check-label" for="logiTechType">Logi-Tech</label>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className=" col-md-12 row mt-2">
                            <div className=" col-md-6">
                              <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox" name="insureTechType" id="insureTechType" onChange={hanleDocChecks} checked={data.insureTechType} />
                                <label className="form-check-label" for="insureTechType">Insure-Tech</label>
                              </div>
                            </div>
                          </div> */}
                        </>
                      )}

                      <br />
                      <br />
                      <br />
                      <div className="col-md-10 mt-1">
                        <div className="form-check pl-0 mt-1 mb-1">
                          <input className="form-check-input" type="checkbox" name="termsAndCondition" id="termsAndCondition" onChange={hanleDocChecks} checked={data.termsAndCondition} />
                          <label className="form-check-label" for="termsAndCondition">
                            I acknowledge that i have read and fully understand the <a href="./assets/docs/Terms_of_Use.pdf" target="true">terms and conditions</a> of the
                            tradereboot onboarding agreement and that i will comply with the same.
                          </label>
                        </div>
                      </div>


                    </div>
                  </form>
                  {(data.tradeTechType || data.finTechType || data.logiTechType || data.insureTechType) && data.termsAndCondition &&
                    <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => handleSubmit()}>Submit</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}


export default ChannelPartnerRegistration