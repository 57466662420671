export default function validate(values) {

  let errors = {};

  if (!values.productName) {
    errors.productName = 'Field Missing'
  }
  if (!values.approvedFinanceAmount) {
    errors.approvedFinanceAmount = 'Field Missing';
  }
  if (!values.fundingPercentage) {
    errors.fundingPercentage = 'Field Missing';
  }
  if (!values.fundingTenor) {
    errors.fundingTenor = 'Field Missing';
  }
  if (!values.minInvoice) {
    errors.minInvoice = 'Field Missing';
  }
  if (!values.maxCredit) {
    errors.maxCredit = 'Field Missing';
  }
  if (!values.minCredit) {
    errors.minCredit = 'Field Missing';
  }
  if (!values.interestRate) {
    errors.interestRate = 'Field Missing';
  }
  if (!values.interestCollection) {
    errors.interestCollection = 'Field Missing';
  }
  if (!values.finRequestPercentage) {
    errors.finRequestPercentage = 'Field Missing';
  }
  if (!values.finRequestCollection) {
    errors.finRequestCollection = 'Field Missing';
  }
  if (!values.sanctionCountry) {
    errors.sanctionCountry = 'Field Missing';
  }
  if (!values.sanctionPort) {
    errors.sanctionPort = 'Field Missing';
  }
  if (!values.commodities) {
    errors.commodities = 'Field Missing';
  }
  if (!values.currency) {
    errors.currency = 'Field Missing';
  }
  if (!values.offerValidity) {
    errors.offerValidity = 'Field Missing';
  }
  if (!values.minInvoice) {
    errors.minInvoice = 'Field Missing';
  }
  if (!values.maxCredit) {
    errors.maxCredit = 'Field Missing';
  }
  if (!values.minCredit) {
    errors.minCredit = 'Field Missing';
  }

  console.log(errors);
  return errors;
}