const columns = [
  {
    name: 'Avatar',
    selector: 'user_avatar',
    grow: 0
  },
  {
    name: 'Exporter',
    selector: 'company_name',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contact Person',
    selector: 'contact_person',
    sortable: false,
  },
  {
    name: 'Buyers (In Number)',
    selector: 'no_of_buyers',
    sortable: false,
    grow: 0
  },
  {
    name: 'Address',
    selector: 'user_address',
    sortable: false,
  },
  {
    name: 'Country',
    selector: 'country_code',
    sortable: false,
    grow: 0
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
    grow: 0
  }
];



export default columns