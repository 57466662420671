import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/images/logo_1.png";
import { ToastContainer } from "react-toastify";
// import SelectField from "../common-components/SelectField";
import axios from "axios";
import BgImg from '../assets/images/sign_up_bg.png'
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import { platformBackendUrl } from "../../urlConstants";
import { decryptData, encryptData } from "../../utils/myFunctions";
import { getUserDataFromCookie, removeCookieandAvatar, setCookieInAPP } from "../../utils/cookieHelper";
import config from '../../config.json';

const NewLogin = ({ props }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let us = queryParams.get('attr1')
  let pw = queryParams.get('attr2')

  const refOtp1 = useRef(null);
  const refOtp2 = useRef(null);
  const refOtp3 = useRef(null);
  const refOtp4 = useRef(null);
  const refOtp5 = useRef(null);
  const refOtp6 = useRef(null);

  const [data, setData] = useState(us ? { email: decryptData(us), password: decryptData(pw) } : {})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [securePw, toggleSecurePw] = useState(true);
  const [loginWithOtp, toggleLoginWithOtp] = useState(false);
  const [otpSent, toggleOtpSent] = useState(false);
  const [forgotPw, toggleForgotPw] = useState(false)
  const [forgotPwLinkSent, toggleForgotPwLinkSent] = useState(false);

  useEffect(() => {
    if (us && data.email && data.password) {
      onLoginClick()
    }
  }, [us, data])



  useEffect(() => {
    try {
      // Fetch IP
      fetch(
        "https://geolocation-db.com/json/")
        .then((res) => res.json())
        .then((json) => {
          // console.log("userippp", json);
          if (json.IPv4) {
            localStorage.setItem("attr1", encryptData(json.IPv4))
          }
        }).catch(e => {

        })
      // Fetch Location
      navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem("attr2", encryptData(position.coords.latitude + ", " + position.coords.longitude))
      });
    } catch (error) {
    }
    //User details from cookie
    let userDetails = getUserDataFromCookie()
    let userTypeId = userDetails.type_id ? userDetails.type_id : null
    let userToken = userDetails.token ? userDetails.token : null
    let status = userDetails.status ? userDetails.status : null

    console.log("userDetails===>", userDetails);

    //Redirect if user already logged in
    if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
      window.location = "dashboard"
    }
    else {
      removeCookieandAvatar()
    }

  }, [])

  const handleChange = (e) => {
    e.persist()
    if (e.target.name.includes('otp') && e.target.value) {
      let currentIndex = e.target.name.split("p")[1] / 1
      if (currentIndex == 1) {
        refOtp2.current.focus()
      }
      if (currentIndex == 2) {
        refOtp3.current.focus()
      }
      if (currentIndex == 3) {
        refOtp4.current.focus()
      }
      if (currentIndex == 4) {
        refOtp5.current.focus()
      }
      if (currentIndex == 5) {
        refOtp6.current.focus()
      }
    }
    setData({ ...data, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: "" })
  }

  function onLoginWithOtpClick() {
    if (!data.otp1 || !data.otp2 || !data.otp3 || !data.otp4 || !data.otp5 || !data.otp6) {
      setErrors({ ...errors, "otp": "Please enter OTP" })
    }
    else {
      let loginPayload = {
        username: data.email,
        otp: data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
      }
      loginPayload.attr1 = localStorage.getItem("attr1")
      loginPayload.attr2 = localStorage.getItem("attr2")
      setshowLoader(true)
      call('post', 'login', loginPayload).then((result) => {
        if (result) {
          // console.log("result in login==>", result)
          localStorage.setItem("last_login_at", result.last_login_at)
          setCookieInAPP(result)
          if (result.status / 1 === 1) {
            let redirectTo = localStorage.getItem("redirectTo")
            localStorage.removeItem("redirectTo")
            window.location = redirectTo ? redirectTo : "/dashboard"
          }
          else {
            toastDisplay("Admin verification is pending, Please try later or contact administrator !!", "warn");
          }
        }
        setshowLoader(false)
      }).catch(e => {
        setshowLoader(false)
        console.log("Error in login====>", e)
        toastDisplay(e, "error");
      })
    }
  }

  function onLoginClick() {
    let err = {}
    if (!data.email) {
      err["email"] = "Email is mandatory"
    }
    if (!data.password) {
      err["password"] = "Password is mandatory"
    }
    if (!Object.keys(err).length) {
      let loginPayload = {
        username: data.email,
        password: encryptData(data.password)
      }
      loginPayload.attr1 = localStorage.getItem("attr1")
      loginPayload.attr2 = localStorage.getItem("attr2")
      setshowLoader(true)
      call('post', 'login', loginPayload).then((result) => {
        if (result) {
          // console.log("result in login==>", result)
          localStorage.setItem("last_login_at", result.last_login_at)
          setCookieInAPP(result)
          if (result.status / 1 === 1) {
            let redirectTo = localStorage.getItem("redirectTo")
            localStorage.removeItem("redirectTo")
            window.location = redirectTo ? redirectTo : "/dashboard"
          }
          else {
            toastDisplay("Admin verification is pending, Please try later or contact administrator !!", "warn");
          }
        }
        setshowLoader(false)
      }).catch(e => {
        setshowLoader(false)
        console.log("Error in login====>", e)
        toastDisplay(e, "error");
      })
    }
    else {
      toastDisplay('Form validation error', "error")
    }
    setErrors(err)
  }

  const onSendOtpClick = (e) => {
    e.preventDefault()
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      setErrors({ ...errors, email: 'Please enter a valid email id.' })
      return;
    }
    errors.email = '';
    setshowLoader(true)
    call('post', 'sendOtp', { email: data.email, baseUrl: config.baseUrl }).then((res) => {
      toggleOtpSent(true)
      toastDisplay("OTP has been sent to your registered email id", "success");
      setData({ ...data, "otp1": "", "otp2": "", "otp3": "", "otp4": "", "otp5": "", "otp6": "" })
      setshowLoader(false)
    }).catch((e) => {
      toastDisplay(e, "error");
      setshowLoader(false)
    });
  }

  return (
    <div
      style={{
        backgroundImage: `url(${BgImg})`,
      }}
      className="bg-img justify-content-center d-flex"
    ><ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div
        style={{ width: '25rem', borderRadius: '1rem' }}
        className="bg-white align-self-center py-3 shadow"
      >
        <div className="text-center py-4">
          <img onClick={() => { window.location = "/" }}
            style={{}}
            className="cursor" src={Logo} alt="logo" height="60px"
          />
        </div>
        <h1 className="font-wt-500 font-size-18 text-center">Login</h1>

        <div className="p-4 justify-content-center d-flex flex-column align-items-center">
          {otpSent ? (
            <>
              <label className="cursor w-90" >Enter OTP</label>
              <div className="form-group mb-4 w-90 d-flex flex-row justify-content-between"
                style={{ height: "3rem" }}
              >
                <input type="text" id="otp1" ref={refOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp1" value={data.otp1} onChange={handleChange} />
                <input type="text" id="otp2" ref={refOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp2" value={data.otp2} onChange={handleChange} />
                <input type="text" id="otp3" ref={refOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp3" value={data.otp3} onChange={handleChange} />
                <input type="text" id="otp4" ref={refOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp4" value={data.otp4} onChange={handleChange} />
                <input type="text" id="otp5" ref={refOtp5} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp5" value={data.otp5} onChange={handleChange} />
                <input type="text" id="otp6" ref={refOtp6} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp6" value={data.otp6} onChange={handleChange} />
              </div>
              {errors.otp && <label className="w-90 text-danger">{errors.otp}</label>}
              <label className="cursor w-90 light-font" >An OTP has been sent on your registered email id</label>
              <label
                onClick={onSendOtpClick}
                className="cursor w-90 mb-4" >Resend OTP</label>
            </>
          ) : (
            <div className="form-group mb-4 w-90">
              <input type="text" className={" form-control" + (!errors.email ? '' : ' border-danger')} placeholder="Enter Email Id" name="email" value={data.email} onChange={handleChange} />
              {errors.email && <p className="text-danger error-contract">{errors.email}</p>}
            </div>
          )}

          {loginWithOtp ? (
            <button type="button"
              onClick={otpSent ? onLoginWithOtpClick : onSendOtpClick}
              className={`new-btn py-2 w-90 text-white mb-2`}>
              {otpSent ? "Login" : "Send OTP"}
            </button>
          ) : (
            <>
              {!forgotPw ? (
                <div className="form-group w-90 position-relative">
                  <i
                    onClick={() => toggleSecurePw(!securePw)}
                    className={`fas ${!securePw ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i>
                  <input type={securePw ? "password" : 'text'} className={" form-control" + (!errors.password ? '' : ' border-danger')} placeholder="Enter Password" name="password" value={data.password} onChange={handleChange} />

                  {errors.password && <p className="text-danger error-contract">{errors.password}</p>}
                </div>
              ) : null}
            </>
          )}

          {forgotPw ? (
            <label className="cursor w-90 light-font" >Password reset request registered (if account associated with given mail exists). Please check your email for further instructions.</label>
          ) : null}

          {/* {!loginWithOtp && !forgotPw ? (
            <label
              onClick={() => toggleForgotPw(true)}
              className="cursor w-90" >Forgot password?</label>
          ) : null} */}

          {!forgotPw ? (
            <label
              onClick={() => { toggleLoginWithOtp(!loginWithOtp); toggleOtpSent(false) }}
              className={`cursor w-90 ${loginWithOtp ? "mb-4" : ""}`}>{loginWithOtp ? 'Log in with password' : "Log in with OTP?"}</label>
          ) : null}

          {!loginWithOtp || forgotPw && !forgotPwLinkSent ? (
            <button type="button"
              onClick={onLoginClick}
              className={`new-btn py-2 w-90 mb-4 text-white my-4`}>
              {forgotPw ? "Send Reset Link" : "Login"}
            </button>
          ) : (
            null
          )}

          <label className="font-wt-400 font-size-14" >New to Trade reboot? <span
            onClick={() => { window.location = 'registration' }}
            className="text-primary cursor">Sign up</span></label>
        </div>



      </div>



    </div >

  )
}

export default NewLogin;