import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/myCounterDetailsGrid';
import customStyles from '../dataTablesColumsMap/customTableCss';
import toastDisplay from '../../utils/toastNotification';
import AssignedCreditToBuyer from '../userProfile/assignedCreditToBuyer';
import { formatDate_Application } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile'

const MyCounterDetailsGrid = ({ userTokenDetails, buyerCredit, manualUserId, manualUserName, manualUserEmail }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [data, setData] = useState({})
  const [error, setError] = useState({});
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("");
  const [bankData, setBankData] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState({ modal: false, id: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [potentialLimit, setPotentialLimit] = useState([]);
  const [viewBuyerCredit, setViewBuyerCredit] = useState({ modal: false, status: [] });
  const [viewSingleBuyerLimit, setViewSingleBuyerLimit] = useState({ modal: false, data: {} });
  const [limitToBuyer, assignLimitToBuyer] = useState({ modal: false, data: {} });
  const [bankList, setBankList] = useState([]);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------
  }, [refresh, filterData]);

  useEffect(() => {
    call('POST', 'getBankersList', {})
      .then((result) => {
        console.log("result in getBankersList===>", result)
        setBankData(result);
      })
      .catch((err) => {
        console.log("error:", err)
      });

    call('GET', 'getBanksListMaster').then((result) => {
      setBankList(result);
    });

  }, [])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  useEffect(() => {
    call('POST', 'getPotentialLimit', { userId: manualUserId ? manualUserId : userId, userEmail: manualUserEmail ? manualUserEmail : userEmail }).then((result) => {
      console.log('running getPotentialLimit api-->', result);
      setPotentialLimit(result);
    }).catch((e) => {
      console.log('error in getPotentialLimit', e);
    });
  }, [buyerCredit.length > 0])
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState('');


  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": manualUserId ? manualUserId : userId,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      console.log('running getBuyersDetail api-->', result);
      settableData(formatDataFortable(result.buyerData));
      setTotalRows(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {
      settableData(formatDataFortable(result.buyerData))
      setTotalRows(result.countData)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
      setLoading(false)
    })
  };

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let buyersCredit = JSON.parse(dataArray[i].buyers_credit);
      let tempObj = {}
      tempObj.buyerName = dataArray[i].buyerName;
      tempObj.buyerEmail = dataArray[i].buyerEmail ? dataArray[i].buyerEmail : 'NA';
      tempObj.buyerContact = dataArray[i].buyerPhone ? dataArray[i].buyerPhone : 'NA';
      tempObj.buyerAddress = dataArray[i].buyerAddress ? dataArray[i].buyerAddress : 'NA';
      tempObj.buyerCountry = dataArray[i].countryName;
      tempObj.buyerDUNSNo = dataArray[i].buyerDUNSNo;
      tempObj.annualSale = dataArray[i].previousAnnualSale + ' ' + dataArray[i].buyerCurrency.split(':')[1];
      tempObj.expectedAnnualSale = dataArray[i].currentAnnualSale + ' ' + dataArray[i].buyerCurrency.split(':')[1];

      let doc = dataArray[i].buyersDocId.split(':');
      {
        doc[0] ? tempObj.invoiceDoc =
          <div className="row">
            <button className="btn btn-link btn-sm p-0 m-0 col-12" onClick={() => callPreviewFileComponent(doc[0], "view")}>
              View
            </button>
            <button className="btn btn-link btn-sm p-0 m-0 col-12" onClick={() => callPreviewFileComponent(doc[0], "download")}>
              Download
            </button>
          </div> : tempObj.invoiceDoc = 'NA'
      }
      {
        doc[1] ? tempObj.poDoc =
          <div className="row">
            <button className="btn btn-link btn-sm p-0 m-0 col-12" onClick={() => callPreviewFileComponent(doc[1], "view")}>
              View
            </button>
            <button className="btn btn-link btn-sm p-0 m-0 col-12" onClick={() => callPreviewFileComponent(doc[1], "download")}>
              Download
            </button>
          </div>
          : tempObj.poDoc = 'NA'
      }

      tempObj.lenderName = 'NA';
      tempObj.assignedCredit = 'NA';
      tempObj.creditRemark = 'NA';

      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      tempObj.action = <>
        <button className="btn btn-primary btn-sm ml-1" onClick={() => setViewSingleBuyerLimit({ modal: true, data: dataArray[i] })}>
          <i className="fa fa-eye" aria-hidden="true"></i>
        </button>
        {userTypeId == 1 &&
          <button className="btn btn-primary btn-sm ml-1" onClick={() => assignLimitToBuyer({ modal: true, data: dataArray[i] })}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
        }
        <button className="btn btn-primary btn-sm ml-1" onClick={() => downloadSingleCSV(dataArray[i])}>
          <i className="fa fa-download" aria-hidden="true"></i>
        </button>
        <button className="btn btn-danger btn-sm" onClick={() => setdeleteConfirm({ modal: true, id: dataArray[i].id })} >
          <i className={"fas fa-trash"} aria-hidden="true"></i>
        </button>
      </>

      resultArray.push(tempObj);
    }
    return resultArray
  }


  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = manualUserId
    data.bankId = userId
    call('POST', "insertusercreditdetailbybbank", data).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 2)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };


  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  function ExportTableCSV() {

    let objectAPI = {
      "userId": manualUserId ? manualUserId : userId,
      "userTypeId": userTypeId
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyersDetail', objectAPI).then((result) => {

      const dbResult = result.buyerData;
      let csvString = "Buyer Name,Email,Contact No.,Address,Country Code,DUNS No.,Annual Sale(Previous Year),Expected Annual Sale(Current Year)\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let buyersCredit = JSON.parse(dbResult[i].buyers_credit);
        let rowString = `${dbResult[i].buyerName ? dbResult[i].buyerName : "NA"},
          ${dbResult[i].buyerEmail ? dbResult[i].buyerEmail : "NA"},
          ${dbResult[i].buyerPhone ? dbResult[i].buyerPhone : "NA"},
          ${dbResult[i].buyerAddress ? dbResult[i].buyerAddress : "NA"},
          ${dbResult[i].buyerCountry ? dbResult[i].buyerCountry : "NA"},
          ${dbResult[i].buyerDUNSNo ? dbResult[i].buyerDUNSNo : "NA"},
          ${dbResult[i].previousAnnualSale ? dbResult[i].previousAnnualSale + " " + dbResult[i].buyerCurrency.split(':')[1] : "NA"},
          ${dbResult[i].currentAnnualSale ? dbResult[i].currentAnnualSale + " " + dbResult[i].buyerCurrency.split(':')[1] : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }

      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `My_Counter_Parts_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    })
  }

  function downloadSingleCSV(data) {
    let buyersCredit = JSON.parse(data.buyers_credit);
    let string = '';
    if (buyersCredit.length > 0) {
      for (let i = 0; i < buyersCredit.length; i++) {
        string += buyersCredit[i].lender_name + ',' + buyersCredit[i].lender_name + ' Assigned Credit,' + buyersCredit[i].lender_name + ' Remark, '
      }
    }
    let csvString = "Buyer Name,Email,Contact No.,Address,Country Code,DUNS No.,Annual Sale(Previous Year),Expected Annual Sale(Current Year)," + string + "\r\n"
    let credit = JSON.parse(data.buyers_credit);
    let rowString = `${data.buyerName ? data.buyerName : "NA"},
    ${data.buyerEmail ? data.buyerEmail : "NA"},
    ${data.buyerPhone ? data.buyerPhone : "NA"},
    ${data.buyerAddress ? data.buyerAddress : "NA"},
    ${data.buyerCountry ? data.buyerCountry : "NA"},
    ${data.buyerDUNSNo ? data.buyerDUNSNo : "NA"},
    ${data.previousAnnualSale ? data.previousAnnualSale : "NA"},
    ${data.currentAnnualSale ? data.currentAnnualSale : "NA"},
    ${credit && credit.length && credit.map((value) => {
      return (`${value.lender_name ? value.lender_name : "NA"},
      ${value.assignValue ? value.assignValue + " " + data.buyerCurrency.split(':')[1] : "NA"},
      ${value.assignRemark ? value.assignRemark : "NA"}`)
    })}
    \r\n`

    rowString = rowString.replace(/(\n)/gm, "");
    csvString += rowString;

    let link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
    link.setAttribute('download', `${data.buyerName}_Counter_Parts.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function deleteBuyerDetails(finDtId) {

    call('POST', 'deleteBuyerDetails', { finDtId: finDtId }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1);
      setdeleteConfirm({ modal: false, id: '' });
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      toastDisplay(e, "error");
    })

  }

  function getBuyerCredit() {
    call('POST', 'getBuyerCredit', { userName: userName }).then((result) => {
      if (result == "Success") {
        toastDisplay(result, "success");
      }
      else {
        toastDisplay(result, "error");
      }
      setrefresh(refresh + 1);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
      toastDisplay(e, "error");
    })
  }

  function viewCredits(data) {
    let buyersCredit = JSON.parse(data.buyers_credit);
    return (
      <div className="col-md-12">
        <div className="row pt-2">

          <div className="d-flex mt-3 ">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="table-responsive" style={{ "min-height": "150px" }}>
              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr style={{ fontSize: "14px" }}>
                    <th width="20">#</th>
                    <th width="300">Lender Name</th>
                    <th width="300">Assign Credit</th>
                    <th width="300">Assign Remark</th>
                    <th width="300">Date</th>
                  </tr>
                </thead>
                {buyersCredit && buyersCredit.length && buyersCredit.map((values, key) => {
                  return (<tbody>
                    <tr style={{ fontSize: "14px" }}>
                      <td >{key + 1}</td>
                      <td>{values.lender_name}</td>
                      {/* <td >{values.assignValue + ' ' + data.buyerCurrency.split(':')[1]}</td> */}
                      <td >{values.financeLimit ? (values.financeLimit + ' ' + values.financeLimitCurrency) : null}</td>
                      <td >{values.assignRemark}</td>
                      <td >{formatDate_Application(data.created_at)}</td>
                    </tr>
                  </tbody>)
                })}
              </table>
            </div>
          </div>
          {/* // : <div className="col-md-12"><h3 className="text-center">No Limits Assigned.</h3></div> */}
          {/* // } */}
        </div>
      </div>
    )
  }

  function assignCredit(buyerDetail) {
    data.buyer_id = buyerDetail.id;
    data.insertion_type = 'manual';

    call('POST', "insertcreditofferbybbank", data).then((result) => {
      console.log('post request result:', result);
      if (result == true) {
        toastDisplay('Assigned Limit', "success");
      }
      else {
        toastDisplay('Limit is already assigned by the lender', "error");
      }
      setshowLoader(false)
      setrefresh(refresh + 2)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>

      <div className="creditline_listing">

        <ul className="nav nav-tabs-custom btn m-0 p-0" id="myTab" role="tablist">
          <li>
            <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>Buyers List</a>
          </li>
          {userTypeId != 20 && bankList.map((bank, key) => {
            return (
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === (key + 1) ? " active show" : "")} onClick={() => {
                  setTab(key + 1);
                  setTabName(`${bank.company_name}`);
                }}>{bank.company_name}</a>
              </li>
            )
          })
          }
        </ul>

        {tab === 0 &&
          <>
            <h2 className="text-primary"></h2>
            <div className="d-md-flex mt-3 mb-3">

              <div className="ml-md-auto relative">
                <ul className="range-slider">
                  <li>
                    <input name="search" placeholder="Search" value={searchKey} onChange={event => setsearchKey(event.target.value)} id="searchInput" onKeyDown={handleKeyPress} />
                    <button type="button" onClick={() => {
                      setFilterData({
                        ...filterData,
                        "search": searchKey
                      })
                    }}><i class="fas fa-search fa-lg"></i></button>
                  </li>
                  <li>
                    <input className={"form-control"} name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" type="date" />
                  </li>
                  <li>
                    <input className={"form-control"} name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" type="date" />
                  </li>
                  {/* <li><input type="button" value="Get Buyer Credit" className="btn btn-primary btn-sm ml-1" onClick={() => setViewBuyerCredit({ modal: true, status: buyerCredit })} /></li> */}
                  <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>

                </ul>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />
            <div className="float-left col-md-6 ">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div>
          </>
        }

        {bankList.map((bank, key) => {
          return (<>
            {tab === (key + 1) &&
              <>
                {(userTypeId == 1 || userTypeId == 20) ? <AssignedCreditToBuyer userTokenDetails={userTokenDetails} bank={bank} manualUserId={manualUserId} manualUserName={manualUserName} manualUserEmail={manualUserEmail} />
                  :
                  <AssignedCreditToBuyer userTokenDetails={userTokenDetails} bank={bank} manualUserId={userId} manualUserName={userName} manualUserEmail={userEmail} />}
              </>
            }
          </>)
        })}
      </div>


      {deleteConfirm.modal &&
        <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setdeleteConfirm({ modal: false, id: '' }) }}>×</button>
              </div>
              <div className="modal-body">
                Please Confirm Delete Operation
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteBuyerDetails(deleteConfirm.id)}>Delete</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, id: "" })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {viewBuyerCredit.modal &&
        <div className={"modal" + (viewBuyerCredit.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setViewBuyerCredit({ modal: false, status: [] }) }}>×</button>
              </div>
              <div className="modal-body calc-modal">
                <div className="table-responsive" style={{ "min-height": "150px" }}>
                  <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                    <thead>
                      <tr style={{ fontSize: "14px" }}>
                        <th>#</th>
                        <th width="300">Buyer Name</th>
                        {bankData.map((bank, key) => {
                          return (<>
                            <th width="300">{bank.company_name} Status</th>
                            <th width="300">{bank.company_name} Potential Limit</th>
                          </>)
                        })}
                        <th width="300">STENN Status</th>
                        <th width="300">STENN Potential Limit</th>
                      </tr>
                    </thead>
                    {/* {viewPotentialLimit()} */}
                    {potentialLimit.map((values, key) => {
                      return (
                        <tbody>
                          <tr style={{ fontSize: "14px" }}>
                            <td >{key + 1}</td>
                            <td >{values.companyName}</td>
                            <td style={{
                              backgroundColor: values.status == 'New' ? 'rgba(255, 193, 7, 0.9)'  //yellow
                                :
                                'rgba(63, 195, 128, 0.9)',  //green
                              color: 'white'
                            }}>{values.status}</td>
                            <td >{values.potentialLimit}</td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                </div>
              </div>
              <div className="modal-footer primary">
                {/* <button type="button" className="btn btn-danger btn-sm">Delete</button> */}
                <button type="button" className="btn btn-info btn-sm" onClick={() => setViewBuyerCredit({ modal: false, status: [] })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }

      {limitToBuyer.modal &&
        <div className={"modal" + (limitToBuyer.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Assign Limit</h4>
                <button type="button" className="close" onClick={() => { assignLimitToBuyer({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body">
                <>
                  <div className="modal-padding">
                    <ul className="price-ul">
                      <li>
                        <div className="price-left">{limitToBuyer.data.buyerName}</div>
                        <div className="price-right">Buyer Name </div>
                      </li>
                      <li>
                        <div className="price-left">{limitToBuyer.data.buyerEmail}</div>
                        <div className="price-right">Buyer Email </div>
                      </li>
                      <li>
                        <div className="price-left"> {limitToBuyer.data.buyerPhone} </div>
                        <div className="price-right">Contact No</div>
                      </li>
                      <li>
                        <div className="price-left">{limitToBuyer.data.buyerAddress}</div>
                        <div className="price-right">Buyer Address</div>
                      </li>
                      <li>
                        <div className="price-left"> {limitToBuyer.data.previousAnnualSale}</div>
                        <div className="price-right">Previous Sale</div>
                      </li>
                      <li>
                        <div className="price-left"> {limitToBuyer.data.currentAnnualSale}</div>
                        <div className="price-right">Expected Sale</div>
                      </li>
                      <li>
                        <div className="price-left"> {limitToBuyer.data.productDetails}</div>
                        <div className="price-right">Product Details</div>
                      </li>
                      <li>
                        <div className="price-left"> {limitToBuyer.data.termsOfPayment}</div>
                        <div className="price-right">Terms of Payment</div>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="row pt-2">

                      <div className="form-group col-md-4">
                        <label>Select Lender</label>
                        <select className={" form-control" + (!error.buyerLender ? '' : ' border-danger')} name="buyerLender" value={data.buyerLender} onChange={handleChange}>
                          <option value="" selected> --Select Lender-- </option>
                          <option value="1:Admin">Admin</option>
                          {bankData.map((values) => {
                            return (<option value={values.id + ":" + values.company_name}>{values.company_name}</option>)
                          })}
                        </select>
                      </div>

                      <div className="form-group col-md-4">
                        <label>Assign Credit </label>
                        <input type="text" className={" form-control" + (!error.buyersCredit ? '' : ' border-danger')} placeholder="" name="buyersCredit" value={data.buyersCredit} onChange={handleChange} />
                        {error.buyersCredit && <p className="text-danger error-contract">{error.buyersCredit}</p>}
                      </div>

                      <div className="form-group col-md-4">
                        <label>Remark </label>
                        <input type="text" className={" form-control" + (!error.buyersRemark ? '' : ' border-danger')} placeholder="" name="buyersRemark" value={data.buyersRemark} onChange={handleChange} />
                        {error.buyersRemark && <p className="text-danger error-contract">{error.buyersRemark}</p>}
                      </div>


                    </div>
                  </div> */}
                </>
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-info btn-sm" disabled={true} onClick={() => assignCredit(limitToBuyer.data)}>Assign Credit</button>
                <button type="button" className="btn btn-dark btn-sm" onClick={() => assignLimitToBuyer({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }

      {viewSingleBuyerLimit.modal &&
        <div className={"modal" + (viewSingleBuyerLimit.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Assigned Limit</h4>
                <button type="button" className="close" onClick={() => { setViewSingleBuyerLimit({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body">
                <>
                  {viewCredits(viewSingleBuyerLimit.data)}
                </>
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-dark btn-sm" onClick={() => setViewSingleBuyerLimit({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }
      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
      />}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCounterDetailsGrid)
