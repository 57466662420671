import React, { useEffect, useState } from 'react';
import { setCreditlineDataBank, setCreditDocuments } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import Footer from '../partial/footer';
import DataTable from 'react-data-table-component';
import toastDisplay from '../../utils/toastNotification';
import columns, { columns2 } from '../dataTablesColumsMap/reportsListColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { formatDate_Application } from '../../utils/dateFormaters';
import FilePreview from '../utilComponents/previewFile';
import config from '../../config.json';

const ReportForFinancier = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [filterData, setFilterData] = useState({});
  const [refresh, setrefresh] = useState(0);
  const [bankData, setBankData] = useState({});
  const [supplierList, setSupplierList] = useState({});
  const [buyerList, setBuyerList] = useState({});
  const [lead, setLead] = useState({ "modal": false, "id": 0 });
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [filterCount, setFilterCount] = useState({})
  let creditData = {}


  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [fileData, setfileData] = useState({});

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null;
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null;
  const userEmail = userTokenDetails ? userTokenDetails.email ? userTokenDetails.email : null : null;
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>



  useEffect(() => {

    fetchTableData(1, {});

  }, [filterData]);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  console.log('error => ', error)

  const handleCreditChange = e => {
    e.persist()
    creditData[e.target.name] = e.target.value
  }


  const assignCreditToBuyer = (i, repData) => {
    // console.log("inside assignCreditToBuyer", creditData, i, repData);
    if (!(creditData["assignedCredit" + i] && creditData["assignedRemark" + i])) {
      toastDisplay("Credits & remarks can't be blank", "info")
      return null
    }
    let object = {
      "assignValue": creditData["assignedCredit" + i],
      "assignRemark": creditData["assignedRemark" + i],
      "buyer_id": repData["buyerId"],
      "buyerEmail": repData["buyerEmail"],
      "lender_id": userId,
      "lender_name": userName,
      "lender_email": userEmail,
      "userId": repData["user_id"],
      "userName": repData["company_name"],
      "userEmail": repData["companyEmailId"],
      "id": 8
    }
    call('POST', 'assignCreditToBuyer', {
      "data": [object], "baseUrl": config,
      "traderUserId": repData["user_id"],
      "buyerName": repData["buyerName"],
      "lenderName": userName
    }).then((result) => {
      // console.log('running assignCreditToBuyer api-->', result);
      toastDisplay(result, "success")
    }).catch((e) => {
      // console.log('error in assignCreditToBuyer', e);
      toastDisplay("Error", "error")
    });
  }

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    if (!data.leadBy) {
      setError({ ...error, ["leadBy"]: "Please select lead by" });
    }
    else {
      setError({});
    }
    setIsSubmitting(true);
  };

  const handleSubmit = () => {

    data.buyer_id = lead.id;

    setshowLoader(true);
    call('POST', 'updateLeadBy', data).then((result) => {
      setshowLoader(false)
      setLead({ "modal": false, "id": 0 })
      toastDisplay(result, "success")
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("");
  const [completeButton, setcompleteButton] = useState(0);

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": userId,
      "onlyPending": filterData.status === 0 ? true : false,
      "onlyRemarked": filterData.status === 1 ? true : false
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerDetailsForFinancier', objectAPI).then((result) => {
      console.log('running getBuyerDetailsForFinancier api-->', result);
      setLoading(false);
      settableData(formatDataFortable(result.data));
      setSupplierList(result.supplierList);
      setBuyerList(result.buyerList)
      setTotalRows(result.totalCount);
      setFilterCount(result.filterCount)
    }).catch((e) => {
      setLoading(false);
      // console.log('error in getBuyerDetailsForFinancier', e);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "onlyPending": filterData.status === 0 ? true : false,
      "onlyRemarked": filterData.status === 1 ? true : false
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getBuyerDetailsForFinancier', objectAPI).then((result) => {
      console.log('running getBuyerDetailsForFinancier api-->', result);
      setLoading(false);
      settableData(formatDataFortable(result.data));
      setTotalRows(result.totalCount);
      // setBuyerList(result.buyerList);
    }).catch((e) => {
      setLoading(false);
      // console.log('error in getBuyerDetailsForFinancier', e);
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.dateofestablishment = dataArray[i].date_of_establishment;
      // tempObj.lead_by = (dataArray[i].lead_by ? dataArray[i].lead_by : 'NA');
      tempObj.suppliername = dataArray[i].company_name || "NA";
      tempObj.industryType = dataArray[i].industry_type || 'NA';
      tempObj.buyerDUNS = dataArray[i].buyerDUNSNo || "NA ";
      tempObj.buyerCountry = dataArray[i].buyerCountry || "NA";
      tempObj.buyerCountryName = dataArray[i].country_name || "NA";
      tempObj.buyerTermsOfPayment = dataArray[i].termsOfPayment || "NA";
      tempObj.buyerProductDetails = dataArray[i].productDetails || "NA";
      tempObj.buyerPreviousYearSale = dataArray[i].previousAnnualSale || "NA";
      tempObj.buyerExpectedSale = dataArray[i].currentAnnualSale || "NA";
      tempObj.buyername = dataArray[i].buyerName || "NA";
      tempObj.incoTerms = dataArray[i].incoTerms || "NA";
      tempObj.creditData = dataArray[i].buyers_credit && JSON.parse(dataArray[i].buyers_credit)
      tempObj.creditData = tempObj.creditData && tempObj.creditData.length && tempObj.creditData.filter(item => {
        if (item.lender_id / 1 == userId) {
          return item
        }
      })[0]
      creditData["assignedCredit" + i] = tempObj.creditData ? tempObj.creditData["assignValue"] : ""
      creditData["assignedRemark" + i] = tempObj.creditData ? tempObj.creditData["assignRemark"] : ""
      tempObj.assignedCredit =
        <input key={dataArray[i]["created_at"]} type="text" name={"assignedCredit" + i} placeholder=""
          defaultValue={creditData["assignedCredit" + i]}
          className={"form-control"} onChange={handleCreditChange} />
      tempObj.assignedRemark =
        <input key={dataArray[i]["created_at"]} type="text" name={"assignedRemark" + i} placeholder=""
          defaultValue={creditData["assignedRemark" + i]}
          className={"form-control"} onChange={handleCreditChange} />
      tempObj.action = <button
        className="btn btn-success btn-sm" onClick={() => assignCreditToBuyer(i, dataArray[i])}>
        SAVE
      </button>
      let buyerCurrency = dataArray[i].buyerCurrency ? dataArray[i].buyerCurrency.split(":") : "-"
      tempObj.buyerCurrency = (buyerCurrency.length > 1 ? buyerCurrency[1] : "NA") || "NA";
      tempObj.prevInvDoc =
        <button className="btn btn-success btn-sm" onClick={() => callPreviewFileComponent(dataArray[i].buyersDocId.split(':')[0], "view")}>
          <i className={"fa fa-eye"}></i>
        </button>

      tempObj.prevPODoc =
        <button className="btn btn-success btn-sm" onClick={() => callPreviewFileComponent(dataArray[i].buyersDocId.split(':')[1], "view")} >
          <i className={"fa fa-eye"}></i>
        </button>
      // tempObj.stennLimit = (dataArray[i].stenn ? dataArray[i].stenn : 'NA');
      // tempObj.stennRemarks = (dataArray[i].stenn_remark ? dataArray[i].stenn_remark : 'NA');
      // tempObj.modifiLimit = (dataArray[i].modifi ? dataArray[i].modifi : 'NA');
      // tempObj.modifiRemarks = (dataArray[i].modifi_remark ? dataArray[i].modifi_remark : 'NA');
      // tempObj.tradewindLimit = (dataArray[i].tradewind ? dataArray[i].tradewind : 'NA');
      // tempObj.tradewindRemarks = (dataArray[i].tradewind_remark ? dataArray[i].tradewind_remark : "NA");

      tempObj.created_at = formatDate_Application(dataArray[i].created_at);

      tempObj.selleridentifier = dataArray[i].selleridentifier
      tempObj.buyeridentifier = dataArray[i].buyeridentifier
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // functions

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }


  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const reset = () => {
    setFilterData({ supplierType: "", buyerType: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "onlyPending": filterData.status === 0 ? true : false,
      "onlyRemarked": filterData.status === 1 ? true : false
    }

    objectAPI = { ...objectAPI, ...filterData }


    call('POST', 'getBuyerDetailsForFinancier', objectAPI).then((result) => {
      console.log('running getBuyerDetailsForFinancier api in csv-->', result);

      const dbResult = result.data
      let csvString = "Date of Establishment,Seller Id,Buyer Id,Supplier Name,Industry Type,Buyer Name,DUNS No,Country Code,Country Name,Buyer Currency,Terms of Payment,Inco Terms,Product Details,Previous Annual Sale,Expected Sale,Created At,Assigned Credits,Assigned Remarks\r\n"

      for (let i = 0; i < dbResult.length; i++) {
        let lenderCreditData = dbResult[i].buyers_credit && JSON.parse(dbResult[i].buyers_credit)
        lenderCreditData = lenderCreditData && lenderCreditData.length && lenderCreditData.filter(item => {
          if (item.lender_id / 1 == userId) {
            return item
          }
        })[0]
        let buyerCurrency = dbResult[i].buyerCurrency ? dbResult[i].buyerCurrency.split(":") : "-"
        let rowString = `${dbResult[i].date_of_establishment},
        ${dbResult[i].selleridentifier || "NA"},
        ${dbResult[i].buyeridentifier || "NA"},
        ${dbResult[i].supplier_name || "NA"},
        ${dbResult[i].industry_type || 'NA'},
        ${dbResult[i].buyerName || "NA"},
        ${dbResult[i].buyerDUNSNo || "NA"},
        ${dbResult[i].buyerCountry || "NA"},
        ${dbResult[i].country_name || "NA"},
        ${(buyerCurrency.length > 1 ? buyerCurrency[1] : "NA") || "NA"},
        ${dbResult[i].termsOfPayment || "NA"},
        ${dbResult[i].incoTerms || "NA"},
        ${dbResult[i].productDetails || "NA"},
        ${dbResult[i].previousAnnualSale || "NA"},
        ${dbResult[i].currentAnnualSale || "NA"},
        ${formatDate_Application(dbResult[i].created_at)},
        ${lenderCreditData ? lenderCreditData["assignValue"] : "NA"},
        ${lenderCreditData ? lenderCreditData["assignRemark"] : "NA"}\r\n`

        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `User_Report.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((e) => {
      // console.log('error in getBuyerDetailsForFinancier', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'reports'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title="Reports"
              total={"Total Buyers - " + (filterCount.total || 0)}
              userTokenDetails={userTokenDetails} />

            <div class="d-md-flex mt-3 mb-3">
              <div class="filter-bg">
                <ul>
                  <li className={"pilot-cont  cursor-pointer " + (filterData.status === 0 ? "shadow" : '')} id="progessButton" onClick={() => {
                    setFilterData({ ...filterData, status: 0 })
                  }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Pending (${filterCount.pending || 0})`}
                  </li>
                  <li className={"complete-cont  cursor-pointer " + (filterData.status === 1 ? "shadow" : '')} onClick={() => {
                    setFilterData({ ...filterData, status: 1 })
                  }}>
                    <span class="icon"><img src="assets/images/filter-icon.svg" /></span>{`Remarked (${filterCount.remarked || 0})`}</li>
                </ul>
              </div>
              <div class="ml-md-auto">
                <ul class="range-slider">
                  <li>
                    <label>From</label>
                    <input type="date" id="searchFromdate" name="fromDate" onChange={filterState} />
                  </li>
                  <li>
                    <label>To</label>
                    <input type="date" id="searchTodate" name="toDate" onChange={filterState} />
                  </li>
                  {/* <li>
                    <select className="form-control" name="lenderType" value={filterData.lenderType} onChange={(event) => { setFilterData({ lenderType: event.target.value }) }}>
                      <option value="" selected>--Select Lender--</option>
                      {bankData && bankData.length > 0 && bankData.map((bankList) => {
                        return (<option value={bankList.tbl_user_id + ':' + bankList.company_name}>{bankList.company_name}</option>)
                      })}
                    </select>
                  </li> */}
                  {<li>
                    <select className="form-control" name="supplierType" value={filterData.supplierType} onChange={(event) => { setFilterData({ supplierType: event.target.value }) }}>
                      <option value="" selected>--Select Supplier--</option>
                      {supplierList && supplierList.length > 0 && supplierList.map((supplier) => {
                        return (<option value={supplier.tbl_user_id}>{supplier.company_name}</option>)
                      })}
                    </select>
                  </li>}
                  <li>
                    <select className="form-control" name="buyerType" value={filterData.buyerType} onChange={(event) => { setFilterData({ buyerType: event.target.value }) }}>
                      <option value="" selected>--Select Buyer--</option>
                      {buyerList && buyerList.length > 0 && buyerList.map((buyer) => {
                        return (<option value={buyer.id}>{buyer.buyerName}</option>)
                      })}
                    </select>
                  </li>
                  <li>
                    <input type="button" value="Reset" class="btn btn-primary btn-sm" onClick={() => reset()} />
                  </li>
                </ul>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                columns={columns2}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />
            <div className="float-left col-md-6 ">
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                    <i class="fas fa-file-csv"></i> Export CSV
                  </button>
                </div>
              </div>
            </div>

            {lead.modal &&
              <div className={"modal" + (lead.modal ? " show" : "")} id="leadmodal">
                <div className="modal-dialog modal-xs border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Select Lead By</h4>
                      <button type="button" className="close" onClick={() => { setLead({ "modal": false, "id": 0 }); }}>×</button>
                    </div>
                    <div className="modal-body">
                      <div className="col-md-12 m-2 row">

                        <div className="col-md-12">
                          <div className="row text-center form-group">
                            <div className="col-md-12">
                              <select className="form-control" name="leadBy" value={data.leadBy} onChange={handleChange}>
                                <option value="" selected>--Select Lead By--</option>
                                <option value="Arpit">Arpit</option>
                                <option value="Ravi">Ravi</option>
                                <option value="Fiza">Fiza</option>
                                <option value="Ayushi">Ayushi</option>
                                <option value="Garima">Garima</option>
                                <option value="Zarina">Zarina</option>
                                {/* <option value="Simran">Simran</option> */}
                                <option value="Mukta">Mukta</option>
                                <option value="Dhrumi">Dhrumi</option>
                                <option value="Dhruvi">Dhruvi</option>
                              </select>
                              {error.leadBy ? <div class="text-danger error-contract">{error.leadBy}</div> : ''}
                            </div>
                          </div>
                        </div>
                        <hr className="col-md-11" />
                      </div>
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => { setLead({ "modal": false, "id": 0 }); }}>Cancel</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>}

          </main>
        </div>
      </div>

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
      />}

      {/* footer start */}
      <Footer />
      {/* footer end */}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportForFinancier)