import { getUserDataFromCookie } from '../../utils/cookieHelper';

var userDetails = getUserDataFromCookie();
const aclMap = userDetails.aclMap ? JSON.parse(userDetails.aclMap) : {}
let userTypeId = userDetails.type_id ? userDetails.type_id : null


var columns = [
  {
    name: 'Contract Name',
    selector: 'contract_name',
    sortable: false,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Contract ID',
    selector: 'contract_id',
    sortable: true,
    grow: 2,
  },
  null,//column no. two dynamic
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Price',
    selector: 'price',
    sortable: false,
  },
  {
    name: 'Biding Price',
    selector: 'bidPrice',
    sortable: false,
  },
  {
    name: 'Message',
    selector: 'message',
    grow: 2,
    sortable: false,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    grow: 2,
    cell: row => row.status / 1 === 0 ? "Pending" :
      row.status / 1 === 1 ? "Accepted" :
        "Rejected",


    conditionalCellStyles: [

      {
        when: row => (row.status / 1 === 0),
        style: {
          backgroundColor: 'rgba(255, 193, 7, 0.9)',  //yellow
          color: 'white',
        },
      },
      {
        when: row => (row.status / 1 === 1),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      },
      {
        when: row => (row.status / 1 !== 1 && row.status / 1 !== 0),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 0)',  //you tell?
          color: 'white',
        },
      },
    ]
  },
];

var quotationColumnTitle;

if (userTypeId / 1 === 5 || userTypeId / 1 === 10 || userTypeId / 1 === 11) {
  quotationColumnTitle = "Quotation Sent To";
} else {
  quotationColumnTitle = "Quotation Received From"
}

columns[2] = {
  name: quotationColumnTitle,
  grow: 2,
  selector: 'quotation_party',
}


// if ((aclMap.mquotation_access && aclMap.mquotation_access.mquot_edit && aclMap.mquotation_access.mquot_edit.isExist) ||
//   (aclMap.mquotation_access && aclMap.mquotation_access.mquot_view && aclMap.mquotation_access.mquot_view.isExist)) {
columns.push({
  name: "Action",
  grow: 2,
  selector: 'action',
  center: true
})
// }

export default columns;
