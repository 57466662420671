import React from 'react';
import Header from '../homeComponents/header';
import { connect } from 'react-redux';
import { setRegistrationData } from '../../store/actions/action';


const TechTypePicker = ({ userTokenDetails, registrationData, showCreateProfileAdminModal, setRegistrationData, dispatch }) => {

  // console.log("type in picker:", registrationData);
  // console.log("userTokenDetails type in userTokenDetails:", userTokenDetails);
  const aclMap = userTokenDetails && userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails && userTokenDetails.type_id ? userTokenDetails.type_id : null
  const parentId = userTokenDetails ? userTokenDetails.parentId : 0
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //---- profile tiles[start]----
  var profileTiles = function () {
    return (
      <ul className="on-bording-dashboard">

        {(showCreateProfileAdminModal.modal === false ||
          ((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) &&
            (userTypeId === 14 || userTypeId === 1 || userTypeId === 19) &&
            showCreateProfileAdminModal.modal)) &&
          <>
            {/* <li onClick={() => { setRegistrationData(1, 1, {}) }}>
              <div className="img-icon">
                <img src="../assets/images/onbording/usertechtype/tradetype.png" alt="" />
                <h2> Trade-Tech (for Commodity Contract) </h2>
              </div>
            </li> */}
            <li onClick={() => { setRegistrationData(2, 1, {}) }}>
              <div className="img-icon">
                <img src="../assets/images/onbording/usertype/20.png" alt="" />
                <h2> Fin-Tech </h2>
                <h3> <b>Trade Finance</b> </h3>
              </div>
            </li>
            {/* <li onClick={() => { setRegistrationData(3, 1, {}) }}>
              <div className="img-icon">
                <img src="../assets/images/onbording/usertype/13.png" alt="" />
                <h2> Logi-Tech </h2>
              </div>
            </li> */}

          </>}

        {/* {((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) &&
          (userTypeId === 14 || userTypeId === 1 || userTypeId === 19) &&
          showCreateProfileAdminModal.modal) &&
          <li onClick={() => { setRegistrationData(9, 1, {}) }}>
            <div className="img-icon">
              <img src="../assets/images/onbording/usertype/9.png" alt="" />
              <h2> Employee </h2>
            </div>
          </li>
        } */}

        {((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) &&
          ((userTypeId === 8 && parentId === 0)) &&
          showCreateProfileAdminModal.modal) &&
          <li onClick={() => { setRegistrationData(8, 1, {}) }}>
            <div className="img-icon">
              <img src="../assets/images/onbording/usertype/8.png" alt="" />
              <h2> Bank </h2>
            </div>
          </li>
        }

        {/* {((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) &&
          (userTypeId === 5 || userTypeId === 10 || userTypeId === 11 || userTypeId === 1) &&
          (showCreateProfileAdminModal.modal)) &&
          <>
            <li onClick={() => { setRegistrationData(7, 1, {}) }}>
              <div className="img-icon">
                <img src="../assets/images/onbording/usertype/7.png" alt="" />
                <h2> Vessel Master </h2>
              </div>
            </li>
            <li onClick={() => { setRegistrationData(18, 1, {}) }}>
              <div className="img-icon">
                <img src="../assets/images/onbording/usertype/18.png" alt="" />
                <h2> Carrier Agent </h2>
              </div>
            </li>
          </>
        } */}

      </ul >
    )
  }
  //---- profile tiles[end]----
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //--------final return[start]--------
  if ((aclMap.mng_subusers_profile && aclMap.mng_subusers_profile.add_user_profile && aclMap.mng_subusers_profile.add_user_profile.isExist) && showCreateProfileAdminModal.modal) {
    return (
      <div className="modal-body calc-inner-modal">
        <div className="custom-tab-content active-tab">
          {profileTiles()}
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        <div className="container-fluid">
          <div className="row">
            <Header title={'What type would you like to register as ?'} />
            <div className="outer-box mx-auto">
              {profileTiles()}
            </div>
          </div>
        </div>
      </div>
    )
  }
  //--------final return[end]--------
}
//---------------------------------------------------------------------------------------------------------------------


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData,
    showCreateProfileAdminModal: state.showCreateProfileAdminModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TechTypePicker)