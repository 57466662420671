import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import SignPad from '../contractDocument/signPad';

const DocumentView = ({ userTokenDetails, fileData, showPreviewModal, setshowPreviewModal, viewTrail }) => {

  const [data, setData] = useState({});
  const [fileResult, setfileResult] = useState({});
  const [docDetailsDB, setdocDetailsDB] = useState({});
  const [bcInfoForDoc, setbcInfoForDoc] = useState([]);
  const [tab, setTab] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [signPad, setSignPad] = useState(false);
  const [addFileData, setaddFileData] = useState('');
  const [refresh, setrefresh] = useState(0);
  const [userId, setUserID] = useState('');
  const [userName, setUserName] = useState('');
  const [authorityId, setAuthorityId] = useState({});

  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    console.log("fileData in preview file-->", fileData)


    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)

    window.onload = function () {
      try {
        var url_string = (window.location.href);
        var url = new URL(url_string);
        var file_id = url.searchParams.get('id');
        var type = url.searchParams.get('type');

        console.log(file_id + ' ', type);

        call('POST', 'getdocId', { 'signAuthorityId': file_id }).then((res) => {
          console.log('getDocId => ', res);
          setUserID(res.user_type);
          setUserName(res.contact_person);

          call('POST', 'getAuthorityId', { 'tbldocid': res.tbl_doc_id, 'userId': res.user_type }).then((authorityresult) => {
            console.log("getAuthorityId Result-->", authorityresult)
            setAuthorityId(authorityresult)
          }).catch(err => {
            console.log("getAuthorityId error:->", err)
          })

          call('POST', 'getdocdetails', { 'tbldocid': res.tbl_doc_id }).then((result) => {
            console.log("getdocdetails Result-->", result)
            setdocDetailsDB(result)

            call('POST', 'getDoc', { 'fileHash': result.file_hash }).then((result2) => {
              console.log("getDoc fileResult-->", result2)
              if (result2) {
                setfileResult(result2)
                setshowLoader(false)
              }
            }).catch(err => {
              setshowLoader(false)
              console.log("previewFile error:->", err)
              toastDisplay(err, "error");
            })

          }).catch(err => {
            console.log("getdocdetails error:->", err)
            toastDisplay(err, "error");
          })
        }).catch(err => {
          console.log("getdocId error:->", err)
          toastDisplay(err, "error");
        })

        setData({ ...data, reqId: file_id })

      } catch (error) {

      }
    }
    //------------------------------------------------------------------
  }, []);

  //Handlers ------------------------------------------------

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleFile = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (e) => {
      setaddFileData(e.target.result);
    }
  }

  //Functions ------------------------------------------------

  function submitStamp() {
    setshowLoader(true)
    console.log("data in sign submit==>", data)

    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('userId', userId);
    formData.append('signPadBase64', addFileData ? addFileData : null);
    formData.append('userName', userName);
    call('POST', 'signimage', formData).then((result) => {
      console.log("=== signimage === ", result)
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay("Digital Signature Added successfuly", "success");
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
    })
  }

  //---------------------------------------------------------------------------------------------------------------------

  function getSignPad() {
    return (
      <div className="row">

        <ul className="nav nav-tabs-custom btn m-0 p-0" id="myTab" role="tablist">
          <li>
            <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>Type Sign</a>
          </li>
          <li>
            <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>E-Sign</a>
          </li>

          <li>
            <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Upload Sign</a>
          </li>
        </ul>

        {tab === 0 &&
          <div className="col-md-12">
            <br />
            <br />
            <h2>Type Sign</h2>
            <div className="col-md-12 row form-group float-right d-flex">
              <div className="col-md-5">
                <label >Place</label>
                <textarea rows="2" className="form-control" placeholder="Enter Comments" name="comment" onChange={handleChange} required></textarea>
              </div>
              <div className="col-md-5">
                <label >Digital Signature</label>
                <textarea rows="1" className="form-control" style={signStyle} placeholder="Enter Full Name" name="sign" onChange={handleChange} required></textarea>
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-success mt-5 text-center btn-sm" onClick={() => submitStamp()} >Submit</button>
              </div>
            </div>
          </div>
        }
        {tab === 1 &&
          <div className="col-md-10">
            <br />
            <br />
            <h2>E-Sign Pad</h2>
            <SignPad reqId={data.reqId} signType={3} document_name="email" userId={userId} email={userEmail} userName={userName} />
          </div>
        }
        {tab === 2 &&
          <div className="form-group pb-0 mb-0">
            <br />
            <br />
            <h2>Upload E-Sign Image</h2>
            <ul className="other-documents pb-0 mb-0">
              <li>
                <label className="mb-0" >
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                  E-Sign Image Upload
                  <span className="required-field text-danger">*</span>
                </label>
              </li>
              <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                <div className="file-browse">
                  <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                  <input type="file" accept=".png" id="file_1" name={"e_sign"} onChange={handleFile} />
                </div>
              </li>
              <li>
                {
                  addFileData &&
                  <iframe title="Document Preview" frameborder="0" src={addFileData} >
                  </iframe>
                }
              </li>
            </ul>
            <button type="button" className="btn btn-success mt-5 text-center btn-sm" onClick={() => submitStamp()} >Submit</button>
          </div>
        }
      </div>)
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  function viewTheFile(result) {
    // console.log("result==>", result)
    let mime = docDetailsDB.file_name.split(".").pop()
    let sourceType = getSourceType(mime)
    return (
      <>
        {
          (mime === "pdf" || mime === "PDF") ?
            <>
              {authorityId && authorityId.doc_signed == "1" ? <h1 className="text-success text-center">You have already signed the document.</h1> :
                <div style={{ "float": "right" }}>
                  <button className="btn btn-primary btn-sm" onClick={() => setSignPad(true)}>
                    Add E-Sign
                  </button>
                  <br />
                  <br />
                  {signPad &&
                    getSignPad()
                  }
                </div>}
              <iframe title="Document Preview" frameborder="0" height="100%" className="col-md-10 m-0 p-0"
                src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")}></iframe>
            </>
            :
            <img src={sourceType + encodeURI(result.filebase64 ? result.filebase64 : "")} alt={fileData.file_name} />
        }
      </>

    )
  }
  // style = {{ "position": "relative" }, { "height": "100%" }, { "width": "100%" }}
  function getSourceType(mime) {
    return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
      (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," :
        (mime === "pdf" || mime === "PDF") ? "data:application/pdf;base64," : "")
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <div className="calc-inner-modal col-md-12 m-0 p-0">
        <div style={{ "float": "right" }}>

        </div>
        {fileResult.filebase64 && viewTheFile(fileResult)}
      </div>
    </>)
}



export default DocumentView