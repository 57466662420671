import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TabComponent } from '../../CommonComponent/TabComponent';
import Header from '../../partial/header';
import Sidebar from '../../partial/sidebar';
import * as CSV from 'csv-string';
import toastDisplay from '../../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import call from '../../../service';
import columnNames from './rateListColumns';
import { cargoTypeDD, containerTypeDD, vesselTypeDD } from '../../../utils/myFunctions';

const RatePopup = ({ userTokenDetails, togglePopup }) => {
  // States and variables
  const [data, setData] = useState({
    "modeTab": 0,
    "typeTab": 0
  })
  const [rateFile, setRateFile] = useState({})
  const [error, setError] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [portData, setportData] = useState([])
  const [commCategorys, setcommCategorys] = useState([])
  const [originCountries, setOriginCountries] = useState({})
  const [destinationCountries, setDestinationCountries] = useState({})


  let isFileDataValid = true
  const astrix = <span className="required-field text-danger">*</span>
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const containerType = ["FCL", "LCL"]
  const vesselType = ['Voyage Charter', 'Time Charter', 'Bareboat Charter']


  useEffect(() => {
    call('POST', 'getPortMaster').then((result) => {
      // console.log('inside getPortMaster api-->', result);
      setportData(result)
    }).catch((e) => {
    })
    call('POST', 'getCommodityCategoryMaster').then((result) => {
      // console.log('inside getCommodityCategoryMaster api-->', result);
      setcommCategorys(result)
    }).catch((e) => {
    })
  }, [])


  const handleCSV = (event) => {
    event.persist();
    if (!event.target.files.length) {
      return null
    }
    setError({ ...error, "bulk_upld_error": "" });
    if (!event.target.files[0]['name'].includes("csv")) {
      setError({ ...error, "bulk_upld_error": "Please select csv file" });
      setRateFile({ ...rateFile, name: event.target.files[0]['name'], rows: [] })
      return
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      let rows = CSV.parse(e.target.result)
      // console.log("processed csv rows", rows);
      if (rows && rows.length > 1) {
        let isColsModified = false
        // To check if file template is valid or not
        rows[0].map((item, index) => { if (item != rateFile.columns[index]["name"]) { isColsModified = true } })
        if (isColsModified) {
          setError({ ...error, "bulk_upld_error": "File template does not match as per shipping mode & type selection" });
          setRateFile({ ...rateFile, name: event.target.files[0]['name'], rows: [] })
          return null
        }
        rows.shift()
        setRateFile({ ...rateFile, rows, name: event.target.files[0]['name'] })
      }
      else {
        setError({ ...error, "bulk_upld_error": "Selected file is empty" });
        setRateFile({ ...rateFile, name: event.target.files[0]['name'], rows: [] })
      }
    }
    reader.readAsText(event.target.files[0]);
  }

  useEffect(() => {
    handleFileColumns(data.modeTab, data.typeTab)
  }, [data.modeTab, data.typeTab])


  const handleFileColumns = (modeIndex, typeIndex) => {
    let columns = []
    let templateFileName = ""
    if (modeIndex === 0 && typeIndex === 0) {
      columns = [columnNames[1], columnNames[3], columnNames[4], columnNames[5], columnNames[7], columnNames[8]]
      templateFileName = "FCLContainer.csv"
    }
    if (modeIndex === 0 && typeIndex === 1) {
      columns = [columnNames[1], columnNames[3], columnNames[9], columnNames[10], columnNames[7], columnNames[8]]
      templateFileName = "LCLContainer.csv"
    }
    if (modeIndex === 1 && typeIndex === 0) {
      columns = [columnNames[1], columnNames[3], columnNames[11], columnNames[12], columnNames[7], columnNames[8]]
      templateFileName = "VoyageCharterVessel.csv"
    }
    if (modeIndex === 1 && typeIndex === 1) {
      columns = [columnNames[1], columnNames[3], columnNames[11], columnNames[6], columnNames[13], columnNames[7], columnNames[8]]
      templateFileName = "TimeCharterVessel.csv"
    }
    if (modeIndex === 1 && typeIndex === 2) {
      columns = [columnNames[1], columnNames[3], columnNames[11], columnNames[6], columnNames[7], columnNames[8]]
      templateFileName = "BareboatCharterVessel.csv"
    }
    columns.push(columnNames[14])
    // console.log("setted columns", columns);
    setRateFile({ ...rateFile, columns, templateFileName })
  }

  const validateField = (col_name, value, index) => {
    let validateAsNo = [columnNames[5], columnNames[9], columnNames[10], columnNames[13], columnNames[14]]
    let validateAsDate = [columnNames[7], columnNames[8]]
    if (!value) {
      return { "msg": "Required", "success": false }
    }
    // value = value.replace(/\s/g, "");
    value = value.trim();
    if (validateAsNo.filter(item => { if (item.name === col_name) { return item } }).length) {
      if (isNaN(value / 1)) {
        return { "msg": "Invalid No", "success": false }
      }
      if (value <= 0) {
        return { "msg": "Invalid No", "success": false }
      }
    }
    if (validateAsDate.filter(item => { if (item.name === col_name) { return item } }).length) {
      // console.log("is date valid", value, moment(value).format("YYYY-MM-DD") != value);
      if (moment(value).format("YYYY-MM-DD") != value) {
        return { "msg": "Invalid Date", "success": false }
      }
    }
    let validateStrictly = {
      [columnNames[1]['name']]: portData, [columnNames[3]['name']]: portData, [columnNames[4]['name']]: containerTypeDD,
      [columnNames[6]['name']]: cargoTypeDD,
      [columnNames[11]['name']]: vesselTypeDD, [columnNames[12]['name']]: commCategorys
    }
    // console.log("col_name", col_name);
    if (validateStrictly[col_name]) {
      let temp = validateStrictly[col_name]
      let isValid = false
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]["name"] === value) {
          isValid = true
          if (col_name === "Origin Port") {
            console.log("temp[i", temp[i]);
            originCountries[index] = temp[i]["country_name"]
          }
          if (col_name === "Destination Port") {
            destinationCountries[index] = temp[i]["country_name"]
          }
        }
      }
      if (!isValid) {
        return { "msg": "Invalid Input", "success": false }
      }
    }
    return { "msg": "", "success": true }
  }

  const submitRateList = () => {
    setshowLoader(true);
    let req = {
      mode: data.modeTab === 0 ? "Container" : "Vessel",
      mode_type: data.modeTab === 0 ? containerType[data.typeTab] : vesselType[data.typeTab],
      data: [],
      created_by: userId
    }
    for (let i = 0; i < rateFile.rows.length; i++) {
      let singleRow = {}
      singleRow["origin_country"] = originCountries["" + i + 0 + ""]
      singleRow["destination_country"] = destinationCountries["" + i + 1 + ""]
      for (let j = 0; j < rateFile.columns.length; j++) {
        singleRow[rateFile.columns[j]["selector"]] = rateFile.rows[i][j].trim()
      }
      req['data'].push(singleRow)
    }
    // console.log('req body', req);
    call('POST', 'insertShippingRates', req).then((result) => {
      // console.log('inside insertShippingRates api-->', result);
      toastDisplay(result, "success")
      setshowLoader(false);
      togglePopup(false)
      setTimeout(() => {
        window.location.reload()
      }, 1500);
    }).catch((e) => {
      toastDisplay("Something went wrong", "error")
      setshowLoader(false);
    })
  }

  const csvField = (key, val) => {
    if (key && key[val]) {
      return `"${key[val]}",`
    }
    else {
      return ","
    }
  }

  const downlodReferenceTemplate = () => {
    let csvString = "Country Name,Port Name,Container Type,Cargo Type,Vessel Type,Commodity Type\r\n"
    let rowString = ""
    for (let i = 0; i < portData.length; i++) {
      rowString = csvField(portData[i], "country_name") + csvField(portData[i], "name") + csvField(containerTypeDD[i], "name") +
        csvField(cargoTypeDD[i], "name") + csvField(vesselTypeDD[i], "name") + csvField(commCategorys[i], "name") + "\r\n"
      csvString += rowString
    }
    let link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
    link.setAttribute('download', "master_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toastDisplay("Kindly use master data as reference to fill template", "info")
  }

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className={"modal show"} id="Confirmation">
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Upload Rate List</h4>
                <button type="button" className="close" onClick={() => togglePopup(false)}>×</button>
              </div>
              <div className="modal-body calc-modal mx-4" style={{ "overflow-x": "hidden" }}>
                <div className='row my-2'>
                  <div className='col-md-6 '>
                    <h6 className=''>
                      <a className="text-dark cursor-pointer" href={"./assets/docs/" + rateFile.templateFileName}
                        target="_blank" download>
                        <img className='mr-2' src="./assets/images/logos/csv.jpg" alt="" width="45" height="45"></img>
                        {"Download " + (rateFile.templateFileName ? rateFile.templateFileName.split(".")[0] : "") + " Template"}</a>
                    </h6>
                  </div>
                  <div className='col-md-6'>
                    <h6 className=''>
                      <a className="text-dark cursor-pointer"
                        onClick={downlodReferenceTemplate}>
                        <img className='mr-2' src="./assets/images/logos/csv.jpg" alt="" width="45" height="45"></img>
                        {"Download Master Data"}</a>
                    </h6>
                  </div>
                </div>
                <div className="row mb-4 mt-2">
                  <div className="col-md-6">
                    <TabComponent title={"Select Mode"} tabs={['Container', 'Vessel']}
                      showAstrix={true}
                      selectedTab={data.modeTab} onTabChange={(index) => {
                        setData({ ...data, modeTab: index, typeTab: 0 }); setRateFile({}); setError({});
                        toastDisplay("Kinldy download updated CSV file template", "info")
                      }} />
                  </div>
                  <div className="col-md-6">
                    <TabComponent title={"Select Type "} showAstrix={true}
                      tabs={data.modeTab === 1 ? vesselType : containerType}
                      selectedTab={data.typeTab} onTabChange={(index) => {
                        setData({ ...data, typeTab: index }); setRateFile({}); setError({});
                        toastDisplay("Kinldy download updated CSV file template", "info")
                      }} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto'>
                    <div>
                      <label className="">Select CSV file for bulk upload</label>
                      <div className="file-browse">
                        <button className="btn btn-primary btn-sm">Upload  File</button>
                        <input type="file" onClick={(event) => {
                          event.target.value = null
                        }} accept=".csv" name="ratelist" onChange={handleCSV} />
                      </div>
                      {(rateFile.rows || error.bulk_upld_error) ?
                        <label className={"error-contract " + (!error.bulk_upld_error ? "text-success" : "text-danger")}>{error.bulk_upld_error ? error.bulk_upld_error : (rateFile.rows.length + " Entries Found")}</label> : null}
                    </div>
                  </div>
                </div>
                {rateFile.rows && rateFile.rows.length ? (
                  <div>
                    <h6>{rateFile.name}</h6>
                    <h6 className='text-danger'>Note* - Before submitting file kindly review below entries</h6>
                    <table className="table">
                      <thead>
                        <tr>
                          {rateFile.columns.map(item => (
                            <th scope="col">{item.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rateFile.rows.map((row, rIndex) =>
                          <tr>
                            {rateFile.columns.map((col, index) => {
                              let res = validateField(col["name"], row[index], "" + rIndex + index + "");
                              if (!res.success) {
                                isFileDataValid = res.success
                                return (
                                  <td className=''>
                                    <span class="border border-danger p-2">
                                      {res.msg}
                                    </span>
                                  </td>
                                )
                              }
                              return (
                                <td>{row[index]}</td>
                              )
                            }
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => togglePopup(false)}>Cancel</button>
                <button type="button"
                  disabled={(rateFile.rows && rateFile.rows.length && isFileDataValid) ? false : true}
                  className="btn btn-info btn-sm" onClick={() => submitRateList()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    disbursed: state.disbursed,
    financeDetails: state.financeDetails,
    navToggleState: state.navToggleState
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//     setDisbursed: (flag, data) => { dispatch(setDisbursed({ modal: flag, info: data })) },
//     setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
//   }
// }
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  null
)(RatePopup)