function setCookieInAPP(tokenObject) {

    // Setting the avatar in local storage
    localStorage.setItem('user_avatar', tokenObject.userAvatar ? tokenObject.userAvatar : "")
    // Setting the aclTree in local storage
    localStorage.setItem('user_acl_tree', tokenObject.aclMap ? tokenObject.aclMap : "{}")

    //Setting all other info in cookie
    delete tokenObject.userAvatar
    delete tokenObject.aclMap
    let tokenString = JSON.stringify(tokenObject)
    // Encode the String
    let encodedTokenString = btoa(tokenString);
    setCookie('TradeReboot_cookie', encodedTokenString, 1)
}

function getUserDataFromCookie() {

    let encodedTokenString = getCookie('TradeReboot_cookie')
    // Decode the String
    var decodedTokenString = atob(encodedTokenString);
    let tokenObject = JSON.parse(decodedTokenString ? decodedTokenString : '{}')
    tokenObject.aclMap = localStorage.getItem('user_acl_tree')
    tokenObject.userAvatar = localStorage.getItem('user_avatar')
    // console.log("tokenObject in util-->", tokenObject)
    return tokenObject
}


function getTokenOnly() {
    let encodedTokenString = getCookie('TradeReboot_cookie')
    // Decode the String
    var decodedTokenString = atob(encodedTokenString);
    let tokenObject = JSON.parse(decodedTokenString ? decodedTokenString : '{}')
    return tokenObject.token ? tokenObject.token : null
}

function getACLTreeOnly() {
    let encodedTokenString = getCookie('TradeReboot_cookie')
    // Decode the String
    var decodedTokenString = atob(encodedTokenString);
    let tokenObject = JSON.parse(decodedTokenString ? decodedTokenString : '{}')
    return tokenObject.aclTree ? tokenObject.aclTree : null
}

function setNewAvatar(newAvatar) {
    localStorage.setItem('user_avatar', newAvatar)
}

function removeCookieandAvatar() {
    let domainUrl = window.location.hostname
    document.cookie = "TradeReboot_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=" + domainUrl + ";";
    localStorage.removeItem('user_acl_tree')
    localStorage.removeItem('user_avatar')
}

function setCookie(cname, cvalue, exdays) {
    let domainUrl = window.location.hostname
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Domain=" + domainUrl + ";";
    //console.log("cookie string ---->", cname + "=" + cvalue + ";" + expires + ";path=/;Domain=" + domainUrl + ";")
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) / 1 === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setTraderType(cvalue) {
    localStorage.setItem('app_trader_activetype', cvalue ? cvalue : "buyer")
}

function getTraderType() {
    let current_trader_activetype = localStorage.getItem('app_trader_activetype')
    return current_trader_activetype
}

function setNavToggleStateLS(value) {
    localStorage.setItem('nav_toggle_state', value)
}

function getNavToggleState() {
    let navState = localStorage.getItem('nav_toggle_state')
    return navState / 1 ? navState / 1 : 0
}

module.exports = {
    setCookieInAPP,
    getUserDataFromCookie,
    removeCookieandAvatar,
    getTokenOnly,
    setNewAvatar,
    setTraderType,
    getTraderType,
    getACLTreeOnly,
    setNavToggleStateLS,
    getNavToggleState
}
