export default function validate(values) {

  let errors = {};

  if (!values.approvedFinanceAmount) {
    errors.approvedFinanceAmount = "Field Missing";
  }
  if (!values.fundingPercentage) {
    errors.fundingPercentage = "Field Missing";
  }
  if (!values.fundingTenor) {
    errors.fundingTenor = "Field Missing";
  }
  if (!values.duedateCalcFrom) {
    errors.duedateCalcFrom = "Field Missing";
  }
  if (!values.offerValidity) {
    errors.offerValidity = "Field Missing"
  }
  if (!values.interestRate) {
    errors.interestRate = "Field Missing";
  }
  if (!values.interestCollection) {
    errors.interestCollection = "Field Missing";
  }
  if (!values.finRequestPercentage) {
    errors.finRequestPercentage = "Field Missing";
  }
  if (!values.finRequestCollection) {
    errors.finRequestCollection = "Field Missing";
  }
  if (!values.additionalFee) {
    errors.additionalFee = "Field Missing";
  }
  if (!values.additionalDescription) {
    errors.additionalDescription = "Field Missing";
  }
  if (!values.additionalCollection) {
    errors.additionalCollection = "Field Missing";
  }
  if (!values.lastfeeCharges) {
    errors.lastfeeCharges = "Field Missing";
  }
  // if (!values.platformFee) {
  //   errors.platformFee = "Field Missing";
  // }

  if (!values.sanctionCountry) {
    errors.sanctionCountry = "Field Missing";
  }
  if (!values.sanctionPort) {
    errors.sanctionPort = "Field Missing";
  }
  if (!values.currency) {
    errors.currency = "Field Missing";
  }
  if (!values.commodities) {
    errors.commodities = "Field Missing";
  }
  console.log(errors)
  return errors;
}