
import { removeCookieandAvatar } from './cookieHelper';
import call from '../service';

export default function logoutSession() {

  //------------------------------------------------------------------
  //API Calls
  call('get', `v1/user/logout?attr1=${localStorage.getItem("attr1")}&attr2=${localStorage.getItem("attr2")}`).then((result) => {
    console.log("API result logout:-->", result);
    removeCookieandAvatar()
    localStorage.clear()
    window.location = '/login'
  }).catch((e) => {
    console.log("Error while  logout:-->", e);
  })

  //------------------------------------------------------------------

}
