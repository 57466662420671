import React from 'react';
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Plans from './plans.js';
import Footer from '../partial/footer';
import { connect } from 'react-redux';

const ShowPlans = ({ userTokenDetails, navToggleState, dispatch }) => {


  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <div className="col-md-12 bording-h2">
            <ul className="header-onboarding">
              <li><Link to="/"><img height="50" src="../assets/images/logos/logo_1.png" alt="Logo" /></Link></li>
              <li></li>
              <li><div className="row" style={{ "min-width": "120px" }}><Link to="/login">Login</Link><span className="text-primary"> / </span><Link to="/registration">Sign-Up</Link></div></li>
            </ul>
          </div>
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Plans userTokenDetails={userTokenDetails} />

          </main>
        </div>
      </div >
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
};



const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowPlans)
