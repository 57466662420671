import React, { useEffect, useState } from 'react';

import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

// redux imports:
// import { setaddBankObj } from '../../store/actions/action';
// import { connect } from 'react-redux';

const AddPortComponent = ({ countryList }) => {


  const [view, setView] = useState(1);//1->show button, 2-> show form;
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [countryData, setCountryData] = useState([])
  const [cityData, setCityData] = useState([]);

  function handleChange(event) {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError(error => ({ ...error, [event.target.name]: "" }))
  }

  const loadCity = (event) => {
    event.persist();
    call('POST', 'getCountryCity', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      console.log("cityData: =>", result)
      setCityData(result)
    }).catch((e) => {
      toastDisplay(e, "error");
    })
  }
  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    setCountryData(countryList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleSave = () => {
    let error = {};
    if (!data.country) {
      error.country = "Please select country."
    }
    if (!data.city) {
      error.city = "Please select city."
    }
    if (!data.port) {
      error.port = "Please enter port name."
    }
    if (!data.portTax) {
      error.portTax = "Please enter port tax value."
    }
    if (error.country || error.city || error.port || error.portTax) {
      setError(error);
      return;
    }


    var formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append("loadingRate", 0);


    call('POST', 'addPort', formData).then((result) => {
      if (result) {
        toastDisplay("Port details saved successfuly!", "success");
        setView(0);
      }
    }).catch(err => {
      toastDisplay(err, "error");
    })
  }


  function formView() {

    return (
      <div className="accordionWrapper row pr-0 pt-0">
        <div className="container-fluid accordionItem open">
          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-12">
                  <h3 className="green mt-3">Enter Port Details<button type="button" onClick={() => { setView(1) }} className="close pt-0 mb-4" data-dismiss="modal" >×</button>
                  </h3>
                  <hr />
                </div>
                <div className="col-md-12">
                  <form className="pr-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">Country<span className="required-field text-danger">*</span></label>
                          <div className="col-md-12">
                            <select className={" form-control" + (!error.country ? '' : ' border-danger')} name="country" value={data.country} onChange={(event) => { handleChange(event); loadCity(event) }}>
                              <option value="" selected>--Select country--</option>
                              {/* <CountryList /> */}
                              {
                                countryData.length ? countryData.map((item, index) => {
                                  return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                }) : ''
                              }
                            </select>
                            {error.country && <p className="text-danger error-contract">{error.country}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row form-group">
                          <label className="col-md-12">City<span className="required-field text-danger">*</span></label>
                          <div className="col-md-12">
                            <select className={" form-control" + (!error.city ? '' : ' border-danger')} name="city" value={data.city} onChange={(event) => { handleChange(event) }}>
                              <option value="" selected>--Select City--</option>
                              {/* <cityList /> */}
                              {
                                cityData.length ? cityData.map((item, index) => {
                                  return (<option value={item.id}>{item.city}</option>)
                                }) : ''
                              }
                            </select>
                            {error.city && <p className="text-danger error-contract">{error.city}</p>}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Port name</label><span className="required-field text-danger">*</span>
                          <input className={"form-control" + (!error.port ? '' : ' border-danger')} name="port" placeholder="Enter Port name" onChange={handleChange} />
                          {error.port && <p className="text-danger error-contract">{error.port}</p>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Port Tax</label><span className="required-field text-danger">*</span>
                          <input type="number" className={"form-control" + (!error.portTax ? '' : ' border-danger')} name="portTax" placeholder="Enter Port name" onChange={handleChange} />
                          {error.portTax && <p className="text-danger error-contract">{error.portTax}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="float-right">
                      <input type="button" className="btn btn-primary m-2" value="Save" onClick={handleSave} />
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >)
  }


  function buttonView() {
    return (
      <button type="button" className="btn btn-info btn-sm" onClick={() => { setView(2) }} id="nextid">Add Port</button>
    )
  }

  if (view === 2) {
    return formView()
  } else {
    return buttonView()
  }
}


export default AddPortComponent;
