import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import { ToastContainer } from 'react-toastify';
import toastDisplay from '../../utils/toastNotification';
import validate from '../../utils/validation_files/changePassword';
import Footer from '../partial/footer';

const ChangePassword = ({ userTokenDetails, compCall, navToggleState, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [showLoader, setshowLoader] = useState(false);
  // const [kycVerified, setkycVerified] = useState(false);
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false);


  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails && userTokenDetails.email ? userTokenDetails.email : null

  const astrix = <span className="required-field text-danger">*</span>
  //------------------------------------------------------------------
  //use Form func/comp imports

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };


  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = userId
    data.userEmail = userEmail
    data.attr1 = localStorage.getItem('attr1')
    data.attr2 = localStorage.getItem('attr2')

    call('POST', 'changepassword', data).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)

    }).catch(err => {
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }







  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {(compCall) ?
        <div className="col-md-12 shadow bg-light pb-5">


          <div className="form-group" id="">
            <label>Email ID </label>
            <input type="text" className={" form-control" + (!error.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={userEmail} onChange={handleChange} required disabled />
            {error.email && <p className="text-danger error-contract">{error.email}</p>}
          </div>

          <div className="form-group" id="">
            <label>Current Password {astrix} </label>
            <input type="text" className={" form-control" + (!error.currentPassword ? '' : ' border-danger')} placeholder="Enter Current Password" name="currentPassword" value={data.currentPassword} onChange={handleChange} required />
            {error.currentPassword && <p className="text-danger error-contract">{error.currentPassword}</p>}
          </div>

          <div className="form-group" id="">
            <label>New Password </label>
            <span className="required-field text-danger">*</span><span className="help-icon" flow="right" tooltip="-The Password must contain at least 1 uppercase alphabetical character , -The Password must contain at least 1 numeric character , -The Password must contain at least one special character , -The Password must be eight characters or longer">i </span>
            <input type="text" className={" form-control" + (!error.newPassword ? '' : ' border-danger')} placeholder="Enter New Password" name="newPassword" value={data.newPassword} onChange={handleChange} required />
            {error.newPassword && <p className="text-danger error-contract">{error.newPassword}</p>}
          </div>

          <div className="form-group" id="">
            <label>Confirm Password {astrix} </label>
            <input type="text" className={" form-control" + (!error.confirmPassword ? '' : ' border-danger')} placeholder="Confirm Password" name="confirmPassword" value={data.confirmPassword} onChange={handleChange} required />
            {error.confirmPassword && <p className="text-danger error-contract">{error.confirmPassword}</p>}
          </div>


          <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => preHandleSubmit()}>Update Password</button>


        </div> :
        <>
          <div className="container-fluid">
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
            <div className="row">
              <Sidebar state={'editProfile'} userTokenDetails={userTokenDetails} />
              <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

                <Header
                  title={"Change Password"}
                  total={userEmail}
                  userTokenDetails={userTokenDetails} />

                <div className="col-md-10 offset-md-1">
                  <div className="col-md-12 shadow bg-light mt-5 pt-5 pb-5">


                    <div className="form-group" id="">
                      <label>Email ID </label>
                      <input type="text" className={" form-control" + (!error.email ? '' : ' border-danger')} placeholder="Enter Email ID" name="email" value={userEmail} onChange={handleChange} required disabled />
                      {error.email && <p className="text-danger error-contract">{error.email}</p>}
                    </div>

                    <div className="form-group" id="">
                      <label>Current Password {astrix} </label>
                      <input type="text" className={" form-control" + (!error.currentPassword ? '' : ' border-danger')} placeholder="Enter Current Password" name="currentPassword" value={data.currentPassword} onChange={handleChange} required />
                      {error.currentPassword && <p className="text-danger error-contract">{error.currentPassword}</p>}
                    </div>

                    <div className="form-group" id="">
                      <label>New Password </label>
                      <span className="required-field text-danger">*</span><span className="help-icon" flow="right" tooltip="-The Password must contain at least 1 uppercase alphabetical character , -The Password must contain at least 1 numeric character , -The Password must contain at least one special character , -The Password must be eight characters or longer">i </span>
                      <input type="text" className={" form-control" + (!error.newPassword ? '' : ' border-danger')} placeholder="Enter New Password" name="newPassword" value={data.newPassword} onChange={handleChange} required />
                      {error.newPassword && <p className="text-danger error-contract">{error.newPassword}</p>}
                    </div>

                    <div className="form-group" id="">
                      <label>Confirm Password {astrix} </label>
                      <input type="text" className={" form-control" + (!error.confirmPassword ? '' : ' border-danger')} placeholder="Confirm Password" name="confirmPassword" value={data.confirmPassword} onChange={handleChange} required />
                      {error.confirmPassword && <p className="text-danger error-contract">{error.confirmPassword}</p>}
                    </div>

                    <button type="button" className="btn-primary btn mt-3 btn-sm" onClick={() => preHandleSubmit()}>Update Password</button>

                  </div>
                </div>

              </main>
            </div >
          </div >

          {/* footer start */}
          <Footer />
          {/* footer end */}
        </>
      }

    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword)
