import React, { useEffect, useState } from 'react';
import call from '../../service';
// import { getUserDataFromCookie } from '../../utils/cookieHelper';

const DocumentACL = ({ userArray, docCategoryACL, setdocCategoryACL, contractId, contractNo, dataObj, modalType }) => {

  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryRow, setCategoryRow] = useState([]);
  const [aclData, setAclData] = useState({});

  const deepClone = obj => JSON.parse(JSON.stringify(obj));
  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  console.log("docCategoryACL:-->", docCategoryACL);
  console.log("modalType:-->", modalType);
  console.log("Object.keys(docCategoryACL):-->", Object.keys(docCategoryACL));
  useEffect(() => {
    if ((modalType === "create" && Object.keys(docCategoryACL).length === 0) || Object.keys(docCategoryACL).length === 0) {
      fetchDataFromDB();
    } else {
      call('GET', 'getdoccategory').then((result) => {
        setCategoryRow(deepClone(docCategoryACL));
        setAclData(deepClone(docCategoryACL));
        setCategoryArray(result);
      }).catch((e) => {
        console.log("Error while querying getdoccategory:", e);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchDataFromDB() {
    if (modalType === "create") {
      call('GET', 'getdoccategory').then((result) => {
        const tempObj = {}, tempObj2 = {}, tempArray1 = [], tempArray2 = [];
        const keyArray = Object.keys(dataObj);
        for (let i = 0; i < keyArray.length; i++) {
          console.log("in loop 1-->")
          if (dataObj[keyArray[i]]) {
            tempArray1.push(":)")
            tempArray2.push(dataObj[keyArray[i]])
          }
        }

        for (let i = 0; i < result.length; i++) {
          console.log("in loop 2-->")
          tempObj[result[i].category + ':' + result[i].id] = tempArray1
          tempObj2[result[i].category + ':' + result[i].id] = tempArray2
        }
        setCategoryRow(tempObj)
        setAclData(tempObj2)
        setdocCategoryACL(tempObj2)
        setCategoryArray(result);
      }).catch((e) => {
        console.log("Error while querying getdoccategory:", e);
      })
    } else {
      call('POST', 'getdoccategoryacl', { "contractId": contractId, "contractNo": contractNo }).then((result) => {
        let tempObj = {}, tempObj2 = {}
        for (let i = 0; i < result.categoryArray.length; i++) {
          tempObj[result.categoryArray[i].category + ':' + result.categoryArray[i].id] = [":)"]
          tempObj2[result.categoryArray[i].category + ':' + result.categoryArray[i].id] = []
        }

        //let finalRes = tempObj, finalRes = tempObj
        for (let i = 0; i < result.aclData.length; i++) {
          tempObj[result.aclData[i].category + ":" + result.aclData[i].category_id].push(result.aclData[i].user_id)
          tempObj2[result.aclData[i].category + ":" + result.aclData[i].category_id].push(result.aclData[i].user_id)
        }

        setCategoryRow(tempObj)
        setAclData(tempObj2)
        setdocCategoryACL(tempObj2)
        setCategoryArray(result.categoryArray);

      }).catch((e) => {
        console.log("Error while querying getdoccategoryacl:", e);
      })
    }
  }

  // Handlers
  const handleChange = (event, category, ind) => {
    console.log("handleChange event:-->", event.target.name, event.target.value);
    console.log("handleChange category:-->", category);
    console.log("handleChange ind:-->", ind);

    event.persist();
    let tempObj = { ...aclData }
    console.log("handleChange tempObj before:-->", tempObj);
    tempObj[event.target.name].push(event.target.value / 1)
    setAclData(tempObj)
    setdocCategoryACL(tempObj)
    document.getElementById(category + "Select" + ind).disabled = true;
    addCategoryRow(category)
  }


  function addCategoryRow(category) {
    const tempObj = deepClone(categoryRow);
    tempObj[category].push(":)");
    setCategoryRow(tempObj);
  }

  function deleteCategoryRow(category, ind) {
    let tempObj = { ...categoryRow }
    let tempObj2 = { ...aclData }
    let selInput = document.getElementById(category + "Select" + ind)
    if (selInput.value) {
      let valueIndex = tempObj2[category].indexOf(selInput.value / 1)
      tempObj2[category].splice(valueIndex, 1)
      setAclData(tempObj2)
      setdocCategoryACL(tempObj2)
    }
    tempObj[category].splice(ind, 1)
    setCategoryRow(tempObj)
  }

  function getCategoryRow(category) {
    // console.log("category-->", category)
    let rowsArr = [];
    for (let i = 0; i < categoryRow[category].length; i++) {
      rowsArr.push(
        <>
          <div className="row form-group">
            <div className="col-md-1">
              <span className="field">
                <i className="text-danger fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteCategoryRow(category, i)}></i>
              </span>
            </div>
            <div className="col-md-2">
              <span className="field">
                <label >Select Party</label>
              </span>
            </div>
            <div className="col-md-6">
              <span className="field">
                <select className="form-control" name={category} id={category + "Select" + i} value={aclData[category][i] ? aclData[category][i] : ""}
                  onChange={(event) => handleChange(event, category, i)} disabled={aclData[category][i] ? true : false} required>
                  <option value="" selected>--Select Party--</option>
                  {(userArray && userArray.length) ? userArray.map((value) => {
                    return (<option value={value.userId} >{value.userName}</option>)
                  }) : ""}
                </select>
              </span>
            </div>
          </div>
        </>
      )
    }
    return rowsArr;
  }



  // JSX return
  return (
    <div className="col-md-11 ml-4 ">
      {(categoryArray && categoryArray.length) ? categoryArray.map((value) => {
        return (
          <>
            <div className="row form-group">
              <div className="col-md-6">
                <h3>{value.pretty_name}</h3><br />
              </div>
              <div className="ml-auto">
                <input type="button" className="btn btn-primary btn-sm" value="Add +" onClick={() => addCategoryRow(value.category + ':' + value.id)} />
              </div>
            </div>
            {
              getCategoryRow(value.category + ':' + value.id)
            }
            <hr />
          </>
        )
      }) :
        <h3 className="text-center">Fetching data please wait...</h3>}
    </div>

  )
}

export default DocumentACL;
