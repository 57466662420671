

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { formatDateTime_Application } from '../../utils/dateFormaters';



const QmsWindow = ({ userTokenDetails, contractNo, isInvoiceDiscounting, isBuyerView, buyerUserId, sellerData, partyUserId,
    withoutContractChat, partyEmailId, senderUserName }) => {
    //---------------------------------------------------------------------------------------------------------------------
    //States
    const [chatParty, setChatParty] = useState(null);
    const [parties, setParties] = useState([]);
    let [messageData, setmessageData] = useState([]);

    const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
    const userId = isBuyerView ? buyerUserId : (userTokenDetails.user_id ? userTokenDetails.user_id : null)
    //---------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        //------------------------------------------------------------------

        //------------------------------------------------------------------
        //API Calls
        console.log("running get user query");
        let req = { contractNo: contractNo, userId: userId }

        if (isInvoiceDiscounting) {
            req["partyUserId"] = partyUserId
            req["isInvoiceDiscounting"] = true
            req["isBuyerView"] = isBuyerView
        }

        if (withoutContractChat) {
            req["partyUserId"] = partyUserId
            req["withoutContractChat"] = true
        }
        call('POST', 'getContractUsers', req).then((result) => {
            console.log("result get user query", result);
            setParties(result);
        }).catch((e) => {
            console.log("Error while querying getContractUsers:", e);
        })

        //------------------------------------------------------------------

    }, [])
    //---------------------------------------------------------------------------------------------------------------------


    const getChat = (sender, receiver) => {
        call('post', 'getcommunication', {
            contractId: contractNo,
            user_id1: sender,
            user_id2: receiver,
            type: 1,
            userType: userTypeId
        }).then((result) => {
            setmessageData(result)
            document.getElementById("chatBoxArea").scrollIntoView({ behavior: "smooth" })
        }).catch((e) => {
            console.log('error in getcommunication', e);
        })
    }

    const changeChatParty = (event) => {
        if (event.target.value) {
            setChatParty({
                ...chatParty,
                [event.target.name]: event.target.value,
                'sender': userId
            })
            getChat(userId, event.target.value)
        }
        else {
            setChatParty(null)
            getChat(userId, event.target.value)
        }
    }

    const megHandler = (event) => {
        setChatParty({
            ...chatParty,
            [event.target.name]: event.target.value
        })
    }

    const sendMessage = () => {
        if (!chatParty.message) {
            return
        }
        let obj = {
            cNo: contractNo,
            typeId: 1
        }
        obj = Object.assign(obj, chatParty)

        if (isInvoiceDiscounting) {
            obj["isInvoiceDiscounting"] = true
            if (buyerUserId / 1 == obj.receiver / 1) {
                obj["sendMailToBuyer"] = true
                obj["sellerEmailId"] = sellerData.email
                obj["sellerUserId"] = sellerData.user_id
            }
        }

        if (withoutContractChat) {
            obj["withoutContractChat"] = true
            obj['receiverEmailId'] = partyEmailId.filter(i => {
                if (i[obj.receiver]) {
                    return i[obj.receiver]
                }
            })[0][obj.receiver]
            obj["senderUserName"] = senderUserName
        }

        call('POST', 'sendMessage', obj).then((result) => {

            getChat(chatParty.sender, chatParty.receiver);
            document.getElementById('chatBoxInput').value = '';
            setChatParty({
                ...chatParty,
                'message': null
            });

            document.getElementById("chatBoxArea").scrollIntoView({ behavior: "smooth" })
        }).catch((e) => {
            console.log("Error while querying Doc:", e);
        });
    }


    //---------------------------------------------------------------------------------------------------------------------

    //---------------------------------------------------------------------------------------------------------------------
    // Message list Component
    function gettrailList() {
        const trailList = messageData ? messageData.map((messageData, index) => {
            return (
                <>
                    {(messageData.msg_from !== userId) &&
                        <div className="chat-item is-you">
                            {messageData.msg_to / 1 == -1 ? (
                                <time className="chat-label">{messageData.from_company + "  "}</time>
                            ) : null}
                            <time className="chat-label">{formatDateTime_Application(messageData.created_at)}</time>
                            <p className="chat-message">{messageData.message}</p>
                        </div>
                    }
                    {(messageData.msg_from === userId) &&
                        <div className="chat-item is-me">
                            <time className="chat-time">{formatDateTime_Application(messageData.created_at)}</time>
                            <p className="chat-message">{messageData.message}</p>
                        </div>
                    }
                </>
            )
        }) : ''
        return trailList
    }

    function getPartieslist() {
        let result = parties.map((item) => {
            return (
                <option value={item.tbl_user_id}>{item.company_name}</option>
            )
        })
        return result
    }

    //---------------------------------------------------------------------------------------------------------------------


    return (<>
        <div className="chat-body">
            <div className="chat-content">
                {!chatParty &&
                    <h3 className="m-2">Please Select A Party to start communication</h3>
                }

                {chatParty && !messageData.length &&
                    <h3 className="m-2">No records Found</h3>
                }
                <select className="form-control mb-3" name="receiver" onChange={(event) => changeChatParty(event)}>
                    <option value="" selected > Select Party </option>
                    {getPartieslist()}
                </select>
                {gettrailList()}
            </div>
        </div>
        <form className="chat-form">
            <input className="chat-form-input" type="text" placeholder="Enter your message" name="message" id="chatBoxInput" required onBlur={(event) => megHandler(event)} />
            <button className="button" type="button" disabled={!chatParty} onClick={sendMessage}>Send</button>
        </form>

    </>)
}


//---------------------------------------------------------------------------------------------------------------------
// Redux

//---------------------------------------------------------------------------------------------------------------------

export default QmsWindow
