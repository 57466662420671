import React, { useState, useEffect } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from '../homeComponents/header';
import { setRegistrationData } from '../../store/actions/action';
import commImageUrl from '../../utils/makeCommImageUrl'
import toastDisplay from '../../utils/toastNotification';
import { is_valid } from '../../utils/myFunctions';


const CommoditiesPicker = ({ registrationData, setRegistrationData, dispatch }) => {

  const [dbData, setdbData] = useState([]);
  const [commodityCategories, setcommodityCategories] = useState([])
  const [data, setdata] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  let [error, seterror] = useState(false);
  let [formError, setformError] = useState({});
  var isAdminModal = !(window.location.href.includes('registration'));
  const [searchKey, setsearchKey] = useState("")
  const [selectedCommodity, setselectedCommodity] = useState([]);
  const [addMoreArr, setaddMoreArr] = useState([1])

  const [selectedCommArray, setselectedCommArray] = useState([]);


  useEffect(() => {

    if (registrationData.payload && is_valid(registrationData.payload.step2)) {
      setselectedCommodity(registrationData.payload.step2)
      setaddMoreArr(registrationData.payload.step2_other_data.addMoreArr)
      setdata(registrationData.payload.step2_other_data.data)
      setformError(registrationData.payload.step2_other_data.formError)
    }

    //------------------------------------------------------------------
    //API Calls
    // getCommodityList()
    //------------------------------------------------------------------

    call('GET', 'getcommoditycategory').then((result) => {
      //console.log("API result getcommoditycategory in comm modal:-->", result);
      setcommodityCategories(result);
      setshowLoader(false)
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })

    console.log("commodity:", registrationData.payload.step2);
    registrationData.payload.modalNav = 2;//for navigating next/back in case of modal
    let commodityList = registrationData && registrationData.payload && registrationData.payload.step2 ? registrationData.payload.step2 : [];
    setselectedCommArray(commodityList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCommodityList = () => {
    setshowLoader(true)
    call('POST', 'getCommodityList', { specifications: false, commadd: false, search_text: searchKey }).then((result) => {
      console.log("API result getCommodityList in comm modal:-->", result);
      setdbData(result.message);
      setshowLoader(false)
    }).catch((e) => {
      console.log("Error while querying getCommodityList:", e);
      setshowLoader(false)
    })
  }


  function handleCommoditySelection(id) {
    let tempArray = [...selectedCommArray]
    let indexofvalue = tempArray.indexOf(id)
    if (indexofvalue === -1) {
      tempArray.push(id)
    } else {
      tempArray.splice(indexofvalue, 1);
    }
    setselectedCommArray(tempArray)
  }

  let addNewCommodity = (id) => {
    if (!is_valid(data["commdCategorys" + id]) || !is_valid(data["commName" + id])) {
      setformError({
        ["commdCategory" + id]: !is_valid(data["commdCategorys" + id]) ? "Commodity Category is Mandatory" : '',
        ["commName" + id]: !is_valid(data["commName" + id]) ? "Commodity Name is Mandatory" : ''
      });
      return
    }
    setformError({})
    let commodityDetails = {
      id,
      "category_id": data["commdCategorys" + id],
      "commodity_name": data["commName" + id].toLowerCase().replace(/\s/g, ''),
      "filename": "default.png",
      "commodity_pretty_name": data["commName" + id]
    }
    setselectedCommodity(selectedCommodity.concat(commodityDetails))
    toastDisplay("Commodity added successfully", "success")
    // console.log("selectedCom", selectedCommodity);
  }

  let deleteCommodity = (id) => {
    setselectedCommodity(selectedCommodity.filter(data => { if (data.id != id) { return data } }))
    let temp = addMoreArr
    temp[id - 1] = null
    setaddMoreArr(temp)
    toastDisplay("Commodity removed successfully", "success")
  }


  const postCommodities = () => {

    let selectedComm = selectedCommArray

    //---------refresh step3 data (remove unselected commodity data)[start]-----------
    if (registrationData.payload && registrationData.payload.step3) {
      var allowedValueMustInclude = [...selectedComm, "owner", "company", "other"];
      for (var key in registrationData.payload.step3) {

        var deleteIt = true;

        for (let i = 0; i < allowedValueMustInclude.length; i++) {
          const element = allowedValueMustInclude[i];
          if (key.includes(element)) {
            deleteIt = false;
            break;
          }
        }

        if (deleteIt) {
          delete registrationData.payload.step3[key];
        }
      }
    }
    //---------refresh step3 data (remove unselected commodity data)[end]-----------

    // if (selectedComm.length > 0) {
    //   registrationData.payload.step2 = selectedComm;
    //   setRegistrationData(registrationData.type, 4, registrationData.payload);
    // }
    // else {
    //   seterror(true);
    //   toastDisplay("Form Validation Error", "warn")
    // }

    if (selectedCommodity.length > 0) {
      registrationData.payload.step2 = selectedCommodity;
      registrationData.payload.step2_other_data = {
        formError, data, addMoreArr
      }
      setRegistrationData(registrationData.type, 4, registrationData.payload);
    }
    else {
      seterror(true);
      toastDisplay("Form Validation Error", "warn")
    }
  }

  const handleBack = () => {
    let selectedComm = selectedCommArray
    registrationData.payload.step2 = selectedComm;
    if (registrationData.type === 6) {
      //redirected to service location picker if user type is investigation agency
      setRegistrationData(registrationData.type, 9, registrationData.payload)
    }
    else {
      setRegistrationData(registrationData.type, 7, registrationData.payload)//redirected to profile picker
    }
  }

  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: (event.target.value).replace(/^\s?/, '') }));
  }

  function commodityPickerCommon() {
    const astrix = <span className="required-field text-danger">*</span>
    return (
      <>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <div className="row">
          {/* {selectedCommodity.length ? (
            <button type="button" className="btn btn-primary btn-sm"
              style={{ position: "absolute", right: "1.4rem", zIndex: 1 }}
              onClick={() => setaddMoreArr(addMoreArr.concat(addMoreArr.length + 1))}>ADD MORE</button>
          ) : null} */}
          <div className="col-md-12 mb-2">
            <h3 className="green border-bottom pb-2">Select Commodities to trade in :
            </h3>
          </div>
          {addMoreArr.map((id) => {
            if (id) {
              return (
                <div className="container ml-2">
                  <div className='row'>
                    <div className='col-8'>
                      <label >Commodity Type/Category {astrix} </label>
                      <div >
                        <select disabled={selectedCommodity.filter(data => { if (data.id == id) { return data } }).length}
                          className={" form-control" + (!formError["commdCategory" + id] ? '' : ' border-danger')} name={"commdCategorys" + id}
                          value={data["commdCategorys" + id]} onChange={handleChange}>
                          <option value="" selected>--Select Category--</option>
                          {
                            commodityCategories.length ? commodityCategories.map((item, index) => {
                              return (<option value={item.id}>{item.category}</option>)
                            }) : ''
                          }
                        </select>
                        {formError["commdCategory" + id] ? <div class="text-danger error-contract">{formError["commdCategory" + id]}</div> : ''}
                      </div>
                    </div>

                    <div className='col-4 align-self-end'>
                      {selectedCommodity.filter(data => { if (data.id == id) { return data } }).length ? (
                        <button type="button"
                          disabled={selectedCommodity.length == 1}
                          className="btn btn-primary btn-sm" onClick={() => deleteCommodity(id)}>DELETE</button>
                      ) : (
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => addNewCommodity(id)}>SUBMIT</button>
                      )}
                    </div>

                    <div className='col-8 mb-4 mt-3'>
                      <label >Commodity name {astrix} </label>
                      <div >
                        <input disabled={selectedCommodity.filter(data => { if (data.id == id) { return data } }).length}
                          type="text" name={"commName" + id} value={data["commName" + id]} placeholder="Commodity name"
                          className={" form-control" + (!formError["commName" + id] ? '' : ' border-danger')}
                          onChange={handleChange} />
                        {formError["commName" + id] ? <div class="text-danger error-contract">{formError["commName" + id]}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          }
          )}
          {selectedCommodity.length ? (
            <button type="button" className="btn btn-primary btn-sm mb-4"
              style={{ "margin-left": '45%' }}
              onClick={() => setaddMoreArr(addMoreArr.concat(addMoreArr.length + 1))}>ADD MORE</button>
          ) : null}

          {!selectedCommodity.length ? <p className="text-danger w-100 text-center">{"Please select at least one Commodity"}</p> : ""}

          {/* <div className="col-md-12 our-webcoderskull">
              <div id="SelectName" className="mb-4">
              </div>
              <ul className="row" id="">

                {(dbData && dbData.length > 0) && dbData.map((commData) => {
                  return (
                    <li className={`col-md-3 ${selectedCommArray.indexOf(commData.id) !== -1 ? 'active' : ''} `} true >
                      <div className="cnt-block equal-hight">
                        <figure onClick={() => { handleCommoditySelection(commData.id) }}>
                          <img width="92" src={commImageUrl(commData.filename)} className="img-responsive" alt="" />
                        </figure>
                        <h3 className={selectedCommArray.indexOf(commData.id) !== -1 ? 'text-success' : 'text-dark'}>
                          {commData.commodity_pretty_name ? commData.commodity_pretty_name : '--'}
                        </h3>
                        <hr />
                        <h3 className={selectedCommArray.indexOf(commData.id) !== -1 ? 'text-success' : 'text-dark'}>
                          {commData.category ? commData.category : '--'}
                        </h3>
                      </div>
                    </li>
                  )
                })}
              </ul>
              {(selectedCommArray.length === 0 || error) ? <p className="text-danger text-center">{"Please select at least one Commodity"}</p> : ""}
            </div> */}
        </div >
      </>
    )
  }

  // ----final component return[start]----
  if (isAdminModal) {
    return (
      <div className="modal-body calc-inner-modal">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="container-fluid">
          <div className="row">
            {/* Navigation in case of modal: 1-prev, 2-neutral, 3-next(not used 0 coz its falsy)*/}
            {(registrationData.payload.modalNav === 1) && (registrationData.payload.modalNav = 2) && handleBack()}
            {(registrationData.payload.modalNav === 3) && (registrationData.payload.modalNav = 2) && postCommodities()}
            <div className="col-md-12">
              {commodityPickerCommon()}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="on-bording">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <ul className="next-button">
          <li onClick={handleBack}><button >{"<< Previous"}</button></li>
          <li onClick={postCommodities}><button >{"Next >>"}</button></li>
        </ul>

        <div className="container-fluid">
          <div className="row">
            <Header title={'Commodities Information'} />
            <div className="col-md-8 mx-auto bg-white rounded p-4 mt-3 calc overflow-auto">
              {commodityPickerCommon()}
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ----final component return[start]----

}


const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommoditiesPicker)