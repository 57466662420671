import React, { useEffect, useState } from 'react';
import call from '../../service';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { setShowViewProfileModal, setShowCreateProfileAdminModal } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';
import ViewProfileDemo from './viewProfileToAdmin'
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import columns from '../dataTablesColumsMap/userListColumns';
import customStyles from '../dataTablesColumsMap/customTableCss';
import DataTable from 'react-data-table-component';
import avatarUrl from '../../utils/makeAvatarUrl';

const ManageUsers = ({ userTokenDetails, compType, setShowViewProfileModal, showViewProfileModal, dispatch }) => {


  const [showEmailModal, setShowEmailModal] = useState(false)
  const [refresh, setrefresh] = useState(0)
  const [mailData, setMailData] = useState({
    mailReceiver: ""
  });
  const [profileShow, setprofileShow] = useState(false)
  const [dbTypeData, setdbTypeData] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [completeButton, setcompleteButton] = useState(0);
  const [showPlanModal, setShowPlanModal] = useState({ modal: false, data: {} });
  const [planDetails, setplanDetails] = useState([]);
  const [userActivePlan, setuserActivePlan] = useState({});
  const [modalStatus, setmodalStatus] = useState(true);
  const [cardValue, setCardValue] = useState('');

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  const apiRoute = "getUsers"

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls

    call('GET', 'getuserstypelist').then((result) => {
      setdbTypeData(result)
    }).catch((e) => {
      console.log('error in getuserstypelist', e);
    })

    fetchTableData(1, { "loggedUserType": userTypeId, "loggedUserId": userId })
    //------------------------------------------------------------------

  }, [refresh, filterData]);

  useEffect(() => {
    call('POST', 'v1/plan/list', { "userType": 14 }).then((result) => {
      console.log("API result gettraderplans :-->", result);
      result = result.results
      let tempDetails = [], tempData = []

      // setplanDataKeys(Object.keys(result[0].features))

      for (let i = 0; i < result.length; i++) {
        if (result[i].showPlan) {
          tempData.push(result[i].features)
          delete result[i].features
          tempDetails.push(result[i])
        }
      }
      // setplanData(tempData)
      setplanDetails(tempDetails)

    }).catch((e) => {
      console.log("Error while querying gettraderplans:", e);
    })

    call('GET', 'v1/plan').then((result) => {
      console.log("API result in user plans:-->", result);
      setuserActivePlan(result)
      // setplanName("Subscribtion Plans (Active: " + result.name + ")")
    }).catch((e) => {
      console.log("Error while querying gettraderplans:", e);
    })

  }, [])



  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState({})
  const [searchKey, setsearchKey] = useState("")

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', apiRoute, objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.data, result.currentPage))
      setTotalRows(result.count.total)
      setCount(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', apiRoute, objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.data, result.currentPage))
      setTotalRows(result.count.total)
      setCount(result.count)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  function formatDataFortable(dataArray, pageNo) {
    let resultArray = []
    let s_no = (pageNo - 1) * perPage + 1;
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.s_no = s_no;
      tempObj.country = (<span className="shadow">
        <ReactCountryFlag
          countryCode={dataArray[i].userDetails && dataArray[i].userDetails.country_code ? dataArray[i].userDetails.country_code : ''}
          style={{ width: '25px', height: '25px' }} svg />
      </span>)
      tempObj.avatar = <img src={avatarUrl(dataArray[i].userDetails && dataArray[i].userDetails.user_avatar ? dataArray[i].userDetails.user_avatar : '--')} alt="Avatar" width="32" height="32" />
      tempObj.name = dataArray[i].userDetails && dataArray[i].userDetails.company_name ? dataArray[i].userDetails.company_name : '--';
      tempObj.category = dataArray[i].userType && dataArray[i].userType.usertype_pretty_name ? dataArray[i].userType.usertype_pretty_name : '--'
      tempObj.contactPerson = dataArray[i].userDetails && dataArray[i].userDetails.contact_person ? dataArray[i].userDetails.contact_person : '--'
      tempObj.email = dataArray[i].userDetails && dataArray[i].userDetails.email_id ? dataArray[i].userDetails.email_id : '--'
      tempObj.noOfContracts = dataArray[i].nContracts || dataArray[i].nContracts === 0 ? dataArray[i].nContracts : '--'
      tempObj.created_at = dataArray[i].createdAt ? formatDate_Application(dataArray[i].createdAt) : "--";
      tempObj.action = (<>

        {(compType === 'admin') ? <>
          {(dataArray[i].status / 1) === 1 ?
            (<span className="btn btn-success btn-sm" onClick={() => { setShowViewProfileModal(true, dataArray[i]); setprofileShow(true) }} tooltip="Approved"><i className="fa fa-check-circle"> </i></span>) :
            (dataArray[i].status / 1) === 2 ?
              (<span className="btn btn-danger btn-sm" onClick={() => { setShowViewProfileModal(true, dataArray[i]); setprofileShow(true) }} tooltip="Rejected"><i className="fa fa-info-circle"> </i></span>) :
              (<span className="btn btn-warning btn-sm" onClick={() => { setShowViewProfileModal(true, dataArray[i]); setprofileShow(true) }} tooltip="Pending"><i className="fa fa-hourglass-start"> </i> </span>)}
        </> :
          <>
            <span className="btn btn-primary btn-sm" onClick={() => { setShowViewProfileModal(true, dataArray[i]); setprofileShow(true) }} tooltip="view"><i className="fa fa-eye"> </i></span>
          </>}

        {(aclMap.mng_subusers && aclMap.mng_subusers.send_email_to_user && aclMap.mng_subusers.send_email_to_user.isExist) &&
          <button className="btn btn-info btn-sm ml-1" onClick={() => {
            setShowEmailModal(true);
            mailData.mailReceiver = dataArray[i].userDetails && dataArray[i].userDetails.email_id ? dataArray[i].userDetails.email_id : ''
          }} tooltip="Send Email">
            <i className="fa fa-envelope-o" aria-hidden="true"></i>
          </button>}

        {/* <button className="btn btn-info btn-sm ml-1" onClick={() => { setShowPlanModal({ modal: true, data: dataArray[i] }) }}>
          <i class="fas fa-shopping-cart" aria-hidden="true"></i>
        </button> */}
      </>)

      s_no++;
      resultArray.push(tempObj)
    }
    return resultArray
  }

  const assignPlanDetail = (type) => {
    Object.keys(planDetails).forEach((plan) => {
      if (type == plan.name) {
      }
    })
  }

  // Handlers
  const handleChangeMail = (event) => {
    event.persist();
    setMailData({ ...mailData, [event.target.name]: event.target.value });
  }

  const handleSendMail = (event) => {
    event.preventDefault();
    toastDisplay("sending mail...", "success");
    call('POST', 'sendMail', mailData).then((res) => {
      toastDisplay("Mail sent successfuly.", "success");
      setShowEmailModal(false);
    }).catch(error => {
      console.log("Error occure while sending mail:", error)
      toastDisplay("Unable to process mail.", "error");
    });
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    if (type === 3) {
      setFilterData({
        ...filterData,
        onlyCompleted: true,
        onlyIncompleted: false,
        onlyPending: false
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyIncompleted: true,
        onlyPending: false
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyIncompleted: false,
        onlyPending: true
      })
    }
  };

  const reset = () => {
    setFilterData({ searchUserType: "", search: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------

  if (showViewProfileModal.modal === false && profileShow === true && showViewProfileModal.info.refeshData === true) {
    setrefresh(refresh + 1)
    setprofileShow(false)
  }



  return (
    <div className="">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="d-flex mt-3 mb-3">
        {(compType === 'admin') && <div className="filter-bg">
          <ul>
            <li className={"pilot-cont cursor-pointer" + (completeButton === 1 ? "shadow" : '')} onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
              <span className="icon"><img src="../assets/images/filter-icon.svg" alt="" /></span>Pending</li>
            <li className={"warning cursor-pointer " + (completeButton === 2 ? "shadow" : '')} onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
              <span className="icon"><img src="../assets/images/filter-icon.svg" alt="" /></span>Rejected</li>
            <li className={"complete-cont cursor-pointer " + (completeButton === 3 ? "shadow" : '')} onClick={() => { setcompleteButton(3); filterStateButtons(3); }}>
              <span className="icon"><img src="../assets/images/filter-icon.svg" alt="" /></span>Approved</li>
          </ul>
        </div>}
        <div className="ml-auto">
          <ul className="range-slider">
            <li >
              <div className="d-md-flex" >
                <div className="mr-1" >
                  <select className="form-control" name="searchUserType" value={filterData.searchUserType} onChange={filterState}>
                    <option value="" selected>All users</option>
                    {(dbTypeData.length) ? dbTypeData.map((item) => {
                      if (item.id > 2) {
                        return (<option value={item.id}>{item.usertype_pretty_name}</option>)
                      }
                    }) : ''
                    }
                  </select>
                </div>
                <div className="ml-1 position-relative input-margin" >
                  <input placeholder="Search" type="text" name="search" className="form-control" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                  <button type="button" onClick={() => {
                    setFilterData({
                      ...filterData,
                      "search": searchKey
                    })
                  }}><i class="fas fa-search fa-lg"></i></button>
                </div>
              </div>
            </li>
            <li>
              <label>From</label>
              <input type="date" name="from" onChange={filterState} />
            </li>
            <li>
              <label>To</label>
              <input type="date" name="to" onChange={filterState} />
            </li>
            <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
          </ul>
        </div>
      </div>

      {/* Profile content Grid */}
      <div className="table-responsive">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
          persistTableHead
          // expandableRows
          // expandableRowsComponent={<TableExpandedComponent />}
          expandOnRowClicked
          highlightOnHover
          onSort={handleSort}
          sortServer
          striped
          noHeader
          customStyles={customStyles}
        />
      </div>
      <div className="float-right">
        <br />
      </div>

      {/*  Email Modal */}
      {
        showEmailModal && <div className={"modal" + (showEmailModal ? " show" : "")} id="docAcl">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Send Email </h4>
                <button type="button" className="close" onClick={() => { setShowEmailModal(false) }}>×</button>
              </div>
              <div className="modal-body">
                <form className="pl-5 pr-5 pt-3">
                  <div className="form-group">
                    <label >To</label>
                    <input type="email" className="form-control" placeholder="email" name="mailReceiver" value={mailData.mailReceiver} onChange={handleChangeMail} />
                  </div>
                  <div className="form-group">
                    <label >Subject</label>
                    <input type="text" className="form-control" placeholder="Subject" name="mailSubject" value={mailData.mailSubject} onChange={handleChangeMail} />
                  </div>
                  <div className="form-group">
                    <textarea rows="4" cols="50" className="form-control" name="mailBodyText" onChange={handleChangeMail}></textarea>
                  </div>
                  <div className="form-group text-right mb-0">
                    <button className="btn btn-primary btn-sm" onClick={handleSendMail}>Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }

      {showViewProfileModal.modal && <ViewProfileDemo
        userTokenDetails={userTokenDetails}
        refresh={refresh}
        setrefresh={setrefresh}
        compType={'admin'} />}

      {/* Plan Modal */}
      {showPlanModal.modal &&
        <div className={"modal" + (showPlanModal ? " show" : "")} id="docAcl">
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Plan Payment Details</h4>
                <button type="button" className="close" onClick={() => { setShowPlanModal({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body calc-inner-modal">
                <div className="accordionWrapper row pr-0 pt-0">
                  <div className="container-fluid accordionItem open">
                    <h3 className="green mt-3">
                      Alloted Plan Details
                      <button type="button" onClick={() => setmodalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-folder-open fa-sm text-primary"></i>
                      </button>
                    </h3>
                    {showPlanModal.data.userDetails.plan_quota != null ?
                      <div className="col-md-12">
                        <div className="modal-padding">
                          <ul className="price-ul">
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.commodities ? "True" : "False"}</div>
                              <div className="price-right">Commodities</div>
                            </li>
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.finance ? "True" : "False"}</div>
                              <div className="price-right">Finance</div>
                            </li>
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.partners ? "True" : "False"}</div>
                              <div className="price-right">Partners</div>
                            </li>
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.subUsers ? "True" : "False"}</div>
                              <div className="price-right">SubUsers</div>
                            </li>
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.support ? "True" : "False"}</div>
                              <div className="price-right">Support</div>
                            </li>
                            <li>
                              <div className="price-left">{showPlanModal.data.userDetails.plan_quota.trades ? "True" : "False"}</div>
                              <div className="price-right">Trades</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      : <div className="col-md-12">
                        <h3 className="text-center">No Plans Alloted</h3></div>
                    }
                    <hr />
                  </div>
                </div>

                <div className="accordionWrapper row pr-0 pt-0">
                  <div className="container-fluid accordionItem open">
                    <h3 className="green mt-3">
                      Plans Payment Details
                      <button type="button" onClick={() => setmodalStatus(true)} className="close pt-0 mb-4" data-dismiss="modal" >
                        <i class="fas fa-folder-open fa-sm text-primary"></i>
                      </button>
                    </h3>


                    <div className="row w-100">
                      <div className="col-md-3 col-md-3 col-sm-6 col-xs-12" onClick={() => setCardValue('Trader Standard')}>
                        <div className={"card mb-3 shadow-sm profile-view " + (cardValue == 'Trader Standard' ? "shadow-sm-success" : "shadow-sm")}>

                          <div className="card-header">
                            <h2 className="card-text">Trader Standard</h2>
                            <h3 className="card-text">$1000</h3>
                            <p className="card-text">Per Month</p>
                          </div>

                          <div className="card-body">
                            <ul>
                              <li>
                                <button className="btn btn-primary btn-sm" onClick={assignPlanDetail('Trader Standard')}>Get Detail</button>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-3 col-md-3 col-sm-6 col-xs-12" onClick={() => setCardValue('Trader Professional')}>
                        <div className={"card mb-3 shadow-sm profile-view " + (cardValue == 'Trader Professional' ? "shadow-sm-success" : "shadow-sm")}>

                          <div className="card-header">
                            <h2 className="card-text">Trader Professional</h2>
                            <h3 className="card-text">$5000</h3>
                            <p className="card-text">Per Month</p>
                          </div>

                          <div className="card-body">
                            <ul>
                              <li>
                                <button className="btn btn-primary btn-sm" onClick={assignPlanDetail('Trader Professional')}>Get Detail</button>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-3 col-md-3 col-sm-6 col-xs-12" onClick={() => setCardValue('Trader Enterprise')}>
                        <div className={"card mb-3 shadow-sm profile-view " + (cardValue == 'Trader Enterprise' ? "shadow-sm-success" : "shadow-sm")}>

                          <div className="card-header">
                            <h2 className="card-text">Trader Enterprise</h2>
                            <h3 className="card-text">$10000</h3>
                            <p className="card-text">Per Month</p>
                          </div>

                          <div className="card-body">
                            <ul>
                              <li>
                                <button className="btn btn-primary btn-sm" onClick={assignPlanDetail('Trader Enterprise')}>Get Detail</button>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>}

    </div >
  )
};

const mapStateToProps = state => {

  return {
    showViewProfileModal: state.showViewProfileModal,
    showCreateProfileAdminModal: state.showCreateProfileAdminModal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setShowViewProfileModal: (flag, data) => { dispatch(setShowViewProfileModal({ modal: flag, info: data })) },
    setShowCreateProfileAdminModal: (flag, data) => { dispatch(setShowCreateProfileAdminModal({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsers)
