export default function validate(values, file, financeDetails, userTypeId) {
  let errors = {};

  if (!values.applicantEmail) {
    errors.applicantEmail = 'Applicant is Missing';
  }
  if (!values.contractAttach) {
    errors.contractAttach = 'Contract is Missing';
  }
  if (!values.dirCurrency) {
    errors.dirCurrency = 'Currency is Missing';
  }
  if (!values.amount) {
    errors.amount = 'Req Amount is Missing';
  }
  if (!values.lenderId) {
    errors.lenderId = 'Lender is Missing';
  }
  if (!values.creditDays) {
    errors.creditDays = 'Credit Days is Missing';
  }
  if (userTypeId / 1 === 8) {
    if (!values.interestRate) {
      errors.interestRate = 'Interest Rate is Missing';
    }
    if (!values.fintnc) {
      errors.fintnc = 'TNC is Missing';
    }
  }


  console.log("error in finance form==>", errors)

  return errors;
}
