import call from '../../service';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactCountryFlag from "react-country-flag";
import toastDisplay from '../../utils/toastNotification';
import { setContractStateLogi, setaddBankObj } from '../../store/actions/action';
import validate from '../../utils/validation_files/shipContractValidationRules'
import { todayDate_DatePicker, dateTimeFormatter_DatePicker, formatDate_Application } from '../../utils/dateFormaters';
import avatarUrl from '../../utils/makeAvatarUrl';
import AddBankComponent from '../utilComponents/addBankComponent'
import CountryList from '../utilComponents/countryList'
import toolTipText from '../../utils/toolTips/shipmentContract.json'
import TncForm from '../tncComponents/tncForms'
import commImageUrl from '../../utils/makeCommImageUrl'
import FilePreview from '../utilComponents/previewFile'
// import ContainerComp from './containersComp.js'

const ContractModal = ({ userTokenDetails, contractLogi, setContractStateLogi, setaddBankObj, addBankObj, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [logdata, setlogData] = useState({
    logiContractType: contractLogi.info.logiContractType
  });
  const [activeClass, setActiveClass] = useState(1);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnloading, setPortUnloading] = useState([]);
  const [chartererBank, setchartererBank] = useState();
  const [shipownerBank, setshipownerBank] = useState();
  const [viewAccordion, setViewAccordion] = useState(0);
  const [errorData, seterrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [tabing, settabing] = useState(false);
  const [dbSpecData, setdbSpecData] = useState({});
  const [fileData, setFileData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [currencyData, setcurrencyData] = useState([]);
  const [profileExist, setprofileExist] = useState(true);
  const [profileData, setprofileData] = useState({});
  const [paymentModes, setpaymentModes] = useState([]);
  const [dataTnc, setdataTnc] = useState({});
  const [containerDetails, setContainerDetails] = useState({});
  const [editCount, setEditCount] = useState(0);
  // let [extraTnc, setextraTnc] = useState([]);
  const [containerTypeData, setcontainerTypeData] = useState([]);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [previewFileData, setpreviewFileData] = useState({})
  const [tncOrder, settncOrder] = useState([])
  const [commodityCategories, setcommodityCategories] = useState([]);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userAvatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------

    setlogData(logdata => ({
      ...logdata,
      user_type: userTypeId,
      user_email: userEmail,
      user_id: userId,
    }));

    //------------------------------------------------------------------
    //API Calls
    if (contractLogi.info.logiContractType === 'OnlineContract') {
      setshowLoader(true)

      call('POST', 'getShipContracts', { contractNo: contractLogi.info.contractNo, contractOnly: true }).then((result) => {
        console.log('api result  in getShipContracts-->', result);
        setlogData({
          ...logdata,
          contractId: result.id ? result.id : '',
          contractNo: result.contract_no ? result.contract_no : '',
          contractAlias: result.ship_contract_alias ? result.ship_contract_alias : '',
          commodity: result.commodity_name ? result.commodity_name : result.off_data.commodity ? result.off_data.commodity : '',
          commodity_id: result.commodity_id ? result.commodity_id : result.off_data.commodity_id ? result.off_data.commodity_id : '',
          commFilename: result.comm_filename ? result.comm_filename : '',
          quantity: result.ship_quantity ? result.ship_quantity : '',
          laycanStart: result.laycan_start ? dateTimeFormatter_DatePicker(result.laycan_start) : result.off_data.laycan_start ? result.off_data.laycan_start : '',
          laycanEnd: result.laycan_end ? dateTimeFormatter_DatePicker(result.laycan_end) : result.off_data.laycan_end ? result.off_data.laycan_end : '',
          // norDate: result.nor_date ? dateTimeFormatter_DatePicker(result.nor_date) : '',
          loadingCountry: result.ship_load_country ? result.ship_load_country : '',
          unloadingCountry: result.ship_unload_country ? result.ship_unload_country : '',
          loadingPort: result.ship_load_port ? result.ship_load_port : '',
          unloadingPort: result.ship_unload_port ? result.ship_unload_port : '',
          charterer: result.charterer ? result.charterer : '',
          shipOwner: result.shipOwner ? result.shipOwner : '',
          chartererEmail: result.chartererEmail ? result.chartererEmail : '',
          shipOwnerEmail: result.shipOwnerEmail ? result.shipOwnerEmail : '',
          shipOwnerAvatar: result.shipOwner_avatar ? result.shipOwner_avatar : '',
          chartererAvatar: result.charterer_avatar ? result.charterer_avatar : '',
          shipPrice: result.ship_price ? result.ship_price : '',
          shipCurrency: result.ship_currency ? result.ship_currency : '',
          quantityIndexType: result.ship_qty_index_type ? result.ship_qty_index_type : '',
          quantityIndex: result.ship_qty_index ? result.ship_qty_index : '',
          quantityLoadUnit: result.ship_qty_load_unit ? result.ship_qty_load_unit : '',
          demmurageRate: result.ship_demmurage_price ? result.ship_demmurage_price : '',
          charterType: result.charter_type ? `${result.charter_type}` : '',
          containerCount: result.ship_container_count,
          containerType: result.ship_container_type,
          shipmentType: result.shipment_type,
          berthLoadingNumber: result.berth_loading ? result.berth_loading : '',
          anchorageLoadingNumber: result.anchorage_loading ? result.anchorage_loading : '',
          berthUnloadingNumber: result.berth_unloading ? result.berth_unloading : '',
          anchorageUnloadingNumber: result.anchorage_unloading ? result.anchorage_unloading : '',
          berthLoading: result.berth_loading > 0 ? true : false,
          anchorageLoading: result.anchorage_loading > 0 ? true : false,
          berthUnloading: result.berth_unloading > 0 ? true : false,
          anchorageUnloading: result.anchorage_unloading > 0 ? true : false,
          loadingRate: result.loading_rate ? result.loading_rate : 0,
          unloadingRate: result.unloading_rate ? result.unloading_rate : 0,
          charterStatus: result.charterer_status ? result.charterer_status : 0,
          shipownerStatus: result.shipowner_status ? result.shipowner_status : 0,
          charterer_id: result.charterer_id ? result.charterer_id : null,
          ship_owner_id: result.ship_owner_id ? result.ship_owner_id : null,
          paymentMode: result.payment_mode ? result.payment_mode : "",
          shipownerBank: result.ship_owner_bank_id ? result.ship_owner_bank_id : "",
          chartererBank: result.charterer_bank_id ? result.charterer_bank_id : "",
          edit_request: result.edit_request,
          off_data: result.off_data
        })

        setdataTnc(result.tnc ? result.tnc : {})
        handleShipmentType({ target: { value: result.shipment_type }, persist: () => { } })
        if (result.shipment_type === "container")
          setContainerDetails(result.container_data ? result.container_data : {})

        call('POST', 'getCountryPort', { 'countryId': result.ship_load_country.split(':')[1] }).then((result) => {
          // console.log('setPortLoading in getCountryPort-->', result);
          setPortLoading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })

        call('POST', 'getCountryPort', { 'countryId': result.ship_unload_country.split(':')[1] }).then((result) => {
          // console.log('setPortUnloading in getCountryPort-->', result);
          setPortUnloading(result)
        }).catch((e) => {
          console.log('error in getCountryPort', e);
        })

        call('POST', 'getUserBanks', { email: result.chartererEmail }).then((result) => {
          setchartererBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setchartererBank:", e);
        })

        call('POST', 'getUserBanks', { email: result.shipOwnerEmail }).then((result) => {
          setshipownerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setshipownerBank:", e);
        })

        setshowLoader(false)

      }).catch((e) => {
        setshowLoader(false)
        console.log("Error while querying getShipContracts:", e);
      })
    }

    if (contractLogi.info.logiContractType === 'brokerOfflineContract') {
      call('GET', 'getcommoditycategory').then((result) => {
        setcommodityCategories(result);
      }).catch((e) => {
        // console.log("Error while querying getcommoditycategory:", e);
      })
      // call('POST', 'getCommodityList', { specifications: false, commadd: false }).then((result) => {
      //   console.log("result getCommodityList:", result);
      //   setdbSpecData(result.message);
      // }).catch((e) => {
      //   console.log("Error while querying getCommodityList:", e);
      // })
      call('POST', 'getUserBanks', { email: userEmail }).then((result) => {
        setshipownerBank(result)
      }).catch((e) => {
        console.log("Error while querying getUserBanks setshipownerBank:", e);
      })

      setlogData(logdata => ({
        ...logdata,
        charterType: '1'
      }));

    }


    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })

    call('get', 'getpaymentmodes').then((result) => {
      setpaymentModes(result)
    }).catch((e) => {
      console.log('error in getpaymentmodes', e);
    })

    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logdata.commdCategorys) {
      call('POST', 'getCommodityList', { specifications: false, commadd: false, commCatId: logdata.commdCategorys }).then((result) => {
        // console.log("result getCommodityList:", result);
        setdbSpecData(result.message);
        handleCommodity("", "")
      }).catch((e) => {
        // console.log("Error while querying getCommodityList:", e);
      })
    }
    else if (logdata.commdCategorys === "") {
      setlogData({ ...logdata, "commName": "" })
      setdbSpecData([]);
      handleCommodity("", "")
    }
  }, [logdata.commdCategorys])

  useEffect(() => {

    if (contractLogi.info.logiContractType === 'OnlineContract') {
      if (logdata.chartererEmail) {
        call('POST', 'getUserBanks', { "email": logdata.chartererEmail }).then((result) => {
          setchartererBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setchartererBank:", e);
        })
      }
      if (logdata.shipOwnerEmail) {
        call('POST', 'getUserBanks', { "email": logdata.shipOwnerEmail }).then((result) => {
          setshipownerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setshipownerBank:", e);
        })
      }
    }

    if (contractLogi.info.logiContractType === 'brokerOfflineContract') {
      if (logdata.chartererEmail) {
        call('POST', 'getUserBanks', { "email": logdata.chartererEmail }).then((result) => {
          setchartererBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setchartererBank:", e);
        })
      }
      if (logdata.shipOwnerEmail) {
        call('POST', 'getUserBanks', { "email": userEmail }).then((result) => {
          setshipownerBank(result)
        }).catch((e) => {
          console.log("Error while querying getUserBanks setshipownerBank:", e);
        })
      }
    }

  }, [addBankObj.info.refresh, logdata.chartererEmail]);

  useEffect(() => {
    if (Object.keys(errorData).length === 0 && isSubmitting) {
      doNext();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [errorData]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleActiveClass = (id) => {
    if (id < activeClass) {
      setActiveClass(id)
    } else {
      settempactiveClass(id);
      setIsSubmitting(true)
      settabing(true)
      seterrorData(validate(logdata, dataTnc, activeClass, userTypeId, contractLogi.info.logiContractType))
    }
  }

  const handleNext = () => {
    setIsSubmitting(true)
    seterrorData(validate(logdata, dataTnc, activeClass, userTypeId, contractLogi.info.logiContractType))
  }

  const doNext = () => {
    if (tabing === true) {
      setActiveClass(tempactiveClass);
      setIsSubmitting(false)
      settabing(false)
    } else {
      if (activeClass >= maxactiveClass) {
        setmaxactiveClass(activeClass + 1)
      }
      setActiveClass(activeClass + 1);
      setIsSubmitting(false)
    }
  }


  const handlePrev = (id) => setActiveClass(id - 1);

  const handleTNC = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let tncdataElement = { ...dataTnc }
    tncdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setdataTnc(tncdataElement);
    setEditCount(editCount + 1)
  }

  const handleViewAccordion = () => {
    setViewAccordion(viewAccordion ? 0 : 1);
  }

  const handleChange = (event) => {
    event.persist();
    if (event.target.name === "commName" && event.target.value) {
      let selectedCommodity = dbSpecData.filter(item => { if (item.id == event.target.value) { return item } })[0]
      // console.log('selectedCommodity==>', selectedCommodity);
      handleCommodity(selectedCommodity.id, selectedCommodity.commodity_pretty_name)
    }
    if (event.target.name === "commName" && !event.target.value) {
      handleCommodity("", "")
    }
    setlogData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    setEditCount(editCount + 1)
  }

  const handleChecked = (event) => {
    event.persist();
    setlogData(logdata => ({ ...logdata, [event.target.name]: event.target.checked }));
    seterrorData(errorData => ({ ...errorData, [event.target.name]: "" }))
    setEditCount(editCount + 1)
  }

  function handleCommodity(commID, commName) {
    setlogData(logdata => ({ ...logdata, ["commodity_id"]: commID, ["commodity"]: commName }));
    seterrorData(errorData => ({ ...errorData, ["commodity_id"]: "" }))
    setEditCount(editCount + 1)
  }

  const handleFile = (event) => {
    event.persist();
    setFileData(fileData => ({ ...fileData, [event.target.name]: event.target.files[0] }))
    setEditCount(editCount + 1)
  }

  const handleShipmentType = (event) => {
    event.persist();
    if (event.target.value === "container") {
      call('get', 'getcontainertypes').then((result) => {
        setcontainerTypeData(result)
      }).catch((e) => {
        console.log('error in getcurrencylist', e);
      })
    }
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function getUserDetails(email) {
    call('POST', 'getuserprofiledata', { 'email': email ? email : '', 'min': true }).then((result) => {
      console.log("result in getuserprofiledata-->", result)
      if (result != undefined && result.company_name) {
        setprofileData(result)
        setlogData(logdata => ({ ...logdata, ["chartererName"]: result.company_name, ["chartererAddr"]: result.user_name }));
        setprofileExist(true)
      } else {
        setprofileData({})
        setprofileExist(false)
      }
    }).catch((e) => {
      console.log('error in getuserprofiledata', e);
    })
  }

  function getSelectName(id, value) {
    let sel = document.getElementById(id);
    if (sel) {
      for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].value === value) {
          return sel.options[i].text
        }
      }
    } else {
      return '--'
    }
  }

  function showFixtureDoc() {
    console.log("in function==>", logdata.off_data.fixtureDocData)
    setpreviewFileData({ ...logdata.off_data.fixtureDocData, "action": "view" })
    setshowPreviewModal(true)
  }
  console.log("in setshowPreviewModal==>", showPreviewModal)

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit Handlers
  function handleSubmitOffline() {
    setshowLoader(true)
    console.log("Data in submit>>?>>>", logdata)
    var formData = new FormData();
    Object.keys(logdata).forEach((key) => {
      formData.append(key, logdata[key]);
    })
    Object.keys(fileData).forEach((key) => {
      formData.append(key, fileData[key]);
    })

    formData.append("tnc", JSON.stringify(dataTnc));
    formData.append("containerDetails", JSON.stringify(containerDetails));

    call('POST', 'createContractShipment', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        // toastDisplay("Contract Created!", "success")
        setContractStateLogi(false, { refresh: true })
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Error Occured!", "error")
      //setContractStateLogi(false, {})
    })
  }

  function handleSubmitOnline(operator) {
    setshowLoader(true)
    console.log("Data in update>>>>>", logdata)

    let formObject = JSON.stringify(logdata)
    formObject = JSON.parse(formObject)

    delete formObject.shipOwnerAvatar
    delete formObject.chartererAvatar
    delete formObject.commodity
    delete formObject.commodity_id
    delete formObject.commFilename
    delete formObject.quantity
    delete formObject.laycanStart
    delete formObject.laycanEnd
    delete formObject.loadingCountry
    delete formObject.unloadingCountry
    delete formObject.charterer
    delete formObject.shipOwner
    delete formObject.shipPrice
    delete formObject.shipCurrency
    delete formObject.charterStatus
    delete formObject.shipownerStatus
    delete formObject.off_data


    var formData = new FormData();
    Object.keys(formObject).forEach((key) => {
      formData.append(key, formObject[key]);
    })

    Object.keys(fileData).forEach((key) => {
      formData.append(key, fileData[key]);
    })

    formData.append("tnc", JSON.stringify(dataTnc));
    // formData.append("containerDetails", JSON.stringify(containerDetails));

    call('POST', 'createContractShipment', formData).then((result) => {
      if (result) {
        if (operator === "U&A") {
          aggrementOnline()
        } else {
          setshowLoader(false)
          toastDisplay("Contract Updated!", "success")
          setContractStateLogi(false, { refresh: true })
        }
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Error Occured!", "error")
      //setContractStateLogi(false, {})
    })
  }

  function aggrementOnline() {
    setshowLoader(true)

    let postObj = { ship_owner_id: logdata.ship_owner_id, charterer_id: logdata.charterer_id, charterStatus: logdata.charterStatus, shipownerStatus: logdata.shipownerStatus, contractId: logdata.contractId }

    call('POST', 'agreeShipmentContract', postObj).then((result) => {
      if (result) {
        setshowLoader(false)
        toastDisplay("Contract Created!", "success")
        setContractStateLogi(false, { refresh: true })
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay("Error Occured!", "error")
      //setContractStateLogi(false, {})
    })
  }


  function raiseContractEditRequest() {
    let formData = new FormData();
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("contract_id", logdata.contractId);

    call('POST', 'raisecontracteditrequestshipment', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setContractStateLogi(false, { refresh: true })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }

  function openContractEdit(edit) {
    let formData = new FormData();
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("contract_id", logdata.contractId);
    formData.append("editSingle", edit);

    call('POST', 'opencontractforeditshipment', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setContractStateLogi(false, { refresh: true })
      }
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }



  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function tncPreviewGenerator() {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <ol className="terms-view-ul">
        {keyArray.map((key, index) => {
          return (
            <li>
              <h3>{dataTnc[key].value ? dataTnc[key].value : "--"}</h3>
              <span className="date-format">{dataTnc[key].name ? dataTnc[key].name : "--"} </span>
            </li>
          )
        })}
      </ol>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Mini preview Component
  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{logdata.charterer}</div>
            <div className="price-right">Charterer </div>
          </li>
          <li>
            <div className="price-left">{logdata.shipOwner}</div>
            <div className="price-right">Ship Owner</div>
          </li>
          <li>
            <div className="price-left">{logdata.charterType === '1' ? 'Voyage Charter' : 'Time Charter'}</div>
            <div className="price-right">Charter Type </div>
          </li>
          <li>
            <div className="price-left">{formatDate_Application(logdata.laycanStart) + " - " + formatDate_Application(logdata.laycanEnd)}</div>
            <div className="price-right">Laycan</div>
          </li>
          <li>
            <div className="price-left"> {logdata.commodity ? logdata.commodity.toUpperCase() : "--"}</div>
            <div className="price-right"> Commodity</div>
          </li>

          <li>
            <div className="price-left">{logdata.quantity}</div>
            <div className="price-right">Quantity</div>
          </li>
          <li>
            <div className="price-left">
              {logdata.loadingCountry ? <span className="shadow">
                <ReactCountryFlag
                  countryCode={logdata.loadingCountry ? logdata.loadingCountry.split(':')[0] : ''}
                  style={{ width: '25px', height: '25px' }} svg />
              </span> : ""}
              {logdata.loadingPort ? logdata.loadingPort : '--'}
            </div>
            <div className="price-right">Loading Port</div>
          </li>
          <li>
            <div className="price-left">
              {logdata.unloadingCountry ? <span className="shadow">
                <ReactCountryFlag
                  countryCode={logdata.unloadingCountry ? logdata.unloadingCountry.split(':')[0] : ''}
                  style={{ width: '25px', height: '25px' }} svg />
              </span> : ""}
              {logdata.unloadingPort ? logdata.unloadingPort : '--'}
            </div>
            <div className="price-right">Unloading Port</div>
          </li>
        </ul>
      </div>
    )
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //shipownerBankList
  function getshipownerBankList() {
    let shipownerBankList = shipownerBank && shipownerBank.length ? shipownerBank.map((value, index) => {
      return (
        <option value={value.bank_branch_id}>{value.company_name}</option>
      )
    }) : ''
    return shipownerBankList
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //chartererBankList
  function getchartererBankList() {
    let chartererBankList = chartererBank && chartererBank.length ? chartererBank.map((value, index) => {
      return (
        <option value={value.bank_branch_id}>{value.company_name}</option>
      )
    }) : ''
    return chartererBankList
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //Payment options list
  function getPaymentOptionslist() {
    let PaymentOptionslist = paymentModes ? paymentModes.map((value, index) => {
      return (
        <div className="switch-field ml-1 mr-1">
          <input type="radio" name="paymentMode" id={"paymentMode" + value.id} value={value.id} checked={(logdata.paymentMode / 1 === value.id) ? true : false} onChange={handleChange} />
          <label for={"paymentMode" + value.id}>{value.payment_type_pretty}</label>
        </div>
      )
    }) : ''
    return PaymentOptionslist
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Ports Component
  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  const unloadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortUnloading(result)
    }).catch((e) => {
      console.log('error in getCountryPort unloadPort', e);
    })
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body Console
  console.log("logdata in logi contract -->", logdata)
  // console.log("userId-->", userId);
  // console.log("logdata.charterer_id-->", logdata.charterer_id);
  // console.log("logdata.ship_owner_id-->", logdata.ship_owner_id);
  // console.log("aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist-->", aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist);

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {<div className={"modal" + ((contractLogi.modal && activeClass < 7) ? " show" : "")} id="CreateContract">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">{(contractLogi.info.logiContractType === 'brokerOfflineContract') ? "Add Offline Contract" : "Shipment Contract"}</h4>
              <button type="button" className="close" onClick={() => { setContractStateLogi(false, {}) }}>×</button>
            </div>
            <div className="modal-body calc-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div id="tabs1" className="tab-contents no-js">
                <div className="tab-nav">

                  <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Master Contract info</li>
                  <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Quantity & Price</li>
                  <li id="3" className={"tab-link" + (activeClass === 3 ? " is-active" : "") + ((3 < maxactiveClass) ? " is-completed" : "")} onClick={(3 <= maxactiveClass) ? () => handleActiveClass(3) : ""}>Shipment Details</li>
                  <li id="4" className={"tab-link" + (activeClass === 4 ? " is-active" : "") + ((4 < maxactiveClass) ? " is-completed" : "")} onClick={(4 <= maxactiveClass) ? () => handleActiveClass(4) : ""}>Bank Details</li>
                  <li id="5" className={"tab-link" + (activeClass === 5 ? " is-active" : "") + ((5 < maxactiveClass) ? " is-completed" : "")} onClick={(5 <= maxactiveClass) ? () => handleActiveClass(5) : ""}>T&C</li>
                  <li id="6" className={"tab-link" + (activeClass === 6 ? " is-active" : "") + ((6 < maxactiveClass) ? " is-completed" : "")} onClick={(6 <= maxactiveClass) ? () => handleActiveClass(6) : ""}>Documents</li>
                </div>
                <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>
                  {(contractLogi.info.logiContractType === 'OnlineContract') && < div className="calc-inner-modal">
                    <div className="d-flex">
                      <div className="col-md-6">
                        <h3>{logdata.contractNo}</h3>
                        <label>Contract Number {astrix} </label>
                      </div>
                      <div className="col-md-6 row">
                        <label className="col-md-4">Contract Name {astrix} </label>
                        <div className="col-md-8">
                          <input type="text" name="contractAlias" placeholder="Enter Contract Name" value={logdata.contractAlias} className={" form-control" + (!errorData.contractAlias ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.contractAlias && <p className="text-danger error-contract">{errorData.contractAlias}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 border-top border-bottom pt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mr-auto pr-3 form-group">
                            <div className="chat-message">
                              <img src={avatarUrl(logdata.chartererAvatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="date-format">  {logdata.charterer ? logdata.charterer : '--'}</span><br />
                              </div>
                            </div>
                            <label className="col-md-12 pl-0 pt-2">Charterer</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mr-auto pr-3 form-group">
                            <div className="chat-message">
                              <img src={avatarUrl(logdata.shipOwnerAvatar)} alt="Avatar" width="32" height="32" />
                              <div className="chat-message-content clearfix pt-2">
                                <span className="date-format">  {logdata.shipOwner ? logdata.shipOwner : '--'}</span><br />
                              </div>
                            </div>
                            <label className="col-md-12 pl-0 pt-2">Ship Owner</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-padding">
                      <h3 className="mt-3 ml-2"> Commodity </h3>
                      <div className="com-md-12 row">
                        <div className="com-md-4 ml-5 text-center">
                          {logdata.commFilename && <img width="100" src={commImageUrl(logdata.commFilename)} className="img-responsive" alt="description" />}
                          <h3>{logdata.commodity}</h3>
                        </div>
                      </div>

                      <hr className="pt-1 pb-1" />
                      <div id="Check-box" className="mt-2">
                        <div className="col-md-12 mt-3">
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <label className="col-md-12">Laycan Start Date {astrix}  {toolTip('laycanStart')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="laycanStart" value={logdata.laycanStart} className={" form-control" + (!errorData.laycanStart ? '' : ' border-danger')} onChange={handleChange} required />
                              {errorData.laycanStart && <p className="text-danger error-contract">{errorData.laycanStart}</p>}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label className="col-md-12">Laycan End Date {astrix}  {toolTip('laycanEnd')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="laycanEnd" value={logdata.laycanEnd} className={" form-control" + (!errorData.laycanEnd ? '' : ' border-danger')} onChange={handleChange} required />
                              {errorData.laycanEnd && <p className="text-danger error-contract">{errorData.laycanEnd}</p>}
                            </div>
                            {/* <div className="col-md-4 mt-2">
                              <label>NOR Date (Tentative) {toolTip('norDate')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="norDate" value={logdata.norDate} className={" form-control" + (!errorData.norDate ? '' : ' border-danger')} onChange={handleChange} required />
                              {errorData.norDate && <p className="text-danger error-contract">{errorData.norDate}</p>}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                  {/* contract first page for broker start */}
                  {(contractLogi.info.logiContractType === 'brokerOfflineContract') && <div className="calc-inner-modal">
                    <div className="d-flex mb-2">
                      <div className="col-md-6 row">
                        <label className="col-md-4">Contract No {astrix}  {toolTip('contract_number')}</label>
                        <div className="col-md-8">
                          <input type="text" name="contract_number" value={logdata.contract_number} className={" form-control" + (!errorData.contract_number ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.contract_number && <p className="text-danger error-contract">{errorData.contract_number}</p>}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <label className="col-md-4">Contract Name {astrix}  {toolTip('contractAlias')}</label>
                        <div className="col-md-8">
                          <input type="text" name="contractAlias" placeholder="Enter Contract Name" value={logdata.contractAlias} className={" form-control" + (!errorData.contractAlias ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.contractAlias && <p className="text-danger error-contract">{errorData.contractAlias}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 border-top border-bottom pt-3">
                      <div className="row">
                        <div className="col-md-12 row">
                          <label className="col-md-12">Search Charterer {toolTip('chartererEmail')}</label>
                          <div className="col-md-6">
                            <input type="text" name="chartererEmail" value={logdata.chartererEmail} className={" form-control" + (!errorData.supplierEmail ? '' : ' border-danger')} onChange={handleChange} />
                            {errorData.chartererEmail && <p className="text-danger error-contract">{errorData.chartererEmail}</p>}
                          </div>
                          <div className="col-md-4 ">
                            <input type="button" value="Apply" className="btn btn-primary btn-sm" onClick={() => getUserDetails(logdata.chartererEmail)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mr-auto pr-3 form-group">
                            <div className="chat-message row">
                              <div className="col-md-2 mt-3">
                                <img src={avatarUrl(profileData.user_avatar)} alt="Avatar" width="32" height="32" />
                              </div>
                              {(profileExist && profileData) && <>
                                <div className="col-md-10 row mt-3">
                                  <div className="chat-message-content clearfix pt-2 ml-0">
                                    <span className="date-format">{profileData.company_name ? profileData.company_name : '--'}</span><br />
                                  </div>
                                  <label className="col-md-12 pt-2">Charterer Details</label>
                                </div>
                              </>}
                              {!profileExist && <>
                                <div className="col-md-10 row mt-3">
                                  <p className="col-md-12 text-danger">User not found Please fill the following details </p>
                                  <div className="col-md-4">
                                    <label className="col-md-12">Charterer Name {astrix}  {toolTip('chartererName')}</label>
                                    <input type="text" name="chartererName" value={logdata.chartererName} className={" form-control" + (!errorData.chartererName ? '' : ' border-danger')} onChange={handleChange} />
                                    {errorData.chartererName && <p className="text-danger error-contract">{errorData.chartererName}</p>}
                                  </div>
                                  <div className="col-md-4">
                                    <label className="col-md-12">Charterer Email  {astrix} {toolTip('chartererEmail')}</label>
                                    <input type="text" name="chartererEmail" value={logdata.chartererEmail} className={" form-control" + (!errorData.chartererEmail ? '' : ' border-danger')} onChange={handleChange} />
                                    {errorData.chartererEmail && <p className="text-danger error-contract">{errorData.chartererEmail}</p>}
                                  </div>
                                  <div className="col-md-4">
                                    <label className="col-md-12">Charterer User Type {astrix}  {toolTip('chartererUserType')}</label>
                                    <select className={"form-control" + (!errorData.chartererUserType ? '' : ' border-danger')} name="chartererUserType" value={logdata.chartererUserType} onChange={handleChange}>
                                      <option value="" selected>--Select User Type--</option>
                                      <option value="4">Seller/Exporter</option>
                                      <option value="3">Buyer.Importer</option>
                                    </select>
                                    {errorData.chartererUserType && <p className="text-danger error-contract">{errorData.chartererUserType}</p>}
                                  </div>
                                  <div className="col-md-12">
                                    <label className="col-md-12">Charterer Address {astrix}  {toolTip('chartererAddr')}</label>
                                    <textarea name="chartererAddr" value={logdata.chartererAddr} className={" form-control" + (!errorData.chartererAddr ? '' : ' border-danger')} onChange={handleChange} />
                                    {errorData.chartererAddr && <p className="text-danger error-contract">{errorData.chartererAddr}</p>}
                                  </div>
                                  <label className="col-md-12 pt-2">Charterer Details</label>
                                </div>
                              </>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-padding">
                      <div className="col-md-12 our-webcoderskull commodity-edit-modal">
                        <div id="SelectName" className="mb-4">
                        </div>
                        <div className='row'>
                          <div className='col-md-4 mt-2'>
                            <label >Commodity Type/Category {astrix} </label>
                            <div >
                              <select
                                className={"form-control" + (!errorData.commdCategorys ? '' : ' border-danger')} name={"commdCategorys"}
                                value={logdata.commdCategorys} onChange={handleChange}>
                                <option value="" selected>--Select Category--</option>
                                {
                                  commodityCategories.length ? commodityCategories.map((item, index) => {
                                    return (<option value={item.id}>{item.category}</option>)
                                  }) : ''
                                }
                              </select>
                              {errorData.commdCategorys && <p className="text-danger error-contract mt-2">{errorData.commdCategorys}</p>}
                            </div>
                          </div>
                          <div className='col-md-4 mt-2'>
                            <label >Commodity name {astrix} </label>
                            <div >
                              <select
                                className={"form-control" + (!errorData.commName ? '' : ' border-danger')} name={"commName"}
                                value={logdata.commName} onChange={handleChange}>
                                <option value="" selected>--Select Commodity Name--</option>
                                {
                                  dbSpecData.length ? dbSpecData.map((item, index) => {
                                    return (<option value={item.id}>{item.commodity_pretty_name}</option>)
                                  }) : ''
                                }
                              </select>
                              {logdata.commodity_id && <p className="text-success mt-2">Selected Commodity : {logdata.commodity}</p>}
                              {errorData.commName && <p className="text-danger error-contract mt-2">{errorData.commName}</p>}
                            </div>
                          </div>
                        </div>
                        {/* <ul className="row" id="">
                          {dbSpecData && dbSpecData.length ? dbSpecData.map((value, index) => {
                            return (
                              <>
                                <li className={`col-md-2 ${logdata.commodity_id === value.id ? 'active' : ''} `} true onclick="getText(0)" id="myBtn0">
                                  <div className="cnt-block equal-hight">
                                    <figure onClick={() => handleCommodity(value.id, value.commodity_pretty_name)}>
                                      <img src={commImageUrl(value.filename)} className="img-responsive" alt="" />
                                    </figure>
                                    <h3>
                                      {value.commodity_pretty_name}
                                    </h3>
                                  </div>
                                </li>
                              </>
                            )
                          }) : ''}
                        </ul> */}
                      </div>
                      <div id="Check-box" className="mt-2">
                        <div className="col-md-12 mt-3">
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <label className="col-md-12">Laycan Start Date  {astrix} {toolTip('laycanStart')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="laycanStart" value={logdata.laycanStart} className={" form-control" + (!errorData.laycanStart ? '' : ' border-danger')} onChange={handleChange} required />
                              {errorData.laycanStart && <p className="text-danger error-contract">{errorData.laycanStart}</p>}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label className="col-md-12">Laycan End Date {astrix}  {toolTip('laycanEnd')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="laycanEnd" value={logdata.laycanEnd} className={" form-control" + (!errorData.laycanEnd ? '' : ' border-danger')} onChange={handleChange} required />
                              {errorData.laycanEnd && <p className="text-danger error-contract">{errorData.laycanEnd}</p>}
                            </div>
                            {/* <div className="col-md-4 mt-2">
                              <label className="col-md-12">NOR Date (Tentative) {toolTip('norDate')}</label>
                              <input type="datetime-local" min={todayDate_DatePicker()} name="norDate" value={logdata.norDate} className={" form-control" + (!errorData.norDate ? '' : ' border-danger')} onChange={handleChange} required />
                               {errorData.norDate && <p className="text-danger error-contract">{errorData.norDate}</p>} 
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                  {/* contract first page for broker end */}
                </div>
                <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    <div className="modal-padding">
                      <div className="row d-flex mt-5">
                        <div className="d-flex col-md-12">
                          <label className="col-md-6 pl-0">Quantity to be Shipped  {astrix}  {toolTip('quantity')}</label>
                          {(contractLogi.info.logiContractType === 'brokerOfflineContract') &&
                            <div className="col-md-6">
                              <input type="number" name="quantity" value={logdata.quantity} placeholder="Enter Quantity in mt" className={" form-control" + (!errorData.quantity ? '' : ' border-danger')} onChange={handleChange} />
                              {errorData.quantity && <p className="text-danger error-contract">{errorData.quantity}</p>}
                            </div>}
                          {(contractLogi.info.logiContractType === 'OnlineContract') && <h3 className="text-primary ml-auto" > {logdata.quantity !== undefined ? logdata.quantity : "---"} mt</h3>}
                        </div>

                        <div className="d-flex col-md-12 mt-3">
                          <label className="col-md-6 pl-0">MOL Type  {astrix} {toolTip('quantityLoadUnit')}</label>
                          <div className="col-md-6">
                            <select className={"form-control" + (!errorData.quantityLoadUnit ? '' : ' border-danger')} name="quantityLoadUnit" value={logdata.quantityLoadUnit} onChange={handleChange}>
                              <option value="" selected>--Select MOL Type--</option>
                              <option value="MOLOO">MOLOO</option>
                              <option value="MOLCO">MOLCO</option>
                            </select>
                            {errorData.quantityLoadUnit && <p className="text-danger error-contract">{errorData.quantityLoadUnit}</p>}
                          </div>
                        </div>
                        <div className="d-flex col-md-12 mt-3">
                          <label className="col-md-6 pl-0">Quantity vary index  {astrix} {toolTip('quantityIndexType')}</label>
                          <div className="col-md-3">
                            <select className={"form-control" + (!errorData.quantityIndexType ? '' : ' border-danger')} name="quantityIndexType" value={logdata.quantityIndexType} onChange={handleChange}>
                              <option value="" selected>--Select Quantity vary index--</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Min/Max">Min/Max</option>
                            </select>
                            {errorData.quantityIndexType && <p className="text-danger error-contract">{errorData.quantityIndexType}</p>}
                          </div>
                          {(logdata.quantityIndexType === "Percentage") && <div className="col-md-3">
                            <input type="number" name="quantityIndex" placeholder="Enter Percentage ±" value={logdata.quantityIndex} className={" form-control" + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                            {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                          </div>}
                          {(logdata.quantityIndexType === "Min/Max") && <div className="col-md-3">
                            <input type="number" name="quantityIndex" placeholder="Enter Min/Max" value={logdata.quantityIndex} className={" form-control" + (!errorData.quantityIndex ? '' : ' border-danger')} onChange={handleChange} />
                            {errorData.quantityIndex && <p className="text-danger error-contract">{errorData.quantityIndex}</p>}
                          </div>}
                        </div>

                      </div>
                      <hr className="pt-3 pb-1" />
                      <div className="row">
                        <label className="col-md-6">Freight (pmt)  {astrix} {toolTip('shipPrice')}</label>
                        <div className="col-md-3">
                          <input type="number" step="0.01" name="shipPrice" placeholder="Enter Price" value={logdata.shipPrice} className={" form-control" + (!errorData.shipPrice ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.shipPrice && <p className="text-danger error-contract">{errorData.shipPrice}</p>}
                        </div>
                        <div className="col-md-3">
                          <select className={" form-control" + (!errorData.shipCurrency ? '' : ' border-danger')} name="shipCurrency" value={logdata.shipCurrency} onChange={handleChange}>
                            <option value="" selected>--Select Currency--</option>
                            {currencyData.map((currency) => {
                              return (
                                <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                              )
                            })}
                          </select>
                          {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-md-6">Demurrage Rate  {astrix} {toolTip('demmurageRate')}</label>
                        <div className="col-md-3">
                          <input type="number" step="0.01" name="demmurageRate" placeholder="Enter Price" value={logdata.demmurageRate} className={" form-control" + (!errorData.demmurageRate ? '' : ' border-danger')} onChange={handleChange} />
                          {errorData.demmurageRate && <p className="text-danger error-contract">{errorData.demmurageRate}</p>}
                        </div>
                        <div className="col-md-3">
                          <select className="form-control" name="demmurageCurrency" value={logdata.shipCurrency} disabled>
                            <option value="" selected>--Select Currency--</option>
                            {currencyData.map((currency) => {
                              return (
                                <option value={currency.name + ":" + currency.symbol + ":" + currency.code} >{currency.name + " ( " + currency.code + " )" + " ( " + currency.symbol + " )"}</option>
                              )
                            })}
                          </select>
                          {errorData.shipCurrency && <p className="text-danger error-contract">{errorData.shipCurrency}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={"tab-content" + (activeClass === 3 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    <div className="modal-padding">
                      <div className="row mt-2">

                        <div className="row form-group col-md-12">
                          <label className="col-md-6 ">Shipment Type {astrix}  {toolTip('shipmentType')}</label>
                          <div className="col-md-6">
                            <select className={"form-control" + (!errorData.shipmentType ? '' : ' border-danger')} name="shipmentType" value={logdata.shipmentType} onChange={(event) => { handleChange(event); handleShipmentType(event) }}>
                              <option value="" selected>--Select Shipment Type--</option>
                              <option value="bulk">Bulk Shipment</option>
                              <option value="container">Container Shipment</option>
                            </select>
                            {errorData.shipmentType && <p className="text-danger error-contract">{errorData.shipmentType}</p>}
                          </div>
                        </div>

                        {(logdata.shipmentType === "container") && <>
                          <div className="col-md-12">
                            <div className="row form-group">
                              <div className="col-md-6">
                                <label >Container Type {toolTip('containerType')}</label>
                                <select className={" form-control" + (!errorData.containerType ? '' : ' border-danger')} name="containerType" value={logdata.containerType} onChange={handleChange}>
                                  <option value="" selected>--Select Container Type--</option>
                                  {containerTypeData.map((contType) => {
                                    return (<option value={contType.id} >{contType.container_type_pretty}</option>)
                                  })}
                                </select>
                                {errorData.containerType && <p className="text-danger error-contract">{errorData.containerType}</p>}
                              </div>
                              <div className="col-md-6">
                                <label >No. Of Containers{toolTip('containerCount')}</label>
                                <input type="number" name="containerCount" value={logdata.containerCount} className="form-control" onChange={handleChange} />
                                {errorData.containerCount && <p className="text-danger error-contract">{errorData.containerCount}</p>}
                              </div>
                            </div>
                          </div>
                        </>}

                        <div className="row form-group col-md-12">
                          <label className="col-md-6">Charter Type {astrix}  {toolTip('charterType')}</label>
                          <div className="row col-md-6 ">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="charterType" id="charterType1" value="1" onChange={handleChange} checked={logdata.charterType === '1' ? true : false} />
                              <label className="form-check-label" for="charterType1">Voyage Charter</label>
                            </div>
                            {/* <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="charterType" id="charterType2" value="2" onChange={handleChange} checked={logdata.charterType === '2' ? true : false} />
                              <label className="form-check-label" for="charterType2">Time Charter</label>
                            </div> */}
                            {errorData.charterType && <p className="text-danger error-contract">{errorData.charterType}</p>}
                          </div>
                        </div>
                        {/* {(logdata.charterType === "2") && <>
                          <div className="col-md-12">
                            <div className="row form-group">
                              <div className="col-md-6">
                                <label >Start Date {astrix}  {toolTip('charterStart')}</label>
                                <input type="datetime-local" min={todayDate_DatePicker()} name="charterStart" value={logdata.charterStart} className="form-control" onChange={handleChange} />
                                {errorData.charterStart && <p className="text-danger error-contract">{errorData.charterStart}</p>}
                              </div>
                              <div className="col-md-6">
                                <label >End Date {astrix}  {toolTip('charterEnd')}</label>
                                <input type="datetime-local" min={todayDate_DatePicker()} name="charterEnd" value={logdata.charterEnd} className="form-control" onChange={handleChange} />
                                {errorData.charterEnd && <p className="text-danger error-contract">{errorData.charterEnd}</p>}
                              </div>
                            </div>
                          </div>
                        </>} */}
                      </div>
                      <hr />
                    </div>
                    <div className="modal-padding">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h3 className="text-primary">Loading Details</h3>
                          <hr width="50%" />
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Loading Country  {astrix} {toolTip('loadingCountry')}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!errorData.loadingCountry ? '' : ' border-danger')} name="loadingCountry" value={logdata.loadingCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                <option value="" selected> Select country </option>
                                <CountryList />
                              </select>
                              {errorData.loadingCountry && <p className="text-danger error-contract">{errorData.loadingCountry}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Loading Port {astrix}  {toolTip('loadingPort')}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!errorData.loadingPort ? '' : ' border-danger')} name="loadingPort" value={logdata.loadingPort} onChange={handleChange}>
                                <option value="" selected> Select loading port </option>
                                {portLoading.map((portLoading) => {
                                  return (
                                    <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                  )
                                })}
                              </select>
                              {errorData.loadingPort && <p className="text-danger error-contract">{errorData.loadingPort}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label className="col-md-12">Loading Type {astrix}  {toolTip('loadingType')}</label>
                          <div className="row col-md-6 mt-2">
                            <div className="col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="berthLoading" checked={logdata.berthLoading} id="berthLoading" onChange={handleChecked} />
                                <label className="form-check-label" for="berthLoading">Berth Loading</label>
                              </div>
                            </div>
                            <div className="row col-md-6 ">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber1" value="1" onChange={handleChange} checked={logdata.berthLoadingNumber / 1 === 1 ? true : false} disabled={!logdata.berthLoading ? true : false} />
                                <label className="form-check-label" for="berthLoadingNumber1">1</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="berthLoadingNumber" id="berthLoadingNumber2" value="2" onChange={handleChange} checked={logdata.berthLoadingNumber / 1 === 2 ? true : false} disabled={!logdata.berthLoading ? true : false} />
                                <label className="form-check-label" for="berthLoadingNumber2">2</label>
                              </div>
                            </div>
                          </div>
                          <div className="row col-md-6 mt-2">
                            <div className="col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="anchorageLoading" checked={logdata.anchorageLoading} id="anchorageLoading" onChange={handleChecked} />
                                <label className="form-check-label" for="anchorageLoading">Anchorage Loading</label>
                              </div>
                            </div>
                            <div className="row col-md-6 ">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber1" value="1" onChange={handleChange} checked={logdata.anchorageLoadingNumber / 1 === 1 ? true : false} disabled={!logdata.anchorageLoading ? true : false} />
                                <label className="form-check-label" for="anchorageLoadingNumber1">1</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="anchorageLoadingNumber" id="anchorageLoadingNumber2" value="2" onChange={handleChange} checked={logdata.anchorageLoadingNumber / 1 === 2 ? true : false} disabled={!logdata.anchorageLoading ? true : false} />
                                <label className="form-check-label" for="anchorageLoadingNumber2">2</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 row mt-2">
                            <div className="col-md-12">
                              {errorData.loadingType && <p className="text-danger error-contract">{errorData.loadingType}</p>}
                            </div>
                            <div className="col-md-6">
                              {errorData.berthLoadingNumber && <p className="text-danger error-contract">{errorData.berthLoadingNumber}</p>}
                            </div>
                            <div className="col-md-6">
                              {errorData.anchorageLoadingNumber && <p className="text-danger error-contract">{errorData.anchorageLoadingNumber}</p>}
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <div className="row form-group">
                              <label className="col-md-12">Loading Rate (PDPR) {astrix}  {toolTip('loadingRate')}</label>
                              <div className="col-md-12">
                                <input type="number" name="loadingRate" placeholder="Enter Loading Rate" value={logdata.loadingRate} className={" form-control" + (!errorData.loadingRate ? '' : ' border-danger')} onChange={handleChange} />
                                {errorData.loadingRate && <p className="text-danger error-contract">{errorData.loadingRate}</p>}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h3 className="text-primary">Unloading Details</h3>
                          <hr width="50%" />
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Unloading Country {astrix}  {toolTip('unloadingCountry')}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!errorData.unloadingCountry ? '' : ' border-danger')} name="unloadingCountry" value={logdata.unloadingCountry} onChange={(event) => { handleChange(event); unloadPort(event); }}>
                                <option value="" selected> Select country </option>
                                <CountryList />
                              </select>
                              {errorData.unloadingCountry && <p className="text-danger error-contract">{errorData.unloadingCountry}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-md-12">Unloading Port {astrix}  {toolTip('unloadingPort')}</label>
                            <div className="col-md-12">
                              <select className={" form-control" + (!errorData.unloadingPort ? '' : ' border-danger')} name="unloadingPort" value={logdata.unloadingPort} onChange={handleChange}>
                                <option value="" selected>Select Unloading port</option>
                                {
                                  portUnloading.map((portUnloading) => {
                                    return (
                                      <option value={portUnloading.port_ckey} >{portUnloading.port_name}</option>
                                    )
                                  })
                                }
                              </select>
                              {errorData.unloadingPort && <p className="text-danger error-contract">{errorData.unloadingPort}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label className="col-md-12">Unloading Type {astrix}  {toolTip('unloadingType')}</label>
                          <div className="row col-md-6 mt-2">
                            <div className="col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="berthUnloading" checked={logdata.berthUnloading} id="berthUnloading" onChange={handleChecked} />
                                <label className="form-check-label" for="berthUnloading">Berth Unloading</label>
                              </div>
                            </div>
                            <div className="row col-md-6 ">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber1" value="1" onChange={handleChange} checked={logdata.berthUnloadingNumber / 1 === 1 ? true : false} disabled={!logdata.berthUnloading ? true : false} />
                                <label className="form-check-label" for="berthUnloadingNumber1">1</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="berthUnloadingNumber" id="berthUnloadingNumber2" value="2" onChange={handleChange} checked={logdata.berthUnloadingNumber / 1 === 2 ? true : false} disabled={!logdata.berthUnloading ? true : false} />
                                <label className="form-check-label" for="berthUnloadingNumber2">2</label>
                              </div>
                            </div>
                          </div>
                          <div className="row col-md-6 mt-2">
                            <div className="col-md-6">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="anchorageUnloading" checked={logdata.anchorageUnloading} id="anchorageUnloading" onChange={handleChecked} />
                                <label className="form-check-label" for="anchorageUnloading">Anchorage Unloading</label>
                              </div>
                            </div>
                            <div className="row col-md-6 ">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber1" value="1" onChange={handleChange} checked={logdata.anchorageUnloadingNumber / 1 === 1 ? true : false} disabled={!logdata.anchorageUnloading ? true : false} />
                                <label className="form-check-label" for="anchorageUnloadingNumber1">1</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="anchorageUnloadingNumber" id="anchorageUnloadingNumber2" value="2" onChange={handleChange} checked={logdata.anchorageUnloadingNumber / 1 === 2 ? true : false} disabled={!logdata.anchorageUnloading ? true : false} />
                                <label className="form-check-label" for="anchorageUnloadingNumber2">2</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 row mt-2">
                            <div className="col-md-12">
                              {errorData.unloadingType && <p className="text-danger error-contract">{errorData.unloadingType}</p>}
                            </div>
                            <div className="col-md-6">
                              {errorData.berthUnloadingNumber && <p className="text-danger error-contract">{errorData.berthUnloadingNumber}</p>}
                            </div>
                            <div className="col-md-6">
                              {errorData.anchorageUnloadingNumber && <p className="text-danger error-contract">{errorData.anchorageUnloadingNumber}</p>}
                            </div>
                          </div>

                          <div className="col-md-6 mt-3">
                            <div className="row form-group">
                              <label className="col-md-12">Unloading Rate (PDPR) {astrix}  {toolTip('unloadingRate')}</label>
                              <div className="col-md-12">
                                <input type="number" name="unloadingRate" placeholder="Enter Unloading Rate" value={logdata.unloadingRate} className={" form-control" + (!errorData.unloadingRate ? '' : ' border-danger')} onChange={handleChange} />
                                {errorData.unloadingRate && <p className="text-danger error-contract">{errorData.unloadingRate}</p>}
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>

                {/* <div className={"tab-content" + (activeClass === 4 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    {(activeClass === 4) && <ContainerComp
                      userTokenDetails={userTokenDetails}
                      contractNo={logdata.contractNo}
                      JSONData={containerDetails}
                      setContainerDetails={setContainerDetails} />}
                  </div>
                </div> */}

                <div className={"tab-content" + (activeClass === 4 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    <div className="modal-padding">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-md-12">Payment Mode {astrix}  {toolTip('paymentMode')}</label>
                            <div className="modal-padding row">
                              {
                                getPaymentOptionslist()
                              }
                              {errorData.paymentMode && <p className="text-danger text-center error-contract">{errorData.paymentMode}</p>}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* //---------------------------------------------- */}
                      {/* add bank Component */}
                      {addBankObj.modal && <AddBankComponent />}
                      {/* //---------------------------------------------- */}

                      <div className="row" id="Bank1">
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-md-8">Charterer's Bank  {astrix} {toolTip('chartererBank')}</label>
                            <div className="col-md-8">
                              <select className="form-control" name="chartererBank" id="chartererBank" value={logdata.chartererBank} onChange={handleChange}>
                                <option value="" selected>Select Bank</option>
                                {
                                  getchartererBankList()
                                }
                              </select>
                              {errorData.chartererBank && <p className="text-danger error-contract">{errorData.chartererBank}</p>}
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding">
                                <input type="button" className="btn btn-primary float-right btn-sm" name="addBank" value="Add Bank" onClick={() => setaddBankObj(true, { "email": logdata.chartererEmail ? logdata.chartererEmail : '' })} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" id="Bank1">
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-md-8">Ship Owner's Bank  {astrix} {toolTip('shipownerBank')}</label>
                            <div className="col-md-8">
                              <select className="form-control" name="shipownerBank" id="shipownerBank" value={logdata.shipownerBank} onChange={handleChange}>
                                <option value="" selected>Select Bank</option>
                                {
                                  getshipownerBankList()
                                }
                              </select>
                              {errorData.shipownerBank && <p className="text-danger error-contract">{errorData.shipownerBank}</p>}
                            </div>
                            <div className="col-md-4">
                              <div className="modal-padding">
                                <input type="button" className="btn btn-primary float-right btn-sm" name="addBank" value="Add Bank" onClick={() => setaddBankObj(true, { "email": logdata.shipOwnerEmail ? logdata.shipOwnerEmail : '' })} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"tab-content" + (activeClass === 5 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    {/* <h2>Contract TNC</h2> */}
                    {errorData.tnc && <p className="text-danger text-center error-contract">{errorData.tnc}</p>}
                    {(activeClass === 5) ? <TncForm
                      dataTnc={dataTnc}
                      setdataTnc={setdataTnc}
                      contMode={"edit"}
                      editCount={editCount}
                      setEditCount={setEditCount}
                      handleTNC={handleTNC}
                      tncOrder={tncOrder}
                      settncOrder={settncOrder} /> : ''}

                  </div>
                </div>

                <div className={"tab-content" + (activeClass === 6 ? " is-active" : "")}>
                  <div className="calc-inner-modal">
                    {miniPreview()}
                    <hr />
                    <div className="modal-padding">
                      <h3>Documents</h3>
                      {/* <div className="row col-md-12"> */}

                      {(contractLogi.info.logiContractType === 'brokerOfflineContract') ?
                        <div className="form-group">
                          <hr />
                          <div className="justify-content-center d-flex">
                            <label className="mb-0">
                              <span className="file-icon">
                                <i class="fas fa-file-contract text-primary"></i>
                              </span>
                              Contract Document
                              <span className="help-icon" flow="right" tooltip="">i </span>
                            </label>
                            <div className="ml-auto">
                              <div className="file-browse">
                                <button className="btn btn-primary btn-sm">Upload Document</button>
                                <input type="file" accept=".png,.jpg,.pdf" name="offlineContractDoc" onChange={handleFile} />
                              </div>
                            </div>
                          </div>
                          {(fileData.offlineContractDoc) ? <div className="filePath"><div className="file-name">{fileData.offlineContractDoc.name}</div></div> : ""}
                        </div> :
                        <div className="form-group">
                          <hr />
                          <div className="justify-content-center d-flex">
                            <label className="mb-0">
                              <span className="file-icon">
                                <i class="fas fa-file-contract text-primary"></i>
                              </span>
                              Vessel Fixture Recap Document
                              <span className="help-icon" flow="right" tooltip="">i </span>
                            </label>
                            <div className="ml-auto">
                              {logdata.off_data && logdata.off_data.fixtureDocData &&
                                <div className="file-browse mr-5">
                                  <button className="btn btn-primary btn-sm" onClick={() => showFixtureDoc()}>View Current Document</button>
                                </div>}
                            </div>
                            <div className="ml-auto">
                              <div className="file-browse">
                                <button className="btn btn-primary btn-sm">{logdata.off_data && logdata.off_data.fixtureDocData ? "Update Current" : "Upload"} Document</button>
                                <input type="file" accept=".png,.jpg,.pdf" name="vesselFixtureRecapDoc" onChange={handleFile} />
                              </div>
                            </div>
                          </div>
                          {(fileData.vesselFixtureRecapDoc) ? <div className="filePath"><div className="file-name">{fileData.vesselFixtureRecapDoc.name}</div></div> : ""}
                        </div>}

                      {/* </div> */}
                    </div>
                  </div>

                  {showPreviewModal &&
                    <FilePreview
                      userTokenDetails={userTokenDetails}
                      fileData={previewFileData}
                      showPreviewModal={showPreviewModal}
                      setshowPreviewModal={setshowPreviewModal} />}

                </div>

              </div>
            </div>
            <div className="modal-footer primary">

              {(logdata.charterStatus === 1 || logdata.shipownerStatus === 1) ? <>
                {(logdata.edit_request / 1 === 0) ?
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => raiseContractEditRequest()} >Request Edit</button>
                  : (logdata.edit_request / 1 === userId) ?
                    <button type="button" className="btn btn-danger btn-sm" onClick="" disabled>Edit Request Raised</button> :
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { openContractEdit(false) }}>Accept Edit Request</button>
                }
              </>
                : ""}
              {(((logdata.charterer_id === userId && logdata.charterStatus === 0 && logdata.shipownerStatus === 1) ||
                (logdata.ship_owner_id === userId && logdata.shipownerStatus === 0 && logdata.charterStatus === 1)) &&
                (aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist)) &&
                <button type="button" className="btn btn-danger btn-sm" onClick={() => aggrementOnline()} id="agreeid">Agree</button>
              }

              <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()} id="nextid">Next</button>

            </div>
          </div>
        </div>
      </div>}

      {
        <div className={"modal" + (activeClass === 7 ? " show" : "")} id="docAcl">
          <div className="modal-dialog modal-xl border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">{(contractLogi.info.logiContractType === 'brokerOfflineContract') ? "Add Offline Contract - Preview" : "Shipment Contract - Preview"}</h4>
                <button type="button" className="close" onClick={() => { handlePrev(activeClass) }}>×</button>
              </div>

              <div className="modal-body calc-modal modal-view">
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div className="calc-inner-modal">
                  <div className={"accordionWrapper p-0" + (viewAccordion === 0 ? " open" : " closed")}>
                    <span >Show/Hide</span><br />
                    <i className={"p-2 fa" + (viewAccordion === 0 ? " fa-minus-square-o fa-2x" : " fa-plus-square-o fa-2x")} aria-hidden="true" onClick={handleViewAccordion}></i>
                    <div className="accordionItem">
                      <h2 className="accordionItemHeading" >Contract info</h2>
                      <div className="accordionItemContent">
                        {(contractLogi.info.logiContractType === 'OnlineContract') && <div className="row">
                          <div className="col-md-6">
                            <div className="mr-auto pr-3 form-group">
                              <div className="chat-message">
                                <img src={avatarUrl(logdata.chartererAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {logdata.charterer ? logdata.charterer : '--'}</span><br />
                                </div>
                              </div>
                              <label className="col-md-12 pl-0 pt-2">Charterer</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mr-auto pr-3 form-group">
                              <div className="chat-message">
                                <img src={avatarUrl(logdata.shipOwnerAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {logdata.shipOwner ? logdata.shipOwner : '--'}</span><br />
                                </div>
                              </div>
                              <label className="col-md-12 pl-0 pt-2">Ship Owner</label>
                            </div>
                          </div>
                        </div>}
                        {(contractLogi.info.logiContractType === 'brokerOfflineContract') && <div className="row">
                          <div className="col-md-6">
                            <div className="mr-auto pr-3 form-group">
                              <div className="chat-message">
                                <img src={avatarUrl(profileData.user_avatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {logdata.chartererName ? logdata.chartererName : '--'}</span><br />
                                </div>
                              </div>
                              <label className="col-md-12 pl-0 pt-2">Charterer</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mr-auto pr-3 form-group">
                              <div className="chat-message">
                                <img src={avatarUrl(userAvatar)} alt="Avatar" width="32" height="32" />
                                <div className="chat-message-content clearfix pt-2">
                                  <span className="date-format">  {userEmail ? userEmail : '--'}</span><br />
                                </div>
                              </div>
                              <label className="col-md-12 pl-0 pt-2">Ship Owner</label>
                            </div>
                          </div>
                        </div>}
                        <div className="row">
                          <div className="col-md-4">
                            <div className="row form-group">
                              <label className="col-md-12">
                                {logdata.commodity ? logdata.commodity.toUpperCase() : "--"}
                              </label>
                              <div className="col-md-12"><span className="date-format">Commodity</span></div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label>{logdata.laycanStart ? formatDate_Application(logdata.laycanStart) : '--'}</label><br />
                            <span className="date-format">Laycan Start Date</span>
                          </div>
                          <div className="col-md-4">
                            <label>{logdata.laycanEnd ? formatDate_Application(logdata.laycanEnd) : '--'}</label><br />
                            <span className="date-format">Laycan End Date</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordionItem">
                      <h2 className="accordionItemHeading" > Price &amp; Rate</h2>
                      <div className="accordionItemContent">
                        <div className="d-flex divider">
                          <div className="modal-padding">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.quantity ? logdata.quantity : '--'}mt</label>
                                  <div className="col-md-12"> <span className="date-format">Quantity to be Shipped</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.shipCurrency ? (logdata.shipCurrency.split(':')[1]) : ""}{logdata.shipPrice ? logdata.shipPrice : '--'}</label>
                                  <div className="col-md-12"> <span className="date-format">Shipping Rate</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.shipCurrency ? (logdata.shipCurrency.split(':')[1]) : ""}{logdata.demmurageRate ? logdata.demmurageRate : '--'}</label>
                                  <div className="col-md-12"> <span className="date-format">Demurrage Rate</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.quantityLoadUnit ? logdata.quantityLoadUnit : '--'}</label>
                                  <div className="col-md-12"> <span className="date-format">MOL Type</span></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{(logdata.quantityIndex ? logdata.quantityIndex : "--") + " - " + (logdata.quantityIndexType ? logdata.quantityIndexType : '--')}</label>
                                  <div className="col-md-12"> <span className="date-format">Quantity vary index</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordionItem">
                      <h2 className="accordionItemHeading" >Shipment </h2>
                      <div className="accordionItemContent">
                        <div className="d-flex divider">
                          <div className="modal-padding">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.charterType === '1' ? 'Voyage Charter' : 'Time Charter'}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Charter Type</span>
                                  </div>
                                </div>
                              </div>
                              {(logdata.charterType === "2") && <><div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.charterStart ? logdata.charterStart : '--'}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Start Date</span>
                                  </div>
                                </div>
                              </div>
                                <div className="col-md-4">
                                  <div className="row form-group">
                                    <label className="col-md-12">{logdata.charterEnd ? logdata.charterEnd : '--'}</label>
                                    <div className="col-md-12">
                                      <span className="date-format">End Date</span>
                                    </div>
                                  </div>
                                </div></>}
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <>
                                      {logdata.loadingPort ? logdata.loadingPort : '--'}
                                      {logdata.loadingCountry ? <span className="shadow">
                                        <ReactCountryFlag
                                          countryCode={logdata.loadingCountry ? logdata.loadingCountry.split(':')[0] : ''}
                                          style={{ width: '25px', height: '25px' }} svg />
                                      </span> : ""}
                                    </>
                                  </label>
                                  <div className="col-md-12">
                                    <span className="date-format">Loading Port</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    <>
                                      {logdata.unloadingPort ? logdata.unloadingPort : '--'}
                                      {logdata.loadingCountry ? <span className="shadow">
                                        <ReactCountryFlag
                                          countryCode={logdata.unloadingCountry ? logdata.unloadingCountry.split(':')[0] : ''}
                                          style={{ width: '25px', height: '25px' }} svg />
                                      </span> : ""}
                                    </>
                                  </label>
                                  <div className="col-md-12">
                                    <span className="date-format">Unloading Port</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.berthLoadingNumber ? logdata.berthLoadingNumber : 0}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Berth Loading</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.anchorageLoadingNumber ? logdata.anchorageLoadingNumber : 0}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Anchorage Loading</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.berthUnloadingNumber ? logdata.berthUnloadingNumber : 0}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Berth Unloading</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{logdata.anchorageUnloadingNumber ? logdata.anchorageUnloadingNumber : 0}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Anchorage Unloading</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordionItem">
                      <h2 className="accordionItemHeading" >Bank </h2>
                      <div className="accordionItemContent">
                        <div className="d-flex divider">
                          <div className="modal-padding">
                            <div className="row" id="Bank1">
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">
                                    {(paymentModes && paymentModes.length) ? paymentModes.map((value) => {
                                      if (value.id === (logdata.paymentMode / 1)) {
                                        return value.payment_type_pretty
                                      }
                                    }) : ''}
                                  </label>
                                  <div className="col-md-12">
                                    <span className="date-format">Payment Mode</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{getSelectName('chartererBank', logdata.chartererBank)}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Buyer's Bank</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row form-group">
                                  <label className="col-md-12">{getSelectName('shipownerBank', logdata.shipownerBank)}</label>
                                  <div className="col-md-12">
                                    <span className="date-format">Supplier's Bank</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordionItem">
                      <h2 className="accordionItemHeading" > Terms and Conditions</h2>
                      <div className="accordionItemContent">
                        {(activeClass === 7) ? tncPreviewGenerator() : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer primary">
                {(contractLogi.info.logiContractType === 'OnlineContract') ?
                  <>

                    {/* footer buttons for online shipment contract */}
                    {/* <textarea className="mr-auto form-control col-md-5" cols="60" readOnly required>{logdata.comments}</textarea> */}
                    <button type="button" className="btn btn-info btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>

                    {(((logdata.charterer_id === userId && logdata.charterStatus === 0) ||
                      (logdata.ship_owner_id === userId && logdata.shipownerStatus === 0)) &&
                      (aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist)) && editCount === 0 &&
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => aggrementOnline()} id="agreeid">Agree</button>
                    }

                    {(logdata.charterStatus === 0 && logdata.shipownerStatus === 0) &&
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleSubmitOnline("U&A") }} >Update & Agree</button>}

                    {(logdata.charterStatus === 0 && logdata.shipownerStatus === 0 &&
                      (aclMap.shipment_access && aclMap.shipment_access.shipment_edit && aclMap.shipment_access.shipment_edit.isExist)) &&
                      <button type="button" className="btn btn-success btn-sm" onClick={() => handleSubmitOnline("")} id="updateid">Update</button>
                    }
                  </> :
                  <>
                    {/* footer buttons for offline shipment contract */}
                    <button type="button" className="btn btn-default btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Edit</button>
                    {(aclMap.shipment_access && aclMap.shipment_access.shipment_offCreate && aclMap.shipment_access.shipment_offCreate.isExist) &&
                      <button type="button" className="btn btn-info btn-sm" onClick={() => handleSubmitOffline()} id="nextid">
                        Submit
                      </button>}
                  </>}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// ReduxI
const mapStateToProps = state => {
  return {
    contractLogi: state.contractLogi,
    addBankObj: state.addBankObj
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractStateLogi: (flag, data) => { dispatch(setContractStateLogi({ modal: flag, info: data })) },
    setaddBankObj: (flag, data) => { dispatch(setaddBankObj({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractModal)
