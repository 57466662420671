const columns = [
  {
    name: 'Lender Name',
    selector: 'lendername',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Document Name',
    selector: 'document_name',
    sortable: true,
  },
  {
    name: 'Document Uploaded',
    selector: 'document_upload',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
  }
];

export default columns