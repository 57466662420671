export default function validate(values, commodity) {
  let errors = {};

  if (!values.shipIMO) {
    errors.shipIMO = 'Ship IMO is Mandatory ';
  }

  if (!values.charterType) {
    errors.charterType = 'Charter Type is Mandatory ';
  }

  if (values.charterType === 2) {
    if (!values.charterStart) {
      errors.charterStart = 'Charter Start Date is Mandatory ';
    }
    if (!values.charterEnd) {
      errors.charterEnd = 'Charter End Date is Mandatory ';
    }
  }

  if (!values.price) {
    errors.price = 'Price is Mandatory ';
  }
  if (!values.currency) {
    errors.currency = 'Currency is Mandatory ';
  }

  if (values.charterType === 1) {
    if (!values.loadingCountry) {
      errors.loadingCountry = 'Loading Country is Mandatory ';
    }
    if (!values.loadingPort) {
      errors.loadingPort = 'Loading Port is Mandatory ';
    }
    if (!values.unloadingCountry) {
      errors.unloadingCountry = 'Unloading Country is Mandatory ';
    }
    if (!values.unloadingPort) {
      errors.unloadingPort = 'Unloading Port is Mandatory ';
    }
  }

  console.log(errors);
  return errors;
}
