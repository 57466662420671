import React, { useEffect, useState } from 'react';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';
import { ToastContainer } from 'react-toastify';
import FilePreview from '../../utilComponents/previewFile'
import { Accordion, Card, Button } from 'react-bootstrap'
import { formatDateTime_Application } from '../../../utils/dateFormaters';
import avatarUrl from '../../../utils/makeAvatarUrl';
import BankReimburseForm from '../ttPaymentProcess/bankReimburseForm'
import { formatDate_Application } from '../../../utils/dateFormaters';
import AddUserInContract from '../../utilComponents/addUserInContract.js'


const DaDpPaymentProcess = ({ userTokenDetails, contractNo, contractData, dadpType }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // states and variables
  const [contractDPData, setcontractDPData] = useState({});
  const [bankProfileData, setbankProfileData] = useState({});
  const [data, setdata] = useState({
    'uploadDP': false,
    "dadpType": dadpType
  });
  // const [error, setError] = useState({});
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [docList, setDocList] = useState({})
  const [docArray, setDocArray] = useState([])
  const [toggle, settoggle] = useState(false)
  const [toggle1, settoggle1] = useState(false)

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  //---------------------------------------------------------------------------------------------------------------------

  // console.log("contractData==>", contractData)
  console.log("contractDPData==>", contractDPData)
  console.log("data==>", data)


  //---------------------------------------------------------------------------------------------------------------------
  // Useeffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getdpdetails', { "contract_number": contractNo }).then(async (result) => {
      console.log("data of api in getdpdetails-->", result)

      setcontractDPData(result.contractData)
      setbankProfileData(result.banksProfileData)
      setDocArray(result.documentsArray)
      setdata(data => ({
        ...data,
        dpDocStatus: result.contractData.process_status / 1 < 2 ? 2 : result.contractData.process_status / 1 > 4 ? 4 : result.contractData.process_status / 1
      }));

      if (result.contractData.process_status === null || result.contractData.process_status / 1 === 3) {
        call('POST', 'getDocList', { "cid": contractNo, "userId": userId, "userTypeId": userTypeId }).then((result) => {
          console.log("doc data in bank page-->", result)
          setDocList(result)
          setshowLoader(false)
        }).catch((e) => {
          console.log("Error while querying Doc:", e);
          setshowLoader(false)
        })
      } else {
        setshowLoader(false)
      }
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in getlcdetails ->", error)
    })
    //------------------------------------------------------------------
  }, [refresh]);
  //---------------------------------------------------------------------------------------------------------------------



  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleFile = (event) => {
    event.persist();
    setdata(data => ({ ...data, [event.target.name]: event.target.files[0] }));
  }

  const hanleDocChecks = (event) => {
    event.persist();
    let tempArray = data[event.target.name] ? [...data[event.target.name]] : []
    let selectedDoc = event.target.value / 1
    let index = tempArray.indexOf(selectedDoc)
    if (index !== -1) {
      tempArray = tempArray.slice(0, index)
    } else {
      tempArray.push(selectedDoc / 1)
    }
    setdata(data => ({ ...data, [event.target.name]: tempArray }));
  }


  function notifyUserManual(sendToUsers) {
    setshowLoader(true)

    let postObj = {
      contractNumber: contractNo,
      sendToUsers: sendToUsers,
      message: "From " + userName + " ," + data.comment
    }

    call('POST', 'manualnotifyuser', postObj).then(async (result) => {
      setshowLoader(false)
      setdata(data => ({ ...data, "comment": "" }));
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in manualnotifyuser ->", error)
      toastDisplay(error, "error")
    })
  }

  function getProcessStatus(status) {
    let text = ''
    switch (status) {
      case 0:
        text = "Currently No Status is Available , Operations Pending"
        break;
      case 1:
        text = (userId === contractData.exporter_id) ?
          "Documents are submitted to bank, Please wait for the Banker's response" :
          "Exporter Submitted Documents to his bank for Cover Letter Generation"
        break;
      case 2:
        text = "Document Verification is Pending"
        break;
      case 3:
        text = "Banker Found Conflicts in Documents"
        break;
      case 4:
        text = "Cover Letter is available, Waiting For Importer's Bank response"
        break;
      case 5:
        text = "Importer's Bank agreed with Cover Letter and Forwarded it to Importer for Acceptance"
        break;
      case 6:
        text = "Importer Accepted the Cover Letter"
        break;
      case 7:
        text = "Payment Initiated by Importer's Bank , Process Complete"
        break;
      default:
        text = "Currently No Status is Available , Operations Pending"
    }
    return text
  }


  function openDOCPreview(file) {

    let fileOBJ = {}
    if (file === "DPCover") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractDPData.letter_doc_id
      }
    } else if (file === "instructionLetter") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractDPData.seller_doc_id
      }
    } else if (file === "txnDoc") {
      fileOBJ = {
        "action": 'view',
        "tbl_doc_id": contractDPData.reimburse_data.txnDoc
      }
    }

    setfileData(fileOBJ)
    if (Object.keys(fileOBJ).length) {
      setshowPreviewModal(true)
    } else {
      toastDisplay("File details are missing", "warn")
    }
  }

  function getDocListingChecks() {
    let keyArrary = Object.keys(docList)
    let renderDoc = []

    let returnJSX = keyArrary.length ? keyArrary.map((key) => {

      if (docList[key].categoryData.length) {
        return (
          <>
            {docList[key].categoryData.map((values, index) => {
              if (!renderDoc.includes(values.tbl_doc_id)) {
                renderDoc.push(values.tbl_doc_id)
                return (<>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="linkedDocs" value={values.tbl_doc_id} id={"docCheckbox" + key + index} onChange={hanleDocChecks} />
                      <label className="form-check-label" htmlFor={"docCheckbox" + key + index}>
                        {values.doc_name + " (" + docList[key].categoryPrettyname + ")"}
                      </label>
                    </div>

                  </div>
                </>)
              }
            })}
          </>
        )
      }
    }) : ""
    return returnJSX
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.doc_name) ? values.doc_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          {/* <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button> */}

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Subimit handlers
  function initDPprocess(status) {
    setshowLoader(true)
    let postObj = { ...data, "contract_number": contractNo, "process_status": status }

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    call('POST', 'raisedprequest', formData).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in raisedprequest ->", error)
      toastDisplay(error, "error")
    })
  }

  function submitDPprocessData() {

    setshowLoader(true)

    let processStatus = (contractDPData.process_status / 1 < 3) ? (data.dpDocStatus / 1) :
      (contractDPData.process_status / 1 === 3 && data.agreeDPInit) ? 2 :
        (contractDPData.process_status / 1 === 4 && data.agreeLetterBBank) ? 5 :
          (contractDPData.process_status / 1 === 5 && data.agreeLetterBuyer) ? 6 :
            (contractDPData.process_status / 1)

    // if (dadpType === "DP" && contractDPData.process_status / 1 === 5) {
    //   if (data.buyertxnId && data.buyertxnAmount && data.buyertxnDate) {
    //     let temp = {
    //       ...data.reimburse_data,
    //       "buyertxnId": data.buyertxnId,
    //       "buyertxnAmount": data.buyertxnAmount,
    //       "buyertxnDate": data.buyertxnDate
    //     }
    //     data.reimburseData = JSON.stringify(temp)

    //   } else {
    //     setshowLoader(false)
    //     toastDisplay("Provide Payment Details", "warn")
    //     return ""
    //   }
    // }

    delete data.buyertxnId
    delete data.buyertxnAmount
    delete data.buyertxnDate

    let postObj = {
      ...data,
      "contract_id": contractDPData.contract_id,
      "contract_no": contractNo,
      "process_status": processStatus,
      "sendToUsers": (contractDPData.process_status / 1 < 3) ? (contractData.exporter_id + ',' + contractData.supplier_bank) :
        (contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank)
    }

    console.log("data in submit dpprocess===>", postObj)
    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    call('POST', 'updatedpprocess', formData).then((result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      //toastDisplay(result, "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in updatedpprocess ->", error)
      toastDisplay(error, "error")
    })

  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Html body
  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {(contractData.supplier_bank / 1 === 0 && contractData.buyer_bank / 1 === 0) ?
        <>
          <AddUserInContract
            userTokenDetails={userTokenDetails}
            addUserData={{ usertype: 8, name: "Bank" }}
            contractData={contractData}
            refresh={refresh}
            setrefresh={setrefresh}
          />
        </> :
        <div className="mt-5">


          {/* banker's profile display start */}
          <div className="col-md-12">
            <div className="row bg-shadow bg-white" style={{ "min-height": "200px" }} >
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="chat-message pl-2">
                    <img src={avatarUrl(bankProfileData.buyer_bank_avatar)} alt="Avatar" width="32" height="32" />
                    <div className="chat-message-content clearfix pt-2">
                      <span className="date-format">  {bankProfileData.buyer_bank_name ? bankProfileData.buyer_bank_name : '--'}</span><br />
                    </div>
                    <p className="date-format p-0 m-0">{bankProfileData.buyer_bank_addr ? bankProfileData.buyer_bank_addr : '--'}</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <label>{bankProfileData.buyer_ac_no ? bankProfileData.buyer_ac_no : '--'}</label>
                  <div className="date-format">Importer Bank Account No</div>
                </div>
              </div>
              <div className="col-md-6 border-left">
                <div className="col-md-12">
                  <div className="chat-message pl-2">
                    <img src={avatarUrl(bankProfileData.seller_bank_avatar)} alt="Avatar" width="32" height="32" />
                    <div className="chat-message-content clearfix pt-2">
                      <span className="date-format">  {bankProfileData.seller_bank_name ? bankProfileData.seller_bank_name : '--'}</span><br />
                    </div>
                    <p className="date-format p-0 m-0">{bankProfileData.seller_bank_addr ? bankProfileData.seller_bank_addr : '--'}</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <label>{bankProfileData.seller_ac_no ? bankProfileData.seller_ac_no : '--'}</label>
                  <div className="date-format">Exporter Bank Account No</div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* banker's profile display end */}

          {(contractDPData.process_status) &&
            <>
              <div className="col-md-12">
                <ul className="price-ul">
                  <li>
                    <div className="price-left">{contractDPData.created_at ? formatDateTime_Application(contractDPData.created_at) : '--'}</div>
                    <div className="price-right">Process Initiated at</div>
                  </li>
                  <li>
                    <div className="price-left"> {contractDPData.modified_at ? formatDateTime_Application(contractDPData.modified_at) : '--'}</div>
                    <div className="price-right">Process Updated at</div>
                  </li>
                </ul>
              </div>
              <hr />
            </>}




          <div className="col-md-12">

            {/* status div */}
            <div className="col-md-12 row mt-2">
              <h3 className={(contractDPData.process_status < 6) ? "text-warning" : "text-success"}>
                {!showLoader && getProcessStatus(contractDPData.process_status / 1)}
              </h3>

              {((contractDPData.sellerCoverLetter_file_hash && userId === contractData.exporter_id) ||
                (contractDPData.sellerCoverLetter_file_hash && userId === contractData.supplier_bank)) &&
                <div className="ml-5">
                  <button type="button" className="btn btn-sm btn-primary" onClick={() => openDOCPreview("instructionLetter")}>View Instruction Letter</button>
                </div>}

              {((contractDPData.dpCoverLetter_file_hash && userId !== contractData.importer_id) ||
                (contractDPData.dpCoverLetter_file_hash && userId === contractData.importer_id && contractDPData.process_status / 1 > 4)) &&
                <div className="ml-5">
                  <button type="button" className="btn btn-sm btn-primary" onClick={() => openDOCPreview("DPCover")}>View Cover Letter</button>
                </div>}

            </div>
            <hr />

            {(contractDPData.reimburse_data && Object.keys(contractDPData.reimburse_data).length > 0 && contractDPData.reimburse_data.txnId) &&
              <>
                <div className="col-md-12">
                  <h3 className="text-success">Payment Details</h3>
                  <ul className="price-ul">
                    <li>
                      <div className="price-left">{contractDPData.reimburse_data.txnId ? contractDPData.reimburse_data.txnId : '--'}</div>
                      <div className="price-right">Buyer Bank Transaction ID </div>
                    </li>
                    <li>
                      <div className="price-left"> {contractDPData.reimburse_data.txnAmount ? contractDPData.reimburse_data.txnAmount : '--'} </div>
                      <div className="price-right">Buyer Bank  Transaction Amount</div>
                    </li>
                    <li>
                      <div className="price-left"> {contractDPData.reimburse_data.txnDate ? contractDPData.reimburse_data.txnDate : '--'}</div>
                      <div className="price-right">Buyer Bank Transaction Date/Time</div>
                    </li>
                    <li>
                      <div className="price-left"> <button type="button" className="btn btn-sm btn-link" onClick={() => openDOCPreview("txnDoc")}>Open Document</button></div>
                      <div className="price-right">View Payment TXN Doc</div>
                    </li>
                  </ul>
                </div>
                <hr />
              </>}

            {((docArray.length > 0 && userId !== contractData.exporter_id && userId !== contractData.importer_id) ||
              (docArray.length > 0 && contractDPData.process_status / 1 !== 3 && userId === contractData.exporter_id) ||
              (docArray.length > 0 && contractDPData.process_status / 1 > 4 && userId === contractData.importer_id)) && <>
                <div className="col-md-12">

                  {/* documents div */}
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <div className="col-md-12 row">
                          <div className="col-md-11 row">
                            <h3 className="text-primary mt-2">Attached Documents</h3>
                          </div>
                          <div className="col-md-1">
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                              <span className="cursor-pointer ml-5" onClick={() => settoggle1(!toggle1)}>
                                {!toggle1 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                              </span>
                            </Accordion.Toggle>
                          </div>
                        </div>

                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="col-md-12">
                            <div className="table-responsive" style={{ "min-height": "150px" }}>
                              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                <thead>
                                  <tr>
                                    <th width="20">#</th>
                                    <th>Type of Document</th>
                                    <th width="200">Document Name</th>
                                    <th width="200">Uploaded By</th>
                                    <th width="150">Uploaded On</th>
                                    <th className="text-center" width="150">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(docArray && docArray.length > 0) && docPreviewGenerator(docArray)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                </div>
                {/* <hr /> */}
              </>}

            {/* seller div */}
            {(userId === contractData.exporter_id) &&
              <div className="col-md-12 mt-2">


                {((contractDPData.process_status / 1 < 1) || (contractDPData.process_status / 1 === 3)) &&
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <div className="col-md-12 row">
                          <div className="col-md-11 row">
                            <h3 className="text-primary mt-2">Bank Process Form</h3>
                          </div>
                          <div className="col-md-1">
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                              <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                              </span>
                            </Accordion.Toggle>
                          </div>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <>

                            <div className="col-md-12 row">

                              <div className="col-md-12 row mt-5 mb-2">
                                <div className="col-md-12 ">
                                  <h3>Select Documents to attach with Instruction Letter</h3>
                                </div>
                                <div className="col-md-12 row">
                                  {getDocListingChecks()}
                                </div>
                              </div>
                              <hr className="col-md-12 " />
                              <div className="col-md-12 row mt-3 mb-2">
                                <div className="col-md-12 ">
                                  <h3>Upload Instruction Letter</h3>
                                </div>
                                <div className="col-md-12 row">
                                  <div className="col-md-3 pull-left">
                                    <div className="file-browse col-md-12 mt-2">
                                      <button className="btn btn-primary btn-sm">Select File</button>
                                      <input type="file" accept=".png,.jpg,.pdf" name="sellerCoverDocument" onChange={(event) => {
                                        handleFile(event);
                                      }} />
                                    </div>
                                  </div>
                                  {data.sellerCoverDocument &&
                                    <>
                                      <div className="col-md-8">
                                        <div className="form-group col-md-12"><div className="file-name">{data.sellerCoverDocument.name}</div></div>
                                      </div>
                                    </>}
                                </div>
                              </div>
                              <hr className="col-md-12 " />
                              {(contractDPData.process_status / 1 < 1) &&
                                <div className="col-md-12 form-check mt-3 mb-5">
                                  <input className="form-check-input" type="checkbox" name="agreeDPInit" id="agreeDPInit" onChange={(event) => {
                                    event.persist();
                                    setdata(data => ({ ...data, [event.target.name]: event.target.checked }));
                                  }} checked={data.agreeDPInit} />
                                  <label className="form-check-label" for="agreeDPInit"> I hereby declare that ,I have availed all the documents neede for Cover letter generation through the
                        portal's document module and Now i request bank to Generate Cover letter.</label>
                                </div>}

                              {(data.agreeDPInit && contractDPData.process_status / 1 !== 3) &&
                                <button className="btn btn-sm btn-primary mt-2" type="button" onClick={() => initDPprocess(1)}>Submit Request</button>}
                            </div>

                            {(contractDPData.process_status / 1 === 3) &&
                              <div className="col-md-12 row">
                                <div className="col-md-12 form-check mt-1 mb-1">
                                  <input className="form-check-input" type="checkbox" name="agreeDPInit" id="agreeDPInit" onChange={(event) => {
                                    event.persist();
                                    setdata(data => ({ ...data, [event.target.name]: event.target.checked }));
                                  }} checked={data.agreeDPInit} />
                                  <label className="form-check-label" for="agreeDPInit"> I hereby declare that ,I have Updated all the conficting documents and request the banker to
                                reverify and proceed with the application</label>
                                </div>

                                {data.agreeDPInit &&
                                  <button className="btn btn-sm btn-primary mt-2" type="button" onClick={() => submitDPprocessData()}>Update Status</button>}
                              </div>}

                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>}

              </div>}

            {/* buyer div */}
            {(userId === contractData.importer_id) &&
              <div className="col-md-12 mt-2">
                {(contractDPData.process_status / 1 === 5) ? <>
                  <div className="col-md-12">
                    <h3 className="text-primary mt-2">Accept Cover Letter</h3>
                    <hr />
                  </div>

                  <div className="col-md-12">
                    <div className="form-check pl-0 mt-1 mb-1">
                      <input className="form-check-input" type="checkbox" name="agreeLetterBuyer" id="agreeLetterBuyer" onChange={(event) => {
                        event.persist();
                        setdata(data => ({ ...data, [event.target.name]: (event.target.checked ? 1 : 0) }));
                      }
                      } checked={data.agreeLetterBuyer} />
                      <label className="form-check-label" for="agreeLetterBuyer">
                        I accept/Agree on this Cover Letter and allow my bank to process the further operations.
                        </label>
                    </div>
                  </div>

                  {data.agreeLetterBuyer === 1 &&
                    <div className="col-md-6 mt-3">
                      <button type="button" className="btn btn-success btn-sm" onClick={() => submitDPprocessData()}>
                        Accept Cover Letter
                      </button>
                    </div>}
                </> : (contractDPData.process_status / 1 === 6 && dadpType === "DA" && Object.keys(contractDPData.reimburse_data).length === 0) ? <>
                  <hr />
                  <div className="col-md-12">
                    <h3 className="text-primary mt-2">Instruct Bank for Payment Initiation</h3>
                    <hr />
                  </div>

                  <div className="col-md-12">
                    <div className="form-check pl-0 mt-1 mb-1">
                      <input className="form-check-input" type="checkbox" name="agreeLetterBuyer" id="agreeLetterBuyer" onChange={(event) => {
                        event.persist();
                        setdata(data => ({ ...data, "reimburseData": JSON.stringify({ makepayment: "ok" }) }));
                      }
                      } checked={data.agreeLetterBuyer} />
                      <label className="form-check-label" for="agreeLetterBuyer">
                        I notify my bank that i have received the goods and now bank can release the payment.
                        </label>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <button type="button" className="btn btn-success btn-sm" onClick={() => submitDPprocessData()}>
                      Submit details
                      </button>
                  </div>
                </> : <></>}

              </div>}

            {/* seller bank */}
            {(userId === contractData.supplier_bank) &&
              <div className="col-md-12 mt-2">
                {(contractDPData.process_status / 1 > 0 && contractDPData.process_status / 1 < 5) &&
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <div className="col-md-12 row">
                          <div className="col-md-11 row">

                            <h3 className="text-primary mt-2">Cover Letter Form</h3>

                            {(contractDPData.process_status / 1 > 0) &&
                              <select className="form-control col-md-4 ml-5 mr-5" name="dpDocStatus" value={data.dpDocStatus} onChange={handleChange} disabled={contractDPData.process_status / 1 > 4 ? true : false}>
                                <option value="2" selected>Documnet Verification Pending</option>
                                <option value="3" >Conflicts in Documents</option>
                                <option value="4" >Documents Verified</option>
                              </select>}

                          </div>
                          <div className="col-md-1">
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                              <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                              </span>
                            </Accordion.Toggle>
                          </div>
                        </div>

                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="col-md-12 mt-3 row">
                            <div className="col-md-12 text-center">
                              <h3>{data.dpDocStatus / 1 === 2 ? "You need to verify all the documents to upload the Cover letter" :
                                data.dpDocStatus / 1 === 3 ? "Exporter Will be notifed about the Conflicts in Document" :
                                  data.dpDocStatus / 1 === 4 ? "Upload the Cover Letter" : "Select Doc Status"}</h3>
                            </div>

                            {data.dpDocStatus / 1 === 4 &&
                              <div className="col-md-12 row">

                                <div className="col-md-3 pull-left">
                                  <div className="file-browse col-md-12 mt-2">
                                    <button className="btn btn-primary btn-sm">Select Cover Letter File</button>
                                    <input type="file" accept=".png,.jpg,.pdf" name="dpCoverDocument" onChange={(event) => {
                                      handleFile(event);
                                      setdata(data => ({ ...data, "uploadDP": true }));
                                    }} />
                                  </div>
                                </div>

                                {data.dpCoverDocument &&
                                  <>
                                    <div className="col-md-6">
                                      <div className="form-group col-md-12"><div className="file-name">{data.dpCoverDocument.name}</div></div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="file-browse col-md-12 mt-2">
                                        <button className="btn btn-primary btn-sm" onClick={() => submitDPprocessData()}>Submit</button>
                                      </div>
                                    </div>
                                  </>}

                              </div>}

                            {data.dpDocStatus / 1 === 3 &&
                              <div className="col-md-12">
                                <button className="btn btn-sm btn-primary mt-2" type="button" onClick={() => submitDPprocessData()}>
                                  Submit Status
                            </button>
                              </div>}


                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>}

              </div>}

            {/* buyer bank */}
            {(userId === contractData.buyer_bank) &&
              <div className="col-md-12 mt-2">

                {(contractDPData.process_status / 1 === 4) && <>
                  <div className="col-md-12">
                    <h3 className="text-primary mt-2">Accept Cover Letter</h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="form-check pl-0 mt-1 mb-1">
                      <input className="form-check-input" type="checkbox" name="agreeLetterBBank" id="agreeLetterBBank" onChange={(event) => {
                        event.persist();
                        setdata(data => ({ ...data, [event.target.name]: (event.target.checked ? 1 : 0) }));
                      }
                      } checked={data.agreeLetterBBank} />
                      <label className="form-check-label" for="agreeLetterBBank"> I accept/Agree on this Cover Letter and Forwards it to Importer for Acceptance.</label>
                    </div>
                  </div>
                  {data.agreeLetterBBank &&
                    <div className="col-md-6 mt-3">
                      <button type="button" className="btn btn-success btn-sm" onClick={() => submitDPprocessData()}>Accept Cover Letter</button>
                    </div>}
                </>}

                {(contractDPData.process_status / 1 === 6 && contractDPData.letter_status_bbank === 1 && contractDPData.letter_status_buyer === 1 &&
                  (dadpType === "DP" || (dadpType === "DA" && contractDPData.reimburse_data && contractDPData.reimburse_data.makepayment))) && <>

                    <Accordion>
                      <Card>
                        <Card.Header>
                          <div className="col-md-12 row">
                            <div className="col-md-11 row">
                              <h3 className="text-primary mt-2">Provide Reimbursement Details</h3>
                            </div>
                            <div className="col-md-1">
                              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <span className="cursor-pointer ml-5" onClick={() => settoggle(!toggle)}>
                                  {!toggle ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                </span>
                              </Accordion.Toggle>
                            </div>
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <BankReimburseForm
                              userTokenDetails={userTokenDetails}
                              contractData={{
                                id: contractDPData.contract_id,
                                contractNo: contractNo,
                                exporter_id: contractData.importer_id,
                                importer_id: contractData.supplier_bank,
                                buyer_bank: contractData.buyer_bank,
                                supplier_bank: contractData.exporter_id,
                                paymentType: "DADP",
                                dadpType: dadpType
                              }}
                              setshowLoader={setshowLoader}
                              refresh={refresh}
                              setrefresh={setrefresh}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                  </>}


              </div>}

          </div>

        </div>}

      {/* notify module start */}
      {(contractDPData.process_status / 1 > 0) && <>
        <hr />
        <div className="col-md-12 mt-4 row">
          <div className="col-md-8">
            <h3 className="text-primary">Notify other party about update</h3>
          </div>
          <div className="col-md-4 row">
            <lable className="col-md-6"> Select party</lable>
            <select className="form-control col-md-6 mb-2" name="notifyParty" value={data.notifyParty} onChange={handleChange}>
              <option value="0" selected>-Select Party-</option>
              {(userId / 1 !== contractData.exporter_id / 1) && <option value={contractData.exporter_id} >Exporter</option>}
              {(userId / 1 !== contractData.importer_id / 1) && <option value={contractData.importer_id} >Importer</option>}
              {(userId / 1 !== contractData.supplier_bank / 1) && <option value={contractData.supplier_bank} >Exporter Bank</option>}
              {(userId / 1 !== contractData.buyer_bank / 1) && <option value={contractData.buyer_bank} >Importer Bank</option>}
            </select>
          </div>
          <div className="col-md-12">
            <textarea rows="2" maxlength="200" className="form-control col-md-12" placeholder="Enter Message" name="comment" onChange={handleChange} required></textarea>
            {(data.notifyParty) && <button type="button" className="btn btn-success btn-sm mt-2 mb-2" onClick={() => notifyUserManual(data.notifyParty)}>Send</button>}
          </div>
        </div>
      </>}
      {/* notify module ends */}

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}

    </>)
}


export default DaDpPaymentProcess