const columns = [
  {
    name: 'Invoice ID',
    selector: 'reference_no',
    sortable: true,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Exporter',
    selector: 'company_name',
    sortable: false,
  },
  {
    name: 'Invoice Amount',
    selector: 'contract_amount',
    sortable: false,
  },
  {
    name: 'Disbursed Amount',
    selector: 'amount',
    sortable: false,
    grow: 0
  },
  {
    name: 'Disbursed At',
    selector: 'disbursed_at',
    sortable: false,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
    grow: 0
  }
];



export default columns