

import React, { useEffect, useState } from 'react';
import call from '../../service';
import { formatDateTime_Application } from '../../utils/dateFormaters';


const BlockchainTrail = ({ userTokenDetails, contractNo }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [trailData, setTrailData] = useState([])
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    //User details from cookie
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'bctrail', { "contract_number": contractNo }).then(async (result) => {
      // console.log("api result in bctrail ->", result)
      setTrailData(result)
    }).catch((error) => {
      console.log("error occur in bctrail ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Specification Form Components
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="d-flex flex-column mt-2">
      {/* <div className="d-flex">
        <div className="">
          <h2>Blockchain Audit Trail</h2>
        </div>
      </div> */}
      {/* <div className="table-responsive"> */}
      <div className="bk_details">
        <ul>
          {(trailData && trailData.length > 0) ? trailData.map((data) => {
            return (
              <li className="col-md-12 bg-white">
                <div className="row">
                  <div className="col-md-6 p-0"><label> TXN_ID</label> <span className="swatch-green">{data.txn_id ? data.txn_id : "--"}</span> </div>
                  <div className="col-md-6 p-0"><label>Address</label> <span>{data.address ? data.address : "--"}</span> </div>
                  <div className="col-md-6 p-0"><label>Asset_address</label> <span>{data.asset_address ? data.asset_address : "--"}</span> </div>
                  <div className="col-md-6 p-0"><label>User Address </label> <span>{data.user_address ? data.user_address : "--"}</span> </div>
                </div>
                <div className="row design-row">
                  <div className="col-md-6 p-0"><label>Timestamp</label> <span>{data.timestamp ? formatDateTime_Application(data.timestamp) : "--"}</span> </div>
                  {/* <div className="col-md-6 p-0"><label>Type</label> <span>{data.metadata ? data.metadata : "--"}</span> </div> */}
                  <div className="col-md-6 p-0"><label>Message</label> <span>{data.message ? data.message : "--"}</span> </div>
                  {/* <div className="col-md-6 p-0"><label>Actions</label> <span>{data.action ? data.action : "--"}</span> </div> */}
                </div>
              </li>
            )
          }) : "No Data available"
          }
        </ul>
      </div>
      {/* </div> */}
    </div>
  </>)
}


export default BlockchainTrail
