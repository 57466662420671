import React, { useEffect } from 'react';
import Chart from "chart.js"


const ContractPieChart = ({ contractDetails }) => {
  useEffect(() => {
    var ctx = document.getElementById('chart-area').getContext('2d');
    window.myPie = new Chart(ctx, getConfig())
  })
  //---------------------------------------------------------------------------------------------------------------------

  function getConfig() {
    var config = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [contractDetails.complete, contractDetails.progress, contractDetails.pilot, contractDetails.reject, contractDetails.active, contractDetails.inactive, contractDetails.refund],
          backgroundColor: [
            'rgba(63, 195, 128, 0.9)',
            'rgba(248, 148, 6, 0.9)',
            'rgba(255, 193, 7, 0.9)',
            'rgba(255, 0, 0, 0.9)',
            'rgba(248, 148, 6, 0.9)',
            'rgba(255, 193, 7, 0.9)',
            'rgba(48, 135, 142,0.9)'
          ],
          label: 'Statistics'
        }],
        labels: [
          'Complete',
          'In Progress',
          'In Pilot',
          'Rejected',
          'Active',
          'Inactice',
          'Refund'
        ]
      },
      options: {
        responsive: true,
        aspectRatio: 3
      }
    };

    return config;
  }

  return (
    <canvas id="chart-area"></canvas>
  )
}

export default ContractPieChart;
