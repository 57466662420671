export default function validate(values, commodity) {
  let errors = {};


  if (!values.imoNo) {
    errors.imoNo = 'Ship IMO is Mandatory ';
  }

  if (!values.shipName) {
    errors.shipName = 'Ship Name is Mandatory ';
  }
  if (!values.shipType) {
    errors.shipType = 'Ship Type is Mandatory';
  }
  if (!values.manufactureDate) {
    errors.manufactureDate = 'Ship Manufacturing Date is Mandatory ';
  }
  if (!values.registryPort) {
    errors.registryPort = 'Ship Registry Port is Mandatory';
  }
  if (!values.shipStatus) {
    errors.shipStatus = 'Ship Status is Mandatory';
  }
  if (!commodity) {
    errors.commodity = 'Commodities, Which Ship can carry are Mandatory';
  }
  if (!values.shipCategory) {
    errors.shipCategory = 'Ship Category is Mandatory';
  }
  if (!values.lengthv) {
    errors.lengthv = 'Ship Length is Mandatory';
  }
  if (!values.breadthv) {
    errors.breadthv = 'Ship Breadth is Mandatory';
  }
  if (!values.depth) {
    errors.depth = 'Ship Depth is Mandatory';
  }
  if (!values.draft) {
    errors.draft = 'Ship Draft is Mandatory';
  }
  if (!values.deadWeight) {
    errors.deadWeight = 'Ship Dead Weight is Mandatory';
  }
  if (!values.grossTons) {
    errors.grossTons = 'Ship Gross ton is Mandatory';
  }
  if (!values.netTons) {
    errors.netTons = 'Ship Net ton is Mandatory';
  }
  if (!values.openFromCountry) {
    errors.openFromCountry = 'Open From Country is Mandatory';
  }


  console.log(errors);
  return errors;
}
