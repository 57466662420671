export default function validate(values, specdata) {
  let errors = {};

  if (!values.commdCategory) {
    errors.commdCategory = 'Commodity Category is Mandatory';
  }

  if (!values.commName) {
    errors.commName = 'Commodity Name is Mandatory';
  }

  if (!values.commodityImage) {
    errors.commodityImage = 'Commodity Image is Mandatory';
  }

  if (!Object.keys(specdata).length) {
    errors.specdata = 'Commodity Specification is Mandatory';
  }


  console.log(errors);
  return errors;
}
