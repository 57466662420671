import React, { useEffect, useState } from 'react';

const OtherDocs = ({ data, setData, file, handleFiles, docLimit }) => {

  const [buttoncount, setbuttoncount] = useState(0);
  const [values, setValues] = useState({});

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    let shallowCopy = JSON.stringify(data.otherDocMetaData ? data.otherDocMetaData : {})
    setValues(JSON.parse(shallowCopy))
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  function addElement() {
    setValues(values => ({
      ...values,
      [buttoncount]: {
        // "docType": "",
        "docName": "",
        "docMM": "",
        "docYY": "",
      }
    }));
    setbuttoncount(buttoncount + 1)
  }

  function removeElement(i) {
    let temp = { ...values }
    delete temp[i]
    setValues(temp)
  }


  const handleChange = (event) => {
    event.persist();
    let temp = { ...values }
    temp[event.target.name.split(":")[1]][event.target.name.split(":")[0]] = event.target.value
    setValues(temp)
    setData({ ...data, "otherDocMetaData": temp });
  };

  // console.log("file-->", file)
  function renderList() {

    let keyArray = Object.keys(values)
    let result = keyArray.map((key, value) => {
      return (<>
        <div className="col-md-12">
          <ul className="other-documents">
            <li>
              <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
            </li>
            <li>
              <input type="text" className="custum-control" placeholder="Doc Name" name={"docName:" + key} value={values[key]["docName"]} onChange={handleChange} />
            </li>
            {/* <li>
              <div className="document-name-top">Valid Upto </div>
              <div className="document-name-bottom">(If applicable )</div>
            </li>
            <li>
              <input type="text" className="custum-control year" name={"docMM:" + key} value={values[key]["docMM"]} placeholder="MM" maxlength="2" onChange={handleChange} />
              <input type="text" className="custum-control year" name={"docYY:" + key} value={values[key]["docYY"]} placeholder="YYYY" maxlength="4" onChange={handleChange} />
            </li> */}
            <li>
              <div className="file-browse">
                <button className="btn btn-primary btn-sm">Upload Document</button>
                <input type="file" accept=".png,.jpg,.pdf" name={values[key].docName} value={values[key]["docType:" + key]} onChange={handleFiles} />
              </div>
            </li>
            <li>
              <button type="button" class="delete-icon" onClick={() => removeElement(key)}><i class="fa fa-trash"></i></button>
            </li>
          </ul>
          {(file["docFile:" + key]) ? <div className="form-group pr-10 pb-1"><div className="file-name">{`${file["docFile:" + key].name}`}</div></div> : ""}
        </div>
        <hr />
      </>
      )
    })
    return result
  }

  // JSX return
  return (
    <>
      {renderList()}
      <div className="d-flex mt-2 pr-0"><button type="button" className="btn-primary btn btn-sm" onClick={() => addElement()} disabled={Object.keys(values).length < docLimit ? false : true}>Add Document +</button></div>
    </>
  );
};

export default OtherDocs;
