import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../partial/header';
import { setContractDetailLogi, setLaytimesheet, setViewTrack, setContractDocList, setInvoiceDetail } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import Laytime from '../laytimeSheets/laytime';
import Tracking from '../contractGrid/tracking';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import ContractDoc from '../contractDocument/contractDoc';
import ShipmentDetailAccord from '../contractDetails/shipmentDetailAccord'
import LaytimeSheetTrail from '../laytimeSheets/laytimeSheetTrail';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Invoice from '../invoice/invoice';
import { QRCode } from 'react-qrcode-logo';
import BillOfLading from './billOfLading.js'
import avatarUrl from '../../utils/makeAvatarUrl';
import ContainerComp from './containersComp.js';
import toastDisplay from '../../utils/toastNotification';
import DocUploadModal from '../contractDocument/docUploadModal';
import FilePreview from '../utilComponents/previewFile';
import DigitalSignDoc from '../contractDocument/digitalSignDoc2';
import moment from 'moment'


export const ContractDocList = ({ offerId, userTokenDetails }) => {

  const [data, setdata] = useState([])
  const [accorddata, setaccorddata] = useState({});
  const [showUploadModal, setshowUploadModal] = useState(false)
  const [refreshDoc, setrefreshDoc] = useState(1)
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [deleteDocData, setdeleteDocData] = useState({});
  const [showAclAuthModal, setAclAuthModal] = useState(false)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  useEffect(() => {
    call('POST', 'getMktPlaceContractDocs', { offerId }).then((result) => {
      setdata(result)
    }).catch((e) => {
    })
  }, [refreshDoc])

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function callPreviewFileComponent(values, action) {
    setfileData({
      id: values.id,
      file_hash: values.file_hash,
      file_name: values.file_name,
      file_type: values.type,
      category_name: values.type,
      doc_name: values.name,
      company_name: values.creater_name,
      created_at: values.created_at,
      "action": action
    })
    setshowPreviewModal(true)
  }

  function deleteDocument() {
    setdeleteConfirm(false)
    call('POST', 'delMktPlaceContractDocs', {
      "docId": deleteDocData.docId,
      userId
    }).then((result) => {
      toastDisplay(result, "success");
      setrefreshDoc(refreshDoc + 1);
    }).catch(err => {
      // console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }


  function docGridCreator(arrayObject, catID) {
    let docElements = arrayObject ? arrayObject.map((values, index) => {
      console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.type) ? values.type.split("_").join(" ") : 'NA'}</td>
        <td>{(values && values.name) ? values.name : 'NA'}</td>
        <td>{(values && values.creater_name) ? values.creater_name : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values.docSignArray && values.docSignArray.length) ?
            <pre><span className="text-success mr-1">{values.docSignArray.map((signV) => { return (<><i className="fa fa-certificate" /> {signV.sign}<br /></>) })}</span></pre>
            : ''}
        </td>
        <td className="row justify-content-center m-0">
          {<>
            <button title="View File" className="text-center btn btn-primary btn-sm m-1"
              onClick={
                () => callPreviewFileComponent(values, "view")}
            >
              <i class="fas fa-eye"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1"
              onClick={
                () => callPreviewFileComponent(values, "download")}
            >
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          </>}

          {/* {true && <>
            <div className="file-browse ">
              <button title="Update File" className="text-center btn btn-success btn-sm m-1">
                <i className="fa fa-upload" aria-hidden="true"></i>
              </button>
              <input type="file" accept=".png,.jpg,.pdf" name={"doctype" + values.id}
                onChange={(event) => updateDocument(event, values, catID)}
              />
            </div>
          </>} */}

          {<>
            <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1"
              onClick={
                () => { setfileData({ tbl_doc_id: values.id }); setAclAuthModal(true) }}
            >
              <i class="fas fa-file-signature"></i>
            </button>
          </>}

          {/* {(aclMap.contract_access && aclMap.contract_access.cont_docacl && aclMap.contract_access.cont_docacl.isExist) &&
            <button title="Define ACL" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => setAclModal({ state: true, details: { ...values, catergory_id: catID } })}>
              <i className="fa fa-key" aria-hidden="true"></i>
            </button>} */}

          {
            <button title="Remove File" className="text-center btn btn-danger btn-sm m-1"
              onClick={() => {
                if (values.created_by === userId) {
                  setdeleteDocData({ "docId": values.id });
                  setdeleteConfirm(true)
                }
                else {
                  toastDisplay("You dont have permission to delete this document", "error")
                }
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>}

        </td>
      </tr >)
    }) : ''
    return (docElements)
  }

  function bodyCreator() {
    console.log("here===>", data)
    let docNames = Object.keys(data)
    let bodyElements = (docNames && docNames.length) ? docNames.map((item, index) => {
      return (
        <>
          <div className={"accordionItem" + (accorddata[item] ? " open" : " closed")}>
            <h2 className="accordionItemHeading" onClick={() => handleAccordion(item)}>
              {item.split("_").join(" ")}
            </h2>
            <div className="accordionItemContent">
              <div className="table-responsive" style={{ "min-height": "150px" }}>
                <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th width="20">#</th>
                      <th>Type of Document</th>
                      <th width="200">Document Name</th>
                      <th width="150">Uploaded By</th>
                      <th width="150">Uploaded On</th>
                      <th width="150">Signed By</th>
                      <th className="text-center" width="300">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[item].length ? docGridCreator(data[item], item) : null}
                  </tbody>
                </table>
              </div>
              <hr />
              <div className="col-md-4 row">
                <button title="Upload Document" className="text-center btn btn-success btn-sm m-1 col-md-2"
                  onClick={() => {
                    setshowUploadModal({
                      modal: true, categoryName: item,
                      categoryid: item, uploadedDocArray: []
                    })
                  }}>
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </button>
                {/* <button title="Request File" className="text-center btn btn-primary btn-sm m-1 col-md-2" onClick={() => {
                  // setshowRequestModal({ modal: true, categoryName: dbDocData[key].categoryPrettyname, categoryid: key })
                }}>
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button> */}
              </div>
            </div>
          </div>
        </>
      )
    }) :
      <>
        <div className="col-md-12 text-center m-5 p-5 bg-secondary">
          <h1>
            Loading Documents
          </h1>
        </div>
      </>


    return bodyElements
  }

  return (
    <>
      <div className="d-flex flex-column mt-3">
        {bodyCreator()}
      </div>

      {showUploadModal.modal &&
        <DocUploadModal
          mktPlaceShipment={true}
          userTokenDetails={userTokenDetails}
          contractNo={offerId}
          showUploadModal={showUploadModal}
          setshowUploadModal={setshowUploadModal}
          refreshDoc={refreshDoc}
          setrefreshDoc={setrefreshDoc}
        />}

      {showPreviewModal &&
        <FilePreview
          mktPlaceShipment={true}
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}

      {showAclAuthModal &&
        <DigitalSignDoc
          mktPlaceShipment={true}
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refreshDoc}
          setrefreshDoc={setrefreshDoc} />}

      {deleteConfirm &&
        <div className={"modal" + (deleteConfirm ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
              </div>
              <div className="modal-body">
                Please Confirm Delete Operation
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteDocument()}>Delete</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

    </>
  )
}

const ContractDetailShipmentV2 = ({ userTokenDetails, offerData, resetData }) => {

  console.log("offerDataaaa", offerData);

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setdata] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [contractDoc, setcontractDoc] = useState({
    show: false,
    data: []
  })
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");
  const [partyDetails, setPartyDetails] = useState({ trader: {}, shipper: {} })

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (offerData.offers_received && offerData.offers_received.length) {
      setdata(
        offerData.offers_received.filter(item => {
          if (item.agreed_by_shipper && item.agreed_by_trader) {
            return item
          }
        })[0]
      )
    }
    else {
      setdata(offerData)
    }
  }, [offerData])

  useEffect(() => {
    if (data.booking_id) {
      call('POST', 'getPartyDetails', { bookingId: data.booking_id, offerId: data.id }).then((result) => {
        setPartyDetails(result)
      }).catch((e) => {
      })
    }

  }, [data])

  function resetDocData() {
    setcontractDoc({ show: false, data: [] })
  }

  // return JSX 
  return (
    <>
      <Header
        title={contractDoc.show ? "Contract Details Documents" : 'Contract Details'}
        userTokenDetails={userTokenDetails}
        showNavBack={true}
        onBackAction={contractDoc.show ? resetDocData : resetData}
      />

      {contractDoc.show ?
        <ContractDocList offerId={data.id} userTokenDetails={userTokenDetails} /> : null
      }

      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

      {!contractDoc.show && (
        <div className="d-flex">
          <ul className="contract-left">
            <li>
              <h2>{"Contract Summary"}</h2>
              <p>{formatDate_Application(data.updated_at)}</p>
            </li>
          </ul>
          <div className="ml-auto pr-3">
            <ul className="contract-top d-flex flex-md-row align-items-center">
              <li>
                <button type="button" className="btn btn-info btn-sm"
                  onClick={() => { setcontractDoc({ ...contractDoc, show: true }) }}
                >
                  List of Documents
                </button>
              </li>
            </ul>

          </div>
        </div>
      )}

      {!contractDoc.show && (
        <>
          <ul className="nav nav-tabs-custom btn" id="myTab" role="tablist">
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                setTab(0);
                setTabName("Summary");
              }}>Summary</a>
            </li>

            <li>
              <a className={"nav-link cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
                setTabName("Bill Of Lading");
              }}>B/L Process</a>
            </li>

          </ul>

          {tab === 0 &&
            <div className="tab-pane active show  col-md-12">
              <div className="col-md-12 row mt-2">
                <div className="col-md-10">
                  <div className="d-flex align-content-around flex-wrap mb-3 contract-details">
                    <div className="col-md-12 row mt-2">

                      <div className="col-md-3 ">
                        <div className="mr-auto pr-3 form-group">
                          <label className="col-md-12">Charterer</label>
                          <div className="chat-message pl-2">
                            <img src={avatarUrl(partyDetails.trader.user_avatar)} alt="Avatar" width="32" height="32" />
                            <div className="chat-message-content clearfix pt-2">
                              <span className="date-format text-primary">  {partyDetails.trader.company_name || "-"}</span><br />
                            </div>
                            <p className="date-format p-0 m-0">{partyDetails.trader.email_id || "-"}</p>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-3 ">
                        <div className="mr-auto pr-3 form-group">
                          <label className="col-md-12">Ship Owner</label>
                          <div className="chat-message pl-2">
                            <img src={avatarUrl(partyDetails.shipper.user_avatar)} alt="Avatar" width="32" height="32" />
                            <div className="chat-message-content clearfix pt-2">
                              <span className="date-format text-primary">  {partyDetails.shipper.company_name || "-"}</span><br />
                            </div>
                            <p className="date-format p-0 m-0">{partyDetails.shipper.email_id || "-"}</p>
                          </div>
                        </div>
                      </div>

                    </div>

                    {data.offer_details_obj ? (
                      <>
                        <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.mode || "-"}</h3>
                              <p>Mode</p>
                            </li>
                          </ul>
                        </div>

                        <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.mode_type || "-"}</h3>
                              <p>Mode Type</p>
                            </li>
                          </ul>
                        </div>

                        {/* <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.origin_country || "-"}</h3>
                              <p>Origin Country</p>
                            </li>
                          </ul>
                        </div> */}

                        <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.origin_port || "-"}</h3>
                              <p>Origin Port</p>
                            </li>
                          </ul>
                        </div>

                        {/* <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.destination_country || "-"}</h3>
                              <p>Destination Country</p>
                            </li>
                          </ul>
                        </div> */}

                        <div className="card-panel">
                          <ul>
                            <li>
                              <h3>{data.offer_details_obj.destination_port || "-"}</h3>
                              <p>Destination Port</p>
                            </li>
                          </ul>
                        </div>

                        {data.offer_details_obj.container_type && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.container_type}</h3>
                                <p>Container Type</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.container_no && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.container_no}</h3>
                                <p>Container No</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.cargo_type && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.cargo_type}</h3>
                                <p>Cargo Type</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.start_date && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{moment(data.offer_details_obj.start_date).format("YYYY-MM-DD")}</h3>
                                <p>Start Date</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.end_date && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{moment(data.offer_details_obj.end_date).format("YYYY-MM-DD")}</h3>
                                <p>End Date</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.load_volume && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.load_volume}</h3>
                                <p>Load Volume (cubic meter)</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.load_weight && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.load_weight}</h3>
                                <p>Load Weight (Kg)</p>
                              </li>
                            </ul>
                          </div>
                        )}

                        {data.offer_details_obj.vessel_type && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.vessel_type}</h3>
                                <p>Vessel Type</p>
                              </li>
                            </ul>
                          </div>
                        )}
                        {data.offer_details_obj.commodity_type && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.commodity_type}</h3>
                                <p>Commodity Type</p>
                              </li>
                            </ul>
                          </div>
                        )}
                        {data.offer_details_obj.flexibility_days && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.flexibility_days}</h3>
                                <p>Flexibility Days</p>
                              </li>
                            </ul>
                          </div>
                        )}
                        {data.offer_details_obj.price && (
                          <div className="card-panel">
                            <ul>
                              <li>
                                <h3>{data.offer_details_obj.price}</h3>
                                <p>Price ($)</p>
                              </li>
                            </ul>
                          </div>
                        )}
                      </>
                    ) : null}

                  </div>
                </div>
              </div>
            </div>
          }

          {tab === 1 &&
            <div className="tab-pane active show col-md-12">
              <BillOfLading
                userTokenDetails={userTokenDetails}
                contractNo={null}
                shipContractNo={data.id}
                mktPlaceShipment={true}
                partyDetails={partyDetails}
              />
            </div>}
        </>
      )}

    </>
  )


  //---------------------------------------------------------------------------------------------------------------------

}

export default ContractDetailShipmentV2
