import React, { useEffect, useState } from 'react';
import call from '../../service';
import Header from '../partial/header';
import { ToastContainer } from 'react-toastify';
import { setContractDocList } from '../../store/actions/action';
import { connect } from 'react-redux';
import { formatDate_Application } from '../../utils/dateFormaters';
import toastDisplay from '../../utils/toastNotification';
import DocAcl from './docAcl';
import FilePreview from '../utilComponents/previewFile'
import DocUploadModal from './docUploadModal'
import DocRequestModal from './docRequestModal'
import DigitalSignDoc from './digitalSignDoc2'
import CategoryACLModal from './categoryACLModal'


const ContractDoc = ({ userTokenDetails, planData, contractDocList, setContractDocList, clientType, dispatch }) => {
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [dataKeYArray, setdataKeYArray] = useState([]);
  const [dbDocData, setdbDocData] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [aclModal, setAclModal] = useState({
    state: false,
    details: {}
  })
  const [accorddata, setaccorddata] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false)
  const [showUploadModal, setshowUploadModal] = useState(false)
  const [showAclAuthModal, setAclAuthModal] = useState(false)
  const [showRequestModal, setshowRequestModal] = useState(false)
  const [fileData, setfileData] = useState({})
  const [refreshDoc, setrefreshDoc] = useState(0)
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [deleteDocData, setdeleteDocData] = useState({});
  const [categoryAClmodal, setcategoryAClmodal] = useState(false);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getDocList', { "cid": contractDocList.contractId, "userId": userId, "userTypeId": userTypeId }).then((result) => {
      console.log("doc data in doc details page-->", result)
      setdbDocData(result);
      setshowLoader(false)

      let keyArray = Object.keys(result).length ? Object.keys(result) : []
      let tempAccord = {}
      for (let i = 0; i < keyArray.length; i++) {
        tempAccord[keyArray[i]] = (accorddata[keyArray[i]]) ? accorddata[keyArray[i]] : (i === 0 ? true : false)
      }
      setaccorddata(tempAccord)
      setdataKeYArray(keyArray)
    }).catch((e) => {
      console.log("Error while querying Doc:", e);
      setshowLoader(false)
    })
    //------------------------------------------------------------------
  }, [refreshDoc]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleAccordion = (accordion) => {
    setaccorddata(accorddata => ({ ...accorddata, [accordion]: !accorddata[accordion] }));
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.tbl_doc_id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call")
    setfileData({ ...values })
    setAclAuthModal(true);
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  const updateDocument = (event, data, catID) => {
    event.persist();
    setshowLoader(true)

    let user
    if (userTypeId / 1 === 3 || (userTypeId / 1 === 14 && clientType.type === 'buyer')) {
      user = 'buyer'
    } else if (userTypeId / 1 === 4 || (userTypeId / 1 === 14 && clientType.type === 'seller')) {
      user = 'seller'
    }

    let payloadUpload = {
      uploadingUser: userId,
      userTypeName: user,
      id: data.id ? data.id : '',
      tbl_doc_id: data.tbl_doc_id ? data.tbl_doc_id : '',
      contract_id: contractDocList.contractId,
      linked_to: data.linked_to,
      isUpdateQuery: data.doc_status === 1 ? true : false,
      prevFileHash: data.file_hash,
      categoryId: catID,
      filePayload: {
        docNo: data.doc_status === 1 ? data.doc_no : '',
        docName: data.doc_status === 1 ? data.doc_name : '',
        genDocLabel: (data.doc_status === 1 && data.doc_type === 1) ? data.gen_doc_label : event.target.name,
        docType: data.doc_type ? data.doc_type : '',
        validUpto: data.doc_status === 1 ? data.valid_upto : ''
      }
    }

    payloadUpload = JSON.stringify(payloadUpload);
    let formData = new FormData();
    formData.append('payloadUpload', payloadUpload);
    formData.append('doc', event.target.files[0]);

    call('POST', 'updateDoc', formData).then((result) => {
      if (result) {
        toastDisplay("File Uploaded", "success");
        setrefreshDoc(refreshDoc + 1);
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })
  }

  function deleteDocument() {

    setdeleteConfirm(false)
    setshowLoader(true)

    call('POST', 'deleteDoc', { "id": deleteDocData.doc_rel_id, "tblDocDetailId": deleteDocData.doc_id }).then((result) => {
      if (result) {
        toastDisplay("File Deleted", "success");
        setrefreshDoc(refreshDoc + 1);
        setshowLoader(false)
      }
    }).catch(err => {
      console.log("conn:", err)
      toastDisplay(err.message, "error");
      setshowLoader(false)
    })


  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body JSX creator
  function docGridCreator(arrayObject, catID) {
    let docElements = arrayObject ? arrayObject.filter(value => value.doc_type !== 21).map((values, index) => {
      console.log("value in doc render=>", values)
      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : 'NA'}</td>
        <td>{(values && values.doc_name) ? values.doc_name : 'NA'}</td>
        <td>{(values && values.uploaded_by) ? values.uploaded_by : 'NA'}</td>
        <td>{(values && values.uploaded_on) ? formatDate_Application(values.uploaded_on) : 'NA'}</td>
        <td>
          {(values && values.docSignArray.length) ? <pre><span className="text-success mr-1">{values.docSignArray.map((signV) => { return (<><i className="fa fa-certificate" /> {signV.sign}<br /></>) })}</span></pre> : ''}
          {/* { (values && values.doc_status === 1) ? <span className="text-success mr-1"><i className="fa fa-check-circle" /> Uploaded</span> :
              (values && values.doc_status === 0) ? <span className="text-warning mr-1"><i className="fa fa-hourglass-start" /> Pending</span> :
                (values && values.doc_status === 2) ? <span className="text-warning mr-1"><i className="fa fa-hourglass-start" /> Requested</span> : ''} */}
        </td>
        <td className="row justify-content-center m-0">

          {(values && values.actions && (values.actions.view.includes(userId) || (catID / 1 === 2) || (userTypeId === 8))) && <>
            <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "view")}>
              <i class="fas fa-eye"></i>
            </button>
            <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
              () => callPreviewFileComponent(values, "download")}>
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          </>}

          {(values && values.actions && values.actions.upload.includes(userId)) && <>
            <div className="file-browse ">
              <button title="Update File" className="text-center btn btn-success btn-sm m-1">
                <i className="fa fa-upload" aria-hidden="true"></i>
              </button>
              <input type="file" accept=".png,.jpg,.pdf" name={"doctype" + values.doc_type} onChange={(event) => updateDocument(event, values, catID)} />
            </div>
          </>}

          {(values && values.actions && values.actions.authenticate.includes(userId) && (planData.digitalSign && planData.digitalSign.enable)) && <>
            <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => callAclAuthComponent(values)}>
              <i class="fas fa-file-signature"></i>
            </button>
          </>}

          {(aclMap.contract_access && aclMap.contract_access.cont_docacl && aclMap.contract_access.cont_docacl.isExist) &&
            <button title="Define ACL" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => setAclModal({ state: true, details: { ...values, catergory_id: catID } })}>
              <i className="fa fa-key" aria-hidden="true"></i>
            </button>}

          {(values && values.actions && values.actions.remove.includes(userId)) &&
            <button title="Remove File" className="text-center btn btn-danger btn-sm m-1" onClick={() => {
              setdeleteDocData({ "doc_rel_id": values.id, "doc_id": values.tbl_doc_id });
              setdeleteConfirm(true)
            }} >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>}

        </td>
      </tr >)
    }) : ''
    return (docElements)
  }


  function bodyCreator() {
    console.log("here===>", dataKeYArray)
    let bodyElements = (dataKeYArray && dataKeYArray.length) ? dataKeYArray.map((key, index) => {
      return (
        <>
          <div className={"accordionItem" + (accorddata[key] ? " open" : " closed")}>
            <h2 className="accordionItemHeading" onClick={() => handleAccordion(key)}> {dbDocData[key].categoryPrettyname}</h2>
            <div className="accordionItemContent">
              <div className="table-responsive" style={{ "min-height": "150px" }}>
                <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th width="20">#</th>
                      <th>Type of Document</th>
                      <th width="200">Document Name</th>
                      <th width="150">Uploaded By</th>
                      <th width="150">Uploaded On</th>
                      <th width="150">Signed By</th>
                      <th className="text-center" width="300">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dbDocData[key].categoryData ? docGridCreator(dbDocData[key].categoryData, key) : ''}
                  </tbody>
                </table>
              </div>
              <hr />
              <div className="col-md-4 row">
                <button title="Upload Document" className="text-center btn btn-success btn-sm m-1 col-md-2" onClick={() => {
                  setshowUploadModal({ modal: true, categoryName: dbDocData[key].categoryPrettyname, categoryid: key, uploadedDocArray: dbDocData[key].uploadedDocArray })
                }}>
                  <i className="fa fa-upload" aria-hidden="true"></i>
                </button>
                <button title="Request File" className="text-center btn btn-primary btn-sm m-1 col-md-2" onClick={() => {
                  setshowRequestModal({ modal: true, categoryName: dbDocData[key].categoryPrettyname, categoryid: key })
                }}>
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )
    }) :
      (showLoader === false) ?
        <>
          <div className="col-md-12 text-center m-5 p-5 bg-secondary">
            <h1>You Do not have permission to view/access documents, Please contact your trader to provide you access for the respective application/trade</h1>
          </div>
        </> : ""


    return bodyElements
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX return

  return (
    <><ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <Header
        title={'Contract Details Documents'}
        userTokenDetails={userTokenDetails} />
      <div className="d-flex flex-column mt-3">
        {(aclMap.contract_access && aclMap.contract_access.cont_docacl && aclMap.contract_access.cont_docacl.isExist) && <div className="col-md-12 text-right">
          <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => setcategoryAClmodal(true)}>Update Category ACL</button>
        </div>}

        {bodyCreator()}

      </div>

      {aclModal.state &&
        <DocAcl
          userTokenDetails={userTokenDetails}
          contractNo={contractDocList.contractId}
          setAclModal={setAclModal}
          aclModal={aclModal}
          refreshDoc={refreshDoc}
          setrefreshDoc={setrefreshDoc} />}

      {showPreviewModal &&
        <FilePreview
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal} />}

      {showUploadModal.modal &&
        <DocUploadModal
          userTokenDetails={userTokenDetails}
          contractNo={contractDocList.contractId}
          showUploadModal={showUploadModal}
          setshowUploadModal={setshowUploadModal}
          refreshDoc={refreshDoc}
          setrefreshDoc={setrefreshDoc} />}

      {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal}
          refreshDoc={refreshDoc}
          setrefreshDoc={setrefreshDoc} />}

      {showRequestModal &&
        <DocRequestModal
          userTokenDetails={userTokenDetails}
          contractNo={contractDocList.contractId}
          showRequestModal={showRequestModal}
          setshowRequestModal={setshowRequestModal} />}

      {categoryAClmodal && <CategoryACLModal
        userTokenDetails={userTokenDetails}
        contractNo={contractDocList.contractId}
        categoryAClmodal={categoryAClmodal}
        setcategoryAClmodal={setcategoryAClmodal}
      />}


      {deleteConfirm &&
        <div className={"modal" + (deleteConfirm ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Confirmation</h4>
                <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
              </div>
              <div className="modal-body">
                Please Confirm Delete Operation
              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteDocument()}>Delete</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// redux
const mapStateToProps = state => {
  return {
    contractDocList: state.contractDocList,
    clientType: state.clientType,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDocList: (id) => { dispatch(setContractDocList({ cId: id, modal: false })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractDoc)
