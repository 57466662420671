export const SET_CONTRACT_DETAILS = 'SET_CONTRACT_DETAILS'
export const SET_SUPPLIER = 'SET_SUPPLIER'
export const SET_USER_VIEW = 'SET_USER_VIEW'
export const SET_CREATE_PROFILE_VIEW = 'SET_CREATE_PROFILE_VIEW'
export const SET_CONTRACT = 'SET_CONTRACT'
export const SET_CONTRACT_LOGI = 'SET_CONTRACT_LOGI'
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
export const SET_CREDIT = 'SET_CREDIT'
export const SET_CREDIT_DOCUMENTS = 'SET_CREDIT_DOCUMENTS';
export const SET_CONTRACT_DOC_LIST = 'SET_CONTRACT_DOC_LIST'
export const SET_PAYMENT = 'SET_PAYMENT'
export const SET_ADD_COMMODITIES = 'SET_ADD_COMMODITIES'
export const SET_ADD_SHIP = 'SET_ADD_SHIP'
export const SET_BOOK_SHIP = 'SET_BOOK_SHIP'
export const SET_VIEW_SHIP = 'SET_VIEW_SHIP'
export const SET_TRACK_VIEW = 'SET_TRACK_VIEW'
export const SET_CLIENT_TYPE = 'SET_CLIENT_TYPE'
export const SET_CONTRACT_AGREEMENT = 'SET_CONTRACT_AGREEMENT'
export const SET_INVOICE_DETAILS = 'SET_INVOICE_DETAILS'
export const SET_LAYTIMESHEET = 'SET_LAYTIMESHEET'
export const SET_CONTRACT_DETAIL_LOGI = 'SET_CONTRACT_DETAIL_LOGI'
export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA'
export const SET_ALERT = 'SET_ALERT'
export const SET_ADDBANK_OBJ = 'SET_ADDBANK_OBJ'
export const SET_DISBURSED = 'SET_DISBURSED'
export const SET_FINANCE_DETAILS = 'SET_FINANCE_DETAILS'
export const SET_ADD_DIRECTORY = 'SET_ADD_DIRECTORY'
export const SET_RAISE_QUOTATION = 'SET_RAISE_QUOTATION'
export const SET_OFF_CONTRACT = 'SET_OFF_CONTRACT'
export const NAV_TOGGLE_STATE = 'NAV_TOGGLE_STATE'
export const SET_CREDITLINE_DATA_BANK = 'SET_CREDITLINE_DATA_BANK'
export const SET_CHANNEL_PARTNER_ACCOUNT_LIST = 'SET_CHANNEL_PARTNER_ACCOUNT_LIST'
export const SET_CHANNEL_PARTNER_DISBURSED_INVOICE = 'SET_CHANNEL_PARTNER_DISBURSED_INVOICE'
export const SET_CHANNEL_PARTNER_RAISED_INVOICE = 'SET_CHANNEL_PARTNER_RAISED_INVOICE'
export const SET_CREDITLINE = 'SET_CREDITLINE'

export function setContractDetails(data) {
  return {
    type: SET_CONTRACT_DETAILS,
    data
  }
}

export function setSupplierInfo(info) {
  return {
    type: SET_SUPPLIER,
    info
  }
}

export function setShowViewProfileModal(info) {
  return {
    type: SET_USER_VIEW,
    info
  }
}

export function setInvoiceDetail(info) {
  return {
    type: SET_INVOICE_DETAILS,
    info
  }
}

export function setViewTrack(info) {
  return {
    type: SET_TRACK_VIEW,
    info
  }
}

export function setShowCreateProfileAdminModal(info) {
  return {
    type: SET_CREATE_PROFILE_VIEW,
    info
  }
}

export function setshowModalAddComm(info) {
  return {
    type: SET_ADD_COMMODITIES,
    info
  }
}

export function setAddShip(info) {
  return {
    type: SET_ADD_SHIP,
    info
  }
}

export function setBookShip(info) {
  return {
    type: SET_BOOK_SHIP,
    info
  }
}

export function setViewShip(info) {
  return {
    type: SET_VIEW_SHIP,
    info
  }
}

export function setContractState(info) {
  return {
    type: SET_CONTRACT,
    info
  }
}

export function setContractStateLogi(info) {
  return {
    type: SET_CONTRACT_LOGI,
    info
  }
}

export function setproductDetails(info) {
  return {
    type: SET_PRODUCT_DETAILS,
    info
  }
}

export function setcreditLineDetails(info) {
  return {
    type: SET_CREDIT,
    info
  }
}

export function setCreditDocuments(info) {
  return {
    type: SET_CREDIT_DOCUMENTS,
    info
  }
}

export function setContractDetailLogi(info) {
  return {
    type: SET_CONTRACT_DETAIL_LOGI,
    info
  }
}

export function setContractAgreement(info) {
  return {
    type: SET_CONTRACT_AGREEMENT,
    info
  }
}

export function setContractDocList(info) {
  return {
    type: SET_CONTRACT_DOC_LIST,
    info
  }
}

export function setPayment(state) {
  return {
    type: SET_PAYMENT,
    state
  }
}

export function setClientType(info) {
  return {
    type: SET_CLIENT_TYPE,
    info
  }
}

export function setLaytimesheet(info) {
  return {
    type: SET_LAYTIMESHEET,
    info
  }
}

export function setRegistrationData(info) {
  return {
    type: SET_REGISTRATION_DATA,
    info
  }
}

export function setAlert(info) {
  return {
    type: SET_ALERT,
    info
  }
}


export function setaddBankObj(info) {
  return {
    type: SET_ADDBANK_OBJ,
    info
  }
}

export function setDisbursed(info) {
  return {
    type: SET_DISBURSED,
    info
  }
}

export function setFinanceDetails(info) {
  return {
    type: SET_FINANCE_DETAILS,
    info
  }
}

export function setAddDirectory(info) {
  return {
    type: SET_ADD_DIRECTORY,
    info
  }
}

export function setRaiseQuotation(info) {
  return {
    type: SET_RAISE_QUOTATION,
    info
  }
}

export function setoffContract(info) {
  return {
    type: SET_OFF_CONTRACT,
    info
  }
}

export function setNavToggleState(info) {
  return {
    type: NAV_TOGGLE_STATE,
    info
  }
}

export function setCreditlineDataBank(info) {
  return {
    type: SET_CREDITLINE_DATA_BANK,
    info
  }
}

export function setChannelPartnerAccountList(info) {
  return {
    type: SET_CHANNEL_PARTNER_ACCOUNT_LIST,
    info
  }
}

export function setChannelPartnerDisbursedInvoice(info) {
  return {
    type: SET_CHANNEL_PARTNER_DISBURSED_INVOICE,
    info
  }
}

export function setChannelPartnerRaisedInvoice(info) {
  return {
    type: SET_CHANNEL_PARTNER_RAISED_INVOICE,
    info
  }
}

export function setCreditline(info) {
  return {
    type: SET_CREDITLINE,
    info
  }
}