import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import { setDisbursed, setFinanceDetails } from '../../store/actions/action';
import { getUserDataFromCookie, setCookieInAPP } from '../../utils/cookieHelper';
import { decryptData, encryptData } from '../../utils/myFunctions';
import CreateFinancePages from './financeApply/createFinancePages';

const FinanceDetailView = ({ setFinanceDetails, financeDetails }) => {

  const [loading, setLoading] = useState(false);
  const [buyerUserId, setBuyerUserId] = useState(null)

  let userTokenDetails = getUserDataFromCookie()
  useEffect(() => {
    window.onload = async function () {
      setLoading(true)
      //Format invoice_ref_no::tradefin_user_id::tradefin_type_id::user_email::otp
      var url_params = window.location.href.split("params=")[1]
      url_params = decryptData(url_params).split("::")
      let objectAPI = {
        "currentPage": 1,
        "resultPerPage": 1,
        "userId": url_params[1],
        "type_id": url_params[2],
        "gridOnly": true,
        "search": url_params[0]
      }
      call('POST', 'getLoans', objectAPI).then((result) => {
        // console.log('running getLoans api-->', result);
        setLoading(false);
        if (result.data && result.data.length) {
          setBuyerUserId(result.data[0]["buyer_id"])
          if (result.data[0].seller_status / 1 === 2 && result.data[0].lender_status / 1 === 2) {
            setFinanceDetails(true, result.data[0].type / 1, 2, { action: "viewFinance", FinanceID: result.data[0].id, sellerStatus: result.data[0].seller_status / 1, lenderStatus: result.data[0].lender_status / 1 })
          }
          else {
            setFinanceDetails(true, result.data[0].type / 1, 1, { action: "viewFinance", FinanceID: result.data[0].id, sellerStatus: result.data[0].seller_status / 1, lenderStatus: result.data[0].lender_status / 1 })
          }
        }
      }).catch((e) => {
        // console.log('error in getLoans', e);
        setLoading(false)
      })
    }
  }, [])

  return (
    <>
      {loading === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {financeDetails.status && buyerUserId && userTokenDetails ?
        <CreateFinancePages userTokenDetails={userTokenDetails} viewOnly={true} buyerUserId={buyerUserId}
        /> : null}
    </>
  )
}

const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceDetailView)