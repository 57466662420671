import React, { useEffect, useState } from 'react';
import { setClientType, setNavToggleState } from '../../store/actions/action';
import { connect } from 'react-redux';
import LiveNotif from '../notifications/liveNotifications'
import { setTraderType, getTraderType } from '../../utils/cookieHelper';
import { setNavToggleStateLS, getNavToggleState } from '../../utils/cookieHelper';
import call from '../../service';
import { TabComponent } from '../CommonComponent/TabComponent';

export const traderTypeIds = [14, 3, 4]
export const shipperTypeIds = [5, 10]
export const inspectionTypeIds = [6]
export const freightForwarderIds = [10] //brokers

const Sidebar = ({ userTokenDetails, clientType, setClientType, state, navToggleState, setNavToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [trader, setTrader] = useState(null);
  const [creditLineData, setCreditLineData] = useState([]);
  const [usersDetail, setUsersDetail] = useState([]);
  const [subUserDetail, setSubUserDetail] = useState({});
  const [financeType, setFinanceType] = useState(null);
  const [channelPartnerUser, setChannelPartnerUser] = useState([]);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;

  console.log("userrrrrrrrrrrrrrrrrrrrrrrrrrr", userTypeId);
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  // const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    setNavToggleState(getNavToggleState())

    let tdr = getTraderType();
    if (tdr) {
      setTrader(tdr);
      setClientType(tdr);
    } else {
      setTraderType("buyer");
      setTrader("buyer");
      setClientType("buyer");
    }

    call('POST', 'getCreditLineByBank', { userId: userId }).then((result) => {
      console.log('credit line by getCreditLineByBank--->', result);
      setCreditLineData(result);
    });

    call('POST', 'getUserDetail', { type: 'techType', userId: userId }).then((result) => {
      console.log('get result from getUsersDetail function ==>', result);
      setUsersDetail(result[0]);
    });

    call('POST', 'getSubUserName', { "subUserId": subUserId }).then((result) => {
      console.log('result in getSubUserName => ', result);
      setSubUserDetail(result);
    })

    if (userTypeId === 19) {
      call('POST', 'getFinanceType', { 'userId': userId }).then((result) => {
        console.log('result in getFinanceType', result);
        setFinanceType(result);

      }).catch((e) => {
        console.log('error in getFinanceType', e);
      });

      call('POST', 'getUserNetworkChannelPartner', { 'userId': userId }).then((result) => {
        console.log('result in getUserNetworkChannelPartner', result);
        setChannelPartnerUser(result);

      }).catch((e) => {
        console.log('error in getUserNetworkChannelPartner', e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  //---------------------------------------------------------------------------------------------------------------------

  function switchTrader(traderType) {
    setTraderType(traderType);
    setTrader(traderType);
    setClientType(traderType);
  }

  function handleNavBarToggle() {

    let currentNavState = getNavToggleState() / 1
    let newNavState = currentNavState ? 0 : 1

    setNavToggleStateLS(newNavState)
    setNavToggleState(newNavState)
  }


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    // <nav className="col-md-2 overflow-auto d-none d-md-block sidebar fixed-top">
    <nav className={"col-md-2 d-md-block sidebar " + (navToggleState.status ? " expanded-left" : "")} id="app-nav-div">

      <div className="top-sticky">
        {/* logo start */}
        <div className="logo">
          <div className="expanded-menu cursor-pointer" id="app-nav-toggle" onClick={() => handleNavBarToggle()}><i class="fa fa-bars" aria-hidden="true"></i></div>

          <a href="/dashboard">
            <img height="60" className="default_logo" src="assets/images/logos/logo_1.png" />
            <img height="60" className="mini_logo" src="assets/images/logos/logo_mini.png" />
          </a>
          {/* <div>
            <h2>{usersDetail && (usersDetail.tech_type_id === 1) ? "Trade-Tech" :
              (usersDetail.tech_type_id === 2) ? "Fin-Tech" :
                (usersDetail.tech_type_id === 3) ? "Logi-Tech" :
                  (usersDetail.tech_type_id === 4) ? "Channel Partner" : "Trade-Tech"}</h2>
          </div> */}
        </div>
        {/* logo end */}
      </div>

      <div className="menu-overflow">

        {/* importer/exporter switch only for trader start */}
        {userTypeId === 14 && <div className="d-flex buyer-border">
          <a onClick={() => switchTrader('buyer')} className={"btn buyer-info " + (trader === 'buyer' ? 'active' : '')}>As Importer</a>
          <a onClick={() => switchTrader('seller')} className={"btn buyer-info " + (trader === 'seller' ? 'active' : '')}>As Exporter</a>
        </div>}
        {/* importer/exporter switch only for trader end */}
        <hr />

        <div className="sidebar-sticky " id="scrollbarStyle-custom">
          <button className="close-menu">×</button>

          {/* nav panel start */}
          <div className="col-md-12">
            <div className="row">

              {/* <div className="col-md-12 overflow-auto" style={{ "maxHeight": "325px" }}> */}
              <ul className="nav flex-column">

                {(aclMap.dashboard_access || userTypeId === 20) &&
                  <a className="nav-link " href='/dashboard'>
                    <li className={"nav-item " + ((state === 'dashboard') ? 'active' : '')} >
                      <span ><img src="assets/images/sidebar/dashboard.png" height="25px" className="pr-1" title="Dashboard" /></span>
                      <span>Dashboard</span>
                    </li>
                  </a>}

                {/* {shipperTypeIds.includes(userTypeId) &&
                  <a className="nav-link " href={'/rate-list'}>
                    <li className={"nav-item " + (state === 'rate-list' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/quoatation.png" height="25px" className="pr-1" title="My Shipment Quotations" /></span>
                      <span>Rate List</span>
                    </li>
                  </a>} */}

                {freightForwarderIds.includes(userTypeId) &&
                  <a className="nav-link " href={'/rate-management'}>
                    <li className={"nav-item " + (state === 'rate-management' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/quoatation.png" height="25px" className="pr-1" title="" /></span>
                      <span>Rate Management</span>
                    </li>
                  </a>}

                {(traderTypeIds.includes(userTypeId) || shipperTypeIds.includes(userTypeId) || inspectionTypeIds.includes(userTypeId)) &&
                  <a className="nav-link " href={'/my-quotations'}>
                    <li className={"nav-item " + (state === 'my-quotations' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/myQuoatation.png" height="25px" className="pr-1" title="" /></span>
                      <span>My Quotations</span>
                    </li>
                  </a>}


                {/* {(userTypeId === 5 || userTypeId === 10) &&
                  <a className="nav-link " href={'/marketplace-shipment-quotations'}>
                    <li className={"nav-item " + (state === 'marketplace-shipment-quotations' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/myQuoatation.png" height="25px" className="pr-1" title="My Shipment Quotations" /></span>
                      <span>Marketplace Shipment Quotations</span>
                    </li>
                  </a>} */}

                {
                  (aclMap.contract_access) &&
                  <a className="nav-link " href="/contract">
                    <li className={"nav-item " + ((state === 'contract') ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-1" title={userTypeId === 8 ? " Cases" : "Workorder Contracts"} /></span>
                      <span>{userTypeId === 8 ? "Cases" : "Workorder Contracts"}</span>
                    </li>
                  </a>
                }

                {
                  (traderTypeIds.includes(userTypeId) || shipperTypeIds.includes(userTypeId)) &&
                  <a className="nav-link " href={'/shipment'}>
                    <li className={"nav-item " + (state === 'shipment' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/shipment.png" height="25px" className="pr-1" title="DashbShipment Contractsoard" /></span>
                      <span>Shipment Contracts</span>
                    </li>
                  </a>
                }

                {/* {userTypeId / 1 === 13 &&
                  <a className="nav-link " href="/cases">
                    <li className={"nav-item " + ((state === 'cases') ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-1" title=" Cases" /></span>
                      <span>Cases</span>
                    </li>
                  </a>} */}

                {
                  (aclMap.insuredcontract_accss) &&
                  <a className="nav-link " href={'/contract'}>
                    <li className={"nav-item " + (state === 'insurercontract' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/contract.png" height="25px" className="pr-1" title="Insured Contracts" /></span>
                      <span>Insured Contracts</span>
                    </li>
                  </a>
                }

                {
                  (aclMap.insuredshipment_accss) &&
                  <a className="nav-link " href={'/shipment'}>
                    <li className={"nav-item " + (state === 'insurershipment' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/shipment.png" height="25px" className="pr-1" title="Insured Shipments" /></span>
                      <span>Insured Shipments</span>
                    </li>
                  </a>
                }

                {
                  (aclMap.banker_cont_access) &&
                  <a className="nav-link" href={'bankers'}>
                    <li className={"nav-item " + (state === 'bankers' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/payments.png" height="25px" className="pr-1" title="Contract" /></span>
                      <span>Contract</span>
                    </li>
                  </a>
                }

                {
                  (aclMap.oquotation_access) &&
                  <a className="nav-link " href={'/quotation'}>
                    <li className={"nav-item " + (state === 'quotation' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/quoatation.png" height="25px" className="pr-1" title="Open Shipment Quotations" /></span>
                      <span>Open Shipment Quotations</span>
                    </li>
                  </a>
                }

                {/* {(aclMap.mquotation_access) &&
                  <a className="nav-link " href={'/quotationlist'}>
                    <li className={"nav-item " + (state === 'quotationlist' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/myQuoatation.png" height="25px" className="pr-1" title="My Shipment Quotations" /></span>
                      <span>My Shipment Quotations</span>
                    </li>
                  </a>} */}

                {
                  (aclMap.ships_access) &&
                  <a className="nav-link " href={'/ships'}>
                    <li className={"nav-item " + (state === 'ships' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/ship.png" height="25px" className="pr-1" title="Vessels" /></span>
                      <span>Vessels</span>
                    </li>
                  </a>
                }

                {/* {(aclMap.ships_access) && */}
                {
                  traderTypeIds.includes(userTypeId) && <a className="nav-link " href={'/marketplace'}>
                    <li className={"nav-item " + (state === 'marketplace' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/Marketplace_Logo.png" height="25px" className="pr-1" title="Marketplace" /></span>
                      <span>Marketplace</span>
                    </li>
                  </a>
                }
                {/* } */}

                {
                  (aclMap.payments_access) &&
                  <a className="nav-link" href={'/payment'}>
                    <li className={"nav-item " + (state === 'payment' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/payments.png" height="25px" className="pr-1" title="Payments" /></span>
                      <span>Payments</span>
                    </li>
                  </a>
                }

                {((aclMap.credit_line && userTypeId / 1 != 20) || userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14 || userTypeId / 1 === 19) &&
                  <a className="nav-link" href="/counterPart">
                    <li className={"nav-item " + (state === 'counterPart' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/counterpart.svg" height="25px" className="pr-1" title="Counter Part" /></span>
                      <span>Buyers Detail</span>
                    </li>
                  </a>
                }

                {(userTypeId / 1 == 19 || userTypeId / 1 == 8 || userTypeId / 1 == 1) &&
                  <a className="nav-link" href={'/lc-fast'}>
                    <li className={"nav-item " + (state === 'lc-fast' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-1" title="LC Fast" /></span>
                      <span>LC Fast</span>
                    </li>
                  </a>}

                {/* {(aclMap.credit_line || userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14) && */}
                {
                  (userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14 || userTypeId / 1 === 19) &&
                  <a className="nav-link" href="/creditLine">
                    <li className={"nav-item " + (state === 'creditlineview' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/creditlimit.png" height="25px" className="pr-1" title="Credit Line" /></span>
                      <span>Credit Line</span>
                    </li>
                  </a>
                }

                {((aclMap.finance_access && userTypeId / 1 != 20) || userTypeId == 1) &&
                  <a className="nav-link" href={'/finance'}>
                    <li className={"nav-item " + (state === 'finance' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-1" title="Finance" /></span>
                      <span>Finance</span>
                    </li>
                  </a>}

                {/* {userTypeId == 19 &&
                  <a className="nav-link" href={'/po'}>
                    <li className={"nav-item " + (state === 'po' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-1" title="Finance" /></span>
                      <span>Puchase Order (PO)</span>
                    </li>
                  </a>} */}

                {/* {(userTypeId == 5) &&
                  <a className="nav-link" href={'/finance'}>
                    <li className={"nav-item " + (state === 'finance' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/finance.png" height="25px" className="pr-1" title="Finance" /></span>
                      <span>Finance</span>
                    </li>
                  </a>} */}

                {
                  (userTypeId / 1 === 8 || userTypeId / 1 === 1) &&
                  <a className="nav-link" href={'/creditlineview'}>
                    <li className={"nav-item " + (state === 'creditlineview' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/finDetails.png" height="25px" className="pr-1" title="Credit Line" /></span>
                      <span>Finance User</span>
                    </li>
                  </a>
                }

                {(userTypeId / 1 === 19) &&
                  <a className="nav-link" href={'/financeCalculator'}>
                    <li className={"nav-item " + (state === 'financeCalculator' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/rate-calculator.png" height="25px" className="pr-1" /></span>
                      <span>Finance Calculator</span>
                    </li>
                  </a>
                }

                {(userTypeId / 1 === 8) &&
                  <a className="nav-link" href={'/interestRates'}>
                    <li className={"nav-item " + (state === 'interestRates' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/rate-calculator.png" height="25px" className="pr-1" /></span>
                      <span>Interest Rates</span>
                    </li>
                  </a>
                }

                {/* {(userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14 || userTypeId / 1 === 19) &&
                  <a className="nav-link" href={'/rateCalculator'}>
                    <li className={"nav-item " + (state === 'rateCalculator' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/rate-calculator.png" height="25px" className="pr-1" /></span>
                      <span>Rate-Calculator</span>
                    </li>
                  </a>
                } */}

                {/* {(aclMap.cont_directory || userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14) && */}
                {/* {(userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14) &&
                  <a className="nav-link" href={'/contractdirectory'}>
                    <li className={"nav-item " + (state === 'contdirectory' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/contractbook.png" height="25px" className="pr-1" title="Contracts Directory" /></span>
                      <span>Contracts Directory</span>
                    </li>
                  </a>} */}

                {/* {(aclMap.commodity_access) &&
                  <a className="nav-link" href="/commoditieslist">
                    <li className={"nav-item " + (state === 'commoditieslist' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/commodity.png" height="25px" className="pr-1" title="Manage Commodities" /></span>
                      <span>Manage Commodities</span>
                    </li>
                  </a>} */}

                {/* {(userTypeId == 1) &&
                  <a className="nav-link" href="/setting">
                    <li className={"nav-item " + (state === 'setting' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/settings.png" height="25px" className="pr-1" /></span>
                      <span>Manage Product</span>
                    </li>
                  </a>
                } */}

                {/* {(aclMap.mng_subusers) &&
      <a className="nav-link" href={userTypeId / 1 === 1 ? "/users" : "/network"}>
        <li className={"nav-item " + (state === 'admin' ? 'active' : '')}>
          <span><img src="assets/images/sidebar/users.png" height="25px" className="pr-1" title={userTypeId === 1 ? "Manage Users" : "Manage Network"} /></span>
          <span>{userTypeId === 1 ? "Manage Users" : "Manage Network"}</span>
        </li>
      </a>
    } */}

                {
                  (userTypeId === 1) &&
                  <a className="nav-link" href={'/channelUsers'}>
                    <li className={"nav-item " + (state === 'channelPartnerUser' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/products.png" height="25px" className="pr-1" /></span>
                      <span>Channel / Franchise Partner</span>
                    </li>
                  </a>
                }

                {
                  (userTypeId === 1) &&
                  <a className="nav-link" href={'/channelPartnerReferral'}>
                    <li className={"nav-item " + (state === 'channelPartnerReferral' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/products.png" height="25px" className="pr-1" /></span>
                      <span>Channel Partner Referrals</span>
                    </li>
                  </a>
                }

                {/* {
                  (userTypeId === 8) &&
                  <a className="nav-link" href={'/product'}>
                    <li className={"nav-item " + (state === 'product' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/products.png" height="25px" className="pr-1" /></span>
                      <span>Manage Product</span>
                    </li>
                  </a>
                } */}

                {/* {(userTypeId == 1) &&
                  <a className="nav-link" href="/product">
                    <li className={"nav-item " + (state === 'product' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/products.png" height="25px" className="pr-1" /></span>
                      <span>Manage Product</span>
                    </li>
                  </a>
                }

                {/* {(aclMap.mng_directory) &&
                  <a className="nav-link" href={'/userdirectory'}>
                    <li className={"nav-item " + (state === 'directory' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/directory.png" height="25px" className="pr-1" title="Personal Directory" /></span>
                      <span>Personal Directory</span>
                    </li>
                  </a>} */}

                {/* {[3, 4, 14].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/buyplans">
                    <li className={"nav-item " + (state === 'plans' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/plans.png" height="25px" className="pr-1" title="Plans" /></span>
                      <span>Plans</span>
                    </li>
                  </a>} */}

                {
                  (aclMap.plan_payment) &&
                  <a className="nav-link" href="/planpayments">
                    <li className={"nav-item " + (state === 'planPayments' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/planPayments.png" height="25px" className="pr-1" title="Plans Payments" /></span>
                      <span>Plans Payments</span>
                    </li>
                  </a>
                }

                {[19, 8].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/chatRoom">
                    <li className={"nav-item " + (state === 'chatRoom' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/chat.png" height="25px" className="pr-1" title="chatRoom" /></span>
                      <span>Chat Room</span>
                    </li>
                  </a>}

                {[1, 19, 8].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/reports">
                    <li className={"nav-item " + (state === 'reports' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/reports.png" height="25px" className="pr-1" title="Reports" /></span>
                      <span>Reports</span>
                    </li>
                  </a>
                }

                {
                  [20].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/accountlist">
                    <li className={"nav-item " + (state === 'accountlist' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/reports.png" height="25px" className="pr-1" title="Reports" /></span>
                      <span>Account List</span>
                    </li>
                  </a>
                }

                {
                  [20].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/raisedinvoice">
                    <li className={"nav-item " + (state === 'raisedinvoice' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/reports.png" height="25px" className="pr-1" title="Reports" /></span>
                      <span>Invoice</span>
                    </li>
                  </a>
                }

                {/* {[20].includes(userTypeId / 1) &&
                  <a className="nav-link" href="/disbursedinvoice">
                    <li className={"nav-item " + (state === 'disbursedinvoice' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/reports.png" height="25px" className="pr-1" title="Reports" /></span>
                      <span>Disbursed Invoice</span>
                    </li>
                  </a>} */}


                {
                  userTypeId === 19 && channelPartnerUser && channelPartnerUser.length > 0 &&
                  <a className="nav-link" href="/userdetails">
                    <li className={"nav-item " + (state === 'channelUser' ? 'active' : '')}>
                      <span><img src="assets/images/sidebar/manageUsers.png" height="25px" className="pr-1" title="Reports" /></span>
                      <span>User Journey</span>
                    </li>
                  </a>
                }

                <li className="nav-item">
                  <a className="nav-link dropdown-toggle" href="#" data-toggle="collapse" data-target="#demo">
                    <span><img src="assets/images/sidebar/settings.png" height="25px" className="pr-1" /></span>
                    <span>Settings</span></a>

                  <ul id="demo" class="collapse p-0">
                    {(userTypeId / 1 === 8) &&
                      <a className="nav-link" href={'/setting'}>
                        <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'setting' ? 'active' : '')}><span><img src="assets/images/sidebar/eligible.png" height="25px" className="pr-1" /></span>
                          <span>Eligibility Criteria for Credit Line</span></li>
                      </a>
                    }
                    <a className="nav-link" href={'/dashboard'}>
                      <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'setting' ? 'active' : '')}><span><img src="assets/images/sidebar/dashboard.png" height="25px" className="pr-1" /></span>
                        <span>{subUserDetail.subUserName}</span></li>
                    </a>

                    {[3, 4, 14].includes(userTypeId / 1) &&
                      <a className="nav-link" href={'/buyplans'}>
                        <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'plans' ? 'active' : '')}><span><img src="assets/images/sidebar/plans.png" height="25px" className="pr-1" /></span>
                          <span>Plans</span></li>
                      </a>
                    }

                    {(userTypeId / 1 != 8 && userTypeId / 1 != 20) &&
                      <a className="nav-link" href={userTypeId / 1 === 1 ? "/users" : "/network"}>
                        <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'admin' ? 'active' : '')}>
                          <span><img src="assets/images/sidebar/users.png" height="25px" className="pr-1" title={userTypeId === 1 ? "Manage Users" : "Manage Network"} /></span>
                          <span>{userTypeId === 1 ? "Manage Users" : "Manage Network"}</span>
                        </li>
                      </a>}

                    {(aclMap.commodity_access) &&
                      <a className="nav-link" href={'/commoditieslist'}>
                        <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'commoditieslist' ? 'active' : '')}><span><img src="assets/images/sidebar/commodity.png" height="25px" className="pr-1" /></span>
                          <span>Manage Commodities</span></li>
                      </a>
                    }

                    {(userTypeId / 1 === 3 || userTypeId / 1 === 4 || userTypeId / 1 === 14) &&
                      <a className="nav-link" href={'/contractdirectory'}>
                        <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'contdirectory' ? 'active' : '')}>
                          <span><img src="assets/images/sidebar/contractbook.png" height="25px" className="pr-1" title="Contracts Directory" /></span>
                          <span>Contracts Directory</span>
                        </li>
                      </a>}

                    <a className="nav-link" href={'/editprofile'}>
                      <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'setting' ? 'active' : '')}><span><img src="assets/images/sidebar/editprofile.png" height="25px" className="pr-1" /></span>
                        <span>Edit Profile</span></li>
                    </a>
                    <a className="nav-link" href={'/changepassword'}>
                      <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'setting' ? 'active' : '')}><span><img src="assets/images/sidebar/change-password.png" height="25px" className="pr-1" /></span>
                        <span>Change Password</span></li>
                    </a>
                    <a className="nav-link" href={'/logout'}>
                      <li style={{ marginLeft: "10px", marginTop: "15px" }} className={"nav-item " + (state === 'setting' ? 'active' : '')}><span><img src="assets/images/sidebar/logout.png" height="25px" className="pr-1" /></span>
                        <span>Logout</span></li>
                    </a>
                  </ul>

                </li>

              </ul >
            </div >
          </div >
          {/* nav panel end */}
        </div >
        {/* live notifications component */}
        < LiveNotif userTokenDetails={userTokenDetails} />
      </div >

    </nav >
  );
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setClientType: (type) => { dispatch(setClientType({ type: type })) },
    setNavToggleState: (status) => { dispatch(setNavToggleState({ status: status })) },

  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
