import React from 'react';

const SpecificationsPreview = ({ specification, specOrder }) => {

  console.log("specification in preview form-->", specification)

  //---------------------------------------------------------------------------------------------------------------------
  // use effects


  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Commodity Spec Preview Components
  function specshowBody(specification) {
    let keyArray = specOrder.length ? specOrder : Object.keys(specification).length ? Object.keys(specification) : []
    return (
      <div className="d-flex divider">
        {/* <div className="modal-padding"> */}
        <div className="col-md-12 row">
          {
            keyArray.map((key) => {
              return (
                <div className="col-md-4">
                  <div className="row form-group">
                    <label className="col-md-12">
                      {"Value: " + (specification[key].value ? specification[key].value : '--') + " | Reject: " + (specification[key].rejectionValue ? specification[key].rejectionValue : '--')}
                    </label>
                    <div className="col-md-12"><span className="date-format">{specification[key].name}</span></div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {/* </div> */}
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {specification ? specshowBody(specification) : "Some Error"}
    </>)
}



export default SpecificationsPreview
