import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import toolTipText from '../../utils/toolTips/masterContract.json'
import { connect } from 'react-redux';
import { setInvoiceDetail } from '../../store/actions/action';


const AddUserInContract = ({ userTokenDetails, addUserData, contractData, refresh, setrefresh, setInvoiceDetail, dispatch }) => {

  const [Data, setData] = useState({});
  const [errorData, seterrorData] = useState({});
  const [userMaster, setuserMaster] = useState([]);
  const [buyerBank, setBuyerBank] = useState([]);
  const [sellerBank, setSellerBank] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    if (addUserData.usertype / 1 === 6 || addUserData.usertype / 1 === 13) {
      call('POST', 'getUsersListing', { "searchUserType": addUserData.usertype, "searchKey": '', "network": "innetwork", "userAvatars": false }).then((result) => {
        console.log("result of api in getUsersListing-->", result)
        setuserMaster(result)
      }).catch((e) => {
        console.log('error in getUsersListing', e);
      })
    } else if (addUserData.usertype / 1 === 8) {
      // call('POST', 'getUserBanks', { "email": contractData.supplier_email }).then((result) => {
      //   // console.log('api result for getUserBanks supplierEmail', result);
      //   setSellerBank(result)
      // }).catch((e) => {
      //   console.log("Error while querying getUserBanks for seller:", e);
      // })

      // call('POST', 'getUserBanks', { "email": contractData.buyer_email }).then((result) => {
      //   //  console.log('api result for getUserBanks buyerEmail', result);
      //   setBuyerBank(result)
      // }).catch((e) => {
      //   console.log("Error while querying getUserBanks for buyer:", e);
      // })

      call('GET', 'getBanksListMaster', {}).then((result) => {
        console.log('api result for getBanksListMaster buyerEmail', result);
        setBuyerBank(result)
        setSellerBank(result)
      }).catch((e) => {
        console.log("Error while querying getUserBanks for buyer:", e);
      })
    }


    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    event.persist();
    setData({ ...Data, [event.target.name]: event.target.value });
    seterrorData({ ...errorData, [event.target.name]: "" });
  }

  const handleSubmit = () => {



    let showToStringArrray = contractData.exporter_id + "," +
      contractData.importer_id + ","

    if (addUserData.usertype / 1 === 6) {

      if (!Data.investigationAgencyImporter) {
        seterrorData({ ...errorData, "investigationAgencyImporter": "Field Missing" });
        return
      } else if (!Data.investigationAgencyExporter) {
        seterrorData({ ...errorData, "investigationAgencyExporter": "Field Missing" });
        return
      } else if (!Data.investigationAgencythird) {
        seterrorData({ ...errorData, "investigationAgencythird": "Field Missing" });
        return
      }

      showToStringArrray = showToStringArrray +
        Data.investigationAgencyImporter / 1 + "," +
        Data.investigationAgencyExporter / 1 + "," +
        Data.investigationAgencythird / 1
    } else if (addUserData.usertype / 1 === 13) {

      if (!Data.commodityInsurer) {
        seterrorData({ ...errorData, "commodityInsurer": "Field Missing" });
        return
      } else if (!Data.tradeInsurer) {
        seterrorData({ ...errorData, "tradeInsurer": "Field Missing" });
        return
      }

      showToStringArrray = showToStringArrray +
        Data.commodityInsurer / 1 + "," +
        Data.tradeInsurer / 1 + ","
    } else if (addUserData.usertype / 1 === 8) {

      if (!Data.supplierBank) {
        seterrorData({ ...errorData, "supplierBank": "Field Missing" });
        return
      } else if (!Data.buyerBank) {
        seterrorData({ ...errorData, "buyerBank": "Field Missing" });
        return
      }
      showToStringArrray = showToStringArrray +
        Data.supplierBank / 1 + "," +
        Data.buyerBank / 1
    }

    setshowLoader(true)

    var formData = new FormData();
    Object.keys(Data).forEach((key) => {
      formData.append(key, Data[key]);
    })

    formData.append("showToStringArrray", showToStringArrray);
    formData.append("user_type", userTypeId);
    formData.append("loggedUserID", userId);
    formData.append("importer_id", contractData.importer_id);
    formData.append("exporter_id", contractData.exporter_id);
    formData.append("contract_id", contractData.contractId ? contractData.contractId : contractData.contract_id);
    formData.append("contract_number", contractData.contractNo ? contractData.contractNo : contractData.contract_number);

    // console.log('Here --->>');
    call('POST', 'updateContract', formData).then((result) => {
      setrefresh(refresh + 1)
      setInvoiceDetail(false, { refresh: refresh + 1 })
      setshowLoader(false)
    }).catch(e => {
      console.log("Error while Updating contract:", e);
      setshowLoader(false)
      toastDisplay(e, "error")
    })
  }
  console.log("Data Updating contract:", Data);

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function getinvestigationAgencyList() {
    let investigationAgencyList = (userMaster && userMaster.length) ? userMaster.map((value) => {
      return (
        <option value={value.id} >{value.company_name}</option>
      )
    }) : ''
    return investigationAgencyList
  }

  function getinsuranceAgencyList() {
    let insurersList = (userMaster && userMaster.length) ? userMaster.map((value) => {
      return (<option value={value.id}>{value.company_name}</option>)
    }) : ''
    return insurersList
  }


  function getsupplierBankList() {
    let supplierBankList = sellerBank && sellerBank.length ? sellerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return supplierBankList
  }

  function getbuyerBankList() {
    let buyerBankList = buyerBank && buyerBank.length ? buyerBank.map((value, index) => {
      return (
        // <option value={value.bank_branch_id}>{value.company_name}</option>
        <option value={value.id}>{value.company_name}</option>
      )
    }) : ''
    return buyerBankList
  }


  return (<>
    {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

    <div className="accordionWrapper row pr-0 pt-0">
      <div className="container-fluid accordionItem open bg-white">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h3 className="green mt-3">{addUserData.name} is not added in Contract yet, Add Now</h3>
                <hr />
              </div>
              <div className="col-md-12">
                <div className="pr-4">

                  {(addUserData.usertype / 1 === 6) ?
                    <>
                      <div className="row">
                        <div className="col-md-12 mt-3 row">
                          <label className="col-md-6">Importer's Investigation Agency {astrix} {toolTip('investigationAgencyImporter')}</label>
                          <div className="col-md-5">
                            <select className={"form-control " + (!errorData.investigationAgencyImporter ? '' : ' border-danger')} name="investigationAgencyImporter" value={Data.investigationAgencyImporter}
                              onChange={handleChange} id="investigationAgencyImporter">
                              <option value="" selected>--Select Agency--</option>
                              <option value="0" >Not Available</option>
                              {
                                getinvestigationAgencyList()
                              }
                            </select>
                            {errorData.investigationAgencyImporter && <p className="text-danger error-contract">{errorData.investigationAgencyImporter}</p>}
                          </div>
                        </div>
                        <div className="col-md-12 mt-3 row">
                          <label className="col-md-6">Exporter's Investigation Agency {astrix} {toolTip('investigationAgencyExporter')}</label>
                          <div className="col-md-5">
                            <select className={"form-control " + (!errorData.investigationAgencyExporter ? '' : ' border-danger')} name="investigationAgencyExporter" value={Data.investigationAgencyExporter}
                              onChange={handleChange} id="investigationAgencyExporter">
                              <option value="" selected>--Select Agency--</option>
                              <option value="0" >Not Available</option>
                              {
                                getinvestigationAgencyList()
                              }
                            </select>
                            {errorData.investigationAgencyExporter && <p className="text-danger error-contract" >{errorData.investigationAgencyExporter}</p>}
                          </div>
                        </div>
                        <div className="col-md-12 mt-3 row">
                          <label className="col-md-6">Third Party Investigation Agency {astrix} {toolTip('investigationAgencythird')}</label>
                          <div className="col-md-5">
                            <select className={"form-control " + (!errorData.investigationAgencythird ? '' : ' border-danger')} name="investigationAgencythird" value={Data.investigationAgencythird}
                              onChange={handleChange} id="investigationAgencythird">
                              <option value="" selected>--Select Agency--</option>
                              <option value="0" >Not Available</option>
                              {
                                getinvestigationAgencyList()
                              }
                            </select>
                            {errorData.investigationAgencythird && <p className="text-danger error-contract">{errorData.investigationAgencythird}</p>}
                          </div>
                        </div>
                      </div>
                    </> :
                    (addUserData.usertype / 1 === 13) ?
                      <>
                        <div className="row">
                          <div className="col-md-12 mt-3 row">
                            <label className="col-md-6">Commodity Insurance by {astrix} {toolTip('commodityInsurer')}</label>
                            <div className="col-md-5">
                              <select className={"form-control " + (!errorData.commodityInsurer ? '' : ' border-danger')} name="commodityInsurer" value={Data.commodityInsurer}
                                onChange={handleChange} id="commodityInsurer">
                                <option value="" selected>--Select Insurer--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinsuranceAgencyList()
                                }
                              </select>
                              {errorData.commodityInsurer && <p className="text-danger error-contract">{errorData.commodityInsurer}</p>}
                            </div>
                          </div>
                          <div className="col-md-12 mt-3 row">
                            <label className="col-md-6">Trade Insurance by {astrix} {toolTip('tradeInsurer')}</label>
                            <div className="col-md-5">
                              <select className={"form-control " + (!errorData.tradeInsurer ? '' : ' border-danger')} name="tradeInsurer" value={Data.tradeInsurer}
                                onChange={handleChange} id="tradeInsurer">
                                <option value="" selected>--Select Insurer--</option>
                                <option value="0" >Not Available</option>
                                {
                                  getinsuranceAgencyList()
                                }
                              </select>
                              {errorData.tradeInsurer && <p className="text-danger error-contract">{errorData.tradeInsurer}</p>}
                            </div>
                          </div>
                        </div>
                      </> :
                      (addUserData.usertype / 1 === 8) ?
                        <>
                          <div className="row" id="Bank1">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-md-4"> Importer's Bank  {astrix} {toolTip("buyerBank")}</label>
                                <div className="col-md-7">
                                  <select className="form-control" name="buyerBank" value={Data.buyerBank} onChange={handleChange} id="buyerBank">
                                    <option selected value="">--Select Bank--</option>
                                    <option value="0" >Not Available</option>
                                    {
                                      getbuyerBankList()
                                    }
                                  </select>
                                  {errorData.buyerBank && <p className="text-danger error-contract">{errorData.buyerBank}</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row" id="Bank1">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-md-4">Exporter's bank {astrix} {toolTip("supplierBank")}</label>
                                <div className="col-md-7">
                                  <select className="form-control" name="supplierBank" value={Data.supplierBank} onChange={handleChange} id="supplierBank">
                                    <option selected value="">--Select Bank--</option>
                                    <option value="0" >Not Available</option>
                                    {
                                      getsupplierBankList()
                                    }
                                  </select>
                                  {errorData.supplierBank && <p className="text-danger error-contract">{errorData.supplierBank}</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </> :
                        <>"Something went wrong"</>}

                  <div className="float-right m-3">
                    <input type="button" className="btn btn-success m-2" value="Save" onClick={handleSubmit} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  </>)
}

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserInContract)
