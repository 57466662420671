import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { Table } from 'react-bootstrap'
import AclLogin from '../utilComponents/aclLogin';
import { formatDate_Application, } from '../../utils/dateFormaters';


const DigitalSignDoc = ({ userTokenDetails, fileData, showAclAuthModal, setAclAuthModal, refreshDoc, setrefreshDoc }) => {

  const [data, setData] = useState({});
  const [login, setLogin] = useState({
    state: false,
    validated: false
  });
  const [accordian, setaccordian] = useState(true);
  const [bcInfoForDoc, setbcInfoForDoc] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  // const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {

    setData({ ...data, userId: userId, tblDocId: fileData.tbl_doc_id, email: userEmail })

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getdigitalsigntrail', { 'tbldocid': fileData.tbl_doc_id, 'type': fileData.type ? fileData.type : null }).then((result) => {
      console.log("getdigitalsigntrail Result-->", result)
      setbcInfoForDoc(result)
    }).catch(err => {
      console.log("getdigitalsigntrail error:->", err)
      // toastDisplay(err, "error");
    })
    //------------------------------------------------------------------

  }, [refresh]);

  // Handlers
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  function submitStamp() {
    setshowLoader(true)
    console.log("data in sign submit==>", data)
    data.signType = fileData.signType ? fileData.signType : null;
    data.document_name = fileData.document_name ? fileData.document_name : null;
    data.reqId = fileData.reqId ? fileData.reqId : null;
    call('POST', 'insertDigitalSign', data).then((result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay("Digital Signature Added successfuly", "success");
      console.log("=== insertDigitalSign === ", result)
      setrefreshDoc(refreshDoc + 1)
    }).catch(err => {
      setshowLoader(false)
      toastDisplay(err, "error");
    })
  }

  function gettrailBody(data) {

    let body = (data && data.length) ? data.map((data, index) => {
      return (
        <tr>
          <td>{data.signer}</td>
          <td>{data.sign}</td>
          <td>{data.bcTxnID}</td>
          <td>{data.ipAddress}</td>
          <td>{data.comment}</td>
          <td>{formatDate_Application(data.created_at)}</td>
        </tr>
      )
    }) : ''
    return body
  }

  return (
    <>

      {showAclAuthModal && <div className={"modal" + (showAclAuthModal ? " show" : "")} id="docAcl">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Document Authorize</h4>
              <button type="button" className="close" onClick={() => { setAclAuthModal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

              <div className=" col-md-12 mt-0 pt-0" >
                <div className="col-md-12 mt-0 pt-0 row">
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.document_name) ? fileData.document_name : 'NA'}</h3>
                        <p>Type of Document</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.doc_name) ? fileData.doc_name : 'NA'}</h3>
                        <p>Document Name</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.uploaded_by) ? fileData.uploaded_by : 'NA'}</h3>
                        <p>Uploaded By</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel col-md-3 text-center">
                    <ul className="text-center">
                      <li>
                        <h3>{(fileData && fileData.uploaded_on) ? formatDate_Application(fileData.uploaded_on) : 'NA'}</h3>
                        <p>Uploaded On</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="col-md-12 mt-0 pt-0 row" style={{ "height": "270px", "overflow": "auto" }}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Signer</th>
                        <th>Signature</th>
                        <th>BC TXN Hash</th>
                        <th>IP Addr.</th>
                        <th>Place</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(bcInfoForDoc && bcInfoForDoc.length) ? gettrailBody(bcInfoForDoc) : ''}
                    </tbody>
                  </Table>
                </div>
                <hr />
                <div className={"accordionItem" + (accordian ? " open" : " closed")}>
                  <h2 className="accordionItemHeading" onClick={() => setaccordian(!accordian)}> Certification of Completion in Blockchain</h2>
                  <div className="accordionItemContent">
                    This is to certify that I have accepted the document as genuine and will work with all parties involved for the successfull completion fo the trade contract.
                    I agree with the trade parties to adhere to the agreed terms in the contract and accept mutual concensus as key for successfull completion.
                  </div>
                </div>
              </div>
              {login.state && <AclLogin setLogin={setLogin} />}
            </div>
            <div className="modal-footer primary">
              <div className="col-md-12">
                {!login.validated && <button type="button" className="btn btn-success btn-sm float-right" onClick={() => setLogin({ ...login, state: true })} >Login to Authorize</button>}
                {login.validated &&
                  <div className="col-md-12 row form-group float-right d-flex">
                    <div className="col-md-5">
                      <label >Place</label>
                      {/* <input className="form-control" type="text" placeholder="Enter Comments" name="comment" onChange={handleChange} required /> */}
                      <textarea rows="2" className="form-control" placeholder="Enter Comments" name="comment" onChange={handleChange} required></textarea>
                    </div>
                    <div className="col-md-5">
                      <label >Digital Signature</label>
                      {/* <input className="form-control" type="text" placeholder="Enter Full Name" name="sign" onChange={handleChange} required /> */}
                      <textarea rows="1" className="form-control" style={signStyle} placeholder="Enter Full Name" name="sign" onChange={handleChange} required></textarea>
                    </div>
                    <div className="col-md-2">
                      <button type="button" className="btn btn-success mt-5 text-center btn-sm" onClick={() => submitStamp()} >Submit</button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>)
}



export default DigitalSignDoc