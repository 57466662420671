import React, { useState } from 'react';
import toastDisplay from '../../utils/toastNotification';
import call from '../../service';
import moment from 'moment'

const Footer = () => {

  const [footerModal, setfooterModal] = useState({ modal: false, type: 0 });
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  let last_login_at = localStorage.getItem("last_login_at")

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };


  function contactUs() {
    return (<>
      <div col-md-12>
        <div className="form-group">
          <label className="col-md-12">Name</label>
          <div className="col-md-12">
            <input type="text" name="nameCU" value={data.nameCU} placeholder="Enter Name" className={" form-control" + (!error.nameCU ? '' : ' border-danger')} onChange={handleChange} />
            {error.nameCU ? <div class="text-danger error-contract">{error.nameCU}</div> : ''}
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-12">Email Id</label>
          <div className="col-md-12">
            <input type="text" name="emailCU" value={data.emailCU} placeholder="Enter Email" className={" form-control" + (!error.emailCU ? '' : ' border-danger')} onChange={handleChange} />
            {error.emailCU ? <div class="text-danger error-contract">{error.emailCU}</div> : ''}
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-12">Subject</label>
          <div className="col-md-12">
            <input type="text" name="subjectCU" value={data.subjectCU} placeholder="Enter Subject" className={" form-control" + (!error.subjectCU ? '' : ' border-danger')} onChange={handleChange} />
            {error.subjectCU ? <div class="text-danger error-contract">{error.subjectCU}</div> : ''}
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-12">Message</label>
          <div className="col-md-12">
            <textarea name="messageCU" rows='7' value={data.messageCU} placeholder="Enter Message" className={" form-control" + (!error.messageCU ? '' : ' border-danger')} onChange={handleChange} />
            {error.messageCU ? <div class="text-danger error-contract">{error.messageCU}</div> : ''}
          </div>
        </div>
      </div>
    </>)
  }

  function sendMessage() {

    const mailData = {
      mailReceiver: "info@tradereboot.com",
      mailSubject: data.nameCU + "||" + data.subjectCU,
      mailBodyText: "Query received from \n" + "Name: " + data.nameCU + "\n" + "Subject: " + data.subjectCU + "\n" + "Message: " + data.messageCU + "\n"
    }

    toastDisplay("sending mail...", "info");
    call('POST', 'sendMail', mailData).then((res) => {
      toastDisplay("Mail sent successfuly.", "success");
      setfooterModal({ modal: false, type: 0 })
    }).catch(error => {
      console.log("Error occure while sending mail:", error)
      toastDisplay("Unable to process mail.", "error");
    });

  }

  function termsOfUse() {
    return (<>
      <div className="calc-inner-modal">
        {/* {termsofusetext} */}
      </div>
    </>)
  }

  function privacyPolicy() {
    return (<>
      <div className="calc-inner-modal">
        {/* {privacyPolicytext} */}
      </div>
    </>)
  }

  return (<>
    <footer className="pt-2 pb-2 footer bg-white text-dark border-top bg-light">
      <div className="container-fluid">
        <div className="row text-center d-flex">
          <div className="col-md-4 order-sm-3">
            <ul className="nav-footer">
              <li className=""><a className="text-dark text-decoration-none cursor-pointer">V-1.0</a></li>
              {last_login_at && last_login_at != "null" && (
                <li className=""><a className="text-dark text-decoration-none cursor-pointer">Last Login - {
                  moment(last_login_at).utc().format("YYYY-MM-DD hh:mm:ss")}</a></li>
              )}
              <li className="" onClick={() => setfooterModal({ modal: true, type: 1 })}><a className="text-dark text-decoration-none cursor-pointer">Contact Us</a></li>
              <li className=""><a className="text-dark text-decoration-none cursor-pointer" href="./assets/docs/Terms_of_Use.pdf" target="true">Terms of Use</a></li>
              <li className=""><a className="text-dark text-decoration-none cursor-pointer" href="./assets/docs/Privacy_&_Cookie_Policy.pdf" target="true">Privacy Policy</a></li>
              {/* <li className=""><a className="text-dark text-decoration-none" >FAQs</a></li> */}
              <li className=""><a className="text-dark text-decoration-none" href="./assets/docs/TradeReboot_Whitepapper.pdf" target="true">White Paper</a></li>
            </ul>
          </div>
          <div className="col-md-4 order-sm-2  text-center">
            <a className="m-1" href="https://twitter.com/tradereboot" target="_blank"><i className="fab fa-twitter-square fa-2x" aria-hidden="true" /></a>
            <a className="m-1" href="https://www.linkedin.com/company/trade-reboot/" target="_blank"><i className="fab fa-linkedin fa-2x" aria-hidden="true" /></a>
            <a className="m-1" href="mailto:info@tradereboot.com" target="_blank"><i className="fas fa-envelope-square fa-2x" aria-hidden="true" /></a>
          </div>
          <div className="col-md-4 order-sm-1 text-left">
            <p>Copyright © 2021 TradeReboot. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>

    {footerModal.modal &&
      <div className={"modal" + (footerModal.modal ? " show" : "")} id="Confirmation">
        <div className="modal-dialog modal-lg border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">{footerModal.type === 1 ? "Contact Us" : footerModal.type === 2 ? "Terms of Use" : footerModal.type === 3 ? "Privacy Policy" : ""}</h4>
              <button type="button" className="close" onClick={() => { setfooterModal({ modal: false, type: 0 }) }}>×</button>
            </div>
            <div className="modal-body">
              {footerModal.type === 1 ? contactUs() : footerModal.type === 2 ? termsOfUse() : footerModal.type === 3 ? privacyPolicy() : ""}
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-info btn-sm" onClick={() => setfooterModal({ modal: false, type: 0 })}>Close</button>
              <button type="button" className="btn btn-success btn-sm" onClick={() => sendMessage()}>Send Message</button>
            </div>
          </div>
        </div>
      </div>}
  </>
  )
}

export default Footer;
