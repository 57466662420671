const columns = [

  {
    name: 'Entity Name',
    selector: 'name',
    sortable: false,
    grow: 2,
    style: {
      backgroundColor: 'rgba(63, 195, 128, 0.9)',
      color: 'white',
    },
  },
  {
    name: 'Country',
    selector: 'country',
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: false,
  },
  {
    name: 'Contact Person',
    selector: 'contactPerson',
    sortable: false,
  },
  {
    name: 'Contact No',
    selector: 'contactNo',
    sortable: false,
  },
  {
    name: 'Created on',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    cell: row =>
      (row.status === 1) ? "Active" :
        (row.status === 0) ? "InActive" : "NA",
    conditionalCellStyles: [
      {
        when: row => (row.status === 0),
        style: {
          backgroundColor: 'rgba(255, 0, 0, 0.9)',  //red
          color: 'white',
        },
      },
      {
        when: row => (row.status === 1),
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',  //green
          color: 'white',
        },
      }
    ]
  },
  {
    name: 'Action',
    selector: 'action',
  }
];

export default columns
