

import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

const DocRequestModal = ({ userTokenDetails, contractNo, setshowRequestModal, showRequestModal }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [parties, setParties] = useState([]);
  const [data, setData] = useState({});

  // const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  // const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getContractUsers', { categoryId: showRequestModal.categoryid, contractNo: contractNo }).then((result) => {
      setParties(result);
    }).catch((e) => {
      console.log("Error while querying getContractUsers:", e);
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  }

  const handleCheckbox = (event) => {
    event.persist();
    let tempArray = data[event.target.name] ? [...data[event.target.name]] : []
    let selectedUser = event.target.value
    let index = tempArray.indexOf(selectedUser)

    if (index !== -1) {
      tempArray = tempArray.slice(0, index)
    } else {
      tempArray.push(selectedUser)
    }

    setData(data => ({ ...data, [event.target.name]: tempArray }));
  }

  //---------------------------------------------------------------------------------------------------------------------

  console.log("data in request =>", data)
  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function handleSubmitDocUpload() {

    let formData = new FormData();

    formData.append("userName", userName);
    formData.append("sendToUsers", data.notifUser);
    formData.append("docCategory", showRequestModal.categoryName);
    formData.append("comment", data.comment);
    formData.append("contractNumber", contractNo);


    call('POST', 'raisedocrequest', formData).then((result) => {
      if (result) {
        // console.log("result while insertcontractdoc:", result);
        setshowRequestModal(false)
        toastDisplay(result, "success")
      }
    }).catch(e => {
      console.log("Error while insertcontractdoc:", e);
      toastDisplay(e, "error")
    })

  }
  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {showRequestModal.modal && <div className={"modal" + (showRequestModal.modal ? " show" : "")} id="DocumentUpload">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">Document Request | Category : {showRequestModal.categoryName}</h4>
              <button type="button" className="close" onClick={() => { setshowRequestModal(false) }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              <div className=" col-md-12 row" >
                <div className=" col-md-12 row">
                  <div className=" col-md-6">
                    <label >Select User's to notify</label>
                    {parties && parties.length ? parties.map((user, index) => {
                      return (
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value={user.tbl_user_id} name="notifUser" id={"notifUser" + index} onChange={handleCheckbox} />
                          <label className="form-check-label" htmlFor={"notifUser" + index}>{user.company_name}</label>
                        </div>
                      )
                    }) : ""
                    }
                  </div>
                  <div className=" col-md-6">
                    <label >Comments</label>
                    <textarea rows="5" maxlength="100" className="form-control" placeholder="Enter Comments" name="comment" onChange={handleChange} required></textarea>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-primary btn-sm float-right" onClick={() => handleSubmitDocUpload()} >Submit</button>
            </div>
          </div>
        </div>
      </div >}
    </>)
}


export default DocRequestModal
