import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import call from '../../service';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/insuranceList';
import customStyles from '../dataTablesColumsMap/customTableCss';
import Footer from '../partial/footer';


const InsuranceCasesList = ({ userTokenDetails, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [count, setCount] = useState(0)
  const [completeButton, setcompleteButton] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [planList, setplanList] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1)
    //---------------------------------------------------------------------------------------------------------------------

  }, [refresh, filterData]);


  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState('');


  function fetchTableData(page) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "type_id": userTypeId,
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getsubsaledetails', objectAPI).then((result) => {
      console.log('running getsubsaledetails api-->', result);
      settableData(formatDataFortable(result.paymentData))
      setTotalRows(result.countdata.totalcount)
      setCount(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page);
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getsubsaledetails', objectAPI).then((result) => {
      settableData(formatDataFortable(result.paymentData))
      setTotalRows(result.countdata.totalcount)
      setCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.txnId = dataArray[i].gateway_txn_id;
      tempObj.buyer = dataArray[i].company_name;
      tempObj.planName = dataArray[i].plan_name;
      tempObj.amount = dataArray[i].txn_amount;
      tempObj.currency = dataArray[i].currency;
      tempObj.dated = dataArray[i].created_at;
      tempObj.logJson = dataArray[i].txn_log;
      tempObj.status = dataArray[i].txn_status === 5 ? "REFUNDED" :
        dataArray[i].txn_status === 4 ? "PENDING" :
          dataArray[i].txn_status === 3 ? "PARTIALLY REFUNDED" :
            dataArray[i].txn_status === 2 ? "DECLINED" :
              dataArray[i].txn_status === 1 ? "COMPLETED" : "NA";

      tempObj.action = <button className="btn btn-info btn-sm mr-2 ml-2"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>

      resultArray.push(tempObj)
    }
    return resultArray
  }


  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const filterStateButtons = (type) => {
    // if (type === 2) {
    //   setFilterData({
    //     ...filterData,
    //     onlyCompleted: false,
    //     onlyPending: false,
    //     onlyInProcess: false,
    //     onlyDeclined: true
    //   })
    // } else 
    if (type === 5) {
      setFilterData({
        ...filterData,
        ...filterData,
        onlyCompleted: false,
        onlyPending: false,
        onlyInProcess: true,
        // onlyDeclined: false
      })
    } else if (type === 4) {
      setFilterData({
        ...filterData,
        onlyCompleted: false,
        onlyPending: true,
        onlyInProcess: false,
        // onlyDeclined: false
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyCompleted: true,
        onlyPending: false,
        onlyInProcess: false,
        // onlyDeclined: false
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------
  //Functions
  const TableExpandedComponent = ({ data }) => (
    <>
      <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
        <div className="ml-5 mr-5 border-left">
          <p className="pl-2 pt-1" >Payer Id: {data.logJson.payer.payer_id}</p>
        </div>
        <div className="ml-5 mr-5 border-left">
          <p className="pl-2 pt-1" >Payer Name: {data.logJson.payer.name.given_name + " " + data.logJson.payer.name.surname}</p>

        </div>
        <div className="ml-5 mr-5 border-left">
          <p className="pl-2 pt-1" >Payer Email: {data.logJson.payer.email_address}%</p>

        </div>
        <div className="ml-5 mr-5 border-left">
          <p className="pl-2 pt-1" >Payer Address: {data.logJson.payer.address.country_code}</p>

        </div>
      </div>
    </>
  );

  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar state={'cases'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={'Insurance Quotation'}
              total={'Total ' + count + ' Insurance Quotations'}
              userTokenDetails={userTokenDetails} />
            <div className="d-md-flex mt-3 mb-3">
              <div className="filter-bg">
                <ul>
                  {/* <li className={"danger-cont text-danger cursor-pointer " + (completeButton === 2 ? "shadow" : '')} id="declineButton" onClick={() => { setcompleteButton(2); filterStateButtons(2); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Declined
                  </li> */}
                  <li className={"progress-cont cursor-pointer " + (completeButton === 4 ? "shadow" : '')} id="pendingButton" onClick={() => { setcompleteButton(4); filterStateButtons(4); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Pending
                  </li>
                  <li className={"primary-cont cursor-pointer " + (completeButton === 5 ? "shadow" : '')} id="inProcessButton" onClick={() => { setcompleteButton(5); filterStateButtons(5); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> In-Process
                  </li>
                  <li className={"complete-cont cursor-pointer " + (completeButton === 1 ? "shadow" : '')} id="completeButton" onClick={() => { setcompleteButton(1); filterStateButtons(1); }}>
                    <span className="icon"><img src="assets/images/filter-icon.svg" /></span> Completed
                  </li>
                </ul>
              </div>

              <div className="ml-md-auto relative">

              </div>
            </div>
            <hr />

            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                persistTableHead
                expandableRows
                expandableRowsComponent={<TableExpandedComponent />}
                expandOnRowClicked
                highlightOnHover
                onSort={handleSort}
                sortServer
                striped
                noHeader
                customStyles={customStyles}
              />
            </div>
            <hr />

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceCasesList)