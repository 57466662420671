import React, { useEffect, useState } from 'react';
import { setAddShip, setBookShip, setViewShip } from '../../store/actions/action';
import { connect } from 'react-redux';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import AddShip from './addShip'
import BookShip from './bookShip'
import ViewShip from './viewShip'
import { ToastContainer } from 'react-toastify';
import MyShipsListing from './myshipsListing'
import PublicShipsListing from './publicShipsListing'
import BookedShipsListing from './bookedShipslisting'
import Footer from '../partial/footer';

const Ships = ({ userTokenDetails, showAddShip, bookShip, viewShip, navToggleState, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [tab, setTab] = useState(0);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------
    if (aclMap.ships_access && aclMap.ships_access.ships_myShipGrid && aclMap.ships_access.ships_myShipGrid.isExist) {
      setTab(0)
    } else if (aclMap.ships_access && aclMap.ships_access.ships_pubShipGrid && aclMap.ships_access.ships_pubShipGrid.isExist) {
      setTab(1)
    } else if (aclMap.ships_access && aclMap.ships_access.ships_manageBookedShip && aclMap.ships_access.ships_manageBookedShip.isExist) {
      setTab(2)
    }
    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">

          {/* add ship start */}
          {(showAddShip.modal && (aclMap.ships_access && aclMap.ships_access.ships_add && aclMap.ships_access.ships_add.isExist)) &&
            <AddShip userTokenDetails={userTokenDetails} />}
          {/* add ship end */}

          {/* book ship start */}
          {(bookShip.modal && (aclMap.ships_access && aclMap.ships_access.ships_book && aclMap.ships_access.ships_book.isExist)) &&
            <BookShip userTokenDetails={userTokenDetails} />}
          {/* book ship end */}

          {/* view ship start */}
          {(viewShip.modal && (aclMap.ships_access && aclMap.ships_access.ships_edit && aclMap.ships_access.ships_edit.isExist)) &&
            <ViewShip userTokenDetails={userTokenDetails} />}
          {/* view ship end */}

          <Sidebar state={'ships'} userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={'Vessels'}
              userTokenDetails={userTokenDetails} />

            <ul className="nav nav-tabs-custom btn" id="myTab" role="tablist">
              {(aclMap.ships_access && aclMap.ships_access.ships_myShipGrid && aclMap.ships_access.ships_myShipGrid.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>My Vessels</a>
                </li>}
              {(aclMap.ships_access && aclMap.ships_access.ships_pubShipGrid && aclMap.ships_access.ships_pubShipGrid.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>Available Vessels</a>
                </li>}
              {(aclMap.ships_access && aclMap.ships_access.ships_manageBookedShip && aclMap.ships_access.ships_manageBookedShip.isExist) &&
                <li>
                  <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Vessel Contracts</a>
                </li>}
            </ul>

            <div className="d-flex">
              {tab === 0 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.ships_access && aclMap.ships_access.ships_myShipGrid && aclMap.ships_access.ships_myShipGrid.isExist) &&
                    <MyShipsListing userTokenDetails={userTokenDetails} />}
                </div>
              }
              {tab === 1 &&
                <div className={"tab-pane active show  col-md-12"}>
                  {(aclMap.ships_access && aclMap.ships_access.ships_pubShipGrid && aclMap.ships_access.ships_pubShipGrid.isExist) &&
                    <PublicShipsListing userTokenDetails={userTokenDetails} />}
                </div>
              }
              {tab === 2 &&
                <div className={"tab-pane active show col-md-12"}>
                  {(aclMap.ships_access && aclMap.ships_access.ships_manageBookedShip && aclMap.ships_access.ships_manageBookedShip.isExist) &&
                    <BookedShipsListing userTokenDetails={userTokenDetails} />}
                </div>
              }
            </div>
          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    showAddShip: state.showAddShip,
    bookShip: state.bookShip,
    viewShip: state.viewShip,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAddShip: (flag, data) => { dispatch(setAddShip({ modal: flag, info: data })) },
    setBookShip: (flag, data) => { dispatch(setBookShip({ modal: flag, info: data })) },
    setViewShip: (flag, data) => { dispatch(setViewShip({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ships)
