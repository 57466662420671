import React, { useState, useEffect } from 'react';
// import { setContractStateLogi } from '../../store/actions/action';
import { connect } from 'react-redux';
import LaytimeSheetTrail from './laytimeSheetTrail';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { formatDateTime_Application, dateTimeFormatter_DatePicker } from '../../utils/dateFormaters';
import validate from '../../utils/validation_files/laytimeSheetValidationRules'

const LaytimeSheet = ({ userTokenDetails, laytimeType, laytimesheet, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [sheet, setSheet] = useState([{ row: 1, subRow: 0 }]);
  const [data, setData] = useState({})
  const [trail, setTrail] = useState([])
  const [sheetData, setSheetData] = useState([])
  const [cal, setCal] = useState(false)
  let [showLoader, setshowLoader] = useState(false);

  const [sheetFlag, setSheetFlag] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [totalAllowed, setTotalAllowed] = useState({
    timeAllowed: '00:00:00',
    timeUsed: '00:00:00',
    timeDiif: '00:00:00',
    totalDays: 0
  })

  const [error, setError] = useState({});
  const [isSubmitting1, setIsSubmitting1] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getLaytime', { cNo: laytimesheet.cNo, contractType: 'ship', laytimeType: laytimeType }).then(result => {
      console.log("result in get aytime data-==>", result)
      if (result) {
        if (Object.keys(result.laytimeData).length) {
          setTrail(result.laytimeData);
          setSheetData(result.laytimeData.sheet.sheetData)
          calSheetWithTrail(result.laytimeData)
        } else {
          setData(data => ({
            ...data,
            'demurageRate': result.contractInfo.ship_demmurage_price,
            'dispatchRate': ((Number(result.contractInfo.ship_demmurage_price)) / 2).toFixed(2),
            'qLoaded': result.contractInfo.ship_quantity,
            'nor': dateTimeFormatter_DatePicker(result.contractInfo.nor_date),
            'loadRate': result.contractInfo.loading_rate,
          }));
        }

        setshowLoader(false)
      }
    }).catch((e) => {
      console.log('error', e);
      setshowLoader(false)
    })
  }, [refresh])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting1) {
      saveLaytime()
    }
    if (Object.keys(error).length === 0 && isSubmitting2) {
      updateLaytime()
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {

    event.persist();

    if (event.target.name === 'demurageRate') {
      setData(data => ({ ...data, 'demurageRate': event.target.value, 'dispatchRate': ((Number(event.target.value)) / 2).toFixed(2) }));
    } else if (event.target.name === 'sof') {

      let payloadUpload = {
        contract_id: laytimesheet.cNo,
        linked_to: 0,
        isUpdateQuery: true,
        uploadingUser: userId,
        tbl_doc_id: trail.tbl_document_details_id,
        filePayload: {
          docNo: '',
          docName: 'Statement of Facts (SOF)',
          genDocLabel: event.target.name,
          docType: "16",
          validUpto: ''
        }
      }
      payloadUpload = JSON.stringify(payloadUpload);
      setData(data => ({ ...data, 'docSOF': event.target.files[0], 'payloadUploadSOF': payloadUpload }));
    } else {
      setData(data => ({ ...data, [event.target.name]: event.target.value }));
    }
  };

  console.log("data in laytime sheet---->", data)
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions

  function calLaytimeAllowed(event) {
    event.persist();

    if ((event.target.name === "loadCommenced" && data.loadCompleted) || (event.target.name === "loadCompleted" && data.loadCommenced) || (data.loadCompleted && data.loadCommenced)) {
      let temp, day, hour, minutes, firstDate, secondDate

      if (event.target.name === "loadCommenced") {
        firstDate = new Date(event.target.value);
        secondDate = new Date(data.loadCompleted);
      } else if (event.target.name === "loadCompleted") {
        firstDate = new Date(data.loadCommenced);
        secondDate = new Date(event.target.value);
      } else {
        firstDate = new Date(data.loadCommenced);
        secondDate = new Date(data.loadCompleted);
      }

      let timeDifference = Math.abs(secondDate.getTime() - firstDate.getTime());
      temp = timeDifference / (1000 * 3600 * 24)
      day = Math.floor(temp)
      temp = (temp - day) * 24;
      hour = Math.floor(temp);
      minutes = Math.round((temp - hour) * 60);

      setData(data => ({ ...data, laytimeAllowed: ('' + day + ':' + hour + ':' + minutes), laytimeSheetDays: day }))
    }
  }

  const rowData = () => {
    sheetData.push({
      day: '',
      date: '',
      sub: [{
        laytimeStart: '00:00:00',
        laytimeEnd: '00:00:00',
        laytimeUsed: '00:00:00',
        laytimeDiff: '00:00:00',
        totalDays: 0,
        reason: 'Loading Done'
      }]
    })
    setSheetData([...sheetData])
  }

  const rowInnerData = (i) => {
    sheetData[i].sub.push({
      laytimeStart: '00:00:00',
      laytimeEnd: '00:00:00',
      laytimeUsed: '00:00:00',
      laytimeDiff: '00:00:00',
      totalDays: 0,
      reason: 'Loading Done'
    })
    setSheetData([...sheetData])
  }

  const initRowData = (sheet) => {
    let temp = []
    sheet.map((item, ind) => {
      let elem = {
        day: '',
        date: '',
        sub: []
      }
      for (let i = 0; i < item.subRow + 1; i++) {
        elem.sub.push({
          laytimeStart: '00:00:00',
          laytimeEnd: '00:00:00',
          laytimeUsed: '00:00:00',
          laytimeDiff: '00:00:00',
          totalDays: 0,
          reason: 'Loading Done'
        })
      }
      temp.push(elem)
      setSheetData([...temp])
    })
    setSheet(sheet)
  }

  const addRow = () => {
    sheet.push({ row: 1, subRow: 0 })
    setSheet([...sheet])
    rowData()
  }

  const addSubrow = (i) => {
    sheet[i] = { row: 1, subRow: sheet[i].subRow + 1 }
    setSheet([...sheet])
    rowInnerData(i)
  }

  const delRow = (i) => {
    sheet.splice(i, 1);
    setSheet([...sheet]);
    sheetData.splice(i, 1);
    setSheetData([...sheetData])
  }

  const getFormattedDate = (total) => {
    let temp, hour, minutes, sec;
    temp = Number(total);
    hour = Math.floor(temp / 3600);
    minutes = Math.floor(temp % 3600 / 60);
    sec = Math.floor(temp % 3600 % 60);

    hour = hour > 0 ? hour : "00";
    minutes = minutes > 0 ? minutes : "00";
    sec = sec > 0 ? sec : "00";
    return hour + ':' + minutes + ':' + sec;
  }

  const calDiff = async (start, end) => {
    let day, hour, minutes;
    start = start.split(':');
    end = end.split(':');
    start[2] = start[2] ? start[2] : '00';
    end[2] = end[2] ? end[2] : '00';

    day = Number(start[0]);
    hour = Number(start[1]);
    minutes = Number(start[2]);
    let totalStart = (day * 24 * 60 * 60) + (hour * 60 * 60) + (minutes * 60);

    day = Number(end[0]);
    hour = Number(end[1]);
    minutes = Number(end[2]);
    let totalEnd = (day * 24 * 60 * 60) + (hour * 60 * 60) + (minutes * 60);

    let total = totalStart - totalEnd
    let result
    if (Math.sign(total) === -1) {
      total = Math.abs(total)
      result = await getFormattedDate(total)
      result = '-' + result
    } else {
      result = await getFormattedDate(total)
    }
    return result
  }

  const calDiffTime = async (start, end) => {
    let day, hour, minutes;
    start = start.split(':');
    end = end.split(':');
    start[2] = start[2] ? start[2] : '00';
    end[2] = end[2] ? end[2] : '00';

    day = Number(start[0]);
    hour = Number(start[1]);
    minutes = Number(start[2]);
    let totalStart = (day * 60 * 60) + (hour * 60) + minutes;

    day = Number(end[0]);
    hour = Number(end[1]);
    minutes = Number(end[2]);
    let totalEnd = (day * 60 * 60) + (hour * 60) + minutes;

    let total = totalStart - totalEnd
    let result
    if (Math.sign(total) === -1) {
      total = Math.abs(total)
      result = await getFormattedDate(total)
      result = '-' + result
    } else {
      result = await getFormattedDate(total)
    }
    return result
  }

  const calCummulative = async (lTimeUsed, out, ind, targetOut, targetIn) => {
    let temp, day = 0, hr = 0, min = 0;

    for (let i = 0; i <= out; i++) {
      for (let j = 0; j < sheetData[i].sub.length; j++) {
        if (sheetData[i].sub[j].reason === "Loading Done") {
          if (targetOut === i && targetIn === j) {
            temp = lTimeUsed.split(':')
            day += Number(temp[0])
            hr += Number(temp[1])
            min += Number(temp[2])
          } else {
            temp = sheetData[i].sub[j].laytimeUsed.split(':')
            day += Number(temp[0])
            hr += Number(temp[1])
            min += Number(temp[2])
          }
        }
      }
    }
    let total = (day * 60 * 60) + (hr * 60) + min;
    let result = await getFormattedDate(total)
    return result;
  }

  function timeToDayFormat(total) {
    let date = total.split(':');
    let m1 = date[0] * 60
    let m2 = +date[1]
    let m3 = date[2] / 60
    total = (m1 + m2 + m3);

    let temp, day, hour, minutes;
    temp = total / (60 * 24);
    day = Math.floor(Math.abs(temp))
    temp = (temp - day) * 24;
    hour = Math.floor(Math.abs(temp));
    if (hour > 24) {
      let d = Math.round(hour / 24)
      let h = hour - 24
      day += d
      hour = hour - h;
    }
    if (hour / 1 === 24) {
      hour -= 1
      day += 1
    }
    minutes = Math.round((temp - hour) * 60);
    if (minutes > 60) {
      let h = Math.floor(minutes / 60);
      let m = minutes - 60;
      hour += h;
      minutes -= m;
    }
    if (minutes / 1 === 60) {
      minutes -= 60
      hour += 1
    }
    return (day + ':' + hour + ':' + minutes)

  }

  console.log("sheetData==>", sheetData)

  const handleSheet = async (out, ind, event) => {
    event.persist();

    console.log("event called==>", event.target.name, event.target.value)

    let lTimeUsed = sheetData[out].sub[ind].laytimeUsed

    if (event.target.name === 'laytimeStart') {
      // lTimeUsed = sheetData[out].sub[ind].laytimeEnd === '00:00:00' ? '00:00:00' : await calDiffTime(sheetData[out].sub[ind].laytimeEnd, event.target.value)
      lTimeUsed = await calDiffTime(sheetData[out].sub[ind].laytimeEnd, event.target.value)

      for (let i = 0; i < sheetData.length; i++) {
        for (let j = 0; j < sheetData[i].sub.length; j++) {
          if (sheetData[i].sub[j].reason === "Loading Done") {
            let tempRes = await calCummulative(lTimeUsed, i, j, out, ind)
            tempRes = await timeToDayFormat(tempRes);
            sheetData[i].sub[j] = { ...sheetData[i].sub[j], laytimeDiff: tempRes }
          }
        }
      }
    } else if (event.target.name === 'laytimeEnd') {
      // lTimeUsed = sheetData[out].sub[ind].laytimeStart === '00:00:00' ? '00:00:00' : await calDiffTime(event.target.value, sheetData[out].sub[ind].laytimeStart)
      lTimeUsed = await calDiffTime(event.target.value, sheetData[out].sub[ind].laytimeStart)

      // lTimeUsed = lTimeUsed === 'NaN:NaN:NaN' ? '00:00:00' : lTimeUsed

      for (let i = 0; i < sheetData.length; i++) {
        for (let j = 0; j < sheetData[i].sub.length; j++) {
          if (sheetData[i].sub[j].reason === "Loading Done") {
            let tempRes = await calCummulative(lTimeUsed, i, j, out, ind)
            tempRes = await timeToDayFormat(tempRes);
            sheetData[i].sub[j] = { ...sheetData[i].sub[j], laytimeDiff: tempRes }
          }
        }
      }
    } else if (event.target.name === "reason" && event.target.value !== "Loading Done") {

      for (let i = 0; i < sheetData.length; i++) {
        for (let j = 0; j < sheetData[i].sub.length; j++) {

          let tempRes = await calCummulative('00:00:00', i, j, out, ind)
          tempRes = await timeToDayFormat(tempRes);
          sheetData[i].sub[j] = { ...sheetData[i].sub[j], laytimeDiff: tempRes }

        }
      }

    }

    sheetData[out].sub[ind] = { ...sheetData[out].sub[ind], [event.target.name]: event.target.value, laytimeUsed: lTimeUsed }
    setSheetData([...sheetData])
  };

  const delInnerRow = (out, ind, event) => {
    event.persist();
    sheet[out].subRow = sheet[out].subRow - 1
    setSheet([...sheet])
    sheetData[out].sub.splice(ind, 1);
    setSheetData([...sheetData])
  };

  const handleSheetOuter = (out, ind, event) => {
    event.persist();
    if (event.target.name === 'date') {
      let day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      sheetData[out] = { ...sheetData[out], [event.target.name]: event.target.value, day: day[new Date(event.target.value).getDay()] }
    } else {
      sheetData[out] = { ...sheetData[out], [event.target.name]: event.target.value }
    }
    setSheetData([...sheetData])
  };

  const calChange = async () => {
    let t, d, h, m, timeAllowed;
    let firstDate = new Date(data.loadCommenced);
    let secondDate = new Date(data.loadCompleted);
    if (firstDate == 'Invalid Date') {
      toastDisplay("Loading Commenced Date Invalid", "warn")
      return;
    }
    if (secondDate == 'Invalid Date') {
      toastDisplay("Loading Complete Date Invalid", "warn")
      return;
    }
    let timeDifference = Math.abs(secondDate.getTime() - firstDate.getTime());
    t = timeDifference / (1000 * 3600 * 24)
    d = Math.floor(t)
    t = (t - d) * 24;
    h = Math.floor(t);
    m = Math.round((t - h) * 60);
    timeAllowed = ('' + d + ':' + h + ':' + m);

    let temp, day = 0, hr = 0, min = 0;
    sheetData.map((outData, ind) => {
      outData.sub.map((inData, i) => {
        temp = inData.laytimeUsed.split(':')
        day += Number(temp[0])
        hr += Number(temp[1])
        min += Number(temp[2])
      })
    })
    let total = (day * 60 * 60) + (hr * 60) + min;
    let result = await getFormattedDate(total)
    let res = await timeToDayFormat(result);
    let tDiff = await calDiff(timeAllowed, res)
    tDiff = await timeToDayFormat(tDiff);
    let arr = tDiff.split(':');

    let totalDay = Number(Math.abs(arr[0])) + (Math.abs(arr[1])) / 24 + (Math.abs(arr[2])) / (24 * 60)
    // setTotalAllowed({ ...totalAllowed, timeAllowed: res })

    setTotalAllowed({ ...totalAllowed, timeUsed: res, timeDiif: tDiff, totalDays: totalDay.toFixed(2), timeAllowed: timeAllowed })
    setCal(true)
  }

  const calRevert = () => {
    setCal(false)
  }

  const calAndRenderSheet = (event) => {
    event.persist();

    let firstDate = new Date(data.loadCommenced);
    let secondDate = new Date(data.loadCompleted);
    if (firstDate == 'Invalid Date') {
      toastDisplay("Loading Commenced Date Invalid", "warn")
      return;
    }
    if (secondDate == 'Invalid Date') {
      toastDisplay("Loading Complete Date Invalid", "warn")
      return;
    }


    let tempSheet = []
    for (var i = 0; i < data.laytimeSheetDays; i++) {
      tempSheet.push({ row: 1, subRow: 0 })
    }

    console.log("tempSheet===>", tempSheet)

    setSheet(tempSheet)
    setSheetFlag(true)
    initRowData(tempSheet)
  }

  function calSheetWithTrail(trail) {
    let temp = [];

    setData(data => (
      {
        ...data,
        'demurageRate': trail.sheet.demurageRate,
        'dispatchRate': trail.sheet.dispatchRate,
        'qLoaded': trail.sheet.qLoaded,
        'loadRate': trail.sheet.loadRate,
        'nor': trail.sheet.nor,
        'arrivalTime': trail.sheet.arrivalTime,
        'laytimeUnit': trail.sheet.laytimeUnit,
        'laytimeCommenced': trail.sheet.laytimeCommenced,
        'loadCommenced': trail.sheet.loadCommenced,
        'loadCompleted': trail.sheet.loadCompleted,
        'timeAllowed': trail.sheet.timeAllowed
      }
    ));
    setTotalAllowed({ ...totalAllowed, timeAllowed: trail.sheet.timeAllowed })
    for (let i = 0; i < trail.sheet.sheetData.length; i++) {
      temp.push({ row: 1, subRow: trail.sheet.sheetData[i] && trail.sheet.sheetData[i].sub && trail.sheet.sheetData[i].sub.length - 1 })
    }
    setSheet(temp)
    setSheetFlag(true)
    // initRowData(temp)
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  function saveLaytime() {
    let userType = userTypeId
    let dataBody = {
      sheet: data,
      userType: userType,
      contractNo: laytimesheet.cNo,
      laytimeType: laytimeType
    }

    if (!totalAllowed.timeDiif.includes('-')) {
      console.log("checking inside dispatch==>")
      dataBody['totalDispatch'] = (data.dispatchRate * totalAllowed.totalDays).toFixed(2)
      dataBody['totalDemurage'] = null
    } else {
      console.log("checking inside demurage==>")
      dataBody['totalDispatch'] = null
      dataBody['totalDemurage'] = (data.demurageRate * totalAllowed.totalDays).toFixed(2)
    }

    dataBody.sheet.sheetData = sheetData
    dataBody.sheet = Object.assign(dataBody.sheet, totalAllowed)

    console.log()

    let formData = new FormData();
    formData.append('dataBody', JSON.stringify(dataBody));

    if (data.docSOF) {
      formData.append('docSOF', data.docSOF);
      formData.append('payloadUploadSOF', data.payloadUploadSOF);
    }

    setshowLoader(true)
    call('POST', 'laytime', formData).then((result) => {
      if (result) {
        toastDisplay("Laytime sheet saved successfuly", "success");
        setRefresh(refresh + 1)
        setSheetFlag(false)
        setCal(false)
        setshowLoader(false)
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("error in POST laytime sheet==>", err)
      toastDisplay(err, "error");
    })
  }

  function updateLaytime() {
    let userType = userTypeId
    let dataBody = {
      sheet: data,
      userType: userType,
      contractNo: laytimesheet.cNo,
      id: trail.id,
      tbl_document_details_id: trail.tbl_document_details_id,
      laytimeType: laytimeType
    }

    if (!totalAllowed.timeDiif.includes('-')) {
      console.log("checking inside dispatch==>")
      dataBody['totalDispatch'] = (data.dispatchRate * totalAllowed.totalDays).toFixed(2)
      dataBody['totalDemurage'] = null
    } else {
      console.log("checking inside demurage==>")
      dataBody['totalDispatch'] = null
      dataBody['totalDemurage'] = (data.demurageRate * totalAllowed.totalDays).toFixed(2)
    }

    dataBody.sheet.sheetData = sheetData
    dataBody.sheet = Object.assign(dataBody.sheet, totalAllowed)
    let formData = new FormData();
    formData.append('dataBody', JSON.stringify(dataBody));

    if (data.docSOF) {
      formData.append('docSOF', data.docSOF);
      formData.append('payloadUploadSOF', data.payloadUploadSOF);
    }

    setshowLoader(true)
    call('POST', 'updateLaytime', formData).then((result) => {
      if (result) {
        toastDisplay("Laytime sheet Updated successfuly", "success");
        setRefresh(refresh + 1)
        setSheetFlag(false)
        setCal(false)
        setshowLoader(false)
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("error in updateLaytime laytime sheet==>", err)
      toastDisplay(err, "error");
    })
  }


  const prehandleSave = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting1(true);
  };

  const prehandleUpdate = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting2(true);
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Insert Row Component
  const innerRow = (ind) => {
    let innerRows = []
    for (let i = 1; i < sheet[ind].subRow + 1; i++) {
      innerRows.push(
        <tr>
          <td><span className="delete-table-icon" onClick={(event) => delInnerRow(ind, i, event)}><i className="fas fa-trash text-danger" aria-hidden="true"></i></span><input type="time" step="1" name="laytimeStart" className="form-control" placeholder="00:00:00" onBlur={(event) => handleSheet(ind, i, event)} defaultValue={sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[i].laytimeStart : ''} /></td>
          <td><input type="time" step="1" name="laytimeEnd" className="form-control" placeholder="00:00:00" onBlur={(event) => handleSheet(ind, i, event)} defaultValue={sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[i].laytimeEnd : ''} /></td>
          <td><input type="text" name="laytimeUsed" className="form-control" placeholder="00:00:00" value={sheetData.length && sheetData[ind].sub.length ? sheetData[ind].sub[i].laytimeUsed : 0} readOnly /></td>
          <td>
            <select className="form-control" name="reason" onChange={(event) => handleSheet(ind, i, event)} required >
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[i].reason === 'Loading Done'}>Loading Done</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[i].reason === 'No Loading (RAIN)'}>No Loading (RAIN)</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[i].reason === 'No Loading (Crane Issue)'}>No Loading (Crane Issue)</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[i].reason === 'No Loading'}>No Loading</option>
            </select>
          </td>
          <td>{sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[i].laytimeDiff : ''}</td>
        </tr>
      )
    }
    return innerRows;
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Body Component
  const body = sheet.map((val, ind) => {
    let temp = innerRow(ind)
    return (
      <>
        <tr>
          <td rowSpan={sheet[ind].subRow + 1}>
            <input type="date" name="date" className="form-control custom_input"
              onChange={(event) => handleSheetOuter(ind, 0, event)}
              defaultValue={sheetData[ind] && sheetData[ind].date ? sheetData[ind].date : ''} readOnly={cal} />
            {
              !cal &&
              <span className="plus-icon">
                <i class="fas fa-plus text-success pr-2" onClick={() => addSubrow(ind)}></i>
                <i className="fas fa-trash text-danger" aria-hidden="true" onClick={() => delRow(ind)} ></i>
              </span>
            }
          </td>
          <td rowSpan={sheet[ind].subRow + 1}>
            <input type="text" name="day" defaultValue={sheetData[ind] && sheetData[ind].day ? sheetData[ind].day : ''} className="form-control " placeholder="Wednesday" readOnly />
          </td>

          <td>
            <input type="time" step="1" name="laytimeStart" className="form-control" placeholder="24:00:00"
              onFocus={(event) => handleSheet(ind, 0, event)}
              onChange={(event) => handleSheet(ind, 0, event)}
              defaultValue={sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[0].laytimeStart : ''} readOnly={cal} />
          </td>

          <td>
            <input type="time" step="1" name="laytimeEnd" className="form-control" placeholder="19:30:00"
              onFocus={(event) => handleSheet(ind, 0, event)}
              onChange={(event) => handleSheet(ind, 0, event)}
              defaultValue={sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[0].laytimeEnd : ''} readOnly={cal} />
          </td>

          <td>
            <input type="text" name="laytimeUsed" className="form-control" placeholder="24:00:00"
              value={sheetData.length ? sheetData[ind].sub[0].laytimeUsed : 0} readOnly />
          </td>

          <td>
            <select className="form-control" name="reason" onChange={(event) => handleSheet(ind, 0, event)} required disabled={cal}>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[0].reason === 'Loading Done'}>Loading Done</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[0].reason === 'No Loading (RAIN)'}>No Loading (RAIN)</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[0].reason === 'No Loading (Crane Issue)'}>No Loading (Crane Issue)</option>
              <option selected={sheetData[ind] && sheetData[ind].sub && sheetData[ind].sub[0].reason === 'No Loading'}>No Loading</option>
            </select>
          </td>

          <td>{sheetData[ind] && sheetData[ind].sub ? sheetData[ind].sub[0].laytimeDiff : ''}</td>
        </tr>
        {
          temp
        }
      </>
    )
  })
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <form>
        {/* <h2 className="mt-3 mb-2">Laytime Sheet</h2> */}
        <div className="col-md-12 row mt-3 text-center">
          <label className="col-md-6"><b>Last Edited By :</b> {trail.modified_by_name ? trail.modified_by_name : "NA"} </label>
          <label className="col-md-6"><b>Last Edited At :</b> {trail.updated_at ? formatDateTime_Application(trail.updated_at) : "NA"}</label>
        </div>
        <div className="mt-5">
          <div className="row">
            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Quantity Loaded (In MT) {astrix} </label>
                <div className="col-md-12">
                  <input type="number" name="qLoaded" value={data.qLoaded} placeholder="55,600.000" className={" form-control" + (!error.qLoaded ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.qLoaded : ''} readOnly={cal} />
                  {error.qLoaded ? <div class="text-danger error-contract">{error.qLoaded}</div> : ''}
                </div>
              </div>

            </div>
            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">C/P Loading Rate (PDPR) {astrix} </label>
                <div className="col-md-12">
                  <input type="number" name="loadRate" value={data.loadRate} placeholder="8,000" className={" form-control" + (!error.loadRate ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.loadRate : ''} readOnly={cal} />
                  {error.loadRate ? <div class="text-danger error-contract">{error.loadRate}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Time of Arrival {astrix} </label>
                <div className="col-md-12">
                  <input type="datetime-local" placeholder="2/14/18 7:30" name="arrivalTime" value={data.arrivalTime} className={" form-control" + (!error.arrivalTime ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.arrivalTime : ''} readOnly={cal} />
                  {error.arrivalTime ? <div class="text-danger error-contract">{error.arrivalTime}</div> : ''}
                </div>
              </div>
            </div>


            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">NOR Tendered {astrix} </label>
                <div className="col-md-12">
                  <input type="datetime-local" placeholder="2/14/18 7:30" name="nor" value={data.nor} className={" form-control" + (!error.nor ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.nor : ''} readOnly={cal} />
                  {error.nor ? <div class="text-danger error-contract">{error.nor}</div> : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Laytime Unit {astrix} </label>
                <div className="col-md-12">
                  <select className={" form-control" + (!error.laytimeUnit ? '' : ' border-danger')} name="laytimeUnit" value={data.laytimeUnit} onChange={handleChange} required disabled={cal}>
                    <option selected>--Select Unit--</option>
                    <option>ATUTC</option>
                    <option>USC</option>
                  </select>
                  {error.laytimeUnit ? <div class="text-danger error-contract">{error.laytimeUnit}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Laytime commenced {astrix}  </label>
                <div className="col-md-12">
                  <input type="datetime-local" placeholder="" name="laytimeCommenced" value={data.laytimeCommenced} className={" form-control" + (!error.laytimeCommenced ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.laytimeCommenced : ''} readOnly={cal} />
                  {error.laytimeCommenced ? <div class="text-danger error-contract">{error.laytimeCommenced}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Loading commenced {astrix} </label>
                <div className="col-md-12">
                  <input type="datetime-local" name="loadCommenced" value={data.loadCommenced} placeholder="" className={" form-control" + (!error.loadCommenced ? '' : ' border-danger')} onChange={(event) => { handleChange(event); calLaytimeAllowed(event) }} required defaultValue={trail.sheet ? trail.sheet.loadCommenced : ''} readOnly={cal} />
                  {error.loadCommenced ? <div class="text-danger error-contract">{error.loadCommenced}</div> : ''}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Loading completed {astrix} </label>
                <div className="col-md-12">
                  <input type="datetime-local" name="loadCompleted" value={data.loadCompleted} placeholder="" className={" form-control" + (!error.loadCompleted ? '' : ' border-danger')} onChange={(event) => { handleChange(event); calLaytimeAllowed(event) }} required defaultValue={trail.sheet ? trail.sheet.loadCompleted : ''} readOnly={cal} />
                  {error.loadCompleted ? <div class="text-danger error-contract">{error.loadCompleted}</div> : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Demurage Rate {astrix} </label>
                <div className="col-md-12">
                  <input type="number" step="0.01" placeholder="$9,000.00" name="demurageRate" value={data.demurageRate} className={" form-control" + (!error.demurageRate ? '' : ' border-danger')} onChange={handleChange} required defaultValue={trail.sheet ? trail.sheet.demurageRate : ''} readOnly={cal} />
                  {error.demurageRate ? <div class="text-danger error-contract">{error.demurageRate}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Dispatch Rate {astrix} </label>
                <div className="col-md-12">
                  <input type="number" step="0.01" placeholder="$4,500.00" name="dispatchRate" value={data.dispatchRate} defaultValue={data.dispatchRate} className={" form-control" + (!error.dispatchRate ? '' : ' border-danger')} onChange={handleChange} required readOnly={cal} />
                  {error.dispatchRate ? <div class="text-danger error-contract">{error.dispatchRate}</div> : ''}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="row form-group">
                <label className="col-md-12">Laytime Allowed (Day:Hour:Minute) {astrix} </label>
                <div className="col-md-12">
                  <input type="text" name="laytimeAllowed" value={data.laytimeAllowed} placeholder="DD:HH:MM" defaultValue={totalAllowed.timeAllowed} className={" form-control" + (!error.laytimeAllowed ? '' : ' border-danger')} readOnly={cal} />
                  {error.laytimeAllowed ? <div class="text-danger error-contract">{error.laytimeAllowed}</div> : ''}
                </div>
              </div>
            </div>

            {[5, 7, 10].includes(userTypeId / 1) &&
              <div className="col-md-3">
                <div className="row form-group">
                  <label className="col-md-12">Upload SOF</label>
                  <div className="col-md-12">
                    <input className="form-control" type="file" accept=".png,.jpg,.pdf" name="sof" onChange={handleChange} disabled={cal} />
                  </div>
                </div>
              </div>}

          </div>

          {!(cal || Object.keys(trail).length) && <div className="form-group text-center pt-3">
            <input type="button" value="Go" className="btn btn-primary" onClick={(event) => calAndRenderSheet(event)} />
          </div>}
        </div>
      </form>

      {
        sheetFlag && <div>
          <div className="row">

            {
              !cal && <div className="col-md-3 text-right ml-auto">
                <button className="btn btn-info btn-sm" onClick={addRow}>Add Row</button>
              </div>}
          </div>

          <div className="mt-2">
            <table className="table table-custom table-sm" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Lay Date</th>
                  <th>Day</th>
                  <th>Laytime (From) </th>
                  <th>Laytime (To)</th>
                  <th>Laytime Diff (To - From)</th>
                  <th>Remarks</th>
                  <th>Laytime used</th>
                </tr>
              </thead>
              <tbody>
                {
                  body
                }
                {
                  cal &&
                  <>
                    <tr>
                      <td colSpan="5" rowSpan="6" className="align-middle text-center"><b>Port Total laytime used</b></td>
                      <td><b>Time Allowed</b></td>
                      <td>{totalAllowed.timeAllowed}</td>
                    </tr>
                    <tr>
                      <td><b>Time used </b></td>
                      <td>{totalAllowed.timeUsed}</td>
                    </tr>
                    {
                      !totalAllowed.timeDiif.includes('-') &&
                      <>
                        <tr>
                          <td><b>Time Saved</b></td>
                          <td>{totalAllowed.timeDiif}</td>
                        </tr>
                        <tr>
                          <td><b>Days </b></td>
                          <td>{totalAllowed.totalDays}</td>
                        </tr>
                        <tr>
                          <td><b>Dispatch Rate PDPR </b></td>
                          <td>{data.dispatchRate}</td>
                        </tr>
                        <tr>
                          <td><b> Total Dispatch </b></td>
                          <td>{(data.dispatchRate * totalAllowed.totalDays).toFixed(2)}</td>
                        </tr>
                      </>
                    }

                    {
                      totalAllowed.timeDiif.includes('-') &&
                      <>
                        <tr>
                          <td><b>Time exceeded</b></td>
                          <td>{totalAllowed.timeDiif}</td>
                        </tr>
                        <tr>
                          <td><b>Days </b></td>
                          <td>{totalAllowed.totalDays}</td>
                        </tr>
                        <tr>
                          <td><b>Demurage Rate PDPR </b></td>
                          <td>{data.demurageRate}</td>
                        </tr>
                        <tr>
                          <td><b> Total Demurage </b></td>
                          <td>{(data.demurageRate * totalAllowed.totalDays).toFixed(2)}</td>
                        </tr>
                      </>
                    }
                  </>
                }

              </tbody>
            </table>
          </div>

          <div className="btn-section d-flex  justify-content-end">
            {
              !cal &&
              <input type="button" className="btn btn-primary mt-2 btn-sm" value="Calculate" onClick={calChange} />
            }
            {
              cal &&
              <>
                <input type="button" className="btn btn-danger btn-sm m-2" value="Edit Sheet" onClick={calRevert} />
                {(Object.keys(trail).length === 0) && <input type="button" className="btn btn-primary btn-sm m-2" value="Save Sheet" onClick={() => prehandleSave()} />}
                {(Object.keys(trail).length > 0) && <input type="button" className="btn btn-primary btn-sm m-2" value="Update Sheet" onClick={() => prehandleUpdate()} />}
              </>
            }
          </div>
        </div>
      }
      <hr />
      <LaytimeSheetTrail
        userTokenDetails={userTokenDetails}
        laytimeType={laytimeType}
        contractNo={laytimesheet.cNo}
        contractType={'ship'}
        setRefresh={setRefresh}
        refresh={refresh} />
    </>

  )
}
//---------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    laytimesheet: state.laytimesheet,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaytimeSheet)
