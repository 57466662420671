export default function validate(values, party) {
  console.log("validate", values);

  let errors = {};
  if (party === false) {
    if (!values.party_designation) {
      errors.party_designation = 'Designation is required'
    }
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!values.email) {
      errors.email = 'Email ID is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email ID is invalid';
    }
    if (!values.company_name) {
      errors.company_name = "Company Name is required";
    }
    if (!values.contact_number) {
      errors.contact_number = 'Contact No is required';
    } else if (isNaN(values.contact_number)) {
      errors.contact_number = 'Contact No Should be a number';
    } else if (values.contact_number.length < 10) {
      errors.contact_number = 'Contact No should be of atleast 10 digits';
    }
    if (!values.contact_person) {
      errors.contact_person = "Contact Person is required";
    }
    if (!values.address) {
      errors.address = "Company Address is required";
    }
    if (!values.organization_type) {
      errors.organization_type = "Entity is required";
    }
    if (!values.country) {
      errors.country = 'Country is required';
    }
    // if (values.organization_type && values.organization_type === 'individual' && values.country === 'IN') {
    //   if (!values.panDocumentName) {
    //     errors.panDocumentName = 'PAN Number is required'
    //   }
    //   else if (values.panDocumentName.length < 10) {
    //     errors.panDocumentName = 'PAN Number should be of 10 digits'
    //   }
    // }
    // if (values.organization_type && values.organization_type !== 'individual' && values.country === 'IN') {
    //   if (!values.panDocumentName) {
    //     errors.panDocumentName = 'PAN Number is required'
    //   }
    //   else if (values.panDocumentName.length < 10) {
    //     errors.panDocumentName = 'PAN Number should be of 10 digits'
    //   }
    //   if (!values.gstDocumentName) {
    //     errors.gstDocumentName = 'GST Number is required'
    //   }
    //   else if (values.gstDocumentName.length < 15) {
    //     errors.gstDocumentName = 'GST Number should be of 15 digits'
    //   }
    // }
    // if (values.organization_type && values.organization_type === 'pvtPubLtd' && values.country === 'IN') {
    //   if (!values.cinDocumentName) {
    //     errors.cinDocumentName = 'CIN Number is required'
    //   }
    //   else if (values.cinDocumentName.length < 21) {
    //     errors.cinDocumentName = 'CIN Number should be of 21 digits'
    //   }
    // }
    // if (!values.tr_witness1_name) {
    //   errors.tr_witness1_name = 'Name is required'
    // }
    // if (!values.tr_witness1_aadhar) {
    //   errors.tr_witness1_aadhar = 'Aadhar / PAN Number is required'
    // }
    // if (!values.tr_witness1_address) {
    //   errors.tr_witness1_address = 'Address is required'
    // }
    // if (!values.tr_witness2_name) {
    //   errors.tr_witness2_name = 'Name is required'
    // }
    // if (!values.tr_witness2_aadhar) {
    //   errors.tr_witness2_aadhar = 'Aadhar / PAN Number is required'
    // }
    // if (!values.tr_witness2_address) {
    //   errors.tr_witness2_address = 'Address is required'
    // }
  }
  else if (party === true) {
    // if (!values.party_designation) {
    //   errors.party_designation = 'Designation is required'
    // }
    // if (!values.party_date) {
    //   errors.party_date = 'Date is required'
    // }
    // if (!values.party_place) {
    //   errors.party_place = 'Place is required'
    // }
    // if (!values.party_witness1_name) {
    //   errors.party_witness1_name = 'Name is required'
    // }
    // if (!values.party_witness1_aadhar) {
    //   errors.party_witness1_aadhar = 'Aadhar / PAN Number is required'
    // }
    // if (!values.party_witness1_address) {
    //   errors.party_witness1_address = 'Address is required'
    // }
    // if (!values.party_witness2_name) {
    //   errors.party_witness2_name = 'Name is required'
    // }
    // if (!values.party_witness2_aadhar) {
    //   errors.party_witness2_aadhar = 'Aadhar / PAN Number is required'
    // }
    // if (!values.party_witness2_address) {
    //   errors.party_witness2_address = 'Address is required'
    // }
  }
  console.log(errors);
  return errors;
}