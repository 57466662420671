import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setoffContract } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import { formatDate_Application } from '../../utils/dateFormaters';
import columns from '../dataTablesColumsMap/contractDirectoryColumn';
import customStyles from '../dataTablesColumsMap/customTableCss';
import DataTable from 'react-data-table-component';
import Sidebar from '../partial/sidebar';
import Header from '../partial/header';
import toastDisplay from '../../utils/toastNotification';
import AddOfflineContractDirectory from './addContractDir'
import Footer from '../partial/footer';

const ContractDirectoryGrid = ({ userTokenDetails, offContract, setoffContract, navToggleState, dispatch }) => {


  const [refresh, setrefresh] = useState(0)
  const [filterData, setFilterData] = useState({})
  const [deleteConfirm, setdeleteConfirm] = useState({});
  // const [updateModal, setupdateModal] = useState({ modal: false, id: null });

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}

  //---------------------------------------------------------------------------------------------------------------------
  // console.log("addDirectory====>", addDirectory)

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1)
    //------------------------------------------------------------------

  }, [refresh, filterData, offContract.info.refresh]);


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState("")

  function fetchTableData(page) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', "getdirectorycontractlisting", objectAPI).then((result) => {
      console.log('running getdirectorycontractlisting api-->', result);
      settableData(formatDataFortable(result.dirData))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getdirectorycontractlisting', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', "getdirectorycontractlisting", objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.dirData))
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getdirectorycontractlisting', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };



  function formatDataFortable(dataArray, pageNo) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}

      tempObj.dir_contract_name = dataArray[i].dir_contract_name ? dataArray[i].dir_contract_name : '--';
      tempObj.dir_contract_no = dataArray[i].dir_contract_no ? dataArray[i].dir_contract_no : '--';
      tempObj.seller = dataArray[i].sellerName ? dataArray[i].sellerName : '--';
      tempObj.buyer = (dataArray[i].buyer_type / 1 === 2) ? dataArray[i].buyerName_dir : dataArray[i].buyerName;
      tempObj.commodity = dataArray[i].dir_json_data.dirCommodity ? dataArray[i].dir_json_data.dirCommodity : '--';
      tempObj.quantity = dataArray[i].dir_json_data.dirQuantity ? dataArray[i].dir_json_data.dirQuantity + " (mt)" : '--';
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : "--";
      tempObj.action = (aclMap.cont_directory && aclMap.cont_directory.dir_mngcontract && aclMap.cont_directory.dir_mngcontract.isExist) ?
        <>
          <button className="btn btn-primary btn-sm ml-1" onClick={() => setoffContract(true, { type: "view", id: dataArray[i].id, jsonData: dataArray[i].dir_json_data })} tooltip="update">
            <i class="fas fa-eye"></i>
          </button>
          {/* <button className="btn btn-primary btn-sm ml-1" onClick={() => setoffContract(true, { type: "update", id: dataArray[i].id, jsonData: dataArray[i].dir_json_data })} tooltip="update">
            <i class="fas fa-edit"></i>
          </button> */}
          <button className="btn btn-danger btn-sm ml-1" onClick={() => setdeleteConfirm({ modal: true, id: dataArray[i].id })} tooltip="Remove">
            <i class="fas fa-trash-alt"></i>
          </button>
        </> : ""

      resultArray.push(tempObj)
    }
    return resultArray
  }

  // Handlers
  // console.log("update modal==>", updateModal)

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };


  const reset = () => {
    setFilterData({ searchUserType: "", search: "", fromDate: "", toDate: "" })
    setsearchKey("")
    setrefresh(refresh + 1)
  }

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------

  function deleteContract(id) {

    call('post', 'deletecontractdir', { dirContractId: id }).then((result) => {
      setdeleteConfirm({ modal: false, id: null })
      toastDisplay(result, "success")
      setrefresh(refresh + 1)
    }).catch((e) => {
      console.log('error in deletecontractdir', e);
    })
  }


  function warnAndStop() {
    console.log("here");
    toastDisplay("You already added Max Contract in your directory", "warn");
    setoffContract(false, {})
    return ""
  }

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state={'contdirectory'} userTokenDetails={userTokenDetails} />   {/* Sidebar */}
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            <Header
              title={"Contracts Directory"}
              total={"Total Contract - " + totalRows}
              userTokenDetails={userTokenDetails} />

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
            {console.log("totalRows", totalRows / 1)}
            {(offContract.modal && (totalRows / 1 < 300) && (aclMap.cont_directory && aclMap.cont_directory.dir_addcontract && aclMap.cont_directory.dir_addcontract.isExist)) ?
              <AddOfflineContractDirectory
                userTokenDetails={userTokenDetails}
              /> :
              <div className="">

                <div className="d-flex mt-3 mb-3">

                  <div className="ml-auto">
                    <ul className="range-slider">
                      <li>
                        <div className="ml-1">
                          <input placeholder="Search" type="text" name="search" value={searchKey} onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} />
                          <button type="button" onClick={() => {
                            setFilterData({
                              ...filterData,
                              "search": searchKey
                            })
                          }}><i class="fas fa-search fa-lg"></i></button>
                        </div>
                      </li>
                      <li>
                        <label>From</label>
                        <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} />
                      </li>
                      <li>
                        <label>To</label>
                        <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} />
                      </li>
                      <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
                    </ul>
                  </div>
                </div>

                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                    persistTableHead
                    // expandableRows
                    // expandableRowsComponent={<TableExpandedComponent />}
                    expandOnRowClicked
                    highlightOnHover
                    onSort={handleSort}
                    sortServer
                    striped
                    noHeader
                    customStyles={customStyles}
                  />
                </div>
                <hr />
                <div className="float-right">
                  <br />
                </div>


                {(deleteConfirm.modal && (aclMap.mng_directory && aclMap.mng_directory.dir_mnguser && aclMap.mng_directory.dir_mnguser.isExist)) &&
                  <div className={"modal" + (deleteConfirm.modal ? " show" : "")} id="Confirmation">
                    <div className="modal-dialog modal-lg border-inner" id="parent_class">
                      <div className="modal-content">
                        <div className="modal-header primary">
                          <h4 className="modal-title text-white">Confirmation</h4>
                          <button type="button" className="close" onClick={() => { setdeleteConfirm({ modal: false, id: null }) }}>×</button>
                        </div>
                        <div className="modal-body">
                          Please Confirm Delete Operation
                      </div>
                        <div className="modal-footer primary">
                          <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteContract(deleteConfirm.id)}>Delete</button>
                          <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm({ modal: false, id: null })}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>}

                {((offContract.modal) && (totalRows / 1 === 300)) && <>{warnAndStop()}</>}

              </div>}

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
};

// && (aclMap.cont_directory && aclMap.cont_directory.dir_addcontract && aclMap.cont_directory.dir_addcontract.isExist)
//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    offContract: state.offContract,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setoffContract: (modal, info) => { dispatch(setoffContract({ modal: modal, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractDirectoryGrid)
