import { useState, useEffect } from 'react';

const useForm = (callback, scrolltoError, validate, data) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      if (scrolltoError instanceof Function)
        scrolltoError()
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleSubmitcomdoc = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, data));
    setIsSubmitting(true);
  };

  const handleSubmitbank = (event) => {
    let counter = 0
    if (event) event.preventDefault();
    for (let key in values) {
      let value = values[key];
      if (value !== '')
        counter++;
    }
    if (Object.keys(values).length > 0 && counter > 0) {
      setErrors(validate(values));
    }
    else {
      setErrors(0)
    }
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    setErrors(errors => ({ ...errors, [event.target.name]: "" }));
  };

  const handleFile = (event) => {
    event.persist();
    console.log('fileUPloaded:==>', event.target.files[0])
    setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
    setErrors(errors => ({ ...errors, [event.target.name]: "" }))
  }

  const handleAvatar = (event) => {
    event.persist();
    console.log('fileUPloaded:==>', event.target.files[0])
    if (event.target.files && event.target.files[0] && event.target.files[0].size && event.target.files[0].size <= 5242880) {
      setErrors(errors => ({ ...errors, [event.target.name]: "" }))
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setValues(values => ({ ...values, avatarBase64: reader.result, avatarFile: event.target.files[0] }));
      };
    } else {
      setErrors(errors => ({ ...errors, [event.target.name]: "Max size limit is 5MB" }))
      setValues(values => ({ ...values, avatarBase64: "" }));
    }
  }


  const SETvaluesData = (data) => {
    data = data ? data : {};
    setValues(data);
  };

  return {
    SETvaluesData,
    handleChange,
    handleAvatar,
    handleSubmit,
    handleSubmitcomdoc,
    handleSubmitbank,
    handleFile,
    values,
    errors,
  }
};

export default useForm;
